import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const GermanImprint = () => {
    const h1Style = {
        paddingBottom: "15px",
    };
    const h2Style = {
        paddingBottom: "15px",
    };

    return (
        <Container maxWidth="md" sx={{ paddingTop: "80px" }}>
            <Grid container spacing={6}>
                <Grid item sx={{ paddingBottom: "50px" }}>
                    <Typography variant="h3" sx={h1Style}>
                        Impressum
                    </Typography>
                    <Typography variant="subtitle2">
                        Verantwortliche Instanz: nextflat AG
                        <br />
                        Riedmattstrasse 9<br />
                        8153 Rümlang
                        <br />
                        Schweiz
                        <br />
                        <br />
                        <b>E-Mail:</b>&nbsp;info@nextflat.ch
                        <br />
                        <br />
                        <Typography variant="h7" sx={h2Style}>
                            <b>Vertretungsberechtigte Personen:</b>&nbsp;Herr
                            Taman Choschnau (Geschäftsführer)
                            <br />
                        </Typography>
                        <br />
                        <Typography variant="h7" sx={h2Style}>
                            <b>Name des Unternehmens:</b>&nbsp;nextflat AG
                        </Typography>
                        <br />
                        <Typography variant="h7" sx={h2Style}>
                            <b>Registrationsnummer:</b>&nbsp;CH-020.3.051.235-5
                            <br />
                        </Typography>
                        <Typography variant="h7" sx={h2Style}>
                            <b>Umsatzsteuer-Identifikationsnummer:</b>&nbsp;
                            CHE-417.247.189
                            <br />
                        </Typography>
                        <br />
                        <br />
                        <b>Haftungsausschluss</b>
                        <br />
                        Der Autor übernimmt keine Gewähr für die Richtigkeit,
                        Genauigkeit, Aktualität, Zuverlässigkeit und
                        Vollständigkeit der Informationen. Haftungsansprüche
                        gegen den Autor wegen Schäden materieller oder
                        immaterieller Art, die aus dem Zugriff oder der Nutzung
                        bzw. Nichtnutzung der veröffentlichten Informationen,
                        durch Missbrauch der Verbindung oder durch technische
                        Störungen entstanden sind, werden ausgeschlossen. Alle
                        Angebote sind freibleibend. Der Autor behält es sich
                        ausdrücklich vor, Teile der Seiten oder das gesamte
                        Angebot ohne gesonderte Ankündigung zu verändern, zu
                        ergänzen, zu löschen oder die Veröffentlichung zeitweise
                        oder endgültig einzustellen.
                        <br />
                        <br />
                        <b>Haftungsausschluss für Inhalte und Links</b>
                        <br />
                        Verweise und Links auf Webseiten Dritter liegen
                        ausserhalb unseres Verantwortungsbereichs. Es wird
                        jegliche Verantwortung für solche Webseiten abgelehnt.
                        Der Zugriff und die Nutzung solcher Webseiten erfolgen
                        auf eigene Gefahr des jeweiligen Nutzers.
                        <br />
                        <br />
                        <b>Urheberrechtserklärung</b>
                        <br />
                        Die Urheber- und alle anderen Rechte an Inhalten,
                        Bildern, Fotos oder anderen Dateien auf dieser Website,
                        gehören ausschliesslich Testfirma oder den speziell
                        genannten Rechteinhabern. Für die Reproduktion jeglicher
                        Elemente ist die schriftliche Zustimmung des
                        Urheberrechtsträgers im Voraus einzuholen.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GermanImprint;
