import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { Axios } from "../../axios/axios";

function getNotification({ queryKey }) {
    const query = queryKey[1];
    return Axios.get(
        `/users/notification_find_byID/?user_id=${query.userId}&page=0&limit=10&language=${query.language}`
    );
}

// export function useGetNotification(userId) {
//     return useQuery(["get-notification", userId], getNotification, {
//         enabled: Boolean(userId),
//     });
// }
export function useGetNotification(userId, language) {
    return useInfiniteQuery(
        ["get-notification", { userId, language }],
        getNotification,
        {
            getNextPageParam: (_lastPage, pages) => {
                if (pages.length < pages[0].data.totalPages) {
                    return (pages.length - 1) * 1 + 1;
                } else {
                    return undefined;
                }
            },
            enabled: Boolean(userId),
        }
    );
}

//* unread notification
function getUnReadNotification({ queryKey }) {
    const query = queryKey[1];
    return Axios.get(
        `/users/get_unread_notification_find_byUID?user_id=${query.userId}&page=0&limit=5&language=${query.language}`
    );
}

export function useGetUnReadNotification(userId, language) {
    return useQuery(
        ["get-unread-notification", { userId, language }],
        getUnReadNotification,
        {
            enabled: Boolean(userId),
        }
    );
}

//* change read status

function onReadNotification(id) {
    return Axios.put("/users/notification_update", id);
}

export function useReadNotification() {
    return useMutation(onReadNotification);
}
