import PropTypes from "prop-types";

import { forwardRef } from "react";

//* mui components
import Dialog from "@mui/material/Dialog";
import Grow from "@mui/material/Grow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const ModalLayout = ({
    children,
    scroll,
    open,
    handleClose,
    maxWidth,
    ariaDescBy,
    ariaLabelBy,
    unresponsive,
    dialogProps,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            maxWidth={maxWidth}
            fullWidth
            open={open}
            scroll={scroll || "body"}
            TransitionComponent={Transition}
            transitionDuration={500}
            keepMounted
            // onClose={handleClose}
            aria-describedby={ariaDescBy}
            aria-labelledby={ariaLabelBy}
            {...(!unresponsive ? { fullScreen } : {})}
            {...(handleClose ? { onClose: handleClose } : {})}
            {...dialogProps}
        >
            {children}
        </Dialog>
    );
};

ModalLayout.propTypes = {
    children: PropTypes.node,
    scroll: PropTypes.string,
    handleClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ariaDescBy: PropTypes.string,
    ariaLabelBy: PropTypes.string,
};

export default ModalLayout;
