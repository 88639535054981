import React from "react";

//* mui components imports
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import ApartmentIcon from "@mui/icons-material/Apartment";
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import StairsIcon from "@mui/icons-material/Stairs";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
//* api
import { useCategories } from "../../../../hooks/search/useSearchOptions";
import Box from "@mui/material/Box";

//*utils
import { getPropertyPrice } from "../../../../utils/getPropertyTypes";
import { useTheme } from "@mui/material";
import { checkForEmptyDate } from "../../../../utils/checkDataFunction";
import moment from "moment/moment";
import "moment/locale/de";
import cookies from "js-cookie";

function PropertyFeatures({
    numberOfBedroom,
    numberOfFloors,
    price,
    livingSpace,
    categoryId,
    availability,
    availabilityDate,
}) {
    const { isLoading, data } = useCategories();
    const { t, i18n } = useTranslation();

    const theme = useTheme();

    const getCategoryName = () => {
        let categoryObj = data?.find((category) => category.id === categoryId);
        if (!categoryObj) return "Not Available";
        //console.log(categoryObj);
        return categoryObj?.name;
    };

    // const formatDate = (dateString) => {
    //     if (!dateString || dateString === "null") {
    //         return "--";
    //     }

    //     //console.log(dateString);
    //     if (dateString.toLowerCase() === "immediately") {
    //         return "immediately_&_after_agreement";
    //     }

    //     const parts = dateString.split("/");
    //     if (parts.length === 3) {
    //         const [month, day, year] = parts;
    //         return `${day}.${month}.${year}`;
    //     } else {
    //         return "";
    //     }
    // };

    const currentLanguageCode = cookies.get("i18next") || "en";
    moment.locale(currentLanguageCode);

    const formatDate = (dateString) => {
        if (!dateString) return "--";

        return moment(dateString).format("LL");
    };

    const availabilityText =
        availability === "date" && availabilityDate
            ? `${t("from")} ${formatDate(availabilityDate)}`
            : t(availability);

    const displayText =
        availabilityText === "--"
            ? "--"
            : `${availabilityText} ${t("available")}`;

    return (
        <Stack spacing={2}>
            <Typography variant="h5">{t("main_features")}</Typography>

            <Grid container rowSpacing={1}>
                <Grid item md={8}>
                    <Grid item container rowSpacing={1}>
                        <Grid item xs={6} sm={6} md={6}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <ApartmentIcon
                                    fontSize="medium"
                                    sx={{ color: theme.palette.secondary.main }}
                                />
                                <Typography
                                    component={"p"}
                                    sx={{
                                        color: "#717171",
                                        fontWeight: 400,
                                        lineHeight: "1.2500em",
                                        fontSize: "0.995em",
                                    }}
                                >
                                    {isLoading ? (
                                        <Skeleton width={90} height={20} />
                                    ) : (
                                        t(getCategoryName().toLowerCase())
                                    )}
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <BedIcon
                                    fontSize="large"
                                    sx={{ color: theme.palette.secondary.main }}
                                />
                                <Typography
                                    component={"p"}
                                    sx={{
                                        color: "#717171",
                                        fontWeight: 400,
                                        lineHeight: "1.2500em",
                                        fontSize: "0.995em",
                                    }}
                                >
                                    {numberOfBedroom
                                        ? `${numberOfBedroom} ${t("rooms")}`
                                        : `--`}
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <StairsIcon
                                    fontSize="large"
                                    sx={{ color: theme.palette.secondary.main }}
                                />
                                <Typography
                                    component={"p"}
                                    sx={{
                                        color: "#717171",
                                        fontWeight: 400,
                                        lineHeight: "1.2500em",
                                        fontSize: "0.995em",
                                    }}
                                >
                                    {numberOfFloors
                                        ? `${numberOfFloors} ${t(
                                              "number_of_floor"
                                          )}`
                                        : `--`}
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <ControlCameraIcon
                                    fontSize="large"
                                    sx={{ color: theme.palette.secondary.main }}
                                />
                                <Typography
                                    component={"p"}
                                    sx={{
                                        color: "#717171",
                                        fontWeight: 400,
                                        lineHeight: "1.2500em",
                                        fontSize: "0.995em",
                                        display: "inline-flex",
                                        alignItems: "baseline",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {livingSpace ? `${livingSpace} m²` : `--`}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    <Stack
                        p={2}
                        borderRadius={16}
                        border={1}
                        borderColor={theme.palette.secondary.main}
                        width={"fit-content"}
                        sx={{
                            background: "#FFFFFF",
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: theme.palette.secondary.main,
                                textAlign: "center",
                                display: "block",
                            }}
                        >
                            {getPropertyPrice(price)}
                            <Box
                                component="span"
                                sx={{
                                    display: "block",
                                    fontSize: "0.8rem",
                                    color: theme.palette.text.secondary,
                                }}
                            >
                                {t("property_detail_is_monthly_rent")}
                            </Box>
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                        sx={{ pt: 1 }}
                    >
                        <AccessTimeIcon
                            fontSize="large"
                            sx={{ color: theme.palette.secondary.main }}
                        />
                        <Typography
                            component={"p"}
                            sx={{
                                color: "#717171",
                                fontWeight: 400,
                                lineHeight: "1.2500em",
                                fontSize: "0.995em",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {displayText}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            {/* <Grid item container>
          <Grid item xs={12}>
            <Stack
              p={1.25}
              borderRadius={2.5}
              width={"fit-content"}
              sx={{
                background:
                  "linear-gradient(0deg, rgba(254, 89, 0, 0.2), rgba(254, 89, 0, 0.2)), #FFFFFF",
              }}
            >
              <Typography variant='h6' color={"#FE5900"}>
                CHF {price}.-
              </Typography>
            </Stack>
          </Grid>
        </Grid> */}
        </Stack>
    );
}

export default PropertyFeatures;
