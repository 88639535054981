import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { LOGGED_IN_OPTIONS } from "../../options/loggedInOption";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/Auth/useAuth";
import { actionTypes } from "../../store/reducer";
import { useStateValue } from "../../store/StateProvider";
import { Tooltip, styled, useTheme, Fade } from "@mui/material";
import useUserProfile from "../../hooks/profile/useUserProfile";
import { getName } from "../../utils/getFullName";
import { Axios } from "../../axios/axios";

const LoggedInButton = styled(Button)(() => ({
    padding: 0,
}));

const LoadingAccountTitle = () => (
    <Stack direction={"row"} spacing={1}>
        <Skeleton animation="wave" width={40} height={20} />
        <Skeleton animation="wave" width={40} height={20} />
    </Stack>
);

function LoggedInMenu({ colorPrimary }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const [{ user_id, user_email }, dispatch] = useStateValue();
    const { isLoading, data, status } = useUserProfile(user_id);
    const theme = useTheme();

    const [name, setName] = useState({
        firstName: "",
        lastName: "",
    });

    // if the account will be deleted from the database so it will prevent from crashing the website.
    //! please don't remove this useEffect hook
    useEffect(() => {
        if (status === "success" && !data?.data?.statuscode) {
            window.location.replace("/");
            localStorage.removeItem("nch_id");
            localStorage.removeItem("nch_user_id");
            dispatch({
                type: actionTypes.REMOVE_USER,
            });
            setAuth({});
        } else {
            //console.log(data?.data?.data[0]);
            setName({
                firstName: data?.data?.data[0]?.first_name,
                lastName: data?.data?.data[0]?.last_name,
            });
        }
    }, [
        status,
        data?.data?.statuscode,
        dispatch,
        setAuth,
        setName,
        data?.data?.data,
    ]);

    //    const userFirstName = data?.data?.data[0]?.first_name;
    //    const userLastName = data?.data?.data[0]?.last_name;

    const fullName = useMemo(
        () => getName(name.firstName, name.lastName, user_email),
        [name, user_email]
    );
    const [isLoggedInMenuOpen, setIsLoggedInMenuOpen] = useState(null);
    const loggedInMenuAnchor = Boolean(isLoggedInMenuOpen);

    const handleLoggedInMenu = (event) => {
        setIsLoggedInMenuOpen(event.currentTarget);
    };

    const handleLoggedInMenuClose = () => {
        setIsLoggedInMenuOpen(null);
    };

    const onMenuClick = (url) => {
        navigate(url);
        setIsLoggedInMenuOpen(null);
    };

    const handleLogout = () => {
        // logout after google login
        Axios.get("/logout", {
            withCredentials: true,
        });
        window.location.replace("/");
        localStorage.removeItem("nch_id");
        localStorage.removeItem("nch_user_id");
        localStorage.removeItem("nch_user_data");
        localStorage.removeItem("nf-role");

        dispatch({
            type: actionTypes.REMOVE_USER,
        });
        setAuth({});
    };

    return (
        <Fragment>
            <Tooltip title="Go to account" arrow>
                <LoggedInButton
                    variant="text"
                    color={colorPrimary ? "primary" : "secondary"}
                    disableRipple
                    onClick={handleLoggedInMenu}
                >
                    {isLoading ? <LoadingAccountTitle /> : fullName}
                </LoggedInButton>
            </Tooltip>
            <Menu
                anchorEl={isLoggedInMenuOpen}
                id="account-menu"
                open={loggedInMenuAnchor}
                onClose={handleLoggedInMenuClose}
                onClick={handleLoggedInMenuClose}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
                PaperProps={{
                    sx: {
                        minWidth: 200,
                    },
                }}
                sx={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    transition:
                        "color 300ms ease-in, background-color 300ms ease-in",
                    "& .MuiPaper-root": {
                        borderColor: "white",
                        borderWidth: "3px",
                        borderStyle: "solid",
                        bgcolor: theme.palette.primary.main,
                        color: "#ffffff",
                    },
                    "& .MuiListItemIcon-root": {
                        color: "#ffffff",
                    },
                    "& .MuiListItemText-primary": {
                        color: "#ffffff",
                        fontWeight: 500,
                    },
                    "& .MuiMenuItem-root": {
                        fontWeight: 500,
                        color: "#ffffff",
                        "&:hover": {
                            backgroundColor: "inherit",
                            "& .MuiListItemIcon-root, & .MuiListItemText-primary":
                                {
                                    color: theme.palette.secondary.main,
                                },
                        },
                    },
                }}
            >
                {LOGGED_IN_OPTIONS.map((item) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                item.link
                                    ? onMenuClick(item.link)
                                    : handleLogout();
                            }}
                            key={item.id}
                            disableRipple
                        >
                            <ListItemIcon>
                                <Icon color="inherit">{item.Icon}</Icon>
                            </ListItemIcon>
                            <ListItemText>{t(item.name)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Fragment>
    );
}

export default LoggedInMenu;
