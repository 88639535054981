//* mui imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//* custom layouts
import HeroLayout from "../layouts/HeroLayout";
import { useMemo } from "react";

//* shapes
import Droplet from "../shapes/Droplet";

//* image imports
import { heroImage } from "../../utils/imgImports";

const HeroDropletContainer = styled(Box)(() => ({
    position: "absolute",
    left: -60,
    top: 40,
}));

const HeroDropletTypography = styled(Typography)(({ theme }) => ({
    width: "150px",
    lineHeight: "30px",
    letterSpacing: "0.6px",
    color: theme.palette.common.white,
}));

const Hero = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleHeroButton = () => navigate("/create-dossier");
    const handleHeroButtonProperty = () => navigate("/create-object");

    const HeroDropletText = useMemo(
        () => (
            <HeroDropletContainer>
                <Droplet
                    position={"right"}
                    size={240}
                    bgcolor={"secondary"}
                    containerStyleProps={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <HeroDropletTypography variant="h6" textAlign={"center"}>
                        {t("increase_chances_of_finding")}
                    </HeroDropletTypography>
                </Droplet>
            </HeroDropletContainer>
        ),
        [t]
    );
    return (
        <HeroLayout
            heroDropletText={HeroDropletText}
            coloredBg
            dropletImage={heroImage}
            dropletImagePosition={"right"}
            dropletImageSize={740}
            heroHeading={"get_your_smart_dossier"}
            heroSubHeading={
                "get_your_smart_dossier_subtext"
            }
            heroButtonText={"get_your_smart_dossier_button"}
            heroButtonOnClick={handleHeroButton}
            heroHeadingProperty={"get_your_smart_dossier_property"}
            heroSubHeadingProperty={
                "get_your_smart_dossier_subtext_property"
            }
            heroButtonTextProperty={"get_your_smart_dossier_button_property"}
            heroButtonOnClickProperty={handleHeroButtonProperty}
            dropletImageAlt={"hero-image"}
        />
    );
};

export default Hero;
