import { useEffect } from 'react';

const BitrixScript = (show) => {
    useEffect(() => {
        const scriptId = 'bitrix-script';

        if (show) {
            if (!document.getElementById(scriptId)) {
                const script = document.createElement('script');
                script.id = scriptId;
                script.src = "https://crm.nachmieter24.ch/upload/crm/site_button/loader_3_1hsg8e.js";
                script.async = true;
                document.body.appendChild(script);
            }
        } else {
            const script = document.getElementById(scriptId);
            if (script) {
                document.body.removeChild(script);
            }
        }
    }, [show]);
};

export default BitrixScript;
