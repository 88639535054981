//* mui imports
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { DossierCTA } from "../../utils/imgImports";
import ImageWithContent from "../layouts/ImageWithContent";
import { useTranslation } from "react-i18next";
// import SearchByDistance from "../Search/SearchByDistance";
import ArrowButton from "../elements/button/ArrowButton";
import { useNavigate } from "react-router-dom";

const ApplyToSmartDossier = ({ coloredBg }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const onStart = () => navigate("/create-dossier");

    return (
        <ImageWithContent
            layoutDirection={"row"}
            image={DossierCTA}
            coloredBg={coloredBg}
        >
            <Stack
                justifyContent={"center"}
                height={"100%"}
                sx={{ px: { xs: 3, md: 6, lg: 10 }, py: { xs: 5, md: 6 } }}
                rowGap={5}
            >
                <Stack rowGap={2.5}>
                    <Typography variant="h4" color={theme.palette.common.white}>
                        {t("already_convinced_by_our_smart_dossier")}
                    </Typography>
                    <Typography
                        variant="body1"
                        color={theme.palette.common.white}
                    >
                        {t("start_compiling_your_smart_dossier")}
                    </Typography>
                </Stack>
                <ArrowButton
                    variant="contained"
                    sx={{ textTransform: "Capitalize" }}
                    color={coloredBg ? "primary" : "secondary"}
                    onClick={onStart}
                >
                    {t("start")}
                </ArrowButton>
                {/* <SearchByDistance coloredBg={coloredBg} /> */}
            </Stack>
        </ImageWithContent>
    );
};

ApplyToSmartDossier.propTypes = {
    coloredBg: PropTypes.bool,
};

export default ApplyToSmartDossier;
