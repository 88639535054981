export const DEFAULT_VALUE = {
    //* Tenant1
    //first-section
    salutation: "",
    title: "",
    otherTitle: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    civilStatus: "",
    nationality: null,

    //second-section
    country: null,
    residencyPermit: "",
    dateOfEntry: null,
    // hometown: null,
    streetAddress: "",
    streetNo: "",
    city: "",
    zip: "",
    residingThereSince: null,

    //section-third
    phoneNo: "",
    privatePhoneNo: "",
    email: "",

    //section-fourth
    employedOrNot: "",
    //if-no
    unemploymentStatus: "",
    otherEmploymentStatus: "",
    selfEmployedCompanyName: "",
    selfEmployedCompanyAddress: "",
    selfEmployedThereSince: "",
    selfEmployedIncome: "",
    liablePerson: "",
    coveredRentAmount: "",

    howYouPayRent: "",
    referenceName: "",
    referenceEmail: "",
    referencePhone: "",
    //if-yes
    employedThereSince: null,
    employmentRelationship: "",
    workloadPercent: "",
    profession: "",
    monthlyIncome: "",
    annualSalaryRange: "",
    annualSalaryRangeId: "",
    salary_id: "",
    companyNameAndAddress: null,
    companyName: "",
    companyAddress: "",
    employeeName: "",
    employeeEmail: "",
    employeePhoneNo: "",

    //section-fifth
    currentAdministrationName: "",
    currentAdministrationContactName: "",
    currentRent: "",
    currentAdministrationEmail: "",
    currentAdministrationPhoneNo: "",
    nameTags: "",
    hasApartmentCancelled: "",
    apartmentCancelledBy: "",
    hasEmploymentTerminated: "",
    residencyChangeReason: "",
    residencyChangeReasonComment: "",
    isImmediateAndAfterAgreement: false,
    availability: "",
    dateOfMoving: null,
    hasNoCurrentApartment: false,
    movingOutOf: "",
    otherMovingOut: "",
    parentName: "",
    parentEmail: "",
    parentPhoneNo: "",

    //section-sixth
    noOfAdults: "",
    hasChildren: "",
    //if yes
    noOfChildren: null,
    childrensAverageAge: [],
    noOfChildrenMovingIn: "",

    isSmoker: "",
    // if yes
    smokePreference: "",
    hasMusicalInstrument: "",
    // if yes
    isPlayingAtHome: "",
    hasVehicle: "",
    // if yes
    typeOfVehicle: "",
    isParkingNeeded: "",
    numberOfParkingSpace: "",
    hasPet: "",
    //if yes
    pet: "",
    // petMaxHeight: "",
    isAnotherTenantMovingIn: "",
    //if yes
    anotherTenantRelation: "",

    //* Tenant 2
    //1st-section
    tenantTwoId: "",
    tenantTwoSalutation: "",
    tenantTwoTitle: "",
    tenantTwoOtherTitle: "",
    tenantTwoFirstName: "",
    tenantTwoLastName: "",
    tenantTwoDateOfBirth: null,
    tenantTwoCivilStatus: "",
    tenantTwoNationality: null,

    //2nd-section
    sameAddressAsFirstTenant: "",
    // if No
    tenantTwoCountry: null,
    tenantTwoResidencyPermit: "",
    tenantTwoDateOfEntry: null,
    tenantTwoCity: null,
    tenantTwoZip: "",
    tenantTwoStreetAddress: null,
    tenantTwoStreetNo: "",
    tenantTwoResidingThereSince: "",

    //3rd section
    sameContactAsFirstTenant: "",
    // if no
    tenantTwoPhoneNo: "",
    tenantTwoPrivatePhoneNo: "",
    tenantTwoEmail: "",

    //4th section
    tenantTwoEmployedOrNot: "",
    // if no
    tenantTwoUnemploymentStatus: "",
    tenantTwoOtherEmploymentStatus: "",
    tenantTwoSelfEmployedCompanyName: "",
    tenantTwoSelfEmployedCompanyAddress: "",
    tenantTwoSelfEmployedThereSince: "",
    tenantTwoSelfEmployedIncome: "",
    tenantTwoLiablePerson: "",
    tenantTwoCoveredRentAmount: "",

    tenantTwoHowYouPay: "",
    tenantTwoReferenceName: "",
    tenantTwoReferenceEmail: "",
    tenantTwoReferencePhone: "",

    // if yes
    tenantTwoEmployedThereSince: "",
    tenantTwoEmploymentRelationship: "",
    tenantTwoWorkloadPercent: "",
    tenantTwoProfession: "",
    tenantTwoMonthlyIncome: "",
    tenantTwoAnnualSalaryRange: "",
    tenantTwoAnnualSalaryRangeId: null,
    ptr_salary_id: null,
    tenantTwoCompanyName: "",
    tenantTwoCompanyAddress: "",
    tenantTwoCompanyNameAndAddress: null,
    tenantTwoEmployeeName: "",
    tenantTwoEmployeeEmail: "",
    tenantTwoEmployeePhoneNo: "",

    //5th section
    sameAdministrationAsFirstTenant: "",
    // if mo
    tenantTwoCurrentAdministrationName: "",
    tenantTwoCurrentAdministrationContactName: "",
    tenantTwoCurrentRent: "",
    tenantTwoCurrentAdministrationEmail: "",
    tenantTwoCurrentAdministrationPhoneNo: "",
    tenantTwoNameTags: "",
    tenantTwoHasEmploymentTerminated: "",
    tenantTwoResidencyChangeReason: "",
    tenantTwoResidencyChangeReasonComment: "",
    tenantTwoIsImmediateAndAfterAgreement: false,
    tenantTwoDateOfMoving: null,
    tenantTwoAvailability: "",
    tenantTwoHasNoCurrentApartment: "",
    tenantTwoMovingOutOf: "",
    tenantTwoOtherMovingOut: "",
    tenantTwoParentName: "",
    tenantTwoParentEmail: "",
    tenantTwoParentPhoneNo: "",

    //6th section

    isTenantTwoSmoker: "",
    // if yes
    tenantTwoSmokePreference: "",
    tenantTwoHasMusicalInstrument: "",
    // if yes
    isTenantTwoPlayingAtHome: "",

    hasTenantTwoVehicle: "",
    tenantTwoTypeOfVehicle: "",
    isTenantTwoNeedParking: "",
    tenantTwoNumberOfParkingSpace: "",

    hasTenantTwoPet: "",
    tenantTwoPet: "",

    // propertyAddress: "",
    // propertyCityAndZip: "",
    // propertyRooms: "",
    // propertyFloor: "",
    // propertyRent: "",
    // wishedCity: "",
    // wishedRooms: "",
    // maxRent: "",

    video: null,
    description: "",

    tenantType: "tenant_one",
    documentTypeTenantOne: "passport_id",
    documentTypeTenantTwo: "tenantTwoPassport",
    documentComment: "",

    profilePic: null,
    tenantTwoProfilePic: null,

    bannerImage: null,

    passport_id: null,
    salary_statement: null,
    debt_collect: null,
    criminal_record: null,
    other_docs: null,
    passport_id_description: null,
    salary_statement_description: null,
    debt_collect_description: null,
    criminal_record_description: null,
    other_docs_description: null,
    isNegativeTenantOneDebtEntry: "",
    isNegativeTenantTwoDebtEntry: "",

    passport_id_date_of_upload: null,
    salary_statement_date_of_upload: null,
    debt_collect_date_of_upload: null,
    criminal_record_date_of_upload: null,
    other_docs_date_of_upload: null,

    tenantTwoPassport: null,
    tenantTwoSalary: null,
    tenantTwoDebt: null,
    tenantTwoCriminal: null,
    tenantTwoOtherDocs: null,
    tenantTwoPassportDateOfUpload: null,
    tenantTwoSalaryDateOfUpload: null,
    tenantTwoDebtDateOfUpload: null,
    tenantTwoCriminalDateOfUpload: null,
    tenantTwoOtherDocsDateOfUpload: null,
    tenantTwoProfilePicDescription: null,
    tenantTwoPassportDescription: null,
    tenantTwoSalaryDescription: null,
    tenantTwoDebtDescription: null,
    tenantTwoCriminalDescription: null,
    tenantTwoOtherDocsDescription: null,
};
