import React, { useEffect, useRef, useState } from "react";

//*  Translation
import { useTranslation } from "react-i18next";

//* libraries
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

//* store imports
import { useStateValue } from "../../store/StateProvider";

//* map imports
import Map from "../../component/map/Map";

//* component import
import PropertyCardWithDescription from "../../component/cards/PropertyCardWithDescription";
import FilterModal from "../../component/modal/properties/FilterModal";
import PropertyCardWithDescriptionSkeleton from "../../component/skeleton/PropertyCardWithDescriptionSkeleton";
import useGetAllProperties from "../../hooks/properties/useProperties";

//* mui components import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import cookies from "js-cookie";
// import { NoPropertyFound } from "../../component/no_data_found/NoPropertyFound";

export default function Explore() {
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const navigate = useNavigate();
    //   const { isLoading, mutate } = useSearch();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);

    const [removeMap, setRemoveMap] = useState(false);

    const handleRemoveMap = () => {
        setRemoveMap(!removeMap);
    };

    const {
        isLoading: propertiesLoading,
        data: allProperties,
        isFetching,
        isPreviousData,
        status,
    } = useGetAllProperties({
        page: currentPage,
        limit: "8",
        lang: searchParams.get("lang") || "en",
        city_id: searchParams.get("city_id") || "",
        type_id: 2,
        category_id: searchParams.get("category_id") || "",
        floor: searchParams.get("floor") || "",
        // period: searchParams.get("period"),
        space_from: searchParams.get("space_from") || "",
        space_to: searchParams.get("space_to") || "",
        number_bedroom: searchParams.get("number_bedroom") || "",
        price: searchParams.get("price") || "",
        aminity:
            searchParams.getAll("amenities").length === 0
                ? ""
                : searchParams.getAll("amenities"),
        user_id: user_id || "",
    });

    const [filterOpen, setFilterOpen] = useState(false);

    const handleClickFilterOpen = () => {
        setFilterOpen(true);
    };

    const handleFilterClose = () => {
        setFilterOpen(false);
    };

    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (filterOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [filterOpen]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [pathname, currentPage]);

    const handlePageChange = (e, page) => setCurrentPage(page - 1);

    if (status === "success" && !allProperties?.data?.statuscode) {
        // return <NoPropertyFound />;
        return <h3>No Data found</h3>;
    }

    const LoadingProperties = () => {
        return [1, 2, 3, 4].map((_, idx) => (
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                    p: 1,
                    mb: 2,
                }}
                key={idx}
            >
                <PropertyCardWithDescriptionSkeleton />
            </Grid>
        ));
    };

    const MapPlaceholder = () => (
        <Skeleton variant="rectangular" width={"100%"} height={"100vh"} />
    );

    return (
        <>
            <Container maxWidth="lg" sx={{ pt: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            sx={{
                                p: 1,
                            }}
                        >
                            <Grid
                                spacing={2}
                                sx={{
                                    p: 1,
                                }}
                            >
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ placeItems: "center" }}
                                >
                                    <Typography
                                        component={"span"}
                                        className={`fi fi-ch`}
                                        sx={{ mr: 1 }}
                                    ></Typography>

                                    <Typography
                                        variant="h5"
                                        fontSize={"1.3em"}
                                        color="primary"
                                    >
                                        {propertiesLoading ? (
                                            <Skeleton
                                                width="25px"
                                                height="25px"
                                            />
                                        ) : (
                                            allProperties?.data?.totalItems +
                                            "+"
                                        )}
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        fontSize={"1.3em"}
                                        color="secondary"
                                        sx={{ mb: 2 }}
                                    >
                                        {searchParams.get("type_id") === "1"
                                            ? t(
                                                  "explore_for_buy_in_switzerland"
                                              )
                                            : t(
                                                  "explore_for_rent_in_switzerland"
                                              )}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="flex-end"
                                >
                                    {removeMap ? (
                                        <Button
                                            onClick={handleRemoveMap}
                                            disabled={propertiesLoading}
                                            variant="contained"
                                            startIcon={
                                                <FormatListBulletedIcon />
                                            }
                                        >
                                            {t("list")}
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={handleRemoveMap}
                                            disabled={propertiesLoading}
                                            variant="contained"
                                            startIcon={<LocationOnIcon />}
                                        >
                                            {t("map")}
                                        </Button>
                                    )}
                                    <Button
                                        onClick={handleClickFilterOpen}
                                        variant="contained"
                                        disabled={propertiesLoading}
                                        startIcon={<TuneRoundedIcon />}
                                    >
                                        {t("filters")}
                                    </Button>
                                </Stack>
                            </Grid>
                            {!removeMap ? (
                                <Grid container mt={2}>
                                    {propertiesLoading
                                        ? LoadingProperties()
                                        : allProperties?.data?.results?.map(
                                              (property) => {
                                                  return (
                                                      <Grid
                                                          key={property?.id}
                                                          item
                                                          xs={12}
                                                          mb={2}
                                                      >
                                                          <PropertyCardWithDescription
                                                              property={
                                                                  property
                                                              }
                                                          />
                                                      </Grid>
                                                  );
                                              }
                                          )}
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    item
                                    height={"723px"}
                                    width={"100%"}
                                    mt={2}
                                >
                                    <Grid item xs={12}>
                                        {propertiesLoading ? (
                                            MapPlaceholder()
                                        ) : (
                                            <Map
                                                properties={
                                                    allProperties?.data?.results
                                                }
                                                removeMap={removeMap}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Box>

                        {/* right now we are not hiding the pagination because the functionality is working there */}
                        {!removeMap && (
                            <Stack
                                direction={"row"}
                                justifyContent="center"
                                mb={2}
                            >
                                <Pagination
                                    count={allProperties?.data?.totalPages}
                                    page={currentPage + 1}
                                    onChange={handlePageChange}
                                    color="primary"
                                    variant="outlined"
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        )}
                    </Grid>

                    {/* {!removeMap && (
            <Grid item lg={12}>
              {propertiesLoading ? (
                MapPlaceholder()
              ) : (
                <Map
                  properties={allProperties?.data?.results}
                  removeMap={removeMap}
                />
              )}
            </Grid>
          )} */}
                </Grid>
            </Container>
            <FilterModal
                open={filterOpen}
                descriptionElementRef={descriptionElementRef}
                handleClose={handleFilterClose}
            />
        </>
    );
}
