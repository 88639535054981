import React, { useState } from "react";

//* mui components imports
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

export const PropertyMobileDescription = ({ description, title }) => {
    const [text, setText] = useState(true);

    const toggleText = () => {
        setText(!text);
    };
    return (
        <>
            <Typography variant="h5" mb={2}>
                {title}
            </Typography>
            <Collapse in={!text} collapsedSize={60}>
                <Typography variant="h5" fontWeight={400} color={"#717171"}>
                    {description}
                </Typography>
            </Collapse>
            <Typography component={"span"} onClick={toggleText}>
                {text ? "...read more" : "show less"}
            </Typography>
        </>
    );
};
