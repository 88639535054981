import React from "react";

//*  using toast for success and error
import { toast, Zoom } from "react-toastify";

//* cookie
import cookies from "js-cookie";

//* react-hook-form
import { useFormContext } from "react-hook-form";

//* Translation
import { useTranslation } from "react-i18next";

//* mui components import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import {
    useSendAdministrationReference,
    useSendEmployerReference,
} from "../../../../hooks/reference-form/useReferenceForm";
import { useStateValue } from "../../../../store/StateProvider";
import { isValidEmail } from "../../../../utils/isValidEmail";
import ArrowButton from "../../../elements/button/ArrowButton";

export const DossierReferenceBox = ({
    heading,
    bodyText,
    buttonTextOne,
    buttonTextTwo,
    buttonFor,
    handleReferenceStatus,
    status,
    tenantRole,
    isDisabled,
}) => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const [{ user_id }] = useStateValue();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { watch, getValues, onSubmitData, trigger } = useFormContext();

    const { mutate: mutateEmployer } = useSendEmployerReference();
    const { mutate: mutateAdministration } = useSendAdministrationReference();

    const {
        firstName,
        lastName,
        streetAddress,
        streetNo,
        zip,
        city,
        employeeEmail,
        referenceEmail,
        currentAdministrationEmail,
        parentEmail,
        tenantTwoFirstName,
        tenantTwoLastName,
        tenantTwoStreetAddress,
        tenantTwoStreetNo,
        tenantTwoZip,
        tenantTwoCity,
        tenantTwoEmployeeEmail,
        tenantTwoReferenceEmail,
        tenantTwoCurrentAdministrationEmail,
        tenantTwoParentEmail,
    } = getValues();

    const watchEmployedOrNot = watch("employedOrNot");

    const watchTenantTwoEmployed = watch("tenantTwoEmployedOrNot");

    const watchTenantOneHasNoCurrentApartment = watch("hasNoCurrentApartment");
    const watchTenantTwoHasNoCurrentApartment = watch(
        "tenantTwoHasNoCurrentApartment"
    );

    const watchMovingOutOf = watch("movingOutOf");
    const watchTenantTwoMovingOutOf = watch("tenantTwoMovingOutOf");

    const watchTenantTwoSameAsFirstTenant = watch("sameAddressAsFirstTenant");
    const watchTenantTwoSameAdministrationAsFirstTenant = watch(
        "sameAdministrationAsFirstTenant"
    );

    // tenant one reference form field
    const tenantOneEmployerRef =
        watchEmployedOrNot === "yes"
            ? [
                  "profession",
                  "monthlyIncome",
                  "companyNameAndAddress",
                  "employeeName",
                  "employeeEmail",
                  "employeePhoneNo",
              ]
            : [
                  "howYouPayRent",
                  "referenceName",
                  "referenceEmail",
                  "referencePhone",
              ];

    const tenantOneAdministrationRef = [
        "currentAdministrationName",
        "currentRent",
        "currentAdministrationEmail",
        "currentAdministrationPhoneNo",
    ];

    // tenant two reference form field
    const tenantTwoEmployerRef =
        watchTenantTwoEmployed === "yes"
            ? [
                  "tenantTwoProfession",
                  "tenantTwoMonthlyIncome",
                  "tenantTwoCompanyNameAndAddress",
                  "tenantTwoEmployeeName",
                  "tenantTwoEmployeeEmail",
                  "tenantTwoEmployeePhoneNo",
              ]
            : [
                  "tenantTwoHowYouPay",
                  "tenantTwoReferenceName",
                  "tenantTwoReferenceEmail",
                  "tenantTwoReferencePhone",
              ];

    const tenantTwoAdministrationRef =
        watchTenantTwoSameAdministrationAsFirstTenant === "yes"
            ? [
                  "currentAdministrationName",
                  "currentRent",
                  "currentAdministrationEmail",
                  "currentAdministrationPhoneNo",
              ]
            : [
                  "tenantTwoCurrentAdministrationName",
                  "tenantTwoCurrentRent",
                  "tenantTwoCurrentAdministrationEmail",
                  "tenantTwoCurrentAdministrationPhoneNo",
              ];

    // checking the address of tenant one
    const tenantTwoEmployerAddress =
        watchTenantTwoSameAsFirstTenant === "yes"
            ? `${streetAddress} ${streetNo}, ${zip} ${city}`
            : `${tenantTwoStreetAddress} ${tenantTwoStreetNo}, ${tenantTwoZip} ${tenantTwoCity}`;

    // checking the address of tenant two
    const tenantTwoAdministrationAddress =
        watchTenantTwoSameAdministrationAsFirstTenant === "yes"
            ? `${streetAddress} ${streetNo}, ${zip} ${city}`
            : `${tenantTwoStreetAddress} ${tenantTwoStreetNo}, ${tenantTwoZip} ${tenantTwoCity}`;

    // checking the email with the employer field of tenant one
    const employerEmailTenantOne =
        watchEmployedOrNot === "yes" ? employeeEmail : referenceEmail;

    // checking the email with the employer field of tenant two
    const employerEmailTenantTwo =
        watchTenantTwoEmployed === "yes"
            ? tenantTwoEmployeeEmail
            : tenantTwoReferenceEmail;

    // checking the email with the administration field of tenant two
    const administrationEmailTenantTwo =
        watchTenantTwoSameAdministrationAsFirstTenant === "yes"
            ? currentAdministrationEmail
            : tenantTwoCurrentAdministrationEmail;

    const getTooltipTitle = (status) => {
        switch (status) {
            case "":
                return "you_have_not_requested_for_reference";
            case "pending":
                return "please_wait_for_validation";
            case "confirmed":
                return "you_reference_is_confirmed";
            default:
                return;
        }
    };

    // function to check which data to check for validation using hook form trigger method
    const getRefTriggerArray = (btnFor, role) => {
        if (btnFor === "employer" && role === "tenantOne")
            return tenantOneEmployerRef;

        if (btnFor === "administration" && role === "tenantOne")
            return tenantOneAdministrationRef;

        if (btnFor === "employer" && role === "tenantTwo")
            return tenantTwoEmployerRef;

        if (btnFor === "administration" && role === "tenantTwo")
            return tenantTwoAdministrationRef;
    };

    const getReferenceStatusKey = (btnFor, role) => {
        if (btnFor === "administration" && role === "tenantOne")
            return "administration";
        if (btnFor === "employer" && role === "tenantOne") return "employer";
        if (btnFor === "administration" && role === "tenantTwo")
            return "tenantTwoAdministration";
        if (btnFor === "employer" && role === "tenantTwo")
            return "tenantTwoEmployer";
    };

    const handleEmployerReference = async () => {
        let tenantOneEmployer = {
            user_id: user_id,
            referenceEmail: employerEmailTenantOne,
            type: "employer",
            role_type: tenantRole,
            fullName: `${firstName} ${lastName}`,
            address: `${streetAddress} ${streetNo}, ${zip} ${city}`,
            lang: currentLanguageCode,
        };

        let tenantTwoEmployer = {
            user_id: user_id,
            referenceEmail: employerEmailTenantTwo,
            type: "employer",
            role_type: tenantRole,
            fullName: `${tenantTwoFirstName} ${tenantTwoLastName}`,
            address: tenantTwoEmployerAddress,
            lang: currentLanguageCode,
        };

        //  checking the reference fields
        const isFilled = await trigger(
            getRefTriggerArray(buttonFor, tenantRole)
        );

        const checkButtonFor =
            tenantRole === "tenantOne" ? tenantOneEmployer : tenantTwoEmployer;

        if (isFilled) {
            mutateEmployer(checkButtonFor, {
                onSuccess: (data) => {
                    if (!data?.data?.statuscode) {
                        toast.error(t("something_went_wrong"), {
                            transition: Zoom,
                        });
                        return;
                    }

                    toast.success(
                        t(
                            "we_have_successfully_sent_the_request_for_a_reference"
                        )
                    );
                    handleReferenceStatus(
                        getReferenceStatusKey(buttonFor, tenantRole),
                        "pending"
                    );
                },
                onError: (errors) => {
                    toast.error(`${t("we_are_facing_some_technical_issue")}`);
                    console.error(errors);
                },
            });
        }
    };

    const handleAdministrationReference = async () => {
        let tenantOneReferenceMail =
            watchTenantOneHasNoCurrentApartment &&
            watchMovingOutOf === "parental_home"
                ? parentEmail
                : currentAdministrationEmail;
        let tenantOneAdministration = {
            user_id: user_id,
            referenceEmail: tenantOneReferenceMail,
            type: "administration",
            role_type: tenantRole,
            fullName: `${firstName} ${lastName}`,
            address: `${streetAddress} ${streetNo}, ${zip} ${city}`,
            lang: currentLanguageCode,
        };

        let tenantTwoReferenceMail =
            watchTenantTwoHasNoCurrentApartment &&
            watchTenantTwoMovingOutOf === "parental_home"
                ? tenantTwoParentEmail
                : tenantTwoCurrentAdministrationEmail;

        let tenantTwoAdministration = {
            user_id: user_id,
            referenceEmail: tenantTwoReferenceMail,
            type: "administration",
            role_type: tenantRole,
            fullName: `${tenantTwoFirstName} ${tenantTwoLastName}`,
            address: tenantTwoAdministrationAddress,
            lang: currentLanguageCode,
        };

        const isFilled = await trigger(
            getRefTriggerArray(buttonFor, tenantRole)
        );

        const checkButtonFor =
            tenantRole === "tenantOne"
                ? tenantOneAdministration
                : tenantTwoAdministration;

        if (isFilled) {
            mutateAdministration(checkButtonFor, {
                onSuccess: (data) => {
                    if (!data?.data?.statuscode) {
                        toast.error(t("something_went_wrong"), {
                            transition: Zoom,
                        });
                        return;
                    }
                    toast.success(
                        t(
                            "we_have_successfully_sent_the_request_for_a_reference"
                        )
                    );
                    handleReferenceStatus(
                        getReferenceStatusKey(buttonFor, tenantRole),
                        "pending"
                    );
                },
                onError: (errors) => {
                    toast.error(`${t("we_are_facing_some_technical_issue")}`);
                    console.error(errors);
                },
            });
        }
    };

    const handleSubmitAllData = () => {
        let allValues = getValues();
        buttonFor === "employer"
            ? handleEmployerReference()
            : handleAdministrationReference();
        setTimeout(handleCloseConfirmation, 500);
        onSubmitData(allValues);
    };

    // check if the email field is vaild
    const isEmailFieldValid = () => {
        //   if (tenantRole === "tenantOne") {
        if (buttonFor === "administration") {
            if (
                tenantRole === "tenantOne" &&
                !watchTenantOneHasNoCurrentApartment
            )
                return isValidEmail(currentAdministrationEmail);
            if (
                tenantRole === "tenantOne" &&
                watchTenantOneHasNoCurrentApartment &&
                watchMovingOutOf === "parental_home"
            )
                return isValidEmail(parentEmail);
            if (
                tenantRole === "tenantTwo" &&
                watchTenantTwoSameAdministrationAsFirstTenant === "no" &&
                !watchTenantOneHasNoCurrentApartment
            )
                return isValidEmail(tenantTwoCurrentAdministrationEmail);
            if (
                tenantRole === "tenantTwo" &&
                watchTenantTwoSameAdministrationAsFirstTenant === "no" &&
                watchTenantTwoHasNoCurrentApartment &&
                watchTenantTwoMovingOutOf === "parental_home"
            )
                return isValidEmail(tenantTwoParentEmail);
        }

        if (tenantRole === "tenantOne" && watchEmployedOrNot === "yes")
            return isValidEmail(employeeEmail);
        if (tenantRole === "tenantOne" && watchEmployedOrNot === "no")
            return isValidEmail(referenceEmail);
        if (tenantRole === "tenantTwo" && watchTenantTwoEmployed === "yes")
            return isValidEmail(tenantTwoEmployeeEmail);
        if (tenantRole === "tenantTwo" && watchTenantTwoEmployed === "no")
            return isValidEmail(tenantTwoReferenceEmail);

        //   return isValidEmail(employeeEmail); // Return true if the role is neither "tenantOne" nor "tenantTwo"
    };

    const handleShowConfirmation = async () => {
        let reqArr = getRefTriggerArray(buttonFor, tenantRole);
        const isFilled = await trigger(reqArr);
        if (isFilled || isEmailFieldValid()) {
            setShowConfirmation(true);
        }
    };

    // checking if the email field is empty
    const disableEmptyEmail = !isEmailFieldValid();

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    };

    return (
        <>
            <Stack mt={2}>
                <Box
                    p={1}
                    sx={{ border: "1px dashed #68437e", borderRadius: 5 }}
                >
                    <Stack
                        direction="row"
                        spacing={1} // Verkleinert den Abstand zwischen Icon und Text
                        justifyContent="center"
                        alignItems="center" // Zentriert Icon und Text vertikal
                    >
                        <InfoOutlinedIcon
                            sx={{ color: "#68437e", fontSize: 32 }}
                        />
                        <Stack>
                            <Typography variant="h6">{heading}</Typography>
                            <Typography variant="body1">{bodyText}</Typography>
                        </Stack>
                    </Stack>

                    <Stack direction="row" mt={2} justifyContent={"center"}>
                        <Box>
                            <ArrowButton
                                disabled={isDisabled || disableEmptyEmail}
                                onClick={handleShowConfirmation}
                                variant="contained"
                            >
                                <span>
                                    {/*
                                   <span>{buttonTextOne}</span>&nbsp;
                                   */}
                                    <span>
                                        <strong>{buttonTextTwo}</strong>
                                    </span>
                                    &nbsp;
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontStyle: "italic",
                                            fontSize: 16,
                                            textAlign: "center",
                                            display: "block",
                                            color: "#ffffff",
                                        }}
                                    >
                                        {t(getTooltipTitle(status))}
                                    </Typography>
                                </span>
                            </ArrowButton>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            <Dialog open={showConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle>Send Reference</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`${t("do_you_want_to_request_a_reference")}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmation}>{t("no")}</Button>
                    <Button
                        onClick={handleSubmitAllData}
                        autoFocus
                        variant="contained"
                    >
                        {t("yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
