import PropTypes from "prop-types";

//* react-hook-form
import { Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";

//* mui-x-grid imports
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

// import "moment/locale/de";

function ControllerDateField({
    label,
    control,
    name,
    errorFlag,
    errorText,
    openTo,
    shouldDisableYear,
    disableFuture,
    lang = "en",
    textFieldProps,
    datePickerProps,
}) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => {
                return (
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={lang}
                    >
                        <DatePicker
                            slotProps={{
                                textField: {
                                    size: "small",
                                    variant: "filled",
                                    hiddenLabel: true,
                                    error: errorFlag,
                                    helperText: errorText,
                                    ...textFieldProps,
                                },
                            }}
                            inputFormat={"DD.MM.yyy"}
                            label={label}
                            // disableFuture={disableFuture}
                            // shouldDisableYear={shouldDisableYear}
                            // openTo={openTo}
                            value={value}
                            onChange={(value) => onChange(value)}
                            renderInput={(params) => (
                                <TextField {...params} variant="filled" />
                            )}
                            {...datePickerProps}
                        />
                    </LocalizationProvider>
                );
            }}
        />
    );
}

ControllerDateField.propTypes = {
    control: PropTypes.any,
    disableFuture: PropTypes.bool,
    errorFlag: PropTypes.bool,
    errorText: PropTypes.string,
    label: PropTypes.string,
    lang: PropTypes.string,
    name: PropTypes.string,
    openTo: PropTypes.string,
    shouldDisableYear: PropTypes.bool,
    textFieldProps: PropTypes.object,
    datePickerProps: PropTypes.object,
};

export default ControllerDateField;
