// Account Routes Flat Seeker , New Tenant , Property Manager
import { Suspense, lazy } from "react";
import Loading from "../component/loading/Loading";

//common
const Chats = lazy(() => import("../pages/Dashboard/Chat/Chat"));
const Settings = lazy(() => import("../pages/Dashboard/Settings/Settings"));
const Support = lazy(() => import("../pages/Dashboard/Support/Support"));
const Notification = lazy(() =>
    import("../pages/Dashboard/Notification/Notification")
);

// fs
const SmartDossier = lazy(() =>
    import("../pages/Dashboard/Flat-seeker/SmartDossier")
);

const AppliedFlats = lazy(() =>
    import("../pages/Dashboard/Flat-seeker/AppliedFlats")
);
const AppliedFlatsV1 = lazy(() =>
    import("../pages/Dashboard/Flat-seeker/AppliedFlatsV1")
);

const FavoriteProperty = lazy(() =>
    import("../pages/Dashboard/Flat-seeker/FavoriteProperty")
);

const GoodMatches = lazy(() =>
    import("../pages/Dashboard/Flat-seeker/GoodMatches")
);

const SearchSubscription = lazy(() =>
    import("../pages/Dashboard/Flat-seeker/SearchSubscription")
);
const SearchSubscriptionForm = lazy(() =>
    import("../component/forms/search-subscription-form/SearchSubscriptionForm")
);
// nt
const NtDashboard = lazy(() =>
    import("../pages/Dashboard/New-tenant/NtDashboard")
);
const ManageRequest = lazy(() =>
    import("../pages/Dashboard/New-tenant/ManageRequest")
);
const MyFlats = lazy(() => import("../pages/Dashboard/New-tenant/MyFlats"));

//pm
const Agents = lazy(() => import("../pages/Dashboard/Property-manager/Agents"));
const Appointment = lazy(() =>
    import("../pages/Dashboard/Property-manager/Appointment")
);
const Clients = lazy(() =>
    import("../pages/Dashboard/Property-manager/Clients")
);
const NfClients = lazy(() =>
    import("../pages/Dashboard/Property-manager/NfClients")
);

const PmDashboard = lazy(() =>
    import("../pages/Dashboard/Property-manager/PmDashboard")
);
const PmFlatList = lazy(() =>
    import("../pages/Dashboard/Property-manager/PmFlatList")
);
const PmManageRequest = lazy(() =>
    import("../pages/Dashboard/Property-manager/PmManageRequest")
);

const ACCOUNT_ROUTES = [
    {
        id: "nf-smart-dossier",
        path: "fs/smart-dossier",
        element: (
            <Suspense fallback={<Loading />}>
                <SmartDossier />
            </Suspense>
        ),
    },
    {
        id: "nf-applied-flats",
        path: "fs/applied-flats",
        element: (
            <Suspense fallback={<Loading />}>
                <AppliedFlats />
            </Suspense>
        ),
    },
    {
        id: "nf-applied-flats",
        path: "fs/applied-flats-v1",
        element: (
            <Suspense fallback={<Loading />}>
                <AppliedFlatsV1 />
            </Suspense>
        ),
    },
    {
        id: "nf-favorite",
        path: "fs/favorites",
        element: (
            <Suspense fallback={<Loading />}>
                <FavoriteProperty />
            </Suspense>
        ),
    },
    {
        id: "",
        path: "",
        element: null,
    },
    {
        id: "nf-good-matches",
        path: "fs/good-matches",
        element: (
            <Suspense fallback={<Loading />}>
                <GoodMatches />
            </Suspense>
        ),
    },
    /*
    {


    {
        id: "nf-search-subscription",
        path: "fs/create-alert",
        element: (
            <Suspense fallback={<Loading />}>
                <SearchSubscription />
            </Suspense>
        ),
    },
    {
        id: "nf-search-subscription-form",
        path: "fs/create-property-alert",
        element: (
            <Suspense fallback={<Loading />}>
                <SearchSubscriptionForm />
            </Suspense>
        ),
    },
    */
    /*
    {
        id: "nf-chat",
        path: "chats",
        element: (
            <Suspense fallback={<Loading />}>
                <Chats />
            </Suspense>
        ),
    },*/
    {
        id: "nf-support",
        path: "support",
        element: (
            <Suspense fallback={<Loading />}>
                <Support />
            </Suspense>
        ),
    },
    {
        id: "nf-settings",
        path: "settings",
        element: (
            <Suspense fallback={<Loading />}>
                <Settings />
            </Suspense>
        ),
    },

    {
        id: "nf-notifications",
        path: "notifications",
        element: (
            <Suspense fallback={<Loading />}>
                <Notification />
            </Suspense>
        ),
    },
    {
        id: "nf-nt-dashboard",
        path: "nt/dashboard",
        element: (
            <Suspense fallback={<Loading />}>
                <NtDashboard />
            </Suspense>
        ),
    },
    {
        id: "nf-nt-flats",
        path: "fs/flats",
        element: (
            <Suspense fallback={<Loading />}>
                <MyFlats />
            </Suspense>
        ),
    },
    {
        id: "nf-nt-flat-request",
        path: "nt/flat-requests/:propertyId",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageRequest />
            </Suspense>
        ),
    },
    {
        id: "agents",
        path: "pm/agents",
        element: (
            <Suspense fallback={<Loading />}>
                <Agents />
            </Suspense>
        ),
    },
    {
        id: "appointment",
        path: "pm/appointments",
        element: (
            <Suspense fallback={<Loading />}>
                <Appointment />
            </Suspense>
        ),
    },
    {
        id: "clients",
        path: "pm/clients",
        element: (
            <Suspense fallback={<Loading />}>
                <Clients />
            </Suspense>
        ),
    },
    {
        id: "nf-clients",
        path: "pm/nf-clients",
        element: (
            <Suspense fallback={<Loading />}>
                <NfClients />
            </Suspense>
        ),
    },
    {
        id: "pm-dashboard",
        path: "pm/dashboard",
        element: (
            <Suspense fallback={<Loading />}>
                <PmDashboard />
            </Suspense>
        ),
    },
    {
        id: "pm-flats",
        path: "pm/flats",
        element: (
            <Suspense fallback={<Loading />}>
                <PmFlatList />
            </Suspense>
        ),
    },
    {
        id: "pm-manage-request",
        path: "pm/pm-flat-requests/:propertyId",
        element: (
            <Suspense fallback={<Loading />}>
                <PmManageRequest />
            </Suspense>
        ),
    },
    {
        id: "nf-chat",
        path: "chats",
        element: (
            <Suspense fallback={<Loading />}>
                <Chats />
            </Suspense>
        ),
    },
];
export default ACCOUNT_ROUTES;
