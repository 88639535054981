import React from "react";

//* cookie

//* using toast for success and error

//* Translation
import { useTranslation } from "react-i18next";

//* Custom loading components

//* libraries imports

//* store imports

//* api calls

//* image imports

//* mui components import
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

//* mui icons imports
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../hooks/responsive/useResponsive";
import LanguageSelector from "../language-selector/LanguageSelector";
import LoggedInMenu from "../logged-in-menu/LoggedInMenu";
import NotificationPopup from "./NotificationPopup";

const drawerWidth = 260;

function DashboardHeader({ handleDrawerToggle, role }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isBelowSm } = useResponsive();

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
                backgroundColor: "#fff",
                minHeight: 90,
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            }}
        >
            <Box
                sx={{
                    minHeight: "85px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    rowGap: 1,
                    px: 3,
                }}
            >
                <Box display={"flex"} alignItems={"center"}>
                    {!isBelowSm && (
                        <>
                            <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                {t("property_you_are_registered_as")}
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: theme.palette.primary.main,
                                    mr: 4,
                                }}
                            >
                                {t(role)}
                            </Typography>
                        </>
                    )}
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                {/* Header Settings Start */}
                <Stack
                    direction={"row"}
                    columnGap={{
                        xs: 1,
                        md: 2,
                    }}
                >
                    <LoggedInMenu colorPrimary />
                    <NotificationPopup />
                    <LanguageSelector buttonSxProps={{ padding: 0 }} />
                </Stack>
            </Box>
        </AppBar>
    );
}

export default DashboardHeader;
