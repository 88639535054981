import React from 'react';

const CommonEditFormContext = React.createContext();

export const EditFormProviderSegment = ({ children, commonProps }) => (
    <CommonEditFormContext.Provider value={commonProps}>
        {children}
    </CommonEditFormContext.Provider>
);
export const useCommonEditFormContext = () => React.useContext(CommonEditFormContext);

export default CommonEditFormContext;