import * as yup from "yup";

const step0Section5Schema = yup.object().shape({
    tenantTwoSmokePreference: yup
        .string()
        .nullable()
        .when("isTenantTwoSmoker", {
            is: "yes",
            then: yup
                .string()
                .nullable()
                .required("please_choose_your_preference"),
        }),
    isTenantTwoPlayingAtHome: yup
        .string()
        .nullable()
        .when("tenantTwoHasMusicalInstrument", {
            is: "yes",
            then: yup.string().nullable().required("please_select_any"),
        }),
    tenantTwoTypeOfVehicle: yup
        .string()
        .nullable()
        .when("hasTenantTwoVehicle", {
            is: "yes",
            then: yup
                .string()
                .nullable()
                .required("please_confirm_the_type_of_vehicle_you_have"),
        }),
    isTenantTwoNeedParking: yup
        .string()
        .nullable()
        .when(["hasTenantTwoVehicle", "tenantTwoTypeOfVehicle"], {
            is: (hasTenantTwoVehicle, tenantTwoTypeOfVehicle) =>
                hasTenantTwoVehicle === "yes" && tenantTwoTypeOfVehicle !== "",
            then: yup
                .string()
                .nullable()
                .required("please_confirm_the_need_of_parking"),
            otherwise: yup.string().nullable(),
        }),
    tenantTwoNumberOfParkingSpace: yup
        .string()
        .when(
            [
                "hasTenantTwoVehicle",
                "tenantTwoTypeOfVehicle",
                "isTenantTwoNeedParking",
            ],
            {
                is: (
                    hasTenantTwoVehicle,
                    tenantTwoTypeOfVehicle,
                    isTenantTwoNeedParking
                ) =>
                    hasTenantTwoVehicle === "yes" &&
                    tenantTwoTypeOfVehicle !== "" &&
                    isTenantTwoNeedParking === "yes",
                then: yup
                    .string()
                    .required("please_confirm_the_need_of_parking"),
                otherwise: yup.string(),
            }
        ),
    tenantTwoPet: yup
        .string()
        .nullable()
        .when("hasTenantTwoPet", {
            is: "yes",
            then: yup
                .string()
                .nullable()
                .required("please_confirm_pet_you_have"),
        }),
});

export default step0Section5Schema;
