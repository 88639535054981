import React, { useEffect } from "react";

// material component import
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { Controller, useFormContext, useForm } from "react-hook-form";

import {
    ControllerSelectField,
    ControllerSliderField,
    ControllerSliderTextField,
} from "../../mui-components/MuiDossierFormComponents";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { slideUp } from "../../../utils/animation/Animation";
import { ROOMS } from "../../../options/propertyOptions";
import ControllerToggleButtonsComponent from "../../../component/forms/smart_dossier_form/dossier_components/ControllerToggleButtonsComponent";
import ControllerSliderComponent from "../../../component/forms/smart_dossier_form/dossier_components/ControllerSliderComponent";

function MaxRentForm() {
    const { t } = useTranslation();

    const { control, watch, setValue } = useFormContext();
    const roomsFrom = watch("roomsFrom");
    const roomsTo = watch("roomsTo");

    useEffect(() => {
        if (roomsTo <= roomsFrom) {
            setValue("roomsTo", roomsFrom + 0.5);
        }
    }, [roomsFrom, roomsTo, setValue]);

    //    const watchRooms = watch("rooms");

    //    const handleClick = (room) => {
    //       setValue("rooms", room);
    //    };

    return (
        <Grid
            container
            rowSpacing={2}
            component={motion.div}
            variants={slideUp}
            initial="hidden"
            animate="visible"
        >
            <Grid item xs={12}>
                <Typography variant="subtitle1" mb={1}>
                    {t("what_do_you_want_to_spend_on_your_rent")}
                </Typography>

                <ControllerSliderField
                    control={control}
                    name="maxRent"
                    label={t("rent")}
                    min={400}
                    max={8000}
                    step={50}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle1" mb={1}>
                    {t("how_much_rooms_your_are_searching_for") + "?"}
                </Typography>

                <Grid item container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <ControllerSliderField
                            control={control}
                            name="roomsFrom"
                            label={t("from")}
                            min={1}
                            max={12}
                            step={0.5}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ControllerSliderField
                            control={control}
                            name="roomsTo"
                            label={t("to")}
                            min={1}
                            max={12}
                            step={0.5}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MaxRentForm;
