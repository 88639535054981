import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const PrivacyPolicy = () => {
  const h1Style = {
    paddingBottom: "15px",
  };
  const h2Style = {
    paddingBottom: "15px",
  };

  return (
    <Container maxWidth="md" sx={{ paddingTop: "80px" }}>
      <Grid container spacing={6}>
        <Grid item sx={{ paddingBottom: "20px" }}>
          <Typography variant="h3" sx={h1Style}>
            Datenschutzerklärung der nextflat AG
          </Typography>
          <Typography variant="subtitle2">
          Version vom 20. Juli 2023
          <br /><br />
          In dieser Datenschutzerklärung erläutern wir, die Nextflat AG, Riedmattstrasse 9, 8153
          Rümlang, CHE-417.247.189 (nachfolgend «Nextflat», «wir» oder «uns»), wie wir
          Personendaten erheben und bearbeiten, wenn Sie die Webseite bzw. die Plattform nextflat.ch
          oder unsere Apps (nachfolgend gesamthaft «Webseite»), unsere Dienstleistungen (z.B. im
          Zusammenhang mit der Erstellung des Bewerbungsdossier «Smartdossier») oder Produkte
          beziehen. Das ist keine abschliessende Beschreibung. Gegebenenfalls werden wir Sie durch
          eine rechtzeitige schriftliche Mitteilung über zusätzliche, nicht in dieser Datenschutzerklärung
          erwähnte Bearbeitungsaktivitäten informieren, z.B. in Einwilligungserklärungen im Rahmen der
          Registrierung des Benutzerkontos, Allgemeinen Geschäftsbedingungen, Formularen und
          Hinweisen. Unter Personendaten werden alle Angaben verstanden, die sich auf eine bestimmte
          oder bestimmbare natürliche Person beziehen.
          <br /><br />
          Wenn Sie uns Personendaten anderer Personen (z.B. Familienmitglieder, Arbeitgeber, aktuelle
          oder frühere Vermieter sowie anderweitige Personen zwecks Einholung von
          Referenzauskünften) übermitteln, stellen Sie bitte sicher, dass diese Personen die vorliegende
          Datenschutzerklärung kennen und teilen Sie uns deren Personendaten nur mit, wenn Sie dies
          dürfen und wenn diese Personendaten korrekt sind.
          <br /><br />
          Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung
          («DSGVO») und das Schweizer Datenschutzgesetz («DSG») ausgelegt. Ob und inwieweit
          diese Gesetze anwendbar sind, hängt jedoch vom Einzelfall ab.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Identität und Kontaktangaben des Verantwortlichen
          </Typography>
          <Typography variant="body1">
          Verantwortlich für die Datenbearbeitungen, welche in dieser Datenschutzerklärung dargelegt werden ist: 
          <b> Nextflat AG</b>, Riedmattastrasse 9, 8153 Rümlang, taman@nextflat.ch
          <br /><br />
          Wenn Sie datenschutzrechtliche Anliegen haben, können Sie sich an die vorgenannte Adresse wenden.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Erhebung und Bearbeitung von Personendaten
          </Typography>
          <Typography variant="body1">
            Wir bearbeiten in erster Linie diejenigenPersonendaten, die wir im
            Rahmen der Geschäftsbeziehung mit Ihnenbzw. Ihrem Arbeitgeber
            erhaltenoder die wir beim Betrieb unserer Websites (Plattform), Apps
            und allfälligen weiteren Anwendungen von Ihnen als Nutzer erheben.
            <br />
            Soweit dies erlaubt ist, entnehmen wir auch öffentlich zugänglichen
            Quellen (z.B. Betreibungsregister, Grundbücher, Handelsregister,
            Presse, Internet) gewisse Daten oder erhalten solche von Behördenund
            sonstigen Dritten (wie z.B. Vermieter oder Arbeitgeber, welche Sie
            uns im Rahmen der Nutzung unserer Dienstleistungenzwecks Einholung
            von Referenzen angegeben haben). Nebst den Daten von Ihnen, die Sie
            uns direktbekanntgeben, umfassen die Kategorien von Personendaten,
            die wir von Dritten über Sie erhalten, insbesondere Angaben aus
            öffentlichen Registern, Angaben, die wir im Zusammenhang mit
            behördlichen und gerichtlichen Verfahren erfahren, Angaben im
            Zusammenhang mit ihren beruflichen Funktionen und Aktivitäten (damit
            wir z.B. diese Angaben in das Smartdossier integrieren und Sie sich
            damit auf ein Wohnungsinserat bewerben können), Angaben über Sie in
            Korrespondenz und Besprechungen mit Dritten, Bonitätsauskünfte
            (soweit wir mit Ihnen persönlich Geschäfte abwickeln), Angaben über
            Sie, die uns Personen aus Ihrem Umfeld (Familie, Arbeitgeber,
            aktuelle und frühere Vermieter, etc.) bekanntgeben, damit wir
            Verträge mit Ihnen oder unter Einbezug von Ihnen prüfen,
            abschliessen oder abwickeln sowie die bei uns erfragten
            Dienstleistungen erbringen können (z.B. Referenzen, Ihre Adresse für
            Lieferungen, Vollmachten, Angaben von Banken, Versicherungen,
            Vertriebs- und anderen Vertragspartnern von uns zur Inanspruchnahme
            oder Erbringung von Leistungen durch Sie (z.B. erfolgte Zahlungen,
            erfolgte Käufe), Angaben aus Medien und Internet zu Ihrer Person
            (soweit dies im konkreten Fall angezeigt ist, z.B. im Rahmen einer
            Bewerbung, Presseschau, Marketing/Verkauf, etc.), Ihre Adressen und
            ggf. Interessen und weitere soziodemographische Daten (für
            personalisiertes Marketing), Daten im Zusammenhang mit der Benutzung
            unserer Websites, Apps und weiteren Anwendungen (z.B. IP-Adresse,
            MAC-Adresse des Smartphones oder Computers, Angaben zu Ihrem Gerät
            und Einstellungen, Cookies, Datum und Zeit des Besuchs, abgerufene
            Seiten und Inhalte, benutzte Funktionen, verweisende Website,
            Standortangaben).
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Zwecke der Datenbearbeitung und Rechtsgrundlagen
          </Typography>
          <Typography variant="body1">
            Wir verwenden IhrePersonendaten in erster Linie, um unsere Verträge
            mit Ihnen (als Kunde bzw. Nutzer unserer Dienstleistungen) bzw.
            mitIhrem Arbeitgeber (falls Sie für diesen mit uns
            interagieren)abzuschliessen und abzuwickeln sowie die erfragten
            Dienstleistungen zu erbringen.
            <br />
            <br />
            Damit wir die von Ihnen gewünschten Dienstleistungen im Zusammenhang
            mit dem «Smartdossier» erbringen können, müssen Sie uns insbesondere
            Angaben zu ihrer Person (Name, Vorname, Geburtsdatum, Adresse), zu
            ihrer beruflichen Situation (Arbeitgeber, Lohn oder Lohnklasse,
            Dauer der Anstellung, Position) und zu ihrer familiären Situation
            (Zivilstand, Anzahl Kinder, Anzahl Personen im Haushalt, aktuelle
            und zukünftig gewünschte Wohnsituation) mitteilen. Ebenfalls sind
            wir auf Informationen rund um ihre Wohnungssituation angewiesen
            (Angaben zum aktuellen und früheren Vermieter; zur aktuellen
            Wohnsituation, zur gesuchten Immobilie).Mittels einer separaten
            Vollmachtermächtigen Sie uns zur Einholung eines
            Betreibungsregisterauszugs, welcher Auskunft über aktuelle oder
            ältere Betreibungen sowie Verlustscheine gibt.
            <br />
            <br />
            Damit wir Ihre Angaben zum Arbeitgeber sowie zu den aktuellen und
            früheren Vermietern überprüfen und die Dienstleistung «Smartdossier»
            erbringen können, kontaktieren wir möglicherweise die von Ihnen
            angegebenen Personen. Diese Kontaktaufnahme kann schriftlich,
            telefonischsowie mittels eines vorformulierten Fragebogens oder
            anhand von vorformulierten E-Mails erfolgen. Die Rückmeldung durch
            die kontaktieren Personen ist für Sie im Smartdossier ersichtlich .{" "}
            <br />
            <br />
            Damit die Chancen auf eine erfolgreiche Wohnungssuche gesteigert
            werden können, ist Nextflat berechtigt, Ihr Profil pseudonymisiert,
            d.h. der Empfänger der Daten kann keine Rückschlüsse auf Ihre Person
            ziehen, potenziellen Vermietern bekanntzugeben. Das Profil enthält
            Angaben zu Ihrem aktuellen Wohnort,Alter, Berufund Lohnklasse sowie
            Angaben zur Immobiliensuche (zukünftiger Wohnort, Anzahl Zimmer und
            Personen, usw.). Ist der Vermieter an Ihrem Profil interessiert,
            werden wir ihm mitIhrer vorgängigen Zustimmung ihren Namen,
            Vornamen, die Mail-Adresse und Telefonnummer zwecks Kontaktaufnahme
            bekanntgeben. Ihre pseudonymisierten Daten können auch im Rahmen
            eines sog. «Machting-Verfahrens» verwendet werden, in dem anhand von
            Nextflat festgelegten Kriterien automatisiert oder mittels Einsatzes
            von künstlicher Intelligenz überprüft wird, welche Nutzer zu welchem
            potentiellen Vermieter (Inserenten) passen würden und diese
            pseudonymisiertenProfile dem Vermieter vorgeschlagen werden. Bevor
            dem Vermieter Ihre identifizierenden Daten (Name, Vorname etc.)
            zugestellt werden, wird Ihre Zustimmung zur Kontaktaufnahme
            vorgängig eingeholt. <br />
            <br />
            Sie nehmen zur Kenntnis, dass beim unentgeltlichen Smartdossier
            «Premium Account Sponsoring» die von ihnen vorgängig anhand der
            Branche ausgewählten Dienstleister mit Ihnen in Kontakt treten
            dürfen, sofern Sie vorgängig der Kontaktaufnahme durch die
            Dienstleister zugestimmt haben. Zur individuellen Zusammenstellung
            eines passenden Branchenangebots, wird Nextflat die Angaben aus dem
            Smartdossier des Nutzers berücksichtigen. Je nach Dienstleister
            besteht die Möglichkeit, dass auf pseudonymisierter Basis, welche
            aus Sicht des Dienstleisters keine Rückschlüsse auf den Nutzer
            erlaubt, erste Informationen an die Dienstleister übermittelt werden
            (z.B. Grösse der aktuellen Immobilie, aktueller Wohnort, Anzahl
            Personen im Haushalt, Einkommensklasse) oder gestützt auf die
            ausgewerteten Informationen nur bestimmte Dienstleister gegenüber
            bestimmten Nutzern angezeigt werden. Sofern Sie interessiert sind,
            können Sie den Dienstleister zur Kontaktaufnahme ermächtigen und ihm
            Ihre genauen Kontaktangaben mitteilen. Sie nehmen zur Kenntnis, dass
            ihnen der Dienstleister auch nach Löschung des Benutzerkontos eine
            erste unverbindliche Offerte, basierend auf anonymisierten Daten,
            zustellen kann . <br />
            <br />
            Zur Erbringung unserer Dienstleistungen im Rahmen des Smartdossiers
            (z.B. Hilfestellung beim Erstellen des Bewerbungsdossiers) können
            wir auch auf Verfahren der künstlichen Intelligenz zurückgreifen und
            solche Methoden z.B. zur Erstellung eines Bewerbungsschreibens
            verwenden.
            <br />
            <br />
            Darüber hinaus bearbeiten wir Personendaten von Ihnen und weiteren
            Personen, soweit erlaubt und es uns als angezeigt erscheint, auch
            für folgende Zwecke, an denen wir (und zuweilen auch Dritte) ein dem
            Zweck entsprechendes berechtigtes Interesse haben:
          </Typography>
          <Box
            component={"ul"}
            sx={{ padding: "30px 0 40px 30px", color: "#68437E" }}
          >
            <Box component={"li"}>
              <Typography variant="body2">
                Angebot und Weiterentwicklung unserer Angebote, Dienstleistungen
                und Webseiten, Apps und weiteren Plattformen, auf welchen wir
                präsent sind;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Kommunikation mit Dritten(Arbeitgeber, aktueller und früherer
                Vermieter, mögliche Dienstleister)und Bearbeitung derer
                Anfragen;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Prüfung und Optimierung von Verfahren zur Bedarfsanalyse zwecks
                direkter Kundenansprache sowie Erhebung von Personendaten aus
                öffentlich zugänglichen Quellen zwecks Kundenakquisition;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Werbung und eigenes Marketing (einschliesslich Durchführung von
                Anlässen), soweit Sie der Nutzung Ihrer Daten nicht
                widersprochen haben;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Vertragsabwicklung, Abwicklung und Führung von Smartdossiers
                (Bewerbungsdossiers), Vermittlung von Wohnungen;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Markt- und Meinungsforschung;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Geltendmachung rechtlicher Ansprüche und Verteidigung in
                Zusammenhang mit rechtlichen Streitigkeiten und behördlichen
                Verfahren;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Verhinderung und Aufklärung von Straftaten und sonstigem
                Fehlverhalten (z.B. Durchführung interner Untersuchungen,
                Datenanalysen zur Betrugsbekämpfung);
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Gewährleistungen unseres Betriebs, insbesondere der IT, unserer
                Websites, Apps und weiteren Plattformen;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Videoüberwachungen zur Wahrung des Hausrechts und sonstige
                Massnahmen zur IT-, Gebäude- und Anlagesicherheit und Schutz
                unserer Mitarbeiter und weiteren Personen und uns gehörenden
                oder anvertrauten Werte (wie z.B. Zutrittskontrollen,
                Besucherlisten, Netzwerk- und Mailscanner,
                Telefonaufzeichnungen);
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Kauf und Verkauf von Geschäftsbereichen, Gesellschaften oder
                Teilen von Gesellschaften und andere gesellschaftsrechtliche
                Transaktionen und damit verbunden die Übertragung von
                Personendaten sowie Massnahmen zur Geschäftssteuerung und soweit
                zur Einhaltung gesetzlicher und regulatorischer Verpflichtungen
                sowie interner Vorschriften von Nextflat AG.
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1">
            Sofern die sie betreffenden Daten nicht in pseudonymisierter Form an
            potenzielle Vermieter oder Dienstleister (letztere im Rahmen des
            «Premium Accounts Sponsoring») weitergegeben werden, werden wir
            vorgängig ihre explizite Zustimmung zur Datenweitergabe einholen.
            Zur Anzeige von für Sie interessanten Dienstleister, können wir Ihre
            Informationen aus dem Smartdossier auswerten. Diesbezüglich können
            wir auch Verfahren der künstlichen Intelligenz zur Anwendung
            bringen. <br />
            <br />
            Soweit Sie uns eine Einwilligung zur Bearbeitung Ihrer Personaldaten
            für bestimmte Zwecke erteilt haben (zum Beispiel bei Ihrer Anmeldung
            zum Erhalt von Newslettern oder Durchführung eines
            Background-Checks), bearbeiten wir Ihre Personendaten im Rahmen und
            gestützt auf diese Einwilligung, soweit wir keine andere
            Rechtsgrundlage haben und wir eine solche benötigen. Eine erteilte
            Einwilligung kann jederzeit widerrufen werden, was jedoch keine
            Auswirkung auf bereits erfolgte Datenbearbeitungen hat.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Cookies / Tracking und andere Technologien im Zusammenhang mit der
            Nutzung unserer
          </Typography>
          <Typography variant="body1">
            Falls permanente Cookies oder sonst Tracking-Techniken eingesetzt
            werden (Text je nach Situation anpassen):
            <br />
            <br />
            Wir setzen auf unseren Websites [und Apps]typischerweise
            "Cookies"und vergleichbare Techniken ein, mit denen Ihr Browser oder
            Ihr Gerät identifiziert werden kann. EinCookie ist eine kleine
            Datei, die an Ihren Computer gesendet bzw. vom verwendeten
            Webbrowser automatisch auf Ihrem Computer oder mobilen Gerät
            gespeichert wird, wenn Sie unsere Website besuchen[oder App
            installieren]. Wenn Sie diese Website erneut aufrufen[oder unsere
            App benutzen], können wir Sie so wiedererkennen, selbst wenn wir
            nicht wissen, wer Sie sind. Neben Cookies, die lediglich während
            einer Sitzung benutzt und nach Ihrem Website-Besuch gelöscht werden
            ("Session Cookies"), können Cookies auch benutzt werden, um
            Nutzereinstellungen und andere Informationen über eine bestimmte
            Zeit (z.B. zwei Jahre) zu speichern ("permanente Cookies"). Sie
            können Ihren Browser jedoch so einstellen, dass er Cookies
            zurückweist, nur für eine Sitzung speichert oder sonst vorzeitig
            löscht. Die meisten Browser sind so voreingestellt, dass Sie Cookies
            akzeptieren. Wir nutzen permanente Cookies [damit Sie
            Benutzereinstellungen speichern (z.B. Sprache, Autologin),][damit
            wir besser verstehen, wie Sie unsere Angebote und Inhalte nutzen,][
            und][damit wir Ihnen auf Sie zugeschnittene Angebote und Werbung
            anzeigen können (was auch auf Websites von anderen Firmen geschehen
            kann; diese erfahren dann allerdings von uns nicht, wer Sie sind,
            falls wir selbst das überhaupt wissen, denn sie sehen nur, dass auf
            ihrer Website derselbe Nutzer ist, der auch bei uns auf einer
            bestimmten Seite war)]. [Gewisse der Cookies werden von uns gesetzt,
            gewisse auch von Vertragspartnern, mit denen wir zusammenarbeiten.]
            Wenn Sie Cookies sperren, kann es sein, dass gewisse
            Funktionalitäten (wie z.B. Sprachwahl, Warenkorb, Bestellprozesse)
            nicht mehr funktionieren.
            <br />
            <br />
            [Wir bauen in unserenNewslettern und sonstigen Marketing-E-Mails
            teilweise und soweit erlaubt auch sicht- und unsichtbare
            Bildelemente ein,durch deren Abruf von unseren Servern wir
            feststellen können, ob und wann Sie die E-Mail geöffnet haben, damit
            wir auch hier messen und besser verstehen können, wie Sie unsere
            Angebote nutzen und sie auf Sie zuschneiden können. Sie können dies
            in Ihrem E-Mail-Programm blockieren; die meisten sind so
            voreingestellt, dass Sie dies tun.]
            <br />
            <br />
            Mit der Nutzung unserer Websites[, Apps] und der Einwilligung in den
            Erhalt von Newslettern und anderen Marketing-E-Mails stimmen Sie dem
            Einsatz dieser Techniken zu. Wollen Sie dies nicht, dann müssen Sie
            Ihren Browser bzw. Ihre E-Mail-Programm entsprechend einstellen[,
            oder die App deinstallieren, sofern sich dies nicht über die
            Einstellungen anpassen lässt.]]
            <br />
          </Typography>
          <Typography variant="body1">
            Falls Google Analytics oder andere Statistikdiensteeingesetzt
            werden, denen keine Personendaten (wie z.B. E-Mail-Adressen)
            übermittelt werden:
            <br />
            <br />
            Wir setzen auf unseren Websites mitunter Google Analytics oder
            vergleichbare Dienste ein. Dies ist eine Dienstleistung von Dritten,
            die sich in irgendeinem Land der Erde befinden können(im Falle von
            Google Analytics ist es Google Irland (mit Sitz in Irland), Google
            Irland stützt sich dabei auf Google LLC (mit Sitz in den USA) als
            Auftragsbearbeiter (beide «Google»), www.google.com), mit welcherwir
            die Nutzung der Website(nicht personenbezogen) messen und auswerten
            können. Hierzu werden ebenfalls permanente Cookies benutzt, die der
            Dienstleister setzt. Wir haben den Dienst so konfiguriert, dass die
            IP-Adressen der Besucher von Google in Europa vor einer
            Weiterleitung in die USA gekürzt werden und dadurch nicht
            zurückverfolgt werden können. Wir haben die Einstellungen
            «Datenweitergabe» und «Signals» ausgeschaltet. Obwohl wir annehmen
            können, dass die Informationen, welche wir mit Google teilen, für
            Google keine Personendaten sind, ist es möglich, dass Google von
            diesen Daten für eigene Zwecke Rückschlüsse auf die Identität der
            Besucher ziehen, personenbezogene Profile erstellen und diese Daten
            mit den Google-Konten dieser Personen verknüpfen kann.. Soweit Sie
            sich beim Dienstleister selbst registriert haben, kennt der
            Dienstleister Sie auch. Die Bearbeitung Ihrer Personendaten durch
            den Dienstleister erfolgt dann in Verantwortung des Dienstleisters
            nach dessen Datenschutzbestimmungen. Uns teilt der Dienstleister
            lediglich mit, wie unsere jeweilige Website genutzt wird (keine
            Angaben über Sie persönlich).
          </Typography>
          <Typography variant="body1">
            Falls Social Media Plug-ins eingesetzt werden:
            <br />
            <br />
            Wir setzen auf unseren Websites ferner sog. Plug-Ins von sozialen
            Netzwerken wie Facebook, Twitter, Youtube, Pinterest oder Instagram.
            Das ist für Sie jeweils ersichtlich (typischerweise über
            entsprechende Symbole). Wir haben diese Elemente so konfiguriert,
            dass sie standardmässig deaktiviert sind. Aktivieren Sie sie (durch
            Anklicken), können die Betreiber der jeweiligen sozialen Netzwerke
            registrieren, dass Sie auf unserer Website sind und wo und können
            diese Informationen für ihre Zwecke nutzen. Die Bearbeitung Ihrer
            Personendaten erfolgt dann in Verantwortung dieses Betreibers nach
            dessen Datenschutzbestimmungen. Wir erhalten von ihm keine Angaben
            zu Ihnen.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Profiling und automatisierte Einzelentscheidung
          </Typography>
          <Typography variant="body1">
            Als «Profiling» gilt die automatisierte Bearbeitung von
            Personendaten, um persönliche Aspekte zu analysieren oder Prognosen
            zu treffen, z.B. die Analyse von persönlichen Interessen,
            Präferenzen, Affinitäten und Gewohnheiten oder die Prognose eines
            voraussichtlichen Verhaltens. Anhand von Profiling können
            insbesondere Präferenzdaten abgeleitet werden. Profiling hilft uns
            bspw. dabei, unsere Angebote und Dienstleistungen zu verbessern und
            auf die individuellen Bedürfnisse des Nutzers anzupassen. Wir führen
            Profiling insbesondere für die unter Ziff. 3 genannten Zwecken
            durch, indem wirIhre persönlichen Angaben (Ziff. 2) automatisiert
            auswerten und Sie gestützt auf diese Auswertung einer
            «Nutzerkategorie» zuordnen. Aufgrund dieser Kategorie erfolgt die
            Auswahl von Dienstleistern, welche Ihnen im Rahmen der
            Dienstleistung «Premium Account Sponsoring» angezeigt werden. Das
            Profiling wird auch genutzt, um ihre Bewerbung geeigneten Vermietern
            anzuzeigen. Zudem nutzen wir Profiling,um Missbrauchs- und
            Sicherheitsrisiken festzustellen, oder um statistische Auswertungen
            vorzunehmen sowie für personalisiertes Marketing.
            <br />
            <br />
            Als «automatisierte Einzelentscheidung» gelten Entscheidungen, die
            vollständig automatisiert erfolgen, d.h. ohne menschlichen Einfluss,
            und die rechtliche Konsequenzen für die betroffene Person haben oder
            sie in anderer Weise erheblich beeinträchtigen. Das tun wir in der
            Regel nicht, werden Sie aber gesondert informieren, sollten wir in
            Einzelfällen automatisierte Einzelentscheidungen einsetzen. Sie
            haben dann die Möglichkeit, die Entscheidung durch einen Menschen
            überprüfen zu lassen, wenn Sie mit ihr nicht einverstanden sind.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Datenweitergabe und Datenübermittlung ins Ausland
          </Typography>
          <Typography variant="body1">
            Wir geben Ihre Personendaten im Rahmen unserer geschäftlichen
            Aktivitäten und der Zwecke gemäss Ziff. 3, soweit erlaubt und es uns
            als angezeigt erscheint, auch Dritten bekannt, sei es, weil sie
            diese für uns bearbeiten, sei es, weil sie sie für ihre eigenen
            Zwecke verwenden wollen. Dabei geht es insbesondere um folgende
            Stellen:
            <br />
            <br />
          </Typography>
          <Box
            component={"ul"}
            sx={{ padding: "30px 0 40px 30px", color: "#68437E" }}
          >
            <Box component={"li"}>
              <Typography variant="body2">
                Dienstleister von uns (wie z.B. Banken, Versicherungen,
                Inkassounternehmen, Wirtschaftsauskunfteien, Adressprüfer),
                einschliesslich Auftragsbearbeitern (wie z.B. IT-Provider);
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Geschäftspartner, Dienstleistungserbringer (insbesondere im
                Rahmen des Smartdossier «Premium Account Sponsoring», welche
                Dienstleistungen in Branchen anbieten, die einen Bezug zu
                Wohnungswechsel aufweisen (Versicherungen, Banken,
                Umzugsunternehmen, Reinigungsunternehmen, Immobilienplattformen,
                etc.);
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">Kunden;</Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Anbieter von Immobilien bzw. Immobilieninserenten (Vermieter);
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                in- und ausländische Behörden, Amtsstellen oder Gerichten;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                Erwerber oder Interessenten am Erwerb von Geschäftsbereichen,
                Gesellschaften oder sonstigen Teilen der Nextflat;
              </Typography>
            </Box>
            <Box component={"li"}>
              <Typography variant="body2">
                anderen Parteien in möglichen oder tatsächlichen
                Rechtsverfahren; alle gemeinsam Empfänger.
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1">
            Diese Empfänger sind teilweise im Inland, können aber irgendwo auf
            der Erde sein. Sie müssen insbesondere mit der Übermittlung Ihrer
            Daten in andere Länder Europas und den USA rechnen, wo sich die von
            uns benutzten Dienstleister befinden (wie z.B. [Microsoft], [SAP],
            [Amazon], [Salesforce.com]). <br />
            <br />
            Befindet sich ein Empfänger in einem Land ohne angemessenen
            gesetzlichen Datenschutz, verpflichten wir den Empfänger vertraglich
            zur Einhaltung des anwendbaren Datenschutzes (dazu verwenden wir die
            revidierten Standardvertragsklauseln der Europäischen Kommission,
            die hier: https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?
            abrufbar sind), soweit er nicht bereits einem gesetzlich anerkannten
            Regelwerk zur Sicherstellung des Datenschutzes unterliegt und wir
            uns nicht auf eine Ausnahmebestimmung stützen können. Eine Ausnahme
            kann namentlich bei Rechtsverfahren im Ausland gelten, aber auch in
            Fällen überwiegender öffentlicher Interessen oder wenn eine
            Vertragsabwicklung eine solche Bekanntgabe erfordert, wenn Sie
            eingewilligt haben oder wenn es sich um von Ihnen allgemein
            zugänglich gemachte Daten handelt, deren Bearbeitung Sie nicht
            widersprochen haben.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Dauer der Aufbewahrung von Personendaten
          </Typography>
          <Typography variant="body1">
            Wir verarbeiten und speichern Ihre Personendaten, solange es für die
            Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder
            sonst die mit der Bearbeitung verfolgten Zwecke erforderlich ist,
            d.h. also zum Beispiel für die Dauer der gesamten Geschäftsbeziehung
            (von der Anbahnung, Abwicklung bis zur Beendigung eines Vertrags)
            sowie darüber hinaus gemäss den gesetzlichen Aufbewahrungs- und
            Dokumentationspflichten. Dabei ist es möglich, dass Personendaten
            für die Zeit aufbewahrt werden, in der Ansprüche gegen unser
            Unternehmen geltend gemacht werden können und soweit wir anderweitig
            gesetzlich dazu verpflichtet sind oder berechtigte
            Geschäftsinteressen dies erfordern (z.B. für Beweis- und
            Dokumentationszwecke). Sobald Ihre Personendaten für die oben
            genannten Zwecke nicht mehr erforderlich sind, werden sie
            grundsätzlich und soweit möglich gelöscht oder anonymisiert.Für
            betriebliche Daten (z.B. Systemprotokolle, Logs), gelten
            grundsätzliche kürzere Aufbewahrungsfristen von zwölf Monaten oder
            weniger.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Datensicherheit
          </Typography>
          <Typography variant="body1">
            Wir treffen angemessene technische und organisatorische
            Sicherheitsvorkehrungen zum Schutz Ihrer Personendaten vor
            unberechtigtem Zugriff und Missbrauch wie etwa Schulungen der
            Mitarbeitenden, IT- und Netzwerksicherheitslösungen,
            Zugangskontrollen und –beschränkungen bezüglich der Einsichtnahme in
            die Daten für die Smartdossiers, Verschlüsselung von Datenträgern
            und Übermittlungen, Pseudonymisierung (z.B. im Rahmen der
            Datenweitergabe an mögliche Dienstleister), Kontrollen.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Pflicht zur Bereitstellung von Personendaten
          </Typography>
          <Typography variant="body1">
            Im Rahmen unserer Geschäftsbeziehung müssen Sie diejenigen
            Personendaten bereitstellen, die für die Aufnahme und Durchführung
            einer Geschäftsbeziehung und der Erfüllung der damit verbundenen
            vertraglichen Pflichten erforderlich sind (eine gesetzliche Pflicht,
            uns Daten bereitzustellen, haben Sie in der Regel nicht). Ohne diese
            Daten werden wir in der Regel nicht in der Lage sein, die von Ihnen
            gewünschten Dienstleistungen bezüglich des Smartdossier zu
            erbringen. Auch die Website kann nicht genutzt werden, wenn gewisse
            Angaben zur Sicherstellung des Datenverkehrs (wie z.B. IP-Adresse)
            nicht offengelegt werden. Ebenfalls ist es nicht möglich, das
            Benutzerkonto zu erstellen, wenn gewisse Angaben und Daten nicht
            übermittelt und offengelegt werden.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Rechte der betroffenen Person
          </Typography>
          <Typography variant="body1">
            Sie haben im Rahmen des auf Sie anwendbaren Datenschutzrechts und
            soweit darin vorgesehen (wie etwa im Falle der DSGVO) das Recht auf
            Auskunft, Berichtigung, Löschung, das Recht auf Einschränkung der
            Datenbearbeitung und sonst dem Widerspruch gegen unsere
            Datenbearbeitungen, insbesondere jener für Zwecke des
            Direktmarketings, des für Direktwerbung betriebenen Profilings und
            weiterer berechtigter Interessen an der Bearbeitung sowie
            aufHerausgabe gewisser Personendaten zwecks Übertragung an eine
            andere Stelle (sog. Datenportabilität). Bitte beachten Sie aber,
            dass wir uns vorbehalten, unsererseits die gesetzlich vorgesehenen
            Einschränkungen geltend zu machen, etwa wenn wir zur Aufbewahrung
            oder Bearbeitung gewisser Daten verpflichtet sind, daran ein
            überwiegendes Interesse haben (soweit wir uns darauf berufen dürfen)
            oder sie für die Geltendmachung von Ansprüchen benötigen.Falls für
            Sie Kosten anfallen, werden wir Sie vorab informieren. Über die
            Möglichkeit, Ihre Einwilligung zu widerrufen, haben wir bereits in
            Ziff. 3 informiert. Beachten Sie, dass die Ausübung dieser Rechte im
            Konflikt zu vertraglichen Abmachungen stehen kann und dies Folgen
            wie z.B. die vorzeitige Vertragsauflösung oder Kostenfolgen haben
            kann. Wir werden Sie diesfalls vorgängig informieren, wo dies nicht
            bereits vertraglich geregelt ist.
            <br />
            <br />
            Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre
            Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo
            Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann).
            Zur Geltendmachung Ihrer Rechte können Sie uns unter der in Ziffer
            1angegebenen Adresse kontaktieren.
            <br />
            <br />
            Jede betroffene Person hat überdies das Recht, ihre Ansprüche
            gerichtlich durchzusetzen oder bei der zuständigen
            Datenschutzbehörde eine Beschwerde einzureichen. Die zuständige
            Datenschutzbehörde der Schweiz ist der Eidgenössische Datenschutz-
            und Öffentlichkeitsbeauftragte (http://www.edoeb.admin.ch).
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            Änderungen
          </Typography>
          <Typography variant="body1">
            Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung
            anpassen. Es gilt die jeweils aktuelle, auf unserer Website
            publizierte Fassung. Soweit die Datenschutzerklärung Teil einer
            Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer
            Aktualisierung über die Änderung per E-Mail oder auf andere
            geeignete Weise informieren.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrivacyPolicy;
