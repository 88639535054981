import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function addProfileDocument(formData) {
  return Axios.post("/users/upload_dossier_documents", formData);
}

export default function useAddProfileDocument() {
  return useMutation(addProfileDocument);
}
