export const initialState = {
    token: null,
    user_id: null,
    user_email: null,
    user_role: null,
};

export const actionTypes = {
    SET_USER: "SET_USER",
    REMOVE_USER: "REMOVE_USER",
};

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                token: action.token,
                user_id: action.user_id,
                user_email: action.user_email,
                user_role: action.user_role,
            };
        case actionTypes.REMOVE_USER:
            return {
                ...state,
                token: null,
                user_id: null,
                user_email: null,
                user_role: null,
            };
        default:
            return state;
    }
};

export default reducer;
