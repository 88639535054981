import React from "react";

//* custom components
import { Img } from "../../../img/Img";

//* image imports
import { profilePlaceholder } from "../../../../assets/imgImport";

//* mui components imports
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function getFullName(firstName, lastName) {
    if (firstName && lastName) {
        return firstName + " " + lastName;
    } else if (firstName || lastName) {
        return firstName || lastName;
    } else {
        return "N/A";
    }
}

function isValueEmpty(value) {
    if (
        value === "" ||
        value === null ||
        value === undefined ||
        value === "null" ||
        value === "undefined"
    )
        return true;
    if (typeof value === "string" && value.trim() === "") return true;
    return false;
}

function AdministrationDetail({
    adminOrOwnerName,
    contactPersonEmail,
    contactPersonMobile,
    contactPersonFirstName,
    contactPersonLastName,
    contactPersonPosition,
    title,
}) {
    return (
        <Stack spacing={2}>
            <Typography variant="h5">{title}</Typography>
            <Stack direction={"row"} spacing={2}>
                {/* <Img
                    src={
                        advertiserProfile
                            ? imageUrl + advertiserProfile
                            : profilePlaceholder
                    }
                    alt={advertiserName}
                    height={100}
                    width={100}
                    style={{
                        borderRadius: "10%",
                    }}
                /> */}
                <Stack spacing={1}>
                    {isValueEmpty(adminOrOwnerName) || (
                        <Typography variant="h6">{adminOrOwnerName}</Typography>
                    )}
                    <Stack>
                        {isValueEmpty(contactPersonFirstName) ||
                            isValueEmpty(contactPersonLastName) || (
                                <Typography variant="body1">
                                    {getFullName(
                                        contactPersonFirstName,
                                        contactPersonLastName
                                    )}
                                </Typography>
                            )}
                        {isValueEmpty(contactPersonPosition) || (
                            <Typography variant="body1">
                                {contactPersonPosition}
                            </Typography>
                        )}
                        {isValueEmpty(contactPersonEmail) || (
                            <Typography variant="body1">
                                {contactPersonEmail}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default AdministrationDetail;
