export const getPropertyCategory = (property) => {
   if (!property || property === "undefined") {
      return "Apartment";
   }
   return property;
};

export const getPropertyPrice = (property) => {
   if (!property) {
      return "CHF -";
   }
   return `${new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "CHF",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
   }).format(parseInt(property))}.-`;
};

export const getPropertyName = (property) => {
   if (!property) {
      return "no_property_address_found";
   }
   return property;
};

export const getPropertyStreet = (property, nameLength) => {
   if (!property) {
      return "no_street_address_found";
   }
   if (nameLength && property?.length > nameLength) {
      return `${property?.slice(0, nameLength)}...`;
   }

   return property;
};

export const getPropertyDescription = (property, descriptionLength) => {
   return !property?.length > descriptionLength
      ? property
      : property?.slice(0, descriptionLength);
};

export const getPropertyFRL = (property, type) => {
   switch (type) {
      case "floor":
         return property?.number_floor;
      case "room":
         return property?.number_bedroom;
      case "living":
         return property?.living_space;

      default:
         return "-";
   }
};
