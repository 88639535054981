import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    ControllerPhoneTextField,
    TitleSpacer,
    FieldHintSpacer,
    ControllerSelectField,
    ControllerRadioField,
    ControllerSliderTextField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import { useContext } from "react";
import FormSectionContextSegmentN, {
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, Divider, FormGroup } from "@mui/material";
import {
    isYesNoOption,
    movingOutOption,
    REASON_TO_MOVE_OPTIONS,
} from "../../../../../../options/dossierOptions";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { DossierReferenceBox } from "../../../dossier_components/DossierReferenceBox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Radio from "@mui/material/Radio";
import ControllerSliderComponent from "../../../dossier_components/ControllerSliderComponent";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";

const TenantTwoSegmentFive = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        watchTenantTwoMovingOutOf,
        watchTenantTwoHasNoCurrentApartment,
        sameAdministrationAsFirstTenant,
        tenantTwoAdministrationEmail,
        watchTenantTwoIsImmediateAndAfterAgreement,
        referenceStatus,
        handleReferenceStatus,
        control,
        errors,
        sectionVariants,
        direction,
    } = additionalProps;

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid item container spacing={4}>
                <Grid item xs={12}>
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="sameAdministrationAsFirstTenant"
                        label={
                            t("got_the_same_administration_like_1st_tenant") +
                            "?*"
                        }
                        data={isYesNoOption}
                    />

                    {/*
                    <ControllerRadioField
                        name="sameAdministrationAsFirstTenant"
                        label={
                            t(
                                "got_the_same_administration_like_1st_tenant"
                            ) + "?"
                        }
                        control={control}
                        errorFlag={
                            !!errors?.sameAdministrationAsFirstTenant
                        }
                        errorText={t(
                            errors?.sameAdministrationAsFirstTenant
                                ?.message
                        )}
                    >
                        {isYesNoOption.map((option) => (
                            <FormControlLabel
                                key={option.id}
                                label={t(option.label)}
                                value={option.value}
                                control={<Radio/>}
                            />
                        ))}
                    </ControllerRadioField>
                    */}
                </Grid>
                {sameAdministrationAsFirstTenant === "no" && (
                    <Fragment>
                        {/* if no apartment earlier */}
                        <Grid item xs={12}>
                            <Controller
                                name="tenantTwoHasNoCurrentApartment"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field?.value}
                                            />
                                        }
                                        label={t(
                                            "i_do_not_have_a_current_rented_apartment"
                                        )}
                                    />
                                )}
                            />
                        </Grid>

                        {/* {watchPartnerEmployed === "yes" && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ControllerRadioField
                        control={control}
                        name="tenantTwoHasEmploymentTerminated"
                        label={`${t("employment_relationship_terminated")}`}>
                        <FormControlLabel
                          label={t("no")}
                          value="no"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label={t("yes")}
                          value="yes"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label={t("no_specification")}
                          value="no_specification"
                          control={<Radio />}
                        />
                      </ControllerRadioField>
                    </Grid>
                  )} */}

                        {watchTenantTwoHasNoCurrentApartment ? (
                            <Fragment>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerSelectField
                                        control={control}
                                        name="tenantTwoMovingOutOf"
                                        label={t("i_am_moving_out_of")}
                                        errorFlag={
                                            !!errors?.tenantTwoMovingOutOf
                                        }
                                        errorText={t(
                                            errors?.tenantTwoMovingOutOf
                                                ?.message
                                        )}
                                    >
                                        {movingOutOption.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {t(option.label)}
                                            </MenuItem>
                                        ))}
                                    </ControllerSelectField>
                                </Grid>
                                {watchTenantTwoMovingOutOf === "other" && (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <ControllerTextField
                                            name="tenantTwoOtherMovingOut"
                                            label={t("please_describe")}
                                            errorFlag={
                                                !!errors?.tenantTwoOtherMovingOut
                                            }
                                            errorText={t(
                                                errors?.tenantTwoOtherMovingOut
                                                    ?.message
                                            )}
                                            control={control}
                                            multiline
                                            minRows={3}
                                        />
                                    </Grid>
                                )}
                                {watchTenantTwoMovingOutOf ===
                                    "parental_home" && (
                                    <Fragment>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <ControllerTextField
                                                name="tenantTwoParentName"
                                                label={t("name_of_parent")}
                                                control={control}
                                                errorFlag={
                                                    !!errors?.tenantTwoParentName
                                                }
                                                errorText={t(
                                                    errors?.tenantTwoParentName
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <ControllerTextField
                                                name="tenantTwoParentEmail"
                                                label={t("email_of_parent")}
                                                control={control}
                                                errorFlag={
                                                    !!errors?.tenantTwoParentEmail
                                                }
                                                errorText={t(
                                                    errors?.tenantTwoParentEmail
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <ControllerPhoneTextField
                                                control={control}
                                                name="tenantTwoParentPhoneNo"
                                                label={t("phone_of_parent")}
                                                errorFlag={
                                                    !!errors?.tenantTwoParentPhoneNo
                                                }
                                                errorText={t(
                                                    errors
                                                        ?.tenantTwoParentPhoneNo
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                    </Fragment>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="tenantTwoCurrentAdministrationName"
                                        label={t(
                                            "name_of_current_administration_for_your_reference"
                                        )}
                                        errorFlag={
                                            !!errors?.tenantTwoCurrentAdministrationName
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoCurrentAdministrationName
                                                ?.message
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="tenantTwoCurrentAdministrationContactName"
                                        label={t("name_of_contact_person")}
                                        errorFlag={
                                            !!errors?.tenantTwoCurrentAdministrationContactName
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoCurrentAdministrationContactName
                                                ?.message
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={6} xl={6}>
                                    <ControllerSliderTextField
                                        control={control}
                                        name="tenantTwoCurrentRent"
                                        label={t("current_gross_monthly_rent")}
                                        renderSlider
                                        SliderComponent={
                                            ControllerSliderComponent
                                        }
                                        sliderProps={{
                                            label: t(
                                                "current_gross_monthly_rent"
                                            ),
                                            min: 400,
                                            max: 8000,
                                            step: 200,
                                        }}
                                    />
                                    {/*
                            <ControllerTextField
                                name="tenantTwoCurrentRent"
                                label={t(
                                    "current_gross_monthly_rent"
                                )}
                                control={control}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            CHF
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            */}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        name="tenantTwoCurrentAdministrationEmail"
                                        label={t(
                                            "email_of_current_administration_for_your_reference"
                                        )}
                                        control={control}
                                        errorFlag={
                                            !!errors?.tenantTwoCurrentAdministrationEmail
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoCurrentAdministrationEmail
                                                ?.message
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerPhoneTextField
                                        control={control}
                                        name="tenantTwoCurrentAdministrationPhoneNo"
                                        label={t(
                                            "phone_no_of_current_administration_for_your_reference"
                                        )}
                                        errorFlag={
                                            !!errors?.tenantTwoCurrentAdministrationPhoneNo
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoCurrentAdministrationPhoneNo
                                                ?.message
                                        )}
                                    />
                                </Grid>
                            </Fragment>
                        )}

                        {sameAdministrationAsFirstTenant === "no" &&
                            (watchTenantTwoHasNoCurrentApartment === false ||
                                watchTenantTwoMovingOutOf ===
                                    "parental_home") && (
                                <Grid item xs={12}>
                                    <DossierReferenceBox
                                        heading={t(
                                            "you_have_chosen_the_verified_version_of_our_smart_dossier_administration_reference"
                                        )}
                                        buttonTextOne={t("request")}
                                        buttonTextTwo={t(
                                            "administration_reference"
                                        )}
                                        buttonTextThree={t("now")}
                                        buttonFor={"administration"}
                                        status={
                                            referenceStatus?.tenantTwoAdministration
                                        }
                                        tenantRole={"tenantTwo"}
                                        handleReferenceStatus={
                                            handleReferenceStatus
                                        }
                                        isDisabled={
                                            referenceStatus?.tenantTwoAdministration !==
                                                "" ||
                                            tenantTwoAdministrationEmail === ""
                                        }
                                    />
                                </Grid>
                            )}

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                                component={"label"}
                                color={"secondary"}
                                fontWeight={500}
                                // mb={1}
                            >
                                {t(
                                    "partner_reason_of_change_of_residence_description"
                                )}
                                ?
                            </Typography>
                            <Controller
                                name="tenantTwoResidencyChangeReason"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="filled"
                                        select
                                        {...field}
                                        label={t(
                                            "reason_of_change_of_residence"
                                        )}
                                        SelectProps={{
                                            IconComponent:
                                                ArrowDropDownCircleOutlinedIcon,
                                        }}
                                        fullWidth
                                        error={
                                            !!errors?.tenantTwoResidencyChangeReason
                                        }
                                        helperText={t(
                                            errors
                                                ?.tenantTwoResidencyChangeReason
                                                ?.message
                                        )}
                                    >
                                        {REASON_TO_MOVE_OPTIONS.map(
                                            (option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {t(option.label)}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                name="tenantTwoResidencyChangeReasonComment"
                                label={t(
                                    "reason_of_change_of_residence_comment"
                                )}
                                control={control}
                                multiline
                                minRows={3}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid item container>
                                <Grid item xs={12}>
                                    <Typography
                                        component={"label"}
                                        color={"secondary"}
                                        fontWeight={500}
                                    >
                                        {t("earliest_possible_movin_date") +
                                            "?"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <Controller
                                        name="tenantTwoIsImmediateAndAfterAgreement"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field?.value}
                                                    />
                                                }
                                                label={t(
                                                    "immediately_&_after_agreement"
                                                )}
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Grid>
                            {!watchTenantTwoIsImmediateAndAfterAgreement && (
                                <Grid item xs={12}>
                                    <ControllerDateField
                                        control={control}
                                        //   openTo={"year"}
                                        datePickerProps={{
                                            disablePast: true,
                                        }}
                                        name="tenantTwoDateOfMoving"
                                        label={t("date") + "*"}
                                        errorFlag={
                                            !!errors?.tenantTwoDateOfMoving
                                        }
                                        errorText={t(
                                            errors?.tenantTwoDateOfMoving
                                                ?.message
                                        )}
                                        lang={currentLanguageCode}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Fragment>
                )}
                {/*
                {sameAdministrationAsFirstTenant === "yes" && (
                    <Grid item xs={12}>
                        <DossierReferenceBox
                            heading={t(
                                "you_have_chosen_the_verified_version_of_our_smart_dossier_administration_reference"
                            )}
                            buttonTextOne={t("request")}
                            buttonTextTwo={t(
                                "administration_reference"
                            )}
                            buttonTextThree={t("now")}
                            buttonFor={"administration"}
                            status={
                                referenceStatus?.tenantTwoAdministration
                            }
                            handleReferenceStatus={
                                handleReferenceStatus
                            }
                            tenantRole={"tenantTwo"}
                            isDisabled={
                                referenceStatus?.tenantTwoAdministration !==
                                ""
                            }
                        />
                    </Grid>
                )}
                */}
            </Grid>
        </DossierFormSection>
    );
};

export default TenantTwoSegmentFive;
