import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import ImageWithContent from "../../component/layouts/ImageWithContent";
import ArrowButton from "../../component/elements/button/ArrowButton";
import { BENEFITS_OPTIONS } from "../../options/benefitsOption";
import { useAuthModal } from "../../context/ModalContext";
import SearchByDistance from "../../component/search/SearchByDistance";
import MasterStepForm from "../../component/modal/onboarding-smart-dossier/MasterStepForm";
import { useStateValue } from "../../store";
import useResponsive from "../../hooks/responsive/useResponsive";
import Chip from "@mui/material/Chip";
import useUserContext from "../../hooks/user-context/useUserContext";

const initialDefaultCities = [
    { name: "Aarau", zip: "5000" },
    { name: "Basel", zip: "4000" },
    { name: "Bern", zip: "3000" },
    { name: "Genf", zip: "1200" },
    { name: "Lugano", zip: "6900" },
    { name: "Luzern", zip: "6000" },
    { name: "St. Gallen", zip: "9000" },
    { name: "Winterthur", zip: "8400" },
    { name: "Zürich", zip: "8000" },
];

const CreateSmartDossier = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isBelowMd } = useResponsive();
    const { openAuthModal, handleUserRole } = useAuthModal();
    const [{ user_id }] = useStateValue();
    const { pathname } = useLocation();

    const [showWishForm, setShowWishForm] = useState({
        open: false,
        data: null,
    });

    const [selectedCities, setSelectedCities] = useState([]);
    const [availableCities, setAvailableCities] =
        useState(initialDefaultCities);

    const handleCitySelect = (event, newValue, isSelected) => {
        //console.log(newValue);
        if (isSelected) {
            setSelectedCities((prev) => [...prev, newValue]);
            setAvailableCities((prev) =>
                prev.filter((c) => c.zip !== newValue.zip)
            );
        } else {
            setSelectedCities((prev) =>
                prev.filter((c) => c.zip !== newValue.zip)
            );
            setAvailableCities((prev) => [...prev, newValue]);
        }
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, []);

    const handleOpen = (data) => {
        setShowWishForm({
            open: true,
            data: data,
        });
    };
    const handleClose = () =>
        setShowWishForm((prevState) => ({
            ...prevState,
            open: false,
        }));

    const handleOnClick = () => {
        handleUserRole("TN");
        openAuthModal();
    };

    const handleFinishSelection = () => {
        const cityNames = selectedCities.map((city) => city.name).join(", ");
        const zipCodes = selectedCities.map((city) => city.zip).join(", ");

        const cityAndZipData = {
            name: cityNames,
            zip: zipCodes,
        };

        //console.log(cityAndZipData);

        handleOpen(cityAndZipData);
    };

    const { userContext, updateUserContext } = useUserContext();

    useEffect(() => {
        const newContext = {
            origin: "createSmartDossier",
            action: "onLoginOrNew",
            actionValue: "new",
            actionOpenValue: "started",
            actionCloseValue: "",
        };
        updateUserContext(newContext);
    }, [updateUserContext]);

    const getFullCityName = (name, zip, type) => {
        if (zip) {
            return `${zip} ${name}`;
        }
        return `${type} ${name}`;
    };

    //console.log("Origin ContactSeller: " + userContext.origin + " / " + userContext.action + " / " + userContext.actionValue+ " / " + userContext.actionOpenValue+ " / " + userContext.actionCloseValue);

    return (
        <>
            <ImageWithContent
                layoutDirection={isBelowMd ? "columnReverse" : "row"}
                benefits={BENEFITS_OPTIONS}
                t={t}
            >
                <Stack
                    justifyContent={"center"}
                    height={"100%"}
                    sx={{
                        px: { xs: 3, md: 6, lg: 10 },
                        py: { xs: 10, md: 0 },
                    }}
                    rowGap={5}
                >
                    <Stack rowGap={2.5}>
                        <Typography
                            variant="h3"
                            color={theme.palette.common.white}
                        >
                            {pathname.includes("/create-dossier")
                                ? t(
                                      "with_the_smart_dossier_to_your_dream_property"
                                  )
                                : t("already_convinced_by_our_smart_dossier")}
                        </Typography>
                        <Typography
                            variant="body1"
                            color={theme.palette.common.white}
                        >
                            {t("start_compiling_your_smart_dossier")}
                        </Typography>
                    </Stack>

                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            justifyContent: "center",
                            maxWidth: "100%",
                        }}
                    >
                        {selectedCities.map((city, index) => (
                            <Chip
                                key={`selected-${index}`}
                                label={getFullCityName(
                                    city.name,
                                    city.zip,
                                    city.type
                                )}
                                onClick={() =>
                                    handleCitySelect(null, city, false)
                                }
                                sx={{
                                    bgcolor: theme.palette.secondary.main,
                                    color: "common.white",
                                    fontSize: "1rem",
                                    height: "auto",
                                    padding: "10px",
                                    margin: "5px",
                                }}
                            />
                        ))}
                    </Box>

                    <SearchByDistance
                        onStart={handleOpen}
                        handleCitySelect={handleCitySelect}
                        handleFinishSelection={handleFinishSelection}
                        selectedCities={selectedCities}
                    />

                    {user_id === null && (
                        <Box>
                            <ArrowButton
                                variant="text"
                                color={"secondary"}
                                onClick={handleOnClick}
                            >
                                {t("already_created_smart_dossier")}
                                <br />
                                {t("to_the_login")}
                            </ArrowButton>
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            justifyContent: "center",
                            maxWidth: "100%",
                        }}
                    >
                        {availableCities.map((city, index) => (
                            <Chip
                                key={`available-${index}`}
                                label={getFullCityName(
                                    city.name,
                                    city.zip,
                                    city.type
                                )}
                                onClick={() =>
                                    handleCitySelect(null, city, true)
                                }
                                sx={{
                                    bgcolor: "grey",
                                    color: "common.white",
                                    fontSize: "1rem",
                                    fontWeight: "normal",
                                    height: "50px",
                                    borderRadius: "25px",
                                    padding: "0 10px",
                                    opacity: 0.7,
                                    "&:hover": {
                                        opacity: 1,
                                        bgcolor: theme.palette.secondary.main,
                                    },
                                }}
                            />
                        ))}
                    </Box>
                </Stack>
            </ImageWithContent>
            <MasterStepForm
                open={showWishForm.open}
                location={showWishForm.data}
                handleClose={handleClose}
            />
        </>
    );
};

export default CreateSmartDossier;
