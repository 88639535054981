import * as yup from "yup";

const step0Section3Schema = yup.object().shape({
    tenantTwoEmployedOrNot: yup.string().required("please_select_any"),
    // if no
    tenantTwoUnemploymentStatus: yup
        .string()
        .nullable()
        .when("tenantTwoEmployedOrNot", {
            is: "no",
            then: yup.string().nullable().required("please_select_any"),
        }),
    tenantTwoOtherEmploymentStatus: yup
        .string()
        .when(["tenantTwoEmployedOrNot", "tenantTwoUnemploymentStatus"], {
            is: (tenantTwoEmployedOrNot, tenantTwoUnemploymentStatus) =>
                tenantTwoEmployedOrNot === "no" &&
                tenantTwoUnemploymentStatus === "other_unemployed",
            then: yup.string().required("please_enter_a_status"),
        }),

    tenantTwoHowYouPay: yup
        .string()
        .nullable()
        .when(["tenantTwoEmployedOrNot", "tenantTwoUnemploymentStatus"], {
            is: (tenantTwoEmployedOrNot, tenantTwoUnemploymentStatus) => {
                return (
                    tenantTwoEmployedOrNot === "no" &&
                    tenantTwoUnemploymentStatus !== "self_employed"
                );
            },
            then: yup.string().nullable().required("please_select_any"),
        }),

    tenantTwoLiablePerson: yup
        .string()
        .when(["tenantTwoEmployedOrNot", "tenantTwoHowYouPay"], {
            is: (tenantTwoEmployedOrNot, tenantTwoHowYouPay) =>
                tenantTwoEmployedOrNot === "no" &&
                tenantTwoHowYouPay === "additional_solidarity_liability",
            then: yup
                .string()
                .nullable()
                .required("please_enter_the_name_of_the_person"),
        }),

    // if self-employed

    tenantTwoSelfEmployedCompanyName: yup
        .string()
        .when(["tenantTwoEmployedOrNot", "tenantTwoUnemploymentStatus"], {
            is: (tenantTwoEmployedOrNot, tenantTwoUnemploymentStatus) =>
                tenantTwoEmployedOrNot === "no" &&
                tenantTwoUnemploymentStatus === "self_employed",
            then: yup
                .string()
                .required("please_enter_a_company_name_and_address"),
        }),
    tenantTwoSelfEmployedCompanyAddress: yup
        .string()
        .when(["tenantTwoEmployedOrNot", "tenantTwoUnemploymentStatus"], {
            is: (tenantTwoEmployedOrNot, tenantTwoUnemploymentStatus) =>
                tenantTwoEmployedOrNot === "no" &&
                tenantTwoUnemploymentStatus === "self_employed",
            then: yup
                .string()
                .required("please_enter_a_company_name_and_address"),
        }),

    tenantTwoSelfEmployedIncome: yup
        .string()
        .nullable()
        .when(["tenantTwoEmployedOrNot", "tenantTwoUnemploymentStatus"], {
            is: (tenantTwoEmployedOrNot, tenantTwoUnemploymentStatus) =>
                tenantTwoEmployedOrNot === "no" &&
                tenantTwoUnemploymentStatus === "self_employed",
            then: yup.string().required("please_enter_monthly_mid_income"),
        }),

    tenantTwoReferenceName: yup.string().when("tenantTwoEmployedOrNot", {
        is: "no",
        then: yup.string().required("please_enter_a_name_for_reference"),
    }),
    tenantTwoReferenceEmail: yup.string().when("tenantTwoEmployedOrNot", {
        is: "no",
        then: yup
            .string()
            .email("email_is_invalid")
            .required("please_enter_a_email_for_reference"),
    }),
    tenantTwoReferencePhone: yup.string().when("tenantTwoEmployedOrNot", {
        is: "no",
        then: yup
            .string()
            .required("please_enter_your_mobile_no")
            .max(19, "please_enter_a_valid_phone_number"),
    }),
    // if yes

    tenantTwoProfession: yup.string().when("tenantTwoEmployedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_your_profession"),
    }),

    employmentRelationship: yup.string().when("tenantTwoEmployedOrNot", {
        is: "yes",
        then: yup.string().nullable().required("please_select_any"),
    }),
    // tenantTwoCompanyNameAndAddress: yup.mixed().when("tenantTwoEmployedOrNot", {
    //     is: "yes",
    //     then: yup.mixed().required("please_enter_a_company_name_and_address"),
    // }),

    tenantTwoCompanyName: yup.string().when("tenantTwoEmployedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_the_company_name"),
    }),
    tenantTwoCompanyAddress: yup.string().when("tenantTwoEmployedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_the_company_name"),
    }),

    tenantTwoEmployeeName: yup.string().when("tenantTwoEmployedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_name_of_a_employee"),
    }),
    tenantTwoEmployeeEmail: yup.string().when("tenantTwoEmployedOrNot", {
        is: "yes",
        then: yup
            .string()
            .email("please_enter_a_valid_email")
            .required("please_enter_email_of_a_employee"),
    }),
    tenantTwoEmployeePhoneNo: yup.string().when("tenantTwoEmployedOrNot", {
        is: "yes",
        then: yup
            .string()
            .required("please_enter_your_mobile_no")
            .max(19, "please_enter_a_valid_phone_number"),
    }),
});

export default step0Section3Schema;
