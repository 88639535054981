import { ButtonBase, styled } from "@mui/material";
import PropTypes from "prop-types";

const MultiSelectButton = styled(ButtonBase, {
   shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }) => ({
   color: selected ? theme.palette.common.white : theme.palette.primary.main,
   backgroundColor: selected
      ? theme.palette.secondary.main
      : theme.palette.grey[200],
   padding: "12px 15px",
   minWidth: 90,
   borderRadius: 30,
   fontSize: 16,
   fontWeight: 300,
   lineHeight: "26px",
   transition: "color 300ms ease-in, background-color 300ms ease-in",

   "&:hover": {
      color: selected
         ? theme.palette.common.white
         : theme.palette.secondary.dark,
      backgroundColor: selected
         ? theme.palette.secondary.main
         : theme.palette.secondary.light,
   },
}));

function MultiselectButton({ children, selected, ...props }) {
   return (
      <MultiSelectButton selected={selected} {...props}>
         {children}
      </MultiSelectButton>
   );
}

MultiselectButton.propTypes = {
   children: PropTypes.any,
};

export default MultiselectButton;
