import { Person1, Person2, Person3, Person4 } from "../utils/imgImports";

export const VOICE_OF_CUSTOMERS = [
   {
      id: 1,
      name: "M. E. E.",
      initials: "M",
      img: Person1,
      avatarColor: "#2197a7",
      description: "subsequent_nextFlat_would_even_deserve_&more",
   },
   {
      id: 2,
      name: "R.O.",
      initials: "R",
      img: Person2,
      avatarColor: "#ab47bc",
      description: "a_great_professional_service_with_&more",
   },
   {
      id: 3,
      name: "M.Z.",
      initials: "M",
      img: Person3,
      avatarColor: "#fe5900",
      description: "unfortunately_we_came_across_this_platform_&more",
   },
   {
      id: 4,
      name: "A.M.",
      initials: "A",
      img: Person4,
      avatarColor: "#fe5900",
      description: "a_big_thank_you",
   },
];
