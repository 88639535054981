import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { Controller, useFormContext, useForm } from "react-hook-form";

//* icon
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
//* cookie
import cookies from "js-cookie";

//* api;
import { useCategories } from "../../../hooks/search/useSearchOptions";
import { Autocomplete } from "@mui/material";
import { motion } from "framer-motion";
import { slideUp } from "../../../utils/animation/Animation";

import ControllerToggleButtonsComponent from "../../../component/forms/smart_dossier_form/dossier_components/ControllerToggleButtonsComponent";
import ControllerSliderComponent from "../../../component/forms/smart_dossier_form/dossier_components/ControllerSliderComponent";
import { workload } from "../../../options/dossierOptions";
import {
    ControllerSliderField,
    ControllerSliderTextField,
} from "../../mui-components/MuiDossierFormComponents";

import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";

// const cate = [
//     { id: 1, value: "Apartment" },
//     { id: 2, value: "Apartment & house" },
//     { id: 3, value: "House" },
//     { id: 4, value: "Furnished dwelling" },
//     { id: 5, value: "Parking space" },
//     { id: 6, value: "office" },
//     { id: 7, value: "Commercial & industry" },
//     { id: 8, value: "Storage room" },
//     { id: 9, value: "Garage" },
// ];

function KindOfObjectForm() {
    const { t } = useTranslation();

    const { control, watch, setValue } = useFormContext();

    const currentLanguageCode = cookies.get("i18next") || "en";
    const { isLoading: categoryLoading, data: categoryData } =
        useCategories(currentLanguageCode);
    const livingSpaceFrom = watch("livingSpaceFrom");
    const livingSpaceTo = watch("livingSpaceTo");

    useEffect(() => {
        if (livingSpaceTo <= livingSpaceFrom) {
            setValue("livingSpaceTo", livingSpaceFrom + 10);
        }
    }, [livingSpaceFrom, livingSpaceTo, setValue]);

    const [transformedCategoryData, setTransformedCategoryData] = useState([]);

    useEffect(() => {
        if (categoryData && categoryData.length > 0) {
            const newTransformedCategoryData = categoryData
                .filter((category) => category.name.length > 3)
                .map((category) => ({
                    id: category.id.toString(),
                    value: category.name,
                    label: category.name,
                }));
            setTransformedCategoryData(newTransformedCategoryData);
            console.log(newTransformedCategoryData);
        }
    }, [categoryData]);

    return (
        <Grid
            container
            rowSpacing={2}
            component={motion.div}
            variants={slideUp}
            initial="hidden"
            animate="visible"
        >
            <Grid item xs={12}>
                <Typography variant="subtitle1" mb={1}>
                    {t("what_kind_of_object_are_you_searching_for") + "?"}
                </Typography>
                {categoryLoading ? (
                    <CircularProgress />
                ) : (
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="category"
                        label={`${t("")}`}
                        data={transformedCategoryData}
                        minWidth="84px"
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" mb={1}>
                    {t("how_much_living_space_you_want") + "?"}
                </Typography>

                <Grid item container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <ControllerSliderField
                            control={control}
                            name="livingSpaceFrom"
                            label={t("from")}
                            min={20}
                            max={320}
                            step={10}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ControllerSliderField
                            control={control}
                            name="livingSpaceTo"
                            label={t("to")}
                            min={20}
                            max={320}
                            step={10}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default KindOfObjectForm;
