import React, { forwardRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import cookies from "js-cookie";

//* translation
import { useTranslation } from "react-i18next";

//* Router
import { useNavigate } from "react-router-dom";

//* react-hook-form
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WishPropertyValidationSchema } from "../../../forms-schemas/WishPropertySchema";

//* mui components import
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Grow from "@mui/material/Grow";
import { useTheme } from "@mui/material";

import { AnimatePresence } from "framer-motion";

//* mui icons imports
import CloseIcon from "@mui/icons-material/Close";

//* store
import { actionTypes } from "../../../store/reducer";
import { useStateValue } from "../../../store/StateProvider";

//* component

import PageNotFound from "../../../pages/Error-pages/PageNotFound";
import ArrowButton from "../../elements/button/ArrowButton";

//*hooks
import useIsSmartDossierPaid from "../../../hooks/transaction/useIsSmartDossierPaid";
import useAddDossierWishProperty from "../../../hooks/wish-property/useAddDossierWishProperty";
import useRegistration from "../../../hooks/Auth/useRegistration";
import useLogin from "../../../hooks/Auth/useLogin";
import useResponsive from "../../../hooks/responsive/useResponsive";

//*steps
import KindOfObjectForm from "./KindOfObjectForm";
import MaxRentForm from "./MaxRentForm";
import NoOfPeopleForm from "./NoOfPeopleForm";
import AuthForm from "./AuthForm";
import useVerifyCode from "../../../hooks/Auth/useVerifyCode";
import useAuth from "../../../hooks/Auth/useAuth";
import { useAuthModal } from "../../../context/ModalContext";
import useUserContext from "../../../hooks/user-context/useUserContext";
import { useCheckDossier } from "../../../hooks/profile/useCheckDossier";

const onboardingSteps = ["step1", "step2", "step3", "step4"];
const DEFAULT_VALUES = {
    city: "",
    zip: "",
    distance: "",
    // rooms: "Any",
    category: null,
    maxRent: 400,
    roomsFrom: 1,
    roomsTo: 3,
    livingSpaceFrom: 20,
    livingSpaceTo: 40,
    features: [],
    noOfAdults: "",
    hasPet: "",
    isSmoke: "",
    email: "",
    authOtp: "",
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

function MasterStepForm({ open, handleClose, location }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isBelowSm } = useResponsive();
    const navigate = useNavigate();
    const { setAuth } = useAuth();
    const [{ user_id, token }, dispatch] = useStateValue();
    const {
        //   isLoading: checkDossierIsLoading,
        data: checkDossierCreated,
        status: checkDossierStatus,
    } = useCheckDossier(user_id);

    const isCreated = checkDossierCreated?.data;

    const [activeStep, setActiveStep] = useState(0);
    const [authForm, setAuthForm] = useState("register");
    const [successMessage, setSuccessMessage] = useState("");

    const [previousAuthForm, setPreviousAuthForm] = useState("");
    const currentLanguageCode = cookies.get("i18next") || "en";

    const {
        isLoading: isLoadingDossierPaid,
        data: smartDossierPayment,
        status: smartDossierPaymentStatus,
    } = useIsSmartDossierPaid(user_id);

    const { isLoading: isLoadingWishProperty, mutate: onAddWishProperty } =
        useAddDossierWishProperty();
    const { isLoading: isLoadingRegister, mutate: onRegister } =
        useRegistration();
    const { isLoading: isLoadingLogin, mutate: onLogin } = useLogin();

    const { isLoading: isLoadingVerifyOtp, mutate: onVerifyOtp } =
        useVerifyCode();

    const getValidationSchema = () => {
        if (activeStep === 3 && authForm === "verify")
            return WishPropertyValidationSchema[4];
        return WishPropertyValidationSchema[activeStep];
    };

    const methods = useForm({
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(getValidationSchema()),
        mode: "onSubmit",
    });

    useEffect(() => {
        if (location) {
            methods.reset((values) => {
                return {
                    ...values,
                    city: location?.cityAndZip?.name,
                    zip: location?.cityAndZip?.zip,
                    distance: location?.distance,
                };
            });
        }
    }, [location, methods]);

    //store previous form in the state to be able to go back
    const storeAuthForm = (form) => setPreviousAuthForm(form);

    const handleAuthForm = (formType) => {
        setAuthForm(formType);
        methods.clearErrors(["email"]);
    };

    const { userContext, updateUserContext } = useUserContext();

    useEffect(() => {
        const newContext = {
            origin: "createSmartDossier",
            action: "onLoginOrNew",
            actionValue: "new",
            actionOpenValue: "started",
            actionCloseValue: "",
        };
        updateUserContext(newContext);
    }, [updateUserContext]);

    console.log(
        "Origin ContactSeller: " +
            userContext.origin +
            " / " +
            userContext.action +
            " / " +
            userContext.actionValue +
            " / " +
            userContext.actionOpenValue +
            " / " +
            userContext.actionCloseValue
    );

    const onSubmitWishListData = (wishedProperty, isPaid) => {
        onAddWishProperty(wishedProperty, {
            onSuccess: (data) => {
                //check status

                if (!data?.data?.statuscode) return t("something_went_wrong");
                handleClose();

                if (!isPaid) {
                    return navigate("/dossier-payment");
                }
                if (isPaid && !isCreated.briefFormFilled) {
                    return navigate("/brief-dossier-form");
                }
                navigate("/dossier-form/any-property");
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    const onSubmitLoginData = (data) => {
        const { email } = data;

        let user = { email, language: currentLanguageCode };

        onLogin(user, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    let errorMessage = data?.data?.message?.toLowerCase();
                    if (errorMessage.includes("verify")) {
                        methods.setError("email", {
                            type: "server",
                            message: `${t(
                                "entered_email_is_not_verified_yet"
                            )}`,
                        });
                        methods.setError("password", {
                            type: "server",
                            message: "",
                        });

                        return;
                    }
                    methods.setError("email", {
                        type: "server",
                        message: "",
                    });
                    methods.setError("email", {
                        type: "server",
                        message: `${t("you_entered_incorrect_email")}`,
                    });

                    return;
                }
                storeAuthForm("login");
                handleAuthForm("verify");
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    const onSubmitRegisterData = (data) => {
        const { email } = data;

        let userData = {
            email: email,
            first_name: null,
            last_name: null,
            username: null,
            role_type: "TN",
            device_type: "website",
            language: currentLanguageCode,
        };

        onRegister(userData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    setSuccessMessage("");

                    let message = data?.data?.message;
                    let errorCheck = message?.toLowerCase();
                    if (errorCheck.includes("email")) {
                        methods.setError("email", {
                            type: "server",
                            message: `${t("this_email_is_already_taken")}`,
                        });
                    }
                    if (errorCheck.includes("username")) {
                        methods.setError("username", {
                            type: "server",
                            message: `${t(
                                "this_username_is_already_taken_try_a_different_one"
                            )}`,
                        });
                    }

                    return;
                }
                storeAuthForm("register");
                handleAuthForm("verify");
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    const onSubmitOtp = (data) => {
        let {
            city,
            zip,
            distance,
            category,
            maxRent,
            roomsFrom,
            roomsTo,
            livingSpaceFrom,
            livingSpaceTo,
            features,
            noOfAdults,
            hasPet,
            isSmoke,
            email,
            authOtp,
        } = data;

        let otpData = {
            key: email,
            PIN: authOtp,
        };
        let wishedProperty = {
            city,
            zip,
            distance,
            category,
            maxRent,
            roomsFrom,
            roomsTo,
            livingSpaceFrom,
            livingSpaceTo,
            features: features.toString(),
            noOfAdults,
            hasPet,
            isSmoke,
        };

        onVerifyOtp(otpData, {
            onSuccess: (data) => {
                // console.log(data);
                if (!data?.data?.statuscode) {
                    methods.setError("authOtp", {
                        type: "server",
                        message: "verification_code_is_invalid_or_expired",
                    });
                    return;
                }
                const userId = data?.data?.data?.[0]?.id;
                const userEmail = data?.data?.data?.[0]?.email;
                const accessToken = data?.data?.accessToken;
                setAuth({ userId, userEmail, accessToken });
                localStorage.setItem("nch_id", data?.data?.accessToken);
                localStorage.setItem(
                    "nch_user_data",
                    JSON.stringify({
                        id: data?.data?.data[0].id,
                        user_email: data?.data?.data[0].email,
                    })
                );
                dispatch({
                    type: actionTypes.SET_USER,
                    token: data?.data?.accessToken,
                    user_id: data?.data?.data[0].id,
                    user_email: data?.data?.data[0].email,
                });
                let wishedPropertyData = { ...wishedProperty, user_id: userId };
                let isPaid = data?.data?.data?.[0]?.dossierPayment === 1;
                onSubmitWishListData(wishedPropertyData, isPaid);
            },
            onError: (error) => {
                console.error(error);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    const onSubmitData = (data) => {
        let wishedProperty = {
            ...data,
            user_id,
            features: data.features.toString(),
        };
        let isPaid = Boolean(smartDossierPayment?.dossierPayment);
        onSubmitWishListData(wishedProperty, isPaid);
    };

    const handleNext = async () => {
        const isStepValid = await methods.trigger();

        //console.log(allValues);
        if (isStepValid) {
            //   onSubmitData(allValue);
            setActiveStep((prevState) => {
                return prevState + 1;
            });
        }
    };
    const handlePrev = () => {
        setActiveStep((prevState) => {
            return prevState - 1;
        });
    };
    const handlePrevFromOtp = () => {
        handleAuthForm(previousAuthForm);
    };

    const getForm = () => {
        switch (activeStep) {
            case 0:
                return <KindOfObjectForm />;
            case 1:
                return <MaxRentForm />;
            case 2:
                return <NoOfPeopleForm />;
            case 3:
                return (
                    <AuthForm
                        authForm={authForm}
                        handleAuthForm={handleAuthForm}
                    />
                );

            default:
                return <PageNotFound />;
        }
    };

    const FormsButtons = () => {
        if (activeStep === onboardingSteps.length - 1 && authForm === "verify")
            return (
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <ArrowButton
                        variant="text"
                        arrowStart
                        onClick={handlePrevFromOtp}
                    >
                        {t("back")}
                    </ArrowButton>
                    <ArrowButton
                        variant="contained"
                        disabled={isLoadingVerifyOtp}
                        onClick={methods.handleSubmit(onSubmitOtp)}
                    >
                        {isLoadingRegister ? t("submit") + "..." : t("submit")}
                    </ArrowButton>
                </Stack>
            );
        if (activeStep === 0) {
            return (
                <Stack direction="row" justifyContent="flex-end">
                    <ArrowButton variant="contained" onClick={handleNext}>
                        {t("next")}
                    </ArrowButton>
                </Stack>
            );
        }
        if (activeStep === onboardingSteps.length - 1) {
            return (
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <ArrowButton variant="text" arrowStart onClick={handlePrev}>
                        {t("back")}
                    </ArrowButton>

                    {authForm === "register" ? (
                        <ArrowButton
                            variant="contained"
                            disabled={
                                isLoadingRegister || isLoadingWishProperty
                            }
                            onClick={methods.handleSubmit(onSubmitRegisterData)}
                        >
                            {isLoadingRegister
                                ? t("register") + "..."
                                : t("register")}
                        </ArrowButton>
                    ) : (
                        <ArrowButton
                            variant="contained"
                            onClick={methods.handleSubmit(onSubmitLoginData)}
                            disabled={isLoadingLogin || isLoadingWishProperty}
                        >
                            {isLoadingLogin ? t("send") + "..." : t("send")}
                        </ArrowButton>
                    )}
                </Stack>
            );
        }

        if (
            user_id !== null &&
            token !== null &&
            activeStep === onboardingSteps.length - 2
        ) {
            return (
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <ArrowButton variant="text" arrowStart onClick={handlePrev}>
                        {t("back")}
                    </ArrowButton>
                    <ArrowButton
                        variant="contained"
                        onClick={methods.handleSubmit(onSubmitData)}
                    >
                        {t("submit")}
                    </ArrowButton>
                </Stack>
            );
        }

        return (
            <Stack direction={"row"} justifyContent={"space-between"}>
                <ArrowButton variant="text" arrowStart onClick={handlePrev}>
                    {t("back")}
                </ArrowButton>
                <ArrowButton variant={"contained"} onClick={handleNext}>
                    {activeStep === onboardingSteps.length - 2
                        ? t("send")
                        : t("next")}
                </ArrowButton>
            </Stack>
        );
    };

    return (
        <Dialog
            fullScreen={isBelowSm}
            open={open}
            // onClose={onClose}
            scroll="body"
            PaperProps={{
                sx: {
                    border: `1px solid ${theme.palette.secondary.main} `,
                    backdropFilter: "blur(10px)",
                },
            }}
            fullWidth
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={Transition}
            slotProps={{
                backdrop: {
                    timeout: 500,
                    sx: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        backdropFilter: "blur(10px)",
                    },
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 1,
                }}
            >
                <CloseIcon
                    onClick={() => {
                        handleClose();
                        handleAuthForm("register");
                        methods.reset(DEFAULT_VALUES);
                        setActiveStep(0);
                    }}
                    fontSize="large"
                    sx={{
                        cursor: "pointer",
                        marginLeft: 1,
                    }}
                />
            </Box>
            <FormProvider {...methods}>
                {activeStep === 5 && successMessage !== "" && (
                    <Alert severity="success">{t(successMessage)}</Alert>
                )}
                <form
                    onSubmit={methods.handleSubmit(handleNext)}
                    style={{
                        padding: "10px 40px",
                    }}
                >
                    <AnimatePresence>{getForm()}</AnimatePresence>
                    <Box pt={3} pb={2}>
                        <FormsButtons />
                    </Box>
                </form>
            </FormProvider>
        </Dialog>
    );
}

export default MasterStepForm;
