import React from "react";

//* mui components imports
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    WhatsappIcon,
} from "react-share";

export default function ShareModal({fullScreen, open, handleClose}) {
    const shareUrl = window.location.href;

    return (
        <Dialog
            // fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
        >
            <DialogTitle variant='h4' id='responsive-dialog-title'>
                Share listing
            </DialogTitle>
            <Divider variant='middle'/>
            <DialogContent>
                <Stack direction='row' spacing={2}>
                    <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={40} round={true}/>
                    </FacebookShareButton>
                    <EmailShareButton url={shareUrl}>
                        <EmailIcon size={40} round={true}/>
                    </EmailShareButton>
                    <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon size={40} round={true}/>
                    </WhatsappShareButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
