import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Droplet from "../shapes/Droplet";
import { Typography } from "@mui/material";
import useResponsive from "../../hooks/responsive/useResponsive";

const NextflatTeamBox = ({ name, image, nameImage, position }) => {
    const { isBelowMd } = useResponsive();
    return (
        <Stack rowGap={2} alignItems={{ xs: "center" }}>
            <Stack rowGap={1} >
                <Droplet
                    position={"left"}
                    {...(isBelowMd ? { size: 343 } : { size: 304 })}
                    containerStyleProps={{
                        overflow: "hidden",
                    }}
                >
                    <img src={image} alt='' height={"100%"} width={"100%"} />
                </Droplet>
                <Stack rowGap={1} width={335}>
                    {nameImage ? (
                        <img src={"/static/media/" + nameImage + ".webp"} alt="Name" height={50} />
                    ) : (
                        <Typography variant='h5'>{name}</Typography>
                    )}
                    <Typography
                        variant='h7'
                        sx={{ wordBreak: "break-word" }}
                    >
                        {position}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

NextflatTeamBox.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
};

export default NextflatTeamBox;
