import * as yup from "yup";

const step0Section5Schema = yup.object().shape({
    noOfChildren: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("hasChildren", {
            is: "yes",
            then: yup
                .number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .nullable()
                .required("please_enter_no_of_childrens")
                .min(1, "please_enter_number_greater_than_0")
                .max(8, "please_enter_number_smaller_than_8"),
        }),
    // noOfChildren: yup
    //   .number()
    //   .transform((value) => (isNaN(value) ? undefined : value)) // hacky way to fix NaN issue for number validation
    //   .nullable()
    //   .min(1, "please_enter_number_greater_than_0")
    //   .max(8, "please_enter_number_smaller_than_8")
    //   .when("hasChildren", {
    //     is: "yes",
    //     then: yup
    //       .number()
    //       .transform((value) => (isNaN(value) ? undefined : value))
    //       .required("please_enter_no_of_children")
    //       .min(1, "please_enter_number_greater_than_0")
    //       .max(8, "please_enter_number_smaller_than_8"),
    //   }),

    //https://stackoverflow.com/questions/76278794/yup-validation-with-dynamic-fields-on-react-hook-form
    childrensAverageAge: yup.mixed().when("hasChildren", {
        is: "yes",
        then: yup.array().of(
            yup.object().shape({
                age: yup.string().required("please_enter_age_of_child"),
            })
        ),
    }),

    noOfChildrenMovingIn: yup.string().when("hasChildren", {
        is: "yes",
        then: yup
            .string()
            .required("please_enter_the_number_of_children_moving_with_you"),
    }),

    smokePreference: yup
        .string()
        .nullable()
        .when("isSmoker", {
            is: "yes",
            then: yup
                .string()
                .nullable()
                .required("please_choose_your_preference"),
        }),
    isPlayingAtHome: yup
        .string()
        .nullable()
        .when("hasMusicalInstrument", {
            is: "yes",
            then: yup.string().nullable().required("please_select_any"),
        }),
    typeOfVehicle: yup
        .string()
        .nullable()
        .when("hasVehicle", {
            is: "yes",
            then: yup
                .string()
                .nullable()
                .required("please_the_confirm_the_type_of_vehicle_you_have"),
        }),
    isParkingNeeded: yup
        .string()
        .nullable()
        .when(["hasVehicle", "typeOfVehicle"], {
            is: (hasVehicle, typeOfVehicle) =>
                hasVehicle === "yes" && typeOfVehicle !== "",
            then: yup
                .string()
                .nullable()
                .required("please_confirm_the_need_of_parking"),
            otherwise: yup.string().nullable(),
        }),
    numberOfParkingSpace: yup
        .string()
        .nullable()
        .when(["hasVehicle", "typeOfVehicle", "isParkingNeeded"], {
            is: (hasVehicle, typeOfVehicle, isParkingNeeded) =>
                hasVehicle === "yes" &&
                typeOfVehicle !== "" &&
                isParkingNeeded === "yes",
            then: yup
                .string()
                .nullable()
                .required("please_enter_the_number_of_parking_space"),
            otherwise: yup.string().nullable(),
        }),
    pet: yup
        .string()
        .nullable()
        .when("hasPet", {
            is: "yes",
            then: yup.string().nullable().required("please_enter_pet's_detail"),
        }),
    anotherTenantRelation: yup
        .string()
        .nullable()
        .when("isAnotherTenantMovingIn", {
            is: "yes",
            then: yup
                .string()
                .nullable()
                .required("please_select_your_relationship"),
        }),
});

export default step0Section5Schema;
