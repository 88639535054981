import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerRadioField,
    ControllerTextField,
    ControllerDateField,
    ControllerSelectField,
    ControllerAutoCompleteField,
    TitleSpacer,
    FieldHintSpacer,
    FieldDottedLineSpacer,
    ControllerNextRadioField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import {
    civilStatusArr,
    permitOptionsArr,
    tenantGender,
    tenantTitle,
} from "../../../../../../options/dossierOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { isGreaterThanYear } from "../../../../../../utils/isGreaterThanYear";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";

const TenantOneSegmentOne = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        getAllCountry,
        watchNationality,
        title,
        control,
        errors,
        watch,
        sectionVariants,
        direction,
    } = additionalProps;

    const watchFirstName = watch("firstName");
    const watchLastName = watch("lastName");
    const watchDateOfBirth = watch("dateOfBirth");
    const watchCivilStatus = watch("civilStatus");

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="salutation"
                        label={t("salutation") + "*"}
                        data={tenantGender}
                        errorFlag={!!errors.salutation}
                        errorText={t(errors?.salutation?.message)}
                    />
                    {/*
                    <ControllerRadioField
                        name="salutation"
                        label={t("salutation") + "*"}
                        control={control}
                        errorFlag={!!errors?.salutation}
                        errorText={t(errors?.salutation?.message)}
                    >
                        {tenantGender.map((gender) => (
                            <FormControlLabel
                                key={gender.id}
                                label={t(gender.label)}
                                value={gender.value}
                                control={<Radio />}
                            />
                        ))}
                    </ControllerRadioField>
                    */}
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="title"
                        label={t("title")}
                        data={tenantTitle}
                        errorFlag={!!errors?.title}
                        errorText={t(errors?.title?.message)}
                    />
                    {/*
                    <ControllerRadioField
                        name="title"
                        label={t("title")}
                        control={control}
                    >
                        {tenantTitle.map((gender) => (
                            <FormControlLabel
                                key={gender.id}
                                label={t(gender.label)}
                                value={gender.value}
                                control={<Radio />}
                            />
                        ))}
                    </ControllerRadioField>
                    */}
                    {title === "other" && (
                        <ControllerTextField
                            control={control}
                            name="otherTitle"
                            // title={t("dossier_first_name_info")}
                            label={t("title")}
                            errorFlag={!!errors?.otherTitle}
                            errorText={t(errors?.otherTitle?.message)}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerTextField
                        control={control}
                        name="firstName"
                        label={t("first_name") + "*"}
                        errorFlag={!!errors?.firstName}
                        errorText={t(errors?.firstName?.message)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerTextField
                        control={control}
                        name="lastName"
                        label={t("last_name") + "*"}
                        errorFlag={!!errors?.lastName}
                        errorText={t(errors?.lastName?.message)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FieldDottedLineSpacer />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerDateField
                        control={control}
                        openTo={"year"}
                        name="dateOfBirth"
                        disableFuture
                        shouldDisableYear={(date) =>
                            isGreaterThanYear(date, 18)
                        }
                        label={t("date_of_birth") + "*"}
                        errorFlag={!!errors?.dateOfBirth}
                        errorText={t(errors?.dateOfBirth?.message)}
                        lang={currentLanguageCode}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerSelectField
                        control={control}
                        name="civilStatus"
                        label={t("civil_status")}
                    >
                        {civilStatusArr?.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </ControllerSelectField>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerAutoCompleteField
                        label={t("nationality") + "*"}
                        name="nationality"
                        options={getAllCountry}
                        disableClearable
                        errorFlag={!!errors?.nationality}
                        errorText={t(errors?.nationality?.message)}
                    />
                </Grid>

                {watchNationality === null ||
                watchNationality?.toLowerCase() === "switzerland" ? null : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid item container spacing={4}>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Controller
                                    name="residencyPermit"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            {...field}
                                            variant="filled"
                                            label={t("permit") + "*"}
                                            error={!!errors?.residencyPermit}
                                            helperText={t(
                                                errors?.residencyPermit?.message
                                            )}
                                            fullWidth
                                        >
                                            {permitOptionsArr.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {`${t(option.label)} (${t(
                                                        option?.shortDescription
                                                    )})`}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <ControllerDateField
                                    control={control}
                                    name="dateOfEntry"
                                    label={t("date_of_entry") + "*"}
                                    disableFuture
                                    errorFlag={!!errors?.dateOfEntry}
                                    errorText={t(errors?.dateOfEntry?.message)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </DossierFormSection>
    );
};

export default TenantOneSegmentOne;
