import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";

function DossierFormSection({
    heading,
    children,
    mainStyles,
    formContainerStyles,
    Icon,
    loading,
    index,
    currentSection,
    sectionVariants,
    direction,
}) {
    const theme = useTheme();

    if (index !== currentSection) {
        return null;
    }

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={currentSection}
                custom={direction}
                variants={sectionVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: 1.0 }}
            >
                <Stack gap={1} my={1} {...mainStyles}>
                    <Stack direction="row" alignItems={"center"} columnGap={1}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                justifyContent: "center",
                                width: "95%",
                            }}
                        >
                            {Icon && (
                                <>
                                    <Icon
                                        sx={{ color: "#68437E", fontSize: 32 }}
                                    />
                                </>
                            )}

                            <Typography
                                variant="h6"
                                color="#68437e"
                                sx={{ ml: -0.5 }}
                            >
                                {heading}
                            </Typography>
                        </Box>
                        <Stack>
                            {loading && (
                                <Box sx={{ position: "relative" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        sx={{
                                            color: theme.palette.grey[300],
                                        }}
                                        size={30}
                                        thickness={4}
                                        value={100}
                                    />
                                    <CircularProgress
                                        variant="indeterminate"
                                        disableShrink
                                        sx={{
                                            color: theme.palette.secondary.main,
                                            animationDuration: "550ms",
                                            position: "absolute",
                                            left: 0,
                                            [`& .${circularProgressClasses.circle}`]:
                                                {
                                                    strokeLinecap: "round",
                                                },
                                        }}
                                        size={30}
                                        thickness={4}
                                    />
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                    <Box
                        p={3}
                        sx={{ border: "1px dashed #68437e", borderRadius: 5 }}
                        {...formContainerStyles}
                    >
                        {children}
                    </Box>
                </Stack>
            </motion.div>
        </AnimatePresence>
    );
}

export default DossierFormSection;
