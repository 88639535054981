import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

// const token = localStorage.getItem("nch_id");

//add and update
function addProfilePicture(formData) {
    return Axios.post("/users/uploadprofile", formData);
}

export function useUploadProfilePicture() {
    return useMutation(addProfilePicture);
}
