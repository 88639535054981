import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const EnglishImprint = () => {
    const h1Style = {
        paddingBottom: "15px",
    };
    const h2Style = {
        paddingBottom: "15px",
    };

    return (
        <Container maxWidth="md" sx={{ paddingTop: "80px" }}>
            <Grid container spacing={6}>
                <Grid item sx={{ paddingBottom: "50px" }}>
                    <Typography variant="h3" sx={h1Style}>
                        Impressum
                    </Typography>
                    <Typography variant="subtitle2">
                        Responsible entity: nextflat AG
                        <br />
                        Riedmattstrasse 9<br />
                        8153 Rümlang
                        <br />
                        Switzerland
                        <br />
                        <br />
                        <b>E-Mail:</b>&nbsp;info@nextflat.ch
                        <br />
                        <br />
                        <Typography variant="h7" sx={h2Style}>
                            <b>Persons authorized to represent the company:</b>
                            &nbsp;Mr. Taman Choschnau (Managing Director)
                            <br />
                        </Typography>
                        <br />
                        <Typography variant="h7" sx={h2Style}>
                            <b>Company name:</b>&nbsp;nextflat AG
                        </Typography>
                        <br />
                        <Typography variant="h7" sx={h2Style}>
                            <b>Registrationsnummer:</b>&nbsp;CH-020.3.051.235-5
                            <br />
                        </Typography>
                        <Typography variant="h7" sx={h2Style}>
                            <b>Sales tax identification number:</b>
                            &nbsp;CHE-417.247.189
                            <br />
                        </Typography>
                        <br />
                        <br />
                        <b>Disclaimer</b>
                        <br />
                        The author does not guarantee the correctness, accuracy,
                        timeliness, reliability and completeness of the
                        information. Liability claims regarding damage caused by
                        the use of any information provided, including any kind
                        of information which is incomplete or incorrect, will
                        therefore be rejected. All offers are subject to change.
                        Parts of the pages or the complete publication including
                        all offers and information might be extended, changed or
                        partly or completely deleted by the author without
                        separate announcement.
                        <br />
                        <br />
                        <b>Disclaimer for contents and links</b>
                        <br />
                        References and links to third party websites are beyond
                        our responsibility. Any responsibility for such websites
                        is declined. The access and use of such websites is at
                        the own risk of the respective user.
                        <br />
                        <br />
                        <b>Copyright declaration</b>
                        <br />
                        The copyright and all other rights to the content,
                        images, photos or other files on this website belong
                        exclusively to Testfirma or the specifically named
                        rights holders. For the repro
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EnglishImprint;
