import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

//* Employer reference button click

function onSendEmail(data) {
    return Axios.post("/contact", data);
}

export function useSendMail() {
    return useMutation(onSendEmail);
}
