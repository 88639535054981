import * as React from "react";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";

function LinkedInIcon(props) {
    return (
        <SvgIcon
            {...props}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM25.6386 25.0811V18.9962C25.6386 15.7354 23.9182 14.2187 21.616 14.2187C19.761 14.2187 18.9351 15.2491 18.4689 15.9719V14.4678H14.979C15.0259 15.4637 14.979 25.0804 14.979 25.0804H18.4682V19.1533C18.4682 18.8385 18.496 18.5196 18.5876 18.2918C18.8422 17.6594 19.4122 17.0022 20.3786 17.0022C21.6424 17.0022 22.146 17.9728 22.146 19.4009V25.0811H25.6386ZM18.4689 15.9719V16.0083H18.4492C18.4517 16.001 18.4558 15.9947 18.4597 15.9885C18.4632 15.983 18.4666 15.9777 18.4689 15.9719ZM9.35002 11.1857C9.35002 10.1418 10.1319 9.35029 11.3256 9.35029C12.5223 9.35029 13.2545 10.1418 13.2783 11.1857C13.2783 12.2017 12.5221 13.0199 11.302 13.0199H11.2809C10.1088 13.0199 9.35002 12.2017 9.35002 11.1857ZM13.0489 25.081H9.55826V14.467H13.0489V25.081Z"
            />
        </SvgIcon>
    );
}

export default function SvgIconsSize() {
    return (
        <Stack alignItems="center" sx={{ display: "inline-block" }}>
            <LinkedInIcon fontSize="large" />
        </Stack>
    );
}
