import PropTypes from "prop-types";

//* mui imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Container, Skeleton, styled, useTheme } from "@mui/material";

//* shapes
import Droplet from "../shapes/Droplet";
import { useTranslation } from "react-i18next";
import ArrowButton from "../elements/button/ArrowButton";
import useResponsive from "../../hooks/responsive/useResponsive";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import {
    Home_Header_w_1000,
    Home_Header_w_200,
    Home_Header_w_607,
} from "../../utils/imgImports";
import useImagePreloader from "../../hooks/preload-image/usePreloadImage";

const HeroBox = styled(Box, {
    shouldForwardProp: (props) => props !== "coloredBg",
})(({ theme, coloredBg }) => ({
    backgroundColor: coloredBg ? theme.palette.primary.main : "transparent",
    height: 775,
    borderRadius: coloredBg ? "0px 0px 50px 50px" : "none",
    overflow: "hidden",
}));

const HeroContainer = styled(Container)(() => ({
    height: "100%",
    position: "relative",
}));

const HeroImage = styled(Box, {
    shouldForwardProp: (props) => props !== "layoutDirection",
})(({ layoutDirection }) => {
    let positionDirection =
        layoutDirection === "reverse" ? { left: 0 } : { right: 0 };
    return {
        position: "absolute",
        bottom: 0,
        ...positionDirection,
    };
});

const MobileHeroBox = styled(Box, {
    shouldForwardProp: (props) => props !== "coloredBg",
})(({ theme, coloredBg }) => ({
    backgroundColor: coloredBg ? theme.palette.primary.main : "transparent",
    minHeight: 778,
    borderRadius: coloredBg ? "0px 0px 30px 30px" : "none",
    overflow: "hidden",
}));

const CenterBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",
}));

const HeroLayout = ({
    heroDropletText,
    dropletImage,
    dropletImagePosition,
    dropletImageSize,
    pageName,
    heroHeading,
    heroSubHeading,
    heroButtonText,
    heroButtonOnClick,
    heroHeadingProperty,
    heroSubHeadingProperty,
    heroButtonTextProperty,
    heroButtonOnClickProperty,
    dropletImageAlt,
    coloredBg,
    layoutDirection,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isBelowLg } = useResponsive();

    if (isBelowLg) {
        return (
            <MobileHeroBox coloredBg={coloredBg}>
                <Grid container rowSpacing={3.75}>
                    <Grid item xs={12}>
                        <CenterBox pt={5}>
                            <Droplet
                                position={dropletImagePosition}
                                size={443}
                                containerStyleProps={{ overflow: "hidden" }}
                            >
                                <img
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                    }}
                                    src={dropletImage}
                                    alt={dropletImageAlt}
                                />
                            </Droplet>
                        </CenterBox>
                    </Grid>
                    <Grid item xs={12}>
                        <CenterBox px={2.125} pb={2.125}>
                            <Stack
                                rowGap={3}
                                height={"100%"}
                                justifyContent={"center"}
                            >
                                {pageName && (
                                    <Typography variant="h5" color="secondary">
                                        {t(pageName)}
                                    </Typography>
                                )}

                                <Typography
                                    variant="h4"
                                    {...(coloredBg
                                        ? {
                                              color: theme.palette.common.white,
                                          }
                                        : {})}
                                >
                                    {t(heroHeading)}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    {...(coloredBg
                                        ? {
                                              color: theme.palette.common.white,
                                          }
                                        : {})}
                                >
                                    {t(heroSubHeading)}
                                </Typography>
                                <Box>
                                    <ArrowButton
                                        variant="contained"
                                        color={
                                            coloredBg ? "secondary" : "primary"
                                        }
                                        startIcon={<PersonIcon />}
                                        onClick={heroButtonOnClick}
                                    >
                                        {t(heroButtonText)}
                                    </ArrowButton>
                                </Box>
                                {heroHeadingProperty && (
                                    <>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                mt: 4,
                                                ...(coloredBg
                                                    ? {
                                                          color: theme.palette
                                                              .common.white,
                                                      }
                                                    : {}),
                                            }}
                                        >
                                            {t(heroHeadingProperty)}
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            {...(coloredBg
                                                ? {
                                                      color: theme.palette
                                                          .common.white,
                                                  }
                                                : {})}
                                        >
                                            {t(heroSubHeadingProperty)}
                                        </Typography>
                                        <Box>
                                            <ArrowButton
                                                variant="contained"
                                                color={
                                                    coloredBg
                                                        ? "secondary"
                                                        : "primary"
                                                }
                                                sx={{ mt: 2 }}
                                                startIcon={<HomeIcon />}
                                                onClick={
                                                    heroButtonOnClickProperty
                                                }
                                            >
                                                {t(heroButtonTextProperty)}
                                            </ArrowButton>
                                        </Box>
                                    </>
                                )}
                            </Stack>
                        </CenterBox>
                    </Grid>
                </Grid>
            </MobileHeroBox>
        );
    }

    return (
        <HeroBox coloredBg={coloredBg}>
            <HeroContainer maxWidth="xl" style={{ marginTop: "-25px" }}>
                <Container maxWidth="lg" sx={{ height: "100%" }}>
                    <Grid
                        container
                        columnSpacing={3}
                        sx={{ height: "100%" }}
                        {...(layoutDirection === "reverse"
                            ? { direction: "row-reverse" }
                            : { direction: "row" })}
                    >
                        <Grid item md={5}>
                            <Stack
                                rowGap={3}
                                height={"100%"}
                                justifyContent={"center"}
                            >
                                {pageName && (
                                    <Typography variant="h5" color="secondary">
                                        {t(pageName)}
                                    </Typography>
                                )}

                                <Typography
                                    variant="h4"
                                    {...(coloredBg
                                        ? { color: theme.palette.common.white }
                                        : {})}
                                >
                                    {t(heroHeading)}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    {...(coloredBg
                                        ? { color: theme.palette.common.white }
                                        : {})}
                                >
                                    {t(heroSubHeading)}
                                </Typography>
                                <ArrowButton
                                    variant="contained"
                                    color={coloredBg ? "secondary" : "primary"}
                                    startIcon={<PersonIcon />}
                                    onClick={heroButtonOnClick}
                                >
                                    {t(heroButtonText)}
                                </ArrowButton>
                                {/* Optional Second Section */}
                                {heroHeadingProperty && (
                                    <>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                mt: 4,
                                                ...(coloredBg
                                                    ? {
                                                          color: theme.palette
                                                              .common.white,
                                                      }
                                                    : {}),
                                            }}
                                        >
                                            {t(heroHeadingProperty)}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            {...(coloredBg
                                                ? {
                                                      color: theme.palette
                                                          .common.white,
                                                  }
                                                : {})}
                                        >
                                            {t(heroSubHeadingProperty)}
                                        </Typography>
                                        <ArrowButton
                                            variant="contained"
                                            color={
                                                coloredBg
                                                    ? "secondary"
                                                    : "primary"
                                            }
                                            onClick={heroButtonOnClickProperty}
                                        >
                                            {t(heroButtonTextProperty)}
                                        </ArrowButton>
                                    </>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>

                <HeroImage layoutDirection={layoutDirection}>
                    {heroDropletText && heroDropletText}
                    <Droplet
                        position={dropletImagePosition}
                        size={dropletImageSize}
                        containerStyleProps={{ overflow: "hidden" }}
                    >
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                            }}
                            src={dropletImage}
                            alt={dropletImageAlt}
                        />
                    </Droplet>
                </HeroImage>
            </HeroContainer>
        </HeroBox>
    );
};

HeroLayout.propTypes = {
    coloredBg: PropTypes.bool,
    dropletImage: PropTypes.string,
    dropletImageAlt: PropTypes.string,
    dropletImageBgcolor: PropTypes.string,
    dropletImagePosition: PropTypes.string,
    dropletImageSize: PropTypes.number,
    heroButtonOnClick: PropTypes.func,
    heroButtonText: PropTypes.string,
    heroDropletText: PropTypes.any,
    heroHeading: PropTypes.string,
    heroSubHeading: PropTypes.string,
    layoutDirection: PropTypes.string,
    pageName: PropTypes.string,
};

export default HeroLayout;
