import { useMutation, useQuery } from "react-query";
import { Axios } from "../../axios/axios";

// const token = localStorage.getItem("nch_id");

function getDossierConfirmation({ queryKey }) {
    const id = queryKey[1];

    return Axios.get(`/property/get_check_dossier_user?user_id=${id}`, {
        // headers: {
        //     "x-access-token": token,
        // },
    });
}

export function useCheckDossier(id) {
    return useQuery(["check_dossier", id], getDossierConfirmation, {
        enabled: Boolean(id),
    });
}

function checkDossierScore(data) {
    return Axios.post("property/get_score_dossier", data);
}

export function useCheckDossierScore() {
    return useMutation(checkDossierScore);
}
