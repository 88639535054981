import React from "react";

//*  Translation
import { useTranslation } from "react-i18next";

//* libraries
// import { useNavigate } from "react-router-dom";

//* custom components
// import { Img } from "../../component/img/Img";

//* import image imports
import {
  // movingService,
  // rentalService,
  serviceImage,
  // service_coming_soon,
  // service_coming_soon_w_1304,
  // service_coming_soon_w_1400,
  // service_coming_soon_w_200,
  // service_coming_soon_w_878,
} from "../../assets/imgImport";

//* mui components import
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import CardActionArea from "@mui/material/CardActionArea";
import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* import mui icons
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box } from "@mui/material";

function AllService() {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: `linear-gradient(
                  rgba(254, 117, 41, 0.07), 
                  rgba(0, 0, 0, 0.07)
                ),url(${serviceImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth='lg'>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={3}
          pt={13}
        >
          <Typography variant='h2' color={"white"} textAlign='center'>
            {t("services")}
          </Typography>
          <Typography variant='h4' color={"white"} textAlign={"center"}>
              In Zukunft werden Sie hier viele zusätzliche Dienstleistungen für Ihren Umzug an einen neuen Standort finden, z.B. Versicherungen, Logistik, hilfreiche Informationen usw.
          </Typography>
          {/* <Img
                        srcSet={`(${service_coming_soon_w_200} 200w, ${service_coming_soon_w_878} 873w , ${service_coming_soon_w_1304} 1304w , ${service_coming_soon_w_1400} 1400w)`}
                        src={service_coming_soon}
                        height={450}
                        style={{ borderRadius: 8, paddingBottom: 70 }}
                        width={"50%"}
                        loading={"lazy"}
                    /> */}
        </Stack>

        {/* <Grid container mt={8} mb={8}>
          <Grid item xs={6}>
            <Card sx={{ maxWidth: 545, borderRadius: 4 }}>
              <CardActionArea onClick={() => navigate("/moving-service")}>
                <CardMedia
                  component='img'
                  height='340'
                  image={movingService}
                  sx={{ borderRadius: 6, p: 1 }}
                />
                <CardContent sx={{ textAlign: "center" }}>
                  <Stack spacing={2}>
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <Typography variant='h4' component='div'>
                        {t("moving_service")}
                      </Typography>
                      <OpenInNewIcon />
                    </Stack>
                    <Typography component={"p"}>
                      Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                      Qui quaerat fugit quas veniam perferendis repudiandae
                      sequi, dolore quisquam illum.
                    </Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card sx={{ maxWidth: 545, borderRadius: 4 }}>
              <CardActionArea>
                <CardMedia
                  component='img'
                  height='340'
                  image={rentalService}
                  sx={{ borderRadius: 6, p: 1 }}
                />
                <CardContent sx={{ textAlign: "center" }}>
                  <Stack spacing={2}>
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <Typography variant='h4' component='div'>
                        {t("rental_insurance")}
                      </Typography>
                      <OpenInNewIcon />
                    </Stack>
                    <Typography component={"p"}>
                      Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                      Qui quaerat fugit quas veniam perferendis repudiandae
                      sequi, dolore quisquam illum.
                    </Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  );
}

export default AllService;
