// Home Routes and pages
import { Suspense, lazy } from "react";
import PrivateRoute from "./PrivateRoute";

import Loading from "../component/loading/Loading";
import Contact from "../pages/Contact-us/Contact";
import Faq from "../pages/FAQ/Faq";
import Partnership from "../pages/Partnership/Partnership";
import ReferenceSuccessful from "../pages/References/ReferenceSuccessful";
import AllService from "../pages/Services/AllService";

import AboutUs from "../pages/About-us/AboutUs";
import Features from "../pages/Features/Feature";
import HomePage from "../pages/Home-page/HomePage";

import Imprint from "../pages/Imprint/Imprint";
import PrivacyPolicy from "../pages/Privacy-Policy/PrivacyPolicy";
import TermsAndConditions from "../pages/Terms-and-conditions/TermsAndConditions";

// smart-dossier
import DossierPayment from "../pages/Payments/DossierPayment";
import CreatePropertyObject from "../pages/Property/CreatePropertyObject";
import Explore from "../pages/Property/Explore";
import ExploreList from "../pages/Property/ExploreList";
import PreviewPropertyDetails from "../pages/Property/PreviewPropertyDetails";
import PropertyDetails from "../pages/Property/PropertyDetails";
import BriefDossierForm from "../pages/Smart-Dossier/BriefDossierForm";
import CreateSmartDossier from "../pages/Smart-Dossier/CreateSmartDossier";
import DossierCreationAlert from "../pages/Smart-Dossier/DossierCreationAlert";
import DossierForm from "../pages/Smart-Dossier/DossierForm";
import SmartDossierPaymentStatus from "../pages/Smart-Dossier/SmartDossierPaymentStatus";

const AddPropertyDetails = lazy(() =>
    import("../pages/Property/AddPropertyDetail")
);

const AddPropertyDetailsFastLane = lazy(() =>
    import("../pages/Property/AddPropertyDetailFastLane")
);

const SwitchUser = lazy(() => import("../pages/Switch-user/SwitchUser"));
const PaymentSponsor = lazy(() => import("../pages/Payments/PaymentSponsor"));
const ResetPassword = lazy(() => import("../pages/Forgot/ResetPassword"));
const EmployerReferenceForm = lazy(() =>
    import("../pages/References/EmployerReferenceForm")
);
const AdministrationReferenceForm = lazy(() =>
    import("../pages/References/AdministrationReferenceForm")
);

const SocialLoginSuccess = lazy(() =>
    import("../pages/Social-login/SocialLoginSuccess")
);

const MAIN_ROUTES = [
    {
        id: "nf-home",
        path: "/",
        element: <HomePage />,
    },

    {
        id: "nf-about",
        path: "/about",
        element: <AboutUs />,
    },
    {
        id: "nf-create-dossier",
        path: "/create-dossier",
        element: <CreateSmartDossier />,
    },
    {
        id: "nf-term-condition",
        path: "/terms-conditions",
        element: <TermsAndConditions />,
    },

    {
        id: "nf-all-service",
        path: "/all-service",
        element: <AllService />,
    },
    {
        id: "nf-privacy-policy",
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
    },
    {
        id: "nf-contact",
        path: "/contact",
        element: <Contact />,
    },
    {
        id: "nf-faq",
        path: "/faq",
        element: <Faq />,
    },
    {
        id: "nf-brief-dossier-form",
        path: "/brief-dossier-form",
        element: (
            <PrivateRoute>
                <BriefDossierForm />
            </PrivateRoute>
        ),
    },

    {
        id: "nf-dossier-form",
        path: "/dossier-form/:dossierFor",
        element: <DossierForm />,
    },

    {
        id: "nf-payment-sponsor",
        path: "/payment-sponsor",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <PaymentSponsor />
            </Suspense>
        ),
    },
    {
        id: "nf-dossier-payment-status",
        path: "/smart-dossier-payment-status",
        element: <SmartDossierPaymentStatus />,
    },

    {
        id: "nf-dossier-payment",
        path: "/dossier-payment",
        element: <DossierPayment />,
    },
    {
        id: "nf-success-smart-dossier",
        path: "/success-smart-dossier",
        element: <DossierCreationAlert />,
    },
    {
        id: "nf-reset-password",
        path: "/reset-password/:email/:token",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <ResetPassword />
            </Suspense>
        ),
    },

    {
        id: "nf-employer-reference-form",
        path: "/employer-reference-form",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <EmployerReferenceForm />
            </Suspense>
        ),
    },
    {
        id: "nf-administration-reference-form",
        path: "/administration-reference-form",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <AdministrationReferenceForm />
            </Suspense>
        ),
    },
    {
        id: "nf-partnership",
        path: "/partnership",
        element: <Partnership />,
    },
    {
        id: "nf-reference-successful",
        path: "/reference-successful",
        element: <ReferenceSuccessful />,
    },
    {
        id: "nf-features",
        path: "/features",
        element: <Features />,
    },
    {
        id: "nf-imprint",
        path: "/imprint",
        element: <Imprint />,
    },

    {
        id: "nf-explore",
        path: "/explore",
        element: <Explore />,
    },
    {
        id: "nf-explore-list",
        path: "/explore-list",
        element: <ExploreList />,
    },
    {
        id: "nf-property-detail",
        path: "/property/:id",
        element: <PropertyDetails />,
    },

    {
        id: "nf-create-property",
        path: "/create-object",
        element: <CreatePropertyObject />,
    },
    {
        id: "nf-add-property-details",
        path: "/add-property-details/:propertyId",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <AddPropertyDetails />
            </Suspense>
        ),
    },
    {
        id: "nf-add-property-details-fast-lane",
        path: "/add-property-details-fast-lane/:propertyId",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <AddPropertyDetailsFastLane />
            </Suspense>
        ),
    },
    {
        id: "nf-preview-property",
        path: "/preview-property/:propertyId",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <PreviewPropertyDetails />
            </Suspense>
        ),
    },
    {
        id: "nf-oauth-google-success",
        path: "/oauth-google-success",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <SocialLoginSuccess />
            </Suspense>
        ),
    },
    {
        id: "nf-admin-switch-user",
        path: "/switch-user-login/:id",
        element: (
            <Suspense
                fallback={
                    <Loading
                        containerStyle={{
                            height: "100vh",
                        }}
                    />
                }
            >
                <SwitchUser />
            </Suspense>
        ),
    },
];

export default MAIN_ROUTES;
