import React, { Fragment, useState } from "react";

//* using toast for success and error
import { toast, Flip } from "react-toastify";

//* Translation
import { useTranslation } from "react-i18next";

//* libraries imports
import { Link } from "react-router-dom";

//*api calls
import { useAddWishlist } from "../../hooks/properties/useWishlist";

//* store imports
import { useStateValue } from "../../store/StateProvider";

//* mui components import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import PlaceIcon from "@mui/icons-material/Place";

//* utility function
import {
    getPropertyCategory,
    getPropertyDescription,
    getPropertyFRL,
    getPropertyName,
    getPropertyPrice,
    getPropertyStreet,
} from "../../utils/getPropertyTypes";

//* custom components
import {
    CarousalImages,
    PropertyCategoryAndPrice,
    PropertyNameTooltip,
} from "../mui-components/MuiPropertyCardComponents";

//* custom options
import { PROPERTY_DETAILS } from "../../static_options/propertyCardOptions";
import useResponsive from "../../hooks/responsive/useResponsive";

//* imageUrl = dynamic image path
const imageUrl = process.env.REACT_APP_PROPERTY_IMAGE_URL;

function PropertyCardWithDescription({
    property,
    isFavoritePage,
    refetchFavorite,
}) {
    const { t } = useTranslation();
    const [{ user_id, token }] = useStateValue();
    const { isBelowMd } = useResponsive();
    let propertyDescriptionTrimLength = 150;
    const { mutate } = useAddWishlist();
    const [isFavoriteProperty, setIsFavoriteProperty] = useState(
        Boolean(property.is_fav)
    );

    const addToWishlist = (property_id) => {
        if (token === null) {
            return toast.info("Please login to add a property to favorite");
        }

        let userData = {
            property_id,
            user_id,
        };

        setIsFavoriteProperty(!isFavoriteProperty);

        mutate(userData, {
            onSuccess: (data) => {
                // setIsFavId(data?.data?.data);
                isFavoritePage && refetchFavorite();
                if (!data?.data?.statuscode) return;
                // toast.success(data?.data?.message);
            },
            onError: (errors) => {
                // toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    return (
        <Paper
            sx={{
                p: 1.25,
                borderRadius: 5,
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
            }}
            elevation={0}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{ position: "relative", height: 320 }}
                >
                    <CarousalImages
                        propertyImages={property?.images}
                        altTags={property?.street_no}
                        imageUrl={imageUrl + property?.id + "/"}
                        height={"300px"}
                        width={"100%"}
                    />
                    {/* Favorite Icon */}
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={30}
                        width={30}
                        borderRadius={"50%"}
                        bgcolor={"#fe7529"}
                        sx={{
                            position: "absolute",
                            top: 30,
                            right: 20,
                            zIndex: 1,
                            cursor: "pointer",
                        }}
                    >
                        {/* property?.is_fav === 1 ||
                        isFavoriteProperty ||
                        isFavoritePage ?  */}
                        {isFavoriteProperty ? (
                            <FavoriteIcon
                                onClick={() => {
                                    addToWishlist(property?.id);
                                }}
                                sx={{
                                    color: "#fff",
                                }}
                            />
                        ) : (
                            <FavoriteBorderOutlinedIcon
                                onClick={() => {
                                    addToWishlist(property?.id);
                                }}
                                sx={{
                                    color: "#fff",
                                }}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Link
                        to={`/property/${property?.id}`}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                    >
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <PropertyCategoryAndPrice
                                        color={"#55b7f8"}
                                        background={"#eef8fe"}
                                        styleProps={{ padding: "10px 5px" }}
                                    >
                                        {getPropertyCategory(
                                            property?.category_name
                                        )}
                                    </PropertyCategoryAndPrice>
                                    <PropertyCategoryAndPrice
                                        color={"#FE5900"}
                                        background={
                                            "linear-gradient(0deg, rgba(254, 89, 0, 0.2), rgba(254, 89, 0, 0.2)), #FFFFFF"
                                        }
                                        styleProps={{ padding: "10px 5px" }}
                                    >
                                        {getPropertyPrice(property?.price)}
                                    </PropertyCategoryAndPrice>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12}>
                                        <PropertyNameTooltip
                                            property={t(
                                                getPropertyName(property?.name)
                                            )}
                                        >
                                            <Typography variant="h4">
                                                {t(
                                                    getPropertyStreet(
                                                        property?.street_no
                                                    )
                                                )}
                                            </Typography>
                                        </PropertyNameTooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">
                                            {getPropertyDescription(
                                                property?.description,
                                                propertyDescriptionTrimLength
                                            )}
                                            {property?.description?.length >
                                                propertyDescriptionTrimLength && (
                                                <strong>"...more"</strong>
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 2,
                                        }}
                                    >
                                        <PlaceIcon sx={{ color: "#fe7529" }} />
                                        <Typography variant="subtitle1">
                                            {isFavoritePage
                                                ? property?.new_city_name
                                                : property?.city_name}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: { xs: 3, md: 5 },
                                        }}
                                    >
                                        {PROPERTY_DETAILS.map((item) => (
                                            <Fragment key={item.id}>
                                                <Box
                                                    display="flex"
                                                    flexDirection={"row"}
                                                    gap={2}
                                                >
                                                    {item.icon}
                                                    <Typography
                                                        color={"#123763"}
                                                        textTransform={
                                                            "capitalize"
                                                        }
                                                    >
                                                        {`${getPropertyFRL(
                                                            property,
                                                            item.type
                                                        )} ${
                                                            isBelowMd
                                                                ? ""
                                                                : item.type
                                                        }`}
                                                    </Typography>
                                                </Box>
                                                {item.isLast || (
                                                    <span
                                                        style={{
                                                            color: "#55b7f8",
                                                        }}
                                                    >
                                                        &#8226;
                                                    </span>
                                                )}
                                            </Fragment>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PropertyCardWithDescription;
