import {  useQuery } from "react-query";
import { Axios } from "../../axios/axios";

//get
function getDossierScore({ queryKey }) {
    const id = queryKey[1];
    return Axios.get(`/users/SmartDossierScore?user_id=${id}`);
}

export function useGetDossierScore(user_id) {
    return useQuery(["get-dossier-score", user_id], getDossierScore, {
        enabled: Boolean(user_id),
    });
}
