import React, { useEffect } from "react";

//*  using toast for success and error
// import { toast, Zoom } from "react-toastify";

//* translation
// import { useTranslation } from "react-i18next";

//* libraries
import { useNavigate, useParams } from "react-router-dom";

//* api calls
import usePropertyById from "../../hooks/properties/usePropertyById";

//* store imports
import { useStateValue } from "../../store/StateProvider";

//* property images and video imports
import PropertyImages from "../../component/properties/property-details/property-media/PropertyImages";
import PropertyVideo from "../../component/properties/property-details/property-media/PropertyVideo";

//* component imports
import PropertyFeatures from "../../component/properties/property-details/property-detail-desktop/PropertyFeatures";
import PropertyDescription from "../../component/properties/property-details/property-detail-desktop/PropertyDescription";
import PropertyAmenities from "../../component/properties/property-details/property-detail-desktop/PropertyAmenities";
import AdvertiserBrief from "../../component/properties/property-details/property-advertiser/AdvertiserBrief";
import ContactSeller from "../../component/properties/property-details/property-advertiser/ContactSeller";
import PropertyLocation from "../../component/properties/property-details/property-detail-desktop/PropertyLocation";
import PropertyHeading from "../../component/properties/property-details/property-detail-desktop/PropertyHeading";

//* mui components imports
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import PropertyDetailLoadingDesktopView from "../../component/skeleton/PropertyDetailLoadingDesktopView";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useUserProfile from "../../hooks/profile/useUserProfile";

import cookies from "js-cookie";
import AdministrationDetail from "../../component/properties/property-details/property-advertiser/AdministrationDetail";
import { checkForNull } from "../../utils/checkDataFunction";

const profileImageUrl = process.env.REACT_APP_PROFILE_IMAGE_URL;
const propertyImageUrl = process.env.REACT_APP_PROPERTY_IMAGE_URL;

export const PropertyDetailsDesktopView = () => {
    // const { t } = useTranslation();

    const { t } = useTranslation();
    const theme = useTheme();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const params = useParams();
    const [{ user_id }] = useStateValue();
    let property = {
        id: params.id,
        lang: currentLanguageCode,
        user_id: user_id,
    };
    const { isLoading, data } = usePropertyById(property);

    let propertyDetails = data?.data?.data[0];

    const video = propertyDetails?.youtube_link;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, [params.id]);

    let AdministrationDetailObj = {
        admin_or_owner_name: propertyDetails?.admin_or_owner_name,
        contact_person_email: propertyDetails?.contact_person_email,
        contact_person_mobile: propertyDetails?.contact_person_mobile,
        contact_person_first_name: propertyDetails?.contact_person_first_name,
        contact_person_last_name: propertyDetails?.contact_person_last_name,
        contact_person_position: propertyDetails?.contact_person_position,
    };

    const isObjectAllValueEmpty = (obj) => {
        if (obj === null || obj === undefined) return true;
        if (Object.keys(obj).length === 0 && obj.constructor === Object)
            return true;
        if (
            Object.values(obj).every(
                (value) => value === "" || value === null || value === undefined
            )
        )
            return true;
        return false;
    };

    if (isLoading) {
        return <PropertyDetailLoadingDesktopView />;
    }
    return (
        <Container maxWidth="lg" sx={{ p: 5 }}>
            <Grid container rowSpacing={1}>
                {/* Property Heading */}
                <Grid item xs={12}>
                    <PropertyHeading
                        propertyId={propertyDetails?.id}
                        isFav={propertyDetails?.is_fav}
                        propertyOwnerId={propertyDetails?.user_id}
                        userId={user_id}
                    />
                </Grid>
                {/* Property Images */}
                <Grid item xs={12}>
                    <PropertyImages
                        propertyDetails={propertyDetails}
                        imageUrl={propertyImageUrl + params.id + "/"}
                        height={"250px"}
                        sx={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: 12,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{
                            color: theme.palette.secondary.main,
                            p: 2,
                            pt: 3,
                            pb: 3,
                        }}
                    >
                        {propertyDetails?.name} ({propertyDetails?.id})
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            textDecoration: "none",
                            color: theme.palette.secondary.main,
                            cursor: "pointer",
                            mt: -2,
                            pb: 1,
                            pl: 2,
                        }}
                        // onClick={handleClickOpen}
                    >
                        {t("street")} : {propertyDetails?.street_no}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid item container>
                        <Grid item xs={12} md={8} p={2}>
                            {/* Features and Advertiser and contact Details */}
                            <Stack spacing={5}>
                                {/* Features details */}
                                {
                                    //console.log(propertyDetails)
                                }
                                <PropertyFeatures
                                    numberOfBedroom={
                                        propertyDetails?.number_bedroom
                                    }
                                    numberOfFloors={propertyDetails?.floor}
                                    price={propertyDetails?.price}
                                    livingSpace={propertyDetails?.living_space}
                                    categoryId={propertyDetails?.category_id}
                                    availabilityDate={
                                        propertyDetails?.availability_date
                                    }
                                    availability={propertyDetails?.availability}
                                />
                                {
                                    //console.log(propertyDetails)
                                }
                                <Divider variant="middle" />
                                <PropertyDescription
                                    propertyDescription={
                                        propertyDetails?.description
                                    }
                                    title={t("description")}
                                    propertyExternalLink={propertyDetails?.link}
                                    propertyId={propertyDetails?.id}
                                    documentFile={
                                        propertyDetails?.document_file
                                    }
                                />
                                <Divider
                                    sx={{ backgroundColor: "#f0bb9e61" }}
                                />
                                {
                                    //console.log("propertyDetails:", propertyDetails)
                                }

                                <PropertyAmenities
                                    attributes={propertyDetails?.attributes}
                                    title={t("property_detail_amenities")}
                                />
                                <Divider
                                    sx={{ backgroundColor: "#f0bb9e61" }}
                                />
                                {video && (
                                    <>
                                        <PropertyVideo video={video} />
                                        <Divider
                                            sx={{
                                                backgroundColor: "#f0bb9e61",
                                            }}
                                        />
                                    </>
                                )}
                                {/* Advertiser Details  */}
                                <AdvertiserBrief
                                    advertiserPhone={
                                        propertyDetails?.host_mobile
                                    }
                                    advertiserName={propertyDetails?.host_name}
                                    advertiserEmail={
                                        propertyDetails?.host_email
                                    }
                                    advertiserProfile={
                                        propertyDetails?.profile_pic
                                    }
                                    imageUrl={profileImageUrl}
                                    title={t(
                                        "property_detail_contact_provider"
                                    )}
                                />
                                {/* Advertiser Administration Details  */}

                                {!isObjectAllValueEmpty(
                                    AdministrationDetailObj
                                ) && (
                                    <>
                                        <Divider
                                            sx={{
                                                backgroundColor: "#f0bb9e61",
                                            }}
                                        />
                                        <AdministrationDetail
                                            adminOrOwnerName={
                                                propertyDetails?.admin_or_owner_name
                                            }
                                            contactPersonEmail={
                                                propertyDetails?.contact_person_email
                                            }
                                            contactPersonMobile={
                                                propertyDetails?.contact_person_mobile
                                            }
                                            contactPersonFirstName={
                                                propertyDetails?.contact_person_first_name
                                            }
                                            contactPersonLastName={
                                                propertyDetails?.contact_person_last_name
                                            }
                                            contactPersonPosition={
                                                propertyDetails?.contact_person_position
                                            }
                                            title={t(
                                                "property_detail_administration"
                                            )}
                                        />
                                    </>
                                )}
                            </Stack>
                        </Grid>
                        {/* Contact Seller details */}

                        <Grid
                            item
                            xs={12}
                            md={4}
                            p={2}
                            id="contact-seller-section"
                        >
                            <ContactSeller
                                name={propertyDetails?.name}
                                propertyId={propertyDetails?.id}
                                propertyOwnerId={propertyDetails?.user_id}
                                cityName={propertyDetails?.city_name}
                                zipCode={propertyDetails?.zip_code}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {checkForNull(propertyDetails?.latitude) ||
            checkForNull(propertyDetails?.longitude) ? (
                <>
                    <Divider />
                    <PropertyLocation
                        propertyType={propertyDetails?.type_id}
                        propertyPrice={propertyDetails?.price}
                        period={propertyDetails?.period}
                        name={propertyDetails?.name}
                        images={propertyDetails?.images}
                        latitude={propertyDetails?.latitude}
                        longitude={propertyDetails?.longitude}
                        streetNo={propertyDetails?.street_no}
                        cityName={propertyDetails?.city_name}
                        countryName={propertyDetails?.country_name}
                        numberOfBathroom={propertyDetails?.number_bathroom}
                        numberOfBedroom={propertyDetails?.number_bedroom}
                        numberOfFloor={propertyDetails?.floor}
                        livingSpace={propertyDetails?.living_space}
                        title={t("company_location")}
                    />
                </>
            ) : null}
        </Container>
    );
};
