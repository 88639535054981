import { useInfiniteQuery, useQuery } from "react-query";
import { Axios } from "../../axios/axios";

function getFaqs({ pageParam = 0, queryKey }) {
    const faqFilters = queryKey[1];
    const { lang, category_id } = faqFilters;
    return Axios.get(
        `/get_faq_questions?lang=${lang}&category_id=${category_id}&page=${pageParam}&limit=20`
    );
}

export function useGetFaqs(faqFilters) {
    return useInfiniteQuery(["get-FAQs", faqFilters], getFaqs, {
        getNextPageParam: (_lastPage, pages) => {
            if (pages.length < pages[0].data.totalPages) {
                return (pages.length - 1) * 1 + 1;
            } else {
                return undefined;
            }
        },
    });
}

// faq category
function getFaqCategory({ queryKey }) {
    const lang = queryKey[1];
    return Axios.get(`/get_faq_category?lang=${lang}`);
}

export function useGetFaqCategory(lang) {
    return useQuery(["get-faq-category", lang], getFaqCategory);
}
