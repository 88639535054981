import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function addWishProperty(wishProperty) {
    return Axios.post("/create_wish_properties", wishProperty);
}

export default function useAddDossierWishProperty() {
    return useMutation(addWishProperty);
}
