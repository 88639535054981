import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControllerSlider from "../../elements/textfield/ControllerSlider";
import ModalLayout from "../../layouts/ModalLayout";

import Chip from "@mui/material/Chip";
import Slider from "@mui/material/Slider";

import { Controller } from "react-hook-form";
import ControllerDateField from "../../elements/textfield/ControllerDateField";

import cookies from "js-cookie";
import { permitOptionsArr } from "../../../options/dossierOptions";
import { availability as AVAILABILITY } from "../../../options/propertyOptions";
import ControllerSelectField from "../../elements/textfield/ControllerSelectField";

import {
    useAddWishTenantForObject,
    useGetWishTenantData,
} from "../../../hooks/wish-tenant/useWishTenant";
import { useStateValue } from "../../../store/StateProvider";

import moment from "moment";
import { toast } from "react-toastify";
import Loading from "../../loading/Loading";
import { ListItemText, MenuItem, TextField } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const currentLanguageCode = cookies.get("i18next") || "en";
const minDistance = 2;
// const salaryMin = 20000;

const getAgeArray = (age = "") => {
    if (age === "") {
        return [15, 20];
    }
    let ageSplit = age.split("-");
    return ageSplit.map(Number);
};

const getResidencyPermit = (permit = "") => {
    if (permit === "") {
        return [];
    }
    let permitArr = permit.split(",");
    return permitArr;
};

const DEFAULT_VALUE = {
    ageRange: [15, 20],
    salaryRangeId: "",
    salaryRange: 0,
    // salaryRange: [0, 22000],
    hasPet: false,
    hasChildren: false,
    hasNoNegativeDebtEntries: false,
    residencyPermits: [],
    hasStableEmployment: false,
    hasSelfEmployment: false,
    isLiveInSwitzerland: false,
    hasAdminReference: false,
    hasEmployReference: false,
    moveInAvailability: "",
    moveInDate: null,
};

function WishTenantForm({ open, handleClose, propertyId }) {
    const [{ user_id }] = useStateValue();
    const { t } = useTranslation();
    const {
        data: wishTenantData,
        isLoading: isLoadingWishTenantData,
        status: statusWishTenantData,
    } = useGetWishTenantData(propertyId);

    const { mutate: addWishTenant } = useAddWishTenantForObject();

    const {
        control,
        handleSubmit,
        reset,
        watch,
        // formState: { errors },
    } = useForm({
        mode: "onSubmit",
        defaultValues: DEFAULT_VALUE,
    });

    const salaryRange = watch("salaryRange");
    const ageRange = watch("ageRange");
    const moveInAvailability = watch("moveInAvailability");
    const [showSwitzerlandField, setShowSwitzerlandField] = useState(false);
    const [showHasStableEmployment, setHasStableEmployment] = useState(false);

    useEffect(() => {
        if (!isLoadingWishTenantData && statusWishTenantData === "success") {
            let wishTenantResult = wishTenantData?.data?.result;
            if (
                Array.isArray(wishTenantResult) &&
                wishTenantResult.length > 0
            ) {
                let {
                    ageRange,
                    // salary_id,
                    salaryRange,
                    hasPet,
                    hasChildren,
                    hasNoNegativeDebtEntries,
                    residencyPermits,
                    hasStableEmployment,
                    hasSelfEmployment,
                    isLiveInSwitzerland,
                    hasAdminReference,
                    hasEmployReference,
                    moveInAvailability,
                    moveInDate,
                } = wishTenantResult[0];
                reset((values) => ({
                    ...values,
                    ageRange: getAgeArray(ageRange),
                    // salaryRangeId: salary_id,
                    salaryRange: isNaN(salaryRange)
                        ? 0
                        : parseInt(salaryRange) === 0
                        ? 0
                        : parseInt(salaryRange) / 1000,
                    // salaryRange: [0, 22000],
                    hasPet: Boolean(hasPet),
                    hasChildren: Boolean(hasChildren),
                    hasNoNegativeDebtEntries: Boolean(hasNoNegativeDebtEntries),
                    residencyPermits: getResidencyPermit(residencyPermits),
                    hasStableEmployment: Boolean(hasStableEmployment),
                    hasAdminReference: Boolean(hasAdminReference),
                    hasEmployReference: Boolean(hasEmployReference),
                    hasSelfEmployment: Boolean(hasSelfEmployment),
                    isLiveInSwitzerland: Boolean(isLiveInSwitzerland),
                    moveInAvailability,
                    moveInDate,
                }));
            }
        }
    }, [
        isLoadingWishTenantData,
        reset,
        statusWishTenantData,
        wishTenantData?.data?.result,
    ]);

    const onSubmit = (data) => {
        const {
            ageRange,
            salaryRangeId,
            hasPet,
            hasChildren,
            hasNoNegativeDebtEntries,
            residencyPermits,
            hasStableEmployment,
            hasSelfEmployment,
            isLiveInSwitzerland,
            hasAdminReference,
            hasEmployReference,
            moveInAvailability,
            moveInDate,
        } = data;

        let getAgeRange = (value) => {
            if (!Array.isArray(value)) {
                return [];
            }
            return value.join("-");
        };
        let wishTenantData = {
            user_id: user_id,
            property_id: propertyId,
            ageRange: getAgeRange(ageRange),
            // salary_id: salaryRangeId,
            salaryRange: isNaN(salaryRange)
                ? 0
                : parseInt(salaryRange) === 0
                ? 0
                : parseInt(salaryRange) * 1000,
            hasPet: Number(hasPet), //parsing it to pass 0 and 1 for true and false
            hasChildren: Number(hasChildren),
            hasNoNegativeDebtEntries: Number(hasNoNegativeDebtEntries),
            residencyPermits: residencyPermits,
            hasStableEmployment: Number(hasStableEmployment),
            hasSelfEmployment: Number(hasSelfEmployment),
            isLiveInSwitzerland: Number(isLiveInSwitzerland),
            hasAdminReference: Number(hasAdminReference),
            hasEmployReference: Number(hasEmployReference),
            moveInAvailability,
            moveInDate:
                moveInAvailability === "date"
                    ? moment(moveInDate).format("YYYY-MM-DD")
                    : null,
        };
        addWishTenant(wishTenantData, {
            onSuccess: (data) => {
                if (!data.data.statuscode) {
                    toast.error(t("something_went_wrong"));
                    return;
                }
                toast.success(t("wish_tenant_created"));
                handleClose();
            },
            onError: (error) => {
                console.error(error);
                toast.error(t("we_are_facing_some_technical_issue"));
            },
        });
    };

    return (
        <ModalLayout
            open={open}
            handleClose={handleClose}
            scroll={"paper"}
            maxWidth={"md"}
            dialogProps={{
                PaperProps: {
                    component: "form",
                    // onSubmit: handleSubmit(onSubmit),
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h4" mb={1}>
                    {t("create_wish_tenant")}
                </Typography>
                <Typography variant="subtitle1" mb={1}>
                    {t("create_wish_tenant_subtitle")}
                </Typography>
            </DialogTitle>
            <IconButton
                aria-label="close-download"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            {isLoadingWishTenantData ? (
                <Loading containerStyle={{ height: 400 }} />
            ) : (
                <>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5" mb={1}>
                                    {t(
                                        "yearly_gross_salary_incl_pro_rata_13_minimum"
                                    )}
                                </Typography>
                                <Typography variant="subtitle1" mb={1}>
                                    {`CHF ${salaryRange}K`}
                                </Typography>
                                <ControllerSlider
                                    control={control}
                                    name={"salaryRange"}
                                    min={0}
                                    step={10}
                                    max={180}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5" mb={1}>
                                    {t("wish_tenant_age")}
                                </Typography>
                                <Typography variant="subtitle1" mb={1}>
                                    {`${t("wish_tenant_age")}: ${ageRange[0]}-${
                                        ageRange[1]
                                    }`}
                                </Typography>
                                <Controller
                                    control={control}
                                    name={"ageRange"}
                                    render={({ field }) => {
                                        return (
                                            <Slider
                                                {...field}
                                                onChange={(
                                                    event,
                                                    newValue,
                                                    activeThumb
                                                ) => {
                                                    if (
                                                        !Array.isArray(newValue)
                                                    ) {
                                                        return;
                                                    }

                                                    if (
                                                        newValue[1] -
                                                            newValue[0] <
                                                        minDistance
                                                    ) {
                                                        if (activeThumb === 0) {
                                                            const clamped =
                                                                Math.min(
                                                                    newValue[0],
                                                                    100 -
                                                                        minDistance
                                                                );
                                                            field.onChange([
                                                                clamped,
                                                                clamped +
                                                                    minDistance,
                                                            ]);
                                                        } else {
                                                            const clamped =
                                                                Math.max(
                                                                    newValue[1],
                                                                    minDistance
                                                                );
                                                            field.onChange([
                                                                clamped -
                                                                    minDistance,
                                                                clamped,
                                                            ]);
                                                        }
                                                    } else {
                                                        field.onChange(
                                                            newValue
                                                        );
                                                    }
                                                }}
                                                valueLabelDisplay="auto"
                                                min={14}
                                                max={100}
                                                disableSwap
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={12} md={6}>
                                {showSwitzerlandField && (
                                    <Grid item xs={12} md={12}>
                                        <Controller
                                            name="isLiveInSwitzerland"
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={
                                                                field.value
                                                            }
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={t(
                                                        "create_wish_tenant_must_live_in_switzerland"
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={12}>
                                    <Controller
                                        name="hasPet"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                }
                                                label={t(
                                                    "create_wish_tenant_have_pets"
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Controller
                                        name="hasChildren"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                }
                                                label={t(
                                                    "create_wish_tenant_have_children"
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6}>
                                <Grid item xs={12} md={12}>
                                    <Controller
                                        name="hasNoNegativeDebtEntries"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body1">
                                                        {t(
                                                            "create_wish_tenant_no_negative_debt_entry"
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                {showHasStableEmployment && (
                                    <Grid item xs={12} md={12}>
                                        <Controller
                                            name="hasStableEmployment"
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={
                                                                field.value
                                                            }
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={t(
                                                        "create_wish_tenant_is_employed"
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={12}>
                                    <Controller
                                        name="hasSelfEmployment"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body1">
                                                        {t(
                                                            "create_wish_tenant_maybe_self-employed"
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="h5" mb={1}>
                                    {t(
                                        "create_wish_tenant_minimum_residence_permit"
                                    )}
                                </Typography>
                                <Controller
                                    control={control}
                                    name={"residencyPermits"}
                                    render={({ field }) => {
                                        return (
                                            <TextField
                                                select
                                                fullWidth
                                                variant="filled"
                                                {...field}
                                                SelectProps={{
                                                    IconComponent:
                                                        KeyboardArrowDownIcon,
                                                    multiple: true,
                                                    hiddenLabel: true,
                                                    renderValue: (selected) => (
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexWrap:
                                                                    "wrap",
                                                                gap: 0.5,
                                                            }}
                                                        >
                                                            {selected?.map(
                                                                (value) => (
                                                                    <Chip
                                                                        key={
                                                                            value
                                                                        }
                                                                        label={t(
                                                                            value
                                                                        )}
                                                                    />
                                                                )
                                                            )}
                                                        </Box>
                                                    ),
                                                }}
                                            >
                                                {permitOptionsArr.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option.id ||
                                                                    index
                                                                }
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        field.value.indexOf(
                                                                            option.value
                                                                        ) > -1
                                                                    }
                                                                />
                                                                <ListItemText
                                                                    primary={t(
                                                                        option.label
                                                                    )}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </TextField>
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={12} md={6}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" mb={1}>
                                        {t(
                                            "create_wish_tenant_reference_title"
                                        )}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Controller
                                        name="hasAdminReference"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                }
                                                label={t(
                                                    "create_wish_tenant_reference_has_admin"
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Controller
                                        name="hasEmployReference"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                }
                                                label={t(
                                                    "create_wish_tenant_reference_has_employer"
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3} xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" mb={1}>
                                        {t(
                                            "create_wish_tenant_moving_availability"
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ControllerSelectField
                                        control={control}
                                        name={"moveInAvailability"}
                                        OPTIONS={AVAILABILITY}
                                        fullWidth
                                        hiddenLabel={true}
                                    />
                                </Grid>
                                {moveInAvailability === "date" && (
                                    <Grid item xs={12} md={6}>
                                        <ControllerDateField
                                            control={control}
                                            name="moveInDate"
                                            lang={currentLanguageCode}
                                            datePickerProps={{
                                                disablePast: true,
                                            }}
                                            label={t("date")}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t("cancel")}</Button>
                        <Button
                            color="primary"
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                        >
                            {t("save")}
                        </Button>
                    </DialogActions>
                </>
            )}
        </ModalLayout>
    );
}

export default WishTenantForm;
