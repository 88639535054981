import React, { useState } from "react";

//* translation
import { useTranslation } from "react-i18next";

//* libraries import
import { Link } from "react-router-dom";

//* mui components import
import Box from "@mui/material/Box";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//* mui icons imports
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import PlaceIcon from "@mui/icons-material/Place";
import StairsIcon from "@mui/icons-material/Stairs";

//* custom carousal images
import { CarousalImages } from "../mui-components/MuiPropertyCardComponents";
import GoodMatchChanceModal from "../modal/match-chance-modals/GoodMatchChanceModal";
import { useTheme } from "@mui/material";

import { Button } from "@mui/material";
import { useCategories } from "../../hooks/search/useSearchOptions";

// WISH LIST
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";

import { toast } from "react-toastify";
import { useStateValue } from "../../store/StateProvider";
import { useAddWishlist } from "../../hooks/properties/useWishlist";
import CustomFormDivider from "../forms/common/CustomFormDivider";

//* GMC = Good Match Chance Image
const GMCImage = process.env.REACT_APP_PROPERTY_IMAGE_URL;

function GoodMatchChanceCard({ matchChance }) {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const { isLoading, data } = useCategories();
    const [{ user_id, token }] = useStateValue();

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const theme = useTheme();

    const getCategoryName = (categoryId) => {
        let categoryObj = data?.find((category) => category.id === categoryId);
        if (!categoryObj) return "Not Available";
        //console.log(categoryObj);
        return categoryObj?.name;
    };

    // WISH LIST
    const [isFavoriteProperty, setIsFavoriteProperty] = useState(
        Boolean(matchChance.is_fav)
    );

    const { mutate } = useAddWishlist();

    const addToWishlist = (property_id) => {
        if (token === null) {
            return toast.info("Please login to add a property to favorite");
        }

        let userData = {
            property_id,
            user_id,
        };

        setIsFavoriteProperty(!isFavoriteProperty);

        mutate(userData, {
            onSuccess: (data) => {
                // setIsFavId(data?.data?.data);
                //isFavoritePage && refetchFavorite();
                if (!data?.data?.statuscode) return;
                // toast.success(data?.data?.message);
            },
            onError: (errors) => {
                // toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    const wishTenantValidationValue = 1;

    return (
        <>
            <Paper
                sx={{
                    p: 1.25,
                    borderRadius: 2,
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
                    position: "relative",
                }}
                className="property-card"
                elevation={0}
            >
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        lg={3}
                        sx={{ position: "relative" }}
                    >
                        <CarousalImages
                            propertyImages={matchChance?.images}
                            altTags={matchChance?.street_no}
                            imageUrl={`${GMCImage}${matchChance?.id}/`}
                            height={"250px"}
                        />

                        {isFavoriteProperty ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    addToWishlist(matchChance?.id);
                                }}
                                sx={{
                                    color: "#FFF",
                                    fontWeight: 400,
                                    lineHeight: "1.2500em",
                                    zIndex: 2,
                                    fontSize: "0.75rem",
                                    padding: "4px 8px",
                                    borderRadius: 6,
                                    position: "absolute",
                                    top: 24,
                                    left: 24,
                                }}
                            >
                                <FavoriteIcon />
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    addToWishlist(matchChance?.id);
                                }}
                                sx={{
                                    color: "#FFF",
                                    fontWeight: 400,
                                    lineHeight: "1.2500em",
                                    zIndex: 2,
                                    fontSize: "0.75rem",
                                    padding: "4px 8px",
                                    borderRadius: 6,
                                    position: "absolute",
                                    top: 24,
                                    left: 24,
                                }}
                            >
                                <FavoriteBorderOutlinedIcon />
                            </Button>
                        )}

                        <Button
                            component={Link}
                            to={`/property/${
                                matchChance?.property_id
                                    ? matchChance.property_id
                                    : matchChance.id
                            }`}
                            sx={{
                                position: "absolute",
                                top: 24,
                                right: 12,
                                zIndex: 2,
                                fontSize: "0.75rem",
                                padding: "4px 8px",
                                borderRadius: 6,
                            }}
                            variant="contained"
                        >
                            <VisibilityIcon />
                            {
                                // t("details")
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Link
                            to={`/property/${
                                matchChance?.property_id
                                    ? matchChance.property_id
                                    : matchChance.id
                            }`}
                            style={{ textDecoration: "none" }}
                        >
                            <Stack
                                spacing={1}
                                sx={{
                                    padding: "10px 16px",
                                }}
                            >
                                <Stack
                                    spacing={0.5}
                                    direction="row"
                                    alignItems="flex-start"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "6px",
                                            fontWeight: 400,
                                            color: "secondary.main",
                                        }}
                                    >
                                        {`ID: ${
                                            matchChance?.property_id
                                                ? matchChance.property_id
                                                : matchChance.id
                                        }`}{" "}
                                        -
                                    </Typography>
                                    <Typography
                                        component="p"
                                        sx={{
                                            fontSize: "10px",
                                            fontWeight: 500,
                                            color: "secondary.main",
                                            width: "80px",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {!matchChance?.category_id ||
                                        matchChance?.category_id === ""
                                            ? t("apartment")
                                            : t(
                                                  getCategoryName(
                                                      matchChance?.category_id
                                                  ).toLowerCase()
                                              )}
                                    </Typography>
                                </Stack>
                                <Tooltip
                                    title={matchChance?.street_no}
                                    arrow
                                    followCursor
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: "primary.main",
                                        }}
                                    >
                                        {matchChance?.street_no?.length > 128
                                            ? matchChance?.street_no?.slice(
                                                  0,
                                                  128
                                              ) + "..."
                                            : matchChance?.street_no}
                                    </Typography>
                                </Tooltip>

                                <Stack direction={"row"} spacing={1}>
                                    <CustomFormDivider />
                                </Stack>
                                <Stack direction={"row"} spacing={1}>
                                    <PlaceIcon
                                        sx={{
                                            color: theme.palette.secondary.main,
                                        }}
                                    />

                                    <Typography variant="subtitle1">
                                        {matchChance?.city_name}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    divider={
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                        />
                                    }
                                    spacing={1}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                >
                                    <Stack direction={"row"} spacing={1}>
                                        <StairsIcon
                                            sx={{
                                                color: theme.palette.secondary
                                                    .main,
                                            }}
                                        />
                                        <Typography>
                                            {
                                                //console.log(matchChance)
                                            }
                                            {t("number_of_floor")}{" "}
                                            {matchChance?.floor || "--"}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <BedIcon
                                            sx={{
                                                color: theme.palette.secondary
                                                    .main,
                                            }}
                                        />
                                        <Typography>
                                            {matchChance?.number_bedroom ||
                                                "--"}{" "}
                                            {t("rooms")}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <ControlCameraIcon
                                            sx={{
                                                color: theme.palette.secondary
                                                    .main,
                                                position: "relative",
                                                top: "2px",
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                position: "relative",
                                                bottom: "4px",
                                            }}
                                        >
                                            {matchChance?.living_space || "--"}m{" "}
                                            <sup
                                                style={{
                                                    position: "relative",
                                                    bottom: "2px",
                                                }}
                                            >
                                                2
                                            </sup>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Typography
                                    variant="h5"
                                    style={{
                                        position: "relative",
                                        bottom: "-10px",
                                    }}
                                >
                                    CHF {parseInt(matchChance?.price)}.-
                                </Typography>
                            </Stack>
                        </Link>
                    </Grid>
                </Grid>

                {/*
                <Tooltip title={t("click_to_see_details")} arrow>
                    <Box
                        onClick={handleShow}
                        sx={{
                            position: "absolute",
                            cursor: "pointer",
                            height: "67.5px",
                            width: "67.5px",
                            borderRadius: "50%",
                            "&:hover": {
                                boxShadow:
                                    "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            },
                            right: 16,
                            top: { xs: 264, md: 16 },
                        }}
                    >
                        <CircularProgress
                            variant="determinate"
                            sx={{ color: "#F7F7f7" }}
                            size={67.5}
                            thickness={4}
                            value={100}
                        />
                        <CircularProgress
                            variant="determinate"
                            sx={{
                                color: theme.palette.secondary.main,
                                animationDuration: "550ms",
                                position: "absolute",
                                left: 0,
                                top: 0,
                                [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: "round",
                                },
                            }}
                            size={67.5}
                            thickness={3}
                            value={
                                parseInt(matchChance?.match_score) > 0
                                    ? parseInt(matchChance?.match_score)
                                    : 100
                            }
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 2,
                                bottom: 0,
                                right: 0,
                                position: "absolute",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {parseInt(matchChance?.match_score) > 0 ? (
                                <>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: theme.palette.secondary.main,
                                        }}
                                    >
                                        {`${matchChance.match_score}%`}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.secondary.main,
                                            fontSize: "0.75rem",
                                            mt: "4px",
                                        }}
                                    >
                                        {t("score")}
                                    </Typography>
                                </>
                            ) : wishTenantValidationValue === 1 ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: theme.palette.secondary.main,
                                        }}
                                    >
                                        <WarningIcon />
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.secondary.main,
                                            fontSize: "0.75rem",
                                            mt: "4px",
                                        }}
                                    >
                                        {t("score")}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: theme.palette.secondary.main,
                                        }}
                                    >
                                        {t("property_match_status_free")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.secondary.main,
                                            fontSize: "0.75rem",
                                            mt: "4px",
                                        }}
                                    >
                                        {t("score")}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                </Tooltip>
                */}
            </Paper>
            <GoodMatchChanceModal
                show={show}
                t={t}
                matchChance={matchChance}
                handleClose={handleClose}
            />
        </>
    );
}

export default GoodMatchChanceCard;
