// these function are for checking the data type and values we are getting from api but in this the fields are not getting any data from the api we are showing this symbol "-".

function checkForDossierViewYesOrNo(key) {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return "-";

    if (key !== "") return "yes";

    return key;
}

function checkForDossierViewEmpty(key) {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return "-";

    return key;
}

function checkForDossierViewNull(key) {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return null;

    return key;
}

function checkForDossierViewEmptyDate(key, period = "date") {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return "-";

    if (period === "year") return new Date(key).getFullYear();
    if (period === "date") return new Date(key).toLocaleDateString("uk-Uk");
}

function checkForDossierViewEmptyHometown(key) {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === "" ||
        typeof key === "string"
    )
        return "-";

    return `${key?.name}  (${key?.zip})`;
}

function checkForDossierViewEmptyPhone(key) {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return "-";
    if (key.length <= 3) return "-";
    return `${key?.substring(0, 3)} ${key?.substring(3)}`;
}

// for finding keys from array
function checkForDossierViewEmptyFind(options, key) {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return "-";
    let selectedOption = options.find((option) => key === option.value);
    if (!selectedOption) return "-";
    return selectedOption?.label;
}

function checkDocumentExist(documentType) {
    if (
        documentType === null ||
        documentType === "null" ||
        documentType === undefined ||
        documentType === "undefined" ||
        documentType === ""
    ) {
        return [];
    }
    return documentType;
}

export {
    checkForDossierViewYesOrNo,
    checkForDossierViewEmpty,
    checkForDossierViewNull,
    checkForDossierViewEmptyDate,
    checkForDossierViewEmptyHometown,
    checkForDossierViewEmptyPhone,
    checkForDossierViewEmptyFind,
    checkDocumentExist,
};
