import { useMutation, useQuery } from "react-query";
import { Axios } from "../../axios/axios";
// import { useStateValue } from "../../store/StateProvider";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function getFavorite({ queryKey, signal }) {
    // const token = localStorage.getItem("nch_id");
    const id = queryKey[1];
    // const token = queryKey[2];
    // const AxiosPrivate = queryKey[3];
    return Axios.get(
        `/property/get_favorite?user_id=${id}&page=0&limit=10&lang=en`
        // {
        //     headers: {
        //         "x-access-token": token,
        //     },
        // }
    );
}

export function useGetWishlist(user_id) {
    return useQuery(["get-favorites", user_id], getFavorite, {
        enabled: Boolean(user_id),
    });
}

function addFavorite(propertyData) {
    // const token = localStorage.getItem("nch_id");
    return Axios.post(
        "/property/add_to_favorite",
        propertyData
        // {
        // headers: {
        //     "x-access-token": token,
        // },
        // }
    );
}

function removeFavorite(propertyData) {
    // const token = localStorage.getItem("nch_id");
    return Axios.post("/property/add_to_favorite", propertyData, {
        // headers: {
        //     "x-access-token": token,
        // },
    });
}

// http://134.209.229.112:8080/api/property/add_to_favorite
export function useAddWishlist() {
    return useMutation(addFavorite);
}
// export function useAddWishlist() {
//     const { mutate } = useMutation(addFavorite);
//     const { t } = useTranslation();
//     let isFavorite = false;

//     const handleWishlist = ({ user_id, property_id, token }) => {
//         if (token === null) {
//             return toast.info(
//                 `${t("please_login_to_add_a_property_to_favorite")}`
//             );
//         }

//         let userData = {
//             property_id,
//             user_id,
//         };

//         mutate(userData, {
//             onSuccess: (data) => {
//                 let favorite = data?.data?.message;
//                 if (!data?.data?.statuscode) return;
//                 if (favorite.includes("add")) isFavorite = true;
//                 if (favorite.includes("remove")) isFavorite = false;
//             },
//             onError: (errors) => {
//                 toast.error(`${t("we_are_facing_some_technical_issue")}`);
//                 console.error(errors);
//             },
//         });
//     };

//     return { isFavorite, handleWishlist };
// }

export function useRemoveWishlist() {
    const { mutate } = useMutation(addFavorite);
    const { t } = useTranslation();
    let isFavorite = false;

    const handleWishlist = ({ user_id, property_id, token }) => {
        if (token === null) {
            return toast.info(
                `${t("please_login_to_add_a_property_to_favorite")}`
            );
        }

        let userData = {
            property_id,
            user_id,
        };

        mutate(userData, {
            onSuccess: (data) => {
                let favorite = data?.data?.message;
                if (!data?.data?.statuscode) return;
                if (favorite.includes("add")) isFavorite = true;
                if (favorite.includes("remove")) isFavorite = false;
            },
            onError: (errors) => {
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    return { isFavorite, handleWishlist };
}
