import { DossierCTA, heroImage } from "../utils/imgImports";

export const ORGANIZE_DOSSIER = [
    {
        id: 1,
        image: DossierCTA,
        title: "efficient_&_effective_application",
        description: "organize_your_application_dossier",
    },
    {
        id: 2,
        image: heroImage,
        title: "share_on_all_platforms",
        description: "can_download_your_dossier_as_a_pdf",
    },
    {
        id: 3,
        image: heroImage,
        title: "ai_assisting",
        description: "ai_can_help_you",
    },
];
