import React from 'react'

function PageNotFoundSvg({ height, width }) {
  return (
    <svg
      className="animated"
      id="freepik_stories-404-error-page-not-found-with-people-connecting-a-plug"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={height}
      width={width}
      // xmlns:svgjs="http://svgjs.com/svgjs"
    >
      <g
        id="freepik--background-complete--inject-49"
        className="animable"
        style={{ transformOrigin: '250px 228.23px' }}
      >
        <rect
          y="382.4"
          width={500}
          height="0.25"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '250px 382.525px',
          }}
          id="elw3qu9a7yf5"
          className="animable"
        />
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '140.46px 196.4px',
          }}
          id="eld09lxhre5ud"
          className="animable"
        />
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '356.75px 196.4px',
          }}
          id="elewr2cl26dks"
          className="animable"
        />
        <rect
          x="418.33"
          y="391.92"
          width="31.57"
          height="0.25"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '434.115px 392.045px',
          }}
          id="el50izwdrn4rj"
          className="animable"
        />
        <rect
          x="301.93"
          y="389.21"
          width="33.41"
          height="0.25"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '318.635px 389.335px',
          }}
          id="elps8a0oyoihh"
          className="animable"
        />
        <rect
          x="396.59"
          y="401.21"
          width="62.43"
          height="0.25"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '427.805px 401.335px',
          }}
          id="elh79wec65k8b"
          className="animable"
        />
        <rect
          x="52.46"
          y="399.53"
          width="19.23"
          height="0.25"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '62.075px 399.655px',
          }}
          id="elae3zr34hv7r"
          className="animable"
        />
        <rect
          x="80.6"
          y="399.53"
          width="30.29"
          height="0.25"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '95.745px 399.655px',
          }}
          id="eltbkvjr0jhho"
          className="animable"
        />
        <rect
          x="189.58"
          y="395.31"
          width="54.56"
          height="0.25"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '216.86px 395.435px',
          }}
          id="ele7832i9bkk6"
          className="animable"
        />
        <polygon
          points="272.26 118.13 215.28 175.12 215.28 364.91 415.27 364.91 415.27 118.13 272.26 118.13"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '315.275px 241.52px',
          }}
          id="elrkcatl6gy1i"
          className="animable"
        />
        <path
          d="M415.27,365.45h-200a.54.54,0,0,1-.54-.54V175.12a.53.53,0,0,1,.16-.38l57-57a.57.57,0,0,1,.38-.16h143a.54.54,0,0,1,.54.54V364.91A.54.54,0,0,1,415.27,365.45Zm-199.45-1.07H414.73V118.67H272.49l-56.67,56.67Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '315.27px 241.515px',
          }}
          id="el6icxa3swcl6"
          className="animable"
        />
        <polygon
          points="215.28 175.12 275.07 182.83 272.26 118.13 215.28 175.12"
          style={{
            fill: 'rgb(230, 230, 230)',
            transformOrigin: '245.175px 150.48px',
          }}
          id="elpsfifgp4mfj"
          className="animable"
        />
        <polygon
          points="215.28 175.12 272.26 175.12 272.26 118.13 215.28 175.12"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '243.77px 146.625px',
          }}
          id="el4ag9mf191vu"
          className="animable"
        />
        <path
          d="M272.26,175.65h-57a.54.54,0,0,1-.5-.33.52.52,0,0,1,.12-.58l57-57a.56.56,0,0,1,.59-.12.55.55,0,0,1,.33.5v57A.54.54,0,0,1,272.26,175.65Zm-55.68-1.07h55.15V119.43Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '243.759px 146.616px',
          }}
          id="el2t3s4t1ae1j"
          className="animable"
        />
        <path
          d="M326.84,257.19l34-34a8.18,8.18,0,1,0-11.57-11.57l-34,34-34-34a8.18,8.18,0,0,0-11.57,11.57l34,34-34,34a8.18,8.18,0,0,0,11.57,11.57l34-34,34,34a8.18,8.18,0,1,0,11.57-11.57Z"
          style={{
            fill: 'rgb(240, 240, 240)',
            transformOrigin: '315.27px 257.19px',
          }}
          id="elge9fp4r74q7"
          className="animable"
        />
        <path
          d="M223,229h0a10.23,10.23,0,0,1-14.46,0l-32.81-32.82,14.46-14.46L223,214.58A10.23,10.23,0,0,1,223,229Z"
          style={{
            fill: 'rgb(240, 240, 240)',
            transformOrigin: '200.851px 206.856px',
          }}
          id="elvnnmkmollhm"
          className="animable"
        />
        <g id="elmwlaz0ejzxg">
          <path
            d="M176,187.63h20.45a0,0,0,0,1,0,0v1.89a7.39,7.39,0,0,1-7.39,7.39h-5.67a7.39,7.39,0,0,1-7.39-7.39v-1.89A0,0,0,0,1,176,187.63Z"
            style={{
              fill: 'rgb(224, 224, 224)',
              transformOrigin: '186.225px 192.27px',
              transform: 'rotate(-45deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M84.71,90.71a71,71,0,1,0,100.46,0A71,71,0,0,0,84.71,90.71Zm96.36,96.36a65.23,65.23,0,1,1,0-92.25A65.23,65.23,0,0,1,181.07,187.07Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '134.94px 140.889px',
          }}
          id="elky2a04sysqa"
          className="animable"
        />
        <path
          d="M172.26,180.65a2.38,2.38,0,0,1-1.68-4.06,50.48,50.48,0,0,0,0-71.29,2.38,2.38,0,1,1,3.36-3.36,55.22,55.22,0,0,1,0,78A2.38,2.38,0,0,1,172.26,180.65Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '179.924px 140.896px',
          }}
          id="elppwewyorfqn"
          className="animable"
        />
        <path
          d="M83.58,155.53a2.37,2.37,0,0,1-2.31-1.83,55.37,55.37,0,0,1,7.6-43.11,2.38,2.38,0,0,1,4,2.62,50.65,50.65,0,0,0-7,39.4,2.36,2.36,0,0,1-1.76,2.86A2.64,2.64,0,0,1,83.58,155.53Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '86.5244px 132.52px',
          }}
          id="elvaeeos55is"
          className="animable"
        />
        <path
          d="M97.62,180.65a2.38,2.38,0,0,1-1.68-.7,54.8,54.8,0,0,1-10.5-14.64,2.37,2.37,0,0,1,4.26-2.1,50.51,50.51,0,0,0,9.6,13.38,2.38,2.38,0,0,1,0,3.36A2.41,2.41,0,0,1,97.62,180.65Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '92.5947px 171.268px',
          }}
          id="ellmq0o3uw0t9"
          className="animable"
        />
        <g id="elmaoigykmg0c">
          <path
            d="M196.15,319.72h13.22a0,0,0,0,1,0,0v30.7a5.92,5.92,0,0,1-5.92,5.92h-1.39a5.92,5.92,0,0,1-5.92-5.92v-30.7a0,0,0,0,1,0,0Z"
            style={{
              fill: 'rgb(240, 240, 240)',
              transformOrigin: '202.755px 338.03px',
              transform: 'rotate(45deg)',
            }}
            className="animable"
          />
        </g>
        <g id="elckttsajbwv9">
          <path
            d="M207,324.2H220.2a0,0,0,0,1,0,0v.63a5.37,5.37,0,0,1-5.37,5.37h-2.48a5.37,5.37,0,0,1-5.37-5.37v-.63A0,0,0,0,1,207,324.2Z"
            style={{
              fill: 'rgb(224, 224, 224)',
              transformOrigin: '213.59px 327.2px',
              transform: 'rotate(45deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M284.35,269.7l-21.9,21.89A13.74,13.74,0,0,0,281.88,311l8.86-8.85A47.07,47.07,0,0,0,284.35,269.7Z"
          style={{
            fill: 'rgb(240, 240, 240)',
            transformOrigin: '275.066px 292.202px',
          }}
          id="elpx6od308js"
          className="animable"
        />
        <path
          d="M292.6,290.82a45.94,45.94,0,1,0-42.64,49A45.94,45.94,0,0,0,292.6,290.82Zm-87.91,6.12a42.18,42.18,0,1,1,45,39.15A42.18,42.18,0,0,1,204.69,296.94Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '246.77px 293.991px',
          }}
          id="elbf7d7gdo9ir"
          className="animable"
        />
        <path
          d="M223.38,320.91a35.58,35.58,0,0,1-12.19-24.42,1.54,1.54,0,1,1,3.07-.21A32.64,32.64,0,0,0,249,326.53a1.53,1.53,0,1,1,.21,3.06A35.53,35.53,0,0,1,223.38,320.91Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '230.912px 312.227px',
          }}
          id="el22snwuxt3nw"
          className="animable"
        />
        <path
          d="M278.64,286a1.51,1.51,0,0,1-.47-.75,32.72,32.72,0,0,0-16.27-20.1,1.54,1.54,0,0,1,1.42-2.73,35.82,35.82,0,0,1,17.81,22,1.53,1.53,0,0,1-1.07,1.89A1.56,1.56,0,0,1,278.64,286Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '271.158px 274.321px',
          }}
          id="elqncgvpgyl7j"
          className="animable"
        />
        <path
          d="M243.39,261.13a1.56,1.56,0,0,1-.52-1.06,1.54,1.54,0,0,1,1.42-1.64,35.88,35.88,0,0,1,11.6,1.09,1.54,1.54,0,1,1-.79,3,32.48,32.48,0,0,0-10.59-1A1.58,1.58,0,0,1,243.39,261.13Z"
          style={{
            fill: 'rgb(224, 224, 224)',
            transformOrigin: '249.957px 260.458px',
          }}
          id="eln3pk5h5v309"
          className="animable"
        />
      </g>
      <g
        id="freepik--Shadow--inject-49"
        className="animable"
        style={{ transformOrigin: '250px 416.24px' }}
      >
        <ellipse
          id="freepik--path--inject-49"
          cx={250}
          cy="416.24"
          rx="193.89"
          ry="11.32"
          style={{
            fill: 'rgb(245, 245, 245)',
            transformOrigin: '250px 416.24px',
          }}
          className="animable"
        />
      </g>
      <g
        id="freepik--character-1--inject-49"
        className="animable"
        style={{ transformOrigin: '154.766px 275.111px' }}
      >
        <path
          d="M137.82,145.88c-8.06,8.11,5.64,20.7-3.41,26.48,7.39,3.71,33.29,8.42,40-6.17-4.89-3.8-1.49-13.14-6-22.11C161.66,130.78,129.76,136.67,137.82,145.88Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '154.41px 156.225px',
          }}
          id="elpnqosnyok5n"
          className="animable"
        />
        <path
          d="M138.55,145.67c-2.51-.42-11,3.8-4.68,15.13,5.09,9.16.85,9.81.85,9.81s5,.14,2-6.67C133.54,156.67,132.05,149.13,138.55,145.67Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '135.064px 158.126px',
          }}
          id="el7na4exhwmb"
          className="animable"
        />
        <path
          d="M198,217.61a2.46,2.46,0,0,0-3.3-1l-.59.31c-.19.1-.55.34-.84.51s-.63.36-.95.52a15.54,15.54,0,0,1-1.95.82,12.28,12.28,0,0,1-3.85.73,10.57,10.57,0,0,1-3.55-.57,12.76,12.76,0,0,1-2.09-1c-.86-1.9-1.68-3.82-2.38-5.8-.85-2.31-1.6-4.67-2.26-7.06s-1.24-4.81-1.72-7.24c-.25-1.21-.47-2.43-.67-3.64l-.27-1.81c-.09-.58-.17-1.24-.22-1.7l0-.2a5,5,0,0,0-10,1c.07.85.13,1.5.22,2.21s.19,1.39.29,2.08c.22,1.38.47,2.74.75,4.1a76.87,76.87,0,0,0,2.1,8c.39,1.32.87,2.62,1.32,3.93s1,2.59,1.57,3.86a72.36,72.36,0,0,0,3.71,7.49l.12.22a3.89,3.89,0,0,0,1.55,1.54,20.26,20.26,0,0,0,6.05,2.14,16.83,16.83,0,0,0,6.56-.14,17.3,17.3,0,0,0,8.09-4.39c.37-.34.72-.69,1-1.06s.62-.72,1-1.24A2.46,2.46,0,0,0,198,217.61Z"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '180.788px 206.632px',
          }}
          id="el9urxjgptnha"
          className="animable"
        />
        <path
          d="M204.65,208.25h-.29a6.23,6.23,0,0,0-5,2.34l-4.83,6.06,0,4.14c3.88,1.54,8.8-2.61,8.8-2.61l2.49-.63a3.49,3.49,0,0,0,2.61-3.88l-.36-2.43A3.48,3.48,0,0,0,204.65,208.25Z"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '201.498px 214.691px',
          }}
          id="elg1l0h069ype"
          className="animable"
        />
        <path
          d="M173.23,184.88c3.23,3.32,3.43,13.23,3.43,13.23l-12.92,10.4s-5.31-14.7-2.37-19.89C163.86,184.22,169.11,180.65,173.23,184.88Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '168.571px 195.748px',
          }}
          id="eluwyx1p49rx"
          className="animable"
        />
        <g id="elfoujlykn3z">
          <g
            style={{
              opacity: '0.2',
              transformOrigin: '168.571px 195.748px',
            }}
            className="animable"
          >
            <path
              d="M173.23,184.88c3.23,3.32,3.43,13.23,3.43,13.23l-12.92,10.4s-5.31-14.7-2.37-19.89C163.86,184.22,169.11,180.65,173.23,184.88Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '168.571px 195.748px',
              }}
              id="elhwpwypcipj9"
              className="animable"
            />
          </g>
        </g>
        <polygon
          points="151.85 407.67 158.99 408.45 159.97 389.05 152.83 388.26 151.85 407.67"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '155.91px 398.355px',
          }}
          id="elqxjmcjkm94a"
          className="animable"
        />
        <path
          d="M160.16,403.79h-8.39a.65.65,0,0,0-.67.61l-.56,7.93a1.59,1.59,0,0,0,1.55,1.59c3.54,0,3.58,0,8,0H172c3.66,0,3.67-3.71,2.09-4.05-7.11-1.5-9.88-3.58-12.39-5.56A2.5,2.5,0,0,0,160.16,403.79Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '162.816px 408.855px',
          }}
          id="el4qtcv11yocc"
          className="animable"
        />
        <g id="elhzmj89n8mgm">
          <polygon
            points="159.97 389.06 159.46 399.06 152.28 399.06 152.83 388.27 159.97 389.06"
            style={{
              opacity: '0.2',
              transformOrigin: '156.125px 393.665px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M163.82,234.27S170,286,170,309c-4.87,32-9,88.16-9,88.16l-9.88-.26s-4.49-61.16-.33-87.57c-5.33-23.66-11.91-78.26-11.91-78.26Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '154.44px 314.115px',
          }}
          id="elrn7a1axksag"
          className="animable"
        />
        <g id="eltfjnngbk3gk">
          <path
            d="M144.77,248.67a29.51,29.51,0,0,0-3.42,1.78c1.36,10.21,3.15,23.06,5.05,34.92C149.64,275.61,147.89,254.15,144.77,248.67Z"
            style={{
              opacity: '0.2',
              transformOrigin: '144.74px 267.02px',
            }}
            className="animable"
          />
        </g>
        <polygon
          points="74.53 389.66 80.42 393.77 90.58 377.21 84.69 373.1 74.53 389.66"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '82.555px 383.435px',
          }}
          id="el91pndbipws"
          className="animable"
        />
        <path
          d="M81.91,391.6l-5-6.73a.64.64,0,0,0-.88-.17l-6.7,4.3a1.58,1.58,0,0,0-.35,2.19c2.09,2.85,2.13,2.87,4.76,6.41,1.63,2.17,4.88,6.54,7.12,9.55s5.17.73,4.49-.75c-3-6.59-3-10-2.94-13.24A2.54,2.54,0,0,0,81.91,391.6Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '77.0682px 396.611px',
          }}
          id="el9pqhdtvfiea"
          className="animable"
        />
        <g id="elm4o7u60b9tj">
          <polygon
            points="90.57 377.21 85.34 385.75 79.03 382.31 84.69 373.1 90.57 377.21"
            style={{
              opacity: '0.2',
              transformOrigin: '84.8px 379.425px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M110.66,396.6c-5.11,0-15.75,0-15.93-7.52-.14-5.9,7.16-8.35,13.46-8.65a5.32,5.32,0,0,1,5.57,5.06v.4c7.14-.34,11.76-1.9,13.45-4.56,3.81-6-3.49-21.49-10.54-36.46-8.3-17.61-17.7-37.56-17.88-56.67a5.32,5.32,0,0,1,5.27-5.37h0a5.33,5.33,0,0,1,5.32,5.27c.16,16.78,9,35.61,16.86,52.23,9,19.18,16.85,35.75,9.9,46.7C132.11,393.47,123.76,396.6,110.66,396.6Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '116.765px 339.715px',
          }}
          id="el8l6ipuiep7"
          className="animable"
        />
        <path
          d="M155.91,233.25s-17.06,59.12-25.39,80.56c-16.14,28.06-42.92,71-42.92,71l-8.55-5s20-49.6,33.45-72.7c3.61-24,17.26-77.26,17.26-77.26Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '117.48px 307.33px',
          }}
          id="elf40n5y0wa5i"
          className="animable"
        />
        <path
          d="M83.45,396.43a2.85,2.85,0,0,0,1.48,1.21,1,1,0,0,0,1-.33.7.7,0,0,0,.22-.61c-.12-1.2-3.22-2.76-3.84-3.06a.16.16,0,0,0-.2.07.23.23,0,0,0,0,.25A14.78,14.78,0,0,0,83.45,396.43Zm2.13,0a.83.83,0,0,1,.17.38.24.24,0,0,1-.08.21.69.69,0,0,1-.66.22c-.66-.13-1.53-1.2-2.37-2.89A10.54,10.54,0,0,1,85.58,396.39Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '84.1147px 395.648px',
          }}
          id="el5tkhfk4dmmy"
          className="animable"
        />
        <path
          d="M82.06,394l.06.05c1,.55,3.6,1,4.17.17a.87.87,0,0,0-.05-1c-1-1.77-4,.31-4.13.4a.24.24,0,0,0-.09.2A.33.33,0,0,0,82.06,394Zm3.8-.58a1,1,0,0,1,.08.11c.11.19.13.33.07.41-.33.48-2.25.31-3.37-.13C83.39,393.36,85.14,392.45,85.86,393.42Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '84.2218px 393.593px',
          }}
          id="el71gps7g80h5"
          className="animable"
        />
        <path
          d="M165,405.44a2.89,2.89,0,0,0,1.86-.47,1,1,0,0,0,.31-1,.75.75,0,0,0-.36-.55c-1-.61-4.13.94-4.74,1.26a.17.17,0,0,0-.08.2.27.27,0,0,0,.19.17A13.93,13.93,0,0,0,165,405.44Zm1.24-1.73a.76.76,0,0,1,.41.09c.05,0,.1.07.12.19a.68.68,0,0,1-.22.66c-.49.45-1.87.51-3.73.18A10.42,10.42,0,0,1,166.19,403.71Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '164.592px 404.366px',
          }}
          id="eltwegdacso2g"
          className="animable"
        />
        <path
          d="M162.18,405.1l.07,0c1-.46,2.95-2.29,2.63-3.24a.87.87,0,0,0-.8-.53c-2-.23-2.14,3.39-2.15,3.54a.24.24,0,0,0,.11.2A.24.24,0,0,0,162.18,405.1Zm1.8-3.4h.14c.21,0,.34.09.37.19.19.55-1.09,2-2.12,2.63C162.46,403.65,162.78,401.7,164,401.7Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '163.423px 403.21px',
          }}
          id="elujimg1cksp"
          className="animable"
        />
        <path
          d="M129.76,229.9l34.06,4.36c7.68-25.86,10.21-38.35,10.89-44.36a6.53,6.53,0,0,0-4.52-7c-2-.64-4.58-1.4-7.14-2-.87-.23-1.76-.44-2.63-.64a109.08,109.08,0,0,0-15.46-2c-.77-.07-1.53-.11-2.3-.15-1.51-.08-3-.1-4.36-.1a7.35,7.35,0,0,0-7.33,8c.27,3,.48,5.79.64,8.35.38,6,.5,10.8.41,15A129.36,129.36,0,0,1,129.76,229.9Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '152.258px 206.135px',
          }}
          id="el0u69wvhdocq"
          className="animable"
        />
        <g id="el9fhskmxkipw">
          <g
            style={{
              opacity: '0.3',
              transformOrigin: '152.258px 206.135px',
            }}
            className="animable"
          >
            <path
              d="M129.76,229.9l34.06,4.36c7.68-25.86,10.21-38.35,10.89-44.36a6.53,6.53,0,0,0-4.52-7c-2-.64-4.58-1.4-7.14-2-.87-.23-1.76-.44-2.63-.64a109.08,109.08,0,0,0-15.46-2c-.77-.07-1.53-.11-2.3-.15-1.51-.08-3-.1-4.36-.1a7.35,7.35,0,0,0-7.33,8c.27,3,.48,5.79.64,8.35.38,6,.5,10.8.41,15A129.36,129.36,0,0,1,129.76,229.9Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '152.258px 206.135px',
              }}
              id="el4ay5d0vkmpo"
              className="animable"
            />
          </g>
        </g>
        <g id="eliq0xt8tnqs">
          <path
            d="M132,209.32c4.5-5.82,6.13-12.48,4.67-16.15l-5.08,1.14C132,200.29,132.11,205.11,132,209.32Z"
            style={{
              fill: 'rgb(254, 117, 41)',
              opacity: '0.3',
              transformOrigin: '134.39px 201.245px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M163.05,180.84c-.88-.23-1.76-.44-2.63-.64a110.65,110.65,0,0,0-15.47-2c-.77-.06-1.53-.1-2.29-.14l-1.33-.06,1,1.75c.14.24,3.54,5.8,12,11.78l1.81,1.26,1.74-1.33a13.14,13.14,0,0,0,5.48-10.53Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '152.345px 185.395px',
          }}
          id="elbizdclaug45"
          className="animable"
        />
        <path
          d="M148.71,167.92c-.32,4.21-1.31,8.5-3.76,10.3,0,0,3.12,5.19,11.2,10.86,5.06-3.87,4.27-8.88,4.27-8.88-5.38-2-5-5.73-2.73-11.68l-4.2-2.26A2.75,2.75,0,0,0,148.71,167.92Z"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '152.707px 177.219px',
          }}
          id="elgroncn05cm8"
          className="animable"
        />
        <g id="elfyqsdytchoi">
          <path
            d="M153.49,166.26a2.75,2.75,0,0,0-4.73,1.28,17,17,0,0,0,7.35,7.51,18.21,18.21,0,0,1,1.57-6.53Z"
            style={{
              opacity: '0.2',
              transformOrigin: '153.22px 170.203px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M142.17,154.72c2.09,8.13,2.8,11.61,7.77,15,7.47,5.14,17,.8,17.37-7.78.35-7.73-3.12-19.73-11.8-21.45A11.45,11.45,0,0,0,142.17,154.72Z"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '154.55px 156.093px',
          }}
          id="elukeqvhw3typ"
          className="animable"
        />
        <path
          d="M162.86,142.17a43.9,43.9,0,0,1-17,9.37s6.21,13.21-5.41,9.88c-3.37-11.25,0-17.09,3-19.84C152.69,138.58,159.19,137.58,162.86,142.17Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '150.952px 150.492px',
          }}
          id="el7jy08cvzkqx"
          className="animable"
        />
        <path
          d="M141.78,162.73a6.14,6.14,0,0,0,3.44,3.18c2,.72,3.19-1,2.76-3-.39-1.79-1.91-4.33-4-4.2A2.75,2.75,0,0,0,141.78,162.73Z"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '144.766px 162.391px',
          }}
          id="el7pvpyge5puo"
          className="animable"
        />
        <path
          d="M129.51,227.84l-1.93,2.85c-.15.22.1.5.49.55l35.72,4.58c.31,0,.58-.08.63-.27l.7-3c0-.21-.2-.42-.53-.47l-34.5-4.42A.6.6,0,0,0,129.51,227.84Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '146.328px 231.732px',
          }}
          id="elz0zat1cvax"
          className="animable"
        />
        <path
          d="M133.64,232.29l-.92-.12c-.18,0-.31-.14-.28-.25l.94-3.91a.33.33,0,0,1,.38-.16l.93.12c.18,0,.31.13.28.25l-.94,3.9C134,232.24,133.83,232.31,133.64,232.29Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '133.705px 230.065px',
          }}
          id="elakotyvkd1h"
          className="animable"
        />
        <path
          d="M161.15,235.81l-.92-.11c-.18,0-.31-.14-.28-.26l.94-3.9c0-.11.2-.19.38-.17l.93.12c.18,0,.31.14.28.25l-.94,3.91C161.51,235.76,161.34,235.84,161.15,235.81Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '161.215px 233.592px',
          }}
          id="el2ibkjx9ysef"
          className="animable"
        />
        <path
          d="M147.4,234.05l-.92-.12c-.19,0-.32-.13-.29-.25l.94-3.9c0-.12.2-.19.39-.17l.92.12c.18,0,.31.14.29.25l-.95,3.91C147.76,234,147.58,234.07,147.4,234.05Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '147.459px 231.83px',
          }}
          id="el3ajkxtrcnla"
          className="animable"
        />
        <path
          d="M156.1,154.37c.12.67.58,1.15,1,1.06s.69-.69.56-1.36-.59-1.15-1-1.06S156,153.7,156.1,154.37Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '156.885px 154.22px',
          }}
          id="elh5bmghd5e8u"
          className="animable"
        />
        <path
          d="M163.66,152.92c.13.67.59,1.14,1,1.06s.69-.7.56-1.37-.59-1.14-1-1.06S163.54,152.25,163.66,152.92Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '164.442px 152.765px',
          }}
          id="ela94p75fvi8h"
          className="animable"
        />
        <path
          d="M164,151.63l1.52-.78S165,152.27,164,151.63Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '164.76px 151.323px',
          }}
          id="elice7nor6kc"
          className="animable"
        />
        <path
          d="M161.66,154.42a18.53,18.53,0,0,0,3.21,3.81,2.91,2.91,0,0,1-2.3.91Z"
          style={{
            fill: 'rgb(255, 86, 82)',
            transformOrigin: '163.265px 156.783px',
          }}
          id="elcp59qj6miji"
          className="animable"
        />
        <path
          d="M156.94,161.84a.2.2,0,0,0,.24.31,5.37,5.37,0,0,1,4.88,0,.2.2,0,0,0,.27-.07.21.21,0,0,0-.06-.28,5.78,5.78,0,0,0-5.27,0S157,161.82,156.94,161.84Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '159.626px 161.67px',
          }}
          id="elrau1ggu7pl8"
          className="animable"
        />
        <path
          d="M153.49,152.31a.36.36,0,0,0,.14-.16,3.17,3.17,0,0,1,2.26-1.79.4.4,0,0,0,.35-.45.39.39,0,0,0-.44-.35,4,4,0,0,0-2.89,2.24.4.4,0,0,0,.58.51Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '154.556px 150.967px',
          }}
          id="elsok1kzehvhn"
          className="animable"
        />
        <path
          d="M165.91,147.24l.05-.05a.4.4,0,0,0-.1-.56,4,4,0,0,0-3.62-.54.41.41,0,0,0-.22.52.4.4,0,0,0,.52.22,3.16,3.16,0,0,1,2.86.46A.39.39,0,0,0,165.91,147.24Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '164.013px 146.62px',
          }}
          id="el0eyg2kpueq8l"
          className="animable"
        />
        <path
          d="M156.47,153.08l1.52-.77S157.4,153.72,156.47,153.08Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '157.23px 152.778px',
          }}
          id="eld56hz6q66yj"
          className="animable"
        />
        <path
          d="M104.11,293.47a5.32,5.32,0,0,1-5.32-5.27c-.14-14.2,4.59-26.19,13.68-34.67,11.19-10.44,28.64-15.29,49.16-13.67,14.39,1.14,23.62-2.36,34.3-6.43,1.76-.66,3.53-1.34,5.35-2a5.32,5.32,0,1,1,3.64,10c-1.77.64-3.49,1.3-5.2,1.95-10.89,4.14-22.14,8.41-38.93,7.09-17.5-1.39-32.08,2.46-41.06,10.84-7,6.48-10.41,15.49-10.3,26.79a5.32,5.32,0,0,1-5.27,5.37Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '153.608px 262.288px',
          }}
          id="elh2lwf5fpxnp"
          className="animable"
        />
        <g id="ele8z6hfqowku">
          <path
            d="M191.21,215.28H214a7.17,7.17,0,0,1,7.17,7.17v25.69a7.17,7.17,0,0,1-7.17,7.17H191.21a0,0,0,0,1,0,0v-40A0,0,0,0,1,191.21,215.28Z"
            style={{
              fill: 'rgb(254, 117, 41)',
              transformOrigin: '206.19px 235.295px',
              transform: 'rotate(159.88deg)',
            }}
            className="animable"
          />
        </g>
        <g id="elszcvgwnuqjg">
          <path
            d="M191.21,215.28H214a7.17,7.17,0,0,1,7.17,7.17v25.69a7.17,7.17,0,0,1-7.17,7.17H191.21a0,0,0,0,1,0,0v-40A0,0,0,0,1,191.21,215.28Z"
            style={{
              fill: 'rgb(255, 255, 255)',
              opacity: '0.7',
              transformOrigin: '206.19px 235.295px',
              transform: 'rotate(159.88deg)',
            }}
            className="animable"
          />
        </g>
        <g id="elonu8zg0vgy">
          <path
            d="M203.84,214.84l9.55-3.49,13.76,37.58-9.55,3.5a5.77,5.77,0,0,1-1.42-2.2l-12-32.79A5.7,5.7,0,0,1,203.84,214.84Z"
            style={{
              fill: 'rgb(254, 117, 41)',
              opacity: '0.2',
              transformOrigin: '215.48px 231.89px',
            }}
            className="animable"
          />
        </g>
        <g id="el59d0nyayhqa">
          <rect
            x={235}
            y="220.7"
            width={1}
            height="6.66"
            style={{
              fill: 'rgb(254, 117, 41)',
              transformOrigin: '235.5px 224.03px',
              transform: 'rotate(-20.12deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M232.45,217.16l-3.37-9.22a8.16,8.16,0,0,0-7.65-5.33,8,8,0,0,0-2.78.49l-6.08,2.23-.35-.94,6.09-2.23a9,9,0,0,1,3.12-.55,9.18,9.18,0,0,1,8.59,6l3.37,9.22Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '222.805px 209.385px',
          }}
          id="elz4eo9hajkkk"
          className="animable"
        />
        <g id="el6320sldqokf">
          <rect
            x="212.75"
            y="205.65"
            width="20.74"
            height="46.89"
            rx="5.62"
            style={{
              fill: 'rgb(254, 117, 41)',
              transformOrigin: '223.12px 229.095px',
              transform: 'rotate(-199.885deg)',
            }}
            className="animable"
          />
        </g>
        <path
          d="M222.2,217.87a3,3,0,1,1-3.84-1.79A3,3,0,0,1,222.2,217.87Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '219.382px 218.9px',
          }}
          id="elirl9lk5f3lk"
          className="animable"
        />
        <path
          d="M229.67,238.26a3,3,0,1,1-3.85-1.79A3,3,0,0,1,229.67,238.26Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '226.851px 239.287px',
          }}
          id="el21ijs98bweh"
          className="animable"
        />
        <path
          d="M142,235.85l-.08-.09c-1.56-1.56-3.21-3.26-4.7-5s-2.95-3.48-4.28-5.28a49.41,49.41,0,0,1-3.55-5.51,28.72,28.72,0,0,1-1.93-4.27c.6-2,1.24-4,2-5.92.87-2.31,1.85-4.58,2.92-6.82s2.21-4.44,3.44-6.59c.6-1.08,1.23-2.15,1.88-3.2l1-1.54c.31-.5.68-1,.94-1.43l.11-.16a5,5,0,0,0-8.16-5.77c-.5.68-.88,1.22-1.28,1.81s-.76,1.18-1.14,1.77c-.73,1.18-1.44,2.37-2.12,3.58a79,79,0,0,0-3.67,7.44c-.56,1.26-1,2.55-1.57,3.84s-.95,2.6-1.35,3.94a73.83,73.83,0,0,0-2.09,8.09l0,.23a4,4,0,0,0,.15,2.19,32.78,32.78,0,0,0,3.83,7.24,48.22,48.22,0,0,0,4.94,6,61.7,61.7,0,0,0,5.59,5.05,59.47,59.47,0,0,0,6.17,4.31,2.48,2.48,0,0,0,3-3.9Z"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '130.532px 211.198px',
          }}
          id="elti2fxb34p98"
          className="animable"
        />
        <path
          d="M153.62,240.82l-.13-.26a6.2,6.2,0,0,0-4.37-3.4l-7.59-1.54-3.71,1.85c.4,4.16,6.34,6.65,6.34,6.65l1.69,1.93a3.5,3.5,0,0,0,4.64.55l2-1.43A3.48,3.48,0,0,0,153.62,240.82Z"
          style={{
            fill: 'rgb(255, 139, 123)',
            transformOrigin: '145.892px 241.429px',
          }}
          id="elba29v6je3s9"
          className="animable"
        />
        <path
          d="M135.19,178.65c-4.21,1.93-8.69,8.61-8.69,8.61l9,16.85s10.23-11.79,9.37-17.69C144.17,181.41,140.55,176.19,135.19,178.65Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '135.711px 191.066px',
          }}
          id="elv3e8q3idapk"
          className="animable"
        />
        <g id="el0s2kxu76pbmd">
          <g
            style={{
              opacity: '0.3',
              transformOrigin: '135.711px 191.066px',
            }}
            className="animable"
          >
            <path
              d="M135.19,178.65c-4.21,1.93-8.69,8.61-8.69,8.61l9,16.85s10.23-11.79,9.37-17.69C144.17,181.41,140.55,176.19,135.19,178.65Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '135.711px 191.066px',
              }}
              id="elzcxlwswuwga"
              className="animable"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--character-2--inject-49"
        className="animable"
        style={{ transformOrigin: '362.936px 264.853px' }}
      >
        <path
          d="M423.29,399.43c-8.81,0-17.47-1.81-21.67-7.29-19.88-26-19.7-78.3-19.53-128.91.1-27.25,2.25-53-.78-73-1.78-11.79-8-19.88-14.28-24.06-9.32-6.16-22.12-2.85-27.59-1.44a5.32,5.32,0,1,1-2.66-10.3c6.83-1.77,22.85-5.9,36.11,2.86,8.95,5.91,16.69,16.46,18.94,31.35,3.15,20.8,1,46.93.9,74.59-.17,48.83-.34,99.32,17.34,122.41,1.43,1.86,7,3.53,16.25,3.05.82,0,1.62-.09,2.41-.16a5.22,5.22,0,0,1-.54-2.33,5.32,5.32,0,0,1,5.32-5.32c11.63,0,17.75,1,18.93,6.32.34,1.53.52,5.37-5.1,8C443.14,397.17,433.12,399.43,423.29,399.43Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '392.635px 275.664px',
          }}
          id="elrmhwqbas13"
          className="animable"
        />
        <polygon
          points="306.87 404.02 296.02 399.75 301.74 380.94 312.6 385.2 306.87 404.02"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '304.31px 392.48px',
          }}
          id="el06sodszhkb4"
          className="animable"
        />
        <path
          d="M295.76,398.6,308,403.4a.75.75,0,0,1,.46.8l-1.2,7.62a1.25,1.25,0,0,1-1.7.95c-3.07-1.27-7.88-3.37-11.77-4.9-2.39-.94-6.94-2.44-10.25-3.74s-2.45-4.74-1-4.5c6.59,1.06,9.11-.07,11.74-1.05A2,2,0,0,1,295.76,398.6Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '294.861px 405.654px',
          }}
          id="el3s3ppy0xvza"
          className="animable"
        />
        <g id="elogn7t0nlxio">
          <polygon
            points="312.6 385.21 301.74 380.94 298.79 390.64 309.65 394.91 312.6 385.21"
            style={{
              opacity: '0.2',
              transformOrigin: '305.695px 387.925px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M326.17,239.61s-9.82,48.54-13.57,72.48c-4.22,26.83-15.37,75.84-15.37,75.84l14.43,5.67s20.61-50.18,23.48-74.48c8.65-19.75,25.85-77.87,25.85-77.87Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '329.11px 316.605px',
          }}
          id="ellrbf8dr8fej"
          className="animable"
        />
        <polygon
          points="296.53 388.01 312.37 394.24 315.08 389.44 297.37 382.1 296.53 388.01"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '305.805px 388.17px',
          }}
          id="elw9uzhmdsgvj"
          className="animable"
        />
        <path
          d="M292.65,398.81a2.59,2.59,0,0,1-1.36-1,1.24,1.24,0,0,1,0-1.14.62.62,0,0,1,.52-.4c1.05-.11,3,2.45,3.19,2.75a.2.2,0,0,1,0,.22.2.2,0,0,1-.2.09A10.18,10.18,0,0,1,292.65,398.81Zm-.53-2.09a.5.5,0,0,0-.26-.05c-.06,0-.13,0-.2.17a.8.8,0,0,0,0,.78c.29.53,1.29,1,2.74,1.24A7.86,7.86,0,0,0,292.12,396.72Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '293.092px 397.799px',
          }}
          id="eln4qutifozi8"
          className="animable"
        />
        <path
          d="M294.78,399.32a.16.16,0,0,1-.08-.06c-.64-.76-1.54-3.11-1-3.92a.66.66,0,0,1,.79-.25,1.1,1.1,0,0,1,.7.55c.62,1.11-.08,3.45-.11,3.55a.21.21,0,0,1-.16.14A.14.14,0,0,1,294.78,399.32Zm-.34-3.82-.08,0c-.26-.06-.32,0-.35.09-.3.48.19,2.18.76,3.11a4.82,4.82,0,0,0,0-2.84A.7.7,0,0,0,294.44,395.5Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '294.49px 397.195px',
          }}
          id="eljfzey13jzhl"
          className="animable"
        />
        <g id="ele0f7sfache">
          <path
            d="M347.34,269c-5.44,6.74-7.59,23.92-7.57,38.24,3.67-10.11,7.88-22.95,11.58-34.61Z"
            style={{
              opacity: '0.2',
              transformOrigin: '345.56px 288.12px',
            }}
            className="animable"
          />
        </g>
        <polygon
          points="372.17 405.83 360.51 405.83 358.96 386.23 370.62 386.23 372.17 405.83"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '365.565px 396.03px',
          }}
          id="el3dr0crv4fw1"
          className="animable"
        />
        <path
          d="M359.85,404.85H373a.73.73,0,0,1,.72.58l1.67,7.52a1.25,1.25,0,0,1-1.24,1.51c-3.32-.06-8.55-.26-12.73-.26-2.58,0-7.36.27-10.91.27s-4-3.51-2.57-3.83c6.53-1.43,8.47-3.39,10.55-5.27A2.06,2.06,0,0,1,359.85,404.85Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '361.299px 409.66px',
          }}
          id="elqlt8tefhie"
          className="animable"
        />
        <g id="elfxdwi150958">
          <polygon
            points="370.62 386.23 358.95 386.23 359.76 396.34 371.43 396.34 370.62 386.23"
            style={{
              opacity: '0.2',
              transformOrigin: '365.19px 391.285px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M340.29,240.22s6.46,54.09,7.82,73.56c1.89,27.1,9.2,80.6,9.2,80.6h15.5s1.66-54.64-1.14-78.95c3.86-21.21,2.64-73.58,2.64-73.58Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '357.392px 317.3px',
          }}
          id="el6cby4vdgh7v"
          className="animable"
        />
        <polygon
          points="356.69 394.71 373.71 394.71 374.48 389.26 355.31 388.9 356.69 394.71"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '364.895px 391.805px',
          }}
          id="elq1k88n65t6s"
          className="animable"
        />
        <path
          d="M357,406.18a2.58,2.58,0,0,1-1.63-.43,1.25,1.25,0,0,1-.42-1.06.64.64,0,0,1,.35-.57c.94-.48,3.67,1.2,4,1.39a.2.2,0,0,1,.1.2.23.23,0,0,1-.16.17A10,10,0,0,1,357,406.18Zm-1.26-1.75a.53.53,0,0,0-.26.05c-.05,0-.11.08-.12.23a.8.8,0,0,0,.27.73c.46.39,1.57.44,3,.15A8.08,8.08,0,0,0,355.77,404.43Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '357.173px 405.111px',
          }}
          id="elg2wzec6h95"
          className="animable"
        />
        <path
          d="M359.2,405.88l-.1,0c-.87-.48-2.57-2.33-2.39-3.28a.65.65,0,0,1,.64-.52,1.07,1.07,0,0,1,.85.25c1,.81,1.19,3.25,1.2,3.35a.22.22,0,0,1-.09.19A.23.23,0,0,1,359.2,405.88Zm-1.71-3.43h-.09c-.26,0-.28.16-.29.21-.11.55,1,2,1.84,2.62a4.86,4.86,0,0,0-1-2.66A.75.75,0,0,0,357.49,402.45Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '358.049px 403.976px',
          }}
          id="elt5ec4zfqobe"
          className="animable"
        />
        <path
          d="M345.64,165.63h0l-9.34-.12c-3.14,0-6.26-.1-9.35-.15-.94,0-1.86-.06-2.79-.1.06,0,.1-.11.15-.16.66-.7,1.32-1.42,2-2.11q.51-.54,1-1.05c.31-.34.71-.72.92-1l.85-1.08a5.17,5.17,0,0,0-5.42-8.18l-1.85.5c-.57.17-1.14.36-1.71.55-1.12.39-2.22.82-3.31,1.29a49.09,49.09,0,0,0-6.35,3.38,45.63,45.63,0,0,0-5.95,4.53,39.42,39.42,0,0,0-5.31,5.91,9.07,9.07,0,0,0-1,1.72,8.76,8.76,0,0,0,4.52,11.53l.91.4a38.36,38.36,0,0,0,6.36,2.1c1.9.49,3.7.81,5.47,1.11s3.5.54,5.22.75,3.42.37,5.12.51c3.39.3,6.75.45,10.1.55s6.63.1,10.11-.06a10.42,10.42,0,0,0-.34-20.83Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '326.707px 169.04px',
          }}
          id="el2g2p9yettw6"
          className="animable"
        />
        <path
          d="M351.08,183.88c-8.07,9.2-27.49,5.76-27.49,5.76l1.07-22.92a101,101,0,0,1,20.26-2.35C356.32,164.35,357.54,176.5,351.08,183.88Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '339.288px 177.32px',
          }}
          id="el76mj856ormg"
          className="animable"
        />
        <path
          d="M329.54,176.83c-.48,3.7-1,8.68-1.31,15.38-4.21,21.3-5.78,36.39-2.06,47.4,2.94,3.63,41.25,2.93,48.14,2.23,4-9.9,4.42-38.32,4.89-62.21a13.77,13.77,0,0,0-11.45-13.82,92.49,92.49,0,0,0-26.12-.88A13.76,13.76,0,0,0,329.54,176.83Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '351.638px 203.397px',
          }}
          id="elfn2lbh2bblq"
          className="animable"
        />
        <g id="elujulsb7lnhj">
          <path
            d="M366.71,191.37c.13,7.28,5.41,18.91,11.35,24.81.36-6.15.59-12.82.77-19.55Z"
            style={{
              opacity: '0.2',
              transformOrigin: '372.77px 203.775px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M400.09,205.52l-.34-.8a76,76,0,0,0-4.89-9.67c-1.78-3-3.7-5.83-5.69-8.56s-4.1-5.34-6.26-7.88c-1.1-1.26-2.19-2.52-3.34-3.74l-1.73-1.82L376,171.19a10.42,10.42,0,0,0-15.08,14.37l.3.33,5.87,6.53c2,2.22,3.91,4.48,5.81,6.73.92,1.13,1.87,2.26,2.73,3.41s1.77,2.26,2.63,3.4c1.24,1.73,2.44,3.45,3.51,5.14a25.68,25.68,0,0,1-.62,2.53,72.68,72.68,0,0,1-2.53,7.14c-1,2.47-2.06,5-3.16,7.49s-2.28,5.09-3.33,7.59l-.1.22a5.26,5.26,0,0,0,8.4,5.91,83,83,0,0,0,6.27-6.48q3-3.37,5.63-7.1c.89-1.25,1.74-2.55,2.58-3.9a45.2,45.2,0,0,0,2.39-4.25,39.75,39.75,0,0,0,2.1-4.92,22.39,22.39,0,0,0,1.28-6.71A7.49,7.49,0,0,0,400.09,205.52Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '379.471px 205.769px',
          }}
          id="elv1xwt6wcrn"
          className="animable"
        />
        <path
          d="M368.43,165.94c13.49,2.31,22.28,20.57,22.28,20.57L374.45,204s-9.37-9.54-15-18.38C353.38,176,358.77,164.3,368.43,165.94Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '373.798px 184.893px',
          }}
          id="el0pjl504m09ei"
          className="animable"
        />
        <path
          d="M343.79,137.15c-2.26-3.79-3-9,1.14-14.31a4.2,4.2,0,0,1-4.12-2,24.79,24.79,0,0,1,16.09-5.6c12.1,0,13,4.67,12.59,7.86,8.28.53,14.32,22.06-6.67,25.87C350.81,136.32,343.79,137.15,343.79,137.15Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '358.971px 132.103px',
          }}
          id="el7dpkky940qj"
          className="animable"
        />
        <path
          d="M368.73,123.49s3.37-3.44-.82-7.6a5.64,5.64,0,0,1,3.94,3.92C372.44,122.23,370.81,124.17,368.73,123.49Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '369.941px 119.759px',
          }}
          id="elx22hj0sedg"
          className="animable"
        />
        <path
          d="M364.09,146.34c-.32,6.63-.92,14.17.19,19.46-1.72,2.71-6.12,5.83-14,6.53-2.8-1.59-6.5-5.48-5.21-7.48,3.35-2.15,4.77-8.66,3.81-13.53Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '354.543px 159.335px',
          }}
          id="elbui3jftu1hh"
          className="animable"
        />
        <g id="eli4pxy7c8pvq">
          <path
            d="M360.25,147.59l-11.39,3.73a17.87,17.87,0,0,1,.05,6.28c3.4-.11,8.11-2.67,9.88-5.07A6.32,6.32,0,0,0,360.25,147.59Z"
            style={{
              opacity: '0.2',
              transformOrigin: '354.583px 152.595px',
            }}
            className="animable"
          />
        </g>
        <path
          d="M367.41,135.84c-2.11,8.13-2.93,13-7.91,16.41-7.49,5.13-16.91-.62-17.28-9.21-.32-7.74,3.18-19.74,11.88-21.45A11.46,11.46,0,0,1,367.41,135.84Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '355.007px 137.776px',
          }}
          id="elsub0jhqtpfm"
          className="animable"
        />
        <path
          d="M365.73,124.2c-3.57,2.54-8,13.3,1,17.62C374.81,134.72,372.72,124.2,365.73,124.2Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '366.686px 133.01px',
          }}
          id="elwgm2q8jj60g"
          className="animable"
        />
        <path
          d="M367.79,125.49c1.53-7.08-16.34-10.74-22-2.47C343.87,128,366.71,132.4,367.79,125.49Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '356.779px 123.668px',
          }}
          id="ele6wp9rduxcs"
          className="animable"
        />
        <path
          d="M354.46,144.47h0a17.28,17.28,0,0,0-5.88-1.49.21.21,0,0,0-.21.22.29.29,0,0,0,.27.24,16.75,16.75,0,0,1,5.64,1.42.24.24,0,0,0,.31-.11A.21.21,0,0,0,354.46,144.47Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '351.488px 143.93px',
          }}
          id="elwupohmqftyr"
          className="animable"
        />
        <path
          d="M369.36,144.73a8,8,0,0,1-5.56,2.51c-2.8.06-3.51-2.61-2.16-4.89,1.22-2,4.14-4.56,6.68-3.54S371.12,142.82,369.36,144.73Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '365.733px 142.91px',
          }}
          id="elmetortgcltd"
          className="animable"
        />
        <path
          d="M354.48,135.7c-.13.67-.59,1.15-1,1.06s-.68-.7-.55-1.37.59-1.14,1-1S354.61,135,354.48,135.7Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '353.705px 135.568px',
          }}
          id="eloa5ly5y45l"
          className="animable"
        />
        <path
          d="M346.91,134.23c-.13.67-.59,1.14-1,1.06s-.69-.7-.56-1.37.59-1.15,1-1.06S347,133.56,346.91,134.23Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '346.123px 134.074px',
          }}
          id="elqxfhjxatdr"
          className="animable"
        />
        <path
          d="M349.25,135a24.53,24.53,0,0,1-4.32,5.11c1,1.33,3.21,1.07,3.21,1.07Z"
          style={{
            fill: 'rgb(237, 132, 126)',
            transformOrigin: '347.09px 138.1px',
          }}
          id="elq6qmk8eaa"
          className="animable"
        />
        <path
          d="M353.39,131.81a.43.43,0,0,1,.4.12,3.19,3.19,0,0,0,2.66,1.15.41.41,0,0,1,.46.34.39.39,0,0,1-.34.45,4,4,0,0,1-3.38-1.41.39.39,0,0,1,0-.56A.51.51,0,0,1,353.39,131.81Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '354.993px 132.841px',
          }}
          id="elxm8a7l45ca"
          className="animable"
        />
        <path
          d="M344.57,129.59a.36.36,0,0,1-.21-.13.4.4,0,0,1,.07-.57,4,4,0,0,1,3.57-.77.41.41,0,0,1,.26.51.43.43,0,0,1-.51.25h0a3.19,3.19,0,0,0-2.83.64A.35.35,0,0,1,344.57,129.59Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '346.275px 128.797px',
          }}
          id="elkylgd4eu9d"
          className="animable"
        />
        <path
          d="M376.85,235.56l-8.59-.06,4.44,9.86s7.33-1.57,6.93-5.55Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '373.953px 240.43px',
          }}
          id="elzspo92tl2y"
          className="animable"
        />
        <path
          d="M363.89,242.27l1.41,2.78a3.34,3.34,0,0,0,4.21,1.58l3.19-1.27-4.44-9.86-3.41,2.6A3.33,3.33,0,0,0,363.89,242.27Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '368.113px 241.184px',
          }}
          id="elnothqr6o2go"
          className="animable"
        />
        <path
          d="M311.46,228.3a5.33,5.33,0,0,1-3.81-9c3.82-3.94,7.27-7,10.31-9.67,7.34-6.47,9.87-9,9.63-13.85a48.72,48.72,0,0,0-1.9-8.82c-2.19-8.05-4.67-17.16-.42-24.47,2.28-3.93,6.15-6.62,11.51-8a5.32,5.32,0,0,1,2.66,10.3c-3.77,1-4.68,2.53-5,3.05-2,3.45-.07,10.59,1.49,16.32a55.55,55.55,0,0,1,2.26,11.1c.5,10.25-5.55,15.59-13.22,22.35-2.89,2.56-6.18,5.46-9.72,9.1A5.29,5.29,0,0,1,311.46,228.3Z"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '324.757px 191.323px',
          }}
          id="elpgbe6menp5j"
          className="animable"
        />
        <path
          d="M323.13,151.9l8.32-2.13-1.94,10.64s-7.49.23-8.05-3.72Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '326.455px 155.091px',
          }}
          id="elr5i87ycwnq"
          className="animable"
        />
        <path
          d="M337.29,155.41l-.64,2.78a3.49,3.49,0,0,1-3.88,2.67l-3.26-.45,1.94-10.64,3.82,1.65A3.49,3.49,0,0,1,337.29,155.41Z"
          style={{
            fill: 'rgb(255, 195, 189)',
            transformOrigin: '333.445px 155.332px',
          }}
          id="elhhjr8hmwrkv"
          className="animable"
        />
        <g id="elxejouyqvk4c">
          <rect
            x="289.06"
            y="203.83"
            width="5.5"
            height="46.89"
            rx="2.59"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '291.81px 227.275px',
              transform: 'rotate(44.99deg)',
            }}
            className="animable"
          />
        </g>
        <g id="elh3qo86xox3">
          <rect
            x="304.41"
            y="219.18"
            width="5.5"
            height="46.89"
            rx="2.59"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '307.16px 242.625px',
              transform: 'rotate(45deg)',
            }}
            className="animable"
          />
        </g>
        <g id="elztzhw4ypiyf">
          <path
            d="M293.18,203h29.39a7.17,7.17,0,0,1,7.17,7.17v25.69a7.17,7.17,0,0,1-7.17,7.17H293.18a0,0,0,0,1,0,0V203a0,0,0,0,1,0,0Z"
            style={{
              fill: 'rgb(254, 117, 41)',
              transformOrigin: '311.46px 223.015px',
              transform: 'rotate(-45deg)',
            }}
            className="animable"
          />
        </g>
        <g id="el93kq6l2nb2v">
          <path
            d="M293.18,203h29.39a7.17,7.17,0,0,1,7.17,7.17v25.69a7.17,7.17,0,0,1-7.17,7.17H293.18a0,0,0,0,1,0,0V203a0,0,0,0,1,0,0Z"
            style={{
              fill: 'rgb(255, 255, 255)',
              opacity: '0.7',
              transformOrigin: '311.46px 223.015px',
              transform: 'rotate(-45deg)',
            }}
            className="animable"
          />
        </g>
        <g id="el7rd022fams2">
          <path
            d="M291.58,214.56l-7.2,7.19,28.31,28.31,7.19-7.2a5.74,5.74,0,0,0-1.39-2.22L293.8,216A5.74,5.74,0,0,0,291.58,214.56Z"
            style={{
              fill: 'rgb(254, 117, 41)',
              opacity: '0.2',
              transformOrigin: '302.13px 232.31px',
            }}
            className="animable"
          />
        </g>
        <g id="el0o170pv8f4g">
          <rect
            x="293.5"
            y="211.51"
            width="11.97"
            height="46.89"
            rx="5.62"
            style={{
              fill: 'rgb(254, 117, 41)',
              transformOrigin: '299.485px 234.955px',
              transform: 'rotate(-45deg)',
            }}
            className="animable"
          />
        </g>
      </g>
      <g
        id="freepik--page-not-found--inject-49"
        className="animable animator-active"
        style={{ transformOrigin: '250.02px 82.74px' }}
      >
        <rect
          x="130.06"
          y="37.96"
          width="239.88"
          height="57.52"
          rx="8.42"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '250px 66.72px',
          }}
          id="el1opysvkfj9o"
          className="animable"
        />
        <path
          d="M361.52,96.22h-223a9.17,9.17,0,0,1-9.16-9.16V46.38a9.17,9.17,0,0,1,9.16-9.16h223a9.17,9.17,0,0,1,9.16,9.16V87.06A9.17,9.17,0,0,1,361.52,96.22Zm-223-57.52a7.69,7.69,0,0,0-7.68,7.68V87.06a7.69,7.69,0,0,0,7.68,7.68h223a7.69,7.69,0,0,0,7.68-7.68V46.38a7.69,7.69,0,0,0-7.68-7.68Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '250.02px 66.72px',
          }}
          id="el7w0fsibi6da"
          className="animable"
        />
        <g id="elbz5o45nere">
          <g
            style={{
              opacity: '0.4',
              transformOrigin: '250.07px 68.88px',
            }}
            className="animable"
          >
            <path
              d="M149.78,76.72V55.08h8.16a20.86,20.86,0,0,1,3.3.2,6.68,6.68,0,0,1,2.67,1,5.28,5.28,0,0,1,1.73,2.09,7,7,0,0,1-1.1,7.63q-1.75,1.9-6.34,1.91h-5.56v8.8Zm2.86-11.35h5.6a5.92,5.92,0,0,0,3.94-1,3.66,3.66,0,0,0,1.17-2.9,3.91,3.91,0,0,0-.69-2.33,3.26,3.26,0,0,0-1.81-1.28,12.77,12.77,0,0,0-2.67-.19h-5.54Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '158.043px 65.8982px',
              }}
              id="el36y9bonr4qx"
              className="animable"
            />
            <path
              d="M179.84,74.79A10.24,10.24,0,0,1,177,76.56a8.32,8.32,0,0,1-2.93.51,5.73,5.73,0,0,1-4-1.26,4.16,4.16,0,0,1-1.38-3.22,4.31,4.31,0,0,1,.52-2.11A4.5,4.5,0,0,1,170.6,69a6.72,6.72,0,0,1,1.92-.87,20.59,20.59,0,0,1,2.36-.4,24.45,24.45,0,0,0,4.74-.91c0-.36,0-.6,0-.69a2.93,2.93,0,0,0-.75-2.29,4.46,4.46,0,0,0-3-.9,4.77,4.77,0,0,0-2.77.65,4.1,4.1,0,0,0-1.32,2.33l-2.6-.36a6.48,6.48,0,0,1,1.17-2.69,5.12,5.12,0,0,1,2.35-1.58,10.44,10.44,0,0,1,3.56-.55,9.33,9.33,0,0,1,3.26.47,3.74,3.74,0,0,1,2.67,3,14.87,14.87,0,0,1,.13,2.45v3.54a38.39,38.39,0,0,0,.17,4.69,6.23,6.23,0,0,0,.67,1.88h-2.77A5.59,5.59,0,0,1,179.84,74.79Zm-.22-5.94a18.94,18.94,0,0,1-4.34,1,9.8,9.8,0,0,0-2.32.54,2.29,2.29,0,0,0-1.05.86,2.34,2.34,0,0,0,.44,3,3.41,3.41,0,0,0,2.35.71,5.47,5.47,0,0,0,2.73-.67,4.16,4.16,0,0,0,1.76-1.84,6.47,6.47,0,0,0,.43-2.65Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '175.924px 68.9092px',
              }}
              id="eljtfzmg8xlc"
              className="animable"
            />
            <path
              d="M185.94,78l2.58.38a2.46,2.46,0,0,0,.9,1.74,4.37,4.37,0,0,0,2.7.74,4.69,4.69,0,0,0,2.85-.74,3.65,3.65,0,0,0,1.36-2.06,16.84,16.84,0,0,0,.19-3.41,5.46,5.46,0,0,1-4.34,2,5.91,5.91,0,0,1-5-2.33,9,9,0,0,1-1.78-5.6,10.3,10.3,0,0,1,.82-4.14,6.5,6.5,0,0,1,2.35-2.93,6.36,6.36,0,0,1,3.63-1,5.63,5.63,0,0,1,4.57,2.24V61h2.45V74.59a13,13,0,0,1-.74,5.19,5.48,5.48,0,0,1-2.37,2.42,8.15,8.15,0,0,1-4,.88,7.53,7.53,0,0,1-4.54-1.26A4.26,4.26,0,0,1,185.94,78Zm2.19-9.42a6.76,6.76,0,0,0,1.23,4.5,3.88,3.88,0,0,0,3.07,1.42,3.93,3.93,0,0,0,3.07-1.41,6.55,6.55,0,0,0,1.24-4.42,6.37,6.37,0,0,0-1.28-4.34,3.91,3.91,0,0,0-3.07-1.46,3.82,3.82,0,0,0-3,1.44A6.36,6.36,0,0,0,188.13,68.6Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '192.321px 71.8676px',
              }}
              id="elsubgenywnm"
              className="animable"
            />
            <path
              d="M214,71.67l2.75.34a6.79,6.79,0,0,1-2.41,3.74,7.27,7.27,0,0,1-4.49,1.32A7.16,7.16,0,0,1,204.37,75a8.28,8.28,0,0,1-2-5.95,8.67,8.67,0,0,1,2-6.14,6.91,6.91,0,0,1,5.28-2.18,6.69,6.69,0,0,1,5.14,2.14,8.48,8.48,0,0,1,2,6c0,.16,0,.4,0,.71H205.1a6,6,0,0,0,1.46,4,4.36,4.36,0,0,0,3.28,1.37,4.08,4.08,0,0,0,2.49-.77A5.06,5.06,0,0,0,214,71.67Zm-8.72-4.29H214a5.34,5.34,0,0,0-1-3,4.08,4.08,0,0,0-3.3-1.54,4.19,4.19,0,0,0-3.07,1.23A4.76,4.76,0,0,0,205.25,67.38Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '209.578px 68.9044px',
              }}
              id="elxih9okz35vl"
              className="animable"
            />
            <path
              d="M228.45,76.72V61h2.39v2.23a5.65,5.65,0,0,1,5-2.58,6.53,6.53,0,0,1,2.61.51,4.1,4.1,0,0,1,1.78,1.33,5.24,5.24,0,0,1,.83,2,16.37,16.37,0,0,1,.14,2.58v9.64h-2.65V67.18a7.37,7.37,0,0,0-.31-2.43,2.62,2.62,0,0,0-1.1-1.28,3.53,3.53,0,0,0-1.86-.48,4.3,4.3,0,0,0-2.93,1.08c-.82.72-1.23,2.08-1.23,4.09v8.56Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '234.828px 68.6822px',
              }}
              id="el5xgac94f38h"
              className="animable"
            />
            <path
              d="M244.28,68.88q0-4.35,2.42-6.45a7.3,7.3,0,0,1,4.93-1.74,7,7,0,0,1,5.28,2.12A8,8,0,0,1,259,68.66a10.44,10.44,0,0,1-.91,4.76,6.46,6.46,0,0,1-2.64,2.69,7.71,7.71,0,0,1-3.79,1A7,7,0,0,1,246.31,75,8.42,8.42,0,0,1,244.28,68.88Zm2.73,0a6.71,6.71,0,0,0,1.31,4.51,4.22,4.22,0,0,0,3.31,1.5,4.16,4.16,0,0,0,3.29-1.51,6.8,6.8,0,0,0,1.32-4.59,6.4,6.4,0,0,0-1.33-4.4,4.38,4.38,0,0,0-6.59,0A6.66,6.66,0,0,0,247,68.88Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '251.635px 68.9016px',
              }}
              id="elr3jd0cezuo9"
              className="animable"
            />
            <path
              d="M267.88,74.34l.39,2.35a9.75,9.75,0,0,1-2,.24,4.59,4.59,0,0,1-2.24-.46,2.66,2.66,0,0,1-1.13-1.2,9.8,9.8,0,0,1-.32-3.14v-9h-1.95V61h1.95V57.16l2.64-1.6V61h2.67v2.07h-2.67v9.17a4.79,4.79,0,0,0,.14,1.46,1.13,1.13,0,0,0,.46.51,1.73,1.73,0,0,0,.91.2A8.62,8.62,0,0,0,267.88,74.34Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '264.45px 66.2478px',
              }}
              id="elju1w154oro"
              className="animable"
            />
            <path
              d="M279.52,76.72V63.11h-2.35V61h2.35V59.37A7.18,7.18,0,0,1,279.8,57a3.35,3.35,0,0,1,1.35-1.68,4.85,4.85,0,0,1,2.71-.64,13.59,13.59,0,0,1,2.48.26l-.4,2.32a8.76,8.76,0,0,0-1.57-.15,2.36,2.36,0,0,0-1.71.52,2.83,2.83,0,0,0-.5,1.93V61h3.06v2.07h-3.06V76.72Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '281.755px 65.6957px',
              }}
              id="elk0n5jcrbiz"
              className="animable"
            />
            <path
              d="M286.29,68.88q0-4.35,2.42-6.45a7.33,7.33,0,0,1,4.94-1.74,7,7,0,0,1,5.28,2.12A8,8,0,0,1,301,68.66a10.45,10.45,0,0,1-.9,4.76,6.42,6.42,0,0,1-2.65,2.69,7.67,7.67,0,0,1-3.78,1A7.06,7.06,0,0,1,288.32,75,8.42,8.42,0,0,1,286.29,68.88Zm2.74,0a6.65,6.65,0,0,0,1.31,4.51,4.37,4.37,0,0,0,6.6,0,6.85,6.85,0,0,0,1.31-4.59,6.45,6.45,0,0,0-1.32-4.4,4.38,4.38,0,0,0-6.59,0A6.61,6.61,0,0,0,289,68.88Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '293.641px 68.901px',
              }}
              id="ele86k5g5y42r"
              className="animable"
            />
            <path
              d="M314.37,76.72v-2.3a5.73,5.73,0,0,1-5,2.65,6.22,6.22,0,0,1-2.59-.53A4.25,4.25,0,0,1,305,75.21a5.09,5.09,0,0,1-.82-2,13.46,13.46,0,0,1-.16-2.48V61h2.66v8.7a15.3,15.3,0,0,0,.16,2.8,2.8,2.8,0,0,0,1.06,1.65,3.28,3.28,0,0,0,2,.6,4.35,4.35,0,0,0,2.24-.62,3.4,3.4,0,0,0,1.49-1.67,8.36,8.36,0,0,0,.43-3.06V61h2.66V76.72Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '310.367px 69.0362px',
              }}
              id="elqwhixhxtb8"
              className="animable"
            />
            <path
              d="M320.91,76.72V61h2.39v2.23a5.65,5.65,0,0,1,5-2.58,6.53,6.53,0,0,1,2.61.51,4.1,4.1,0,0,1,1.78,1.33,5.24,5.24,0,0,1,.83,2,16.37,16.37,0,0,1,.14,2.58v9.64H331V67.18a7.37,7.37,0,0,0-.31-2.43,2.62,2.62,0,0,0-1.1-1.28,3.53,3.53,0,0,0-1.86-.48,4.32,4.32,0,0,0-2.93,1.08c-.82.72-1.23,2.08-1.23,4.09v8.56Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '327.288px 68.6822px',
              }}
              id="elzl64cq23pp"
              className="animable"
            />
            <path
              d="M347.9,76.72v-2a4.87,4.87,0,0,1-4.39,2.33,6.12,6.12,0,0,1-3.44-1,6.81,6.81,0,0,1-2.44-2.89,10,10,0,0,1-.86-4.25,11.15,11.15,0,0,1,.78-4.26,6.06,6.06,0,0,1,2.35-2.93,6.26,6.26,0,0,1,3.5-1,5.21,5.21,0,0,1,2.52.6,5.12,5.12,0,0,1,1.8,1.55V55.08h2.64V76.72Zm-8.4-7.82a6.81,6.81,0,0,0,1.27,4.5,3.84,3.84,0,0,0,3,1.49,3.78,3.78,0,0,0,3-1.43,6.55,6.55,0,0,0,1.21-4.34,7.37,7.37,0,0,0-1.24-4.73,3.8,3.8,0,0,0-3.05-1.5,3.69,3.69,0,0,0-3,1.44A7.12,7.12,0,0,0,339.5,68.9Z"
              style={{
                fill: 'rgb(38, 50, 56)',
                transformOrigin: '343.564px 66.0678px',
              }}
              id="elqei4kmm6t4"
              className="animable"
            />
          </g>
        </g>
        <circle
          cx={250}
          cy="106.49"
          r="18.54"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '250px 106.49px',
          }}
          id="ell2nfi893mj9"
          className="animable"
        />
        <circle
          cx={250}
          cy="106.49"
          r="15.78"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '250px 106.49px',
          }}
          id="ell0ksggldz9c"
          className="animable"
        />
        <polygon
          points="260.67 103.09 253.27 103.09 259.48 84.72 239.33 109.89 246.73 109.89 240.52 128.26 260.67 103.09"
          style={{
            fill: 'rgb(254, 117, 41)',
            transformOrigin: '250px 106.49px',
          }}
          id="el9921aex68bo"
          className="animable"
        />
        <g id="elnuqdxk0h1sn">
          <rect
            x="248.44"
            y="88.95"
            width="3.12"
            height="35.09"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '250px 106.495px',
              transform: 'rotate(135deg)',
            }}
            className="animable"
          />
        </g>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />

          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />

          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />

          <feMerge>
            <feMergeNode in="OUTLINE" />

            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>

        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />

          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />

          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />

          <feMerge>
            <feMergeNode in="OUTLINE" />

            <feMergeNode in="SourceGraphic" />
          </feMerge>

          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />
        </filter>
      </defs>
    </svg>
  )
}

export default PageNotFoundSvg
