import * as yup from "yup";

const step0Section3Schema = yup.object().shape({
    employedOrNot: yup
        .string()
        .nullable()
        .required("please_choose_your_employment_status"),

    // If not employed
    unemploymentStatus: yup
        .string()
        .nullable()
        .when("employedOrNot", {
            is: "no",
            then: yup.string().nullable().required("please_choose_a_status"),
        }),

    otherEmploymentStatus: yup
        .string()
        .when(["employedOrNot", "unemploymentStatus"], {
            is: (employedOrNot, unemploymentStatus) =>
                employedOrNot === "no" &&
                unemploymentStatus === "other_unemployed",
            then: yup.string().required("please_enter_a_status"),
        }),

    howYouPayRent: yup
        .string()
        .nullable()
        .when(["employedOrNot", "unemploymentStatus"], {
            is: (employedOrNot, unemploymentStatus) => {
                return (
                    employedOrNot === "no" &&
                    unemploymentStatus !== "self_employed"
                );
            },
            then: yup
                .string()
                .nullable()
                .required("please_choose_how_you_pay_rent"),
        }),

    liablePerson: yup
        .string()
        .nullable()
        .when(["employedOrNot", "howYouPayRent"], {
            is: (employedOrNot, howYouPayRent) =>
                employedOrNot === "no" &&
                howYouPayRent === "additional_solidarity_liability",
            then: yup
                .string()
                .nullable()
                .required("please_enter_the_name_of_the_person"),
        }),

    // if self-employed

    selfEmployedCompanyName: yup
        .string()
        .when(["employedOrNot", "unemploymentStatus"], {
            is: (employedOrNot, unemploymentStatus) =>
                employedOrNot === "no" &&
                unemploymentStatus === "self_employed",
            then: yup.string().required("please_enter_the_company_name"),
        }),
    selfEmployedCompanyAddress: yup
        .string()
        .when(["employedOrNot", "unemploymentStatus"], {
            is: (employedOrNot, unemploymentStatus) =>
                employedOrNot === "no" &&
                unemploymentStatus === "self_employed",
            then: yup.string().required("please_enter_the_company_address"),
        }),

    selfEmployedIncome: yup
        .string()
        .nullable()
        .when(["employedOrNot", "unemploymentStatus"], {
            is: (employedOrNot, unemploymentStatus) =>
                employedOrNot === "no" &&
                unemploymentStatus === "self_employed",
            then: yup.string().required("please_enter_monthly_mid_income"),
        }),

    referenceName: yup.string().when("employedOrNot", {
        is: "no",
        then: yup.string().required("please_enter_a_name_for_reference"),
    }),
    referenceEmail: yup.string().when("employedOrNot", {
        is: "no",
        then: yup
            .string()
            .email("email_is_invalid")
            .required("please_enter_a_email_for_reference"),
    }),
    referencePhone: yup.string().when("employedOrNot", {
        is: "no",
        then: yup
            .string()
            .required("please_enter_your_mobile_no")
            .max(19, "please_enter_a_valid_phone_number"),
    }),

    // If employed
    profession: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_your_profession"),
    }),
    employmentRelationship: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().nullable().required("please_select_any"),
    }),
    // workloadPercent: yup
    //     .string()
    //     .when(["employedOrNot", "employmentRelationship"], {
    //         is: (employedOrNot, employmentRelationship) =>
    //             employedOrNot === "yes" &&
    //             employmentRelationship === "temporary",
    //         then: yup.string().nullable().required("please_select_any"),
    //     }),

    annualSalaryRangeId: yup
        .string()
        .nullable()
        .when("employedOrNot", {
            is: "yes",
            then: yup
                .string()
                .nullable()
                .required("please_select_a_monthly_income"),
        }),

    hasEmploymentTerminated: yup
        .string()
        .nullable()
        .when("employedOrNot", {
            is: "yes",
            then: yup.string().nullable().required("please_select_any"),
        }),

    companyName: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_the_company_name"),
    }),
    companyAddress: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_the_company_address"),
    }),
    employeeName: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_name_of_a_employee"),
    }),
    employeeEmail: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup
            .string()
            .email("please_enter_a_valid_email")
            .required("please_enter_email_of_a_employee"),
    }),
    employeePhoneNo: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup
            .string()
            .required("please_enter_your_mobile_no")
            .max(19, "please_enter_a_valid_phone_number"),
    }),
});

export default step0Section3Schema;
