import React, { forwardRef } from "react";

//* react-hook-form
import { Controller } from "react-hook-form";

//* libraries
import { MuiTelInput } from "mui-tel-input";

//* mui components import
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Grow from "@mui/material/Grow";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import RadioGroup from "@mui/material/RadioGroup";

//* mui icons import
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";

//* mui-x-grid imports
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { useTheme } from "@mui/material/styles";
import moment from "moment";

// import { isGreaterThan12Year } from "../../utils/isGreaterThanYear";

export const ControllerSliderTextField = ({
    name,
    control,
    label,
    SliderComponent,
    sliderProps,
}) => {
    const theme = useTheme();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                return (
                    <SliderComponent
                        {...field}
                        theme={theme}
                        {...sliderProps}
                    />
                );
            }}
        />
    );
};

export const ControllerSliderField = ({
    name,
    control,
    step,
    min,
    max,
    label,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                return (
                    <FormControl fullWidth>
                        <Typography
                            sx={{
                                marginBottom: 1,
                            }}
                            variant="h6"
                        >
                            {`${label}: ${field.value}`}
                        </Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            step={step}
                            min={min}
                            max={max}
                            {...field}
                        />
                    </FormControl>
                );
            }}
        />
    );
};

export const ControllerTextField = ({
    label,
    name,
    type,
    InputProps,
    control,
    multiline,
    minRows,
    maxRows,
    disabled,
    errorFlag,
    errorText,
    fixedWidth,
    controllerFieldProps,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            {...controllerFieldProps}
            render={({ field }) => {
                return (
                    <TextField
                        variant="filled"
                        label={label}
                        {...field}
                        error={errorFlag}
                        helperText={errorText}
                        // multiline={multiline}
                        // minRows={minRows}
                        // maxRows={maxRows}
                        type={type ?? "text"}
                        InputProps={InputProps}
                        disabled={disabled}
                        fullWidth={!fixedWidth}
                        // {...(multiline
                        //     ? {
                        //           multiline: multiline,
                        //           minRows: minRows || 4,
                        //           maxRows: maxRows || 8,
                        //       }
                        //     : {})}
                    />
                );
            }}
        />
    );
};

export const ControllerNumberField = ({
    label,
    name,
    control,
    errorFlag,
    errorText,
    min,
    max,
    mainLabel,
}) => {
    return (
        <FormControl variant="filled" fullWidth>
            {mainLabel && (
                <Typography
                    component={"label"}
                    color="#123763"
                    fontWeight={500}
                    mb={1}
                >
                    {mainLabel}
                </Typography>
            )}
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <TextField
                            variant="filled"
                            label={label}
                            {...field}
                            error={errorFlag}
                            helperText={errorText}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            inputProps={{
                                min,
                                max,
                            }}
                        />
                    );
                }}
            />
        </FormControl>
    );
};

export const ControllerPhoneTextField = ({
    label,
    name,
    control,
    errorFlag,
    errorText,
    fullWidth,
    telInputProps,
}) => {
    return (
        <FormControl variant="filled" fullWidth>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <MuiTelInput
                        {...field}
                        variant="filled"
                        defaultCountry="CH"
                        label={label}
                        forceCallingCode
                        disableFormatting
                        fullWidth={fullWidth}
                        preferredCountries={["CH", "FR", "DE", "IT"]}
                        error={errorFlag}
                        helperText={errorText}
                        {...telInputProps}
                    />
                )}
            />
        </FormControl>
    );
};

export const ControllerStackTextField = ({
    label,
    title,
    name,
    type,
    control,
    errorFlag,
    errorText,
    inputFor,
    disabled,
}) => {
    return (
        <FormControl variant="filled" fullWidth>
            <Stack direction={"row"} spacing={1}>
                {/* <Typography component={"label"} color='#123763' fontWeight={500}>
          {label}
        </Typography> */}
                {/* <Tooltip placement='top' title={title}>
          <InfoIcon sx={{ color: "#123763" }} />
        </Tooltip> */}
            </Stack>
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <TextField
                            variant="filled"
                            label={label}
                            InputProps={
                                inputFor === "price"
                                    ? {
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  CHF
                                              </InputAdornment>
                                          ),
                                      }
                                    : {
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <Tooltip
                                                      placement="top"
                                                      title={title}
                                                  >
                                                      <InfoIcon
                                                          sx={{
                                                              color: "#123763",
                                                          }}
                                                      />
                                                  </Tooltip>
                                              </InputAdornment>
                                          ),
                                      }
                            }
                            {...field}
                            sx={{ borderRadius: 2 }}
                            error={errorFlag}
                            type={type ?? "text"}
                            helperText={errorText}
                            disabled={disabled}
                        />
                    );
                }}
            />
        </FormControl>
    );
};

export const ControllerRadioField = ({
    children,
    label,
    control,
    name,
    option,
    errorFlag,
    errorText,
    style,
    disabled,
}) => {
    return (
        <FormControl variant="filled" fullWidth style={style}>
            <Typography component={"label"} color="secondary" fontWeight={500}>
                {label}
            </Typography>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <RadioGroup row {...field}>
                        {children}
                    </RadioGroup>
                )}
            />
            {errorFlag ? (
                <Typography variant="caption" color="error">
                    {errorText}
                </Typography>
            ) : null}
        </FormControl>
    );
};


export const ControllerDateField = ({
    label,
    control,
    name,
    errorFlag,
    errorText,
    openTo,
    shouldDisableYear,
    disableFuture,
    disablePast,
    lang,
    datePickerProps,
    disabled,
}) => {

    const currentYear = moment().year();
    const minDateValue = moment().year(1910).startOf('year');
    const maxDateValue = moment().subtract(12, 'years').endOf('year');
    const initialYearValue = moment().subtract(12, 'years');

    return (
        <FormControl variant="filled" fullWidth>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => {
                    return (
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={lang}
                        >
                            <DatePicker
                                inputFormat={"DD.MM.yyy"}
                                label={label}
                                disableFuture={disableFuture}
                                shouldDisableYear={shouldDisableYear}
                                {...(openTo ? { openTo } : {})}
                                disablePast={disablePast}
                                value={value || initialYearValue}
                                minDate={minDateValue}
                                // maxDate={maxDateValue}
                                onChange={(newValue) => {
                                    onChange(newValue || null);
                                }}
                                {...datePickerProps}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        error={errorFlag}
                                        helperText={errorText}
                                        disabled={disabled}
                                    />
                                )}
                            />

                        </LocalizationProvider>
                    );
                }}
            />
        </FormControl>
    );
};
export const ControllerYearOfBirthField = ({
    label,
    control,
    name,
    errorFlag,
    errorText,
    lang,
}) => {
    return (
        <FormControl variant="filled" fullWidth>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => {
                    return (
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={lang}
                        >
                            <DatePicker
                                views={["year"]}
                                openTo={"year"}
                                label={label}
                                disableFuture
                                // shouldDisableYear={isGreaterThan12Year}
                                value={value || ""}
                                onChange={(newValue) => {
                                    if (newValue === undefined || newValue === null) {
                                        onChange(null);
                                    } else {
                                        onChange(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        error={errorFlag}
                                        helperText={errorText}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    );
                }}
            />
        </FormControl>
    );
};

// this is for autocomplete to make a smooth dropdown
// https://github.com/mui/material-ui/issues/19262#issuecomment-1276513767

function PaperComponent(paperProps, ref) {
    return (
        <Grow in>
            <Paper {...paperProps} ref={ref} />
        </Grow>
    );
}

const PaperComponentForward = forwardRef(PaperComponent);

export const ControllerAutoCompleteField = ({
    label,
    control,
    name,
    options,
    errorFlag,
    errorText,
    disableClearable,
    variant,
}) => {
    return (
        <FormControl variant="filled" fullWidth>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => {
                    return (
                        <Autocomplete
                            options={options}
                            disableClearable={disableClearable}
                            popupIcon={<ArrowDropDownCircleOutlinedIcon />}
                            PaperComponent={PaperComponentForward}
                            isOptionEqualToValue={(option, value) => {
                                return option && typeof value === "string"
                                    ? option?.value === value?.toLowerCase()
                                    : option?.value === value.value;
                            }}
                            onChange={(event, value) => {
                                if (value) {
                                    onChange(value?.label);
                                } else {
                                    onChange(value);
                                }
                            }}
                            value={value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={label}
                                    variant="filled"
                                    error={errorFlag}
                                    helperText={errorText}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    );
                }}
            />
        </FormControl>
    );
};

export const ControllerSelectField = ({
    children,
    label,
    name,
    control,
    options,
    textFieldProps,
    errorFlag,
    errorText,
}) => {
    return (
        <FormControl variant="filled" fullWidth>
            {/* <Typography component={"label"} color='#123763' fontWeight={500}>
                            
        {label}
      </Typography> */}
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <TextField
                            select
                            SelectProps={{
                                IconComponent: ArrowDropDownCircleOutlinedIcon,
                            }}
                            variant="filled"
                            label={label}
                            error={errorFlag}
                            helperText={errorText}
                            {...field}
                            {...textFieldProps}
                        >
                            {children}
                        </TextField>
                    );
                }}
            />
        </FormControl>
    );
};

export const ControllerCompanyAutocomplete = ({
    control,
    label,
    name,
    isLoading,
    predictions,
    onInputChange,
    errorFlag,
    errorText,
    autoCompleteProps,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Autocomplete
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.description
                    }
                    {...field}
                    // disableClearable
                    {...autoCompleteProps}
                    clearOnBlur
                    popupIcon={<ArrowDropDownCircleOutlinedIcon />}
                    filterOptions={(x) => x}
                    options={predictions ?? []}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => {
                        return option && typeof value === "string"
                            ? option.description === value
                            : option.description === value.description;
                    }}
                    onChange={(event, value) => {
                        if (value) {
                            field?.onChange(value.description);
                        }
                    }}
                    onInputChange={onInputChange}
                    loading={isLoading}
                    noOptionsText="Type a valid company name"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            label={label}
                            variant="filled"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            error={errorFlag}
                            helperText={errorText}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props}>
                                <Stack direction={"row"} gap={2}>
                                    <LocationOnIcon />
                                    <Typography>
                                        {option.description}
                                    </Typography>
                                </Stack>
                            </li>
                        );
                    }}
                />
            )}
        />
    );
};

export const ControllerMuiSelect = ({
    children,
    label,
    name,
    control,
    options,
    errorFlag,
    errorText,
    displayEmpty,
}) => {
    return (
        <FormControl variant="filled" fullWidth>
            <Typography component={"label"} fontWeight={500} mb={1}>
                {label}
            </Typography>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <Select
                        {...field}
                        displayEmpty={displayEmpty}
                        fullWidth
                        error={errorFlag}
                        helperText={errorText}
                    >
                        {children}
                    </Select>
                )}
            />
        </FormControl>
    );
};

export const TitleSpacer = ({ label }) => {
    return (
        <FormControl variant="filled" fullWidth>
            <Typography
                component={"label"}
                color="#68437e"
                fontWeight={500}
                style={{ fontSize: "1.25rem" }}
                mb={1}
            >
                {label}
            </Typography>
        </FormControl>
    );
};

export const FieldHintSpacer = ({ label }) => {
    return (
        <FormControl variant="filled" fullWidth>
            <Typography
                component={"label"}
                color="#68437e"
                fontWeight={300}
                style={{ fontSize: "1.0rem", fontStyle: "italic" }}
                mb={1}
                mt={2}
            >
                {label}
            </Typography>
        </FormControl>
    );
};

export const FieldDottedLineSpacer = () => {
    return (
        <FormControl fullWidth variant="filled" margin="normal" component="div">
            <hr
                style={{
                    border: "none",
                    borderTop: "1px dotted #cccccc",
                    margin: "0 48px 0 48px",
                }}
            />
        </FormControl>
    );
};
