import React, {useState} from "react";

//* translation
import {useTranslation} from "react-i18next";
//* cookie
import cookies from "js-cookie";
//* react-hook-form
import {useForm, useFormContext} from "react-hook-form";

//* custom components
import {
    ControllerPhoneTextField,
    ControllerRadioField,
    ControllerTextField,
} from "../../mui-components/MuiDossierFormComponents";

// Custom options
import {tenantGender, tenantTitle} from "../../../options/dossierOptions";

//* mui components import
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

// mui icons imports
import {toast} from "react-toastify";
import {yupResolver} from "@hookform/resolvers/yup";
import {BasicContactDataSchema} from "../../../forms-schemas/auth-schemas/BasicContactDataSchema";
import {useTheme} from "@mui/material/styles";
import {useAuthModal} from "../../../context/ModalContext";
import useRegistration from "../../../hooks/Auth/useRegistration";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import DialogTitle from "@mui/material/DialogTitle";
import useUserContext from "../../../hooks/user-context/useUserContext";
import Stack from "@mui/material/Stack";
import {Link} from "react-router-dom";

function EnterBasicContactData() {
    const {t} = useTranslation();
    const theme = useTheme();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const {closeAuthModal, handleAuthForms, handleAuthData, userRole} =
        useAuthModal();
    const {isLoading, mutate} = useRegistration();
    const [success, setSuccess] = useState(false);
    //const { control, handleSubmit, formState: { errors } } = useFormContext();
    const {userContext, updateUserContext} = useUserContext();

    const {
        control,
        handleSubmit,
        reset,
        setError,
        formState: {errors},
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(BasicContactDataSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: ""
        },
    });

    const submitForm = (data) => {
        const {email} = data;
        let userData = {
            email: data.email,
            gender: data.salutation,
            first_name: data.firstName,
            last_name: data.lastName,
            phone: data.phoneNo,
            username: data.firstName + data.lastName,
            role_type: "TN",
            device_type: "website",
            language: currentLanguageCode,
        };
        //console.log("userData:", userData);

        mutate(userData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    setSuccess(false);
                    let message = data?.data?.message;
                    let errorCheck = message?.toLowerCase();
                    if (errorCheck.includes("email")) {
                        setError("email", {
                            type: "server",
                            message: `${t("this_email_is_already_taken")}`,
                        });
                    }
                    if (errorCheck.includes("username")) {
                        setError("username", {
                            type: "server",
                            message: `${t(
                                "this_username_is_already_taken_try_a_different_one"
                            )}`,
                        });
                    }
                    return;
                }

                setSuccess(true);
                handleAuthData({email});

                const newContext = {
                    ...userContext,
                    actionCloseValue: "enterContactDataFinished",
                };
                updateUserContext(newContext);

                setTimeout(() => {
                    handleAuthForms("verify");
                    reset({
                        email: "",
                    });
                }, 800);
                /*
                 */
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    return (
        <Grid container rowSpacing={1}>
            <form onSubmit={handleSubmit(submitForm)}>
                <Grid
                    id="alert-dialog-title"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: theme.palette.primary.main,
                    }}
                >
                    <Typography
                        component="p"
                        variant="h4"
                        sx={{color: theme.palette.primary.main}}
                    >
                        {t("property_detail_apply_with_basic_contact_data_title")}
                        <Typography
                            component="p"
                            variant="body2"
                            sx={{color: theme.palette.primary.main}}
                        >
                            {t("property_detail_apply_with_basic_contact_data_body")}
                        </Typography>
                    </Typography>
                </Grid>

                <Grid item container spacing={3} sx={{justifyContent: "space-between", padding: "20px 1px 20px 5px"}}>
                    <Grid item xs={12} md={12}>
                        <ControllerRadioField
                            name="salutation"
                            label={t("")}
                            control={control}
                            errorFlag={!!errors?.salutation}
                            errorText={t(errors?.salutation?.message)}
                        >
                            {tenantGender.map((gender) => (
                                <FormControlLabel
                                    key={gender.id}
                                    label={t(gender.label)}
                                    value={gender.value}
                                    control={<Radio/>}
                                />
                            ))}
                        </ControllerRadioField>
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <ControllerTextField
                            control={control}
                            name="firstName"
                            // title={t("dossier_first_name_info")}
                            label={t("first_name")}
                            errorFlag={!!errors?.firstName}
                            errorText={t(errors?.firstName?.message)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ControllerTextField
                            control={control}
                            name="lastName"
                            label={t("last_name")}
                            errorFlag={!!errors?.lastName}
                            errorText={t(errors?.lastName?.message)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <ControllerPhoneTextField
                            control={control}
                            name="phoneNo"
                            label={t("phone_number")}
                            errorFlag={!!errors?.phoneNo}
                            errorText={t(errors?.phoneNo?.message)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <ControllerTextField
                            control={control}
                            name="email"
                            label={t("email")}
                            errorFlag={!!errors?.email}
                            errorText={t(
                                errors?.email?.message
                            )}
                        />

                    </Grid>
                    <Grid container spacing={2} sx={{justifyContent: "space-between", padding: "40px 1px 20px 1px"}}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button type="submit" variant="contained" endIcon={<SendIcon/>}>
                                {t("property_detail_apply_with_dossier_title_request_appointment")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            flexDirection={"row"}
                            justifyContent={"center"}
                            gap={1}
                        >
                            <Typography variant="subtitle">
                                {t("already_have_an_account")}
                            </Typography>
                            <Link
                                to="#"
                                style={{
                                    // color: "secondary",
                                    color: theme.palette.secondary.main,
                                    textDecoration: "none",
                                }}
                                onClick={() => handleAuthForms("login")}
                            >
                                {t("sign_in")}
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}

export default EnterBasicContactData;

// minDate={moment().subtract(500, "years")}
