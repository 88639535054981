import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { AxiosPrivate } from "../../axios/axios";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    // axios interceptors
    // req.headers["x-access-token"];

    useEffect(() => {
        const requestIntercept = AxiosPrivate.interceptors.request.use(
            (config) => {
                if (!config.headers["x-access-token"]) {
                    config.headers["x-access-token"] = auth?.accessToken;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = AxiosPrivate.interceptors.response.use(
            (response) => response,
            async (error) => {
                // console.log("🚀 ~ file: useAxiosPrivate.js:27 ~ error:", error);
                const prevRequest = error?.config;

                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();

                    prevRequest.headers["x-access-token"] = newAccessToken;
                    return AxiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            AxiosPrivate.interceptors.request.eject(requestIntercept);
            AxiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh]);

    return AxiosPrivate;
};

export default useAxiosPrivate;
