import FacebookIcon from "../component/custom-icons/FacebookIcon";
import InstagramIcon from "../component/custom-icons/Instagram";
import TikTokIcon from "../component/custom-icons/TikTokIcon";
import LinkedInIcon from "../component/custom-icons/LinkedInIcon";
// import TwitterIcon from "@mui/icons-material/Twitter";

export const socialMediaLinks = [
    {
        key: "instagram",
        platform: "Instagram",
        link: "https://instagram.com/nextflat.ch",
        icon: <InstagramIcon fontSize="large" />,
    },
    {
        key: "facebook",
        platform: "Facebook",
        link: "https://www.facebook.com/nextflat.ch",
        icon: <FacebookIcon fontSize="large" />,
    },
    {
        key: "tiktok",
        platform: "Tiktok",
        link: "https://www.tiktok.com/@nextflat.ch",
        icon: <TikTokIcon fontSize="large" />,
    },
    {
        key: "linkedin",
        platform: "LinkedIn",
        link: "https://ch.linkedin.com/company/nextflat",
        icon: <LinkedInIcon fontSize="large" />,
    },
    //   {
    //     key: "twitter",
    //     platform: "Twitter",
    //     link: "",
    //     icon: <TwitterIcon />,
    //   },
];
