import React, { useCallback, useEffect, useMemo, useState } from "react";

import cookies from "js-cookie";

//*  Translation
import { useTranslation } from "react-i18next";

//* libraries
import { useLocation, useSearchParams } from "react-router-dom";

//* store imports
import { useStateValue } from "../../store/StateProvider";

//* map imports
import Map from "../../component/map/Map";

//* component import
import FilterModal from "../../component/modal/properties/FilterModal";
import useGetAllProperties from "../../hooks/properties/useProperties";

//* mui components import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import ClearIcon from "@mui/icons-material/Clear";

import GoodMatchChanceCard from "../../component/cards/GoodMatchChanceCard";
import GoodMatchChanceCardSkeleton from "../../component/skeleton/GoodMatchChanceCardSkeleton";
import { Chip, useTheme } from "@mui/material";
import { checkForEmpty } from "../../utils/checkDataFunction";
import { isObjectValuesEmpty } from "../../utils/IsObjectValuesEmpty";
// import { NoPropertyFound } from "../../component/no_data_found/NoPropertyFound";

export default function ExploreList() {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const [{ user_id }] = useStateValue();
    const theme = useTheme();
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [filterOpen, setFilterOpen] = useState(false);
    const [showMap, setShowMap] = useState(false);

    const getSearchParams = (name) => {
        let value = searchParams.get(name);
        if (checkForEmpty(value) === "") return "";
        return value;
    };
    const {
        isLoading: propertiesLoading,
        data: allProperties,
        refetch: refetchProperties,
    } = useGetAllProperties({
        page: currentPage,
        limit: showMap ? 100 : 10,
        type: 2,
        lang: "en",
        city_id: getSearchParams("city_id"),
        shortName: getSearchParams("short_name"),
        place_type: getSearchParams("place_type"), //type of place like is it a canton or zip etc
        category_id: getSearchParams("category_id"),
        floor: getSearchParams("floor"),
        // period: getSearchParams("period"),
        space_from: getSearchParams("space_from"),
        space_to: getSearchParams("space_to"),
        number_bedroom: getSearchParams("room_to"),
        room_from: getSearchParams("room_from"),
        room_to: getSearchParams("room_to"),
        price: getSearchParams("price_to"),
        price_from: getSearchParams("price_from"),
        price_to: getSearchParams("price_to"),
        aminity:
            searchParams.getAll("amenities").length === 0
                ? ""
                : searchParams.getAll("amenities"),
        user_id: user_id || "",
    });

    const handleClickFilterOpen = () => {
        setFilterOpen(true);
    };
    const handleFilterClose = () => {
        setFilterOpen(false);
    };
    const handleShowMap = () => {
        setShowMap(!showMap);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, [pathname, currentPage]);

    const handlePageChange = (e, page) => setCurrentPage(page - 1);

    const LoadingProperties = () => {
        return [1, 2, 3, 4].map((_, idx) => (
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                    p: 1,
                    mb: 2,
                }}
                key={idx}
            >
                <GoodMatchChanceCardSkeleton />
            </Grid>
        ));
    };

    const MapPlaceholder = useMemo(
        () => (
            <Skeleton variant="rectangular" width={"100%"} height={"100vh"} />
        ),
        []
    );

    const PlaceFilterChip = ({ placeType, zip, cityName }) => {
        if (placeType === "" && cityName === "") return "";
        let cityNameValue = "";
        if (placeType === "zip") {
            cityNameValue = `${zip} ${cityName}`;
        } else {
            cityNameValue = `${placeType} ${cityName}`;
        }
        return (
            <Box
                display={"flex"}
                gap={2}
                alignItems={"center"}
                border={`1px solid ${theme.palette.secondary.main}`}
                p={1}
                borderRadius={5}
            >
                <Typography variant="caption" color={"primary"}>
                    {t("location")}
                </Typography>
                <Chip
                    color="primary"
                    label={cityNameValue}
                    onDelete={() => {
                        // https://stackoverflow.com/a/75753399 for getting previous search params value
                        setSearchParams((prevParams) => {
                            return new URLSearchParams({
                                ...Object.fromEntries(prevParams.entries()),
                                city_id: "",
                                city_name: "",
                                zip: "",
                                short_name: "",
                                place_type: "",
                            });
                        });
                    }}
                />
            </Box>
        );
    };

    const CategoryFilterChip = ({ category }) => {
        if (category === "") return null;
        return (
            <Box
                display={"flex"}
                gap={2}
                alignItems={"center"}
                border={`1px solid ${theme.palette.secondary.main}`}
                p={1}
                borderRadius={5}
            >
                <Typography variant="caption" color={"primary"}>
                    {t("category")}
                </Typography>
                <Chip
                    color="primary"
                    label={t(category)}
                    onDelete={() => {
                        // https://stackoverflow.com/a/75753399 for getting previous search params value
                        setSearchParams((prevParams) => {
                            return new URLSearchParams({
                                ...Object.fromEntries(prevParams.entries()),
                                category_id: "",
                                category_name: "",
                            });
                        });
                    }}
                />
            </Box>
        );
    };

    const LivingSpaceFilterChip = ({ spaceFrom, spaceTo }) => {
        if (spaceFrom === "" && spaceTo === "") return null;
        let spaceFromValue = spaceFrom === "" ? "any" : `${spaceFrom} sq m.`;
        let spaceToValue = spaceTo === "" ? "any" : `${spaceTo} sq m.`;

        return (
            <Box
                display={"flex"}
                gap={2}
                alignItems={"center"}
                border={`1px solid ${theme.palette.secondary.main}`}
                p={1}
                borderRadius={5}
            >
                <Typography variant="caption" color={"primary"}>
                    {t("living_space")}
                </Typography>
                <Chip
                    color="primary"
                    label={`${spaceFromValue} - ${spaceToValue}`}
                    onDelete={() => {
                        setSearchParams((prevParams) => {
                            return new URLSearchParams({
                                ...Object.fromEntries(prevParams.entries()),
                                space_from: "",
                                space_to: "",
                            });
                        });
                    }}
                />
            </Box>
        );
    };

    const PriceFilterChip = ({ priceFrom, priceTo }) => {
        if (priceFrom === "" && priceTo === "") return null;
        let priceFromValue = priceFrom === "" ? "any" : `${priceFrom} CHF`;
        let priceToValue = priceTo === "" ? "any" : `${priceTo} CHF`;

        return (
            <Box
                display={"flex"}
                gap={2}
                alignItems={"center"}
                border={`1px solid ${theme.palette.secondary.main}`}
                p={1}
                borderRadius={5}
            >
                <Typography variant="caption" color={"primary"}>
                    {t("budget")}
                </Typography>
                <Chip
                    color="primary"
                    label={`${priceFromValue} - ${priceToValue}`}
                    onDelete={() => {
                        setSearchParams((prevParams) => {
                            return new URLSearchParams({
                                ...Object.fromEntries(prevParams.entries()),
                                price_from: "",
                                price_to: "",
                            });
                        });
                    }}
                />
            </Box>
        );
    };
    const RoomFilterChip = ({ roomFrom, roomTo }) => {
        if (roomFrom === "" && roomTo === "") return null;
        let roomFromValue = roomFrom === "" ? "any" : `${roomFrom} `;
        let roomToValue = roomTo === "" ? "any" : `${roomTo}`;

        return (
            <Box
                display={"flex"}
                gap={2}
                alignItems={"center"}
                border={`1px solid ${theme.palette.secondary.main}`}
                p={1}
                borderRadius={5}
            >
                <Typography variant="caption" color={"primary"}>
                    {t("rooms")}
                </Typography>
                <Chip
                    color="primary"
                    label={`${roomFromValue} - ${roomToValue}`}
                    onDelete={() => {
                        setSearchParams((prevParams) => {
                            return new URLSearchParams({
                                ...Object.fromEntries(prevParams.entries()),
                                room_from: "",
                                room_to: "",
                            });
                        });
                    }}
                />
            </Box>
        );
    };

    const ResetFilters = ({ filtersApplied }) => {
        const onReset = () => {
            setSearchParams({});
        };
        if (isObjectValuesEmpty(filtersApplied)) return null;
        return (
            <Button
                variant="text"
                onClick={onReset}
                endIcon={<ClearIcon />}
                disableRipple
            >
                {t("reset_filters")}
            </Button>
        );
    };

    return (
        <>
            <Container maxWidth="lg" sx={{ pt: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box>
                            <Grid container sx={{ textAlign: "center", mb: 2 }}>
                                <Grid item xs={12}>
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography
                                            component={"span"}
                                            className={`fi fi-ch`}
                                            sx={{ mr: 1 }}
                                        />

                                        <Box>
                                            {propertiesLoading ? (
                                                <Skeleton width={200} />
                                            ) : allProperties?.data
                                                  ?.totalItems > 0 ? (
                                                <Typography
                                                    variant="h5"
                                                    fontSize={"1.3em"}
                                                    color="secondary"
                                                >
                                                    <Typography
                                                        component={"span"}
                                                        variant="h5"
                                                        fontSize={"1.3em"}
                                                        color="primary"
                                                    >
                                                        {allProperties?.data
                                                            ?.totalItems + "+"}
                                                    </Typography>
                                                    &nbsp;
                                                    {t(
                                                        "explore_for_rent_in_switzerland"
                                                    )}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    color="error"
                                                    sx={{
                                                        textAlign: "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {t(
                                                        "no_properties_found_message"
                                                    )}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center"
                                    >
                                        {allProperties?.data?.totalItems > 0 &&
                                            (showMap ? (
                                                <Button
                                                    onClick={handleShowMap}
                                                    disabled={propertiesLoading}
                                                    variant="contained"
                                                    startIcon={
                                                        <FormatListBulletedIcon />
                                                    }
                                                >
                                                    {t("list")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={handleShowMap}
                                                    disabled={propertiesLoading}
                                                    variant="contained"
                                                    startIcon={
                                                        <LocationOnIcon />
                                                    }
                                                >
                                                    {t("map")}
                                                </Button>
                                            ))}
                                        <Button
                                            onClick={handleClickFilterOpen}
                                            variant="contained"
                                            disabled={propertiesLoading}
                                            startIcon={<TuneRoundedIcon />}
                                        >
                                            {t("filters")}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display={"flex"}
                                    gap={3}
                                    flexWrap={"wrap"}
                                    width={"100%"}
                                >
                                    <PlaceFilterChip
                                        placeType={getSearchParams(
                                            "place_type"
                                        )}
                                        cityName={getSearchParams("city_name")}
                                        zip={getSearchParams("zip")}
                                    />
                                    <CategoryFilterChip
                                        category={getSearchParams(
                                            "category_name"
                                        )}
                                    />
                                    <PriceFilterChip
                                        priceFrom={getSearchParams(
                                            "price_from"
                                        )}
                                        priceTo={getSearchParams("price_to")}
                                    />
                                    <LivingSpaceFilterChip
                                        spaceFrom={getSearchParams(
                                            "space_from"
                                        )}
                                        spaceTo={getSearchParams("space_to")}
                                    />
                                    <RoomFilterChip
                                        roomFrom={getSearchParams("room_from")}
                                        roomTo={getSearchParams("room_to")}
                                    />

                                    <ResetFilters
                                        filtersApplied={{
                                            placeType:
                                                getSearchParams("place_type"),
                                            cityName:
                                                getSearchParams("city_name"),
                                            zip: getSearchParams("zip"),
                                            category:
                                                getSearchParams(
                                                    "category_name"
                                                ),
                                            priceFrom:
                                                getSearchParams("price_from"),
                                            priceTo:
                                                getSearchParams("price_to"),
                                            spaceFrom:
                                                getSearchParams("space_from"),
                                            spaceTo:
                                                getSearchParams("space_to"),
                                            roomFrom:
                                                getSearchParams("room_from"),
                                            roomTo: getSearchParams("room_to"),
                                        }}
                                    />
                                </Box>
                            </Grid>
                            {!showMap ? (
                                <Grid container item mt={2}>
                                    {propertiesLoading
                                        ? LoadingProperties()
                                        : allProperties?.data?.results?.map(
                                              (property) => {
                                                  return (
                                                      <Grid
                                                          key={property?.id}
                                                          item
                                                          xs={12}
                                                          mb={2}
                                                      >
                                                          <GoodMatchChanceCard
                                                              matchChance={
                                                                  property
                                                              }
                                                          />
                                                      </Grid>
                                                  );
                                              }
                                          )}
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    item
                                    height={"723px"}
                                    width={"100%"}
                                    mt={2}
                                >
                                    <Grid item xs={12}>
                                        {propertiesLoading ? (
                                            <MapPlaceholder />
                                        ) : (
                                            <Map
                                                properties={
                                                    allProperties?.data?.results
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Box>

                        {/* right now we are not hiding the pagination because the functionality is working there */}
                        {!showMap && allProperties?.data?.totalPages > 0 && (
                            <Stack
                                direction={"row"}
                                justifyContent="center"
                                mb={2}
                            >
                                <Pagination
                                    count={allProperties?.data?.totalPages}
                                    page={currentPage + 1}
                                    onChange={handlePageChange}
                                    color="primary"
                                    variant="outlined"
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        )}
                    </Grid>

                    {/* {!removeMap && (
            <Grid item lg={12}>
              {propertiesLoading ? (
                MapPlaceholder()
              ) : (
                <Map
                  properties={allProperties?.data?.results}
                  removeMap={removeMap}
                />
              )}
            </Grid>
          )} */}
                </Grid>
            </Container>
            <FilterModal
                open={filterOpen}
                handleClose={handleFilterClose}
                refetchProperties={refetchProperties}
            />
        </>
    );
}
