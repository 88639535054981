import PropTypes from "prop-types";

//* mui imports
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

const Droplet = ({
   position,
   size,
   bgcolor,
   children,
   containerStyleProps,
}) => {
   const theme = useTheme();
   const borderRadius = position === "left" ? "50% 50% 50% 0" : "50% 50% 0 50%";

   function backgroundColor(bgcolor) {
      let color;

      switch (bgcolor) {
         case "primary":
            color = theme.palette.primary.main;
            break;
         case "primaryLight":
            color = theme.palette.primary.light;
            break;
         case "secondary":
            color = theme.palette.secondary.main;
            break;
         case "secondaryLight":
            color = theme.palette.secondary.light;
            break;

         default:
            color = "transparent";
            break;
      }
      return color;
   }

   return (
      <Box
         sx={{
            // border: "1px solid black",
            height: size || 100,
            width: size || 100,
            borderRadius: borderRadius,
            bgcolor: backgroundColor(bgcolor),
            ...containerStyleProps,
         }}
      >
         {children}
      </Box>
   );
};

Droplet.propTypes = {
   bgcolor: PropTypes.string,
   children: PropTypes.node,
   containerStyleProps: PropTypes.object,
   position: PropTypes.string,
   size: PropTypes.number,
};

export default Droplet;
