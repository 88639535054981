import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    ControllerPhoneTextField,
    TitleSpacer,
    FieldHintSpacer,
    ControllerSelectField,
    ControllerSliderTextField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";
import ControllerSliderComponent from "../../../dossier_components/ControllerSliderComponent";

import { useContext } from "react";
import FormSectionContextSegmentN, {
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, FormGroup } from "@mui/material";
import { movingOutOption } from "../../../../../../options/dossierOptions";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { DossierReferenceBox } from "../../../dossier_components/DossierReferenceBox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useTheme } from "@mui/material/styles";

const TenantOneSegmentFive = ({ additionalProps }) => {
    const theme = useTheme();

    const renderSlider = true;

    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        watchHasNoCurrentApartment,
        watchMovingOutOf,
        reasonForMovingOptSort,
        watchIsImmediateAndAfterAgreement,
        referenceStatus,
        handleReferenceStatus,
        control,
        errors,
        sectionVariants,
        direction,
        isPayment,
    } = additionalProps;

    console.log("isPayment", isPayment);

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid item container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Controller
                        name="hasNoCurrentApartment"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field?.value}
                                    />
                                }
                                label={t(
                                    "i_do_not_have_a_current_rented_apartment"
                                )}
                            />
                        )}
                    />
                </Grid>

                {watchHasNoCurrentApartment ? (
                    <Fragment>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerSelectField
                                control={control}
                                name="movingOutOf"
                                label={t("i_am_moving_out_of")}
                                errorFlag={!!errors?.movingOutOf}
                                errorText={t(errors?.movingOutOf?.message)}
                            >
                                {movingOutOption.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </ControllerSelectField>
                        </Grid>
                        {watchMovingOutOf === "other" && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerTextField
                                    name="otherMovingOut"
                                    label={t("please_describe")}
                                    errorFlag={!!errors?.otherMovingOut}
                                    errorText={t(
                                        errors?.otherMovingOut?.message
                                    )}
                                    control={control}
                                    multiline
                                    minRows={3}
                                />
                            </Grid>
                        )}
                        {watchMovingOutOf === "parental_home" && (
                            <Fragment>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        name="parentName"
                                        label={t("name_of_parent")}
                                        control={control}
                                        errorFlag={!!errors?.parentName}
                                        errorText={t(
                                            errors?.parentName?.message
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        name="parentEmail"
                                        label={t("email_of_parent")}
                                        control={control}
                                        errorFlag={!!errors?.parentEmail}
                                        errorText={t(
                                            errors?.parentEmail?.message
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerPhoneTextField
                                        control={control}
                                        name="parentPhoneNo"
                                        label={t("phone_of_parent")}
                                        errorFlag={!!errors?.parentPhoneNo}
                                        errorText={t(
                                            errors?.parentPhoneNo?.message
                                        )}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerSliderTextField
                                    control={control}
                                    name="currentRent"
                                    label={t("current_gross_monthly_rent")}
                                    renderSlider
                                    SliderComponent={ControllerSliderComponent}
                                    sliderProps={{
                                        label: t("current_gross_monthly_rent"),
                                        min: 400,
                                        max: 8000,
                                        step: 50,
                                    }}
                                />

                                {/*
                                <ControllerSliderComponent
                                    name="currentRentSlider"
                                    label={t("current_gross_monthly_rent")}
                                    control={control}
                                />
                                <ControllerTextField
                                    name="currentRent"
                                    label={t(
                                        "current_gross_monthly_rent"
                                    )}
                                    control={control}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                CHF
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                name="currentAdministrationName"
                                label={t(
                                    "name_of_current_administration_for_your_reference"
                                )}
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                name="currentAdministrationContactName"
                                label={t("name_of_contact_person")}
                                control={control}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                name="currentAdministrationEmail"
                                label={t(
                                    "email_of_current_administration_for_your_reference"
                                )}
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerPhoneTextField
                                control={control}
                                name="currentAdministrationPhoneNo"
                                label={t(
                                    "phone_no_of_current_administration_for_your_reference"
                                )}
                            />
                        </Grid>
                        {/* termination */}
                        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <ControllerRadioField
                      control={control}
                      name="hasApartmentCancelled"
                      label={`${t("current_apartment_is_already_cancelled")}`}>
                      <FormControlLabel
                        label={t("no")}
                        value="no"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        label={t("yes")}
                        value="yes"
                        control={<Radio />}
                      />
                    </ControllerRadioField>
                  </Grid>
                  {hasApartmentCancelled === "yes" && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <ControllerRadioField
                        control={control}
                        name="apartmentCancelledBy"
                        label={`${t("who_cancelled_the_apartment")}`}>
                        <FormControlLabel
                          label={t("administration")}
                          value="administration"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label={t("tenant")}
                          value="tenant"
                          control={<Radio />}
                        />
                      </ControllerRadioField>
                    </Grid>
                  )}
                  {watchEmployed === "yes" && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <ControllerRadioField
                        control={control}
                        name="hasEmploymentTerminated"
                        label={`${t("employment_relationship_terminated")}`}>
                        <FormControlLabel
                          label={t("no")}
                          value="no"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label={t("yes")}
                          value="yes"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label={t("no_specification")}
                          value="no_specification"
                          control={<Radio />}
                        />
                      </ControllerRadioField>
                    </Grid>
                  )} */}
                    </Fragment>
                )}
                {(watchHasNoCurrentApartment === false ||
                    watchMovingOutOf === "parental_home") && (
                    <Grid item xs={12}>
                        <DossierReferenceBox
                            heading={t(
                                "you_have_chosen_the_verified_version_of_our_smart_dossier_administration_reference"
                            )}
                            bodyText={t(
                                "you_have_chosen_the_verified_version_of_our_smart_dossier_administration_reference_body_text"
                            )}
                            buttonTextOne={t("request")}
                            buttonTextTwo={t("administration_reference")}
                            buttonFor={"administration"}
                            status={referenceStatus?.administration}
                            tenantRole={"tenantOne"}
                            isDisabled={
                                referenceStatus?.administration !== "" ||
                                isPayment !== 1
                            }
                            //isDisabled={referenceStatus?.administration !== ""}
                            handleReferenceStatus={handleReferenceStatus}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography
                        component={"label"}
                        color={"secondary"}
                        fontWeight={500}
                        // mb={1}
                    >
                        {t("reason_of_change_of_residence_description")}?
                    </Typography>

                    <Controller
                        name="residencyChangeReason"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="filled"
                                select
                                {...field}
                                label={t("reason_of_change_of_residence")}
                                SelectProps={{
                                    IconComponent:
                                        ArrowDropDownCircleOutlinedIcon,
                                }}
                                fullWidth
                                error={!!errors?.residencyChangeReason}
                                helperText={t(
                                    errors?.residencyChangeReason?.message
                                )}
                            >
                                {reasonForMovingOptSort.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerTextField
                        name="residencyChangeReasonComment"
                        label={t("reason_of_change_of_residence_comment")}
                        control={control}
                        multiline
                        minRows={3}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography
                                component={"label"}
                                color={"secondary"}
                                fontWeight={500}
                            >
                                {t("earliest_possible_movin_date") + "?"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <Controller
                                    name="isImmediateAndAfterAgreement"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field?.value}
                                                />
                                            }
                                            label={t(
                                                "immediately_&_after_agreement"
                                            )}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Grid>
                        {!watchIsImmediateAndAfterAgreement && (
                            <Grid item xs={12}>
                                <em>{t("date_of_moving_description")}</em>
                                <ControllerDateField
                                    control={control}
                                    openTo={"year"}
                                    datePickerProps={{
                                        disablePast: true,
                                    }}
                                    name="dateOfMoving"
                                    label={t("date") + "*"}
                                    errorFlag={!!errors?.dateOfMoving}
                                    errorText={t(errors?.dateOfMoving?.message)}
                                    lang={currentLanguageCode}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </DossierFormSection>
    );
};

export default TenantOneSegmentFive;
