export const LOGGED_IN_OPTIONS = [
    // {
    //     id: 1,
    //     name: "favorite",
    //     link: "/account/fs/favorites",
    //     Icon: "",
    // },
    // { id: 2, name: "chat", link: "/account/chats", Icon: "" },
    {
        id: 3,
        name: "my_profile",
        link: "/account/fs/smart-dossier",
        Icon: "person",
    },
    {
        id: 4,
        name: "settings",
        Icon: "settings",
        link: "/account/settings",
    },
    {
        id: 5,
        name: "services",
        link: "/all-service",
        Icon: "build",
    },
    {
        id: 6,
        name: "support",
        link: "/account/support",
        Icon: "HelpCenterIcon",
    },
    {
        id: 7,
        name: "logout",
        Icon: "logout",
    },
];
