export const DISTANCE_OPTIONS = [
   "0",
   "1",
   "2",
   "3",
   "4",
   "5",
   "6",
   "7",
   "8",
   "9",
   "10",
].map((distance) => ({
   value: distance,
   label: `+ ${distance} KM`,
}));
