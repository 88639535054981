import React from "react"; // { useState }

//* component import
import CreateObjectForm from "../../component/forms/property-form/CreateObjectForm";

//* SVG

//* mui components imports
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

function CreatePropertyObject() {
    return (
        <Container maxWidth="lg" sx={{ py: 10 }}>
            <Grid container spacing={2}>
                <Grid item lg={12}>
                    <CreateObjectForm />
                </Grid>
            </Grid>
        </Container>
    );
}

export default CreatePropertyObject;
