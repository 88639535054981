import { useMutation, useQuery } from "react-query";
import { Axios } from "../../axios/axios";

function addWishTenantForObject(wishTenantData) {
    return Axios.post(
        "/create_wish_tenant_properties_matching",
        wishTenantData
    );
}

function getWishTenantData({ queryKey }) {
    const id = queryKey[1];
    return Axios.get(`/get_wish_tenant_properties_matching?property_id=${id}`);
}

export function useGetWishTenantData(id) {
    return useQuery(["get-wish-tenant-data", id], getWishTenantData, {
        enabled: id !== null || id !== undefined,
    });
}

export function useAddWishTenantForObject() {
    return useMutation(addWishTenantForObject);
}
