import React from "react";

//*  using toast for success and error
import {Flip, toast, Zoom} from "react-toastify";

//*  Translation
import {useTranslation} from "react-i18next";

//* components imports
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

//* mui components imports
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useStateValue} from "../../store/StateProvider";

import {useEffect} from "react";
import {
    ControllerPhoneTextField,
    ControllerTextField,
} from "../../component/mui-components/MuiDossierFormComponents";
import {PartnershipSchema} from "../../forms-schemas/PartnershipSchema";
import {useSendMail} from "../../hooks/send-email/useSendMail";
import {BENEFITS} from "../../static_options/partnershipOptions";

import ArrowButton from "../../component/elements/button/ArrowButton";
import {CreateBenefitsStepsHeading} from "../../component/mui-components/CustomBenefitsComponent";
import {Stack, styled} from "@mui/material";

//* react-hook-form
import {Controller} from "react-hook-form";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';

const Partnership = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const {mutate} = useSendMail();

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            partnershipFirstName: "",
            partnershipLastName: "",
            partnershipYourFunction: "",
            partnershipEmail: "",
            partnershipMobileNo: "",
            partnershipSubject: "",
            partnershipMessage: "",
        },
        resolver: yupResolver(PartnershipSchema),
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, []);

    const onSubmitData = (data) => {
        const {
            partnershipFirstName,
            partnershipLastName,
            partnershipYourFunction,
            partnershipEmail,
            partnershipMobileNo,
            partnershipSubject,
            partnershipMessage,
        } = data;

        let partnershipMail = {
            name: `${partnershipFirstName} ${partnershipLastName}`,
            email_to: "partnership@nextflat.ch",
            email_from: partnershipEmail,
            email_subject: partnershipSubject,
            message: partnershipMessage,
            job_function: partnershipYourFunction,
            contact:
                partnershipMobileNo?.length <= 3 ? "" : partnershipMobileNo,
        };

        mutate(partnershipMail, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    toast.error(
                        "Oops! something went wrong please try again later!",
                        {
                            transition: Zoom,
                        }
                    );
                    return;
                }
                toast.success(
                    "Thankyou for contacting us! We will respond to you as soon as possible.",
                    {
                        transition: Flip,
                    }
                );

                reset();
            },
            onError: (errors) => {
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    const handleOnClick = () => {
        //navigate("/create-object");
        navigate("/contact");
    };

    return (
        <Box sx={{py: 10, minHeight: "100vh"}}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Stack gap={2}>
                            <Typography variant="h4">
                                {t("are_you_an_administration")}
                            </Typography>
                            <Box>
                                <Typography variant="h5">
                                    {t("partnership_heading_one")}
                                </Typography>
                                <Typography variant="h5">
                                    {t("partnership_heading_two")}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Grid item container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <CreateBenefitsStepsHeading
                                    heading={`${t("your_benefits")}`}
                                    benefits={BENEFITS}
                                    t={t}
                                />
                            </Grid>

                                    {/*
                            <Grid item xs={12} md={6}>
                                <Typography variant="h4" color="secondary" marginBottom={2}>
                                    {"Jetzt inserieren und profitieren"}
                                </Typography>
                                <ArrowButton
                                    variant="contained"
                                    color='primary'
                                    onClick={handleOnClick}
                                >
                                    {t("advertise_now")}
                                </ArrowButton>
                            </Grid>
                                    */}

                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant={"h4"}
                                    color="secondary"
                                    marginBottom={2}
                                >
                                    {t("your_message_questions")}
                                </Typography>
                                <form onSubmit={handleSubmit(onSubmitData)}>
                                    <Grid item container spacing={4}>
                                        <Grid item xs={12} md={6}>
                                            <ControllerTextField
                                                control={control}
                                                name={"partnershipFirstName"}
                                                label={t("first_name")}
                                                errorFlag={
                                                    !!errors?.partnershipFirstName
                                                }
                                                errorText={t(
                                                    errors?.partnershipFirstName
                                                        ?.message
                                                )}
                                                sx={{
                                                    backgroundColor: "warning",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ControllerTextField
                                                control={control}
                                                name={"partnershipLastName"}
                                                label={t("last_name")}
                                                errorFlag={
                                                    !!errors?.partnershipLastName
                                                }
                                                errorText={t(
                                                    errors?.partnershipLastName
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ControllerTextField
                                                control={control}
                                                name={"partnershipYourFunction"}
                                                label={t("your_function")}
                                                errorFlag={
                                                    !!errors?.partnershipYourFunction
                                                }
                                                errorText={t(
                                                    errors
                                                        ?.partnershipYourFunction
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ControllerTextField
                                                type={"email"}
                                                control={control}
                                                name={"partnershipEmail"}
                                                label={t("email")}
                                                errorFlag={
                                                    !!errors?.partnershipEmail
                                                }
                                                errorText={t(
                                                    errors?.partnershipEmail
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ControllerPhoneTextField
                                                control={control}
                                                name={"partnershipMobileNo"}
                                                label={t("mobile_no.")}
                                                errorFlag={
                                                    !!errors?.partnershipMobileNo
                                                }
                                                errorText={t(
                                                    errors?.partnershipMobileNo
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ControllerTextField
                                                control={control}
                                                name={"partnershipSubject"}
                                                label={t("subject")}
                                                errorFlag={
                                                    !!errors?.partnershipSubject
                                                }
                                                errorText={t(
                                                    errors?.partnershipSubject
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <ControllerTextField
                                                control={control}
                                                name={"partnershipMessage"}
                                                label={t("message")}
                                                multiline
                                                minRows={6}
                                                maxRows={10}
                                                errorFlag={
                                                    !!errors?.partnershipMessage
                                                }
                                                errorText={t(
                                                    errors?.partnershipMessage
                                                        ?.message
                                                )}
                                            /> */}
                                            <Controller
                                                control={control}
                                                name={"partnershipMessage"}
                                                render={({field}) => {
                                                    return (
                                                        <TextField
                                                            variant="filled"
                                                            label={t("message")}
                                                            multiline
                                                            minRows={4}
                                                            maxRows={8}
                                                            fullWidth
                                                            {...field}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            display={"flex"}
                                            justifyContent={"flex-end"}
                                        >
                                            <ArrowButton
                                                type="submit"
                                                variant="contained"
                                            >
                                                {t("send")}
                                            </ArrowButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Partnership;
