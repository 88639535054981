import { useEffect, useState } from "react";

//*  using toast for success and error
import { toast } from "react-toastify";

//* Translation
import { useTranslation } from "react-i18next";

//* mui component import
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller, useFormContext } from "react-hook-form";
import useResendVerification from "../../../../hooks/Auth/useResendVerification";
import { slideUp } from "../../../../utils/animation/Animation";

function VerifyStep() {
    const {
        control,
        getValues,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation();

    const { mutate: onResend, isLoading: resendIsLoading } =
        useResendVerification();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(20);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [minutes, seconds]);

    const handleResend = () => {
        onResend(
            { email: getValues("email") },
            {
                onSuccess: (data) => {
                    if (!data?.data?.statuscode) {
                        toast.error(t("something_went_wrong"));
                        return;
                    }
                    toast.success(
                        t(
                            "we_have_sent_have_sent_a_new_code_to_your_email_address"
                        )
                    );
                    setMinutes(1);
                    setSeconds(29);
                },
                onError: () => {
                    toast.error(`${t("we_are_facing_some_technical_issue")}`);
                },
            }
        );
    };

    return (
        <Grid
            variants={slideUp}
            initial="hidden"
            animate="visible"
            container
            rowSpacing={3}
        >
            <Grid item xs={12}>
                <Typography textAlign={"center"} variant="h4">
                    {t("check_your_email_for_a_code")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign={"center"}>
                    {t("please_enter_6_character_code_sent_to_your_mail")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {/* {verifyStatus?.isError && (
                    <Alert severity="error">
                        {t("verification_code_is_invalid_or_expired")}
                    </Alert>
                )}
                {verifyStatus?.isSuccess && (
                    <Alert severity="success">
                        {t("verification_code_verified")}
                    </Alert>

                )} */}
                {errors?.authOtp?.message && (
                    <Alert severity="error">
                        {t(errors?.authOtp?.message)}
                    </Alert>
                )}
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ position: "relative" }}>
                    <Controller
                        control={control}
                        name={"authOtp"}
                        render={({ field }) => {
                            return (
                                <MuiOtpInput
                                    length={6}
                                    columnGap={{
                                        xs: "5px",
                                        md: "20px",
                                    }}
                                    TextFieldsProps={{
                                        // size: "small",
                                        placeholder: "-",
                                        error: !!errors?.authOtp,
                                    }}
                                    {...field}
                                />
                            );
                        }}
                    />
                </Box>
            </Grid>
            {/* {!!errors?.authOtp || ( */}
            <Grid item xs={12}>
                <Stack justifyContent={"center"} alignItems={"center"}>
                    {seconds > 0 || minutes > 0 ? (
                        <Typography variant="subtitle1" textAlign={"center"}>
                            {t("time_remaining")}
                            <br />
                            {`${minutes}m ${seconds}s`}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1">{`${t(
                            "didnt_recieve_the_code"
                        )}?`}</Typography>
                    )}
                    <ButtonBase
                        sx={{
                            "&.Mui-disabled": {
                                color: "#dedadb",
                            },
                            color: (theme) =>
                                seconds > 0 || minutes > 0
                                    ? "#DFE3E8"
                                    : theme.palette.primary.dark,
                        }}
                        disabled={seconds > 0 || minutes > 0 || resendIsLoading}
                        onClick={handleResend}
                    >
                        <u>
                            {resendIsLoading
                                ? `${t("resend_code")}...`
                                : t("resend_code")}
                        </u>
                    </ButtonBase>
                </Stack>
            </Grid>
            {/* )} */}
        </Grid>
    );
}

export default VerifyStep;
