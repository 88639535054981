import {styled, useTheme} from "@mui/material/styles";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";

import {useTranslation} from "react-i18next";

// *** by AAR
const StepperStepColorIcon = styled("div")(({theme, ownerState}) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#E0E0E0",
    zIndex: 1,
    color: (ownerState.active || ownerState.completed) ? '#fff' : '#68437e',
    width: 44,
    height: 44,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    transition: 'transform 0.3s, border 0.3s',
    ...(ownerState.active ? {} : {
        '&:hover': {
            transform: 'scale(1.3)',
            border: '2px solid #68437e',
        },
    }),
    ...(ownerState.active && {
        border: '2px solid #68437e',
        backgroundColor: "",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.secondary.main,
    }),
}));

const DossierStepperLineConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#E0E0E0",
        borderRadius: 1,
    },
}));

function DossierStepperIcon({ active, completed, className, Icon, color }) {
    return (
        <StepperStepColorIcon
            ownerState={{ completed, active }}
            className={className}
        >
            <Icon sx={{ color: active ? color : "#fff", fontSize: 30 }} />
        </StepperStepColorIcon>
    );
}

function DossierSubStepper({ formSectionIcons, currentSection, onSubStepClick }) {
    const theme = useTheme();

    return (
        <Stepper
            sx={{ my: 4, color: theme.palette.secondary.main }}
            alternativeLabel
            activeStep={currentSection}
            connector={<DossierStepperLineConnector />}
        >
            {formSectionIcons.map((entry, index) => (
                <Step key={index} onClick={() => onSubStepClick(index)}>
                    <StepLabel
                        StepIconComponent={(props) => <DossierStepperIcon {...props} Icon={entry.Icon} size="small" color={theme.palette.primary.main} />}

                    />
                </Step>
            ))}
        </Stepper>
    );
}

export default DossierSubStepper;
