import React from "react";
import Stack from "@mui/material/Stack";
import Lottie from "lottie-react";
import { NoData } from "../../utils/imgImports";

function NoDataFound({
    showOnFullPage,
    height,
    containerSxProps,
    lottieStyleProps,
}) {
    return (
        <Stack
            sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                ...containerSxProps,
            }}
        >
            <Lottie
                animationData={NoData}
                {...(lottieStyleProps
                    ? { style: { ...lottieStyleProps } }
                    : {})}
            />
        </Stack>
    );
}

export default NoDataFound;
