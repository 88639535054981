import React, {forwardRef} from "react";

//* mui components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";

import {NextFlatLogo} from "../../../assets/imgImport";
import {useAuthModal} from "../../../context/ModalContext";

//* auth forms
import ForgotForm from "../../forms/auth-forms/ForgotForm";
import LoginForm from "../../forms/auth-forms/LoginForm";
import RegisterForm from "../../forms/auth-forms/RegisterForm";
import VerifyOtpForm from "../../forms/auth-forms/VerifyOtpForm";
import EnterBasicContactData from "../../forms/basic-contact/EnterBasicContactData";

//* mui icons
import CloseIcon from "@mui/icons-material/Close";

//* responsive
import useResponsive from "../../../hooks/responsive/useResponsive";
import useUserContext from '../../../hooks/user-context/useUserContext';

const Transition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

function AuthModal({open, onClose}) {
    const {authForm} = useAuthModal();
    const {isBelowSm} = useResponsive();
    const {userContext, updateUserContext} = useUserContext();

    const tmpOrigin = userContext?.origin ?? '';
    const tmpAction = userContext?.action ?? '';
    const tmpActionValue = userContext?.actionValue ?? '';
    const tmpActionOpenValue = userContext?.actionOpenValue ?? '';
    const tmpActionCloseValue = userContext?.actionCloseValue ?? '';

    //console.log("Origin AuthModal: " + userContext.origin + " / " + userContext.action + " / " + userContext.actionValue + " / " + userContext.actionOpenValue + " / " + userContext.actionCloseValue);

    const getForm = () => {
        switch (authForm) {
            case "login":
                return <LoginForm/>;
            case "register":
                return <RegisterForm/>;
            case "forgot":
                return <ForgotForm/>;
            case "enterBasicContactData":
                return <EnterBasicContactData/>;
            case "verify":
                return <VerifyOtpForm/>;
            default:
                break;
        }
    };

    return (
        <Dialog
            fullScreen={isBelowSm}
            open={open}
            scroll="body"
            PaperProps={{
                sx: {
                    width: 500,
                    // minHeight: authForm === "register" ? 420 : 300,
                },
            }}
            fullWidth
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={Transition}
            transitionDuration={500}
        >
            <Stack justifyContent={"space-between"} p={1} flexDirection={"row"}>
                <Stack
                    width={"100%"}
                    justifyContent={"center"}
                    flexDirection={"row"}
                    sx={{
                        pt: 2,
                        pl: 2,
                    }}
                >
                    <img src={NextFlatLogo} width={150} alt="logo"/>
                </Stack>
                <CloseIcon
                    onClick={onClose}
                    sx={{
                        cursor: "pointer",
                    }}
                />
            </Stack>

            <DialogContent>{getForm()}</DialogContent>
        </Dialog>
    );
}

export default AuthModal;
