import * as React from "react";

//*  using toast for success and error
import {toast, Zoom, Flip} from "react-toastify";

//* translation
import {useTranslation} from "react-i18next";

//* store imports
import {useStateValue} from "../../store";
import {useChangeRequestStatus} from "../../hooks/properties/useChangeRequestStatus";

//* mui components imports
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";


export default function MatchOverview({
                                          show,
                                          handleClose,
                                          onApplyProperty,
                                          isDossierCreated,
                                          modalTitle,
                                          modalBody,
                                          onCreateDossier,
                                          onDoPropertyAppointment,
                                          propertyId,
                                          userAttributes,
                                          applicationStatus,
                                      }) {
    const {t} = useTranslation();
    const theme = useTheme();
    const [{user_id}] = useStateValue();

    /*
    const {
        isLoading: isLoadingMinRequireData,
        status: statusMinRequireData,
        data: minRequireData,
    } = useGetMinimumDataRequirement(propertyId);
    let minRequireResult = minRequireData?.data?.result;
    const {
        isLoading: isLoadingUserDossierData,
        status: statusUserDossierData,
        data: userDossierData,
    } = useGetDossierDataForMatch(user_id);
    let dossierDataForMatch = userDossierData?.data?.data;
    console.log("🚀 ~ minRequireResult:", minRequireResult);
    console.log("🚀 ~ dossierDataForMatch:", dossierDataForMatch);
    */
    const [missingAttributes, setMissingAttributes] = useState([]);

    /*
    const NumberedItem = ({number, children}) => (
        <Box display="flex" alignItems="flex-start" gap={2}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    minWidth: 28,
                    height: 28,
                    borderRadius: "50%",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    fontSize: "0.925rem",
                    paddingTop: "1px",
                    mt: "8px",
                    mb: "8px",
                }}
            >
                {number}
            </Box>
            <Typography sx={{mt: "8px"}}>{children}</Typography>
        </Box>
    );

    const keyMapping = {
        salaryRange: "UserSalary",
        moveInAvailability: "availability",
        hasChildren: "hasChildren",
        hasNoNegativeDebtEntries: "isNegativeTenantOneDebtEntry",
        hasStableEmployment: "is_employed",
        hasPet: "is_pet",
        isLiveInSwitzerland: "country",
        hasAdminReference: "isAdminReference",
        ageRange: "user_date_of_birth",
        hasEmployReference: "isEmployerReference",
        residencyPermits: "residence_permit",
        salaryStatement: "salary_id",
        hasPassport: "passport_id",
        hasDeptCollect: "debt_collect",
    };

    const minRequirementAttributes = useMatchAttributesApplication(
        userAttributes,
        minRequireResult,
        dossierDataForMatch,
        keyMapping
    );
     */
    /*
     */
    /*
    console.log("minRequireResult: ", minRequireResult);
    console.log("dossierDataForMatch: ", dossierDataForMatch);
    console.log("matchingAttributes: ", matchingAttributes);
    console.log("userAttributes: ", userAttributes);
    */
    /*********************
     checking 1st level of application - the appointment requirements
     ********************/
    const [missingApplicationAttributes, setMissingApplicationAttributes] = useState([]);
    const [globalMissingAttributesCount, setGlobalMissingAttributesCount] = useState(0);

    const keyAppointmentAttributes = {
        first_name: t("first_name"),
        last_name: t("last_name"),
        //phone: t("phone"),
        //natal_no: t("mobile_no."),
        email: t("email"),
    };

    const extractAndValidateApplicationAttributes = (userAttributes, keyMapping) => {
        if (!userAttributes || typeof userAttributes !== 'object') {
            //console.log("User Attributes nicht verfügbar oder im falschen Format.");
            return {
                missingApplicationAttributes: Object.values(keyMapping)
            };
        }

        let tempMissingAttributes = [];
        let count = 0;
        const isValidPhone = userAttributes.phone && checkPhoneFormat(userAttributes.phone);
        const isValidNatalPhone = userAttributes.natal_no && checkPhoneFormat(userAttributes.natal_no);

        Object.keys(keyMapping).forEach(key => {
            if (!userAttributes[key] || (key === 'phone' && !isValidPhone) || (key === 'natal_no' && !isValidNatalPhone)) {
                tempMissingAttributes.push(keyMapping[key]);
                count++;
            }
        });

        setMissingApplicationAttributes(tempMissingAttributes);
        setGlobalMissingAttributesCount(count);
    };

    useEffect(() => {
        if (userAttributes && typeof userAttributes === 'object') {
            extractAndValidateApplicationAttributes(userAttributes, keyAppointmentAttributes);
        } else {
            //console.log("Warte auf Benutzerdaten...");
        }
    }, [userAttributes]);


    const checkPhoneFormat = (phone) => {
        const regex = /^\+[0-9]{5,}$/;
        return regex.test(phone);
    };

    if (userAttributes && typeof userAttributes === 'object') {
        //console.log("Missing Attributes:", missingApplicationAttributes);
    } else {
        //console.log("Warte auf Benutzerdaten...");
    }

    /*
        //console.log("Count of missing attributes:", globalMissingAttributesCount);
        const trueCount = Object.values(matchingAttributes).filter(
            (value) => value
        ).length;

        useEffect(() => {
            if (userAttributes) {
                const requiredAttributes = {
                    first_name: t("first_name"),
                    last_name: t("last_name"),
                    user_date_of_birth: t("date_of_birth"),
                    phone: t("phone"),
                    residence_permit: t("residence_permit"),
                    profession: t("profession"),
                    net_monthly_income: t("gross_annual_salary_incl_13"),
                    salary_statement: t("salary_statement"),
                    companyName: t("name_of_employer"),
                    reason_change_residence:
                        t("reason_of_change_of_residence"),
                    availability: t("availability"),
                    hasChildren: t("no_of_children"),
                    criminal_record: t("criminal_record"),
                    is_pet: t("pets"),
                    //"company_name": "Arbeitgebername", ???
                };

                const missing = Object.keys(requiredAttributes).filter(
                    (key) => !userAttributes[key]
                );
                const missingReadableNames = missing.map(
                    (attr) => requiredAttributes[attr]
                );


                setMissingAttributes(missingReadableNames);
            } else {
                setMissingAttributes([]);
            }
        }, [userAttributes]);
    */
    const shouldShowEditButton = (applicationStatus <= 0 && globalMissingAttributesCount > 0) || (applicationStatus >= 3 && Array.isArray(missingAttributes) && missingAttributes.length > 0);

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    minHeight: '300px',
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: 'absolute',
                    top: 8,
                    right: 8,
                }}
            >
                <CloseIcon
                    onClick={handleClose}
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                        color: theme.palette.grey[500],
                    }}
                />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                sx={{display: "flex", flexDirection: "column", gap: 1}}
            >
                <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                    <Typography
                        component="span"
                        variant="h5"
                        sx={{color: theme.palette.primary.main}}
                    >
                        {modalTitle}
                    </Typography>
                </Box>
                {/*
                <Typography
                    component="p"
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "normal",
                        fontSize: "1.0rem",
                        mt: 0.5,
                    }}
                >
                    {t(
                        "property_detail_apply_with_dossier_match_attributes_overview_subtext"
                    )}
                </Typography>
                */}
                {
                    (applicationStatus <= 0 && globalMissingAttributesCount > 0) ? (
                        <>
                            <Box sx={{display: 'flex', alignItems: 'center', color: 'error.main', mb: 1}}>
                                <CancelIcon sx={{
                                    mr: 1,
                                    border: '2px solid error.main',
                                    color: 'error.main',
                                    borderRadius: '50%'
                                }}/>
                                <Typography
                                    component="p"
                                    variant="subtitle2"
                                    sx={{
                                        color: 'error.main',
                                        fontWeight: 'normal',
                                        fontSize: '1.0rem',
                                        mt: 0.5,
                                        mb: -1,
                                    }}
                                >
                                    {t("property_detail_apply_with_dossier_match_attributes_overview_oops")}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        applicationStatus >= 3 && Array.isArray(missingAttributes) && missingAttributes.length > 0 ? (
                            <>
                                <Box sx={{display: 'flex', alignItems: 'center', color: 'error.main', mb: 1}}>
                                    <CheckCircleIcon sx={{
                                        mr: 1,
                                        border: '2px solid error.main',
                                        borderRadius: '50%',
                                        color: 'error.main'
                                    }}/>
                                    <Typography
                                        component="p"
                                        variant="subtitle2"
                                        sx={{
                                            color: 'darkgreen',
                                            fontWeight: 'normal',
                                            fontSize: '1.0rem',
                                            mt: 0.5,
                                            mb: -1,
                                        }}
                                    >
                                        {t("property_detail_apply_with_dossier_match_attributes_overview_oops")}
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', color: 'green'}}>
                                    <CheckCircleIcon sx={{
                                        mr: 1.25,
                                        border: '2px solid #007300',
                                        borderRadius: '50%',
                                        color: '#007300',
                                        mt: '3.75px',
                                        mb: '-3.75px'
                                    }}/>
                                    <Typography
                                        component="p"
                                        variant="subtitle2"
                                        sx={{
                                            color: '#007300',
                                            fontWeight: 'normal',
                                            fontSize: '1.0rem',
                                            mt: 0.5,
                                            mb: -1,
                                        }}
                                    >

                                        {
                                            //console.log(applicationStatus)
                                        }
                                        {applicationStatus === 1 ?
                                            t("property_detail_apply_with_dossier_match_button_start_application_already_done") :
                                            applicationStatus === 2 ?
                                                t("your_custom_message_for_status_2") :
                                                applicationStatus >= 3 ?
                                                    t("property_detail_apply_with_dossier_success_Message") :
                                                    t("property_detail_apply_with_dossier_ready_to_start")
                                        }
                                    </Typography>
                                </Box>
                            </>
                        )
                    )
                }
            </DialogTitle>

            <DialogContent>
                <DialogContentText
                    component="p"
                    id="alert-dialog-description"
                    sx={{margin: "0 0", padding: "6px"}}
                >
                    {
                        (applicationStatus <= 0 && globalMissingAttributesCount > 0) ? (
                            <Box sx={{marginTop: "8px"}}>
                                {Array.isArray(missingApplicationAttributes) && missingApplicationAttributes.length > 0 ? (
                                    missingApplicationAttributes.map((attr, index) => (
                                        <Typography
                                            component="p"
                                            key={index}
                                            sx={{
                                                mt: "8px",
                                                fontSize: "0.875rem",
                                                color: "red",
                                            }}
                                        >
                                            {`${index + 1}. ${attr}`}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography>--</Typography>
                                )}
                            </Box>
                        ) : (
                            applicationStatus >= 3 && Array.isArray(missingAttributes) && missingAttributes.length > 0 ? (
                                <Box sx={{marginTop: "8px"}}>
                                    {
                                        missingAttributes.map((attr, index) => (
                                            <Typography
                                                component="p"
                                                key={index}
                                                sx={{
                                                    mt: "4px",
                                                    fontSize: "1.2rem",
                                                }}
                                            >
                                                {`${index + 1}. ${attr}`}
                                            </Typography>
                                        ))
                                    }
                                </Box>
                            ) : (
                                <>
                                    {/*
                                    {modalBody}
                                    */}
                                </>
                            )
                        )
                    }
                </DialogContentText>
                {/*
                <Grid item xs={4}>
                    <ScoreProgress size={100} score={30}/>
                </Grid>
                */}
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between"}}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                >

                    {
                        //fill out button for Smart Dossier (redirect) if something is missing show this button
                    }
                    {shouldShowEditButton ? (
                        <Button
                            variant="contained"
                            onClick={onDoPropertyAppointment}
                            sx={{
                                borderRadius: "16px",
                                mt: 0,
                                border: "1px solid",
                                height: "30px",
                                backgroundColor: theme.palette.primary.main,
                                borderColor: theme.palette.secondary.main,
                                mr: "auto",
                                minWidth: "250px",
                                width: 'fit-content',
                                padding: '0px 16px',
                                whiteSpace: 'nowrap',
                            }}
                            startIcon={<EditIcon/>}
                        >
                            {t("property_detail_appointment_with_dossier_fillout")}
                        </Button>
                    ) : applicationStatus >= 3 && Array.isArray(missingAttributes) && missingAttributes.length === 0 ? (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                height: "30px",
                                ml: "auto",
                            }}
                            onClick={onCreateDossier}
                            startIcon={<EditIcon/>}
                        >
                            {t("property_detail_apply_with_dossier_fillout")}
                        </Button>
                    ) : null}

                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}
                    >
                        {/*
                        <Button
                            color="error"
                            variant="outlined"
                            sx={{
                                height: "30px",
                                borderRadius: "18px",
                                color: theme.palette.primary.main,
                                borderColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.light,
                                    borderColor: theme.palette.primary.dark,
                                },
                            }}
                            startIcon={<CancelIcon/>}
                            onClick={handleClose}
                        >
                            {t(
                                "property_detail_apply_with_dossier_match_button_cancel_application"
                            )}
                        </Button>
                        */}

                        {globalMissingAttributesCount < 1 && applicationStatus < 1 ? (

                            <Button
                                variant="contained"
                                sx={{
                                    height: "30px",
                                    backgroundColor: theme.palette.primary.main,
                                }}
                                onClick={onApplyProperty}
                                disabled={false}
                                endIcon={<SendIcon/>}
                            >
                                {t("property_detail_apply_with_dossier_match_button_start_application_request_appointment")}
                            </Button>
                        ) : (
                            <>
                                <Button
                                    variant="contained"
                                    sx={{
                                        height: "30px",
                                        backgroundColor: theme.palette.grey[500],
                                    }}
                                    onClick={onApplyProperty}
                                    disabled={true}
                                    endIcon={<SendIcon/>}
                                >
                                    {t("property_detail_apply_with_dossier_match_button_start_application_request_appointment")}
                                </Button>
                            </>
                        )}
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}