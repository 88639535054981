//* mui imports
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

//* custom layouts
import IconWithContent from "../content-box/IconWithContent";
import useResponsive from "../../hooks/responsive/useResponsive";

const ContentBox = styled(Box)(() => ({
   display: "flex",
   flexDirection: "column",
   gap: "35px",
}));

const HowItWorks = ({ data }) => {
   const { isBelowMd } = useResponsive();
   return (
      <ContentBox>
         {data.map((work, index) => {
            return (
               <IconWithContent
                  key={work.id}
                  bgcolor={"primaryLight"}
                  animationData={work.lottieIcon}
                  num={work.id}
                  dropletPosition={index % 2 === 0 ? "left" : "right"}
                  title={work.title}
                  subtitle={work.subtitle}
                  {...(isBelowMd ? { size: 243 } : { size: 350 })}
                  {...(index % 2 !== 0 ? { layoutDirection: "reverse" } : {})}
               />
            );
         })}
      </ContentBox>
   );
};

export default HowItWorks;
