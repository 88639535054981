//* cookie
import cookies from "js-cookie";

import { useTranslation } from "react-i18next";

import EnglishImprint from "./EnglishImprint";
import GermanImprint from "./GermanImprint";

const Imprint = () => {
   // don't comment the below line
   const { t } = useTranslation();
   const currentLanguageCode = cookies.get("i18next") || "en";

   let selectedComponent;

   switch (currentLanguageCode) {
      case "en":
         selectedComponent = <EnglishImprint />;
         break;
      case "de":
         selectedComponent = <GermanImprint />;
         break;

      default:
         selectedComponent = <GermanImprint />;
         break;
   }

   return selectedComponent;
};

export default Imprint;
