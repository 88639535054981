import {
    Andre,
    AndreImage,
    Karim,
    Marcus,
    Silva,
    Taman,
    Arlinda,
    //  Hani,
    //  Shokha,
    //  Alen,
} from "../assets/imgImport";

export const NEXTFLAT_TEAM = [
    {
        id: 1,
        image: Karim,
        name: "Karim Mohr",
        position: "Co-Founder & Platform Enthusiast",
    },
    {
        id: 2,
        image: Taman,
        name: "Taman Choschnau",
        position: "Founder & CEO",
    },
    {
        id: 3,
        image: Silva,
        name: "Silvan Nyffenegger",
        position: "Co-Founder & Real Estate Professional",
    },
    {
        id: 4,
        image: Marcus,
        name: "Marcus Schlink",
        position: "Co-Founder & BoD President",
    },
    {
        id: 5,
        image: Andre,
        name: "André Alexander Rust",
        //name_image: "AndreImage",
        position: "CTO, Security Guy & Tech Company Visionary",
    },
    {
        id: 6,
        image: Arlinda,
        name: "Arlinda Ismajli",
        position: "UI/UX Design",
    },
    // {
    //    id: 7,
    //    image: Hani,
    //    name: "Hani Gysin",
    //    position: "Praktikant",
    // },
    // {
    //    id: 8,
    //    image: Shokha,
    //    name: "Shokha Choschnau",
    //    position: "Leitung Administration",
    // },
    // {
    //    id: 9,
    //    image: Alen,
    //    name: "Alen Maliqi",
    //    position: "Praktikant",
    // },
];
