import { tenantTitle } from "../options/dossierOptions";
import { checkForEmpty } from "./checkDataFunction";

export function getTitle(title) {
    if (checkForEmpty(title) === "") return "";
    let tenantTitleObj = tenantTitle.find(
        (titleItem) => titleItem.value === title
    );
    if (tenantTitleObj === undefined) return "other";

    return title;
}

export function getOtherTitle(title) {
    if (checkForEmpty(title) === "") return "";

    let tenantTitleObj = tenantTitle.find(
        (titleItem) => titleItem.value === title
    );

    if (tenantTitleObj === undefined) return title;

    return "";
}
