import { useQuery } from "react-query";
import { Axios } from "../../axios/axios";

function getProfileDetailsById({ queryKey }) {
    const id = queryKey[1];
    return Axios.get(`users/profile?id=${id}`, {
        // headers: {
        //     "x-access-token": token,
        // },
    });
}

export default function useIsSmartDossierPaid(id) {
    return useQuery(["get-smart-dossier-payment", id], getProfileDetailsById, {
        enabled: Boolean(id),
        select: (data) => {
            let userData = data?.data?.data;

            // return dossierData;
            if (Array.isArray(userData) && userData.length > 0) {
                // let dossierValues = dossierData[0];
                const { dossierPayment, paymentMethod } = { ...userData[0] };
                return { dossierPayment, paymentMethod };
            }
            return false;
        },
    });
}
