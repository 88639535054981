// these function are for checking the data type and values we are getting from api.

import moment from "moment";

export const getNoOrYes = (key) => {
    if (key === "no" || key === "") return key;
    return "yes";
};

export const checkForNull = (key) => {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return null;

    return key;
};

export const checkForNullDateFormat = (key) => {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return null;

    return moment(key, "dd.MM.yy");
};

export const checkForEmptyDate = (key) => {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === ""
    )
        return null;

    return key;
};

export const checkForEmpty = (key, returnData) => {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined"
    )
        return returnData || "";

    return key;
};

export const checkForNoOrYes = (key) => {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined" ||
        key === "no"
    )
        return "";

    return key;
};

export const checkForEmptyFile = (key) => {
    if (
        key === null ||
        key === "null" ||
        key === undefined ||
        key === "undefined"
    )
        return null;

    return key;
};

export const checkIsYes = (isYes, value) => {
    if (isYes === "yes") return value;
    return "";
};

export const checkIsNo = (isNo, value) => {
    if (isNo === "no") return value;
    return "";
};

export const checkForSameValue = (isYes, sameValue, value) => {
    if (isYes === "yes") return sameValue;
    return value;
};

export const getSelectedOption = (key, optionArray) => {
    if (checkForEmpty(key) === "-") return "-";
    if (Array.isArray(optionArray) && optionArray.length <= 0) return key;
    let selectedOption = optionArray.find((options) => key === options.value);

    return selectedOption;
};
