import { useMutation, useQuery } from "react-query";
import { Axios } from "../../axios/axios";

// get all document
function getDossierDocument({ queryKey }) {
    const id = queryKey[1];
    return Axios.get(`/users/get_dossier_documents?userId=${id}`);
}

export function useGetDossierDocuments(user_id) {
    return useQuery(["get-dossier-documents", user_id], getDossierDocument, {
        enabled: Boolean(user_id),
    });
}

// upload document
function addDossierDocument(formData) {
    return Axios.post("/users/upload_dossier_documents", formData);
}

export function useAddDossierDocument() {
    return useMutation(addDossierDocument);
}

// update document

function updateDossierDocument(formData) {
    return Axios.post("/users/update_dossier_documents", formData);
}

export function useUpdateDossierDocument() {
    return useMutation(updateDossierDocument);
}

//delete document

function deleteDossierDocument(ids) {
    return Axios.post("/users/delete_dossier_documents", ids);
}

export function useDeleteDossierDocument() {
    return useMutation(deleteDossierDocument);
}
