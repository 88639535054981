import React from "react";

//* mui components imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import LinkIcon from "@mui/icons-material/Link";
import ArticleIcon from "@mui/icons-material/Article";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { checkForNull } from "../../../../utils/checkDataFunction";

const propertyDocUrl = process.env.REACT_APP_PROPERTY_IMAGE_URL;

function PropertyDescription({
    propertyDescription,
    title,
    propertyExternalLink,
    propertyId,
    documentFile,
}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const documentPath = `${propertyDocUrl}${propertyId}/${documentFile}`;

    return (
        <Stack spacing={3}>
            <Typography variant="h5">{title}</Typography>
            <Typography
                component={"p"}
                sx={{ color: "#717171", display: "flex" }}
            >
                <ArticleIcon sx={{ mr: 1 }} color="secondary" />
                {propertyDescription}
            </Typography>

            {checkForNull(documentFile) && (
                <>
                    <Typography variant="h6">
                        {t("attached_document")}
                    </Typography>
                    <Box display={"flex"}>
                        <AttachmentIcon sx={{ mr: 1 }} color="secondary" />
                        <Link
                            // component={"p"}
                            href={documentPath}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {documentFile}
                        </Link>
                    </Box>
                </>
            )}
            {propertyExternalLink && (
                <>
                    <Typography
                        variant="h6"
                        sx={{ color: theme.palette.primary.main }}
                    >
                        {t("pre_apply_form_dossier_additionals_of_object")}
                    </Typography>
                    <Typography
                        component={"p"}
                        sx={{
                            color: "#717171",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <LinkIcon sx={{ mr: 0.5 }} />
                        <a
                            href={propertyExternalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                                padding: "3px",
                                borderRadius: "4px",
                                color: "#717171",
                                fontSize: "0.95rem",
                                display: "inline-flex",
                                alignItems: "center",
                                "&:hover": { textDecoration: "none" },
                            }}
                        >
                            {propertyExternalLink}
                        </a>
                    </Typography>
                </>
            )}
        </Stack>
    );
}

export default PropertyDescription;
