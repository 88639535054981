import React from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerRadioField,
    ControllerTextField,
    ControllerDateField,
    ControllerSelectField,
    ControllerAutoCompleteField,
    TitleSpacer,
    FieldHintSpacer,
    FieldDottedLineSpacer,
    ControllerNextRadioField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import {
    civilStatusArr,
    permitOptionsArr,
    tenantGender,
    tenantTitle,
} from "../../../../../../options/dossierOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { isGreaterThanYear } from "../../../../../../utils/isGreaterThanYear";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";

const TenantOneSegmentTwo = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        getAllCountry,
        tenantTwoNationality,
        watchTenantTwoNationalityCountry,
        tenantTwoTitle,
        control,
        errors,
        sectionVariants,
        direction,
    } = additionalProps;

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="tenantTwoSalutation"
                        label={t("salutation") + "*"}
                        data={tenantGender}
                        errorFlag={!!errors?.tenantTwoSalutation}
                        errorText={t(errors?.tenantTwoSalutation?.message)}
                    />
                    {/*
                    <ControllerRadioField
                        name="tenantTwoSalutation"
                        label={t("salutation") + "*"}
                        control={control}
                        errorFlag={!!errors?.tenantTwoSalutation}
                        errorText={t(
                            errors?.tenantTwoSalutation?.message
                        )}
                    >
                        {tenantGender.map((gender) => (
                            <FormControlLabel
                                key={gender.id}
                                label={t(gender.label)}
                                value={gender.value}
                                control={<Radio/>}
                            />
                        ))}
                    </ControllerRadioField>
                    */}
                </Grid>
                <Grid item xs={12} md={6} position={"relative"}>
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="tenantTwoTitle"
                        label={t("title")}
                        data={tenantTitle}
                        errorFlag={!!errors?.tenantTwoTitle}
                        errorText={t(errors?.tenantTwoTitle?.message)}
                    />
                    {/*
                    <ControllerRadioField
                        name="tenantTwoTitle"
                        label={t("title")}
                        control={control}
                        errorFlag={!!errors?.tenantTwoTitle}
                        errorText={t(
                            errors?.tenantTwoTitle?.message
                        )}
                    >
                        {tenantTitle.map((gender) => (
                            <FormControlLabel
                                key={gender.id}
                                label={t(gender.label)}
                                value={gender.value}
                                control={<Radio/>}
                            />
                        ))}
                    </ControllerRadioField>
                    */}
                    {tenantTwoTitle === "other" && (
                        <ControllerTextField
                            control={control}
                            name="tenantTwoOtherTitle"
                            // title={t("dossier_first_name_info")}
                            label={t("title")}
                            errorFlag={!!errors?.tenantTwoOtherTitle}
                            errorText={t(errors?.tenantTwoOtherTitle?.message)}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerTextField
                        control={control}
                        name="tenantTwoFirstName"
                        label={t("first_name") + "*"}
                        errorFlag={!!errors?.tenantTwoFirstName}
                        errorText={t(errors?.tenantTwoFirstName?.message)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerTextField
                        control={control}
                        name="tenantTwoLastName"
                        label={t("last_name") + "*"}
                        errorFlag={!!errors?.tenantTwoLastName}
                        errorText={t(errors?.tenantTwoLastName?.message)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FieldDottedLineSpacer />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerDateField
                        control={control}
                        name="tenantTwoDateOfBirth"
                        openTo={"year"}
                        disableFuture
                        shouldDisableYear={(date) =>
                            isGreaterThanYear(date, 18)
                        }
                        label={t("date_of_birth") + "*"}
                        errorFlag={!!errors?.tenantTwoDateOfBirth}
                        errorText={t(errors?.tenantTwoDateOfBirth?.message)}
                        lang={currentLanguageCode}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller
                        name="tenantTwoCivilStatus"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                select
                                {...field}
                                variant="filled"
                                label={t("civil_status")}
                                fullWidth
                                error={!!errors?.tenantTwoCivilStatus}
                                helperText={t(
                                    errors?.tenantTwoCivilStatus?.message
                                )}
                            >
                                {civilStatusArr?.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerAutoCompleteField
                        label={t("nationality") + "*"}
                        name="tenantTwoNationality"
                        options={getAllCountry}
                        disableClearable
                        errorFlag={!!errors?.tenantTwoNationality}
                        errorText={t(errors?.tenantTwoNationality?.message)}
                    />
                </Grid>

                {watchTenantTwoNationalityCountry === null ||
                watchTenantTwoNationalityCountry?.toLowerCase() ===
                    "switzerland" ? null : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl variant="filled" fullWidth>
                            <Stack direction={"row"} spacing={2}>
                                <Controller
                                    name="tenantTwoResidencyPermit"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            {...field}
                                            variant="filled"
                                            label={t("permit") + "*"}
                                            sx={{
                                                width: "100%",
                                            }}
                                            error={
                                                !!errors?.tenantTwoResidencyPermit
                                            }
                                            helperText={t(
                                                errors?.tenantTwoResidencyPermit
                                                    ?.message
                                            )}
                                        >
                                            {permitOptionsArr.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {`${t(option.label)} (${t(
                                                        option?.shortDescription
                                                    )})`}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />

                                <ControllerDateField
                                    control={control}
                                    name="tenantTwoDateOfEntry"
                                    label={t("date_of_entry") + "*"}
                                    disableFuture
                                    errorFlag={!!errors?.tenantTwoDateOfEntry}
                                    errorText={t(
                                        errors?.tenantTwoDateOfEntry?.message
                                    )}
                                />
                            </Stack>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </DossierFormSection>
    );
};

export default TenantOneSegmentTwo;
