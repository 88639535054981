import { useTranslation } from "react-i18next";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

import { NEXTFLAT_TEAM } from "../../options/nextflatTeam";
import NextflatTeamBox from "../content-box/NextflatTeamBox";

const ContainerBox = styled(Container)(() => ({
    "&.MuiContainer-root": {
        padding: 0,
    },
}));

const MuiGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        ":nth-of-type(1)": {
            marginTop: 150,
        },
        ":nth-of-type(3)": {
            marginTop: 150,
        },
        ":nth-of-type(4)": {
            marginTop: 150,
        },
        ":nth-of-type(6)": {
            marginTop: 150,
        },
        ":nth-of-type(7)": {
            marginTop: 150,
        },
        ":nth-of-type(9)": {
            marginTop: 150,
        },
    },
}));

const NextflatTeam = () => {
    const { t } = useTranslation();
    return (
        <ContainerBox maxWidth="lg">
            <Stack spacing={3.75} mt={5}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4">
                            {t("the_nextflat_team")}
                        </Typography>
                        <Typography variant="body1">
                            {t("we_are_a_team_of_visionary_minds")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container rowGap={2}>
                    {NEXTFLAT_TEAM.map((team) => {
                        return (
                            <MuiGrid key={team.id} item xs={12} md={4}>
                                <NextflatTeamBox
                                    name={team.name}
                                    image={team.image}
                                    nameImage={team.name_image}
                                    position={team.position}
                                />
                            </MuiGrid>
                        );
                    })}
                </Grid>
            </Stack>
        </ContainerBox>
    );
};

export default NextflatTeam;
