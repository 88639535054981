import * as yup from "yup";

export const BasicContactDataSchema = yup.object().shape({
    salutation: yup.string().required("please_select_your_gender"),
    firstName: yup.string().required("please_enter_your_first_name").min(2).max(255),
    lastName: yup.string().required("please_enter_your_last_name").min(2).max(255),
    phoneNo: yup.string().required("please_enter_a_valid_phone_number").min(2).max(255),
    email: yup
        .string()
        .email("email_is_invalid")
        .required("please_enter_your_email")
        .min(2)
        .max(255),
});
