export const getSalaryRange = (monthlySalary = 0, salaryArray = []) => {
    let annualSalary = parseInt(monthlySalary) * 12;
    const salaryRange = salaryArray.find((salary) => {
        if (salary.upper_limit === null) {
            return annualSalary >= salary.lower_limit;
        } else {
            return (
                annualSalary >= salary.lower_limit &&
                annualSalary <= salary.upper_limit
            );
        }
    });

    return salaryRange;
};

export const getSalaryRangeLabel = (salary) => {
    return salary.upper_limit === null
        ? `${salary.lower_limit} CHF or above`
        : `${salary.lower_limit} - ${salary.upper_limit} CHF`;
};

export const getSalaryRangeFromId = (id, salaryArray = []) => {
    if (id) {
        const salaryRange = salaryArray.find((salary) => id === salary.id);
        if (salaryRange) return getSalaryRangeLabel(salaryRange);
    }
    return "";
};
