import { useEffect } from "react";
import { getOtherTitle, getTitle } from "../../../../../utils/getTenantTitles";
import {
    getSalaryRange,
    getSalaryRangeFromId,
    getSalaryRangeLabel,
} from "../../../../../utils/getSalaryRange";

//* util and data
import {
    checkForEmpty,
    checkForEmptyDate,
    checkForNoOrYes,
    checkForNull,
    checkForSameValue,
    checkIsNo,
    checkIsYes,
} from "../../../../../utils/checkDataFunction";

const useFormInitializationByEffect = (
    dossierStatus,
    dossierData,
    methods,
    setTenantOneFirstName,
    setTenantTwoFirstName,
    setTenantOneLastName,
    setTenantTwoLastName,
    salaryRangeData
) => {
    useEffect(() => {
        if (dossierStatus === "success") {
            let dossierDetails = dossierData?.data?.data[0];
            let dossierAccountDetails = dossierDetails?.dossier_account_details;
            let dossierPartnerDetails = dossierDetails?.dossier_partner;
            let userCreateDetails = dossierDetails?.user_create_details[0];

            const { first_name, last_name, email } = userCreateDetails;
            methods.reset((value) => ({
                ...value,
                firstName: first_name,
                lastName: last_name,
                email: email,
            }));
            //console.log(dossierPartnerDetails);
            if (first_name && first_name.length > 0) {
                setTenantOneFirstName(first_name || "tenant_one");
            }
            if (last_name && last_name.length > 0) {
                setTenantOneLastName(last_name || "");
            }

            if (dossierPartnerDetails && dossierPartnerDetails.length > 0) {
                setTenantTwoFirstName(
                    dossierPartnerDetails[0].first_name || "tenant_two"
                );
                setTenantTwoLastName(dossierPartnerDetails[0].last_name || "");
            }

            //main dossier
            const getSalaryRangeAndId = (id, currentSalary) => {
                if (!id) {
                    let salaryRange = getSalaryRange(
                        currentSalary,
                        salaryRangeData
                    );
                    if (salaryRange) {
                        return {
                            id: salaryRange.id,
                            currentSalary: getSalaryRangeLabel(salaryRange),
                        };
                    }
                    return {
                        id: null,
                        currentSalary: "",
                    };
                }
                return {
                    id,
                    currentSalary,
                };
            };

            if (
                Array.isArray(dossierAccountDetails) &&
                dossierAccountDetails.length > 0
            ) {
                const {
                    interested,
                    prospective_tenant_title,
                    user_date_of_birth,
                    natal_no,
                    telephone_private_business,
                    availability,
                    country,
                    nationality,
                    date_of_entry,
                    residence_permit,
                    street_no,
                    current_home_address,
                    residing_since,
                    civil_status,
                    is_employed,
                    how_you_pay,
                    reference_phone,
                    reference_email,
                    reference_name,
                    profession,
                    net_monthly_income,
                    salary_id,
                    employed_there_since,
                    name_and_address_of_company,
                    employment_relationship,
                    hasNoCurrentApartment,
                    movingOutOf,
                    otherMovingOut,
                    parentName,
                    parentEmail,
                    parentPhoneNo,
                    part_time_percent,
                    hasChildren,
                    no_of_adults,
                    children,
                    no_of_children_by_age,
                    noOfchildrenMovingin,
                    is_smoke,
                    smokePreference,
                    musical_instruments,
                    is_pet,
                    pet_name,
                    do_you_have_vehicles,
                    typeOfVehicle,
                    parkingSpace,
                    no_of_parking_space,
                    name_of_employee,
                    email_of_employee,
                    employee_phone_no,
                    phone_of_current_administration,
                    name_of_current_administration,
                    currentAdministrationEmail,
                    currentAdministrationContactName,
                    name_tags,
                    partner,
                    reason_change_residence,
                    residencyChangeComment,
                    date_of_moving,
                    hasApartmentCancelled,
                    apartmentCancelledBy,
                    previous_rent,
                    city,
                    zipcode,
                    isPlayingAtHome,
                    isAnotherTenantMovingIn,
                    // rent,
                    // floor,
                    // rooms,
                    // wish_city,
                    // wish_room,
                    // max_rent,
                    description,
                    smartVideo,
                    profile_pic,
                    banner_image,
                    passport_id_dateOfUpload,
                    salary_statement_dateOfUpload,
                    debt_collect_dateOfUpload,
                    criminal_record_dateOfUpload,
                    other_docs_dateOfUpload,
                    passport_id,
                    debt_collect,
                    salary_statement,
                    criminal_record,
                    passport_id_description,
                    salary_statement_description,
                    debt_collect_description,
                    criminal_record_description,
                    other_docs_description,
                    isNegativeTenantOneDebtEntry,
                    unemploymentStatus,
                    otherEmploymentStatus,
                    selfEmployedCompanyName,
                    selfEmployedCompanyAddress,
                    selfEmployedThereSince,
                    selfEmployedIncome,
                    liablePerson,
                    coveredRentAmount,
                    hasEmploymentTerminated,
                    companyName,
                    companyAddress,
                } = dossierAccountDetails[0];

                let childrenAge =
                    no_of_children_by_age === null ||
                    no_of_children_by_age === "null" ||
                    no_of_children_by_age === undefined ||
                    no_of_children_by_age === "undefined" ||
                    no_of_children_by_age === ""
                        ? []
                        : JSON.parse(no_of_children_by_age);

                methods.reset((values) => ({
                    ...values,
                    //first-section
                    salutation: checkForEmpty(interested),
                    title: getTitle(prospective_tenant_title),
                    otherTitle: getOtherTitle(prospective_tenant_title),
                    // firstName: checkForEmpty(first_name),
                    // lastName: checkForEmpty(family_name),
                    dateOfBirth: checkForEmptyDate(user_date_of_birth),
                    civilStatus: checkForEmpty(civil_status),
                    nationality: checkForNull(nationality),

                    //second-section
                    country: checkForNull(country),
                    residencyPermit: checkForEmpty(residence_permit),
                    dateOfEntry: checkForEmptyDate(date_of_entry),
                    // hometown: null,
                    streetAddress: checkForNull(current_home_address),
                    streetNo: checkForEmpty(street_no),
                    city: checkForNull(city),
                    zip: checkForEmpty(zipcode),
                    residingThereSince: checkForEmptyDate(residing_since),

                    //section-third
                    phoneNo: checkForEmpty(natal_no),
                    privatePhoneNo: checkForEmpty(telephone_private_business),
                    email: email,
                    //section-fourth
                    employedOrNot: checkForEmpty(is_employed),
                    //if-no
                    howYouPayRent: checkForEmpty(how_you_pay),
                    referenceName: checkForEmpty(reference_name),
                    referenceEmail: checkForEmpty(reference_email),
                    referencePhone: checkForEmpty(reference_phone),
                    //if-yes
                    employedThereSince: checkForEmptyDate(employed_there_since),
                    employmentRelationship: checkForEmpty(
                        employment_relationship
                    ),
                    workloadPercent: checkForEmpty(part_time_percent),
                    profession: checkForEmpty(profession),
                    monthlyIncome: checkForEmpty(net_monthly_income),
                    annualSalaryRange: getSalaryRangeAndId(
                        salary_id,
                        net_monthly_income
                    ).currentSalary,
                    annualSalaryRangeId: getSalaryRangeAndId(
                        salary_id,
                        net_monthly_income
                    ).id,
                    companyNameAndAddress: checkForNull(
                        name_and_address_of_company
                    ),
                    employeeName: checkForEmpty(name_of_employee),
                    employeeEmail: checkForEmpty(email_of_employee),
                    employeePhoneNo: checkForEmpty(employee_phone_no),
                    unemploymentStatus: checkForEmpty(unemploymentStatus),
                    otherEmploymentStatus: checkForEmpty(otherEmploymentStatus),
                    selfEmployedCompanyName: checkForEmpty(
                        selfEmployedCompanyName
                    ),
                    selfEmployedCompanyAddress: checkForEmpty(
                        selfEmployedCompanyAddress
                    ),
                    selfEmployedThereSince: checkForEmptyDate(
                        selfEmployedThereSince
                    ),
                    selfEmployedIncome: checkForEmpty(selfEmployedIncome),
                    liablePerson: checkForEmpty(liablePerson),
                    coveredRentAmount: checkForEmpty(coveredRentAmount),
                    hasEmploymentTerminated: checkForEmpty(
                        hasEmploymentTerminated
                    ),
                    companyName: checkForEmpty(companyName),
                    companyAddress: checkForEmpty(companyAddress),

                    //section-fifth
                    currentAdministrationName: checkForEmpty(
                        name_of_current_administration
                    ),
                    currentAdministrationContactName: checkForEmpty(
                        currentAdministrationContactName
                    ),
                    currentRent: checkForEmpty(previous_rent),
                    currentAdministrationEmail: checkForEmpty(
                        currentAdministrationEmail
                    ),
                    currentAdministrationPhoneNo: checkForEmpty(
                        phone_of_current_administration
                    ),
                    nameTags: checkForEmpty(name_tags),
                    isImmediateAndAfterAgreement: Boolean(
                        checkForEmpty(availability)
                    ),
                    residencyChangeReason: checkForEmpty(
                        reason_change_residence
                    ),
                    hasApartmentCancelled: checkForEmpty(hasApartmentCancelled),
                    apartmentCancelledBy: checkForEmpty(apartmentCancelledBy),
                    dateOfMoving: checkForEmptyDate(date_of_moving),
                    residencyChangeReasonComment: checkForEmpty(
                        residencyChangeComment
                    ),
                    hasNoCurrentApartment: Boolean(hasNoCurrentApartment),
                    movingOutOf: checkForEmpty(movingOutOf),
                    otherMovingOut: checkForEmpty(otherMovingOut),
                    parentName: checkForEmpty(parentName),
                    parentEmail: checkForEmpty(parentEmail),
                    parentPhoneNo: checkForEmpty(parentPhoneNo),

                    //section-sixth
                    noOfAdults: checkForEmpty(no_of_adults),
                    hasChildren: checkForEmpty(hasChildren),
                    //if yes
                    noOfChildren: checkForNoOrYes(children),
                    childrensAverageAge: childrenAge,
                    noOfChildrenMovingIn: checkForEmpty(noOfchildrenMovingin),
                    isSmoker: checkForEmpty(is_smoke),
                    // if yes
                    smokePreference: checkForEmpty(smokePreference),
                    hasMusicalInstrument: checkForEmpty(musical_instruments),
                    // if yes
                    isPlayingAtHome: checkForEmpty(isPlayingAtHome),
                    hasVehicle: checkForEmpty(do_you_have_vehicles),
                    // if yes
                    typeOfVehicle: checkForEmpty(typeOfVehicle),
                    isParkingNeeded: checkForEmpty(parkingSpace),
                    numberOfParkingSpace: checkForEmpty(no_of_parking_space),
                    hasPet: checkForEmpty(is_pet),
                    //if yes
                    pet: checkForEmpty(pet_name),
                    // petMaxHeight: "",
                    isAnotherTenantMovingIn: checkForEmpty(
                        isAnotherTenantMovingIn
                    ),
                    //if yes
                    anotherTenantRelation: checkForEmpty(partner),
                    description: checkForEmpty(description),
                    // wishedCity: checkForEmpty(wish_city),
                    // wishedRooms: checkForEmpty(wish_room),
                    // maxRent: checkForEmpty(max_rent),
                    video: checkForNull(smartVideo),
                    profilePic: checkForNull(profile_pic),
                    bannerImage: checkForNull(banner_image),
                    passport_id_date_of_upload: checkForNull(
                        passport_id_dateOfUpload
                    ),
                    salary_statement_date_of_upload: checkForNull(
                        salary_statement_dateOfUpload
                    ),
                    debt_collect_date_of_upload: checkForNull(
                        debt_collect_dateOfUpload
                    ),
                    criminal_record_date_of_upload: checkForNull(
                        criminal_record_dateOfUpload
                    ),
                    other_docs_date_of_upload: checkForNull(
                        other_docs_dateOfUpload
                    ),
                    passport_id: checkForNull(passport_id),
                    debt_collect: checkForNull(debt_collect),
                    salary_statement: checkForNull(salary_statement),
                    criminal_record: checkForNull(criminal_record),
                    passport_id_description: checkForNull(
                        passport_id_description
                    ),
                    salary_statement_description: checkForNull(
                        salary_statement_description
                    ),
                    debt_collect_description: checkForNull(
                        debt_collect_description
                    ),
                    criminal_record_description: checkForNull(
                        criminal_record_description
                    ),
                    other_docs_description: checkForNull(
                        other_docs_description
                    ),
                    isNegativeTenantOneDebtEntry: checkForEmpty(
                        isNegativeTenantOneDebtEntry
                    ),
                }));
            }

            // partner
            if (
                Array.isArray(dossierPartnerDetails) &&
                dossierPartnerDetails.length > 0
            ) {
                const {
                    id,
                    country,
                    tenantTwoTitle,
                    family_name,
                    first_name,
                    residing_since,
                    telephone_private_business,
                    natal_no,
                    tenantTwoNationality,
                    email,
                    residence_permit,
                    civil_status,
                    profession,
                    dob,
                    reason_change_residence,
                    employed_there_since,
                    employment_relationship,
                    current_home_address,
                    tenantTwoStreetNo,
                    previous_rent,
                    do_you_have_vehicles,
                    partner_gender,
                    partner_monthly_income,
                    ptr_salary_id,
                    partner_part_time_percent,
                    ptr_name_of_employe,
                    // ptr_employee_address,
                    ptr_employee_phone_no,
                    ptr_email_of_employee,
                    ptr_name_and_address_of_company,
                    ptr_phone_of_current_administration,
                    ptr_name_of_current_administration,
                    sameContactAsFirstTenant,
                    sameAdministrationAsFirstTenant,
                    tenantTwoCurrentAdministrationContactName,
                    tenantTwoNameTags,
                    tenantTwoCity,
                    tenantTwoZip,
                    tenantTwoDateOfMoving,
                    tenantTwoAvailabilty,
                    tenantTwoHasNoCurrentApartment,
                    tenantTwoMovingOutOf,
                    tenantTwoOtherMovingOut,
                    tenantTwoParentName,
                    tenantTwoParentEmail,
                    tenantTwoParentPhoneNo,
                    tenantTwoResidencyChangeComment,
                    sameAddressAsFirstTenant,
                    isTenantTwoSmoker,
                    tenantTwoSmokePreference,
                    tenantTwoHasMusicalInstrument,
                    tenantTwoIsPlayingAtHome,
                    hasTenantTwoPet,
                    tenantTwoPet,
                    ptr_date_of_entry,
                    ptr_is_employed,
                    ptr_reference_name,
                    ptr_reference_phone,
                    ptr_how_you_pay,
                    ptr_reference_email,
                    partnerTypeOfVehicle,
                    partnerParkingSpace,
                    tenantTwoNoParkingSpace,
                    tenantTwoProfilePic,
                    tenantTwoPassport_dateOfUpload,
                    tenantTwoSalary_dateOfUpload,
                    tenantTwoDebt_dateOfUpload,
                    tenantTwoCriminal_dateOfUpload,
                    tenantTwoCurrentAdministrationEmail,
                    tenantTwoOtherDocs_dateOfUpload,
                    tenantTwoPassport,
                    tenantTwoSalary,
                    tenantTwoDebt,
                    tenantTwoCriminal,
                    tenantTwoOtherDocs,
                    tenantTwoPassportDescription,
                    tenantTwoSalaryDescription,
                    tenantTwoDebtDescription,
                    tenantTwoCriminalDescription,
                    tenantTwoOtherDocsDescription,
                    isNegativeTenantTwoDebtEntry,
                    tenantTwoUnemploymentStatus,
                    tenantTwoOtherEmploymentStatus,
                    tenantTwoSelfEmployedCompanyName,
                    tenantTwoSelfEmployedCompanyAddress,
                    tenantTwoSelfEmployedThereSince,
                    tenantTwoSelfEmployedIncome,
                    tenantTwoLiablePerson,
                    tenantTwoCoveredRentAmount,
                    tenantTwoHasEmploymentTerminated,
                } = dossierPartnerDetails[0];

                methods.reset((values) => ({
                    ...values,
                    //1st-section
                    tenantTwoId: id,
                    tenantTwoSalutation: checkForEmpty(partner_gender),
                    tenantTwoTitle: getTitle(tenantTwoTitle),
                    tenantTwoOtherTitle: getOtherTitle(tenantTwoTitle),
                    tenantTwoFirstName: checkForEmpty(first_name),
                    tenantTwoLastName: checkForEmpty(family_name),
                    tenantTwoDateOfBirth: checkForEmptyDate(dob),
                    tenantTwoCivilStatus: checkForEmpty(civil_status),
                    tenantTwoNationality: checkForNull(tenantTwoNationality),

                    //2nd-section
                    sameAddressAsFirstTenant: checkForEmpty(
                        sameAddressAsFirstTenant
                    ),
                    // if No
                    tenantTwoCountry: checkForNull(country),
                    tenantTwoResidencyPermit: checkForEmpty(residence_permit),
                    tenantTwoDateOfEntry: checkForEmptyDate(ptr_date_of_entry),
                    tenantTwoCity: checkForNull(tenantTwoCity),
                    tenantTwoZip: checkForEmpty(tenantTwoZip),
                    tenantTwoStreetAddress: checkForEmpty(current_home_address),
                    tenantTwoStreetNo: checkForEmpty(tenantTwoStreetNo),
                    tenantTwoResidingThereSince:
                        checkForEmptyDate(residing_since),

                    //3rd section
                    sameContactAsFirstTenant: checkForEmpty(
                        sameContactAsFirstTenant
                    ),
                    // if no
                    tenantTwoPhoneNo: checkForEmpty(natal_no),
                    tenantTwoPrivatePhoneNo: checkForEmpty(
                        telephone_private_business
                    ),
                    tenantTwoEmail: checkForEmpty(email),

                    //4th section
                    tenantTwoEmployedOrNot: checkForEmpty(ptr_is_employed),
                    // if no
                    tenantTwoUnemploymentStatus: checkForEmpty(
                        tenantTwoUnemploymentStatus
                    ),
                    tenantTwoOtherEmploymentStatus: checkForEmpty(
                        tenantTwoOtherEmploymentStatus
                    ),
                    tenantTwoSelfEmployedCompanyName: checkForEmpty(
                        tenantTwoSelfEmployedCompanyName
                    ),
                    tenantTwoSelfEmployedCompanyAddress: checkForEmpty(
                        tenantTwoSelfEmployedCompanyAddress
                    ),
                    tenantTwoSelfEmployedThereSince: checkForEmpty(
                        tenantTwoSelfEmployedThereSince
                    ),
                    tenantTwoSelfEmployedIncome: checkForEmpty(
                        tenantTwoSelfEmployedIncome
                    ),
                    tenantTwoLiablePerson: checkForEmpty(tenantTwoLiablePerson),
                    tenantTwoCoveredRentAmount: checkForEmpty(
                        tenantTwoCoveredRentAmount
                    ),
                    tenantTwoHasEmploymentTerminated: checkForEmpty(
                        tenantTwoHasEmploymentTerminated
                    ),
                    tenantTwoHowYouPay: checkForEmpty(ptr_how_you_pay),
                    tenantTwoReferenceName: checkForEmpty(ptr_reference_name),
                    tenantTwoReferenceEmail: checkForEmpty(ptr_reference_email),
                    tenantTwoReferencePhone: checkForEmpty(ptr_reference_phone),

                    // if yes
                    tenantTwoEmployedThereSince:
                        checkForEmptyDate(employed_there_since),
                    tenantTwoEmploymentRelationship: checkForEmpty(
                        employment_relationship
                    ),
                    tenantTwoWorkloadPercent: checkForEmpty(
                        partner_part_time_percent
                    ),
                    tenantTwoProfession: checkForEmpty(profession),
                    tenantTwoMonthlyIncome: checkForEmpty(
                        partner_monthly_income
                    ),
                    tenantTwoAnnualSalaryRange: getSalaryRangeAndId(
                        ptr_salary_id,
                        partner_monthly_income
                    ).currentSalary,
                    tenantTwoAnnualSalaryRangeId: getSalaryRangeAndId(
                        ptr_salary_id,
                        partner_monthly_income
                    ).id,
                    tenantTwoCompanyNameAndAddress: checkForNull(
                        ptr_name_and_address_of_company
                    ),
                    tenantTwoEmployeeName: checkForEmpty(ptr_name_of_employe),
                    tenantTwoEmployeeEmail: checkForEmpty(
                        ptr_email_of_employee
                    ),
                    tenantTwoEmployeePhoneNo: checkForEmpty(
                        ptr_employee_phone_no
                    ),

                    //5th section
                    sameAdministrationAsFirstTenant: checkForEmpty(
                        sameAdministrationAsFirstTenant
                    ),
                    // if no
                    tenantTwoCurrentAdministrationName: checkForEmpty(
                        ptr_name_of_current_administration
                    ),
                    tenantTwoCurrentRent: checkForEmpty(previous_rent),
                    tenantTwoCurrentAdministrationEmail: checkForEmpty(
                        tenantTwoCurrentAdministrationEmail
                    ),
                    tenantTwoCurrentAdministrationContactName: checkForEmpty(
                        tenantTwoCurrentAdministrationContactName
                    ),
                    tenantTwoCurrentAdministrationPhoneNo: checkForEmpty(
                        ptr_phone_of_current_administration
                    ),
                    tenantTwoNameTags: checkForEmpty(tenantTwoNameTags),
                    tenantTwoResidencyChangeReason: checkForEmpty(
                        reason_change_residence
                    ),
                    tenantTwoResidencyChangeReasonComment: checkForEmpty(
                        tenantTwoResidencyChangeComment
                    ),
                    tenantTwoDateOfMoving: checkForNull(tenantTwoDateOfMoving),
                    tenantTwoIsImmediateAndAfterAgreement: Boolean(
                        checkForEmpty(tenantTwoAvailabilty)
                    ),
                    tenantTwoHasNoCurrentApartment: Boolean(
                        tenantTwoHasNoCurrentApartment
                    ),
                    tenantTwoMovingOutOf: checkForEmpty(tenantTwoMovingOutOf),
                    tenantTwoOtherMovingOut: checkForEmpty(
                        tenantTwoOtherMovingOut
                    ),
                    tenantTwoParentName: checkForEmpty(tenantTwoParentName),
                    tenantTwoParentEmail: checkForEmpty(tenantTwoParentEmail),
                    tenantTwoParentPhoneNo: checkForEmpty(
                        tenantTwoParentPhoneNo
                    ),
                    //6th section

                    isTenantTwoSmoker: checkForEmpty(isTenantTwoSmoker),
                    // if yes
                    tenantTwoSmokePreference: checkForEmpty(
                        tenantTwoSmokePreference
                    ),
                    tenantTwoHasMusicalInstrument: checkForEmpty(
                        tenantTwoHasMusicalInstrument
                    ),
                    // if yes
                    isTenantTwoPlayingAtHome: checkForEmpty(
                        tenantTwoIsPlayingAtHome
                    ),

                    hasTenantTwoVehicle: checkForEmpty(do_you_have_vehicles),
                    tenantTwoTypeOfVehicle: checkForEmpty(partnerTypeOfVehicle),
                    tenantTwoNumberOfParkingSpace: checkForEmpty(
                        tenantTwoNoParkingSpace
                    ),
                    isTenantTwoNeedParking: checkForEmpty(partnerParkingSpace),

                    hasTenantTwoPet: checkForEmpty(hasTenantTwoPet),
                    tenantTwoPet: checkForEmpty(tenantTwoPet),
                    tenantTwoProfilePic: checkForNull(tenantTwoProfilePic),

                    tenantTwoPassport: checkForNull(tenantTwoPassport),
                    tenantTwoSalary: checkForNull(tenantTwoSalary),
                    tenantTwoDebt: checkForNull(tenantTwoDebt),
                    tenantTwoCriminal: checkForNull(tenantTwoCriminal),
                    tenantTwoOtherDocs: checkForNull(tenantTwoOtherDocs),
                    tenantTwoPassportDateOfUpload: checkForNull(
                        tenantTwoPassport_dateOfUpload
                    ),
                    tenantTwoSalaryDateOfUpload: checkForNull(
                        tenantTwoSalary_dateOfUpload
                    ),
                    tenantTwoDebtDateOfUpload: checkForNull(
                        tenantTwoDebt_dateOfUpload
                    ),
                    tenantTwoCriminalDateOfUpload: checkForNull(
                        tenantTwoCriminal_dateOfUpload
                    ),
                    tenantTwoOtherDocsDateOfUpload: checkForNull(
                        tenantTwoOtherDocs_dateOfUpload
                    ),
                    tenantTwoPassportDescription: checkForNull(
                        tenantTwoPassportDescription
                    ),
                    tenantTwoSalaryDescription: checkForNull(
                        tenantTwoSalaryDescription
                    ),
                    tenantTwoDebtDescription: checkForNull(
                        tenantTwoDebtDescription
                    ),
                    tenantTwoCriminalDescription: checkForNull(
                        tenantTwoCriminalDescription
                    ),
                    tenantTwoOtherDocsDescription: checkForNull(
                        tenantTwoOtherDocsDescription
                    ),
                    isNegativeTenantTwoDebtEntry: checkForEmpty(
                        isNegativeTenantTwoDebtEntry
                    ),
                }));
            }
        }
    }, [
        dossierData?.data?.data,
        dossierStatus,
        methods,
        salaryRangeData,
        setTenantOneFirstName,
        setTenantOneLastName,
        setTenantTwoFirstName,
        setTenantTwoLastName,
    ]);
};

export default useFormInitializationByEffect;
