import PropTypes from "prop-types";
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

const MobileMenuButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.10)",
    zIndex: 1200,
    position: "fixed",
    top: 69,
    right: 16,
    [theme.breakpoints.up("sm")]: {
        right: 24,
    },
}));

function ViewMenuIconOnScroll({ iconButtonProps }) {
    const trigger = useScrollTrigger({
        threshold: 200,
        disableHysteresis: true,
    });

    return (
        <Fade in={trigger}>
            <MobileMenuButton size="large" {...iconButtonProps}>
                <Icon color="primary">menu</Icon>
            </MobileMenuButton>
        </Fade>
    );
}

ViewMenuIconOnScroll.propTypes = {
    iconButtonProps: PropTypes.object,
};

export default ViewMenuIconOnScroll;
