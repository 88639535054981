import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    ControllerPhoneTextField,
    TitleSpacer,
    FieldHintSpacer,
    ControllerSelectField,
    ControllerNumberField,
    ControllerRadioField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import { useContext } from "react";
import FormSectionContextSegmentN, {
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, FormGroup } from "@mui/material";
import {
    interestedPartnerArr,
    isYesNoOption,
    movingOutOption,
    numberArray,
    SmokeOptionArr,
    vehicles,
} from "../../../../../../options/dossierOptions";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { DossierReferenceBox } from "../../../dossier_components/DossierReferenceBox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";

const TenantTwoSegmentSix = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        watchTenantTwoSmoker,
        watchTenantTwoMusicalInstrument,
        watchTenantTwoVehicle,
        watchTenantTwoTypeOfVehicle,
        watchTenantTwoPets,
        watchIsTenantTwoNeedParking,
        control,
        errors,
        sectionVariants,
        direction,
    } = additionalProps;

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid item container spacing={4}>
                <Grid item container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="isTenantTwoSmoker"
                            label={t("do_you_smoke") + "*"}
                            data={isYesNoOption}
                        />

                        {/*
                        <ControllerRadioField
                            control={control}
                            name="isTenantTwoSmoker"
                            label={t("do_you_smoke")}
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio/>}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio/>}
                            />
                        </ControllerRadioField>
                        */}
                    </Grid>

                    {watchTenantTwoSmoker === "yes" && (
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="tenantTwoSmokePreference"
                                label={t("smoke_preference") + "*"}
                                data={SmokeOptionArr}
                                errorFlag={!!errors?.tenantTwoSmokePreference}
                                errorText={t(
                                    errors?.tenantTwoSmokePreference?.message
                                )}
                            />

                            {/*
                            <ControllerSelectField
                                control={control}
                                name="tenantTwoSmokePreference"
                                label={t("smoke_preference") + "*"}
                                errorFlag={
                                    !!errors?.tenantTwoSmokePreference
                                }
                                errorText={t(
                                    errors?.tenantTwoSmokePreference
                                        ?.message
                                )}
                            >
                                {SmokeOptionArr?.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </ControllerSelectField>
                            */}
                        </Grid>
                    )}
                </Grid>

                <Grid item container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="tenantTwoHasMusicalInstrument"
                            label={t("do_you_play_music_instruments") + "*"}
                            data={isYesNoOption}
                        />
                        {/*
                        <ControllerRadioField
                            control={control}
                            name="tenantTwoHasMusicalInstrument"
                            label={t(
                                "do_you_play_music_instruments"
                            )}
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio/>}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio/>}
                            />
                        </ControllerRadioField>
                          */}
                    </Grid>

                    {watchTenantTwoMusicalInstrument === "yes" && (
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="isTenantTwoPlayingAtHome"
                                label={t("do_you_play_at_home") + "*"}
                                data={isYesNoOption}
                                errorFlag={!!errors?.isTenantTwoPlayingAtHome}
                                errorText={t(
                                    errors?.isTenantTwoPlayingAtHome?.message
                                )}
                            />
                            {/*
                            <ControllerSelectField
                                control={control}
                                name="isTenantTwoPlayingAtHome"
                                label={
                                    t("do_you_play_at_home") + "*"
                                }
                                errorFlag={
                                    !!errors?.isTenantTwoPlayingAtHome
                                }
                                errorText={t(
                                    errors?.isTenantTwoPlayingAtHome
                                        ?.message
                                )}
                            >
                                {isYesNoOption?.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </ControllerSelectField>
                                  */}
                        </Grid>
                    )}
                </Grid>

                <Grid item container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="hasTenantTwoVehicle"
                            label={t("do_you_have_vehicles") + "*"}
                            data={isYesNoOption}
                        />
                        {/*
                        <ControllerRadioField
                            control={control}
                            name="hasTenantTwoVehicle"
                            label={t("do_you_have_vehicles")}
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio/>}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio/>}
                            />
                        </ControllerRadioField>
                         */}
                    </Grid>

                    {watchTenantTwoVehicle === "yes" && (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="tenantTwoTypeOfVehicle"
                                label={t("type_of_vehicle") + "*"}
                                data={vehicles}
                                errorFlag={!!errors?.tenantTwoTypeOfVehicle}
                                errorText={t(
                                    errors?.tenantTwoTypeOfVehicle?.message
                                )}
                            />
                            {/*
                            <ControllerSelectField
                                control={control}
                                name="tenantTwoTypeOfVehicle"
                                label={t("type_of_vehicle") + "*"}
                                errorFlag={
                                    !!errors?.tenantTwoTypeOfVehicle
                                }
                                errorText={t(
                                    errors?.tenantTwoTypeOfVehicle
                                        ?.message
                                )}
                            >
                                {vehicles.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </ControllerSelectField>
                             */}
                        </Grid>
                    )}
                    {watchTenantTwoTypeOfVehicle !== "" &&
                        watchTenantTwoVehicle === "yes" && (
                            <Grid
                                item
                                pl={2}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                xl={4}
                            >
                                <ControllerToggleButtonsComponent
                                    control={control}
                                    name="isTenantTwoNeedParking"
                                    label={t("need_parking_space") + "*"}
                                    data={isYesNoOption}
                                    errorFlag={!!errors?.isTenantTwoNeedParking}
                                    errorText={t(
                                        errors?.isTenantTwoNeedParking?.message
                                    )}
                                />
                                {/*
                                <ControllerRadioField
                                    control={control}
                                    name="isTenantTwoNeedParking"
                                    label={
                                        t("need_parking_space") +
                                        "*"
                                    }
                                    errorFlag={
                                        !!errors?.isTenantTwoNeedParking
                                    }
                                    errorText={t(
                                        errors
                                            ?.isTenantTwoNeedParking
                                            ?.message
                                    )}
                                >
                                    <FormControlLabel
                                        label={t("no")}
                                        value="no"
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        label={t("yes")}
                                        value="yes"
                                        control={<Radio/>}
                                    />
                                </ControllerRadioField>
                                   */}
                                {watchIsTenantTwoNeedParking === "yes" && (
                                    <ControllerTextField
                                        control={control}
                                        name="tenantTwoNumberOfParkingSpace"
                                        label={t("no_of_parking_space") + "*"}
                                        errorFlag={
                                            !!errors?.tenantTwoNumberOfParkingSpace
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoNumberOfParkingSpace
                                                ?.message
                                        )}
                                    />
                                )}
                            </Grid>
                        )}
                </Grid>

                <Grid item container>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="hasTenantTwoPet"
                            label={t("do_you_have_pets") + "*"}
                            data={isYesNoOption}
                        />
                        {/*

                        <ControllerRadioField
                            control={control}
                            name="hasTenantTwoPet"
                            label={t("do_you_have_pets")}
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio/>}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio/>}
                            />
                        </ControllerRadioField>
                         */}
                    </Grid>
                    {watchTenantTwoPets === "yes" && (
                        <Fragment>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerTextField
                                    name={"tenantTwoPet"}
                                    control={control}
                                    label={
                                        t("what_pets_(with_size_for_each)") +
                                        "*"
                                    }
                                    errorFlag={!!errors?.tenantTwoPet}
                                    errorText={
                                        <Fragment>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems={"center"}
                                            >
                                                <InfoOutlinedIcon
                                                    sx={{
                                                        color: "#FF9B3F",
                                                    }}
                                                />
                                                <Typography variant="subtitle2">
                                                    {t(
                                                        "please_enter_the_type_and_height_of_each_pet"
                                                    )}
                                                </Typography>
                                            </Stack>
                                            <br />
                                            {!!errors?.tenantTwoPet &&
                                                t(
                                                    errors?.tenantTwoPet
                                                        ?.message
                                                )}
                                        </Fragment>
                                    }
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </DossierFormSection>
    );
};

export default TenantTwoSegmentSix;
