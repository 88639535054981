import React from "react";
import { Controller } from "react-hook-form";
import {
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    Box,
    styled,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledToggleButton = styled(ToggleButton, {
    shouldForwardProp: (props) => props !== "minWidth",
})(({ theme, minWidth }) => ({
    minWidth: minWidth || "56px",
    "&:not(:first-of-type)": {
        marginLeft: theme.spacing(1),
    },
    "&.Mui-selected": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    "&:not(.Mui-selected)": {
        color: theme.palette.getContrastText(theme.palette.grey[300]),
        backgroundColor: theme.palette.grey[300],
        "&:hover": {
            backgroundColor: theme.palette.grey[400],
        },
    },
    borderRadius: "24px !important",
}));

const ControllerToggleButtonsComponent = ({
    control,
    name,
    label,
    data,
    errorFlag,
    errorText,
    minWidth,
}) => {
   
    const { t } = useTranslation();

    return (
        <Box sx={{ mt: 1, mb: 1 }}>
            <Typography variant="subtitle1" gutterBottom>
                {t(label)}
            </Typography>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <ToggleButtonGroup
                            value={field.value}
                            exclusive
                            onChange={(event, newValue) =>
                                field.onChange(newValue)
                            }
                            sx={{ display: "flex", gap: 0.4, flexWrap: "wrap" }}
                        >
                            {data.map((option) => (
                                <StyledToggleButton
                                    key={option.id}
                                    value={option.value}
                                    minWidth={minWidth}
                                >
                                    {t(option.label)}
                                </StyledToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    );
                }}
            />
            {errorFlag && (
                <Typography
                    color="error"
                    variant="h6"
                    display="block"
                    sx={{ mt: 2, fontWeight: "normal" }}
                >
                    {t(errorText)}
                </Typography>
            )}
        </Box>
    );
};

export default ControllerToggleButtonsComponent;
