//* mui imports
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import HeroLayout from "../../component/layouts/HeroLayout";
import { FeatureHeader } from "../../utils/imgImports";
import ApplyToSmartDossier from "../../component/section/ApplyToSmartDossier";
import OrganizeSmartDossier from "../../component/section/OrganizeSmartDossier";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ContainerBox = styled(Container)(() => ({
    "&.MuiContainer-root": {
        padding: 0,
    },
}));

const Features = () => {
    const navigate = useNavigate();
    const onToTheSmartDossier = () => navigate("/create-dossier");
    return (
        <Stack rowGap={10}>
            <HeroLayout
                layoutDirection={"reverse"}
                pageName={"features"}
                dropletImage={FeatureHeader}
                dropletImagePosition={"left"}
                dropletImageSize={740}
                heroHeading={"what_does_our_smart_dossier_offer"}
                heroSubHeading={"our_smart_dossier_revolutionizes"}
                heroButtonText={"to_the_smart_dossier"}
                heroButtonOnClick={onToTheSmartDossier}
                dropletImageAlt={"hero-image"}
            />

            <ContainerBox maxWidth="xl">
                <OrganizeSmartDossier />
            </ContainerBox>

            <ContainerBox maxWidth="xl">
                <ApplyToSmartDossier />
            </ContainerBox>
        </Stack>
    );
};

export default Features;
