import * as yup from "yup";
const twelveYearInMilliSeconds = 16 * 365 * 24 * 60 * 60 * 1000;
export const schema = [
    //* step one
    // section one
    yup.object({
    /*
        salutation: yup.string().required("please_select_your_gender"),
        firstName: yup
            .string()
            .required("please_enter_your_first_name")
            .min(2)
            .max(255),
        lastName: yup
            .string()
            .required("please_enter_your_last_name")
            .min(2)
            .max(255),
        dateOfBirth: yup
            .date("please_enter_a_valid_date")
            .nullable()
            .typeError("please_enter_a_valid_date")
            .required("please_enter_your_year_of_birth")
            .max(
                new Date(Date.now() - twelveYearInMilliSeconds),
                "your_age_should_be_more_than_19"
            ),

        nationality: yup.mixed().required("please_select_your_nationality"),
        residencyPermit: yup.string().when("nationality", {
            is: (nationality) => {
                let country =
                    nationality && typeof nationality === "string"
                        ? nationality
                        : nationality?.value;
                return country?.toLowerCase() !== "switzerland";
            },
            then: yup
                .string()
                .nullable()
                .required("please_select_residency_permit"),
        }),
        dateOfEntry: yup
            .mixed()
            .nullable()
            .when("nationality", {
                is: (nationality) => {
                    let countryName =
                        nationality && typeof nationality === "string"
                            ? nationality
                            : nationality?.value;
                    return countryName?.toLowerCase() !== "switzerland";
                },
                then: yup
                    .mixed()
                    .nullable()
                    .required("please_enter_date_of_entry"),
            }),
        */
        // { splitting this hometown field into 2 fields  }
        // hometown: yup
        //   .mixed()
        //   .nullable()
        //   .when("personalDetailCountry", {
        //     is: (personalDetailCountry) => {
        //       let country =
        //         personalDetailCountry && typeof personalDetailCountry === "string"
        //           ? personalDetailCountry
        //           : personalDetailCountry?.value;
        //       return country?.toLowerCase() === "switzerland";
        //     },
        //     then: yup
        //       .mixed()
        //       .nullable()
        //       .required("please_enter_your_city_and_zip_code"),
        //   }),

        // section two
        /*
        zip: yup.string().required("please_enter_your_zip_code"),

        city: yup.mixed().nullable().required("please_enter_your_city"),

        streetAddress: yup
            .string()
            .nullable()
            .required("please_enter_your_current_home_address"),
        streetNo: yup.string().required("please_enter_your_street_no"),

        country: yup.mixed().required("please_select_your_country"),
        phoneNo: yup.string().max(19, "please_enter_a_valid_phone_number"),
        privatePhoneNo: yup
            .string()
            .max(19, "please_enter_a_valid_phone_number"),
        email: yup
            .string()
            .email("email_is_invalid")
            .required("please_enter_your_email"),

        // section four
        employedOrNot: yup.string().required("please_select_any"),
        // if no
        howYouPayRent: yup.string().when("employedOrNot", {
            is: "no",
            then: yup.string().required("please_write_how_you_pay_your_rent"),
        }),
        referenceName: yup.string().when("employedOrNot", {
            is: "no",
            then: yup.string().required("please_enter_a_name_for_reference"),
        }),
        referenceEmail: yup.string().when("employedOrNot", {
            is: "no",
            then: yup
                .string()
                .email("email_is_invalid")
                .required("please_enter_a_email_for_reference"),
        }),
        referencePhone: yup.string().when("employedOrNot", {
            is: "no",
            then: yup
                .string()
                .required("please_enter_your_mobile_no")
                .max(19, "please_enter_a_valid_phone_number"),
        }),
        // if yes
        profession: yup.string().when("employedOrNot", {
            is: "yes",
            then: yup.string().required("please_enter_your_profession"),
        }),

        // monthlyIncome: yup.string().when("employedOrNot", {
        //     is: "yes",
        //     then: yup.string().required("please_select_a_monthly_income"),
        // }),
        annualSalaryRangeId: yup
            .string()
            .nullable()
            .when("employedOrNot", {
                is: "yes",
                then: yup.string().required("please_select_a_monthly_income"),
            }),
        // companyNameAndAddress: yup.mixed().when("employedOrNot", {
        //     is: "yes",
        //     then: yup
        //         .mixed()
        //         .required("please_enter_a_company_name_and_address"),
        // }),
        companyName: yup.mixed().when("employedOrNot", {
            is: "yes",
            then: yup
                .mixed()
                .required("please_enter_a_company_name_and_address"),
        }),
        companyAddress: yup.mixed().when("employedOrNot", {
            is: "yes",
            then: yup
                .mixed()
                .required("please_enter_a_company_name_and_address"),
        }),
        employeeName: yup.string().when("employedOrNot", {
            is: "yes",
            then: yup.string().required("please_enter_name_of_a_employee"),
        }),
        employeeEmail: yup.string().when("employedOrNot", {
            is: "yes",
            then: yup
                .string()
                .email("please_enter_a_valid_email")
                .required("please_enter_email_of_a_employee"),
        }),
        employeePhoneNo: yup.string().when("employedOrNot", {
            is: "yes",
            then: yup
                .string()
                .required("please_enter_your_mobile_no")
                .max(19, "please_enter_a_valid_phone_number"),
        }),

        // section fifth is optional
        // currentAdministrationName: yup
        //   .string()
        //   .required("please_enter_current_administration_name"),
        // currentRent: yup.string().required("please_enter_current_rent"),
        currentAdministrationEmail: yup.string().email("email_is_invalid"),
        // .required("please_enter_current_administration_email"),
        // currentAdministrationPhoneNo: yup
        //   .string()
        //   .required("please_enter_current_administration_phone_no"),
        // nameTags: yup.string().required("please_enter_name_tags"),
        // residencyChangeReason: yup
        //   .string()
        //   .required("please_enter_residency_change_reason"),
        dateOfMoving: yup
            .date("please_enter_a_valid_date")
            .nullable()
            .typeError("please_enter_a_valid_date")
            .when("isImmediateAndAfterAgreement", {
                is: "no",
                then: yup
                    .date("please_enter_a_valid_date")
                    .nullable()
                    .typeError("please_enter_a_valid_date")
                    .required("please_enter_a_valid_date"),
            }),

        // section sixth
        noOfChildren: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when("hasChildren", {
                is: "yes",
                then: yup
                    .number()
                    .transform((value) => (isNaN(value) ? undefined : value))
                    .nullable()
                    .required("please_enter_no_of_childrens")
                    .min(1, "please_enter_number_greater_than_0")
                    .max(8, "please_enter_number_smaller_than_8"),
            }),
        // noOfChildren: yup
        //   .number()
        //   .transform((value) => (isNaN(value) ? undefined : value)) // hacky way to fix NaN issue for number validation
        //   .nullable()
        //   .min(1, "please_enter_number_greater_than_0")
        //   .max(8, "please_enter_number_smaller_than_8")
        //   .when("hasChildren", {
        //     is: "yes",
        //     then: yup
        //       .number()
        //       .transform((value) => (isNaN(value) ? undefined : value))
        //       .required("please_enter_no_of_children")
        //       .min(1, "please_enter_number_greater_than_0")
        //       .max(8, "please_enter_number_smaller_than_8"),
        //   }),
        childrensAverageAge: yup.mixed().when("hasChildren", {
            is: "yes",
            then: yup.mixed().required("please_enter_average_age_of_children"),
        }),
        smokePreference: yup.string().when("isSmoker", {
            is: "yes",
            then: yup.string().required("please_choose_your_preference"),
        }),
        isPlayingAtHome: yup.string().when("hasMusicalInstrument", {
            is: "yes",
            then: yup.string().required("please_select_any"),
        }),
        typeOfVehicle: yup.string().when("hasVehicle", {
            is: "yes",
            then: yup
                .string()
                .required("please_the_confirm_the_type_of_vehicle_you_have"),
        }),
        isParkingNeeded: yup.string().when(["hasVehicle", "typeOfVehicle"], {
            is: (hasVehicle, typeOfVehicle) =>
                hasVehicle === "yes" && typeOfVehicle !== "",
            then: yup.string().required("please_confirm_the_need_of_parking"),
            otherwise: yup.string(),
        }),
        numberOfParkingSpace: yup
            .string()
            .when(["hasVehicle", "typeOfVehicle", "isParkingNeeded"], {
                is: (hasVehicle, typeOfVehicle, isParkingNeeded) =>
                    hasVehicle === "yes" &&
                    typeOfVehicle !== "" &&
                    isParkingNeeded === "yes",
                then: yup
                    .string()
                    .required("please_confirm_the_need_of_parking"),
                otherwise: yup.string(),
            }),
        pet: yup.string().when("hasPet", {
            is: "yes",
            then: yup.string().required("please_confirm_pet_you_have"),
        }),
        anotherTenantRelation: yup.string().when("isAnotherTenantMovingIn", {
            is: "yes",
            then: yup.string().required("please_select_your_relation"),
        }),
         */
    }),

    //* step two
    yup.object({
        /*
        tenantTwoSalutation: yup.string().required("please_select_your_gender"),
        tenantTwoFirstName: yup
            .string()
            .required("please_enter_your_first_name")
            .min(2, "name_should_be_more_than_2_characters")
            .max(200, "name_can_not_exceed_200_characters"),
        tenantTwoLastName: yup
            .string()
            .required("please_enter_your_last_name")
            .min(2, "name_should_be_more_than_2_characters")
            .max(200, "name_can_not_exceed_200_characters"),
        tenantTwoDateOfBirth: yup
            .date("please_enter_a_valid_date")
            .nullable()
            .typeError("please_enter_a_valid_date")
            .required("please_enter_your_year_of_birth")
            .max(
                new Date(Date.now() - twelveYearInMilliSeconds),
                "your_age_should_be_more_than_19"
            ),
        // section two
        // tenantTwoCountry: yup.mixed().when("sameAddressAsFirstTenant", {
        //   is: "no",
        //   then: yup.mixed().required("please_select_your_country"),
        // }),
        tenantTwoNationality: yup
            .mixed()
            .required("please_select_your_nationality"),
        tenantTwoResidencyPermit: yup
            .string()
            .when(["sameAddressAsFirstTenant", "tenantTwoNationality"], {
                is: (sameAddressAsFirstTenant, tenantTwoNationality) => {
                    if (sameAddressAsFirstTenant === "no") {
                        let country =
                            tenantTwoNationality &&
                            typeof tenantTwoNationality === "string"
                                ? tenantTwoNationality
                                : tenantTwoNationality?.value;
                        return country?.toLowerCase() !== "switzerland";
                    }
                },
                then: yup
                    .string()
                    .nullable()
                    .required("please_select_residency_permit"),
            }),
        tenantTwoDateOfEntry: yup
            .mixed()
            .nullable()
            .when(["sameAddressAsFirstTenant", "tenantTwoNationality"], {
                is: (sameAddressAsFirstTenant, tenantTwoNationality) => {
                    if (sameAddressAsFirstTenant === "no") {
                        let country =
                            tenantTwoNationality &&
                            typeof tenantTwoNationality === "string"
                                ? tenantTwoNationality
                                : tenantTwoNationality?.value;
                        return country?.toLowerCase() !== "switzerland";
                    }
                },
                then: yup
                    .mixed()
                    .nullable()
                    .required("please_enter_date_of_entry"),
            }),

        // { splitting this hometown field into 2 fields  }
        // hometown: yup
        //   .mixed()
        //   .nullable()
        //   .when("personalDetailCountry", {
        //     is: (personalDetailCountry) => {
        //       let country =
        //         personalDetailCountry && typeof personalDetailCountry === "string"
        //           ? personalDetailCountry
        //           : personalDetailCountry?.value;
        //       return country?.toLowerCase() === "switzerland";
        //     },
        //     then: yup
        //       .mixed()
        //       .nullable()
        //       .required("please_enter_your_city_and_zip_code"),
        //   }),


        tenantTwoCity: yup
            .mixed()
            .nullable()
            .when(["sameAddressAsFirstTenant", "tenantTwoCountry"], {
                is: (sameAddressAsFirstTenant, tenantTwoCountry) => {
                    if (sameAddressAsFirstTenant === "no") {
                        let country =
                            tenantTwoCountry &&
                            typeof tenantTwoCountry === "string"
                                ? tenantTwoCountry
                                : tenantTwoCountry?.value;
                        return country?.toLowerCase() === "switzerland";
                    }
                },
                then: yup
                    .mixed()
                    .nullable()
                    .required("please_enter_your_city_and_zip_code"),
            }),

        tenantTwoZip: yup
            .string()
            .when(["sameAddressAsFirstTenant", "tenantTwoCountry"], {
                is: (sameAddressAsFirstTenant, tenantTwoCountry) => {
                    if (sameAddressAsFirstTenant === "no") {
                        let country =
                            tenantTwoCountry &&
                            typeof tenantTwoCountry === "string"
                                ? tenantTwoCountry
                                : tenantTwoCountry?.value;
                        return country?.toLowerCase() === "switzerland";
                    }
                },
                then: yup
                    .string()
                    .nullable()
                    .required("please_enter_your_zip_code"),
            }),
        // tenantTwoStreetAddress: yup
        //   .string()
        //   .nullable()
        //   .when("sameAddressAsFirstTenant", {
        //     is: "no",
        //     then: yup
        //       .string()
        //       .nullable()
        //       .required("please_enter_your_current_home_address"),
        //   }),
        // tenantTwoStreetNo: yup.string().when("sameAddressAsFirstTenant", {
        //   is: "no",
        //   then: yup.string().nullable().required("please_enter_your_street_no"),
        // }),
        // tenantTwoResidingThereSince: yup
        //   .mixed()
        //   .nullable()
        //   .when(["sameAddressAsFirstTenant", "tenantTwoCountry"], {
        //     is: (sameAddressAsFirstTenant, tenantTwoCountry) => {
        //       if (sameAddressAsFirstTenant === "no") {
        //         let country =
        //           tenantTwoCountry && typeof tenantTwoCountry === "string"
        //             ? tenantTwoCountry
        //             : tenantTwoCountry?.value;
        //         return country?.toLowerCase() !== "switzerland";
        //       }
        //     },
        //     then: yup
        //       .mixed()
        //       .nullable()
        //       .required("please_enter_residing_there_since"),
        //   }),
        // .matches(/^\d+$/, "please_enter_a_street_number"),
        // section three
        // tenantTwoPhoneNo: yup.string().when("sameContactAsFirstTenant", {
        //   is: "no",
        //   then: yup
        //     .string()
        //     .required("please_enter_your_mobile_number")
        //     .max(12, "please_enter_a_valid_phone_number"),
        // }),
        // .min(8, "please_enter_a_valid_phone_number")
        // tenantTwoEmail: yup
        //   .string()
        //   .email("email_is_invalid")
        //   .when("sameContactAsFirstTenant", {
        //     is: "no",
        //     then: yup
        //       .string()
        //       .email("email_is_invalid")
        //       .required("please_enter_your_email"),
        //   }),

        // section four
        tenantTwoEmployedOrNot: yup.string().required("please_select_any"),
        // if no
        tenantTwoHowYouPay: yup.string().when("tenantTwoEmployedOrNot", {
            is: "no",
            then: yup.string().required("please_write_how_you_pay_your_rent"),
        }),
        tenantTwoReferenceName: yup.string().when("tenantTwoEmployedOrNot", {
            is: "no",
            then: yup.string().required("please_enter_a_name_for_reference"),
        }),
        tenantTwoReferenceEmail: yup.string().when("tenantTwoEmployedOrNot", {
            is: "no",
            then: yup
                .string()
                .email("email_is_invalid")
                .required("please_enter_a_email_for_reference"),
        }),
        tenantTwoReferencePhone: yup.string().when("tenantTwoEmployedOrNot", {
            is: "no",
            then: yup
                .string()
                .required("please_enter_your_mobile_no")
                .max(19, "please_enter_a_valid_phone_number"),
        }),
        // if yes
        tenantTwoProfession: yup.string().when("tenantTwoEmployedOrNot", {
            is: "yes",
            then: yup.string().required("please_enter_your_profession"),
        }),
        // tenantTwoMonthlyIncome: yup.string().when("tenantTwoEmployedOrNot", {
        //     is: "yes",
        //     then: yup.string().required("please_select_a_monthly_income"),
        // }),
        tenantTwoAnnualSalaryRangeId: yup.string().when("tenantTwoEmployedOrNot", {
            is: "yes",
            then: yup.string().required("please_select_a_monthly_income"),
        }),
        tenantTwoCompanyNameAndAddress: yup
            .mixed()
            .when("tenantTwoEmployedOrNot", {
                is: "yes",
                then: yup
                    .mixed()
                    .required("please_enter_a_company_name_and_address"),
            }),
        tenantTwoEmployeeName: yup.string().when("tenantTwoEmployedOrNot", {
            is: "yes",
            then: yup.string().required("please_enter_name_of_a_employee"),
        }),
        tenantTwoEmployeeEmail: yup.string().when("tenantTwoEmployedOrNot", {
            is: "yes",
            then: yup
                .string()
                .email("please_enter_a_valid_email")
                .required("please_enter_email_of_a_employee"),
        }),
        tenantTwoEmployeePhoneNo: yup.string().when("tenantTwoEmployedOrNot", {
            is: "yes",
            then: yup
                .string()
                .required("please_enter_your_mobile_no")
                .max(19, "please_enter_a_valid_phone_number"),
        }),

        // section fifth is optional
        // tenantTwoCurrentAdministrationName: yup
        //   .string()
        //   .when("sameAdministrationAsFirstTenant", {
        //     is: "no",
        //     then: yup.string().required("please_enter_current_administration_name"),
        //   }),
        // tenantTwoCurrentRent: yup.string().when("sameAdministrationAsFirstTenant", {
        //   is: "no",
        //   then: yup.string().required("please_enter_current_rent"),
        // }),
        // tenantTwoCurrentAdministrationEmail: yup
        //   .string()
        //   .when("sameAdministrationAsFirstTenant", {
        //     is: "no",
        //     then: yup
        //       .string()
        //       .required("please_enter_current_administration_email"),
        //   }),
        // tenantTwoCurrentAdministrationPhoneNo: yup
        //   .string()
        //   .when("sameAdministrationAsFirstTenant", {
        //     is: "no",
        //     then: yup
        //       .string()
        //       .required("please_enter_current_administration_phone_no"),
        //   }),
        // tenantTwoNameTags: yup.string().when("sameAdministrationAsFirstTenant", {
        //   is: "no",
        //   then: yup.string().required("please_enter_name_tags"),
        // }),
        // tenantTwoResidencyChangeReason: yup
        //   .string()
        //   .when("sameAdministrationAsFirstTenant", {
        //     is: "no",
        //     then: yup.string().required("please_enter_residency_change_reason"),
        //   }),
        // section sixth
        tenantTwoSmokePreference: yup.string().when("isTenantTwoSmoker", {
            is: "yes",
            then: yup.string().required("please_choose_your_preference"),
        }),
        isTenantTwoPlayingAtHome: yup
            .string()
            .when("tenantTwoHasMusicalInstrument", {
                is: "yes",
                then: yup.string().required("please_select_any"),
            }),
        tenantTwoTypeOfVehicle: yup.string().when("hasTenantTwoVehicle", {
            is: "yes",
            then: yup
                .string()
                .required("please_confirm_the_type_of_vehicle_you_have"),
        }),
        isTenantTwoNeedParking: yup
            .string()
            .when(["hasTenantTwoVehicle", "tenantTwoTypeOfVehicle"], {
                is: (hasTenantTwoVehicle, tenantTwoTypeOfVehicle) =>
                    hasTenantTwoVehicle === "yes" &&
                    tenantTwoTypeOfVehicle !== "",
                then: yup
                    .string()
                    .required("please_confirm_the_need_of_parking"),
                otherwise: yup.string(),
            }),
        tenantTwoNumberOfParkingSpace: yup
            .string()
            .when(
                [
                    "hasTenantTwoVehicle",
                    "tenantTwoTypeOfVehicle",
                    "isTenantTwoNeedParking",
                ],
                {
                    is: (
                        hasTenantTwoVehicle,
                        tenantTwoTypeOfVehicle,
                        isTenantTwoNeedParking
                    ) =>
                        hasTenantTwoVehicle === "yes" &&
                        tenantTwoTypeOfVehicle !== "" &&
                        isTenantTwoNeedParking === "yes",
                    then: yup
                        .string()
                        .required("please_confirm_the_need_of_parking"),
                    otherwise: yup.string(),
                }
            ),
        tenantTwoPet: yup.string().when("hasTenantTwoPet", {
            is: "yes",
            then: yup.string().required("please_confirm_pet_you_have"),
        }),
        */
    }),

    //* step three
    yup.object({}),

    //* step five
    yup.object({
        description: yup.string("please_write_something_about_yourself"),
    }),
];
