import React, {useEffect, useState} from "react";

//*  using toast for success and error
import {Zoom, toast} from "react-toastify";

//* translation
import {useTranslation} from "react-i18next";

//* react-hook-form
import {yupResolver} from "@hookform/resolvers/yup";
import {FormProvider, useForm} from "react-hook-form";

//* libraries
import {useNavigate} from "react-router-dom";

//* store imports
import {useStateValue} from "../store/StateProvider";

//* api calls

import useAddProfileDetail from "../hooks/profile/useAddProfileDetails";
import useGetAllProfileDetailsById from "../hooks/profile/useGetAllProfileDetailsById";

//* hooks
import useResponsive from "../hooks/responsive/useResponsive";

//* loading components
import Loading from "../component/loading/Loading";

//* animation
import {AnimatePresence, motion} from "framer-motion";

//* Schema
import {briefFormSchema as schema} from "../forms-schemas/dossier-schemas/BriefFormSchema";

//* components imports
import BriefAddressDetail
    from "../component/forms/smart_dossier_form/smart_dossier_brief_stepper_form/BriefAddressDetail";
import BriefPersonalDetail
    from "../component/forms/smart_dossier_form/smart_dossier_brief_stepper_form/BriefPersonalDetail";
import BriefContactDetail
    from "../component/forms/smart_dossier_form/smart_dossier_brief_stepper_form/BriefContactDetail";
import PageNotFound from "../pages/Error-pages/PageNotFound";

//* mui components import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {styled} from "@mui/material/styles";

//* mui icons imports
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

//* custom functions
import {
    checkForEmpty,
    checkForEmptyDate,
    checkForNull,
} from "../utils/checkDataFunction";
import {getOtherTitle, getTitle} from "../utils/getTenantTitles";
import useUserContext from "../hooks/user-context/useUserContext";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material";

const StepperStepColorIcon = styled("div")(({theme, ownerState}) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#68437E",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundColor: " #5BAFA9 ",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundColor: " #5BAFA9 ",
    }),
}));

const briefDossierSteps = ["log_in", "personal_details", "contact", "address"];

const DossierStepperLineConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#68437E",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#68437E",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}));

function DossierStepperIcon(props) {
    const {active, completed, className} = props;

    const icons = {
        1: <AccountCircleIcon/>,
        2: <PersonAddAltIcon/>,
        3: <SupervisedUserCircleIcon/>,
    };

    return (
        <StepperStepColorIcon
            ownerState={{completed, active}}
            className={className}
        >
            {!completed ? icons[String(props.icon)] : <CheckIcon/>}
        </StepperStepColorIcon>
    );
}

export const BriefDossierContextForm = () => {
    const {t} = useTranslation();
    const {isBelowSm} = useResponsive();
    const navigate = useNavigate();
    const theme = useTheme();

    const [step, setStep] = useState(1);

    const currentValidationSchema = schema[step];

    const {mutate} = useAddProfileDetail();

    const [{user_id}] = useStateValue();
    const {
        isLoading: isDossierLoading,
        data: dossierData,
        status: dossierStatus,
    } = useGetAllProfileDetailsById(user_id);

    let dossierDetail = dossierData?.data?.data[0];

    let dossierAccountDetails = dossierDetail?.dossier_account_details;
    let userCreateDetails = dossierDetail?.user_create_details[0];

    const {userContext, updateUserContext} = useUserContext();
    //console.log("Origin ContactSeller: " + userContext.origin + " / " + userContext.action + " / " + userContext.actionValue);
    const tmpOrigin = userContext?.origin ?? '';
    const tmpAction = userContext?.action ?? '';
    const tmpActionValue = userContext?.actionValue ?? '';
    const tmpActionOpenValue = userContext?.actionOpenValue ?? '';
    const tmpActionCloseValue = userContext?.actionCloseValue ?? '';

    //console.log("Origin BriefDossierContextForm: " + userContext.origin + " / " + userContext.action + " / " + userContext.actionValue + " / " + userContext.actionOpenValue + " / " + userContext.actionCloseValue);

    const methods = useForm({
        defaultValues: {
            salutation: "",
            title: "",
            otherTitle: "",
            firstName: "",
            lastName: "",
            phone_number: "",
            phone_no_private: "",
            dateOfBirth: "",
            streetAddress: "",
            streetNo: "",
            city: "",
            zip: "",
            country: null,
            noOfAdults: "",
            isSmoker: "",
            hasPet: "",
            availability: 0,
        },
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange",
    });

    useEffect(() => {
        if (dossierStatus === "success") {
            const {first_name, last_name, email} = userCreateDetails;

            methods.reset((value) => ({
                ...value,
                firstName: first_name,
                lastName: last_name,
                email: email,
            }));

            if (
                Array.isArray(dossierAccountDetails) &&
                dossierAccountDetails.length > 0
            ) {
                const {
                    interested,
                    prospective_tenant_title,
                    user_date_of_birth,
                    natal_no,
                    telephone_private_business,
                    current_home_address,
                    street_no,
                    city,
                    zipcode,
                    country,
                    no_of_adults,
                    is_smoke,
                    is_pet,
                } = dossierAccountDetails[0];

                methods.reset((value) => ({
                    ...value,
                    salutation: checkForEmpty(interested),
                    dateOfBirth: user_date_of_birth,
                    title: getTitle(prospective_tenant_title),
                    otherTitle: getOtherTitle(prospective_tenant_title),
                    phoneNo: checkForEmpty(natal_no),
                    privatePhoneNo: checkForEmpty(telephone_private_business),
                    streetAddress: current_home_address,
                    streetNo: checkForEmpty(street_no),
                    city: checkForNull(city),
                    zip: checkForEmpty(zipcode),
                    country: checkForNull(country),
                    noOfAdults: checkForEmpty(no_of_adults),
                    isSmoker: checkForEmpty(is_smoke),
                    hasPet: checkForEmpty(is_pet),
                }));
            }
        }
    }, [dossierStatus, methods, userCreateDetails, dossierAccountDetails]);

    const onSubmitData = async (data) => {
        const {
            salutation,
            title,
            otherTitle,
            firstName,
            lastName,
            dateOfBirth,
            phoneNo,
            privatePhoneNo,
            streetAddress,
            streetNo,
            city,
            zip,
            country,
            noOfAdults,
            isSmoker,
            hasPet,
        } = data;

        let dossierData = {
            lang: ["en", "de"],
            // who are you
            id: user_id,
            interested: salutation,
            prospective_tenant_title: title === "other" ? otherTitle : title,
            user_first_name: firstName,
            user_last_name: lastName,
            family_name: lastName,
            user_date_of_birth: dateOfBirth,
            civil_status: "",
            nationality: null,

            // How can you be reached by administrations
            natal_no: phoneNo,
            telephone_private_business: privatePhoneNo,

            // Where do you live
            country: country,
            residence_permit: "",
            date_of_entry: null,
            current_home_address: streetAddress,
            street_no: streetNo,
            city: city,
            zipcode: zip,
            residing_since: null,

            // The new Administration asks: How do you earn your money
            //employment
            is_employed: "",
            // if no
            how_you_pay: "",
            reference_name: "",
            reference_email: "",
            reference_phone: "",
            // if yes
            employed_there_since: "",
            employment_relationship: "",
            part_time_percent: "",
            profession: "",
            net_monthly_income: "",
            salary_id: null,
            name_and_address_of_company: "",
            name_of_employee: "",
            email_of_employee: "",
            employee_phone_no: "",

            // The new Administration asks: How did you live before
            name_of_current_administration: "",
            previous_rent: "",
            currentAdministrationEmail: "",
            phone_of_current_administration: "",
            name_tags: "",
            reason_change_residence: "",
            availability: 0,

            // What else is good to share with the new Administration
            no_of_adults: noOfAdults,
            hasChildren: "",
            // if yes
            children: "",
            no_of_children_by_age: [],
            is_smoke: isSmoker,
            // if yes
            smokePreference: "",
            musical_instruments: "",
            // if yes
            isPlayingAtHome: "",
            do_you_have_vehicles: "",
            // if yes
            typeOfVehicle: "",
            parkingSpace: "",
            is_pet: hasPet,
            // if yes
            pet_name: "",
            isAnotherTenantMovingIn: "",
            // if yes
            partner: "",
        };
        mutate(dossierData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    toast.error(`${t("something_went_wrong")}`, {
                        transition: Zoom,
                    });
                    return;
                }
            },
            onError: (errors) => {
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    const handleNext = async () => {
        window.scrollTo({top: 0, behavior: "smooth"});
        let allValue = methods.getValues();

        const isStepValid = await methods.trigger();

        if (isStepValid) {
            onSubmitData(allValue);
            setStep((prevState) => {
                return prevState + 1;
            });
            return;
        }
    };

    const handlePrev = () => {
        setStep((prevState) => {
            return prevState - 1;
        });
    };

    const handleSave = async () => {
        window.scrollTo({top: 0, behavior: "smooth"});
        let allValue = methods.getValues();

        const isStepValid = await methods.trigger();

        if (isStepValid) {
            onSubmitData(allValue);
            //navigate("/account/fs/smart-dossier");
            if (tmpOrigin === "propertyApplication") {
                const newContext = {
                    origin: tmpOrigin,
                    action: tmpAction,
                    actionValue: tmpActionValue,
                    actionOpenValue: tmpActionOpenValue,
                    actionCloseValue: 'done'
                };
                updateUserContext(newContext);

                console.log("Origin BriefDossierContextForm saved: " + userContext.origin + " / " + userContext.action + " / " + userContext.actionValue + " / " + userContext.actionOpenValue + " / " + userContext.actionCloseValue);

                navigate("/property/" + userContext.actionValue + "#contact-seller-section");
            } else {
                navigate("/account/fs/smart-dossier");
            }
            return;
        }
    };

    const getForm = () => {
        switch (step) {
            case 1:
                return <BriefPersonalDetail/>;
            case 2:
                return <BriefContactDetail/>;
            case 3:
                return <BriefAddressDetail/>;
            default:
                return <PageNotFound/>;
        }
    };

    const getMobileNextButtons = () => {
        if (step === briefDossierSteps.length - 1) {
            return (
                <Button variant="contained" onClick={handleSave}>
                    {t("save_&_proceed")}
                </Button>
            );
        }
        return (
            <Button variant="contained" onClick={handleNext}>
                {t("next")}
            </Button>
        );
    };

    const getMobileBackButtons = () => {
        if (step === 1)
            return (
                <Button variant="text" disabled>
                    {t("back")}
                </Button>
            );

        return (
            <Button variant="text" onClick={handlePrev}>
                {t("back")}
            </Button>
        );
    };

    //desktop-buttons

    const FormsButtons = () => {
        if (step === 1) {
            return (
                <Stack direction={"row"} justifyContent={"flex-end"}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleNext}
                    >
                        {t("next")}
                    </Button>
                </Stack>
            );
        }
        if (step === briefDossierSteps.length - 1) {
            return (
                <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                    <Button variant="text" onClick={handlePrev}>
                        {t("back")}
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        {t("save_&_proceed")}
                    </Button>
                    {/* <Button
                        variant="contained"
                        disabled={stripeIsLoading || paymentIsLoading}
                        onClick={() => handleLastStepNext(paymentMethod)}
                    >
                        {stripeIsLoading || paymentIsLoading
                            ? `${t("next")}...`
                            : t("next")}
                    </Button> */}
                </Stack>
            );
        }

        return (
            <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                <Button variant="text" onClick={handlePrev}>
                    {t("back")}
                </Button>
                <Button variant="contained" onClick={handleNext}>
                    {t("next")}
                </Button>
            </Stack>
        );
    };

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"});
    }, []);

    return (
        <>
            <Container maxWidth={isBelowSm ? "xl" : "lg"}>
                {!isBelowSm && (
                    <Stepper
                        sx={{my: 4}}
                        alternativeLabel
                        activeStep={step}
                        connector={<DossierStepperLineConnector/>}
                    >
                        {briefDossierSteps.map((label) => (
                            <Step key={label}>
                                <StepLabel
                                    StepIconComponent={DossierStepperIcon}
                                >
                                    {t(label)}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}

                <Paper
                    elevation={5}
                    sx={{
                        borderRadius: 2,
                        my: {xs: 8, sm: 4},
                        p: {xs: 1, sm: 4},
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {user_id !== null && isDossierLoading ? (
                                <Loading/>
                            ) : (
                                <FormProvider {...methods}>
                                    <form>
                                        <Box px={{xs: 2, md: 10}}>
                                            <AnimatePresence>
                                                <motion.div
                                                    // layout
                                                    style={{
                                                        padding: "10px 0",
                                                    }}
                                                    animate={{opacity: 1}}
                                                    initial={{opacity: 0}}
                                                    exit={{opacity: 0}}
                                                    transition={{
                                                        opacity: {
                                                            ease: "linear",
                                                        },
                                                        layout: {
                                                            duration: 0.3,
                                                        },
                                                    }}
                                                >
                                                    {
                                                        userContext && userContext.origin === "propertyApplication" ? (
                                                            <Typography variant="h5" sx={{
                                                                mb: 2,
                                                                textAlign: "center",
                                                                color: theme.palette.primary.main
                                                            }}>
                                                                {t("brief_dossier_form_title")}
                                                            </Typography>
                                                        ) : null}
                                                    {getForm()}
                                                </motion.div>
                                            </AnimatePresence>
                                            {!isBelowSm && <FormsButtons/>}
                                        </Box>
                                    </form>
                                </FormProvider>
                            )}
                        </Grid>
                    </Grid>
                    {isBelowSm && (
                        <MobileStepper
                            variant="text"
                            steps={briefDossierSteps.length}
                            position="static"
                            activeStep={step}
                            nextButton={getMobileNextButtons(step)}
                            backButton={getMobileBackButtons()}
                        />
                    )}
                </Paper>
            </Container>
        </>
    );
};
