import React from "react";
import { Controller } from "react-hook-form";
import Slider from "@mui/material/Slider";

function ControllerSlider({ control, name, step, min, max, ...props }) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                return (
                    <Slider
                        valueLabelDisplay="auto"
                        step={step}
                        min={min}
                        max={max}
                        {...field}
                        {...props}
                    />
                );
            }}
        />
    );
}

export default ControllerSlider;
