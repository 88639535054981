import { Box, Container, Grid, Typography } from "@mui/material";

const TermsAndConditions = () => {
  const h2Style = {
    paddingBottom: "15px",
  };

  const hightext = {
    fontWeight: "500",
    paddingRight: "5px",
  };
  return (
    <Container maxWidth="md" sx={{ paddingTop: "80px" }}>
      <Grid container spacing={6}>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            AGB’s
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            1. Geltungsbereich
          </Typography>
          <Typography variant="body1">
            Diese allgemeinen Geschäftsbedingungen («AGB») regeln die
            rechtlichen Beziehungen zwischen der Nextflat AG und den Nutzern der
            Immobilienplattform nextflat.ch («Plattform nextflat»). Die AGB
            gelten für jede Nutzung der Plattform nextflat und für sämtliche
            darauf angebotenen Leistungen, vorbehältlich einer separaten Abrede.
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            2. Definitionen
          </Typography>
          <Typography variant="body1" sx={{ paddingBottom: "10px" }}>
            Nachfolgend werden die folgenden Begriffe wie folgt verwendet:
          </Typography>
          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              a) Nutzer:
            </Typography>
            Sind natürliche oder juristische Personen, welche die auf der
            Plattform nextflat angebotenen Dienstleistungen beziehen wollen und
            ein Benutzerkonto auf der Plattform nextflat eröffnet haben.
          </Typography>
          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              b) Dienstleister:
            </Typography>
            Sind Unternehmen, welche Dienstleistungen anbieten, die im weitesten
            Sinne einen Bezug zur Immobiliensuche und Wohnungswechsel aufweisen,
            wie z.B.: Zügelunternehmen, Anbieter von Mieterkautionslösungen,
            Versicherungsunternehmen, Banken usw. Die Dienstleister können
            Werbeinserate auf der Plattform nextflat schalten und sind nach
            vorgängig erfolgter Zustimmung berechtigt, die Nutzer zu
            kontaktieren.
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            3. Akzeptanz AGB
          </Typography>

          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              3.1.
            </Typography>
            Die Nutzer akzeptieren die AGB mit der Eröffnung des Benutzerkontos.
            Die AGB sind in der jeweils gültigen Fassung auf der Plattform
            nextflat aufgeschaltet und können heruntergeladen werden.
          </Typography>
          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              3.2.
            </Typography>
            Nextflat ist berechtigt, diese AGB jederzeit zu ändern und gibt die
            Änderungen gegenüber den Nutzern in jeweils geeigneter Form bekannt.
            Ist der Nutzer mit den Änderungen nicht einverstanden, hat er das
            Vertragsverhältnis mit Nextflat innerhalb von zwei Wochen ab
            Bekanntgabe der Änderungen zu beenden und sein Nutzerkonto zu
            löschen. Andernfalls gelten die Änderungen als akzeptiert.
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            4. Registrierung Benutzerkonto
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  4.1.
                </Typography>
                Die Registrierung eines Benutzerkontos ist kostenlos und setzt
                eine gültige E-Mail Adresse sowie die Generierung eines vom
                Nutzer gewählten Kennworts voraus. Zusätzlich müssen vom Nutzer
                wahrheitsgetreu sein Vor- und Nachname sowie seine Adresse und
                gegebenenfalls weitere erforderliche Identifizierungsmerkmale
                (wie z.B. Geburtsdatum) bekanntgegeben werden.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  4.2.
                </Typography>
                Für die durch Übermittlungsfehler, Fehlleistungen, technische
                Mängel und Störungen, Betriebsausfälle oder rechtswidrige
                Eingriffe in EDV-Systeme des Nutzers verursachten Schäden,
                übernimmt Nextflat AG keine Haftung. Der Nutzer ist für sein
                Benutzerkonto, den Schutz seines Benutzernamens und des
                Kennwortes alleine verantwortlich. Die durch die missbräuchliche
                Verwendung des Benutzerkontos oder Fehlmanipulationen
                verursachten Schäden werden dem Nutzer zugerechnet.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  4.3.
                </Typography>
                Nextflat AG ist berechtigt, ohne vorherige Ankündigung und ohne
                Angabe von Gründen, das Benutzerkonto zu sperren, wenn die
                Zugangsberechtigung durch falsche Angaben im Anmeldeformular
                erwirkt worden ist, der Nutzer sein Benutzerkonto missbräuchlich
                verwendet, der Nutzer mit der Bezahlung der für die Nutzung der
                kostenpflichtigen Dienste erforderlichen Entgelte in Verzug ist
                oder ein anderer wichtiger Grund besteht, der vom Nutzer zu
                vertreten ist (vgl. Ziff. 10.1).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  4.4.
                </Typography>
                Der Nutzer kann grundsätzlich jederzeit die Löschung seines
                Benutzerkontos vornehmen (vgl. Ziff. 10.2). Nextflat AG wird in
                diesem Fall alle Benutzerdaten und alle sonstigen über den
                Kunden gespeicherten personenbezogenen Daten löschen, sobald
                diese entsprechend den gesetzlichen Vorschriften nicht mehr
                aufbewahrt werden müssen. Sofern der Nutzer das Smartdossier
                «Premium-Account Sponsoring » gewählt hatte, hat der Nutzer der
                Weitergabe seiner Kontaktdaten und der Kontaktaufnahme durch den
                Dienstleister zugestimmt. Der Nutzer nimmt zur Kenntnis und
                erklärt sich damit einverstanden, dass diese Kontaktaufnahme
                auch erst nach der Löschung des Benutzerkontos erfolgen kann.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            5. Verhältnis zwischen Nextflat AG und Nutzer
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.1.
                </Typography>
                Nextflat AG bietet gegenüber dem Nutzer die Möglichkeit, ein
                sog. Smartdossier (elektronisches Bewerbungsdossier) zu
                erstellen und in seinem Benutzerkonto zu speichern. Das
                Smartdossier wird in drei verschiedenen Ausführungsvarianten mit
                unterschiedlichem Leistungsumfang und teilweise kostenpflichtig
                angeboten.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.2.
                </Typography>
                Das Smartdossier «Free-Account» ist kostenlos und setzt einzig
                die Registrierung eines Benutzerkontos voraus. Es beinhaltet
                folgende Dienstleistungen:
                <Box
                  component={"ul"}
                  sx={{
                    padding: "30px 0 40px 30px",
                    color: "#68437E",
                    listStyleType: "none",
                  }}
                >
                  <Box component={"li"}>
                    <Typography variant="body2">
                      a) Vorlagen zur Ausarbeitung eines Bewerbungsdossiers;
                      Bearbeitungs-, Speicher- und Archivierungsfunktion;
                    </Typography>
                  </Box>
                  <Box component={"li"}>
                    <Typography variant="body2">
                      b) Möglichkeit Bilder, Videos und PDF-Unterlagen (z.B.
                      Lohnausweis, Betreibungsregisterauszug, Kontaktliste für
                      Referenzauskünfte) heraufzuladen und Nextflat AG zwecks
                      Dienstleistungserbringung zur Verfügung zu stellen;
                    </Typography>
                  </Box>
                  <Box component={"li"}>
                    <Typography variant="body2">
                      c) Möglichkeit, das erstellte Bewerbungsdossier online
                      anzusehen. Demgegenüber ist es nicht möglich, das Dossier
                      online zu verschicken oder auszudrucken.
                    </Typography>
                  </Box>
                  <Box component={"li"}>
                    <Typography variant="body2">
                      d) Das Profil des Nutzers kann pseudonymisiert und ohne
                      dass Rückschlüsse auf einzelne Personen möglich sind,
                      potenziellen Vermietern bekanntgegeben werden. Das Profil
                      enthält Angaben zum aktuellen Wohnort, Alter und Beruf des
                      Nutzers sowie Angaben zur Immobiliensuche (zukünftiger
                      Wohnort, Anzahl Zimmer und Personen, usw.). Bei Interesse
                      des Immobilienanbieters, wird die Zustimmung des Nutzers
                      eingeholt, damit zwecks Kontaktaufnahme sein Name,
                      Vorname, die Mail-Adresse und Telefonnummer weitergegeben
                      werden dürfen .
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.3.
                </Typography>
                Das Smartdossier «Premium-Account» ist kostenpflichtig und
                enthält zusätzlich zu den Dienstleistungen gemäss «Free-Account»
                die folgenden Dienstleistungen:
                <Box
                  component={"ul"}
                  sx={{
                    padding: "30px 0 40px 30px",
                    color: "#68437E",
                    listStyleType: "none",
                  }}
                >
                  <Box component={"li"}>
                    <Typography variant="body2">
                      a) Bewerbungshilfe durch Nextflat AG;
                    </Typography>
                  </Box>
                  <Box component={"li"}>
                    <Typography variant="body2">
                      b) Downloadfunktion des Betreibungsregisterauszugs;
                    </Typography>
                  </Box>
                  <Box component={"li"}>
                    <Typography variant="body2">
                      c) Möglichkeit, das Dossier zu speichern, auszudrucken und
                      online zu versenden;
                    </Typography>
                  </Box>
                  <Box component={"li"}>
                    <Typography variant="body2">
                      d) Verifizierung der Bewerbungsunterlagen durch Nextflat
                      AG, in dem der Betreibungsregisterauszug überprüft sowie
                      (gegebenenfalls auch automatisiert) die Arbeitgeber und
                      früheren sowie aktuellen Vermieter zwecks Verifizierung
                      der Angaben des Nutzers kontaktiert werden .
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.4.
                </Typography>
                Der Nutzer kann anstelle des kostenpflichtigen Smartdossier
                «Premium-Account» die unentgeltliche Variante «Sponsoring»
                wählen. Bei dieser Variante kann der Nutzer aus einem
                vordefinierten Angebot Dienstleister auswählen, welche ihn
                kontaktieren dürfen, sofern der Nutzer vorgängig der
                individuellen Kontaktaufnahme zustimmt. Zur individuellen
                Zusammenstellung eines passenden Branchenangebots, kann Nextflat
                AG die Angaben aus dem Smartdossier des Nutzers berücksichtigen.
                Je nach Dienstleister besteht die Möglichkeit, dass auf
                pseudonymisierter Basis, welche aus Sicht des Dienstleisters
                keine Rückschlüsse auf den einzelnen Nutzer erlaubt, erste
                Informationen an die Dienstleister übermittelt werden (z.B.
                Grösse der aktuellen Immobilie, aktueller Wohnort, Anzahl
                Personen im Haushalt, Einkommensklasse), wodurch die
                Dienstleister eine erste unverbindliche Offerte erstellen
                können. Sofern der Nutzer interessiert ist, kann er die
                Dienstleister zur Kontaktaufnahme ermächtigen und ihnen seine
                genauen Kontaktdaten mitteilen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.5.
                </Typography>
                Mit der Speicherung seines Smartdossiers sichert der Nutzer
                folgendes zu:
                <Box
                  component={"ul"}
                  sx={{
                    padding: "20px 0 0px 30px",
                    color: "#68437E",
                    listStyleType: "none",
                  }}
                >
                  <Box component={"li"}>
                    <Typography variant="body2">
                      a) Die Angaben im Smartdossier sind vollständig, richtig,
                      aktuell, nicht irreführend oder unlauter;
                    </Typography>
                  </Box>
                  <Box component={"li"}>
                    <Typography variant="body2">
                      b) Die Angaben im Smartdossier verletzen keine
                      Persönlichkeitsrechte und/oder Bildrechte bzw. generell
                      Immaterialgüterrechte Dritter.
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.6.
                </Typography>
                Der Nutzer erteilt seine Zustimmung, im Zusammenhang mit dem Smartdossier «Premium-Account» seine
                Angaben verifizieren und seinen Arbeitgeber sowie seinen
                aktuellen Vermieter (und/oder frühere Vermieter) zur Überprüfung
                des Wahrheitsgehalts der Angaben kontaktieren darf (vgl. Ziff.
                11.4). Das Smartdossier «Premium-Account» ist für eine Periode
                von drei Monaten gültig. Im Anschluss muss der Nutzer erneut die
                kostenpflichtige Aktualisierung des Smartdossiers
                «Premium-Account» beantragen, ansonsten dieses in ein
                Smartdossier «Free-Account» umgewandelt wird. Während der
                Gültigkeitsdauer von drei Monaten ist der Nutzer berechtigt,
                seine Angaben drei Mal zu aktualisieren bzw. zu ändern.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.7.
                </Typography>
                Der Nutzer nimmt zur Kenntnis, dass Nextflat AG für einzelne
                Dienstleistungen (z.B. Erstellung Bewerbungsschreiben) auch auf
                Programme der künstlichen Intelligenz umstellen kann und dass
                solche Programme inhärente Risiken aufweisen, für welche
                Nextflat AG keine Haftung übernimmt .
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.8.
                </Typography>
                Der Nutzer nimmt zur Kenntnis, dass er für die Sicherung seiner
                Daten , insbesondere derjenigen Angaben, welche er auf der
                Plattform nextflat in seinem Smartdossier gespeichert hat,
                selbst verantwortlich ist.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.9.
                </Typography>
                Der Nutzer ist verpflichtet, auf eigene Kosten sicherzustellen,
                dass bei ihm die technischen Voraussetzungen für die Nutzung der
                Plattform nextflat vorhanden sind und er die jeweils aktuellste
                Version des Webbrowsers verwendet. Der Nutzer nimmt zur
                Kenntnis, dass bei Benutzung älterer oder nicht allgemein
                gebräuchlicher Technologien die Möglichkeit besteht, dass die
                Plattform nextflat nicht oder nur eingeschränkt genutzt werden
                kann. Ausserdem liegt es in der Verantwortung des Nutzers die
                zur Sicherung seiner eigenen Systeme notwendigen Vorkehrungen
                auf eigene Kosten zu treffen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  5.10.
                </Typography>
                Nextflat AG ist berechtigt, bei Inaktivität des Nutzers dessen
                Smartdossier und das Benutzerkonto zu löschen. Eine Inaktivität
                wird angenommen, wenn während 5 Jahren kein Log-In mehr erfolgt
                ist.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            6. Zahlungsbedingungen
          </Typography>

          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              6.1.
            </Typography>
            Die Bezahlung des kostenpflichtigen Dienstes erfolgt über die
            üblichen Zahlungsmethoden (Debit- und Kreditkarten, Twint,
            Postfinance, Applepay, etc.). Bevor der Dienst genutzt werden kann,
            muss die Gebühr bezahlt worden sein.
          </Typography>
          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              6.2.
            </Typography>
            Bezahlt der Nutzer den Rechnungsbetrag nicht innerhalb der
            angesetzten Zahlungsfrist, so gerät er automatisch in Verzug.
            Nextflat AG ist berechtigt, die Erfüllung ihrer Leistungen zu
            verweigern und das Benutzerkonto zu sperren sowie das
            Vertragsverhältnis mit dem Nutzer frist- und entschädigungslos zu
            kündigen (vgl. Ziff. 10.1.).
          </Typography>
          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              6.3.
            </Typography>
            Nextflat AG ist berechtigt, ein Inkassounternehmen beizuziehen und
            diesem – im Zusammenhang mit der Geltendmachung der Forderung – die
            erforderlichen Daten und Informationen zum säumigen Nutzer zu
            übermitteln.
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            7. Haftung
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  7.1.
                </Typography>
                Die Nutzer sind vollumfänglich für die von ihnen auf der
                Plattform nextflat und im Rahmen des Smartdossiers publizierten
                Inhalte verantwortlich und haften gegenüber Nextflat AG für
                allfälligen daraus resultierenden Schaden.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  7.2.
                </Typography>
                Die Haftung von Nextflat AG für sämtlichen Schaden im
                Zusammenhang mit der Nutzung der Plattform nextflat wird
                vollumfänglich ausgeschlossen. Der Haftungsausschluss gilt
                insbesondere für Schaden, der wegen unbefugten Zugriffs auf ein
                Benutzerkonto, durch widerrechtliches oder sittenwidriges
                Verhalten von Nutzern oder Dritten, namentlich betrügerischer
                Bewerbungen, oder durch Datenverlust, -entwendung bzw. -löschung
                (bspw. von im Smartdossier gespeicherten Angaben) entsteht.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  7.3.
                </Typography>
                Für Schäden, die den Nutzern aus der Nutzung der
                Dienstleistungen von Nextflat AG entstehen, haftet diese nur,
                sofern ihr ein vorsätzliches oder grobfahrlässiges Verhalten
                vorgeworfen werden kann. Die Haftung für Folgeschäden und
                entgangener Gewinn ist ausgeschlossen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  7.4.
                </Typography>
                Nextflat AG erteilt keinerlei Zusicherungen, Garantien oder
                Gewährleistungen im Zusammenhang mit der Plattform und deren
                Nutzung, insbesondere nicht für die Verfügbarkeit,
                Servicequalität, Richtigkeit, Vollständigkeit und Aktualität der
                Plattform nextflat oder der darin enthaltenen oder verfügbaren
                Informationen, namentlich auch hinsichtlich der mit Hilfe der
                Plattform nextflat erstellten Smartdossiers.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  7.5.
                </Typography>
                Die Nutzer nehmen zur Kenntnis, dass das Internet ein offenes
                und für jedermann einsehbares Netz ist. Nextflat AG haftet
                nicht, für allfällige Schäden (inklusive Folgeschäden), welche
                durch Virenprogramme, Softwarefehler oder durch den Missbrauch
                durch Dritte (Hacker) verursacht werden.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  7.6.
                </Typography>
                Kundenzahlungen erfolgen in einem geschützten Bereich, in dem
                die Daten verschlüsselt übermittelt werden. Dennoch kann
                Nextflat AG die einwandfreie Funktionsfähigkeit nicht
                gewährleisten und haftet nicht für die Sicherheit der
                Übermittlung der Daten bzw. aus einem allfälligen
                Datenmissbrauch entstandenen Schaden.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            8. Dienstleistungen / Angebote Dritter
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  8.1.
                </Typography>
                Dritt-Unternehmen können auf der Webseite der Nextflat AG
                Werbung schalten und Dienstleistungen (Bestellung von
                Betreibungsregisterauszügen, Versicherungs- und
                Bankdienstleistungen, Umzugs- und Lagerdienstleistungen, usw.)
                anbieten. Ein Vertragsverhältnis über solche kostenpflichtigen
                Zusatzdienstleistungen kommt ausschliesslich zwischen dem Nutzer
                und dem Drittanbieter (Dienstleister) zustande. Nextflat AG ist
                an diesem Vertragsverhältnis nicht beteiligt, berät den Nutzer
                nicht im Hinblick auf die für ihn am besten geeignete
                Dienstleistung, nimmt keine Interessenswahrung für den Nutzer
                wahr und übernimmt keine Haftung und Gewährleistung für derartig
                beanspruchte Dienstleistungen. Nextflat AG erhält unter
                Umständen eine Vergütung, falls der Nutzer einen Vertrag mit dem
                Dienstleister abschliesst.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  8.2.
                </Typography>
                Soweit Drittunternehmen ihre Webseiten mit derjenigen der
                Nextflat AG verknüpfen, übernimmt Nextflat AG keine Haftung und
                Gewährleistung für den Inhalt, die Richtigkeit sowie die
                Funktionsfähigkeit der Webseite dieses Dritten.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            9. Änderung der Dienstleistung / Einstellung der Plattform nextflat
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  9.1.
                </Typography>
                Nextflat AG ist berechtigt, Änderungen und Anpassungen an der
                Plattform nextflat vorzunehmen, insbesondere aufgrund
                technischer Weiterentwicklungen sowie geänderter
                Nutzerbedürfnisse. Nextflat AG ist darüber hinaus berechtigt,
                den Leistungsumfang abzuändern, zu erweitern, einzuschränken
                oder die Plattform ganz oder teilweise einzustellen. Der Nutzer
                hat keinen Anspruch auf eine Weiterführung oder
                Aufrechterhaltung des Leistungsumfanges, der Funktionalitäten,
                der Ausgestaltung oder der Inhalte der Plattform nextflat.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  9.2.
                </Typography>
                Gegenüber Nutzern, welche ein kostenpflichtiges Angebot von
                Nextflat AG beziehen, wird darauf geachtet, dass die Einstellung
                der Dienstleistungen mit einer Frist von drei (3) Monaten
                angekündigt wird.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            10. Vertragsdauer
          </Typography>

          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              10.1.
            </Typography>
            Nextflat AG ist berechtigt, das Vertragsverhältnis mit dem Nutzer
            jederzeit fristlos aus wichtigen Gründen per Mail zu kündigen und
            das Benutzerkonto zu löschen. Als wichtige Gründe gelten
            insbesondere:
            <Box
              component={"ul"}
              sx={{
                padding: "30px 0 40px 30px",
                color: "#68437E",
                listStyleType: "none",
              }}
            >
              <Box component={"li"}>
                <Typography variant="body2">
                  a) Die Missachtung der Vorgaben aus diesen AGB;
                </Typography>
              </Box>
              <Box component={"li"}>
                <Typography variant="body2">
                  b) ein rechtswidriges oder unsittliches Verhalten der Nutzer
                  im Zusammenhang mit dem Smartdossier, sowie
                </Typography>
              </Box>
              <Box component={"li"}>
                <Typography variant="body2">c) Zahlungsverzug.</Typography>
              </Box>
            </Box>
          </Typography>
          <Typography variant="body1">
            <Typography component="span" sx={hightext}>
              10.2.
            </Typography>
            Der Nutzer ist jederzeit berechtigt, sein Benutzerkonto zu löschen.
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            11. Datenschutz
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  11.1.
                </Typography>
                Nextflat AG hält die Bestimmungen des Schweizer
                Datenschutzgesetzes ein und nimmt die Datenbearbeitung so vor,
                wie dies von Nextflat AG in der Datenschutzerklärung, welche auf
                der Webseite abrufbar ist, dargelegt worden ist.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  11.2.
                </Typography>
                Nextflat AG verpflichtet ihre Dienstleister, die Bestimmungen
                des Schweizer Datenschutzgesetzes ebenfalls zu beachten . Sofern
                diese Dienstleister Personendaten in einem Land ohne
                angemessenen Datenschutz bearbeiten, stellt Nextflat AG durch
                den Abschluss von Standardvertragsklauseln sicher, dass ein mit
                der Schweiz vergleichbares Datenschutzniveau eingehalten wird.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  11.3.
                </Typography>
                Die im Rahmen der Kundenregistrierung und der Vertragsabwicklung
                von Nextflat AG erfassten persönlichen Kundendaten werden unter
                anderem zu Zwecken des Marketings, Marktforschung, zur
                Verbesserung der Dienstleistungen von Nextflat AG sowie zur
                Produktentwicklung bearbeitet. Eine vollständige Übersicht der
                Bearbeitungszwecke findet sich in der Datenschutzerklärung. Der
                Kunde erteilt seine Zustimmung , dass ihm personalisierte
                Werbung (per Mail, Telefon oder postalisch) zugestellt werden
                kann. Er kann seine Zustimmung jederzeit unter [Mailadresse]
                kostenlos widerrufen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  11.4.
                </Typography>
                Soweit der Kunde das Angebot Smartdossier «Premium-Account»
                nutzt, nimmt er zur Kenntnis, dass Nextflat AG, die zur Prüfung
                und Verifizierung seines Bewerbungsdossiers erforderliche
                Datenbearbeitung vornehmen kann, das heisst insbesondere, mit
                Vollmacht des Nutzers einen Betreibungsregisterauszug über ihn
                einholen, welcher Auskunft über allfällige laufende Betreibungen
                und Verlustscheine gibt. Ebenfalls ist Nextflat AG berechtigt,
                den Arbeitgeber zu kontaktieren, welcher Informationen über das
                Arbeitsverhältnis und persönliche Eigenschaften des Nutzers
                geben kann, und/oder aktuelle sowie frühere Vermieter zu
                kontaktieren, welche über das bestehende Mietverhältnis Auskunft
                geben können. Die Kontaktaufnahme mit dem Arbeitgeber und dem
                aktuellen sowie früheren Vermieter kann unter Umständen auch
                durch eine automatisch generierte Mail erfolgen.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            12. Copyright
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  12.1.
                </Typography>
                Sämtliche Rechte an der Plattform nextflat sowie an den darin
                enthaltenen oder verfügbaren Informationen, insbesondere Rechte
                an der Software, Ausstattungen, Marken, Firmen und an allen
                Materialien, die in Zusammenhang mit der Plattform stehen,
                stehen ausschliesslich Nextflat AG zu.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  12.2.
                </Typography>
                Nextflat AG gewährt dem Nutzer mit dessen Registrierung ein
                unentgeltliches, nicht ausschliessliches, auf die Dauer des
                Vertragsverhältnisses zwischen dem Nutzer und Nextflat AG
                befristetes, nicht auf Dritte übertragbares und nicht
                unterlizenzierbares Recht zur bestimmungsgemässen Nutzung der
                Plattform nextflat und der darin enthaltenen oder verfügbaren
                Informationen. Der Nutzer verpflichtet sich, die Plattform
                nextflat nur diesem Nutzungsrecht entsprechend zu nutzen.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h4" sx={h2Style}>
            13. Schlussbestimmungen
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  13.1.
                </Typography>
                Sollte eine Bestimmung dieser AGB und / oder des Vertrages
                unwirksam sein oder werden, so werden die übrigen Bestimmungen
                dieser AGB davon nicht berührt. Nextflat AG und der Nutzer
                verpflichten sich hiermit, eine Regelung zu treffen, die dem
                Sinn und Zweck der unwirksamen Norm so weit als möglich
                entspricht. Änderungen, Ergänzungen und Nebenabreden der AGB
                bedürfen der Schriftform.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Typography component="span" sx={hightext}>
                  13.2.
                </Typography>
                Das Rechtsverhältnis zwischen dem Nutzer und Nextflat AG
                untersteht dem schweizerischen Recht. Der ausschliessliche
                Gerichtsstand ist Zürich, wobei sich Nextflat AG ausdrücklich
                vorbehält ihre Ansprüche bei jedem anderen zuständigen Gericht
                geltend zu machen.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
    </Container>
  );
};

export default TermsAndConditions;
