import PropTypes from "prop-types";
import React, {Fragment} from "react";
import LanguageSelector from "../language-selector/LanguageSelector";
import Button from "@mui/material/Button";
import {styled} from "@mui/material";
import ArrowButton from "../elements/button/ArrowButton";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useStateValue} from "../../store/StateProvider";
import {useAuthModal} from "../../context/ModalContext";
import LoggedInMenu from "../logged-in-menu/LoggedInMenu.jsx";
import HIDE_NAVIGATION_LINKS from "../../options/hideNavigationLinks.js";
import HIDE_NAVIGATION_CREATE_DOSSIER_LINKS from "../../options/hideNavigationCreateDossierButton.js";
import Icon from "@mui/material/Icon";
import HIDE_NAVIGATION_LINKS_EDIT_FORM from "../../options/hideNavigationLinksEditForm";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined.js";

const LoginButton = styled(Button)(() => ({
    padding: 0,
}));

function NavigationButtons({handleDrawerClose}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [{token, user_email, user_role}] = useStateValue();
    const {openAuthModal, handleUserRole} = useAuthModal();

    //console.log(useStateValue());

    const handleOnClick = () => {
        openAuthModal();
        //* role for registration from header
        handleUserRole("TN");
        handleDrawerClose && handleDrawerClose();
    };

    const onCreateDossier = () => {
        navigate("/create-dossier");
        handleDrawerClose && handleDrawerClose();
    };

    const onAddProperty = () => {
        navigate("/create-object");
    };

    const onOpenProfile = () => {
        if (user_role === "PM") {
            navigate("/account/pm/dashboard");
        } else {
            navigate("/account/fs/smart-dossier");
        }
        handleDrawerClose && handleDrawerClose();
    };

    if (HIDE_NAVIGATION_LINKS.includes(pathname))
        return <LanguageSelector buttonSxProps={{padding: 0}}/>;

    return (
        <Fragment>

            <Fragment>
                <ArrowButton variant="contained" onClick={onAddProperty}>
                    {t("property_new_tenant_list_of_flats_short")}
                </ArrowButton>
            </Fragment>

            {/* changed by Andre
         <ArrowButton variant='contained' onClick={onCreateDossier}>
            {pathname.includes("/dossier-form/any-property")
               ? t("set_my_dream_property")
               : t("create_smart_dossier")}
         </ArrowButton>

         // new code version
         */}
            {!HIDE_NAVIGATION_CREATE_DOSSIER_LINKS.includes(pathname) &&
            token === null &&
            user_email === null ? (
                <ArrowButton variant="contained" onClick={onCreateDossier}>
                    {t("create_smart_dossier")}
                </ArrowButton>
            ) : null}

            {!HIDE_NAVIGATION_CREATE_DOSSIER_LINKS.includes(pathname) &&
            token !== null &&
            user_email !== null ? (
                <ArrowButton
                    variant="contained"
                    onClick={onOpenProfile}
                    startIcon={<PersonOutlinedIcon/>}
                >
                    {t("my_profile")}
                </ArrowButton>
            ) : null}

            {!HIDE_NAVIGATION_LINKS_EDIT_FORM.includes(pathname) && (
                <Fragment>
                    {token === null && user_email === null && (
                        <LoginButton
                            variant="text"
                            color="secondary"
                            onClick={handleOnClick}
                            disableRipple
                        >
                            {t("login")}
                        </LoginButton>
                    )}
                </Fragment>
            )}

            {HIDE_NAVIGATION_CREATE_DOSSIER_LINKS.includes(pathname) &&
            token !== null &&
            user_email !== null ? (
                <LoggedInMenu/>
            ) : null}

            <LanguageSelector buttonSxProps={{padding: 0}}/>
        </Fragment>
    );
}

NavigationButtons.propTypes = {
    handleDrawerClose: PropTypes.func,
};

export default NavigationButtons;
