import React from "react";

//*  Translation
import { useTranslation } from "react-i18next";

//* libraries
import { useNavigate } from "react-router-dom";

//* mui components imports
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DossierCreationSvg from "../../component/svg/DossierCreationSvg";

function ReferenceSuccessful() {
   const { t } = useTranslation();
   const navigate = useNavigate();
   return (
      <Container maxWidth='lg' sx={{ p: 5 }}>
         <Grid container>
            <Grid
               item
               xs={12}
               md={6}
               display={"flex"}
               justifyContent={"center"}
            >
               <Stack spacing={3} justifyContent={"center"}>
                  <Typography variant='h1'>
                     {t("reference_success_title")}
                  </Typography>

                  <Typography variant='p'>
                     {t("reference_success_text")}
                  </Typography>
                  <Stack direction='row'>
                     <Button
                        variant='contained'
                        size='medium'
                        onClick={() => navigate("/partnership")}
                     >
                        {t("reference_success_button")}
                     </Button>
                  </Stack>
               </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
               <DossierCreationSvg />
            </Grid>
         </Grid>
      </Container>
   );
}

export default ReferenceSuccessful;
