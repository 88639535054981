//* mui components imports
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons
import CheckIcon from "@mui/icons-material/Check";

//* custom svg icons
import { ArrowIcon } from "../custom-icons/ArrowIcon";

//* Animation
import { motion } from "framer-motion";
import {
    smartDossierBenefit,
    smartDossierBenefitBox,
} from "../../utils/animation/Animation";
import { Fragment } from "react";
import { useTheme } from "@mui/material";

export const CreateBenefitsStepsHeading = ({ heading, benefits, t }) => {
    const theme = useTheme();
    return (
        <>
            <Typography
                variant={"h4"}
                textTransform={"capitalize"}
                color="secondary"
            >
                {heading}
            </Typography>
            <Stack
                gap={3}
                mt={2}
                component={motion.div}
                variants={smartDossierBenefitBox}
                initial="hidden"
                animate="visible"
            >
                {benefits?.map((benefit) => (
                    <Stack
                        key={benefit.id}
                        direction={"row"}
                        border={`1px dashed ${theme.palette.secondary.main}`}
                        p={2}
                        borderRadius={2}
                        sx={{ placeItems: "center" }}
                        gap={2}
                        component={motion.div}
                        variants={smartDossierBenefit}
                    >
                        <CheckIcon
                            sx={{
                                color: theme.palette.secondary.main,
                            }}
                        />
                        <Typography component={"p"} variant={"h5"}>
                            {t(benefit.title)}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
        </>
    );
};

export const CreateBenefitsSteps = ({
    heading,
    isSmallHowItWorks,
    iconDataMap,

    t,
}) => {
    return (
        <Stack gap={2} my={3}>
            <Typography variant="h4">{heading}</Typography>
            <Box p={2} sx={{ border: "1px dashed #5BAFA9", borderRadius: 5 }}>
                <Stack
                    direction={isSmallHowItWorks ? "column" : "row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    {iconDataMap.map((item) => (
                        <Fragment key={item.id}>
                            <Box
                                display="flex"
                                flexDirection={
                                    isSmallHowItWorks ? "row" : "column"
                                }
                                gap={isSmallHowItWorks && "40px"}
                                alignItems={"center"}
                                margin={isSmallHowItWorks && "15px"}
                            >
                                <Avatar
                                    sx={{
                                        width: 90,
                                        height: 90,
                                        bgcolor: "#fff",
                                        boxShadow:
                                            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                                    }}
                                >
                                    {item.icon}
                                </Avatar>
                                <Typography
                                    variant="subtitle1"
                                    textAlign={"center"}
                                >
                                    {t(item.title)}
                                </Typography>
                            </Box>
                            {item.isLast || (
                                <ArrowIcon
                                    isSmallScreenMatched={isSmallHowItWorks}
                                />
                            )}
                        </Fragment>
                    ))}
                </Stack>
            </Box>
        </Stack>
    );
};
