import React from "react";

//* mui components import
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

//* mui icons imports
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import StairsIcon from "@mui/icons-material/Stairs";
import { useTheme } from "@mui/material";
import CustomFormDivider from "../forms/common/CustomFormDivider";
import { ScoreProgress } from "../score-progress/ScoreProgress";

export default function GoodMatchChanceCardSkeleton() {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                p: 1.25,
                borderRadius: 2,
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
                position: "relative",
            }}
            elevation={0}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3} sx={{ position: "relative" }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="250px"
                        sx={{ borderRadius: 4 }}
                    />

                    <Skeleton
                        variant="rounded"
                        sx={{
                            height: 32,
                            width: 64,
                            backgroundColor: theme.palette.primary.light,
                            borderRadius: 6,
                            position: "absolute",
                            top: 24,
                            left: 24,
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        sx={{
                            height: 32,
                            width: 64,
                            backgroundColor: theme.palette.primary.light,
                            borderRadius: 6,
                            position: "absolute",
                            top: 24,
                            right: 12,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Stack
                        spacing={1}
                        sx={{
                            padding: "10px 16px",
                        }}
                    >
                        <Typography variant="h5">
                            <Skeleton width={"40%"} />
                        </Typography>

                        <Typography variant="h5">
                            <Skeleton />
                        </Typography>

                        <Stack direction={"row"} spacing={1}>
                            <CustomFormDivider />
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <PlaceIcon
                                sx={{
                                    color: theme.palette.secondary.main,
                                }}
                            />

                            <Typography variant="subtitle1">
                                <Skeleton width={85} />
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            divider={
                                <Divider
                                    sx={{
                                        backgroundColor: "#fe7529",
                                    }}
                                    orientation="vertical"
                                    flexItem
                                />
                            }
                            spacing={1}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Stack direction={"row"} spacing={1}>
                                <StairsIcon
                                    sx={{
                                        color: theme.palette.secondary.main,
                                    }}
                                />
                                <Typography color={"#123763"}>
                                    <Skeleton width={75} />
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1}>
                                <BedIcon
                                    sx={{
                                        color: theme.palette.secondary.main,
                                    }}
                                />
                                <Typography color={"#123763"}>
                                    <Skeleton width={75} />
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1}>
                                <ControlCameraIcon
                                    sx={{
                                        color: theme.palette.secondary.main,
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                                <Typography
                                    color={"#123763"}
                                    style={{
                                        position: "relative",
                                        bottom: "4px",
                                    }}
                                >
                                    <Skeleton width={75} />
                                </Typography>
                            </Stack>
                        </Stack>
                        <Typography
                            variant="h5"
                            style={{
                                position: "relative",
                                bottom: "-10px",
                            }}
                        >
                            <Skeleton width={"20%"} height={50} />
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Box
                sx={{
                    position: "absolute",
                    top: { xs: 264, md: 16 },
                    right: 10,
                    zIndex: 1,
                }}
            >
                <ScoreProgress size={70} />
            </Box>
        </Paper>
    );
}
