import React, { Fragment, useEffect } from "react";

//* translation
import { useTranslation } from "react-i18next";
//* cookie
import cookies from "js-cookie";

//* react-hook-form
import { Controller, useFormContext } from "react-hook-form";

//* custom components
import {
    ControllerAutoCompleteField,
    ControllerCompanyAutocomplete,
    ControllerDateField,
    ControllerPhoneTextField,
    ControllerRadioField,
    ControllerSelectField,
    ControllerTextField,
    TitleSpacer,
} from "../../../mui-components/MuiDossierFormComponents";

//* custom options
import {
    REASON_TO_MOVE_OPTIONS,
    SmokeOptionArr,
    civilStatusArr,
    employmentRelationshipArr,
    isEmployedOption,
    isYesNoOption,
    movingOutOption,
    permitOptionsArr,
    rentPaymentOption,
    tenantGender,
    tenantTitle,
    unemploymentStatusOption,
    vehicles,
    workload,
} from "../../../../options/dossierOptions";

//* mui components import
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

//* mui icons imports
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import Looks3OutlinedIcon from "@mui/icons-material/Looks3Outlined";
import Looks4OutlinedIcon from "@mui/icons-material/Looks4Outlined";
import Looks5OutlinedIcon from "@mui/icons-material/Looks5Outlined";
import Looks6OutlinedIcon from "@mui/icons-material/Looks6Outlined";

//*  import JSON data
import countryNames from "../../../../assets/JSON/countrybyname.json";
import DossierFormSection from "../dossier_components/DossierFormSection";
import { DossierReferenceBox } from "../dossier_components/DossierReferenceBox";
import { isGreaterThanYear } from "../../../../utils/isGreaterThanYear";

//* custom progress bar
import { ScoreProgress } from "../../../score-progress/ScoreProgress";
import { Divider } from "@mui/material";
import AnnualSalaryRangeSelect from "../../../mui-components/AnnualSalaryRangeSelect";

import TenantTwoSegmentOne from "./segments/tenant_two/TenantTwoSegmentOne";
import TenantTwoSegmentTwo from "./segments/tenant_two/TenantTwoSegmentTwo";
import TenantTwoSegmentThree from "./segments/tenant_two/TenantTwoSegmentThree";
import TenantTwoSegmentFour from "./segments/tenant_two/TenantTwoSegmentFour";
import TenantTwoSegmentFive from "./segments/tenant_two/TenantTwoSegmentFive";
import TenantTwoSegmentSix from "./segments/tenant_two/TenantTwoSegmentSix";
import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "./tenant_common_context/EditFormSectionContextSegment";
import useResponsive from "../../../../hooks/responsive/useResponsive";

let getAllCountry = countryNames.map((item) => ({
    label: item.country,
    value: item.country.toLowerCase(),
}));

export default function DossierTenantTwo({
    referenceStatus,
    handleReferenceStatus,
    stepLabels,
    formSectionIcons,
    commonProps,
}) {
    //const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const { isBelowSm, isBelowMd } = useResponsive();

    const { t, theme, step, currentSection, handleStepClick } = commonProps;

    const {
        control,
        watch,
        setValue,
        formState: { errors },
        score,
    } = useFormContext();

    const tenantTwoTitle = watch("tenantTwoTitle");
    const watchAddressAsFirstTenant = watch("sameAddressAsFirstTenant");
    const watchTenantTwoNationalityCountry = watch("tenantTwoNationality");
    const watchCountry = watch("tenantTwoCountry");
    const watchCity = watch("tenantTwoCity");
    const watchZip = watch("tenantTwoZip");

    let isCountryFilled = watchCountry === "" || watchCountry === null;
    let isStreetDisable =
        watchCity === "" ||
        watchZip === "" ||
        watchCity === null ||
        watchZip === null;

    const watchPartnerEmployed = watch("tenantTwoEmployedOrNot");
    const sameAdministrationAsFirstTenant = watch(
        "sameAdministrationAsFirstTenant"
    );

    const watchTenantTwoHasNoCurrentApartment = watch(
        "tenantTwoHasNoCurrentApartment"
    );
    const watchTenantTwoMovingOutOf = watch("tenantTwoMovingOutOf");
    const watchSameContactAsFirstTenant = watch("sameContactAsFirstTenant");

    const watchTenantTwoUnemployedStatus = watch("tenantTwoUnemploymentStatus");
    const watchTenantTwoRentPaymentMethod = watch("tenantTwoHowYouPay");

    const watchTenantTwoSmoker = watch("isTenantTwoSmoker");
    const watchTenantTwoMusicalInstrument = watch(
        "tenantTwoHasMusicalInstrument"
    );

    const watchIsTenantTwoNeedParking = watch("isTenantTwoNeedParking");

    const watchTenantTwoIsImmediateAndAfterAgreement = watch(
        "tenantTwoIsImmediateAndAfterAgreement"
    );
    const watchTenantTwoVehicle = watch("hasTenantTwoVehicle");
    const watchTenantTwoTypeOfVehicle = watch("tenantTwoTypeOfVehicle");
    const watchTenantTwoPets = watch("hasTenantTwoPet");

    const watchTenantTwoEmployeeEmail = watch("tenantTwoEmployeeEmail");
    const watchTenantTwoReferenceEmail = watch("tenantTwoReferenceEmail");

    const watchCurrentAdministrationEmail = watch("currentAdministrationEmail");
    const watchTenantTwoAdministrationEmail = watch(
        "tenantTwoCurrentAdministrationEmail"
    );

    // checking the email with the employer field of tenant two
    const employerEmailTenantTwo =
        watchPartnerEmployed === "yes"
            ? watchTenantTwoEmployeeEmail
            : watchTenantTwoReferenceEmail;

    const tenantTwoAdministrationEmail =
        sameAdministrationAsFirstTenant === "yes"
            ? watchCurrentAdministrationEmail
            : watchTenantTwoAdministrationEmail;

    return (
        <>
            <Grid container rowSpacing={2} my={2}>
                <Grid item xs={12} position="relative">
                    <Stack
                        flexDirection={{ xs: "column", md: "row" }}
                        justifyContent={"space-between"}
                        alignContent={"center"}
                    >
                        <Stack
                            sx={{ width: "100%" }}
                            justifyContent={"center"}
                            alignContent={"center"}
                        >
                            <Typography variant="h4" textAlign={"center"}>
                                {stepLabels[1] && stepLabels[1] !== "tenant_two"
                                    ? `${stepLabels[1]} ${t(
                                          "edit"
                                      ).toLowerCase()}`
                                    : t("new_edit_smart_dossier_Tenant_2")}
                            </Typography>
                        </Stack>
                        <ScoreProgress size={"75px"} score={score} />
                    </Stack>
                </Grid>

                {/* detail about tenant two start*/}
                <Grid item xs={12}>
                    <EditFormProviderSegment commonProps={commonProps}>
                        {/* SECTION 1 */}
                        <TenantTwoSegmentOne
                            additionalProps={{
                                heading: t("who_are_you") + "?",
                                Icon: formSectionIcons[0].Icon,
                                index: 0,
                                currentLanguageCode,
                                getAllCountry,
                                watchTenantTwoNationalityCountry,
                                tenantTwoTitle,
                                control,
                                errors,
                                watch,
                            }}
                        />

                        {/* SECTION 2 */}
                        <TenantTwoSegmentTwo
                            additionalProps={{
                                heading: t("where_do_you_live") + "?",
                                Icon: formSectionIcons[1].Icon,
                                index: 1,
                                currentLanguageCode,
                                watchAddressAsFirstTenant,
                                getAllCountry,
                                isCountryFilled,
                                isStreetDisable,
                                control,
                                errors,
                                watch,
                            }}
                        />

                        {/* SECTION 3 */}
                        <TenantTwoSegmentThree
                            additionalProps={{
                                heading:
                                    t(
                                        "how_can_you_be_reached_by_administrations"
                                    ) + "?",
                                Icon: formSectionIcons[2].Icon,
                                index: 2,
                                currentLanguageCode,
                                watchSameContactAsFirstTenant,
                                control,
                                errors,
                                watch,
                            }}
                        />

                        {/* SECTION 4 */}
                        <TenantTwoSegmentFour
                            additionalProps={{
                                heading: t(
                                    "the_new_administration_asks_how_do_you_earn_your_money"
                                ),
                                Icon: formSectionIcons[3].Icon,
                                index: 3,
                                currentLanguageCode,
                                watchTenantTwoUnemployedStatus,
                                watchTenantTwoRentPaymentMethod,
                                watchPartnerEmployed,
                                referenceStatus,
                                handleReferenceStatus,
                                setValue,
                                control,
                                errors,
                                watch,
                            }}
                        />

                        {/* SECTION 5 */}
                        <TenantTwoSegmentFive
                            additionalProps={{
                                heading: t(
                                    "the_new_administration_asks_how_did_you_live_before"
                                ),
                                Icon: formSectionIcons[4].Icon,
                                index: 4,
                                currentLanguageCode,
                                watchTenantTwoMovingOutOf,
                                watchTenantTwoHasNoCurrentApartment,
                                sameAdministrationAsFirstTenant,
                                tenantTwoAdministrationEmail,
                                watchTenantTwoIsImmediateAndAfterAgreement,
                                referenceStatus,
                                handleReferenceStatus,
                                control,
                                errors,
                                watch,
                            }}
                        />

                        {/* SECTION 6 */}
                        <TenantTwoSegmentSix
                            additionalProps={{
                                heading: t(
                                    "what_else_is_good_to_share_with_the_new_administration"
                                ),
                                Icon: formSectionIcons[5].Icon,
                                index: 5,
                                currentLanguageCode,
                                watchTenantTwoSmoker,
                                watchTenantTwoMusicalInstrument,
                                watchTenantTwoVehicle,
                                watchTenantTwoTypeOfVehicle,
                                watchTenantTwoPets,
                                watchIsTenantTwoNeedParking,
                                control,
                                errors,
                                watch,
                            }}
                        />
                    </EditFormProviderSegment>
                </Grid>
            </Grid>
        </>
    );
}
