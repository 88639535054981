import React, { useState } from "react";

//*  using toast for success and error
import { toast } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* libraries
import { useNavigate } from "react-router-dom";

//* modal components
import ShareModal from "../../../modal/properties/ShareModal";

//* store imports
import { useStateValue } from "../../../../store";

//* api calls
import { useAddWishlist } from "../../../../hooks/properties/useWishlist";

//* image imports
import PropertyCarouselImages from "../property-media/PropertyCarouselImages";

//* mui components imports
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";

//* mui icons imports
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IosShareIcon from "@mui/icons-material/IosShare";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const PropertyMobileImages = ({
    propertyDetailsImages,
    imageUrl,
    propertyId,
    isFav,
    propertyName,
    propertyOwnerId,
    userId,
    token,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    //const [{user_id, token}] = useStateValue();
    const { mutate } = useAddWishlist();
    const [isFavoriteProperty, setIsFavoriteProperty] = useState(
        Boolean(isFav)
    );

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addToWishlist = (property_id) => {
        if (token === null) {
            return toast.info("Please login to add a property to favorite");
        }

        let userData = {
            property_id,
            userId,
        };
        setIsFavoriteProperty(!isFavoriteProperty);
        mutate(userData, {
            onSuccess: (data) => {
                // setIsFavId(data?.data?.data);
                //
                if (!data?.data?.statuscode) return;
                // toast.success(data?.data?.message);
            },
            onError: (errors) => {
                // toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    const handleNavigate = (path, context) => {
        navigate({
            pathname: path,
            search: `?context=${context}`,
        });
    };

    return (
        <Box position={"relative"} height={500}>
            <PropertyCarouselImages
                url={imageUrl}
                propertyImages={propertyDetailsImages}
                altTags={propertyName}
                animation={"slide"}
                indicators
                swipe
            />
            <Stack
                width={"100%"}
                direction={"row"}
                justifyContent={"space-between"}
                px={2}
                position={"absolute"}
                zIndex={1}
                top={15}
            >
                {/* <Fab
                    color="default"
                    size="medium"
                    onClick={() => navigate(-1)}
                    sx={{ bgcolor: "white" }}
                >
                    <ArrowBackIcon color={"secondary"} />
                </Fab> */}
                <Stack direction={"row"} gap={3}>
                    <Fab
                        color="default"
                        size="medium"
                        onClick={handleClickOpen}
                        sx={{ bgcolor: "white" }}
                    >
                        <IosShareIcon color={"secondary"} />
                    </Fab>
                    {isFavoriteProperty ? (
                        <Fab
                            color="default"
                            size="medium"
                            onClick={() => addToWishlist(propertyId)}
                            sx={{ bgcolor: "white" }}
                        >
                            <FavoriteIcon color={"secondary"} />
                        </Fab>
                    ) : (
                        <Fab
                            color="default"
                            size="medium"
                            onClick={() => addToWishlist(propertyId)}
                            sx={{ bgcolor: "white" }}
                        >
                            <FavoriteBorderIcon color={"secondary"} />
                        </Fab>
                    )}
                </Stack>
                {Number(userId) === Number(propertyOwnerId) && (
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                        sx={{
                            cursor: "pointer",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                        onClick={() =>
                            handleNavigate(
                                `/add-property-details/${propertyId}`,
                                "fs-flats"
                            )
                        }
                    >
                        <Fab
                            color="default"
                            size="medium"
                            onClick={handleClickOpen}
                            sx={{ bgcolor: "white" }}
                        >
                            <EditIcon color="primary" />
                        </Fab>
                    </Stack>
                )}
                {
                    //console.log(userId === propertyOwnerId)
                }
            </Stack>
            <ShareModal
                // fullScreen={fullScreen}
                open={open}
                handleClose={handleClose}
            />
        </Box>
    );
};
