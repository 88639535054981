import { useMutation, useQuery } from "react-query";
import { Axios } from "../../axios/axios";

//* Employer reference button click

function sendEmployerReference(data) {
    return Axios.post("/request_for_reference", data);
}

export function useSendEmployerReference() {
    return useMutation(sendEmployerReference);
}

//* Employers form

function employerForm(data) {
    return Axios.post("/create_employer_reference_form", data);
}

export function useEmployerReference() {
    return useMutation(employerForm);
}

//* Administration reference button click

function sendAdministrationReference(data) {
    return Axios.post("/request_for_reference", data);
}

export function useSendAdministrationReference() {
    return useMutation(sendAdministrationReference);
}

//* Administration Form

function administrationForm(data) {
    return Axios.post("/create_administration_reference_form", data);
}

export function useAdministrationReference() {
    return useMutation(administrationForm);
}

//* verify reference form

function getVerifyRequest({ queryKey }) {
    const token = queryKey[1];
    return Axios.get(`/verify_request_for_reference?token=${token}`);
}

export function useVerifyRequest(token) {
    return useQuery(["verify-request", token], getVerifyRequest);
}

//* get reference data

function getReferenceDetail({ queryKey }) {
    const user_id = queryKey[1];
    const type = queryKey[2];
    return Axios.get(`/reference_form_details?user_id=${user_id}&type=${type}`);
}

export function useReferenceDetail(user_id, type) {
    return useQuery(["reference_detail", user_id, type], getReferenceDetail, {
        enabled: Boolean(user_id),
    });
}
