import React, {Fragment, useEffect, useState} from "react";

//* translation
import {useTranslation} from "react-i18next";

//* react-hook-form
import {useFormContext} from "react-hook-form";

//* custom component
import DossierFormSection from "../dossier_components/DossierFormSection";

//* mui components import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports

import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";

//* components imports
import {TenantProfile} from "../uploads/TenantProfile";
import {TenantVideo} from "../uploads/TenantVideo";
import DossierDocumentTable from "../../../table/dossier-document/DossierDocumentTable";
import {TenantDocument} from "../uploads/TenantDocument";
import {
    InfoWithIcon,
    MainHeadingWithIcon,
} from "../dossier_components/DossierInfoBox";

//* custom progress bar
import {ScoreProgress} from "../../../score-progress/ScoreProgress";
import ViewDossierDocument from "./ViewDossierDocument";
import {PictureAsPdfOutlined} from "@mui/icons-material";

const MediaBox = ({children, mainTitle, subTitle}) => {
    return (
        <Stack rowGap={2}>
            <Stack rowGap={1}>
                {mainTitle && (
                    <Typography variant={"h5"} textAlign={"center"}>
                        {mainTitle}
                    </Typography>
                )}
                {subTitle && (
                    <Typography variant={"h5"} textAlign={"center"}>
                        {subTitle}
                    </Typography>
                )}
            </Stack>
            {children}
        </Stack>
    );
};

const DocumentUploadStep = ({order, label}) => {
    return (
        <Stack
            flexDirection={"row"}
            minHeight={100}
            alignItems={"center"}
            columnGap={2}
        >
            <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    height: 50,
                    width: 50,
                    borderRadius: "50%",
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: (theme) => theme.palette.primary.dark,
                }}
            >
                <Typography variant="h5">{order}</Typography>
            </Stack>
            <Typography variant="h5">{label}</Typography>
        </Stack>
    );
};

export default function DossierUploadDocument({
                                                  stepLabels
                                              }) {
    const {t} = useTranslation();

    const {watch, score} = useFormContext();

    const watchIsAnotherTenantMovingIn = watch("isAnotherTenantMovingIn");

    const tmpTenantOneFirstName = stepLabels[0] && stepLabels[0] !== "tenant_one" ? stepLabels[0] : t("tenant_one");
    const tmpTenantTwoFirstName = stepLabels[1] && stepLabels[1] !== "tenant_two" ? stepLabels[1] : t("tenant_two");

    return (
        <>
            <Grid container rowSpacing={2} my={2}>
                <Grid item xs={12} position="relative">
                    <Stack
                        flexDirection={{xs: "column", md: "row"}}
                        justifyContent={"space-between"}
                        alignContent={"center"}
                    >
                        <Stack
                            sx={{width: "100%"}}
                            justifyContent={"center"}
                            alignContent={"center"}
                        >
                            <Typography variant="h4" textAlign={"center"}>
                                {t("edit_smart_dossier_upload_documents")}
                            </Typography>
                        </Stack>
                        <ScoreProgress size={"75px"} score={score}/>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <DossierFormSection
                        heading={t(
                            "want_to_send_a_smile_to_the_new_administration"
                        )}
                        Icon={LooksOneOutlinedIcon}
                    >
                        <Grid item container>
                            <Grid item xs={12} md={6}>
                                <Grid item container>
                                    <Grid item xs={12} sm={6}>
                                        <MediaBox
                                            mainTitle={t("application_photo")}
                                            subTitle={tmpTenantOneFirstName}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <TenantProfile
                                                    profileKey={"profile_pic"}
                                                />
                                            </Box>
                                        </MediaBox>
                                    </Grid>
                                    {watchIsAnotherTenantMovingIn === "yes" && (
                                        <Grid item xs={12} sm={6}>
                                            <MediaBox
                                                mainTitle={t(
                                                    "application_photo"
                                                )}
                                                subTitle={tmpTenantTwoFirstName}
                                            >
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <TenantProfile
                                                        profileKey={
                                                            "tenantTwoProfilePic"
                                                        }
                                                    />
                                                </Box>
                                            </MediaBox>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} mt={1}>
                                        <Typography
                                            variant={"h6"}
                                            textAlign={"center"}
                                        >
                                            {t(
                                                "upload_photos_to_make_it_more_personal"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid item container>
                                    <Grid item xs={12}>
                                        <MediaBox
                                            mainTitle={t("applicant")}
                                            subTitle={t(
                                                "your_personal_message"
                                            )}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <TenantVideo/>
                                            </Box>
                                        </MediaBox>
                                    </Grid>
                                    <Grid item xs={12} mt={1}>
                                        <Typography
                                            variant={"h6"}
                                            textAlign={"center"}
                                        >
                                            {t(
                                                "upload_a_video_and_present_yourself"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DossierFormSection>
                </Grid>
                {/* profile and video section end */}

                {/* provide document section start */}
                <Grid item xs={12}>
                    <DossierFormSection
                        heading={t(
                            "which_documents_do_you_want_to_provide_to_the_administration"
                        )}
                        Icon={LooksTwoOutlinedIcon}
                    >
                        <Grid item container>
                            {[
                                "enter_further_details",
                                "choose_pdf_document",
                                "save",
                            ].map((label, index) => (
                                <Grid item xs={12} md={4} key={label}>
                                    <DocumentUploadStep
                                        order={index + 1}
                                        label={t(label)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <TenantDocument/>
                        </Grid>

                        <Grid item xs={12} mt={6.5}>
                            <Typography variant="h5">
                                {t("list_of_your_documents")}
                            </Typography>

                            <Box my={2}>
                                <ViewDossierDocument
                                    stepLabels={stepLabels}
                                    isAnotherTenantMovingIn={
                                        watchIsAnotherTenantMovingIn
                                    }
                                />
                            </Box>
                        </Grid>
                    </DossierFormSection>
                </Grid>
                {/* provide document section end */}
            </Grid>
        </>
    );
}
