import React, { useState } from "react";

import cookies from "js-cookie";

//*  using toast for success and error
import { toast, Flip, Zoom } from "react-toastify";

//*  Translation
import { useTranslation } from "react-i18next";

//* hook form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//* api calls
import { useForgotPassword } from "../../../hooks/Auth/useForgotPassword";

//* Schema
import { ForgotSchema } from "../../../forms-schemas/auth-schemas/ForgotSchema";

//* mui components import
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

//* mui icons imports
import { Alert, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useAuthModal } from "../../../context/ModalContext";

function ForgotForm() {
    const { t } = useTranslation();
    const { closeAuthModal } = useAuthModal();
    const { isLoading, mutate } = useForgotPassword();
    const [message, setMessage] = useState("");
    const currentLanguageCode = cookies.get("i18next") || "en";

    const {
        control,
        handleSubmit,
        formState: { errors },
        // reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(ForgotSchema),
    });

    const submitForm = (data) => {
        let forgetData = {
            email: data.email,
            lang: currentLanguageCode,
        };

        mutate(forgetData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    toast.error(
                        `${t("you_are_not_registered_with_us_please_signup")}`,
                        {
                            transition: Zoom,
                        }
                    );
                    return;
                }
                toast.success(
                    `${t("you_will_receive_reset_link_on_your_email")}`,
                    {
                        transition: Flip,
                    }
                );
                setMessage(`${t("you_will_receive_reset_link_on_your_email")}`);
                setTimeout(() => {
                    closeAuthModal();
                }, 1000);
            },
            onError: (errors) => {
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Stack alignItems={"center"}>
                    <Typography textAlign={"center"} variant="h4">
                        {t("forgot_password")}
                    </Typography>
                    <Typography variant="subtitle">
                        {t("please_enter_your_email")}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Grid item container rowSpacing={2}>
                        {message && (
                            <Grid item xs={12}>
                                <Alert severity="success">{message}</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="email"
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            type="email"
                                            label={t("email")}
                                            fullWidth
                                            variant="filled"
                                            {...field}
                                            error={!!errors?.email}
                                            helperText={t(
                                                errors?.email?.message
                                            )}
                                        />
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ position: "relative" }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    {isLoading
                                        ? t("sending_verification_link")
                                        : t("send")}
                                </Button>
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
}

export default ForgotForm;
