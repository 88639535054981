import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material";
import Droplet from "../shapes/Droplet";

import { useTranslation } from "react-i18next";

const MuiCustomerAvatar = styled(Avatar)(({ theme }) => ({
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "50% 50% 0 50%",
    objectPosition: "left",
}));

const CustomerVoiceBox = ({ image, testimony, name, placeholders }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                borderRadius: 5,
                bgcolor: theme.palette.grey[200],
                padding: 4,
            }}
        >
            <Stack spacing={2.5}>
                <Stack direction={"row"} spacing={2.5} alignItems={"center"}>
                    <Typography variant="h6">{name}</Typography>
                </Stack>
                <Stack>
                    <Typography variant="body2">{t(testimony)}</Typography>
                </Stack>
            </Stack>
        </Box>
    );
};

CustomerVoiceBox.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    testimony: PropTypes.string,
    placeholders: PropTypes.string,
};

export default CustomerVoiceBox;
