import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ArrowButton from "../../../../../component/elements/button/ArrowButton";
import {handlePrev} from "../utils/handleSteps";

const NavigationButtons = ({ currentSection, setCurrentSection, goToPreviousSection, goToNextSection, activeStep, handlePrevStep, t }) => {
    const showSectionBackButton = () => {
        // When on the first or second step and the current section is 0, don't show the back button.
        if (activeStep === 0 && currentSection === 0) {
            return false;
        }
        return true;
    };

    const getBackButtonAction = () => {
        // Use handlePrevStep when activeStep is greater than 0 and currentSection is 0.
        if (activeStep > 0 && currentSection === 0) {
            return () => handlePrevStep(setCurrentSection);
        }
        // Otherwise, use goToPreviousSection.
        return goToPreviousSection;
    };

    return (
        <Stack direction={"row"} justifyContent={"space-between"} p={1}>
            {showSectionBackButton() ? (
                <ArrowButton variant="text" onClick={getBackButtonAction()} arrowStart>
                    {t("back")}
                </ArrowButton>
            ) : (
                <Box />
            )}
            <ArrowButton variant="contained" onClick={goToNextSection}>
                {t("save")}
            </ArrowButton>
        </Stack>
    );
};

export default NavigationButtons;