//* Translation
import { useTranslation } from "react-i18next";

//* mui components import
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material";
import { Fragment } from "react";
import useResponsive from "../../../../hooks/responsive/useResponsive";
import { socialMediaLinks } from "../../../../options/socialLinks";

const PropertyText = styled(Typography)(() => ({
    wordBreak: "break-word",
    textAlign: { xs: "center" },
    msHyphens: "auto",
    MozHyphens: "auto",
    WebkitHyphens: "auto",
    hyphens: "auto",
}));

export const MainHeadingWithIcon = ({
    textOne,
    textTwo,
    isParagraph,
    isEmployerReference,
    showSocialLinks,
    currentAddress,
    propertyName,
    TenantName,
    email,
}) => {
    const { t } = useTranslation();

    const { isBelowMd } = useResponsive();

    return (
        <Stack mt={3}>
            <Box p={3} sx={{ border: "1px dashed #5BAFA9", borderRadius: 5 }}>
                <Stack direction={isBelowMd ? "column" : "row"} spacing={2}>
                    <InfoOutlinedIcon sx={{ color: "#5BAFA9", fontSize: 40 }} />
                    <Stack spacing={2}>
                        <Typography variant="h6">{textOne}</Typography>
                        <Typography variant="body2">
                            {textTwo}&nbsp;
                            <a
                                href="mailto:reference@nextflat.ch"
                                style={{ color: "#5BAFA9" }}
                            >
                                {email}
                            </a>
                        </Typography>
                        {showSocialLinks && (
                            <Stack direction="row" gap={2}>
                                {socialMediaLinks &&
                                    socialMediaLinks.map((social) =>
                                        social.key ? (
                                            <Stack
                                                key={social.key}
                                                component="a"
                                                href={social.link}
                                                target="_blank"
                                                sx={{ textDecoration: "none" }}
                                            >
                                                <Typography color="secondary">
                                                    {social.icon}
                                                </Typography>
                                            </Stack>
                                        ) : null
                                    )}
                            </Stack>
                        )}
                    </Stack>
                </Stack>
                {isParagraph && (
                    <Stack mt={2} ml={!isBelowMd && 7}>
                        <Typography variant="h4" fontSize={"1.2em"}>
                            {currentAddress}
                        </Typography>
                        {isEmployerReference ? (
                            <Stack flexWrap={"wrap"}>
                                <Typography variant="h4" fontWeight={500}>
                                    {`${t("employee")} :`}&nbsp;
                                </Typography>
                                <PropertyText variant="h4">
                                    {propertyName}
                                </PropertyText>
                            </Stack>
                        ) : (
                            <Fragment>
                                <Stack direction="row">
                                    <Typography variant="h4" fontWeight={500}>
                                        {`${t("property")} :`}&nbsp;
                                    </Typography>
                                    <Typography variant="h4">
                                        {propertyName}
                                    </Typography>
                                </Stack>
                                <Stack direction="row">
                                    <Typography variant="h4" fontWeight={500}>
                                        {`${t("tenant")} :`}&nbsp;
                                    </Typography>
                                    <Typography variant="h4">
                                        {TenantName}
                                    </Typography>
                                </Stack>
                            </Fragment>
                        )}
                    </Stack>
                )}
            </Box>
        </Stack>
    );
};

export const InfoWithIcon = ({ title }) => {
    return (
        <Stack
            direction="row"
            spacing={1}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <InfoOutlinedIcon sx={{ color: "#5BAFA9" }} />
            <Typography variant={"h5"} textAlign={"center"}>
                {title}
            </Typography>
        </Stack>
    );
};
