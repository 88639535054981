import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material";
import Droplet from "../shapes/Droplet";
import { useTranslation } from "react-i18next";

const ContainerBox = styled(Box)(({ theme }) => ({
   backgroundColor: theme.palette.common.white,
   borderRadius: 30,
}));

const DropletWrapper = styled(Box)(({ theme }) => ({
   [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
   },
}));

const BenefitTypography = styled(Typography)(({ theme }) => ({
   [theme.breakpoints.down("md")]: {
      textAlign: "center",
   },
}));

const IconWithText = ({ icon, title, subTitle, position, size, bgcolor }) => {
   const { t } = useTranslation();
   const theme = useTheme();
   return (
      <ContainerBox>
         <Grid container spacing={2} padding={2.5}>
            <Grid item xs={12} md={2}>
               <DropletWrapper>
                  <Droplet
                     position={position}
                     size={size}
                     bgcolor={bgcolor}
                     containerStyleProps={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                     }}
                  >
                     {icon && (
                        <Icon sx={{ color: theme.palette.common.white }}>
                           {icon}
                        </Icon>
                     )}
                  </Droplet>
               </DropletWrapper>
            </Grid>
            <Grid item xs={12} md={10}>
               <Grid item container>
                  <Grid item xs={12}>
                     {title && (
                        <BenefitTypography variant='h5'>
                           {t(title)}
                        </BenefitTypography>
                     )}
                     {subTitle && (
                        <BenefitTypography variant='body2'>
                           {t(subTitle)}
                        </BenefitTypography>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </ContainerBox>
   );
};

IconWithText.propTypes = {
   bgcolor: PropTypes.string,
   icon: PropTypes.any,
   position: PropTypes.string,
   size: PropTypes.number,
   subTitle: PropTypes.string,
   title: PropTypes.string,
};

export default IconWithText;
