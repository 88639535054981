import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

const ControllerSelectField = ({
    label,
    name,
    type,
    InputProps,
    inputProps,
    control,
    disabled,
    errorFlag,
    errorText,
    fullWidth,
    OPTIONS,
    hiddenLabel,
    props,
    selectProps,
}) => {
    const { t } = useTranslation();
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                return (
                    <TextField
                        select
                        variant="filled"
                        {...(hiddenLabel
                            ? { hiddenLabel: true }
                            : { label: label || "" })}
                        {...field}
                        error={errorFlag}
                        helperText={errorText}
                        type={type ?? "text"}
                        InputProps={InputProps}
                        inputProps={inputProps}
                        disabled={disabled}
                        fullWidth={fullWidth}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            ...selectProps,
                        }}
                        {...props}
                    >
                        {OPTIONS.map((option, index) => (
                            <MenuItem
                                key={option.id || index}
                                value={option.value}
                            >
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            }}
        />
    );
};

ControllerSelectField.propTypes = {
    InputProps: PropTypes.object,
    inputProps: PropTypes.object,
    control: PropTypes.any,
    disabled: PropTypes.bool,
    errorFlag: PropTypes.bool,
    errorText: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    OPTIONS: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.any,
            name: PropTypes.string,
        })
    ),
    props: PropTypes.object,
};

export default ControllerSelectField;
