export const tenantGender = [
    { id: 1, value: "woman", label: "woman" },
    { id: 2, value: "mister", label: "mister" },
    { id: 3, value: "diverse", label: "diverse" },
];

export const tenantTitle = [
    { id: 1, value: "prof", label: "prof" },
    { id: 2, value: "prof_dr", label: "prof_dr" },
    { id: 3, value: "dr", label: "dr" },
    { id: 4, value: "none", label: "none" },
    { id: 5, value: "other", label: "other" },
];

export const permitOptionsArr = [
    {
        value: "l_permit_eu",
        label: "l_permit_eu",
        shortDescription: "short_stay_permit",
    },
    {
        value: "b_permit_eu",
        label: "b_permit_eu",
        shortDescription: "residence_permit_option",
    },
    {
        value: "c_permit_eu",
        label: "c_permit_eu",
        shortDescription: "settlement_permit",
    },
    {
        value: "ci_permit_eu",
        label: "ci_permit_eu",
        shortDescription: "residence_permit_with_gainful_employement",
    },
    {
        value: "g_permit_eu",
        label: "g_permit_eu",
        shortDescription: "cross_border_permit",
    },
    {
        value: "b_permit",
        label: "b_permit",
        shortDescription: "residence_permit_option",
    },
    {
        value: "c_permit",
        label: "c_permit",
        shortDescription: "settlement_permit",
    },
    {
        value: "ci_permit",
        label: "ci_permit",
        shortDescription:
            "residence_permit_with_employment_for_third_country_nationals",
    },
    {
        value: "g_permit",
        label: "g_permit",
        shortDescription: "cross_border_permit",
    },
    {
        value: "l_permit",
        label: "l_permit",
        shortDescription: "residence_permit_option",
    },
    {
        value: "f_permit",
        label: "f_permit",
        shortDescription: "for_temporarily_admitted_foreigners",
    },
    {
        value: "n_permit",
        label: "n_permit",
        shortDescription: "for_asylum_seeker",
    },
    {
        value: "s_permit",
        label: "s_permit",
        shortDescription:
            "provisional_status_for_persons_in_need_of_protection",
    },

];
/*
    {
        value: "swiss_citizen",
        label: "swiss_citizen",
        shortDescription: "swiss_citizen",
    },
 */
export const workload = [
    // { value: "none", label: "none" },
    { id: "10", value: "10", label: "10" },
    { id: "20", value: "20", label: "20" },
    { id: "30", value: "30", label: "30" },
    { id: "40", value: "40", label: "40" },
    { id: "50", value: "50", label: "50" },
    { id: "60", value: "60", label: "60" },
    { id: "70", value: "70", label: "70" },
    { id: "80", value: "80", label: "80" },
    { id: "90", value: "90", label: "90" },
    { id: "100", value: "100", label: "100" },
];

export const civilStatusArr = [
    { id: 1, value: "single", label: "single" },
    { id: 2, value: "married", label: "married" },
    { id: 3, value: "divorced", label: "divorced" },
    { id: 4, value: "widowed", label: "widowed" },
    {
        id: 5,
        value: "registered_partnership",
        label: "registered_partnership",
    },
    {
        id: 6,
        value: "no_specification",
        label: "no_specification",
    },
    // {
    //     id: 6,
    //     value: "dissolved_partnership",
    //     label: "dissolved_partnership",
    // },
    // {
    //   id: 7,
    //   value: "do_not_disclose",
    //   label: "dont_want_to_disclose",
    // },
];

export const isYesNoOption = [
    { id: 1, label: "no", value: "no" },
    {
        id: 2,
        label: "yes",
        value: "yes",
    },
];

export const isEmployedOption = [
    {
        id: 1,
        label: "yes",
        value: "yes",
    },
    { id: 2, label: "no", value: "no" },
    // {
    //   id: 3,
    //   label: "select",
    //   value: "",
    // },
    //   {
    //     id: 3,
    //     label: "no_specification",
    //     value: "no_specification",
    //   },
];

export const quitContract = [
    {
        id: 1,
        label: "administration",
        value: "administration",
    },
    { id: 2, label: "tenant", value: "tenant" },
    // {
    //   id: 3,
    //   label: "select",
    //   value: "",
    // },
    {
        id: 3,
        label: "no_specification",
        value: "no_specification",
    },
];

export const documentArrTenantOne = [
    {
        id: 1,
        label: "id_passport",
        value: "passport_id",
    },
    { id: 2, label: "salary_statement", value: "salary_statement" },
    {
        id: 3,
        label: "debt_collect",
        value: "debt_collect",
    },
    {
        id: 4,
        label: "criminal_record",
        value: "criminal_record",
    },
    // {
    //   id: 5,
    //   label: "others",
    //   value: "other_docs ",
    // },
];

export const documentArrTenantTwo = [
    {
        id: 1,
        label: "id_passport",
        value: "tenantTwoPassport",
    },
    { id: 2, label: "salary_statement", value: "tenantTwoSalary" },
    {
        id: 3,
        label: "debt_collect",
        value: "tenantTwoDebt",
    },
    {
        id: 4,
        label: "criminal_record",
        value: "tenantTwoCriminal",
    },
    // {
    //   id: 5,
    //   label: "others",
    //   value: "tenantTwoOtherDocs",
    // },
];

export const tenantType = [
    {
        id: 1,
        value: "tenant_one",
        label: "tenant_one",
    },
    { id: 2, value: "tenant_two", label: "tenant_two" },
];

export const employmentRelationshipArr = [
    {
        id: 1,
        label: "permanent_employ",
        value: "permanent_employ",
    },
    // { id: 2, label: "100%", value: "100_percent" },
    {
        id: 2,
        label: "temporary",
        value: "temporary",
    },
    {
        id: 3,
        label: "no_specification",
        value: "no_specification",
    },
    // {
    //   id: 4,
    //   label: "part_time",
    //   value: "part_time",
    // },
];

export const salarySlabArr = [
    {
        id: 1,
        label: "CHF 25000 ",
        subLabel: "below",
        value: "below_25000",
    },
    {
        id: 2,
        label: "CHF 25000 to CHF 30000",
        value: "25000_to_30000",
    },
    {
        id: 3,
        label: "CHF 30000 to CHF 35000",
        value: "30000_to_35000",
    },
    {
        id: 4,
        label: "CHF 35000 to CHF 40000",
        value: "35000_to_40000",
    },
    {
        id: 5,
        label: "CHF 40000 to CHF 45000",
        value: "40000_to_45000",
    },
    {
        id: 6,
        label: "CHF 45000 to CHF 50000",
        value: "45000_to_50000",
    },
    {
        id: 7,
        label: "CHF 50000 to CHF 60000",
        value: "50000_to_60000",
    },
    {
        id: 8,
        label: "CHF 60000 to CHF 70000",
        value: "70000_to_80000",
    },
    {
        id: 9,
        label: "CHF 70000 to CHF 80000",
        value: "70000_to_80000",
    },
    {
        id: 10,
        label: "CHF 80000 to CHF 90000",
        value: "80000_to_90000",
    },
    {
        id: 11,
        label: "CHF 90000 to CHF 100000",
        value: "90000_to_100000",
    },
    {
        id: 12,
        label: "CHF 100000 to CHF 120000",
        value: "100000_to_120000",
    },
    {
        id: 13,
        label: "CHF 120000 to CHF 140000",
        value: "120000_to_140000",
    },
    {
        id: 14,
        label: "CHF 140000 to CHF 160000",
        value: "120000_to_160000",
    },

    {
        id: 15,
        label: "CHF 160000 ",
        subLabel: "above",
        value: "over_150000",
    },
];

export const SmokeOptionArr = [
    {
        id: 1,
        label: "inside_the_house",
        value: "inside_the_house",
    },
    {
        id: 2,
        label: "outside_the_house",
        value: "outside_the_house",
    },
    {
        id: 3,
        label: "no_preference",
        value: "no_preference",
    },
];

export const interestedPartnerArr = [
    {
        id: 1,
        label: "spouse",
        value: "spouse",
    },
    {
        id: 2,
        label: "partner_in",
        value: "partner_in",
    },
    {
        id: 3,
        label: "flatmate",
        value: "flatmate",
    },
    {
        id: 4,
        label: "subtenant",
        value: "subtenant",
    },
];

export const vehicles = [
    {
        id: 1,
        label: "motorcycle",
        value: "motorcycle",
    },
    {
        id: 2,
        label: "automobile",
        value: "automobile",
    },
    {
        id: 3,
        label: "motorcycle_&_automobile",
        value: "motorcycle_&_automobile",
    },
];

export const REASON_TO_MOVE_OPTIONS = [
    {
        id: 1,
        label: "separation/divorce",
        value: "separation/divorce",
    },
    {
        id: 2,
        label: "moving_in_together",
        value: "moving_in_together",
    },
    {
        id: 3,
        label: "enlargement",
        value: "enlargement",
    },
    {
        id: 4,
        label: "downsizing",
        value: "downsizing",
    },
    {
        id: 5,
        label: "second_home/holiday_home",
        value: "second_home/holiday_home",
    },
    {
        id: 6,
        label: "family_planning",
        value: "family_planning",
    },
    {
        id: 7,
        label: "career_change",
        value: "career_change",
    },
    {
        id: 8,
        label: "moving_out_from_home",
        value: "moving_out_from_home",
    },
    {
        id: 9,
        label: "desire_for_change",
        value: "desire_for_change",
    },
    {
        id: 10,
        label: "cancellation_because_of_own_need",
        value: "cancellation_because_of_own_need",
    },
    {
        id: 11,
        label: "other/miscellaneous",
        value: "other/miscellaneous",
    },
    {
        id: 12,
        label: "way_to_work",
        value: "way_to_work",
    },
    {
        id: 13,
        label: "apartment_demolished/renovated",
        value: "apartment_demolished/renovated",
    },
    {
        id: 13,
        label: "immigration_from_abroad",
        value: "immigration_from_abroad",
    },
];

export const isTenantQuiteOption = [
    {
        id: 1,
        label: "yes",
        value: "yes",
    },
    { id: 2, label: "no", value: "no" },
    {
        id: 3,
        label: "no_specification",
        value: "no_specification",
    },
];

export const paymentMoraleOption = [
    {
        id: 1,
        label: "rents_have_always_been_paid_on_time",
        value: "rents_have_always_been_paid_on_time",
    },
    {
        id: 2,
        label: "irregular_rent_payments",
        value: "irregular_rent_payments",
    },
    { id: 3, label: "rent_debts_exist", value: "rent_debts_exist" },
    { id: 4, label: "applicant_was_reminded", value: "applicant_was_reminded" },
];

export const administrationTypeOption = [
    { id: 1, label: "owner", value: "owner" },
    { id: 2, label: "administration", value: "administration" },
];

export const numberArray = (length = 5) =>
    Array.from({ length }, (_, i) => {
        return { id: i + 1, label: `${i + 1}`, value: `${i + 1}` };
    });

export const unemploymentStatusOption = [
    { id: 1, label: "jobseeking", value: "jobseeking" },
    { id: 2, label: "retired_pensioner", value: "retired_pensioner" },
    { id: 3, label: "student", value: "student" },
    { id: 4, label: "self_employed", value: "self_employed" },
    { id: 5, label: "other_unemployed", value: "other_unemployed" },
];
export const rentPaymentOption = [
    {
        id: 1,
        label: "other_tenant_pays_the_rent",
        value: "other_tenant_pays_the_rent",
    },
    { id: 2, label: "from_assets", value: "from_assets" },
    { id: 3, label: "social_welfare", value: "social_welfare" },
    { id: 4, label: "unemployment_insurance", value: "unemployment_insurance" },
    {
        id: 5,
        label: "additional_solidarity_liability",
        value: "additional_solidarity_liability",
    },
    { id: 6, label: "other_rent_method", value: "other_rent_method" },
];

export const movingOutOption = [
    {
        id: 1,
        label: "own_property_owner",
        value: "own_property_owner",
    },
    {
        id: 2,
        label: "parental_home",
        value: "parental_home",
    },
    {
        id: 3,
        label: "shared_flat",
        value: "shared_flat",
    },
    {
        id: 4,
        label: "other",
        value: "other",
    },
];
