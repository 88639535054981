import React, { useEffect, useState } from "react";

//* mui components imports
import Box from "@mui/material/Stack";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function PropertyVideo({ video }) {
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    if (video) {
      // How to preview video url --->https://stackoverflow.com/questions/21607808/convert-a-youtube-video-url-to-embed-code/21607897
      let regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      let match = video.match(regExp);
      setVideoId(() => {
        if (match && match[2].length === 11) {
          return match[2];
        } else {
          return "error";
        }
      });
    }
  }, [video]);

  return (
    <Stack spacing={2}>
      <Typography variant='h4'>Property Video</Typography>
      <Box>
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          height={400}
          title='House Tour'
        ></iframe>
      </Box>
    </Stack>
  );
}

export default PropertyVideo;
