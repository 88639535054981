import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function getEmailVerification(data) {
    let { email, lang } = data;
    return Axios.get(
        `/users/forgot_password?email=${email}&type=website&lang=${lang}`
    );
}

export function useForgotPassword() {
    return useMutation(getEmailVerification);
}

function handleResetPassword(data) {
    return Axios.get(
        `/users/reset_password?key=${data.key}&token=${data.token}&password=${data.password}`
    );
}

export function useResetPassword() {
    return useMutation(handleResetPassword);
}
