import * as yup from "yup";

export const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .email("email_is_invalid")
        .required("email_is_required")
        .min(6, "email_must_be_between_6_and_254_characters_long")
        .max(254, "email_must_be_between_6_and_254_characters_long"),
    // password: yup.string().required("please_enter_your_password"),
});
