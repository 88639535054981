import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Menu,
    styled,
    Badge,
    Typography,
    Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    useGetUnReadNotification,
    useReadNotification,
} from "../../hooks/notification/useGetNotification";
import { useStateValue } from "../../store";
import Loading from "../loading/Loading";

//* cookie
import cookies from "js-cookie";

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
    marginTop: "5px",
    marginBottom: "5px",
    // transition: "color 300ms ease-in, background-color 300ms ease-in",
    "&.Mui-selected": {
        ".MuiListItemText-primary": {
            color: theme.palette.common.white,
            fontWeight: 300,
        },
        ".MuiListItemText-secondary": {
            color: theme.palette.common.white,
            fontWeight: 300,
        },
        ".MuiListItemIcon-root": {
            color: theme.palette.common.white,
        },
        backgroundColor: theme.palette.tertiary.main,
        ":hover": {
            backgroundColor: theme.palette.tertiary.main,
        },
    },

    "& .MuiListItemIcon-root": {
        color: theme.palette.primary.dark,
    },
    ".MuiListItemText-primary": {
        fontWeight: 300,
        color: theme.palette.primary.dark,
    },
    ".MuiListItemText-secondary": {
        fontWeight: 300,
        color: theme.palette.primary.dark,
    },
    "&:hover:not(.Mui-selected)": {
        ".MuiListItemText-primary": {
            color: theme.palette.primary.main,
            fontWeight: 300,
        },
        ".MuiListItemText-secondary": {
            fontWeight: 300,
            color: theme.palette.primary.dark,
        },
        ".MuiListItemIcon-root": {
            color: theme.palette.primary.main,
        },
        backgroundColor: theme.palette.tertiary.light,
    },
    ".MuiAvatar-root": {
        color: "#FFFFFF",
        backgroundColor: theme.palette.tertiary.main,
        // filter: "drop-shadow(0px 0px 2px #000000)",
    },
    margin: 0,
}));

const NotificationPopup = ({ userId }) => {
    const [{ user_id }] = useStateValue();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState();

    const onShowAll = () => {
        navigate("/account/notifications");
        setAnchorEl(null);
    };

    const handleClickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseButton = () => {
        setAnchorEl(null);
    };
    const currentLanguageCode = cookies.get("i18next") || "en";
    // Fetch unread notifications
    const {
        data: unreadNotifications,
        isLoading: isUnreadNotificationLoading,
        refetch: refetchUnreadNotification,
    } = useGetUnReadNotification(user_id, currentLanguageCode);

    const filteredNotifications =
        unreadNotifications?.data?.results?.filter(
            (unreadNotifications) =>
                unreadNotifications.message !== undefined &&
                unreadNotifications.message !== undefined &&
                unreadNotifications.message !== null &&
                unreadNotifications.message !== "" &&
                unreadNotifications.status !== undefined &&
                unreadNotifications.status !== undefined &&
                unreadNotifications.status !== null &&
                unreadNotifications.status !== ""
        ) || [];

    // Mutation to mark a notification as read
    const { mutate: markNotificationAsRead, isLoading: isMarkingRead } =
        useReadNotification();
    //console.log(notifications);

    // Example function to handle marking a notification as read
    const handleMarkAsRead = (notificationId) => {
        markNotificationAsRead(
            { id: notificationId },
            {
                onSuccess: (data) => {
                    refetchUnreadNotification();
                },
            }
        );
        navigate("/account/notifications");
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={anchorEl ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                onClick={handleClickButton}
            >
                <Badge
                    badgeContent={
                        isUnreadNotificationLoading
                            ? 0
                            : unreadNotifications?.data?.totalItems
                    }
                    color="primary"
                >
                    <NotificationsIcon color="primary" />
                </Badge>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseButton}
                slotProps={{
                    paper: {
                        sx: {
                            width: {
                                xs: 280,
                                md: 420,
                            },
                        },
                    },
                }}
                sx={{
                    borderRadius: "30px",
                }}
            >
                <List disablePadding>
                    {isUnreadNotificationLoading ? (
                        <Loading />
                    ) : Array.isArray(filteredNotifications) &&
                      filteredNotifications.length === 0 ? (
                        <Stack
                            direction={"row"}
                            pt={3}
                            width={"100%"}
                            justifyContent={"center"}
                        >
                            {t("error_data_exist")}
                        </Stack>
                    ) : (
                        filteredNotifications.map(
                            (unreadNotifications, index) => (
                                <ListItem
                                    disablePadding
                                    key={unreadNotifications.id}
                                >
                                    <StyledListButton
                                        onClick={() =>
                                            handleMarkAsRead(
                                                unreadNotifications.id
                                            )
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    backgroundColor: (theme) =>
                                                        theme.palette.common
                                                            .white,
                                                    boxShadow:
                                                        "0px 10px 12px 0px #0000001A",
                                                }}
                                            >
                                                <CircleNotificationsIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={unreadNotifications.title}
                                            secondary={
                                                unreadNotifications.message
                                            }
                                        />
                                    </StyledListButton>
                                </ListItem>
                            )
                        )
                    )}
                </List>

                <Button
                    onClick={onShowAll}
                    sx={{
                        width: "100%",
                        padding: "10px",
                        textAlign: "center",
                    }}
                >
                    {t("view_all")}
                </Button>
            </Menu>
        </div>
    );
};

export default NotificationPopup;
