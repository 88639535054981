import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function handleRegistration(userData) {
    return Axios.post("/users/new_register_web", userData);
}

export default function useRegistration() {
    return useMutation(handleRegistration);
}



