import PropTypes from "prop-types";
import {Fragment, useState} from "react";

//* cookie
import cookies from "js-cookie";

//* Translation
import i18next from "i18next";

//* mui components imports
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

import "/node_modules/flag-icons/css/flag-icons.min.css";

//*  mui icons imports
// import LanguageIcon from "@mui/icons-material/Language";

//* For changing language
const languages = [
    {
        id: 1,
        code: "de",
        name: "Deutsch",
        country_code: "ch",
    },
    {
        id: 2,
        code: "en",
        name: "English",
        country_code: "gb",
    },
    // {
    //   id: 3,
    //   code: "it",
    //   name: "Italian",
    //   country_code: "it",
    // },
    // {
    //   id: 4,
    //   code: "fr",
    //   name: "Français",
    //   country_code: "fr",
    // },
];

function LanguageSelector({buttonSxProps}) {
    const {t} = useTranslation();
    const [langOpen, setLangOpen] = useState(null);

    const currentLanguageCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find(
        (lang) => lang.code === currentLanguageCode
    );
    const currentFlag = languages.find(
        (lang) => lang.code === currentLanguageCode
    );

    useEffect(() => {
        document.title = t("app_title");
    }, [currentLanguage, t]);

    const open = Boolean(langOpen);
    const handleClick = (event) => {
        setLangOpen(event.currentTarget);
    };

    const handleClose = () => {
        setLangOpen(null);
    };

    return (
        <Fragment>
            {/* Language Button Start */}
            <Button
                id="basic-button"
                variant="text"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableRipple
                onClick={handleClick}
                sx={{
                    textTransform: "uppercase",
                    ...buttonSxProps,
                }}
            >
                <Typography
                    component={"span"}
                    className={`fi fi-${currentFlag?.country_code}`}
                    sx={{mr: 1}}
                ></Typography>
                {currentLanguageCode}
            </Button>
            {/* Language Button End */}

            {/* Language Dropdown Start */}
            <Menu
                id="language-menu"
                anchorEl={langOpen}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "language-button",
                }}
            >
                {languages?.map((lang) => {
                    return (
                        <MenuItem
                            key={lang?.id}
                            onClick={() => {
                                i18next.changeLanguage(lang?.code);
                                handleClose();
                            }}
                            disabled={lang?.code === currentLanguageCode}
                        >
                            <Typography
                                component={"span"}
                                className={`fi fi-${lang?.country_code}`}
                                sx={{mr: 1}}
                            ></Typography>
                            {lang?.name}
                        </MenuItem>
                    );
                })}
            </Menu>
            {/* Language Dropdown End */}
        </Fragment>
    );
}

LanguageSelector.propTypes = {
    buttonSxProps: PropTypes.object,
};

export default LanguageSelector;
