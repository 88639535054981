const HIDE_NAVIGATION_LINKS_EDIT_FORM = [
    "/dossier-form/any-property",
    "/terms-conditions",
    "/privacy-policy",
    "/imprint"
];
/*
    "/about",
    "/partnership",
    "/contact",
    "/faq",
 */
export default HIDE_NAVIGATION_LINKS_EDIT_FORM;
