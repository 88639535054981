import React, { useEffect } from "react";

//*  using toast for success and error
import { toast, Zoom, Flip } from "react-toastify";

//* cookies
import cookies from "js-cookie";

//* react-hook-form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

//* api calls
import { useGetAdminInfoData } from "../../hooks/static-pages/useGetAdminInfoData";
import { useTranslation } from "react-i18next";

//* Schema
import { ContactSchema } from "../../forms-schemas/ContactSchema";

//* mui components imports
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useSendMail } from "../../hooks/send-email/useSendMail";
import {
    ControllerPhoneTextField,
    ControllerTextField,
} from "../../component/mui-components/MuiDossierFormComponents";
import { getFooterInfo, phoneNumber, showEmail } from "../../utils/getFooter";
import { socialMediaLinks } from "../../options/socialLinks";

function Contact() {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const { isLoading: isLoadingContactData, data } =
        useGetAdminInfoData(currentLanguageCode);

    const { isLoading, mutate } = useSendMail();

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(ContactSchema),
        defaultValues: {
            fullName: "",
            email: "",
            subject: "",
            phone: "",
            message: "",
        },
    });

    const submitForm = (data) => {
        let contactUsData = {
            name: data.fullName,
            email_from: data.email,
            email_to: "info@nextflat.ch",
            contact: data.phone?.length <= 3 ? "" : data.phone,
            email_subject: data.subject,
            message: data.message,
        };
        mutate(contactUsData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    toast.error(data?.data?.message, {
                        transition: Zoom,
                    });
                    return;
                }
                toast.success(
                    "Danke!",
                    {
                        transition: Flip,
                    }
                );

                reset();
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("something_went_wrong")}`);
            },
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 5 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <Stack spacing={3} py={3}>
                        <Stack spacing={1}>
                            <Typography variant="h3">
                                {t("get_in_touch")}
                            </Typography>
                            {isLoadingContactData ? (
                                <>
                                    <Skeleton
                                        animation="wave"
                                        sx={{ width: 300 }}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        sx={{ width: 300 }}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        sx={{ width: 300 }}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        sx={{ width: 150 }}
                                    />
                                </>
                            ) : (
                                <Typography variant="h6">
                                    {t("get_in_touch_subtext")}
                                </Typography>
                            )}
                        </Stack>
                        <Stack spacing={2}>
                            <Stack
                                direction={"row"}
                                spacing={4}
                                sx={{ placeItems: "center" }}
                            >
                                <HomeOutlinedIcon
                                    color="secondary"
                                    fontSize="large"
                                />
                                <Stack>
                                    <Typography
                                        variant="h5"
                                        sx={{ fontWeight: 400 }}
                                    >
                                        {t("reach_us")}
                                    </Typography>
                                    {isLoadingContactData ? (
                                        <Skeleton
                                            animation="wave"
                                            sx={{ width: 200 }}
                                        />
                                    ) : (
                                        <Typography>
                                            {/*
                                            {getFooterInfo(
                                                data,
                                                "admin_address"
                                            )}
                                            */}
                                            nextflat AG< br/>
                                            Riedmattstrasse 9< br/>
                                            8153 Rümlang
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                            <Stack
                                direction={"row"}
                                spacing={4}
                                sx={{ placeItems: "center" }}
                            >
                                <EmailOutlinedIcon
                                    color="secondary"
                                    fontSize="large"
                                />
                                <Stack>
                                    <Typography
                                        variant="h5"
                                        sx={{ fontWeight: 400 }}
                                    >
                                        {t("email")}
                                    </Typography>
                                    {isLoadingContactData ? (
                                        <>
                                            <Skeleton
                                                animation="wave"
                                                sx={{ width: 200 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                sx={{ width: 200 }}
                                            />
                                        </>
                                    ) : (
                                        <Typography>
                                            {showEmail(data)}
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                            <Stack
                                direction={"row"}
                                spacing={4}
                                sx={{ placeItems: "center" }}
                            >
                                <CallOutlinedIcon
                                    color="secondary"
                                    fontSize="large"
                                />
                                <Stack>
                                    <Typography
                                        variant="h5"
                                        sx={{ fontWeight: 400 }}
                                    >
                                        {t("call_us")}
                                    </Typography>
                                    {isLoadingContactData ? (
                                        <Skeleton
                                            animation="wave"
                                            sx={{ width: 120 }}
                                        />
                                    ) : (
                                        <Typography>
                                            {phoneNumber(data)}
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                            <Stack
                                    direction={"row"}
                                    spacing={3}
                                    sx={{ placeItems: "center" }}
                                >
                                    {socialMediaLinks.map((social) => (
                                        <Stack
                                            component={"a"}
                                            href={social.link}
                                            target="_blank"
                                        >
                                            <Typography
                                                color="secondary"
                                                fontSize="large"
                                            >
                                                {social.icon}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Paper
                        elevation={0}
                        sx={{
                            mt: 3,
                            p: 1,
                            borderRadius: 3,
                            minHeight: "380px",
                            boxShadow: "6px 6px 20px 0px #b4b2b240;",
                        }}
                    >
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12} md={6}>
                                <ControllerTextField
                                    control={control}
                                    name={"fullName"}
                                    label={t("name")}
                                    errorFlag={!!errors?.fullName}
                                    errorText={t(errors?.fullName?.message)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControllerTextField
                                    type={"email"}
                                    control={control}
                                    name={"email"}
                                    label={t("email")}
                                    errorFlag={!!errors?.email}
                                    errorText={t(errors?.email?.message)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControllerTextField
                                    control={control}
                                    name={"subject"}
                                    label={t("subject")}
                                    errorFlag={!!errors?.subject}
                                    errorText={t(errors?.subject?.message)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ControllerPhoneTextField
                                    control={control}
                                    name={"phone"}
                                    label={t("phone")}
                                    errorFlag={!!errors?.phone}
                                    errorText={t(errors?.phone?.message)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ControllerTextField
                                    control={control}
                                    name={"message"}
                                    label={t("message")}
                                    multiline
                                    minRows={6}
                                    maxRows={10}
                                    errorFlag={!!errors?.message}
                                    errorText={t(errors?.message?.message)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"flex-end"}
                            >
                                <Button
                                    onClick={handleSubmit(submitForm)}
                                    variant="contained"
                                >
                                    {isLoading ? t("send") + "..." : t("send")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {t("company_location")}
                    </Typography>
                    {isLoadingContactData ? (
                        <Skeleton
                            animation="wave"
                            sx={{ width: "100%", height: 500 }}
                        />
                    ) : (
                        <iframe
                            width="100%"
                            height={500}
                            style={{
                                border: 0,
                            }}
                            // src={getContactInfo("admin_map")}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2697.5023361083113!2d8.521384175988011!3d47.46063967117676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900ba567ec878b%3A0x9bd3965c750ede8d!2sNachmieter24%20-%20Nachmieteragentur%20%26%20Nextflat.ch!5e0!3m2!1sen!2sin!4v1682061547210!5m2!1sen!2sin"
                            // style='border:0'
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            title="map"
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

export default Contact;
