import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//* cookie
import cookies from "js-cookie";

//*  using toast for success and error
import { toast } from "react-toastify";

//* libraries import
// import { useNavigate } from "react-router-dom";

//* react-hook-form
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

//* api calls
import useLogin from "../../../hooks/Auth/useLogin";
import useIsSmartDossierPaid from "../../../hooks/transaction/useIsSmartDossierPaid";

//* store imports
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../store/StateProvider";

//* Schema
import { LoginSchema } from "../../../forms-schemas/auth-schemas/LoginSchema";

//* mui component import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

//* mui icon imports
import CheckIcon from "@mui/icons-material/Check";

//* context
import { useAuthModal } from "../../../context/ModalContext";

//* icons
import { useSocialLoginStatus } from "../../../hooks/social-login/useSocialLoginStatus";
import CustomGoogleIcon from "../../svg/CustomGoogleIcon";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function LoginForm() {
    const { t } = useTranslation();

    const currentLanguageCode = cookies.get("i18next") || "en";
    const navigate = useNavigate();
    const [{ user_id }] = useStateValue();
    const { handleAuthData } = useAuthModal();
    const theme = useTheme();
    const { mutate, isLoading } = useLogin();
    const { data: paymentStatusData, status: getPaymentDataStatus } =
        useIsSmartDossierPaid(user_id);
    let isPayment = paymentStatusData?.dossierPayment;

    const { closeAuthModal, handleAuthForms } = useAuthModal();
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const redirectToBrief = setTimeout(() => {
            user_id &&
                getPaymentDataStatus === "success" &&
                Boolean(!isPayment) &&
                navigate("/dossier-form/any-property");
        }, 800);

        return () => clearTimeout(redirectToBrief);
    }, [user_id, getPaymentDataStatus, isPayment, navigate]);

    const {
        //   isLoading: socialLoginLoading,
        refetch: socialLoginRefetch,
    } = useSocialLoginStatus();
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(LoginSchema),
        defaultValues: {
            email: "",
            checkbox: false,
        },
    });

    //! social login

    const google = () => {
        let timer = null;

        let googleUrl = `${process.env.REACT_APP_BASE_URL}/google`;

        let socialLoginWindow = window.open(
            googleUrl,
            "_blank",
            "width=500, height=600"
        );

        if (socialLoginWindow) {
            timer = setInterval(() => {
                if (socialLoginWindow.closed) {
                    socialLoginRefetch();
                    closeAuthModal();
                    if (timer) clearInterval(timer);
                }
            }, 500);
        }
    };

    const submitForm = (data) => {
        const { email } = data;
        let user = {
            email: data.email,
            language: currentLanguageCode,
            // password: data.password,
        };

        mutate(user, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    setError("email", {
                        type: "server",
                        message: "",
                    });

                    setError("email", {
                        type: "server",
                        message: `${t("you_entered_incorrect_email")}`,
                    });

                    return;
                }
                setSuccess(true);
                handleAuthData({ email });
                setTimeout(() => {
                    handleAuthForms("verify");
                    reset({
                        email: "",
                        password: "",
                    });
                }, 800);
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Stack alignItems={"center"}>
                    {/*
                    <Typography
                        textAlign={"center"}
                        variant="h4"
                        textTransform={"capitalize"}
                    >
                        {t("sign_in")}
                    </Typography>
                    */}
                    <Box display="flex" alignItems="center" gap={1} my={2}>
                        <EmailIcon
                            sx={{
                                color: theme.palette.primary.main,
                                ml: 0.5,
                                marginTop: "-3px",
                            }}
                        />
                        <Typography variant="body2">E-Mail</Typography>
                        <ArrowForwardIosIcon
                            sx={{
                                color: theme.palette.primary.main,
                                mx: 0.5,
                                marginTop: "-4px",
                            }}
                        />
                        <VpnKeyIcon
                            sx={{
                                color: theme.palette.primary.main,
                                ml: 0.5,
                                marginTop: "-4px",
                            }}
                        />
                        <Typography variant="body2">Code</Typography>
                        <ArrowForwardIosIcon
                            sx={{
                                color: theme.palette.primary.main,
                                mx: 1,
                                marginTop: "-3px",
                            }}
                        />
                        <PlayArrowIcon
                            sx={{
                                color: theme.palette.primary.main,
                                ml: 0.5,
                                transform: "translateY(-3px)",
                            }}
                        />
                        <Typography variant="body2">Los!</Typography>
                    </Box>
                    {/*
                    <Typography variant="body2">
                        {`${t("please_enter_your_details_to_sign_in")}.`}
                    </Typography>
                    */}
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Grid item container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="email"
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            type="email"
                                            label={t("email")}
                                            fullWidth
                                            //  variant="outlined"
                                            variant="filled"
                                            {...field}
                                            error={!!errors?.email}
                                            helperText={t(
                                                errors?.email?.message
                                            )}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ position: "relative" }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        ...(success && {
                                            bgcolor: green[500],
                                            "&:hover": {
                                                bgcolor: green[700],
                                            },
                                        }),
                                    }}
                                    style={{
                                        textDecoration: "none",
                                        fontSize: "1.25rem",
                                    }}
                                    fullWidth
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    {success ? (
                                        <CheckIcon
                                            sx={{
                                                color: theme.palette.common
                                                    .white,
                                            }}
                                        />
                                    ) : (
                                        t("sign_in")
                                    )}
                                </Button>

                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid item xs={12}>
                <div className="loginFormOrText" color={"secondary"}>
                    {t("or")}
                </div>
                {/* <div className="loginFormOrText">{t("or")}</div> */}
            </Grid>
            <Grid item xs={12}>
                <Stack flexDirection={"row"} justifyContent={"center"} gap={2}>
                    <Button
                        // variant="outlined"
                        variant="text"
                        color="secondary"
                        startIcon={<CustomGoogleIcon viewBox={"0 0 32 32"} />}
                        onClick={google}
                        style={{
                            color: theme.palette.secondary.main,
                            textDecoration: "none",
                            fontSize: "1.25rem",
                        }}
                    >
                        {t("sign_in")}
                    </Button>

                    {/* <Button
                        variant="text"
                        color="secondary"
                        startIcon={<CustomFacebookIcon viewBox={"0 0 16 16"} />}
                        // onClick={() => {}}
                    >
                        {t("sign_in")}
                    </Button> */}
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack
                    flexDirection={{ sm: "column", md: "row" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={1}
                >
                    <Typography variant="subtitle">
                        {t("dont_have_an_account") + "?"}
                    </Typography>
                    <Link
                        to="#"
                        style={{
                            color: theme.palette.secondary.main,
                            textDecoration: "none",
                        }}
                        onClick={() => handleAuthForms("register")}
                    >
                        {t("register_a_new_account")}
                    </Link>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default LoginForm;
