import React from "react";

//* libraries
import Carousel from "react-material-ui-carousel";

//* custom components
import { Img } from "../../../img/Img";

//* image imports
import { propertyPlaceholder } from "../../../../assets/imgImport";

const PropertyCarouselImages = ({
    propertyImages,
    altTags,
    url,
    indicators,
    swipe,
    animation,
    height,
    width,
    style,
}) => {
    if (
        propertyImages === "null" ||
        propertyImages === "undefined" ||
        propertyImages === null ||
        propertyImages === undefined
    ) {
        return (
            <Img
                src={propertyPlaceholder}
                height={height || "auto"}
                width={width || "100%"}
                style={style || { borderRadius: "2px" }}
                alt={altTags}
            />
        );
    }

    let propertyImageArray = Object.values(JSON.parse(propertyImages));

    return (
        <Carousel indicators={indicators} swipe={swipe} animation={animation}>
            {propertyImageArray?.map((img, index) => {
                return (
                    <div key={index}>
                        <Img
                            src={url + img}
                            height={height || "auto"}
                            width={width || "100%"}
                            style={style || { borderRadius: "2px" }}
                            alt={altTags + "_" + index}
                        />
                    </div>
                );
            })}
        </Carousel>
    );
};
export default PropertyCarouselImages;
