import PropTypes from "prop-types";

//* mui imports
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import IconWithText from "../content-box/IconWithText";

const ContainerBox = styled(Box, {
    shouldForwardProp: (props) => props !== "coloredBg",
})(({ theme, coloredBg }) => ({
    backgroundColor: coloredBg
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    borderRadius: "50px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        borderRadius: "30px",
    },
    // [theme.breakpoints.up("md")]: {
    //    minHeight: 720,
    // },
}));

const MainContainer = styled(Container)(() => ({
    "&.MuiContainer-root": {
        padding: 0,
    },
}));

const MuiGrid = styled(Grid, {
    shouldForwardProp: (props) =>
        props !== "benefits" && props !== "layoutDirection",
})(({ theme, benefits, layoutDirection }) => ({
    backgroundColor: benefits && theme.palette.primary.light,
    borderRadius: layoutDirection === "columnReverse" ? "30px 30px 0 0" : 0,
}));

const ImageWithContent = ({
    children,
    layoutDirection,
    image,
    coloredBg,
    benefits,
    t,
}) => {
    // const { isBelowMd } = useResponsive();

    return (
        <MainContainer maxWidth="xl">
            <ContainerBox coloredBg={coloredBg}>
                <Grid
                    container
                    {...(layoutDirection === "reverse"
                        ? { direction: "row-reverse" }
                        : layoutDirection === "columnReverse"
                        ? { direction: "column-reverse" }
                        : { direction: "row" })}
                    height={"100%"}
                >
                    <MuiGrid
                        item
                        xs={12}
                        md={6}
                        benefits
                        layoutDirection={layoutDirection}
                    >
                        {benefits ? (
                            <Stack
                                rowGap={3}
                                sx={{
                                    px: { xs: 3, lg: 12.5 },
                                    py: { xs: 4, lg: 12.5 },
                                }}
                            >
                                <Stack rowGap={1}>
                                    <Typography variant="h3">
                                        {t("your_benefits")}
                                    </Typography>
                                    <Typography variant="body1">
                                        {t(
                                            "the_smart_dossier_offers_a_variety_of_benefit"
                                        )}
                                    </Typography>
                                </Stack>
                                <Stack rowGap={2}>
                                    {benefits.map((benefit) => {
                                        return (
                                            <IconWithText
                                                key={benefit.id}
                                                icon={benefit.icon}
                                                title={benefit.title}
                                                subTitle={benefit.subtitle}
                                                position={"right"}
                                                size={50}
                                                bgcolor={"primary"}
                                            />
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        ) : (
                            <img
                                src={image}
                                alt=""
                                style={{
                                    height: 720,
                                    width: "100%",
                                    objectFit: "cover",
                                    aspectRatio: 16 / 17,
                                }}
                            />
                            // <picture>
                            //     <img
                            //         sizes="(max-width: 667px) 100vw, 667px"
                            //         srcSet="download_app_dwjgh0_c_scale,w_200.png 200w,download_app_dwjgh0_c_scale,w_295.png 295w,download_app_dwjgh0_c_scale,w_369.png 369w,download_app_dwjgh0_c_scale,w_438.png 438w,download_app_dwjgh0_c_scale,w_495.png 495w,download_app_dwjgh0_c_scale,w_547.png 547w,download_app_dwjgh0_c_scale,w_595.png 595w,download_app_dwjgh0_c_scale,w_628.png 628w,download_app_dwjgh0_c_scale,w_659.png 659w,download_app_dwjgh0_c_scale,w_667.png 667w"
                            //         src="download_app_dwjgh0_c_scale,w_667.png"
                            //         alt=""
                            //     />
                            // </picture>
                        )}
                    </MuiGrid>
                    <Grid item xs={12} md={6}>
                        <Box height={"100%"}>{children}</Box>
                    </Grid>
                </Grid>
            </ContainerBox>
        </MainContainer>
    );
};

ImageWithContent.propTypes = {
    children: PropTypes.node,
    coloredBg: PropTypes.bool,
    image: PropTypes.string,
    OPTION: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            icon: PropTypes.string,
            title: PropTypes.string,
            subtitle: PropTypes.string,
        })
    ),
    layoutDirection: PropTypes.string,
    t: PropTypes.any,
};

export default ImageWithContent;
