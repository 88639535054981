import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    return localStorage.getItem("nch_id") !== null &&
        localStorage.getItem("nch_user_data") !== null ? (
        children
    ) : (
        <>
            <Navigate replace to="/"></Navigate>
        </>
    );
};

export default PrivateRoute;
