import React, {
    Fragment,
    useCallback,
    // useEffect,
    useMemo,
    useState,
} from "react";

//*  using toast for success and error
import { toast } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* react-hook-form
import { useFormContext } from "react-hook-form";

import {
    //  Dropzone,
    useDropzone,
} from "react-dropzone";

//* store imports
import { useStateValue } from "../../../../store/StateProvider";

//* api calls
import useAddProfileDocument from "../../../../hooks/profile/useAddProfileDocument";

//* mui components import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//* mui icon imports
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { truncate } from "../../../../utils/truncateWord";

const videoBaseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#000",
    borderStyle: "dashed",
    backgroundColor: "#d3dae363",
    height: "160px",
    minWidth: "160px",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

export const TenantVideo = () => {
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const {
        control,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();
    const { isLoading, mutate } = useAddProfileDocument();

    const onDropVideo = useCallback(
        (droppedFiles) => {
            const formData = new FormData();

            if (droppedFiles?.length > 0) {
                formData.append("id", user_id);
                formData.append("key", "smartVideo");
                formData.append("image", droppedFiles[0]);
                formData.append("description", "Smart Dossier Video");

                mutate(formData, {
                    onSuccess: (data) => {
                        if (!data?.data?.statuscode) {
                            return;
                        }
                        let uploadedVideo = data?.data?.img_name;
                        toast.success("Video uploaded successfully");
                        setValue("video", uploadedVideo);
                    },
                    onError: (errors) => {
                        toast.error(
                            `${t("we_are_facing_some_technical_issue")}`
                        );
                        console.error(errors);
                    },
                });
            } else {
                toast.warning("Please upload a  video (.mp4 or webm) file");
            }
        },
        [mutate, setValue, t, user_id]
    );

    const {
        acceptedFiles: acceptedVideoFiles,
        fileRejections: videoFileRejections,
        getRootProps: getVideoRootProps,
        getInputProps: getVideoInputProps,
        isFocused: isVideoFocused,
        isDragAccept: isVideoDragAccept,
        isDragReject: isVideoDragReject,
    } = useDropzone({
        onDrop: onDropVideo,
        accept: {
            "video/*": [
                ".mp4",
                // ".x-msvideo",
                // ".x-ms-wmv",
                // ".quicktime",
                // ".x-flv",
                ".webm",
            ],
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 50 * 1024 * 1024,
    });

    const videoStyle = useMemo(
        () => ({
            ...videoBaseStyle,
            ...(isVideoFocused ? focusedStyle : {}),
            ...(isVideoDragAccept ? acceptStyle : {}),
            ...(isVideoDragReject ? rejectStyle : {}),
        }),
        [isVideoFocused, isVideoDragAccept, isVideoDragReject]
    );

    const watchVideo = watch("video");
  

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box>
                <div {...getVideoRootProps({ style: videoStyle })}>
                    {isLoading ? (
                        `${t("uploading_video")}...`
                    ) : (
                        <Fragment>
                            <input {...getVideoInputProps()} />
                            {watchVideo === null ? (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "5px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "160px",
                                    }}
                                >
                                    <VideoFileIcon fontSize="large" />
                                    <Typography variant="subtitle2">
                                        Drag &apos;n&apos; drop or click to
                                        select image
                                    </Typography>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "5px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "160px",
                                        width: "100%",
                                    }}
                                >
                                    {/* {acceptedVideoFiles?.length > 0 && acceptedVideoFileItems}
        {videoFileRejections?.length > 0 && videoFileRejectionItems} */}
                                    <VideoFileIcon
                                        fontSize="large"
                                        sx={{ color: "#123763" }}
                                    />
                                    <Typography variant="subtitle1">
                                        {t("uploaded_video")}
                                    </Typography>
                                    <Typography variant="body1">
                                        {truncate(watchVideo, 30, 0, 30)}
                                    </Typography>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
            </Box>
            {watchVideo === null ? (
                <p
                    style={{
                        color: "GrayText",
                        fontSize: "12px",
                    }}
                >
                    {t("Click or drag'n'drop")}
                </p>
            ) : (
                <p
                    style={{
                        color: "GrayText",
                        fontSize: "12px",
                    }}
                >
                    {t("click_or_drag_drop_to_update")}
                </p>
            )}
        </Box>
    );
};
