import * as yup from "yup";

const twelveYearInMilliSeconds = 16 * 365 * 24 * 60 * 60 * 1000;

const step0Section0Schema = yup.object().shape({
    salutation: yup.string().nullable().required("please_select_your_gender"),
    otherTitle: yup.string().when("title", {
        is: "other",
        then: yup.string().required("please_enter_your_title"),
    }),
    firstName: yup
        .string()
        .required("please_enter_your_first_name")
        .min(2, "name_should_be_more_than_2_characters")
        .max(255, "name_can_not_exceed_200_characters"),
    lastName: yup
        .string()
        .required("please_enter_your_last_name")
        .min(2, "name_should_be_more_than_2_characters")
        .max(255, "name_can_not_exceed_200_characters"),
    dateOfBirth: yup
        .date()
        .nullable()
        .typeError("please_enter_a_valid_date")
        .required("please_enter_your_year_of_birth")
        .max(
            new Date(Date.now() - twelveYearInMilliSeconds),
            "your_age_should_be_more_than_19"
        ),
    nationality: yup.mixed().required("please_select_your_nationality"),
    residencyPermit: yup.string().when("nationality", {
        is: (nationality) => {
            let country =
                nationality && typeof nationality === "string"
                    ? nationality
                    : nationality?.value;
            return country?.toLowerCase() !== "switzerland";
        },
        then: yup
            .string()
            .nullable()
            .required("please_select_residency_permit"),
    }),
    dateOfEntry: yup
        .mixed()
        .nullable()
        .when("nationality", {
            is: (nationality) => {
                let countryName =
                    nationality && typeof nationality === "string"
                        ? nationality
                        : nationality?.value;
                return countryName?.toLowerCase() !== "switzerland";
            },
            then: yup.mixed().nullable().required("please_enter_date_of_entry"),
        }),
});

export default step0Section0Schema;
