import React from "react";

//* mui components import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

//* mui icons imports
import PlaceIcon from "@mui/icons-material/Place";

function FeaturedPropertySkeleton() {
    return (
        <Paper
            sx={{
                p: 1.25,
                borderRadius: 5,
            }}
            elevation={0}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sx={{ position: "relative", height: 260, width: "100%" }}
                >
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="250px"
                        sx={{
                            borderRadius: "8px",
                            outline: (theme) =>
                                `${theme.palette.primary.main} solid 1px`,
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: 30,
                            width: "95%",
                            zIndex: 1,
                            px: 1.875,
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Skeleton
                                width="40%"
                                height="45px"
                                sx={{ bgcolor: "rgba(238,248,254,0.7)" }}
                            />

                            <Skeleton
                                variant="circular"
                                height={30}
                                width={30}
                                sx={{ bgcolor: "rgba(238,248,254,0.7)" }}
                            />
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Stack spacing={1}>
                        <Skeleton width="55%" height="28px" />
                        <Stack direction="row" alignItems={"center"}>
                            <PlaceIcon color="secondary" />
                            <Skeleton width="15%" height="24px" />
                        </Stack>

                        <Skeleton width="15%" height="45px" />
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default FeaturedPropertySkeleton;
