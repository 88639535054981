import React, {
    // forwardRef,
    useState,
} from "react";

//*  using toast for success and error
import { toast } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* store imports
import { useStateValue } from "../../../../store/StateProvider";
import { useNavigate } from "react-router-dom";

//* api calls
import { useAddWishlist } from "../../../../hooks/properties/useWishlist";

//* model component
import ShareModal from "../../../modal/properties/ShareModal";

//* mui components import
// import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EditIcon from "@mui/icons-material/Edit";

//* mui icons imports
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
// import CloseIcon from "@mui/icons-material/Close";
import Undo from "@mui/icons-material/Undo";
import Grid from "@mui/material/Grid";

import { Tooltip } from "@mui/material";

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />;
// });

function PropertyHeading({
    propertyPrice,
    livingSpace,
    streetNo,
    advertiserName,
    advertiserPhone,
    advertiserProfile,
    propertyType,
    period,
    imageUrl,
    propertyName,
    propertyId,
    isPreview,
    isFav,
    propertyOwnerId,
    userId,
}) {
    //console.log("🚀 ~ isFav:", isFav);
    const { t } = useTranslation();
    const [{ user_id, token }] = useStateValue();
    const { mutate } = useAddWishlist();
    const [isFavoriteProperty, setIsFavoriteProperty] = useState(
        Boolean(isFav)
    );

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addToWishlist = (property_id) => {
        if (token === null) {
            return toast.info("Please login to add a property to favorite");
        }

        let userData = {
            property_id,
            user_id,
        };
        setIsFavoriteProperty(!isFavoriteProperty);
        mutate(userData, {
            onSuccess: (data) => {
                // setIsFavId(data?.data?.data);
                //
                if (!data?.data?.statuscode) return;
                // toast.success(data?.data?.message);
            },
            onError: (errors) => {
                // toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    const handleNavigate = (path, context) => {
        navigate({
            pathname: path,
            search: `?context=${context}`,
        });
    };
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const BackIcon = <Undo sx={{ fontSize: "2.4rem" }} />;
    const ShareIcon = <IosShareIcon sx={{ fontSize: "2.4rem" }} />;
    const FavoriteIconStyled = <FavoriteIcon sx={{ fontSize: "2.4rem" }} />;
    const FavoriteBorderIconStyled = (
        <FavoriteBorderIcon sx={{ fontSize: "2.4rem" }} />
    );

    //console.log(propertyOwnerId, userId);
    return (
        <>
            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
            >
                <Grid
                    item
                    sx={{
                        cursor: "pointer",
                        justifyContent: "left",
                        display: "flex",
                    }}
                >
                    <Tooltip title={t("back")}>
                        <Stack
                            direction={"row"}
                            alignItems={"left"}
                            spacing={1}
                            sx={{
                                cursor: "pointer",
                                justifyContent: "left",
                            }}
                            onClick={() => navigate(-1)}
                        >
                            {<Undo sx={{ fontSize: "2rem" }} />}
                        </Stack>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <Tooltip title={t("share")}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                            sx={{
                                cursor: "pointer",
                                justifyContent: "center",
                            }}
                            onClick={handleClickOpen}
                        >
                            {<IosShareIcon sx={{ fontSize: "2rem" }} />}
                        </Stack>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <Tooltip
                        title={t(
                            isFavoriteProperty
                                ? "saved"
                                : "add_to_favorite_list"
                        )}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                            sx={{
                                cursor: "pointer",
                                justifyContent: "center",
                            }}
                            onClick={() => addToWishlist(propertyId)}
                        >
                            {isFavoriteProperty ? (
                                <FavoriteIcon sx={{ fontSize: "2rem" }} />
                            ) : (
                                <FavoriteBorderIcon sx={{ fontSize: "2rem" }} />
                            )}
                        </Stack>
                    </Tooltip>
                </Grid>

                {userId == propertyOwnerId && (
                    <Grid item>
                        <Tooltip title={t("edit")}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                                sx={{
                                    cursor: "pointer",
                                    justifyContent: "right",
                                }}
                                onClick={() =>
                                    handleNavigate(
                                        `/add-property-details/${propertyId}`,
                                        "fs-flats"
                                    )
                                }
                            >
                                <EditIcon
                                    color="primary"
                                    sx={{ fontSize: "2rem" }}
                                />
                                {/*
                                <Typography variant="body1">{t("edit")}</Typography>
                                */}
                            </Stack>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>

            <ShareModal
                fullScreen={fullScreen}
                open={open}
                handleClose={handleClose}
            />
        </>
    );
}

export default PropertyHeading;
