import React from "react";
import ReactDOM from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { StateProvider } from "./store/StateProvider";
import reducer, { initialState } from "./store/reducer";
import ThemeProvider from "./theme/index.jsx";

import App from "./App";
import "./index.css";
import { AuthModalProvider } from "./context/ModalContext";
import { AuthProvider } from "./context/AuthContext";

//* https://codetain.com/blog/how-to-translate-react-application-with-react-i18next-part-2
i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        fallbackLng: "de",
        detection: {
            order: ["localStorage", "cookie", "htmlTag"],
            caches: ["localStorage", "cookie"],
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
    });

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <StateProvider initialState={initialState} reducer={reducer}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <ThemeProvider>
                            <AuthModalProvider>
                                <App />
                            </AuthModalProvider>
                            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                        </ThemeProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </StateProvider>
        </AuthProvider>
    </React.StrictMode>
);
