import React from "react";

//* translation
import { useTranslation } from "react-i18next";
//* cookie
import cookies from "js-cookie";
//* react-hook-form
import { useFormContext } from "react-hook-form";

//* custom components
import {
    ControllerDateField,
    ControllerRadioField,
    ControllerTextField,
} from "../../../mui-components/MuiDossierFormComponents";

// Custom options
import { tenantGender, tenantTitle } from "../../../../options/dossierOptions";

//* mui components import

// import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

// mui icons imports
import { isGreaterThanYear } from "../../../../utils/isGreaterThanYear";

function BriefPersonalDetail() {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    const title = watch("title");

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6" textAlign={"center"}>
                    {t("personal_details")}
                </Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} md={6}>
                    <ControllerRadioField
                        name="salutation"
                        label={t("salutation")}
                        control={control}
                        errorFlag={!!errors?.salutation}
                        errorText={t(errors?.salutation?.message)}
                    >
                        {tenantGender.map((gender) => (
                            <FormControlLabel
                                key={gender.id}
                                label={t(gender.label)}
                                value={gender.value}
                                control={<Radio />}
                            />
                        ))}
                    </ControllerRadioField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControllerRadioField
                        name="title"
                        label={t("title")}
                        control={control}
                    >
                        {tenantTitle.map((gender) => (
                            <FormControlLabel
                                key={gender.id}
                                label={t(gender.label)}
                                value={gender.value}
                                control={<Radio />}
                            />
                        ))}
                    </ControllerRadioField>
                    {title === "other" && (
                        <ControllerTextField
                            control={control}
                            name="otherTitle"
                            // title={t("dossier_first_name_info")}
                            label={t("title")}
                            errorFlag={!!errors?.otherTitle}
                            // errorText={t(errors?.otherTitle?.message)}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} md={6}>
                    <ControllerTextField
                        control={control}
                        name="firstName"
                        // title={t("dossier_first_name_info")}
                        label={t("first_name")}
                        errorFlag={!!errors?.firstName}
                        errorText={t(errors?.firstName?.message)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControllerTextField
                        control={control}
                        name="lastName"
                        label={t("last_name")}
                        errorFlag={!!errors?.lastName}
                        errorText={t(errors?.lastName?.message)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ControllerDateField
                        control={control}
                        name="dateOfBirth"
                        disableFuture
                        openTo={"year"}
                        shouldDisableYear={(date) =>
                            isGreaterThanYear(date, 18)
                        }
                        label={t("birth_date")}
                        // errorFlag={!!errors?.dateOfBirth}
                        // errorText={t(errors?.dateOfBirth?.message)}
                        lang={currentLanguageCode}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BriefPersonalDetail;

// minDate={moment().subtract(500, "years")}
