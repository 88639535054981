import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerRadioField,
    ControllerTextField,
    ControllerDateField,
    ControllerSelectField,
    TitleSpacer,
    ControllerPhoneTextField,
    ControllerSliderTextField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import {
    employmentRelationshipArr,
    isEmployedOption,
    isYesNoOption,
    rentPaymentOption,
    unemploymentStatusOption,
    workload,
} from "../../../../../../options/dossierOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { isGreaterThanYear } from "../../../../../../utils/isGreaterThanYear";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import AnnualSalaryRangeSelect from "../../../../../mui-components/AnnualSalaryRangeSelect";
import { DossierReferenceBox } from "../../../dossier_components/DossierReferenceBox";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";
import ControllerSliderComponent from "../../../dossier_components/ControllerSliderComponent";

const TenantTwoSegmentFour = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        watchTenantTwoUnemployedStatus,
        watchTenantTwoRentPaymentMethod,
        watchPartnerEmployed,
        referenceStatus,
        handleReferenceStatus,
        setValue,
        control,
        errors,
        sectionVariants,
        direction,
    } = additionalProps;

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid item container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="tenantTwoEmployedOrNot"
                            label={t("are_you_employed") + "*"}
                            data={isEmployedOption}
                            errorFlag={!!errors.tenantTwoEmployedOrNot}
                            errorText={
                                errors.tenantTwoEmployedOrNot
                                    ? errors.tenantTwoEmployedOrNot.message
                                    : ""
                            }
                        />

                        {/*
                        <Controller
                            name="tenantTwoEmployedOrNot"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    variant="filled"
                                    select
                                    {...field}
                                    label={`${t("are_you_employed")}?*`}
                                    SelectProps={{
                                        IconComponent:
                                        ArrowDropDownCircleOutlinedIcon,
                                    }}
                                    fullWidth
                                    error={
                                        !!errors?.tenantTwoEmployedOrNot
                                    }
                                    helperText={t(
                                        errors?.tenantTwoEmployedOrNot
                                            ?.message
                                    )}
                                >
                                    {isEmployedOption.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                                */}
                    </Grid>
                </Grid>

                {watchPartnerEmployed === "no" && (
                    <Fragment>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="tenantTwoUnemploymentStatus"
                                label={t("what_is_your_status") + "*"}
                                data={unemploymentStatusOption}
                                errorFlag={
                                    !!errors?.tenantTwoUnemploymentStatus
                                }
                                errorText={t(
                                    errors?.tenantTwoUnemploymentStatus?.message
                                )}
                            />

                            {/*

                            <ControllerSelectField
                                control={control}
                                name="tenantTwoUnemploymentStatus"
                                label={`
                                    ${t("what_is_your_status")}?*
                                `}
                                errorFlag={
                                    !!errors?.tenantTwoUnemploymentStatus
                                }
                                errorText={t(
                                    errors
                                        ?.tenantTwoUnemploymentStatus
                                        ?.message
                                )}
                            >
                                {unemploymentStatusOption?.map(
                                    (option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {t(option.label)}
                                        </MenuItem>
                                    )
                                )}
                            </ControllerSelectField>
                                 */}
                        </Grid>
                        {watchTenantTwoUnemployedStatus ===
                            "other_unemployed" && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerTextField
                                    control={control}
                                    name="tenantTwoOtherEmploymentStatus"
                                    label={t("status") + "*"}
                                    errorFlag={
                                        !!errors?.tenantTwoOtherEmploymentStatus
                                    }
                                    errorText={t(
                                        errors?.tenantTwoOtherEmploymentStatus
                                            ?.message
                                    )}
                                />
                            </Grid>
                        )}
                        {watchTenantTwoUnemployedStatus === "self_employed" ? (
                            <Fragment>
                                {/* if self-employed */}

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="tenantTwoSelfEmployedCompanyName"
                                        label={t("name_of_company") + "*"}
                                        errorFlag={
                                            !!errors?.tenantTwoSelfEmployedCompanyName
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoSelfEmployedCompanyName
                                                ?.message
                                        )}
                                    />
                                </Grid>
                                {/* address of company */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="tenantTwoSelfEmployedCompanyAddress"
                                        label={t("address_of_company") + "*"}
                                        errorFlag={
                                            !!errors?.tenantTwoSelfEmployedCompanyAddress
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoSelfEmployedCompanyAddress
                                                ?.message
                                        )}
                                    />
                                </Grid>
                                {/* employed there since */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerDateField
                                        name={"tenantTwoSelfEmployedThereSince"}
                                        control={control}
                                        label={t("self_employed_since")}
                                        lang={currentLanguageCode}
                                    />
                                </Grid>
                                {/* monthly income  */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="tenantTwoSelfEmployedIncome"
                                        label={`${t("monthly_mid_income")}*`}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    CHF
                                                </InputAdornment>
                                            ),
                                        }}
                                        errorFlag={
                                            !!errors?.tenantTwoSelfEmployedIncome
                                        }
                                        errorText={t(
                                            errors?.tenantTwoSelfEmployedIncome
                                                ?.message
                                        )}
                                    />
                                    <Typography variant="caption" ml={2}>
                                        {t(
                                            "please_add_tax_assessment_to_your_documents_later"
                                        )}
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <ControllerToggleButtonsComponent
                                            control={control}
                                            name="tenantTwoHowYouPay"
                                            label={
                                                t("how_do_you_pay_your_rent") +
                                                "*"
                                            }
                                            data={rentPaymentOption}
                                            errorFlag={
                                                !!errors?.tenantTwoHowYouPay
                                            }
                                            errorText={t(
                                                errors?.tenantTwoHowYouPay
                                                    ?.message
                                            )}
                                        />

                                        {/*

                                        <ControllerSelectField
                                            control={control}
                                            name="tenantTwoHowYouPay"
                                            label={`${t(
                                                "how_do_you_pay_your_rent"
                                            )}?*`}
                                            errorFlag={
                                                !!errors?.tenantTwoHowYouPay
                                            }
                                            errorText={t(
                                                errors
                                                    ?.tenantTwoHowYouPay
                                                    ?.message
                                            )}
                                        >
                                            {rentPaymentOption?.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={
                                                            option.value
                                                        }
                                                        value={
                                                            option.value
                                                        }
                                                    >
                                                        {t(
                                                            option.label
                                                        )}
                                                    </MenuItem>
                                                )
                                            )}
                                        </ControllerSelectField>
                                                */}
                                    </Grid>
                                    {watchTenantTwoRentPaymentMethod ===
                                        "additional_solidarity_liability" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            sx={{ mt: 4 }}
                                        >
                                            <ControllerTextField
                                                control={control}
                                                name="tenantTwoLiablePerson"
                                                label={
                                                    t("who_is_this_person") +
                                                    "*"
                                                }
                                                errorFlag={
                                                    !!errors?.tenantTwoLiablePerson
                                                }
                                                errorText={t(
                                                    errors
                                                        ?.tenantTwoLiablePerson
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Fragment>
                            </Fragment>
                        )}
                        {watchTenantTwoUnemployedStatus !== "self_employed" && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerSliderTextField
                                    control={control}
                                    name="tenantTwoCoveredRentAmount"
                                    label={t("covered_rent_amount")}
                                    renderSlider
                                    SliderComponent={ControllerSliderComponent}
                                    sliderProps={{
                                        label: t("covered_rent_amount"),
                                        min: 400,
                                        max: 8000,
                                        step: 50,
                                    }}
                                    errorFlag={
                                        !!errors?.tenantTwoCoveredRentAmount
                                    }
                                    errorText={t(
                                        errors?.tenantTwoCoveredRentAmount
                                            ?.message
                                    )}
                                />

                                {/*

                                    <ControllerTextField
                                        control={control}
                                        name="tenantTwoCoveredRentAmount"
                                        label={`${t(
                                            "covered_rent_amount"
                                        )}`}
                                        errorFlag={
                                            !!errors?.tenantTwoCoveredRentAmount
                                        }
                                        errorText={t(
                                            errors
                                                ?.tenantTwoCoveredRentAmount
                                                ?.message
                                        )}
                                    />
                                          */}
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TitleSpacer
                                label={t("request_reference_title_employer")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoReferenceName"
                                label={t("reference_name")}
                                errorFlag={!!errors?.tenantTwoReferenceName}
                                errorText={t(
                                    errors?.tenantTwoReferenceName?.message
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoReferenceEmail"
                                type="email"
                                label={t("reference_email")}
                                errorFlag={!!errors?.tenantTwoReferenceEmail}
                                errorText={t(
                                    errors?.tenantTwoReferenceEmail?.message
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerPhoneTextField
                                control={control}
                                name="tenantTwoReferencePhone"
                                label={t("reference_phone")}
                                errorFlag={!!errors?.tenantTwoReferencePhone}
                                errorText={t(
                                    errors?.tenantTwoReferencePhone?.message
                                )}
                            />
                        </Grid>
                    </Fragment>
                )}

                {watchPartnerEmployed === "yes" && (
                    <Fragment>
                        <Grid container spacing={5} sx={{ px: 1, mx: -1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerToggleButtonsComponent
                                    control={control}
                                    name="tenantTwoEmploymentRelationship"
                                    label={t("employment_relationship") + "*"}
                                    data={employmentRelationshipArr}
                                />

                                {/*
                                <ControllerRadioField
                                    name={
                                        "tenantTwoEmploymentRelationship"
                                    }
                                    label={t("employment_relationship")}
                                    control={control}
                                >
                                    {employmentRelationshipArr.map(
                                        (employ_relationship) => (
                                            <FormControlLabel
                                                key={
                                                    employ_relationship.id
                                                }
                                                label={t(
                                                    employ_relationship.label
                                                )}
                                                value={
                                                    employ_relationship.value
                                                }
                                                control={<Radio/>}
                                            />
                                        )
                                    )}
                                </ControllerRadioField>
                                */}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerToggleButtonsComponent
                                    control={control}
                                    name="tenantTwoWorkloadPercent"
                                    label={`${t("workload")} ${t("in")} % `}
                                    data={workload}
                                    minWidth="84px"
                                    errorFlag={
                                        !!errors.tenantTwoWorkloadPercent
                                    }
                                    errorText={
                                        errors.tenantTwoWorkloadPercent
                                            ? errors.tenantTwoWorkloadPercent
                                                  .message
                                            : ""
                                    }
                                />
                                {/*
                                <Controller
                                    name="tenantTwoWorkloadPercent"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            variant="filled"
                                            select
                                            {...field}
                                            id="demo-simple-select-helper"
                                            label={`${t(
                                                "workload"
                                            )} ${t("in")} % `}
                                            SelectProps={{
                                                IconComponent:
                                                ArrowDropDownCircleOutlinedIcon,
                                            }}
                                            fullWidth
                                            error={
                                                !!errors?.tenantTwoWorkloadPercent
                                            }
                                            helperText={t(
                                                errors
                                                    ?.tenantTwoWorkloadPercent
                                                    ?.message
                                            )}
                                        >
                                            {workload.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label + "%"}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                                */}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerDateField
                                name={"tenantTwoEmployedThereSince"}
                                control={control}
                                label={t("employed_there_since")}
                                errorFlag={
                                    !!errors?.tenantTwoEmployedThereSince
                                }
                                errorText={t(
                                    errors?.tenantTwoEmployedThereSince?.message
                                )}
                                lang={currentLanguageCode}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoProfession"
                                label={t("profession") + "*"}
                                errorFlag={!!errors?.tenantTwoProfession}
                                errorText={t(
                                    errors?.tenantTwoProfession?.message
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Controller
                                control={control}
                                name="tenantTwoAnnualSalaryRangeId"
                                render={({ field }) => {
                                    return (
                                        <AnnualSalaryRangeSelect
                                            error={
                                                !!errors?.tenantTwoAnnualSalaryRangeId
                                            }
                                            helperText={t(
                                                errors
                                                    ?.tenantTwoAnnualSalaryRangeId
                                                    ?.message
                                            )}
                                            textFieldProps={field}
                                            onSelect={(value) =>
                                                setValue(
                                                    "annualSalaryRange",
                                                    value
                                                )
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        {watchPartnerEmployed === "yes" && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerToggleButtonsComponent
                                    control={control}
                                    name="tenantTwoHasEmploymentTerminated"
                                    label={
                                        t(
                                            "employment_relationship_terminated"
                                        ) + "*"
                                    }
                                    data={isYesNoOption}
                                />
                                {/*

                                <ControllerRadioField
                                    control={control}
                                    name="tenantTwoHasEmploymentTerminated"
                                    label={`${t(
                                        "employment_relationship_terminated"
                                    )}`}
                                >
                                    <FormControlLabel
                                        label={t("no")}
                                        value="no"
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        label={t("yes")}
                                        value="yes"
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        label={t(
                                            "no_specification"
                                        )}
                                        value="no_specification"
                                        control={<Radio/>}
                                    />
                                </ControllerRadioField>
                                */}
                            </Grid>
                        )}

                        {/* company section splitter */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TitleSpacer label={t("current_address")} />
                        </Grid>

                        {/* name of company */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoCompanyName"
                                label={t("name_of_company") + "*"}
                                errorFlag={!!errors?.tenantTwoCompanyName}
                                errorText={t(
                                    errors?.tenantTwoCompanyName?.message
                                )}
                            />
                        </Grid>
                        {/* address of company */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoCompanyAddress"
                                label={t("address_of_company") + "*"}
                                errorFlag={!!errors?.tenantTwoCompanyAddress}
                                errorText={t(
                                    errors?.tenantTwoCompanyAddress?.message
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoEmployeeName"
                                label={t("name_of_contact_person") + "*"}
                                errorFlag={!!errors?.tenantTwoEmployeeName}
                                errorText={t(
                                    errors?.tenantTwoEmployeeName?.message
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoEmployeeEmail"
                                label={t("email_of_contact_person") + "*"}
                                type="email"
                                errorFlag={!!errors?.tenantTwoEmployeeEmail}
                                errorText={t(
                                    errors?.tenantTwoEmployeeEmail?.message
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerPhoneTextField
                                control={control}
                                name="tenantTwoEmployeePhoneNo"
                                label={t("phone_of_contact_person") + "*"}
                                errorFlag={!!errors?.tenantTwoEmployeePhoneNo}
                                errorText={t(
                                    errors?.tenantTwoEmployeePhoneNo?.message
                                )}
                            />
                        </Grid>
                    </Fragment>
                )}

                {/* company reference section splitter */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TitleSpacer
                        label={t("request_reference_title_employer")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DossierReferenceBox
                        heading={t(
                            "you_have_chosen_the_verified_version_of_our_smart_dossier_employer_reference"
                        )}
                        buttonTextOne={t("request")}
                        buttonTextTwo={t("employer_reference")}
                        buttonTextThree={t("NOW!")}
                        buttonFor={"employer"}
                        status={referenceStatus?.tenantTwoEmployer}
                        tenantRole={"tenantTwo"}
                        isDisabled={referenceStatus?.tenantTwoEmployer !== ""}
                        handleReferenceStatus={handleReferenceStatus}
                    />
                </Grid>
            </Grid>
        </DossierFormSection>
    );
};

export default TenantTwoSegmentFour;
