/*
export const getName = (userFirstName, userLastName, user_email) => {
    if (userFirstName === "" || userLastName === "") {
        if (user_email?.length > 15) {
            return user_email?.substring(0, 14) + "...";
        }
        return user_email;
    }
    let firstName =
        userFirstName?.charAt(0)?.toUpperCase() + userFirstName?.slice(1);
    let lastName =
        userLastName?.charAt(0)?.toUpperCase() + userLastName?.slice(1);
    return `${firstName} ${lastName}`;
};

*/
export const getName = (userFirstName, userLastName, user_email) => {
    if (userFirstName === "" || userLastName === "") {
        if (user_email?.length > 15) {
            return user_email?.substring(0, 14) + "...";
        }
        return user_email;
    }
    let firstName =
        userFirstName?.charAt(0)?.toUpperCase() + userFirstName?.slice(1);
    let lastName =
        userLastName?.charAt(0)?.toUpperCase() + userLastName?.slice(1);
    return `${firstName}`;
};
