//* mui imports

// import ButtonBase from "@mui/material/ButtonBase";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
    //  styled,
    useTheme,
} from "@mui/material";

import ImageWithContent from "../layouts/ImageWithContent";
import { useTranslation } from "react-i18next";
import { DownloadApps } from "../../utils/imgImports";

const DownloadApp = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <ImageWithContent layoutDirection={"reverse"} image={DownloadApps}>
            <Stack
                justifyContent={"center"}
                height={"100%"}
                sx={{ px: { xs: 3, md: 6, lg: 10 }, py: { xs: 5, md: 6 } }}
                rowGap={5}
            >
                <Stack spacing={2.5}>
                    <Typography
                        variant="h4"
                        color={theme.palette.common.white}
                        width={"95%"}
                    >
                        {t("download_our_nextflat_app_coming")}
                    </Typography>
                    <Typography
                        variant="body1"
                        color={theme.palette.common.white}
                    >
                        {t("create_the_smart_dossier_on_your_smartphone")}
                    </Typography>
                </Stack>
            </Stack>
        </ImageWithContent>
    );
};

export default DownloadApp;
