const HIDE_NAVIGATION_CREATE_DOSSIER_LINKS = [
    "/dossier-form/any-property",
    "/about",
    "/partnership",
    "/faq",
    "/terms-conditions",
    "/privacy-policy",
    "/imprint",
    "/contact",
    "/create-dossier",
    "/success-smart-dossier",
    "/dossier-payment",
    "/payment-sponsor",
];

export default HIDE_NAVIGATION_CREATE_DOSSIER_LINKS;
