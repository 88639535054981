import React from "react";

//* mui components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { motion } from "framer-motion";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { slideUp } from "../../../utils/animation/Animation";
import MultiselectButton from "../../elements/button/MultiselectButton";

const numOfPeople = [
    {
        id: 1,
        label: "1",
    },
    {
        id: 2,
        label: "2",
    },
    {
        id: 3,
        label: "3",
    },
    {
        id: 4,
        label: "4",
    },
    {
        id: 5,
        label: "5",
    },
    {
        id: 6,
        label: "6",
    },
    {
        id: 7,
        label: "7",
    },
    { id: 8, label: "8+" },
];
// const yesNo = [
//    {
//       id: 1,
//       label: "yes",
//    },
//    {
//       id: 2,
//       label: "no",
//    },
// ];
function NoOfPeopleForm() {
    const { t } = useTranslation();

    const { watch, setValue } = useFormContext();

    const watchNoOfAdults = watch("noOfAdults");
    // const watchHasPet = watch("hasPet");
    // const watchIsSmoke = watch("isSmoke");

    const handleNoOfPeople = (value) => {
        setValue("noOfAdults", value);
    };
    // const handleHasPet = (value) => {
    //    setValue("hasPet", value);
    // };
    // const handleIsSmoke = (value) => {
    //    setValue("isSmoke", value);
    // };

    return (
        <Grid
            container
            rowSpacing={2}
            component={motion.div}
            variants={slideUp}
            initial="hidden"
            animate="visible"
        >
            <Grid item xs={12}>
                <Typography variant="subtitle1" mb={1}>
                    {t("how_many_people_are_moving_in") + "?"}
                </Typography>
                <Box>
                    <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                        {numOfPeople.map((people) => (
                            <MultiselectButton
                                key={people.id}
                                onClick={() => handleNoOfPeople(people.label)}
                                selected={watchNoOfAdults === people.label}
                            >
                                {people.label}
                            </MultiselectButton>
                        ))}
                    </Stack>
                </Box>
            </Grid>
            {/* <Grid item xs={12}>
            <Typography variant='subtitle1' mb={1}>
               {t("do_you_have_pets") + "?"}
            </Typography>
            <Box>
               <Stack direction={"row"} columnGap={1} flexWrap={"wrap"}>
                  {yesNo.map((option) => (
                     <MultiselectButton
                        key={option.id}
                        onClick={() => handleHasPet(option.label)}
                        selected={watchHasPet === option.label}
                     >
                        {t(option.label)}
                     </MultiselectButton>
                  ))}
               </Stack>
            </Box>
         </Grid>
         <Grid item xs={12}>
            <Typography variant='subtitle1' mb={1}>
               {t("do_you_smoke") + "?"}
            </Typography>

            <Box>
               <Stack direction={"row"} columnGap={1} flexWrap={"wrap"}>
                  {yesNo.map((option) => (
                     <MultiselectButton
                        key={option.id}
                        onClick={() => handleIsSmoke(option.label)}
                        selected={watchIsSmoke === option.label}
                     >
                        {t(option.label)}
                     </MultiselectButton>
                  ))}
               </Stack>
            </Box>
         </Grid> */}
        </Grid>
    );
}

export default NoOfPeopleForm;
