// ----------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
export default function Menu(theme) {
    return {
        MuiMenu: {
            styleOverrides: {
                root: {},
                paper: {
                    borderRadius: 30,
                    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.10)",
                    minWidth: 160,
                    marginTop: 5,
                },
                list: {},
            },
        },
    };
}
