import React, { useEffect, useState } from "react";

//* axios imports
import { Axios } from "../../../../axios/axios";

//* mui components imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//* mui icons imports
import ApartmentIcon from "@mui/icons-material/Apartment";
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import StairsIcon from "@mui/icons-material/Stairs";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cookies from "js-cookie";

const DetailsFeature = ({ icon, detailsFeature, detailsName }) => {
    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {icon}
            <Typography
                component={"p"}
                sx={{
                    color: "#717171",
                    fontWeight: 400,
                    lineHeight: "1.2500em",
                    fontSize: "0.995em",
                }}
            >
                {detailsFeature || "N/A"}{" "}
                {/*
                {detailsName === "living" ? (
                    <>
                        m<sup>2</sup>
                    </>
                ) : (
                    detailsName
                )}*/}
            </Typography>
        </Stack>
    );
};

export const PropertyMobileFeatures = ({
    categoryId,
    bedRooms,
    rooms,
    numberOfFloors,
    livingSpace,
    availabilityDate,
    availability,
}) => {
    const [propertyType, setPropertyType] = useState("");
    const theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        Axios.get("/property/all_values")
            .then((res) => {
                let categoriesArray = res?.data?.data[0]?.categories;
                let category = categoriesArray.filter(
                    (category) => category.id === categoryId
                );
                setPropertyType(category[0]?.name);
            })
            .catch((err) => console.error(err));
    }, [categoryId]);

    const currentLanguageCode = cookies.get("i18next") || "en";
    moment.locale(currentLanguageCode);

    const formatDate = (dateString) => {
        if (!dateString) return "--";

        return moment(dateString).format("LL");
    };

    const availabilityText =
        availability === "date" && availabilityDate
            ? `${t("from")} ${formatDate(availabilityDate)}`
            : t(availability);

    const displayText =
        availabilityText === "--"
            ? "--"
            : `${availabilityText} ${t("available")}`;

    return (
        <>
            <Typography variant="h5" mb={2}>
                {t("main_features")}
            </Typography>
            <Grid item container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <DetailsFeature
                        icon={
                            <ApartmentIcon
                                sx={{ color: theme.palette.secondary.main }}
                            />
                        }
                        detailsFeature={propertyType}
                    />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <DetailsFeature
                        icon={
                            <BedIcon
                                sx={{ color: theme.palette.secondary.main }}
                            />
                        }
                        detailsFeature={rooms}
                        detailsName={t("rooms")}
                    />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <DetailsFeature
                        icon={
                            <StairsIcon
                                sx={{ color: theme.palette.secondary.main }}
                            />
                        }
                        detailsFeature={numberOfFloors}
                        detailsName={t("number_of_floor")}
                    />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <DetailsFeature
                        icon={
                            <ControlCameraIcon
                                sx={{ color: theme.palette.secondary.main }}
                            />
                        }
                        detailsFeature={livingSpace}
                        detailsName={t("living")}
                    />
                </Grid>
                <Grid item md={4}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                        sx={{ pt: 1 }}
                    >
                        <AccessTimeIcon
                            fontSize="large"
                            sx={{ color: theme.palette.secondary.main }}
                        />
                        <Typography
                            component={"p"}
                            sx={{
                                color: "#717171",
                                fontWeight: 400,
                                lineHeight: "1.2500em",
                                fontSize: "0.995em",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {displayText}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};
