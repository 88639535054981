export const getFooterInfo = (footerData, key) => {
   let adminData = footerData?.data?.results;
   if (!footerData?.data?.statuscode) return "not_available";
   let footerInfo = adminData?.find((adminData) => adminData.key === key);

   return footerInfo.value;
};

export const getEmailInfo = (footerData) => {
   let adminData = footerData?.data?.results;
   if (!footerData?.data?.statuscode) return ["not_available"];
   let contact = adminData?.find(
      (adminData) => adminData.key === "admin_email"
   );
   if (!contact) {
      return ["not_available"];
   }
   return JSON.parse(contact?.value);
};

export const adminAddress = (data) => {
   let companyInfo = getFooterInfo(data, "admin_address");

   let result = {
      name: "",
      street: "",
      city: "",
   };

   if (typeof companyInfo === "string") {
      let tmpCompany = companyInfo?.split("\n\n");

      if (tmpCompany.length > 0) {
         result.name = tmpCompany[0];

         if (tmpCompany.length > 1 && typeof tmpCompany[1] === "string") {
            let tmpCompanyAddress = tmpCompany[1]?.split("\n");

            if (tmpCompanyAddress.length > 0) {
               result.street = tmpCompanyAddress[0];
            }
            if (tmpCompanyAddress.length > 1) {
               result.city = tmpCompanyAddress[1];
            }
         }
      }
   } else {
      console.error("Unexpected type for companyInfo, expected 'string'.");
   }

   return result;
};

export const showEmail = (data) =>
   getEmailInfo(data)?.map((email) => {
      return email;
   });

export const phoneNumber = (data) => {
   let phone = getFooterInfo(data, "admin_phone");
   if (phone !== "not_available") {
      return `+${phone?.substring(0, 2)} ${phone?.substring(2)}`;
   }
};
