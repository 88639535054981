import { useQuery } from "react-query";
// import { Axios } from "../../axios/axios";
import useAxiosPrivate from "../Auth/useAxiosPrivate";
// import { useEffect, useState } from "react";

// function getAllProfileDetailsById({ queryKey }) {
//    const id = queryKey[1];
// }

export default function useGetAllProfileDetailsById(id) {
    const axiosPrivate = useAxiosPrivate();

    return useQuery(
        ["Get-All-Profile-Details-By-Id", id],
        ({ signal }) => {
            try {
                return axiosPrivate.get(
                    `/users/get_all_profile_details?id=${id}`,
                    {
                        signal: signal.aborted,
                    }
                );
            } catch (error) {
                console.warn(error);
            }
        },
        {
            enabled: Boolean(id),
        }
    );
    // const [users, setUsers] = useState();

    // useEffect(() => {
    //   let isMounted = true;
    //   const controller = new AbortController();

    //   const getUsers = async () => {
    //     try {
    //       const response = await getAllProfileDetailsById()
    //       isMounted && setUsers(response?.data);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };

    //   getUsers();

    //   return () => {
    //     isMounted = false;
    //     controller.abort();
    //   };
    // }, []);

    // return users;
}
