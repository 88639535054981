import React, {useState} from "react";

//* modal components
import {PropertyMobileContactSellerModal} from "../../../modal/properties/PropertyMobileContactSellerModal";

//* mui components imports
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {getPropertyPrice} from "../../../../utils/getPropertyTypes";
import SendIcon from "@mui/icons-material/Send";
import {useTheme} from "@mui/material/styles";

export const PropertyMobileContactSeller = ({
                                                name,
                                                price,
                                                propertyId,
                                                propertyOwnerId,
                                                contactButtonText,
                                                contactButtonAddonText
                                            }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme();

    return (
        <>
            <AppBar
                position='fixed'
                sx={{
                    top: "auto",
                    bottom: 0,
                    height: 90,
                    bgcolor: "#fff",
                    justifyContent: "center",
                }}
            >
                <Toolbar>
                    <Typography variant='h5' sx={{ lineHeight: '2.5rem', marginRight: '8px' }}>
                        {getPropertyPrice(price)}
                    </Typography>
                    <Box component="span"
                         sx={{display: 'flex', alignItems: 'center', fontSize: '0.8rem', marginRight: 'auto'}}>
                        {contactButtonAddonText}
                    </Box>
                    <Button
                        color='secondary'
                        variant='contained'
                        size='large'
                        sx={{
                            borderRadius: '16px',
                            position: "sticky",
                            top: "100px",
                            border: '1px solid',
                            backgroundColor: theme.palette.primary.main,
                            borderColor: theme.palette.secondary.main
                        }}
                        onClick={handleClickOpen}
                        endIcon={<SendIcon/>}
                    >
                        {contactButtonText}
                    </Button>
                </Toolbar>
            </AppBar>
            <PropertyMobileContactSellerModal
                open={open}
                handleClose={handleClose}
                name={name}
                price={price}
                propertyId={propertyId}
                propertyOwnerId={propertyOwnerId}
            />
        </>
    );
};
