import React from "react";

//* custom components
import { Img } from "../../../img/Img";

//* image imports
import { profilePlaceholder } from "../../../../assets/imgImport";

//* mui components imports
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const PropertyMobileAdvertiserBrief = ({
    hostName,
    advertiserPicture,
    profileImageUrl,
    title,
}) => {
    return (
        <>
            <Typography variant="h5" mb={2}>
                {title}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" fontWeight={400}>
                    {hostName || "N/A"}
                </Typography>
                <Img
                    src={
                        advertiserPicture
                            ? profileImageUrl + advertiserPicture
                            : profilePlaceholder
                    }
                    alt={hostName || "N/A"}
                    height={100}
                    width={100}
                    style={{ borderRadius: 50 }}
                />
            </Stack>
        </>
    );
};
