import {  useQuery } from "react-query";
import { Axios } from "../../axios/axios";

// function getPropertyDetails(property) {
//   return Axios.post('/property/property_by_id', property)
// }
function getPropertyDetails({ queryKey }) {
    let property = queryKey[1];
    let { id, lang, user_id } = property;
    return Axios.get(`/property/property_by_id?id=${id}&lang=${lang}&user_id=${user_id}`);
}

export default function usePropertyById(property) {
    return useQuery(["property-detail", property], getPropertyDetails);
}
