import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { NAVIGATION_OPTIONS } from "../../options/navigationOptions";
import { NAVIGATION_OPTIONS_ADMIN_TEST } from "../../options/navigationOptionsAdminTest";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../store";

import { IconButton } from "@mui/material";

const HIDE_NAVIGATION_LINK = [
    "/employer-reference-form",
    "/administration-reference-form",
    "/reference-successful",
    "/administration-reference-form",
    "/property",
];

const NavigationLink = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "isactive",
})(({ theme, isactive }) => ({
    textDecoration: "none",
    color: isactive
        ? theme.palette.secondary.dark
        : theme.palette.secondary.main,
    ":hover": {
        color: theme.palette.secondary.dark,
    },
}));

const OutlinedBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isactive",
})(({ theme }) => ({
    padding: 8,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    columnGap: 10,
    borderRadius: 12,
    alignItems: "center",
    minWidth: 120,
}));

const RoundedOutline = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isactive",
})(({ theme }) => ({
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: 24,
}));

function NavigationLinks({ handleDrawerClose }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const { pathname } = useLocation();
    const [{ token, user_email, user_id }] = useStateValue();

    const isPropertySubpage = /^\/property\/\d+/.test(pathname);
    const isPropertySubpageAdd = /^\/add-property/.test(pathname);
    //const isPropertySubpageCreateObject = /^\/create-object/.test(pathname);

    if (
        HIDE_NAVIGATION_LINK.includes(pathname) ||
        isPropertySubpage ||
        isPropertySubpageAdd
    )
        return null;

    const profilePath = "/account/fs/smart-dossier";

    if (pathname?.includes("/dossier-form"))
        return (
            <Link to={profilePath} style={{ textDecoration: "none" }}>
                <OutlinedBox>
                    <Typography sx={{ color: "primary.main" }}>
                        {`${t("my_profile")}`}&nbsp;
                    </Typography>
                    <Icon color="primary">exit_to_app</Icon>
                </OutlinedBox>
            </Link>
        );

    //const navigationOptions = user_id === 809 ? NAVIGATION_OPTIONS_ADMIN_TEST : NAVIGATION_OPTIONS;
    const navigationOptions =
        user_id === 809
            ? NAVIGATION_OPTIONS_ADMIN_TEST
            : NAVIGATION_OPTIONS_ADMIN_TEST;

    return (
        <Fragment>
            {NAVIGATION_OPTIONS_ADMIN_TEST.map((nav) => {
                return (
                    <NavigationLink
                        key={nav.id}
                        to={nav.link}
                        variant="body2"
                        fontWeight="fontWeightMedium"
                        isactive={pathname.includes(nav.link) || undefined}
                        component={Link}
                        {...(handleDrawerClose
                            ? { onClick: handleDrawerClose }
                            : {})}
                    >
                        <IconButton>
                            {React.cloneElement(nav.icon, {
                                style: {
                                    fontSize: 18,
                                    color: theme.palette.secondary.main,
                                },
                            })}
                        </IconButton>
                        {t(nav.name)}
                    </NavigationLink>
                );
            })}
        </Fragment>
    );
}

NavigationLinks.propTypes = {
    handleDrawerClose: PropTypes.any,
};

export default NavigationLinks;
