import { useMutation } from 'react-query'
import { Axios } from '../../axios/axios'

function changeStatus(status) {
  return Axios.post('/property/update_drag_status', status)
}

export function useChangeRequestStatus() {
  return useMutation(changeStatus)
}
