import React, { forwardRef } from "react";

//* libraries
import { LazyLoadImage } from "react-lazy-load-image-component";

//* dependency on Lazy load image
import "react-lazy-load-image-component/src/effects/blur.css";

export const Img = forwardRef(
    ({ srcSet, src, key, alt, style, height, width, loading, className, size, ...props }, ref) => {
        return (
            <LazyLoadImage
                ref={ref}
                key={key}
                loading={loading}
                effect="blur"
                height={height}
                width={width}
                style={{ aspectRatio: 1, objectFit: "cover", ...style }}
                sizes={size}
                srcSet={srcSet}
                src={src}
                alt={alt}
                className={className}
                {...props}
            />
        );
    }
);
