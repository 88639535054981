export const roomOptions = (function () {
    let rooms = [];
    for (let i = 1; i <= 8; i += 0.5) {
        if (i === 8) {
            rooms.push(`${i}+`);
        } else {
            rooms.push(`${i}`);
        }
    }
    let roomOptions = rooms.map((floor) => ({ value: floor, label: floor }));
    return roomOptions;
})();

export const floorOptions = (function () {
    let floors = ["EG", "UG"];
    for (let i = 1; i <= 20; i++) {
        floors.push(`${i}`);
    }
    let floorOptions = floors.map((floor) => ({ value: floor, label: floor }));
    return floorOptions;
})();

export const units = ["month", "week", "day"];

export const availability = [
    {
        label: "immediately",
        value: "immediately",
    },
    {
        label: "by_agreement",
        value: "agreement",
    },
    {
        label: "by_date",
        value: "date",
    },
]; // helps in translate

export const advertise = [
    { value: "tenant", label: "tenant" },
    { value: "owner", label: "owner" },
    { value: "business", label: "business" },
];

export const property_type = [
    { value: "1", label: "Sale" },
    { value: "2", label: "Rent" },
];

export const countryOption = [{ value: "3", label: "switzerland" }];

export const ROOMS = [
    { id: 1, room: "any" },
    { id: 2, room: "1" },
    { id: 3, room: "2" },
    { id: 4, room: "3" },
    { id: 5, room: "4" },
    { id: 6, room: "5" },
    { id: 7, room: "6" },
    { id: 8, room: "7" },
    { id: 9, room: "8" },
    { id: 10, room: "8+" },
];

export const CATEGORIES = [
    { id: 1, room: "apartment" },
    //{ id: 2, room: "apartment_&_house" },
    { id: 3, room: "house" },
    { id: 4, room: "furnished_dwelling" },
    { id: 5, room: "shared_room" },
];
