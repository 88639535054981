import { useState, Fragment } from "react";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";

import { styled } from "@mui/material";

import DesktopNavigation from "./DesktopNavigation";
import useResponsive from "../../hooks/responsive/useResponsive";
import MobileNavigation from "./MobileNavigation";
import HideOnScroll from "../scrolls/HideOnScroll";
import MobileDrawer from "./MobileDrawer";
import ViewMenuIconOnScroll from "../scrolls/ViewMenuIconOnScroll";
// import HIDE_NAVIGATION_LINK from "../../options/hideNavigationLinks.js";
import { useLocation } from "react-router-dom";
import HIDE_NAVIGATION_LINKS from "../../options/hideNavigationLinks.js";

const NavigationBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    minHeight: "65px",
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
    boxShadow: "none",
    // position: "static",
}));

const MobileNavigationBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: "center",
    boxShadow: "none",
    // position: "static",
}));

const Navigation = () => {
    const { pathname } = useLocation();
    const { isBelowLg } = useResponsive();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    if (isBelowLg)
        return (
            <Fragment>
                {HIDE_NAVIGATION_LINKS.includes(pathname) || (
                    <ViewMenuIconOnScroll
                        iconButtonProps={{
                            onClick: handleDrawerOpen,
                        }}
                    />
                )}

                <HideOnScroll>
                    <MobileNavigationBar>
                        <Container maxWidth="xl">
                            <MobileNavigation
                                handleDrawerOpen={handleDrawerOpen}
                                handleDrawerClose={handleDrawerClose}
                            />
                        </Container>
                    </MobileNavigationBar>
                </HideOnScroll>
                <MobileDrawer
                    open={isDrawerOpen}
                    handleClose={handleDrawerClose}
                />
            </Fragment>
        );

    return (
        <NavigationBar>
            <Container maxWidth="xl">
                <DesktopNavigation />
            </Container>
        </NavigationBar>
    );
};

export default Navigation;
