import React from "react";

//* mui components import
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";

function Loading({ containerStyle }) {
    return (
        <Container
            maxWidth="xl"
            sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 20,
                ...containerStyle,
                // height: "100vh",
            }}
        >
            <Box sx={{ position: "relative" }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: "#888",
                    }}
                    size={40}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => theme.palette.primary.dark,
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    }}
                    size={40}
                    thickness={4}
                />
            </Box>
        </Container>
    );
}

export default Loading;
