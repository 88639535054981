//* mui imports
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";

//* sections
import ApplyToSmartDossier from "../../component/section/ApplyToSmartDossier";
import VoiceOfCustomers from "../../component/section/VoiceOfCustomers";
import HowItWorks from "../../component/section/HowItWorks";
import DownloadApp from "../../component/section/DownloadApp";
import Hero from "../../component/section/Hero";
import { useState } from "react";
import WishTenantForm from "../../component/forms/wish-tenant-form/WishTenantForm";

//* options
import {
    HOW_IT_WORKS,
    HOW_IT_WORKS_PROPERTY,
} from "../../options/howItWorksOption";

import LatestPropertySection from "../../component/section/LatestPropertySection";

const ContainerBox = styled(Container)(() => ({
    "&.MuiContainer-root": {
        padding: 0,
    },
}));

const HomePage = () => {
    return (
        <>
            <Stack rowGap={5}>
                <Hero />
                <Container maxWidth='lg' sx={{ marginTop: "10px", p: "40px" }}>
                    <LatestPropertySection />
                </Container>
                <Container maxWidth="lg">
                    <HowItWorks data={HOW_IT_WORKS} />
                </Container>
                <Container maxWidth="lg">
                    <HowItWorks data={HOW_IT_WORKS_PROPERTY} />
                </Container>
                <ContainerBox maxWidth="xl">
                    <DownloadApp />
                </ContainerBox>
                {/*
                <ContainerBox maxWidth="xl">
                    <ApplyToSmartDossier coloredBg />
                </ContainerBox>
                */}
                {/*
                <Container maxWidth="lg">
                    <VoiceOfCustomers />
                </Container>
                */}
            </Stack>
        </>
    );
};

export default HomePage;
