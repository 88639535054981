import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function handleVerification(data) {
    return Axios.post("/users/verify_email_or_OTP", data);
}

export default function useVerifyCode() {
    return useMutation(handleVerification);
}
