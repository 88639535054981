import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
//* mui component
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

//* mui icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
//* react-hook-form
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, InputAdornment, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { motion } from "framer-motion";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCheckDossier } from "../../../../hooks/profile/useCheckDossier";
import { useSocialLoginStatus } from "../../../../hooks/social-login/useSocialLoginStatus";
import useIsSmartDossierPaid from "../../../../hooks/transaction/useIsSmartDossierPaid";
import useAddDossierWishProperty from "../../../../hooks/wish-property/useAddDossierWishProperty";
import { useStateValue } from "../../../../store";
import { slideUp } from "../../../../utils/animation/Animation";
import CustomGoogleIcon from "../../../svg/CustomGoogleIcon";

function LoginStep({ handleAuthForm }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [{ user_id }] = useStateValue();

    const {
        control,
        getValues,
        formState: { errors },
    } = useFormContext();

    const { isLoading: isLoadingWishProperty, mutate: onAddWishProperty } =
        useAddDossierWishProperty();
    const {
        //   isLoading: socialLoginLoading,
        data: socialLoginData,
        refetch: socialLoginRefetch,
        status: socialLoginStatus,
    } = useSocialLoginStatus();

    const {
        isLoading: isLoadingPaymentStatus,
        data: paymentStatusData,
        status: getPaymentDataStatus,
    } = useIsSmartDossierPaid(user_id);

    const {
        isLoading: checkDossierIsLoading,
        data: checkDossierCreated,
        status: checkDossierStatus,
    } = useCheckDossier(user_id);

    const isDossierCreated = checkDossierCreated?.data?.data;
    let isPayment = paymentStatusData?.dossierPayment;
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        let {
            city,
            zip,
            distance,
            maxRent,
            rooms,
            category,
            livingSpaceFrom,
            livingSpaceTo,
            features,
            noOfAdults,
            hasPet,
            isSmoke,
        } = getValues();
        if (socialLoginStatus === "success" && socialLoginData) {
            let wishedProperty = {
                city,
                zip,
                distance,
                maxRent,
                rooms,
                category,
                livingSpaceFrom,
                livingSpaceTo,
                features: features.toString(),
                noOfAdults,
                hasPet,
                isSmoke,
            };
            let wished = { ...wishedProperty, user_id };
            onAddWishProperty(wished, {
                onSuccess: (data) => {
                    //check status
                    if (!data?.data?.statuscode)
                        return t("something_went_wrong");

                    if (getPaymentDataStatus === "success" && isPayment === 0)
                        return navigate("/dossier-payment");
                    if (
                        getPaymentDataStatus === "success" &&
                        isPayment === 1 &&
                        checkDossierStatus === "success" &&
                        !isDossierCreated
                    )
                        return navigate("/brief-dossier-form");

                    if (
                        getPaymentDataStatus === "success" &&
                        isPayment === 1 &&
                        checkDossierStatus === "success" &&
                        isDossierCreated
                    )
                        return navigate("/dossier-form/any-property");
                },
                onError: (errors) => {
                    console.error(errors);
                    toast.error(`${t("we_are_facing_some_technical_issue")}`);
                },
            });
        }
    }, [
        socialLoginData,
        socialLoginStatus,
        getValues,
        user_id,
        onAddWishProperty,
        t,
        getPaymentDataStatus,
        isPayment,
        navigate,
        checkDossierStatus,
        isDossierCreated,
    ]);
    const google = () => {
        let timer = null;

        let googleUrl = `${process.env.REACT_APP_BASE_URL}/google`;

        let socialLoginWindow = window.open(
            googleUrl,
            "_blank",
            "width=500, height=600"
        );

        if (socialLoginWindow) {
            timer = setInterval(() => {
                if (socialLoginWindow.closed) {
                    socialLoginRefetch();
                    if (timer) clearInterval(timer);
                }
            }, 500);
        }
    };

    return (
        <Grid
            container
            rowSpacing={4}
            component={motion.div}
            variants={slideUp}
            initial="hidden"
            animate="visible"
        >
            <Grid item xs={12}>
                <Stack flexDirection={"row"} gap={1}>
                    <Box>
                        <InfoOutlinedIcon
                            color="primary"
                            sx={{
                                fontSize: "60px",
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography variant={"h4"} textTransform={"capitalize"}>
                            {t("login")}
                        </Typography>
                        <Typography variant="body2">
                            {t("help_you_creating_your_personal_smart_dossier")}
                        </Typography>
                    </Box>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Controller
                    control={control}
                    name={"email"}
                    render={({ field }) => {
                        return (
                            <TextField
                                type="email"
                                label={t("email")}
                                fullWidth
                                variant="filled"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                {...field}
                                error={!!errors?.email}
                                helperText={
                                    errors?.email
                                        ? t(errors?.email?.message)
                                        : null
                                }
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item md={12} textAlign={"center"}>
                <Typography component={"p"}>
                    {t("dont_have_an_account") + "?"}
                </Typography>
                <Button
                    sx={{
                        textDecoration: "underline",
                        p: "2px",
                        fontSize: 18,
                    }}
                    color="secondary"
                    onClick={() => handleAuthForm("register")}
                >
                    {t("register")}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <div className="loginFormOrText" color={"secondary"}>
                    {t("or")}
                </div>
            </Grid>
            <Grid item xs={12}>
                <Stack flexDirection={"row"} justifyContent={"center"} gap={2}>
                    <Button
                        variant="text"
                        color="secondary"
                        startIcon={<CustomGoogleIcon viewBox={"0 0 32 32"} />}
                        onClick={google}
                    >
                        {t("sign_in")}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default LoginStep;
