import * as yup from "yup";

export const PartnershipSchema = yup.object().shape({
    partnershipFirstName: yup.string().required("please_enter_your_first_name").min(2).max(255),
    partnershipLastName: yup.string().required("please_enter_your_last_name").min(2).max(255),
    partnershipEmail: yup
        .string()
        .email("email_is_invalid")
        .required("please_enter_your_email")
        .min(2)
        .max(255),
    partnershipSubject: yup.string().required("please_enter_your_subject").min(2).max(255),
    partnershipMessage: yup.string().required("please_enter_your_message").min(2).max(255),
});
