import * as yup from "yup";

export const WishPropertyCityValidationSchema = yup.object().shape({
    //cityAndZip: yup.mixed().required("please_enter_your_city_and_zip_code"),
});

export const WishPropertyValidationSchema = [
    yup.object({
        livingSpaceFrom: yup
            .mixed()
            .test(
                "isNumberOrNull",
                "please_enter_living_space",
                function (value) {
                    if (value === null || value === "") {
                        return true; // Allow null and empty values
                    }
                    return /^[1-9]\d*(\.\d+)?$/.test(value); // Check if value is a valid number
                }
            )
            .test(
                "isPositiveNumber",
                "please_enter_living_space",
                function (value) {
                    if (value === null || value === "") {
                        return true; // Allow null and empty values
                    }
                    return parseFloat(value) > 0; // Check if value is a positive number
                }
            )
            .test("isGreater", "please_enter_living_space", function (value) {
                if (value === null || value === "") {
                    return true; // Allow null and empty values
                }
                return parseFloat(value) < 9999999999; // Check if value is a positive number
            })
            .nullable(),
        livingSpaceTo: yup
            .mixed()
            .test(
                "isNumberOrNull",
                "please_enter_living_space",
                function (value) {
                    if (value === null || value === "") {
                        return true; // Allow null and empty values
                    }
                    return /^[1-9]\d*(\.\d+)?$/.test(value); // Check if value is a valid number
                }
            )
            .test(
                "isPositiveNumber",
                "please_enter_living_space",
                function (value) {
                    if (value === null || value === "") {
                        return true; // Allow null and empty values
                    }
                    return parseFloat(value) > 0; // Check if value is a positive number
                }
            )
            .test("isGreater", "please_enter_living_space", function (value) {
                if (value === null || value === "") {
                    return true; // Allow null and empty values
                }
                return parseFloat(value) < 9999999999; // Check if value is a positive number
            })
            .nullable(),
    }),
    yup.object({
        maxRent: yup
            .mixed()
            .test(
                "isNumberOrNull",
                "please_enter_a_valid_rent",
                function (value) {
                    if (value === null || value === "") {
                        return true; // Allow null and empty values
                    }
                    return /^[1-9]\d*(\.\d+)?$/.test(value); // Check if value is a valid number
                }
            )
            .test(
                "isPositiveNumber",
                "please_enter_a_valid_rent",
                function (value) {
                    if (value === null || value === "") {
                        return true; // Allow null and empty values
                    }
                    return parseFloat(value) > 0; // Check if value is a positive number
                }
            )
            .test("isGreater", "please_enter_a_valid_rent", function (value) {
                if (value === null || value === "") {
                    return true; // Allow null and empty values
                }
                return parseFloat(value) < 9999999999; // Check if value is a positive number
            })
            .nullable(),
        roomsFrom: yup.mixed(),
        roomsTo: yup.mixed().when("roomsFrom", (roomsFrom, schema) => {
            return schema.test({
                test: (roomsTo) => {
                    if (!roomsTo) return true;
                    return roomsTo > roomsFrom;
                },
                message: "rooms_from_can_not_be_greater_then_rooms_to",
            });
        }),
    }),
    // yup.object({}),
    yup.object({}),
    // yup.object({}),
    //register or //login
    yup.object({
        email: yup
            .string()
            .email("email_is_invalid")
            .required("email_is_required")
            .min(6, "email_must_be_between_6_and_254_characters_long")
            .max(254, "email_must_be_between_6_and_254_characters_long"),
        // password: yup
        //     .string()
        //     .required("password_is_required")
        //     .min(8, "password_length_should_be_at_least_8_characters")
        //     .max(15, "password_length_should_be_at_least_15_characters"),
    }),
    //Otp
    yup.object({
        authOtp: yup
            .string()
            .required("please_enter_6_character_code_sent_to_your_mail")
            .min(6, "please_enter_6_character_code_sent_to_your_mail")
            .max(6, "please_enter_6_character_code_sent_to_your_mail"),
    }),
];

// .mixed()
// .test("isNumberOrNull", "Price must be a number", function (value) {
//     if (value === null || value === "") {
//         return true; // Allow null and empty values
//     }
//     return !isNaN(value); // Check if value is a valid number
// })
// .positive("Price must be a positive number")
// .nullable(),
