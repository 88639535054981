import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../pages/Dashboard/Dashboard";

import PrivateRoute from "./PrivateRoute";
import MAIN_ROUTES from "./homeRoutes";
import ACCOUNT_ROUTES from "./dashboardRoutes";
import PageNotFound from "../pages/Error-pages/PageNotFound";

export default function PageRoutes() {
    return (
        <>
            <Routes>
                {MAIN_ROUTES.map((mainRoute) => (
                    <Route
                        key={mainRoute.id}
                        path={mainRoute.path}
                        element={mainRoute.element}
                    />
                ))}
                <Route path="*" element={<PageNotFound />} />

                <Route
                    path="/account"
                    element={
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    }
                >
                    {ACCOUNT_ROUTES.map((accountRoute) => (
                        <Route
                            key={accountRoute.id}
                            path={accountRoute.path}
                            element={
                                <PrivateRoute>
                                    {accountRoute.element}
                                </PrivateRoute>
                            }
                        />
                    ))}
                </Route>
            </Routes>
        </>
    );
}
