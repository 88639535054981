import { useQuery } from "react-query";
import { Axios } from "../../axios/axios";

//get
function getAllSalaryRange() {
    return Axios.get("/get_wage_band_value");
}

export function useSalaryRange() {
    return useQuery("get-salary-range", getAllSalaryRange);
}

