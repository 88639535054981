import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

function LoadingButton({
    loading,
    buttonProps,
    onClick,
    disabled,
    variant,
    circularProgressProps,
    circularProgressSx,
    children,
}) {
    return (
        <Box position={"relative"}>
            <Button
                onClick={onClick}
                disabled={disabled}
                variant={variant}
                {...buttonProps}
            >
                {children}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    color="primary"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                        ...circularProgressSx,
                    }}
                    {...circularProgressProps}
                />
            )}
        </Box>
    );
}

export default LoadingButton;
