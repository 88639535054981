// to set the proper translation for document type labels

export function getDocumentTypeLabel(label = "") {
    switch (label) {
        case "passport_id":
            return "id_passport";
        case "salary_statement":
            return "salary_statement";
        case "debt_collect":
            return "debt_collect";
        case "criminal_record":
            return "criminal_record";
        case "other_docs":
            return "others";
        case "tenantTwoPassport":
            return "id_passport";
        case "tenantTwoSalary":
            return "salary_statement";
        case "tenantTwoDebt":
            return "debt_collect";
        case "tenantTwoCriminal":
            return "criminal_record";
        case "tenantTwoOtherDocs":
            return "others";

        default:
            return "";
    }
}
