import PropTypes from "prop-types";
//* mui components
import { styled, Typography, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import useResponsive from "../../hooks/responsive/useResponsive";

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useCities from "../../hooks/search/useCities";
import { DISTANCE_OPTIONS } from "../../options/distanceOption";
import ControllerAutoCompleteField from "../elements/textfield/ControllerAutocompleteField";
import ControllerSelectField from "../elements/textfield/ControllerSelectField";

import { yupResolver } from "@hookform/resolvers/yup";
import { WishPropertyCityValidationSchema } from "../../forms-schemas/WishPropertySchema";

const MainStack = styled(Stack)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: 30,
    position: "relative",
}));

const SmallScreenMainStack = styled(Box)(({ theme }) => ({
    width: "100%",
    height: 60,
    backgroundColor: theme.palette.common.white,
    borderRadius: 30,
}));

const ColoredButton = styled(Button, {
    shouldForwardProp: (props) => props !== "isBelowLg",
})(({ theme, isBelowLg }) => ({
    position: !isBelowLg && "absolute",
    right: !isBelowLg && -8,
    top: !isBelowLg && 0,
    height: "100%",
    "& .MuiButton-endIcon": {
        transition: "transform 300ms ease-in",
    },
    "& .MuiButton-startIcon": {
        transition: "transform 300ms ease-in",
    },
    "&:hover": {
        "& .MuiButton-startIcon": {
            transform: `translateX(-5px)`,
        },
        "& .MuiButton-endIcon": {
            transform: `translateX(5px)`,
        },
    },
    // minWidth: "244px",
}));

const SearchCityAndZipcode = ({ control, t, handleCitySelect }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const { isLoading: isCityLoading, data: cityData } = useCities(searchTerm);
    const searchedCities = cityData?.data?.data;
    // console.log("searchedCities", searchedCities);
    const [inputValue, setInputValue] = useState("");

    const handleSelect = (event, newValue) => {
        //console.log(newValue);
        if (newValue) {
            handleCitySelect(event, newValue, true);
            /*
            const parts = newValue.split(' ');
            const zip = parts[0];
            const name = parts.slice(1).join(' ');
            setInputValue('');
            */
        }
    };
    const getFullCityName = (name, zip) => {
        if (zip) {
            return `${zip} ${name}`;
        }
        return `${name}`;
    };

    return (
        <ControllerAutoCompleteField
            control={control}
            name={"cityAndZip"}
            options={searchedCities ?? []}
            searchedCities={searchedCities}
            handleSelect={handleSelect}
            autocompleteProps={{
                fullWidth: true,
                loading: isCityLoading,
                onInputChange: (event, inputValue) => {
                    setSearchTerm(inputValue);
                },
                getOptionLabel: (option) =>
                    getFullCityName(option.name, option.zip),
                filterOptions: (x) => x,
                autoComplete: true,
                includeInputInList: true,
                filterSelectedOptions: true,
                inputValue: searchTerm,
                noOptionsText: t("type_valid_city_or_zip"),
                renderOption: (props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="body1">
                                    {getFullCityName(option?.name, option?.zip)}
                                </Typography>
                                <Typography variant="caption" fontSize={14}>
                                    {option?.type}
                                </Typography>
                            </div>
                        </li>
                    );
                },
                sx: {
                    "& .MuiFormControl-root": {
                        ".MuiInputBase-root.MuiFilledInput-root": {
                            border: "none",
                            backgroundColor: "transparent",
                            px: 1,
                        },
                    },
                    "& .MuiAutocomplete-input": {
                        marginLeft: "2px",
                    },
                },
            }}
            textFieldProps={{
                hiddenLabel: true,
                placeholder: t("enter_your_destination_city_or_zip_code"),
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment sx={{ marginRight: 0 }} position="start">
                        <Icon color="primary">search</Icon>
                    </InputAdornment>
                ),
            }}
        />
    );
};

SearchCityAndZipcode.propTypes = {
    control: PropTypes.any.isRequired,
    t: PropTypes.func.isRequired,
    selectedCities: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const DistanceSelect = ({ control, t }) => {
    const { isBelowCustom } = useResponsive(1470);
    return (
        <ControllerSelectField
            control={control}
            name="distance"
            fullWidth
            props={{
                placeholder: t("distance"),
                hiddenLabel: true,
                select: true,
                open: true,
                sx: {
                    "& .MuiInputBase-root.MuiFilledInput-root , .MuiInputBase-root.MuiFilledInput-root:hover":
                        {
                            border: "none",
                            backgroundColor: "transparent",

                            "& .MuiSelect-select": {
                                px: isBelowCustom ? 3 : 1.5,
                            },

                            "& .MuiSvgIcon-root": {
                                right: isBelowCustom ? 20 : 45,
                            },

                            "& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input:focus":
                                {
                                    backgroundColor: "transparent",
                                },
                        },
                },
            }}
            OPTIONS={DISTANCE_OPTIONS}
        />
    );
};

DistanceSelect.propTypes = {
    control: PropTypes.any,
    t: PropTypes.any,
};

const SearchByDistance = ({
    coloredBg,
    onStart,
    handleCitySelect,
    handleFinishSelection,
    selectedCities,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            cityAndZip: null,
            distance: "0",
        },
        resolver: yupResolver(WishPropertyCityValidationSchema),
        mode: "onChange",
    });

    const onSubmit = (data) => {
        const cityNames = selectedCities.map((city) => city.name).join(", ");
        const zipCodes = selectedCities.map((city) => city.zip).join(", ");

        const cityAndZip = {
            ...data.cityAndZip,
            name: cityNames,
            zip: zipCodes,
        };
        const finalData = {
            ...data,
            cityAndZip,
        };

        onStart(finalData);
    };
    /*
    const onSubmit = data => {
        onStart(data);
    };
    */

    const { isBelowCustom } = useResponsive(1470);

    return (
        <Box>
            {!!errors?.cityAndZip && errors.cityAndZip && (
                <Alert severity="error" sx={{ mb: 1 }}>
                    {t(errors?.cityAndZip?.message)}
                </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                {isBelowCustom ? (
                    <Stack rowGap={3}>
                        <SmallScreenMainStack>
                            <SearchCityAndZipcode
                                control={control}
                                t={t}
                                handleCitySelect={handleCitySelect}
                                selectedCities={selectedCities}
                            />
                        </SmallScreenMainStack>
                        <Box>
                            <ColoredButton
                                isBelowLg
                                variant="contained"
                                color={coloredBg ? "primary" : "secondary"}
                                type="submit"
                                endIcon={<Icon>arrow_forward</Icon>}
                            >
                                {t("ready_with_city_choice")}
                            </ColoredButton>
                        </Box>
                        {/*
                        <SmallScreenMainStack>
                            <DistanceSelect control={control} t={t}/>
                        </SmallScreenMainStack>
                        <Box>
                            <ColoredButton
                                isBelowLg
                                variant="contained"
                                color={coloredBg ? "primary" : "secondary"}
                                endIcon={<Icon>arrow_forward</Icon>}
                                type="submit"
                            >
                                {t("start")}
                            </ColoredButton>
                        </Box>
                        */}
                    </Stack>
                ) : (
                    <MainStack>
                        <Grid container>
                            <Grid item xs={7}>
                                <SearchCityAndZipcode
                                    control={control}
                                    t={t}
                                    handleCitySelect={handleCitySelect}
                                    selectedCities={selectedCities}
                                />
                            </Grid>
                            <Grid>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    sx={{
                                        bgcolor: theme.palette.grey[500],
                                        height: "70%",
                                    }}
                                />
                            </Grid>
                            {/*
                            <Grid item xs={3}>
                                <DistanceSelect control={control} t={t}/>
                            </Grid>
                            */}
                        </Grid>

                        <ColoredButton
                            variant="contained"
                            color={coloredBg ? "primary" : "secondary"}
                            type="submit"
                            endIcon={<Icon>arrow_forward</Icon>}
                        >
                            {t("ready_with_city_choice")}
                        </ColoredButton>
                        {/*
                        <ColoredButton
                            variant="contained"
                            color={coloredBg ? "primary" : "secondary"}
                            type="submit"
                            endIcon={<Icon>arrow_forward</Icon>}
                        >
                            {t("start")}
                        </ColoredButton>
                        */}
                    </MainStack>
                )}
            </form>
        </Box>
    );
};

SearchByDistance.propTypes = {
    coloredBg: PropTypes.bool,
    onStart: PropTypes.func.isRequired,
};

export default SearchByDistance;
