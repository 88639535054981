import { useTranslation } from "react-i18next";

import  Container  from "@mui/material/Container";
import  Grid  from "@mui/material/Grid";
import  Stack  from "@mui/material/Stack";
import  Typography  from "@mui/material/Typography";
import { styled } from "@mui/material";

import CustomerVoiceBox from "../content-box/CustomerVoiceBox";
import { VOICE_OF_CUSTOMERS } from "../../options/voiceOfCustomer";

const MuiGrid = styled(Grid)(({ theme }) => ({
   [theme.breakpoints.up("md")]: {
      ":nth-of-type(2)": {
         marginTop: 70,
      },
      ":nth-of-type(3)": {
         marginTop: -170,
      },
      ":nth-of-type(5)": {
         marginTop: -140,
      },
   },
}));

const VoiceOfCustomers = () => {
   const { t } = useTranslation();
   return (
      <Container maxWidth='lg'>
         <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
               <Stack rowGap={2}>
                  <Typography variant='h4'>
                     {t("voices_of_customers")}
                  </Typography>
                  <Typography variant='body1'>
                     {t("here_are_a_few_comments_from_our_customers")}
                  </Typography>
               </Stack>
            </Grid>
            {VOICE_OF_CUSTOMERS.map((voices) => {
               return (
                  <MuiGrid item xs={12} md={6} key={voices.id}>
                     <CustomerVoiceBox
                        name={voices.name}
                        image={voices.img}
                        placeholders={voices.initials}
                        testimony={voices.description}
                     />
                  </MuiGrid>
               );
            })}
         </Grid>
      </Container>
   );
};

export default VoiceOfCustomers;
