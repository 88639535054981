import * as React from "react";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";

function FacebookIcon(props) {
  return (
    <SvgIcon {...props} width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM19.2275 8.13043L21.4389 8.13426C21.6312 8.13426 21.7864 8.29207 21.7864 8.48527V11.1174C21.7864 11.3108 21.6303 11.4686 21.4389 11.4686H19.9484C18.9216 11.4686 18.734 11.8739 18.734 12.6707V14.3286H21.3345C21.427 14.3286 21.5158 14.3659 21.5803 14.4315C21.6459 14.4977 21.683 14.5874 21.683 14.6809L21.6821 17.5145C21.6821 17.7089 21.5259 17.8658 21.3336 17.8658H18.734V25.0166C18.734 25.211 18.5778 25.3688 18.3855 25.3688H15.4845C15.2924 25.3688 15.1362 25.211 15.1362 25.0166V17.8658H12.9125C12.7204 17.8658 12.5652 17.7089 12.5652 17.5145V14.6809C12.5652 14.4865 12.7204 14.3286 12.9125 14.3286H15.1362V12.3727C15.1362 9.79584 16.7424 8.13043 19.2275 8.13043Z"
        
      />
    </SvgIcon>
  );
}

export default function SvgIconsSize() {
  return (
    <Stack alignItems="center" sx={{ display: "inline-block" }}>
      <FacebookIcon fontSize="large" />
    </Stack>
  );
}
