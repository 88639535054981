import PropTypes from "prop-types";

//* mui imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* shapes
import Droplet from "../shapes/Droplet";

import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material";
import useResponsive from "../../hooks/responsive/useResponsive";

const ContentBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
}));

const DropletBox = styled(Box, {
    shouldForwardProp: (props) =>
        props !== "layoutDirection" && props !== "isBelowMd",
})(({ layoutDirection, isBelowMd }) => ({
    display: "flex",
    justifyContent: isBelowMd
        ? "center"
        : layoutDirection === "reverse"
        ? "flex-end"
        : "flex-start",
    height: "100%",
}));

const LottieBox = styled(Lottie, {
    shouldForwardProp: (props) => props !== "isBelowMd",
})(({ isBelowMd }) => ({
    height: isBelowMd ? 350 : 400,
    width: isBelowMd ? 350 : 400,
}));

const IconWithContent = ({
    icon,
    num,
    title,
    subtitle,
    dropletPosition,
    size,
    bgcolor,
    layoutDirection,
    animationData,
}) => {
    const { t } = useTranslation();

    const { isBelowMd } = useResponsive();
    return (
        <Grid
            container
            {...(layoutDirection === "reverse"
                ? { direction: "row-reverse" }
                : { direction: "row" })}
            rowSpacing={4}
        >
            <Grid item xs={12} md={6}>
                <DropletBox
                    layoutDirection={layoutDirection}
                    isBelowMd={isBelowMd}
                >
                    <Droplet
                        position={dropletPosition}
                        size={size}
                        bgcolor={bgcolor}
                        containerStyleProps={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {icon && <Icon>{icon}</Icon>}
                        {animationData && (
                            <LottieBox
                                animationData={animationData}
                                // style={{ height: 400, width: 400 }}
                            />
                        )}
                    </Droplet>
                </DropletBox>
            </Grid>
            <Grid item xs={12} md={6}>
                <ContentBox>
                    <Stack rowGap={2}>
                        {num && (
                            <Typography variant="h4" color={"secondary"}>
                                {t(num)}
                            </Typography>
                        )}
                        {/*
                        <Typography
                            variant="h4"
                            sx={{ wordBreak: "break-word" }}
                        >
                            {t(title)}
                        </Typography>
                        */}
                        <Typography variant="body1">{t(subtitle)}</Typography>
                    </Stack>
                </ContentBox>
            </Grid>
        </Grid>
    );
};

IconWithContent.propTypes = {
    animationData: PropTypes.any,
    bgcolor: PropTypes.string,
    dropletPosition: PropTypes.string,
    icon: PropTypes.any,
    layoutDirection: PropTypes.string,
    num: PropTypes.string,
    size: PropTypes.number,
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

export default IconWithContent;
