//* translation
import { useTranslation } from "react-i18next";

//* react-hook-form
import { Controller, useFormContext } from "react-hook-form";

//* mui components import
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useDropzone } from "react-dropzone";

//* mui icons imports
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

//* custom components import
import {
    ControllerRadioField,
    ControllerSelectField,
    ControllerTextField,
} from "../../../mui-components/MuiDossierFormComponents";
import {
    documentArrTenantOne,
    documentArrTenantTwo,
    isYesNoOption,
    tenantType,
} from "../../../../options/dossierOptions";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useStateValue } from "../../../../store/StateProvider";
import ArrowButton from "../../../elements/button/ArrowButton";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Stack,
    Icon,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import useResponsive from "../../../../hooks/responsive/useResponsive";
import { useAddDossierDocument } from "../../../../hooks/dossier/useDossierDocument";
import { useQueryClient } from "react-query";

const baseStyle = {
    display: "flex",
    flexDirection: "column",
    border: "2px dashed #5BAFA9",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "200px",
    height: "100%",
    backgroundColor: "#fff",
    width: "100%",
    outline: "none",
    borderRadius: "8px",
    transition: "border, background-color .24s ease-in-out",
};

const focusedStyle = {
    border: "2px dashed #5BAFA9",
};

const acceptStyle = {
    backgroundColor: "rgba(0, 230, 118, 0.1)",
};

const rejectStyle = {
    backgroundColor: "rgba(255, 10, 10, 0.1)",
};

let documentArrT1 = documentArrTenantOne;
let documentArrT2 = documentArrTenantTwo;

const SelectedDocumentsList = ({ files, onRemove }) => {
    const { t } = useTranslation();
    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<KeyboardArrowDownIcon color="primary" />}
            >
                <Typography variant="h5"> {t("selected_document")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={2}>
                    {(Array.isArray(files) && files.length === 0) ||
                    files === null ? (
                        <Typography variant="subtitle1" textAlign={"center"}>
                            {t("not_selected_any_file")}
                        </Typography>
                    ) : (
                        files?.map((file, index) => (
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                key={`${file.path}-${index}`}
                                width={"100%"}
                            >
                                <Typography variant="body1">
                                    {file.path}
                                </Typography>
                                <IconButton
                                    aria-label="show document list"
                                    onClick={() => onRemove(index)}
                                    color="error"
                                >
                                    <Delete color="error" />
                                </IconButton>
                            </Box>
                        ))
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export const TenantDocument = ({ refetch }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [{ user_id }] = useStateValue();
    const { isBelowSm } = useResponsive();
    const {
        control,
        watch,
        getValues,
        setValue,
        onSubmitData,
        formState: { errors },
    } = useFormContext();

    const watchIsAnotherTenantMovingIn = watch("isAnotherTenantMovingIn");

    const watchTenantType = watch("tenantType");

    const watchDocumentTypeTenantOne = watch("documentTypeTenantOne");
    const watchDocumentTypeTenantTwo = watch("documentTypeTenantTwo");

    const { isLoading, mutate } = useAddDossierDocument();

    const [documentFile, setDocumentFile] = useState({
        passport_id: [],
        salary_statement: [],
        debt_collect: [],
        criminal_record: [],
        // other_docs: [],
        tenantTwoPassport: [],
        tenantTwoSalary: [],
        tenantTwoDebt: [],
        tenantTwoCriminal: [],
        // tenantTwoOtherDocs: [],
    });

    const handleRemoveFile = useCallback(
        (documentIdToRemove) => {
            // Update the documentFile state as well
            setDocumentFile((prevState) => {
                if (watchTenantType === "tenant_one") {
                    return {
                        ...prevState,
                        [watchDocumentTypeTenantOne]: prevState[
                            watchDocumentTypeTenantOne
                        ].filter((file, index) => index !== documentIdToRemove),
                    };
                } else {
                    return {
                        ...prevState,
                        [watchDocumentTypeTenantTwo]: prevState[
                            watchDocumentTypeTenantTwo
                        ].filter((file, index) => index !== documentIdToRemove),
                    };
                }
            });
        },
        [
            // setAcceptedFiles,
            setDocumentFile,
            watchDocumentTypeTenantOne,
            watchDocumentTypeTenantTwo,
            watchTenantType,
        ]
    );

    // for disabling the option value
    // const [disabledValue, setDisabledValue] = useState([]);

    const onDropDocument = useCallback(
        (acceptedFile) => {
            if (Array.isArray(acceptedFile) && acceptedFile.length > 0) {
                if (watchTenantType === "tenant_one") {
                    setDocumentFile((prevState) => {
                        let prevDocumentArr =
                            prevState[watchDocumentTypeTenantOne];

                        return {
                            ...prevState,
                            [watchDocumentTypeTenantOne]: [
                                ...prevDocumentArr,
                                ...acceptedFile,
                            ],
                        };
                    });
                    return;
                }
                return setDocumentFile((prevState) => {
                    let prevDocumentArr = prevState[watchDocumentTypeTenantTwo];

                    return {
                        ...prevState,
                        [watchDocumentTypeTenantTwo]: [
                            ...prevDocumentArr,
                            ...acceptedFile,
                        ],
                    };
                });
            }
        },
        [
            watchDocumentTypeTenantOne,
            watchDocumentTypeTenantTwo,
            watchTenantType,
        ]
    );

    const getDocumentTypeLabel = (value) => {
        switch (value) {
            case "passport_id":
                return "Passport";
            case "salary_statement":
                return "Salary Statement";
            case "debt_collect":
                return "Debt Collect";
            case "criminal_record":
                return "Criminal Record";
            case "other_docs":
                return "Other Docs";
            case "tenantTwoPassport":
                return "Tenant Two Passport";
            case "tenantTwoSalary":
                return "Tenant Two Salary";
            case "tenantTwoDebt":
                return "Tenant Two Debt";
            case "tenantTwoCriminal":
                return "Tenant Two Criminal";
            case "tenantTwoOtherDocs":
                return "Tenant Two Other Docs";

            default:
                break;
        }
    };

    const handleDisableValue = () => {
        if (watchTenantType === "tenant_one") {
            if (watchDocumentTypeTenantOne === "") return true;
            if (
                (Array.isArray(documentFile[watchDocumentTypeTenantOne]) &&
                    documentFile[watchDocumentTypeTenantOne].length === 0) ||
                documentFile[watchDocumentTypeTenantOne] === null
            )
                return true;
        } else {
            if (watchDocumentTypeTenantTwo === "") return true;

            if (
                (Array.isArray(documentFile[watchDocumentTypeTenantTwo]) &&
                    documentFile[watchDocumentTypeTenantTwo].length === 0) ||
                documentFile[watchDocumentTypeTenantTwo] === null
            )
                return true;
        }
    };

    const handleAllSubmitDoc = () => {
        let allValue = getValues();
        onSubmitData(allValue);
        handleSubmitDocument();
    };

    const handleSubmitDocument = () => {
        const formData = new FormData();

        formData.append("id", user_id);
        if (watchTenantType === "tenant_one") {
            formData.append("key", watchDocumentTypeTenantOne);
            documentFile[watchDocumentTypeTenantOne]?.forEach((file) =>
                formData.append("image", file)
            );
            formData.append(
                "documents_type",
                getDocumentTypeLabel(watchDocumentTypeTenantOne)
            );
        } else {
            formData.append("key", watchDocumentTypeTenantTwo);

            const files = documentFile[watchDocumentTypeTenantTwo];

            files.forEach((file) => formData.append("image", file));

            formData.append(
                "documents_type",
                getDocumentTypeLabel(watchDocumentTypeTenantTwo)
            );
        }

        formData.append("description", watch("documentComment"));
        formData.append("type", "");

        mutate(formData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    return;
                }

                let documentKey = data?.data?.key;
                if (documentKey && watchTenantType === "tenant_one") {
                    setDocumentFile((prevState) => ({
                        ...prevState,
                        [watchDocumentTypeTenantOne]: [],
                    }));
                } else {
                    setDocumentFile((prevState) => ({
                        ...prevState,
                        [watchDocumentTypeTenantTwo]: [],
                    }));
                }
                setValue("documentComment", "");

                toast.success("Document uploaded successfully");
                queryClient.invalidateQueries({
                    queryKey: ["get-dossier-documents"],
                });
            },
            onError: (errors) => {
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    const {
        getRootProps: getDocumentRootProps,
        getInputProps: getDocumentInputProps,
        isFocused: isDocumentFocused,
        isDragAccept: isDocumentDragAccept,
        isDragReject: isDocumentDragReject,
    } = useDropzone({
        onDrop: onDropDocument,
        accept: { "application/pdf": [".pdf"] },
    });

    const documentStyle = useMemo(
        () => ({
            ...baseStyle,
            ...(isDocumentFocused ? focusedStyle : {}),
            ...(isDocumentDragAccept ? acceptStyle : {}),
            ...(isDocumentDragReject ? rejectStyle : {}),
        }),
        [isDocumentFocused, isDocumentDragAccept, isDocumentDragReject]
    );

    return (
        <>
            <Grid item container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" sx={{ color: "primary" }}>
                        {t("upload_box")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box height={"75%"}>
                        <div
                            {...getDocumentRootProps({ style: documentStyle })}
                        >
                            <input {...getDocumentInputProps()} />
                            <Icon>upload</Icon>
                            <Typography
                                variant={"subtitle2"}
                                textAlign="center"
                            >
                                {t(
                                    "click_or_drag_drop_to_upload_document_type_pdf_png_jpg"
                                )}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                {isBelowSm && (
                    <Grid item xs={12}>
                        <SelectedDocumentsList
                            files={
                                watchTenantType === "tenant_one"
                                    ? documentFile[watchDocumentTypeTenantOne]
                                    : documentFile[watchDocumentTypeTenantTwo]
                            }
                            onRemove={handleRemoveFile}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            {watchTenantType === "tenant_one" && (
                                <Controller
                                    name="documentTypeTenantOne"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            fullWidth
                                            variant="filled"
                                            label={t("document_type")}
                                            SelectProps={{
                                                IconComponent:
                                                    ArrowDropDownCircleOutlinedIcon,
                                            }}
                                            {...field}
                                            error={!!errors?.documentType}
                                            helperText={t(
                                                errors?.documentType?.message
                                            )}
                                        >
                                            {documentArrT1.map((option) => {
                                                return (
                                                    <MenuItem
                                                        // disabled={disabledValue.includes(option.value)}
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {t(option.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    )}
                                />
                            )}

                            {watchTenantType === "tenant_two" && (
                                <Controller
                                    name="documentTypeTenantTwo"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            fullWidth
                                            variant="filled"
                                            label={t("document_type")}
                                            SelectProps={{
                                                IconComponent:
                                                    ArrowDropDownCircleOutlinedIcon,
                                            }}
                                            {...field}
                                            error={!!errors?.documentType}
                                            helperText={t(
                                                errors?.documentType?.message
                                            )}
                                        >
                                            {documentArrT2.map((option) => {
                                                return (
                                                    <MenuItem
                                                        // disabled={disabledValue.includes(option.value)}
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {t(option.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {watchTenantType === "tenant_one" &&
                                watchDocumentTypeTenantOne ===
                                    "debt_collect" && (
                                    <ControllerSelectField
                                        control={control}
                                        name="isNegativeTenantOneDebtEntry"
                                        label={
                                            t(
                                                "dept_register_got_a_negative_entry"
                                            ) + "*"
                                        }
                                    >
                                        {isYesNoOption?.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.value}
                                            >
                                                {t(option.label)}
                                            </MenuItem>
                                        ))}
                                    </ControllerSelectField>
                                )}
                            {watchTenantType === "tenant_two" &&
                                watchDocumentTypeTenantTwo ===
                                    "tenantTwoDebt" && (
                                    <ControllerSelectField
                                        control={control}
                                        name="isNegativeTenantTwoDebtEntry"
                                        label={
                                            t(
                                                "dept_register_got_a_negative_entry"
                                            ) + "*"
                                        }
                                    >
                                        {isYesNoOption?.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.value}
                                            >
                                                {t(option.label)}
                                            </MenuItem>
                                        ))}
                                    </ControllerSelectField>
                                )}
                        </Grid>
                        <Grid item xs={12} sm={7} ml={0.6}>
                            {watchIsAnotherTenantMovingIn === "yes" && (
                                <ControllerRadioField
                                    name="tenantType"
                                    label={
                                        t("document_is_for_Tenant_1_or_2") + "?"
                                    }
                                    control={control}
                                    errorFlag={!!errors?.tenantType}
                                    errorText={t(errors?.tenantType?.message)}
                                >
                                    {tenantType.map((document) => (
                                        <FormControlLabel
                                            key={document.id}
                                            label={t(document.label)}
                                            value={document.value}
                                            control={<Radio />}
                                        />
                                    ))}
                                </ControllerRadioField>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <ControllerTextField
                                control={control}
                                name="documentComment"
                                label={t("document_comment")}
                                errorFlag={!!errors?.documentComment}
                                errorText={t(errors?.documentComment?.message)}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            display={"flex"}
                            sx={{
                                justifyContent: {
                                    sm: "flex-end",
                                    xs: "center",
                                },
                            }}
                        >
                            <ArrowButton
                                variant="contained"
                                color="primary"
                                disabled={isLoading || handleDisableValue()}
                                onClick={handleAllSubmitDoc}
                                size="large"
                                fullWidth={isBelowSm}
                            >
                                {isLoading
                                    ? t("save_document") + "..."
                                    : t("save_document")}
                            </ArrowButton>
                        </Grid>
                    </Grid>
                </Grid>
                {!isBelowSm && (
                    <Grid item xs={12}>
                        <SelectedDocumentsList
                            files={
                                watchTenantType === "tenant_one"
                                    ? documentFile[watchDocumentTypeTenantOne]
                                    : documentFile[watchDocumentTypeTenantTwo]
                            }
                            onRemove={handleRemoveFile}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};
