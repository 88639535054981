// ControllerSliderComponent.js
import React from 'react';
import { Slider, Typography, Box } from '@mui/material';

const ControllerSliderComponent = ({ value, theme, onChange, min, max, step, label }) => {
    return (
        <Box sx={{ width: '100%', textAlign: 'left' }}>
            <Typography
                sx={{
                    marginBottom: 1,
                }}
                variant="h6"
            >
                {`${label}: ${value}`}
            </Typography>
            <Slider
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                step={step}
                min={min}
                max={max}
                valueLabelDisplay="auto"
                sx={{
                    '& .MuiSlider-rail': {
                        bgcolor: '#e0e0e0',
                        opacity: 1,
                        height: '12px',
                    },
                    '& .MuiSlider-track': {
                        bgcolor: theme.palette.secondary.main,
                        height: '16px',
                    },
                    '& .MuiSlider-thumb': {
                        color: '#40E0D0 !important',
                        bgcolor: theme.palette.primary.main,
                        '&:hover, &.Mui-focusVisible': {
                            boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main, 0.16}`,
                        },
                    },
                    '&.Mui-active': {
                        boxShadow: `0px 0px 0px 14px ${theme.palette.primary.main, 0.16}`,
                    },
                    '& .MuiSlider-valueLabel': {
                        fontSize: '1rem',
                    },
                }}
            />

        </Box>
    );
};

export default ControllerSliderComponent;
