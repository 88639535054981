//* mui icons imports
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import StairsIcon from "@mui/icons-material/Stairs";

export const PROPERTY_DETAILS = [
  {
    id: 1,
    icon: <StairsIcon sx={{ color: "#f98f55" }} />,
    type: "floor",
  },
  {
    id: 2,
    icon: <BedIcon sx={{ color: "#f98f55" }} />,
    type: "room",
  },
  {
    id: 3,
    icon: <ControlCameraIcon sx={{ color: "#f98f55" }} />,
    type: "living",

    isLast: true,
  },
];
