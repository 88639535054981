import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

//* translation
import { useTranslation } from "react-i18next";

//* react-hook-form
import { Controller, useFormContext } from "react-hook-form";

//* cookie
import cookies from "js-cookie";

//* libraries
// import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

//* google place api
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

//* api calls
//import useCities from "../../../../hooks/search/useCities";

//* custom components
import {
    ControllerAutoCompleteField,
    ControllerDateField,
    ControllerNumberField,
    ControllerPhoneTextField,
    ControllerRadioField,
    ControllerSelectField,
    ControllerTextField,
    FieldDottedLineSpacer,
} from "../../../mui-components/MuiDossierFormComponents";

import DossierFormSection from "../dossier_components/DossierFormSection";
import { DossierReferenceBox } from "../dossier_components/DossierReferenceBox";

//* custom options
import {
    REASON_TO_MOVE_OPTIONS,
    SmokeOptionArr,
    civilStatusArr,
    employmentRelationshipArr,
    interestedPartnerArr,
    isEmployedOption,
    isYesNoOption,
    movingOutOption,
    numberArray,
    permitOptionsArr,
    rentPaymentOption,
    tenantGender,
    tenantTitle,
    unemploymentStatusOption,
    vehicles,
    workload,
} from "../../../../options/dossierOptions";

//* mui components import
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

//* mui icons imports
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import InfoIcon from "@mui/icons-material/Info";

//*  import JSON data
import countryNames from "../../../../assets/JSON/countrybyname.json";

// *** by AAR
import TenantOneSegmentOne from "./segments/tenant_one/TenantOneSegmentOne";
import TenantOneSegmentTwo from "./segments/tenant_one/TenantOneSegmentTwo";
import TenantOneSegmentThree from "./segments/tenant_one/TenantOneSegmentThree";
import TenantOneSegmentFour from "./segments/tenant_one/TenantOneSegmentFour";
import TenantOneSegmentFive from "./segments/tenant_one/TenantOneSegmentFive";
import TenantOneSegmentSix from "./segments/tenant_one/TenantOneSegmentSix";
import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "./tenant_common_context/EditFormSectionContextSegment";

//* utils imports
import { isGreaterThanYear } from "../../../../utils/isGreaterThanYear";

//* custom progress bar
import { Checkbox, FormGroup } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ScoreProgress } from "../../../score-progress/ScoreProgress";
import AnnualSalaryRangeSelect from "../../../mui-components/AnnualSalaryRangeSelect";
import onSubmitData from "./helper/OnSubmitData";
import DossierSubStepper from "../dossier_components/DossierSubStepper";
import useResponsive from "../../../../hooks/responsive/useResponsive";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SummarizeIcon from "@mui/icons-material/Summarize";
import useIsSmartDossierPaid from "../../../../hooks/transaction/useIsSmartDossierPaid";
import { useStateValue } from "../../../../store";

/*
let getAllCountry = countryNames.map((item) => ({
    label: item.country,
    code: item.code,
    value: item.country.toLowerCase(),
}));
*/

export default function DossierTenantOne({
    referenceStatus,
    handleReferenceStatus,
    stepLabels,
    formSectionIcons,
    commonProps,
    sectionVariants,
    direction,
}) {
    const partnerRef = useRef();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const [getAllCountry, setAllCountry] = useState([]);

    const [{ user_id }] = useStateValue();

    const {
        isLoading: isLoadingPaymentStatus,
        data: paymentStatusData,
        status: getPaymentDataStatus,
    } = useIsSmartDossierPaid(user_id);

    let isPayment = paymentStatusData?.dossierPayment;

    console.log("isPayment", isPayment);

    useEffect(() => {
        async function loadCountries() {
            const countryData =
                currentLanguageCode === "de"
                    ? await import(
                          "../../../../assets/JSON/countrybyname_german.json"
                      )
                    : await import(
                          "../../../../assets/JSON/countrybyname.json"
                      );
            const formattedCountries = countryData.default.map((item) => ({
                label: item.country,
                code: item.code,
                value: item.country.toLowerCase(),
            }));
            setAllCountry(formattedCountries);
        }
        loadCountries();
    }, [currentLanguageCode]);

    const { t, theme, step, currentSection, handleStepClick } = commonProps;

    const reasonForMovingOptSort = useMemo(
        () =>
            REASON_TO_MOVE_OPTIONS.sort((a, b) =>
                t(a.label).localeCompare(t(b.label))
            ),
        [t]
    );

    const { state } = useLocation();
    let isTenantTwoRef = state?.isTenantTwo;

    const {
        control,
        watch,
        setValue,
        formState: { errors },
        score,
    } = useFormContext();

    const title = watch("title");

    const watchNationality = watch("nationality");
    const watchCountry = watch("country");
    const watchCity = watch("city");
    const watchZip = watch("zip");

    const watchEmployed = watch("employedOrNot");
    const watchUnemployedStatus = watch("unemploymentStatus");
    const watchRentPaymentMethod = watch("howYouPayRent");

    let isCountryFilled = watchCountry === "" || watchCountry === null;
    let isStreetDisable =
        watchCity === "" ||
        watchZip === "" ||
        watchCity === null ||
        watchZip === null;

    const hasApartmentCancelled = watch("hasApartmentCancelled");

    const watchIsImmediateAndAfterAgreement = watch(
        "isImmediateAndAfterAgreement"
    );
    const watchHasNoCurrentApartment = watch("hasNoCurrentApartment");

    const watchMovingOutOf = watch("movingOutOf");

    const watchChildren = watch("hasChildren");
    const watchNoOfChildren = watch("noOfChildren");
    const watchChildrensAverageAge = watch("childrensAverageAge");
    const watchSmoker = watch("isSmoker");
    const watchMusicalInstrument = watch("hasMusicalInstrument");
    const watchPets = watch("hasPet");
    const watchVehicle = watch("hasVehicle");
    const watchTypeOfVehicle = watch("typeOfVehicle");
    const watchIsParkingNeeded = watch("isParkingNeeded");

    const watchAnotherTenantMovingIn = watch("isAnotherTenantMovingIn");

    useEffect(() => {
        if (isTenantTwoRef) {
            partnerRef.current?.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [isTenantTwoRef]);

    const handleNoOfChildren = (e) => {
        let value = e.target.value;
        let intValue = value === "" ? value : parseInt(value, 10);

        if (intValue > 8) return e.preventDefault();

        let averageAge = [];
        for (let i = 1; i <= intValue; i++) {
            averageAge.push({ id: i, age: "" });
        }
        setValue("childrensAverageAge", averageAge);
    };

    return (
        <>
            <Grid
                container
                rowSpacing={2}
                my={2}
                mb={2}
                justifyContent="center"
            >
                <Grid item xs={12}>
                    <Stack
                        flexDirection={{ xs: "column", md: "row" }}
                        justifyContent={"space-between"}
                        alignContent={"center"}
                    >
                        <Stack
                            sx={{ width: "100%" }}
                            justifyContent={"center"}
                            alignContent={"center"}
                        >
                            <Typography
                                variant="h5"
                                textAlign={"center"}
                                mb={3}
                            >
                                {stepLabels[0] && stepLabels[0] !== "tenant_one"
                                    ? `${stepLabels[0]} ${t(
                                          "edit"
                                      ).toLowerCase()}`
                                    : t("edit_smart_dossier_tenant_1")}
                            </Typography>
                        </Stack>
                        <ScoreProgress size={"55px"} score={score} />
                    </Stack>
                </Grid>
                {/* sx={{color: theme.palette.secondary.main}} */}
                {
                    // *** by AAR
                }
                <Grid item xs={12} mb={2}>
                    <EditFormProviderSegment commonProps={commonProps}>
                        {/* SECTION 1 */}
                        <TenantOneSegmentOne
                            additionalProps={{
                                heading: t("who_are_you") + "?",
                                Icon: formSectionIcons[0].Icon,
                                index: 0,
                                currentLanguageCode,
                                getAllCountry,
                                watchNationality,
                                title,
                                control,
                                errors,
                                watch,
                                sectionVariants,
                                direction,
                            }}
                        />

                        {/* SECTION 2 */}
                        <TenantOneSegmentTwo
                            additionalProps={{
                                heading: t("where_do_you_live") + "?",
                                Icon: formSectionIcons[1].Icon,
                                index: 1,
                                currentLanguageCode,
                                getAllCountry,
                                isCountryFilled,
                                isStreetDisable,
                                control,
                                errors,
                                watch,
                                sectionVariants,
                                direction,
                            }}
                        />

                        {/* SECTION 3 */}
                        <TenantOneSegmentThree
                            additionalProps={{
                                heading:
                                    t(
                                        "how_can_you_be_reached_by_administrations"
                                    ) + "?",
                                Icon: formSectionIcons[2].Icon,
                                index: 2,
                                currentLanguageCode,
                                control,
                                errors,
                                watch,
                                sectionVariants,
                                direction,
                            }}
                        />

                        {/* SECTION 4 */}
                        <TenantOneSegmentFour
                            additionalProps={{
                                heading: t(
                                    "the_new_administration_asks_how_do_you_earn_your_money"
                                ),
                                Icon: formSectionIcons[3].Icon,
                                index: 3,
                                currentLanguageCode,
                                watchEmployed,
                                watchUnemployedStatus,
                                watchRentPaymentMethod,
                                referenceStatus,
                                handleReferenceStatus,
                                setValue,
                                control,
                                errors,
                                watch,
                                sectionVariants,
                                direction,
                                isPayment,
                            }}
                        />

                        {/* SECTION 5 */}
                        <TenantOneSegmentFive
                            additionalProps={{
                                heading: t(
                                    "the_new_administration_asks_how_did_you_live_before"
                                ),
                                Icon: formSectionIcons[4].Icon,
                                index: 4,
                                currentLanguageCode,
                                watchHasNoCurrentApartment,
                                watchMovingOutOf,
                                reasonForMovingOptSort,
                                watchIsImmediateAndAfterAgreement,
                                referenceStatus,
                                handleReferenceStatus,
                                control,
                                errors,
                                watch,
                                sectionVariants,
                                direction,
                                isPayment,
                            }}
                        />

                        {/* SECTION 6 */}
                        <TenantOneSegmentSix
                            additionalProps={{
                                heading: t(
                                    "what_else_is_good_to_share_with_the_new_administration"
                                ),
                                Icon: formSectionIcons[5].Icon,
                                index: 5,
                                watchChildren,
                                handleNoOfChildren,
                                watchNoOfChildren,
                                watchChildrensAverageAge,
                                watchSmoker,
                                watchMusicalInstrument,
                                watchVehicle,
                                watchTypeOfVehicle,
                                watchIsParkingNeeded,
                                watchPets,
                                partnerRef,
                                watchAnotherTenantMovingIn,
                                control,
                                errors,
                                watch,
                                sectionVariants,
                                direction,
                            }}
                        />
                    </EditFormProviderSegment>
                </Grid>
            </Grid>
        </>
    );
}
