import React from 'react';
import { Divider, Grid } from '@mui/material';

const CustomFormDivider = () => (
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingTop: '24px', paddingBottom: '8px' }}>
        <Divider style={{ width: '100%', backgroundColor: '#e0e0e0' }} />
    </Grid>
);

export default CustomFormDivider;
