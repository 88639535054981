import * as yup from "yup";

const step0Section4Schema = yup.object().shape({
    employedOrNot: yup.string().required("please_select_any"),

    // If not employed
    howYouPayRent: yup.string().when("employedOrNot", {
        is: "no",
        then: yup.string().required("please_select_any"),
    }),
    referenceName: yup.string().when("employedOrNot", {
        is: "no",
        then: yup.string().required("please_enter_a_name_for_reference"),
    }),
    referenceEmail: yup.string().when("employedOrNot", {
        is: "no",
        then: yup
            .string()
            .email("email_is_invalid")
            .required("please_enter_a_email_for_reference"),
    }),
    referencePhone: yup.string().when("employedOrNot", {
        is: "no",
        then: yup
            .string()
            .required("please_enter_your_mobile_no")
            .max(19, "please_enter_a_valid_phone_number"),
    }),

    // If employed
    profession: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_your_profession"),
    }),
    /*
    annualSalaryRangeId: yup.string().nullable().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_select_a_monthly_income"),
    }),
     */
    companyName: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_a_company_name_and_address"),
    }),
    companyAddress: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_a_company_name_and_address"),
    }),
    employeeName: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup.string().required("please_enter_name_of_a_employee"),
    }),
    employeeEmail: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup
            .string()
            .email("please_enter_a_valid_email")
            .required("please_enter_email_of_a_employee"),
    }),
    employeePhoneNo: yup.string().when("employedOrNot", {
        is: "yes",
        then: yup
            .string()
            .required("please_enter_your_mobile_no")
            .max(19, "please_enter_a_valid_phone_number"),
    }),
});

export default step0Section4Schema;
