import { useQuery } from 'react-query'
import { Axios } from '../../axios/axios'

// const token = localStorage.getItem("nch_id");

function getUserDetails({ queryKey }) {
  const id = queryKey[1]

  return Axios.get(`users/profile?id=${id}`, {
    // headers: {
    //     "x-access-token": token,
    // },
  })
}

export default function useUserProfile(id) {
  return useQuery(['user_details', id], getUserDetails, {
    enabled: Boolean(id),
  })
}
