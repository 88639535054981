export const priceRange = [
    {
        id: 1,
        label: "any",
        value: "",
    },
    {
        id: 2,
        label: "500",
        value: "500",
    },
    {
        id: 3,
        label: "600",
        value: "600",
    },
    {
        id: 4,
        label: "700",
        value: "700",
    },
    {
        id: 5,
        label: "800",
        value: "800",
    },
    {
        id: 6,
        label: "900",
        value: "900",
    },
    {
        id: 7,
        label: "1000",
        value: "1000",
    },
    {
        id: 8,
        label: "1100",
        value: "1100",
    },
    {
        id: 9,
        label: "1200",
        value: "1200",
    },
    {
        id: 10,
        label: "1300",
        value: "1300",
    },
    {
        id: 11,
        label: "1400",
        value: "1400",
    },
    {
        id: 12,
        label: "1500",
        value: "1500",
    },
    {
        id: 13,
        label: "1600",
        value: "1600",
    },
    {
        id: 14,
        label: "1700",
        value: "1700",
    },
    {
        id: 15,
        label: "1800",
        value: "1800",
    },
    {
        id: 16,
        label: "1900",
        value: "1900",
    },
    {
        id: 17,
        label: "2000",
        value: "2000",
    },
    {
        id: 18,
        label: "2200",
        value: "2200",
    },
    {
        id: 19,
        label: "2400",
        value: "2400",
    },
    {
        id: 20,
        label: "2600",
        value: "2600",
    },
    {
        id: 21,
        label: "2800",
        value: "2800",
    },
    {
        id: 22,
        label: "3000",
        value: "3000",
    },
    {
        id: 23,
        label: "3500",
        value: "3500",
    },
    {
        id: 24,
        label: "4000",
        value: "4000",
    },
    {
        id: 25,
        label: "4500",
        value: "4500",
    },
    {
        id: 26,
        label: "5000",
        value: "5000",
    },
    {
        id: 27,
        label: "5500",
        value: "5500",
    },
    {
        id: 28,
        label: "6000",
        value: "6000",
    },
    {
        id: 29,
        label: "7000",
        value: "7000",
    },
    {
        id: 30,
        label: "8000",
        value: "8000",
    },
    {
        id: 31,
        label: "9000",
        value: "9000",
    },
    {
        id: 32,
        label: "10000",
        value: "10000",
    },
];

export const roomRange = [
    {
        id: 1,
        label: "any",
        value: "",
    },
    {
        id: 2,
        label: "1",
        value: "1",
    },
    {
        id: 3,
        label: "1.5",
        value: "1.5",
    },
    {
        id: 4,
        label: "2",
        value: "2",
    },
    {
        id: 5,
        label: "2.5",
        value: "2.5",
    },
    {
        id: 6,
        label: "3",
        value: "3",
    },
    {
        id: 7,
        label: "3.5",
        value: "3.5",
    },
    {
        id: 8,
        label: "4",
        value: "4",
    },
    {
        id: 9,
        label: "4.5",
        value: "4.5",
    },
    {
        id: 10,
        label: "5",
        value: "5",
    },
    {
        id: 11,
        label: "5.5",
        value: "5.5",
    },
    {
        id: 12,
        label: "6",
        value: "6",
    },
    {
        id: 13,
        label: "6.5",
        value: "6.5",
    },
    {
        id: 14,
        label: "7",
        value: "7",
    },
    {
        id: 15,
        label: "7.5",
        value: "7.5",
    },
    {
        id: 16,
        label: "8",
        value: "8",
    },
];

export const spaceRange = [
    {
        id: 1,
        label: "any",
        value: "",
    },
    {
        id: 2,
        label: "20",
        value: "20",
    },
    {
        id: 3,
        label: "30",
        value: "30",
    },
    {
        id: 4,
        label: "40",
        value: "40",
    },
    {
        id: 5,
        label: "50",
        value: "50",
    },
    {
        id: 6,
        label: "60",
        value: "60",
    },
    {
        id: 7,
        label: "70",
        value: "70",
    },
    {
        id: 8,
        label: "80",
        value: "80",
    },
    {
        id: 9,
        label: "90",
        value: "90",
    },
    {
        id: 10,
        label: "100",
        value: "100",
    },
    {
        id: 11,
        label: "110",
        value: "110",
    },
    {
        id: 12,
        label: "120",
        value: "120",
    },
    {
        id: 13,
        label: "140",
        value: "140",
    },
    {
        id: 14,
        label: "160",
        value: "160",
    },
    {
        id: 15,
        label: "180",
        value: "180",
    },
    {
        id: 16,
        label: "200",
        value: "200",
    },
    {
        id: 17,
        label: "250",
        value: "250",
    },
    {
        id: 18,
        label: "300",
        value: "300",
    },
];
