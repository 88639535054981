import * as React from "react";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";

function InstagramIcon(props) {
    return (
        <SvgIcon
            {...props}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM12.9127 7.64971H21.4572C24.3594 7.64971 26.7199 10.0182 26.7199 12.9289V21.5002C26.7199 24.4109 24.3594 26.7794 21.4572 26.7794H12.9127C10.0105 26.7794 7.64998 24.4115 7.64998 21.5002V12.9289C7.64998 10.0182 10.0105 7.64971 12.9127 7.64971ZM21.4573 25.0823C23.4264 25.0823 25.028 23.4757 25.028 21.5004V12.9291C25.028 10.9542 23.426 9.34718 21.4573 9.34718H12.9128C10.9435 9.34718 9.34203 10.9542 9.34203 12.9291V21.5004C9.34203 23.4757 10.9435 25.0823 12.9128 25.0823H21.4573ZM17.1849 12.3263C14.4977 12.3263 12.3115 14.5193 12.3115 17.2149C12.3115 19.9107 14.4977 22.1037 17.1849 22.1037C19.8722 22.1037 22.0584 19.9107 22.0584 17.2149C22.0584 14.5193 19.8722 12.3263 17.1849 12.3263ZM17.1849 20.4204C15.423 20.4204 13.9897 18.9826 13.9897 17.215C13.9897 15.4475 15.423 14.0097 17.1849 14.0097C18.947 14.0097 20.3803 15.4475 20.3803 17.215C20.3803 18.9826 18.947 20.4204 17.1849 20.4204ZM21.3721 11.2111C21.6082 10.9737 21.9361 10.838 22.2702 10.838C22.6055 10.838 22.9336 10.9737 23.1696 11.2111C23.4061 11.4478 23.5416 11.7769 23.5416 12.1133C23.5416 12.4483 23.4061 12.7774 23.1696 13.0153C22.9324 13.2521 22.6055 13.3886 22.2702 13.3886C21.9361 13.3886 21.6082 13.2521 21.3721 13.0153C21.135 12.7774 20.9989 12.4483 20.9989 12.1133C20.9989 11.7769 21.135 11.4478 21.3721 11.2111Z"
            />
        </SvgIcon>
    );
}

export default function SvgIconsSize() {
    return (
        <Stack alignItems="center" sx={{ display: "inline-block" }}>
            <InstagramIcon fontSize="large" />
        </Stack>
    );
}
