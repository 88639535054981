import { useMutation, useQuery } from "react-query";
import { Axios } from "../../axios/axios";

//* create property
function createProperty(propertyDetail) {
    return Axios.post("/property/create_property", propertyDetail);
}

export function useCreateProperty() {
    return useMutation(createProperty);
}

//* Add details
function updatePropertyDetail(propertyDetail) {
    return Axios.post("/property/update_property_controller", propertyDetail);
}

export function useUpdatePropertyDetail() {
    return useMutation(updatePropertyDetail);
}

//* Get Property detail created by user

// function getUserPropertyDetail(propertyId) {
//     return Axios.post("/property/property_by_user_id", {
//         id: propertyId,
//         lang: "en",
//     });
// }

// export function useGetUserPropertyDetail() {
//     return useMutation(getUserPropertyDetail);
// }

function getUserPropertyDetail({ queryKey }) {
    let { propertyId, lang } = queryKey[1];
    return Axios.get(
        `/property/property_by_user_id?id=${propertyId}&lang=${lang}`
    );
}

export function useGetUserPropertyDetail(propertyId, lang = "en") {
    return useQuery(
        ["get-user-property-detail", { propertyId, lang }],
        getUserPropertyDetail
    );
}

function deletePropertyImage(data) {
    return Axios.post("/property/delete_property_image", data);
}

export function useDeletePropertyImage() {
    return useMutation(deletePropertyImage);
}
