import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useSalaryRange } from "../../hooks/dossier/useSalaryRange";
import Loading from "../loading/Loading";

const getSalaryRangeLabel = (salary) => {
    return salary.upper_limit === null
        ? `${salary.lower_limit} CHF or above`
        : `${salary.lower_limit} - ${salary.upper_limit} CHF`;
};

function AnnualSalaryRangeSelect({
    textFieldProps,
    onSelect,
    error,
    helperText,
}) {
    const { t } = useTranslation();
    const { data, isLoading } = useSalaryRange();
    let salaryRange = data?.data?.result;

    return (
        <TextField
            variant="filled"
            fullWidth
            select
            label={t("gross_annual_salary_incl_13")}
            SelectProps={{
                IconComponent: ArrowDropDownCircleOutlinedIcon,
            }}
            sx={{ mt: 1, mb: 1 }}
            error={error}
            helperText={helperText}
            {...textFieldProps}
        >
            {isLoading ? (
                <Loading />
            ) : (
                salaryRange.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {getSalaryRangeLabel(option)}
                    </MenuItem>
                ))
            )}
        </TextField>
    );
}

export default AnnualSalaryRangeSelect;
