// ConfirmationDialog.jsx

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ArrowButton from '../../../../../component/elements/button/ArrowButton';

const ConfirmationDialog = ({ open, onClose, step, t }) => {
    return (
        <Dialog
            open={open}
            onClose={() => onClose(false, step)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {/*
            <DialogTitle id="alert-dialog-title">{"Änderungen speichern?"}</DialogTitle>
            */}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t("save_&_proceed_question")}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <ArrowButton variant="text" onClick={() => onClose(false)} arrowStart>
                    {t("no")}
                </ArrowButton>
                <ArrowButton variant="text" onClick={() => onClose(true)} autoFocus>
                    {t("save_&_proceed")}
                </ArrowButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;