import React, { useCallback, useMemo, useState } from "react";

//*  using toast for success and error
import { toast } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* react-hook-form
import { useFormContext } from "react-hook-form";

//* mui component
import CircularProgress from "@mui/material/CircularProgress";

//* store imports
import { useStateValue } from "../../../../store/StateProvider";

//*  libraries
import { useDropzone } from "react-dropzone";

//* image imports
import { profilePlaceholder } from "../../../../assets/imgImport";

//* mui components import

//* custom components
import { Img } from "../../../img/Img";
import EditImage from "../../../edit-image/EditImage";
import { useUploadProfilePicture } from "../../../../hooks/profile/useProfilePicture";

//* imageUrl = Dynamic image path
const imageUrl = process.env.REACT_APP_PROFILE_IMAGE_URL;

const baseProfileStyle = {
    // flex: 1,

    outlineStyle: "dashed",
    outlineColor: "#d3dae363",
    outlineWidth: "2px",
    backgroundColor: "#d3dae363",
    height: "160px",
    width: "160px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    outlineColor: "#2196f3",
};

const acceptStyle = {
    outlineColor: "#00e676",
};

const rejectStyle = {
    outlineColor: "#ff1744",
};

export const TenantProfile = ({ profileFor, profileKey }) => {
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const { setValue, watch } = useFormContext();
    const [editProfileModal, setEditProfileModal] = useState({
        open: false,
        image: null,
    });
    const { isLoading, mutate } = useUploadProfilePicture();

    // const [newProfileImg, setNewProfileImg] = useState(null);

    const onOpenCropImageModal = (image) => {
        setEditProfileModal({
            open: true,
            image: image,
        });
    };
    const onCloseCropImageModal = (image) => {
        setEditProfileModal((prevState) => ({ ...prevState, open: false }));
    };

    const onDropProfileImage = useCallback((droppedFiles) => {
        if (Array.isArray(droppedFiles) && droppedFiles?.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                onOpenCropImageModal(reader.result);
            };
            reader.readAsDataURL(droppedFiles[0]);
        }
    }, []);

    const {
        getRootProps: getProfileRootProps,
        getInputProps: getProfileInputProps,
        isFocused: isProfileFocused,
        isDragAccept: isProfileDragAccept,
        isDragReject: isProfileDragReject,
    } = useDropzone({
        accept: {
            "image/*": [".jpeg", ".jpg", ".png"],
        },
        onDrop: onDropProfileImage,
        multiple: false,
    });

    const profileStyle = useMemo(
        () => ({
            ...baseProfileStyle,
            ...(isProfileFocused ? focusedStyle : {}),
            ...(isProfileDragAccept ? acceptStyle : {}),
            ...(isProfileDragReject ? rejectStyle : {}),
        }),
        [isProfileDragAccept, isProfileDragReject, isProfileFocused]
    );

    const watchProfile = watch("profilePic");
    const watchProfileTwo = watch("tenantTwoProfilePic");

    const onAddProfile = (imageObject) => {
        const formData = new FormData();
        formData.append("id", user_id);
        formData.append("key", profileKey);
        formData.append("image", imageObject);
        mutate(formData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    return;
                }
                let uploadedImage = data?.data?.img_name;
                toast.success("Profile Image uploaded successfully");
                if (profileKey === "profile_pic") {
                    setValue("profilePic", uploadedImage);
                } else {
                    setValue("tenantTwoProfilePic", uploadedImage);
                }
            },
            onError: (errors) => {
                console.error(errors);
            },
        });
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            height: "160px",
                            width: "160px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <div {...getProfileRootProps({ style: profileStyle })}>
                        <input {...getProfileInputProps()} />
                        {profileKey === "profile_pic" ? (
                            <Img
                                alt={"profile"}
                                src={
                                    watchProfile
                                        ? imageUrl + watchProfile
                                        : profilePlaceholder
                                }
                                style={{
                                    height: "160px",
                                    width: "160px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                }}
                            />
                        ) : (
                            <Img
                                alt={"profile"}
                                src={
                                    watchProfileTwo
                                        ? imageUrl + watchProfileTwo
                                        : profilePlaceholder
                                }
                                style={{
                                    height: "160px",
                                    width: "160px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                }}
                            />
                        )}
                    </div>
                )}
                {profileKey === "profile_pic" ? (
                    <p
                        style={{
                            color: "GrayText",
                            fontSize: "12px",
                        }}
                    >
                        {watchProfile === null
                            ? "Click or drag'n'drop"
                            : t("click_or_drag_drop_to_update")}
                    </p>
                ) : (
                    <p
                        style={{
                            color: "GrayText",
                            fontSize: "12px",
                        }}
                    >
                        {watchProfileTwo === null
                            ? "Click or drag'n'drop"
                            : t("click_or_drag_drop_to_update")}
                    </p>
                )}
            </div>
            <EditImage
                open={editProfileModal.open}
                onClose={onCloseCropImageModal}
                image={editProfileModal.image}
                onSubmit={onAddProfile}
                saving={isLoading}
            />
        </>
    );
};
