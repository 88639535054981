import React, { forwardRef } from "react";

//* component imports
import ContactSeller from "../../properties/property-details/property-advertiser/ContactSeller";

//* mui components imports
import AppBar from "@mui/material/AppBar";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";

//* mui icons imports
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const PropertyMobileContactSellerModal = ({
  open,
  handleClose,
  name,
  price,
  propertyId,
  propertyOwnerId,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", bgcolor: "white" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Apply for Property
          </Typography>
          <IconButton
            edge='start'
            color='primary'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ContactSeller
        name={name}
        price={price}
        propertyId={propertyId}
        propertyOwnerId={propertyOwnerId}
      />
    </Dialog>
  );
};
