import SearchIcon from '@mui/icons-material/Search';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const NAVIGATION_OPTIONS_ADMIN_TEST = [
    // { id: 1, name: "features", link: "/features" },
    //{ id: 1, name: "about_nextflat", link: "/about" },
    { id: 1, name: "flats", link: "/explore-list", icon: <SearchIcon /> },
    { id: 2, name: "partnership", link: "/partnership", icon: <DescriptionIcon /> },
    { id: 3, name: "faq", link: "/faq", icon: <HelpOutlineIcon /> },
    //{ id: 2, name: "property_new_tenant_list_of_flats_short", link: "/create-object" },
    //{ id: 3, name: "contact", link: "/contact" },
    //{ id: 5, name: "Explore", link: "/explore" },
    //{ id: 3, name: "advertise", link: "/create-object" },
];
