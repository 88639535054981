import React from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    TitleSpacer,
    ControllerRadioField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import FormControl from "@mui/material/FormControl";

import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import {
    isEmployedOption,
    isYesNoOption,
} from "../../../../../../options/dossierOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";

const TenantTwoSegmentTwo = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        watchAddressAsFirstTenant,
        getAllCountry,
        isCountryFilled,
        isStreetDisable,
        control,
        errors,
        sectionVariants,
        direction,
    } = additionalProps;

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="sameAddressAsFirstTenant"
                            label={
                                t(
                                    "living_at_the_same_address_as_first_tenant"
                                ) + "?"
                            }
                            data={isYesNoOption}
                            errorFlag={!!errors?.sameAddressAsFirstTenant}
                            errorText={t(
                                errors?.sameAddressAsFirstTenant?.message
                            )}
                        />
                        {/*

                        <ControllerRadioField
                            name="sameAddressAsFirstTenant"
                            label={
                                t(
                                    "living_at_the_same_address_as_first_tenant"
                                ) + "?"
                            }
                            control={control}
                            errorFlag={
                                !!errors?.sameAddressAsFirstTenant
                            }
                            errorText={t(
                                errors?.sameAddressAsFirstTenant
                                    ?.message
                            )}
                        >
                            {isYesNoOption.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    label={t(option.label)}
                                    value={option.value}
                                    control={<Radio/>}
                                />
                            ))}
                        </ControllerRadioField>
                        */}
                    </Grid>
                </Grid>
                {watchAddressAsFirstTenant === "no" && (
                    <>
                        {/* company section splitter */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TitleSpacer label={t("address")} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoZip"
                                label={t("postcode") + "*"}
                                disabled={isCountryFilled}
                                errorFlag={!!errors?.tenantTwoZip}
                                errorText={t(errors?.tenantTwoZip?.message)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoCity"
                                label={t("city") + "*"}
                                disabled={isCountryFilled}
                                errorFlag={!!errors?.tenantTwoCity}
                                errorText={t(errors?.tenantTwoCity?.message)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <FormControl variant="filled" fullWidth>
                                <ControllerTextField
                                    control={control}
                                    name="tenantTwoStreetAddress"
                                    label={t("street_address") + "*"}
                                    disabled={isStreetDisable}
                                    errorFlag={!!errors?.tenantTwoStreetAddress}
                                    errorText={t(
                                        errors?.tenantTwoStreetAddress?.message
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <ControllerTextField
                                control={control}
                                name="tenantTwoStreetNo"
                                label={t("street_no")}
                                disabled={isStreetDisable}
                                errorFlag={!!errors?.tenantTwoStreetNo}
                                errorText={t(
                                    errors?.tenantTwoStreetNo?.message
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <ControllerAutoCompleteField
                                label={t("country") + "*"}
                                name="tenantTwoCountry"
                                options={getAllCountry}
                                disableClearable
                                errorFlag={!!errors?.tenantTwoCountry}
                                errorText={t(errors?.tenantTwoCountry?.message)}
                            />
                        </Grid>

                        {/*
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                >
                                    <ControllerDateField
                                        control={control}
                                        name="residingThereSince"
                                        label={t("residing_there_since")}
                                        errorFlag={
                                            !!errors?.residingThereSince
                                        }
                                        errorText={t(
                                            errors?.residingThereSince
                                                ?.message
                                        )}
                                        lang={currentLanguageCode}
                                    />
                                </Grid>
                                */}
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <ControllerDateField
                                control={control}
                                name="tenantTwoResidingThereSince"
                                label={t("residing_there_since")}
                                lang={currentLanguageCode}
                                //   errorFlag={!!errors?.tenantTwoResidingThereSince}
                                //   errorText={t(
                                //     errors?.tenantTwoResidingThereSince?.message
                                //   )}
                            />
                        </Grid>
                    </>
                )}
                {watchAddressAsFirstTenant === "yes" && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ControllerDateField
                            control={control}
                            name="tenantTwoResidingThereSince"
                            label={t("residing_there_since")}
                            lang={currentLanguageCode}
                            //   errorFlag={!!errors?.tenantTwoResidingThereSince}
                            //   errorText={t(
                            //     errors?.tenantTwoResidingThereSince?.message
                            //   )}
                        />
                    </Grid>
                )}
            </Grid>
        </DossierFormSection>
    );
};

export default TenantTwoSegmentTwo;
