//* mui imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material";



//* import required modules

import { ORGANIZE_DOSSIER } from "../../options/organizeDossier";
import { useTranslation } from "react-i18next";
import Droplet from "../shapes/Droplet";
// import { useState } from "react";
import useResponsive from "../../hooks/responsive/useResponsive";

const ContentBox = styled(Box)(() => ({
   // padding: "0px",
}));



const OrganizeSmartDossier = () => {
   //  const [thumbsSwiper, setThumbsSwiper] = useState(null);

   const { t } = useTranslation();
   const theme = useTheme();

   const { isBelowCustom } = useResponsive(1265);

   if (isBelowCustom) {
      return (
         <ContentBox>
            <Grid container rowSpacing={10}>
               {ORGANIZE_DOSSIER.map((org) => {
                  return (
                     <Grid key={org.id} item xs={12}>
                        <Stack rowGap={10} alignItems={"center"}>
                           <Droplet
                              position={"right"}
                              size={342}
                              bgcolor={"secondaryLight"}
                              containerStyleProps={{
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                              }}
                           >
                              <Box
                                 sx={{
                                    border: `10px solid ${theme.palette.secondary.main}`,
                                    borderRadius: 5,
                                    height: 400,
                                    width: 190,
                                    bgcolor: theme.palette.common.white,
                                 }}
                              >
                                 {/* {org.id} */}
                              </Box>
                           </Droplet>
                           <Stack spacing={2.5} width={343}>
                              <Typography variant='h2'>
                                 {t(org.title)}
                              </Typography>
                              <Typography variant='body1'>
                                 {t(org.description)}
                              </Typography>
                           </Stack>
                        </Stack>
                     </Grid>
                  );
               })}
            </Grid>
         </ContentBox>
      );
   }

   return (
      <ContentBox>
         <Box
            sx={{
               position: "relative",
            }}
         >
          
            <Droplet
               position={"right"}
               size={450}
               bgcolor={"secondaryLight"}
               containerStyleProps={{
                  position: "absolute",
                  top: "50%",
                  right: 0,
                  transform: "translate(-20%, -50%)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <Box
                  sx={{
                     border: `10px solid ${theme.palette.secondary.main}`,
                     borderRadius: 5,
                     height: 492,
                     width: 234,
                     bgcolor: theme.palette.common.white,
                     overflow: "hidden",
                  }}
               >
              
               </Box>
            </Droplet>
         </Box>
      </ContentBox>
   );
};

export default OrganizeSmartDossier;
