import * as yup from "yup";

export const ForgotSchema = yup.object().shape({
    email: yup
        .string()
        .email("please_enter_a_valid_email")
        .required("email_is_required")
        .min(6, "email_must_be_between_6_and_254_characters_long")
        .max(254, "email_must_be_between_6_and_254_characters_long"),
});
