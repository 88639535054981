export const PropertyCardAnimation = {
   hidden: { opacity: 0, scale: 0 },
   visible: {
      opacity: 1,
      scale: 1,
      transition: {
         delayChildren: 0.2,
         duration: 1,
         // staggerChildren: 0.2,
      },
   },
};

export const animateOnShow = {
   hidden: { opacity: 0 },
   visible: {
      opacity: 1,
      transition: {
         duration: 1,
      },
   },
};

export const smartDossierIntroTextBox = {
   hidden: { opacity: 0, y: 75 },
   visible: {
      opacity: 1,
      y: 0,
      transition: {
         duration: 0.5,
         delay: 0.25,
      },
   },
};

export const revealContainer = {
   hidden: { opacity: 0, y: 75 },
   visible: {
      opacity: 1,
      y: 0,
      transition: {
         duration: 0.5,
         delay: 0.2,
         staggerChildren: 0.3,
         delayChildren: 0.5,
      },
   },
};

export const revealItem = {
   hidden: { opacity: 0, y: 100 },
   visible: {
      opacity: 1,
      y: 0,

      transition: {
         duration: 0.5,
      },
   },
};

export const smartDossierBenefitBox = {
   hidden: { opacity: 0 },
   visible: {
      opacity: 1,
      transition: {
         staggerChildren: 0.3,
      },
   },
};

export const smartDossierBenefit = {
   hidden: { opacity: 0, x: -50 },
   visible: {
      opacity: 1,
      x: 0,
      transition: {
         duration: 0.8,
      },
   },
};

export const slideUp = {
   hidden: { y: 10, opacity: 0 },
   visible: { y: 0, opacity: 1, transition: { duration: 0.25, delay: 0.2 } },
};

export const revealNav = {
   hidden: {},
   visible: {
      transition: {
         staggerChildren: 0.3,
         startDelay: 0.3,
         staggeredDirection: 1,
      },
   },
};

export const revealList = {
   hidden: { opacity: 0, filter: "blur(10px)", transform: "scale(.5)" },
   visible: {
      opacity: 1,
      transform: "scale(1)",
      filter: "blur(0px)",
   },
};

export const enterAndExit = {
   hidden: { opacity: 0, transition: { duration: 0.25, delay: 0.2 } },
   visible: {
      opacity: 1,
      transition: { duration: 0.25, delay: 0.2 },
   },
};
