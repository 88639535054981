//* mui component
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

//* router
import {Link} from "react-router-dom";

//* option
import FOOTER_LINKS from "../../options/footerLinks";
import {socialMediaLinks} from "../../options/socialLinks";

import {useGetAdminInfoData} from "../../hooks/static-pages/useGetAdminInfoData";
import cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {
    adminAddress,
    getFooterInfo,
    phoneNumber,
    showEmail,
} from "../../utils/getFooter";

const FooterContainer = styled(Stack)(({theme}) => ({
    backgroundColor: theme.palette.secondary.light,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    overflow: "hidden",
}));

const FooterBox = styled(Box)(({theme}) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 100,
    paddingBottom: 100,
}));

const FooterHeader = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.contrastText,
}));

const FooterLink = styled(Typography, {
    shouldForwardProp: (props) => props !== "underline",
})(({theme, underline}) => ({
    color: theme.palette.primary.contrastText,
    textDecoration: underline ? "underline" : "none",
    fontWeight: 300,
}));

const FooterDarkLink = styled(Typography, {
    shouldForwardProp: (props) => props !== "underline",
})(({theme, underline}) => ({
    color: theme.palette.primary.dark,
    textDecoration: underline ? "underline" : "none",
    fontWeight: 300,
}));

function FooterMainContentLoading() {
    return (
        <Stack rowGap={2.5}>
            <Skeleton animation="wave" sx={{width: 200, height: 50}}/>
            <Stack>
                <Skeleton animation="wave" sx={{width: 310}}/>
                <Skeleton animation="wave" sx={{width: 300}}/>
                <Skeleton animation="wave" sx={{width: 310}}/>
                <Skeleton animation="wave" sx={{width: 280}}/>
            </Stack>
        </Stack>
    );
}

function Footer() {
    const {t} = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const {isLoading, data} = useGetAdminInfoData(currentLanguageCode);

    const company = adminAddress(data);

    return (
        <footer style={{marginTop: "auto", paddingTop: 40}}>
            <FooterContainer>
                <FooterBox>
                    <Container maxWidth="lg">
                        <Grid container columnSpacing={4} rowSpacing={10}>
                            <Grid item xs={12} md={6}>
                                {isLoading ? (
                                    <FooterMainContentLoading/>
                                ) : (
                                    <Stack rowGap={2.5}>
                                        <Typography
                                            variant="h5"
                                            color={"white"}
                                        >
                                            {t(
                                                "nextflat_unites_you_with_your_dream_object"
                                            )}
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={"white"}
                                        >
                                            {
                                                t(
                                                getFooterInfo(
                                                    data,
                                                    "footer_description"
                                                )
                                            )
                                            }
                                        </Typography>
                                    </Stack>
                                )}
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Stack rowGap={3.75}>
                                    <FooterHeader variant="h4">
                                        nextflat AG
                                    </FooterHeader>

                                    <Stack rowGap={2.5}>
                                        {FOOTER_LINKS.map((link) => (
                                            <FooterLink
                                                component={Link}
                                                key={link.id}
                                                to={link.to}
                                            >
                                                {t(link.title)}
                                            </FooterLink>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Stack rowGap={3.75}>
                                    <FooterHeader variant="h4">
                                        {t("support")}
                                    </FooterHeader>
                                    {isLoading ? (
                                        <Skeleton
                                            animation="wave"
                                            sx={{width: 200}}
                                        />
                                    ) : (
                                        <Stack>
                                            <FooterLink
                                                component={Link}
                                                to={"/contact"}
                                            >
                                                {t(company.name)}
                                            </FooterLink>
                                            {company.street !== "" && (
                                                <FooterLink
                                                    component={Link}
                                                    to={"/contact"}
                                                >
                                                    {company.street}
                                                </FooterLink>
                                            )}
                                            {company.city !== "" && (
                                                <FooterLink
                                                    component={Link}
                                                    to={"/contact"}
                                                >
                                                    {company.city}
                                                </FooterLink>
                                            )}

                                            <FooterLink
                                                sx={{
                                                    mt: 2,
                                                }}
                                                component={"a"}
                                                href={`mailto:${t(
                                                    showEmail(data)
                                                )}`}
                                            >
                                                {t(showEmail(data))}
                                            </FooterLink>
                                            <FooterLink
                                                component={"a"}
                                                href={`tel:${t(
                                                    phoneNumber(data)
                                                )}`}
                                            >
                                                {t(phoneNumber(data))}
                                            </FooterLink>
                                        </Stack>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Stack rowGap={3.75}>
                                    <FooterHeader variant="h4">
                                        {t("Follow Us")}
                                    </FooterHeader>
                                    {isLoading ? (
                                        <Skeleton
                                            animation="wave"
                                            sx={{width: 200}}
                                        />
                                    ) : (
                                        <Stack
                                            flexDirection={"row"}
                                            gap={"10px"}
                                        >
                                            {socialMediaLinks.map((social) => (
                                                <FooterLink
                                                    component={"a"}
                                                    href={social.link}
                                                    key={social.key}
                                                    target="_blank"
                                                >
                                                    {social.icon}
                                                </FooterLink>
                                            ))}
                                        </Stack>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </FooterBox>
                <Container maxWidth="lg">
                    <Grid container spacing={2} py={3.125}>
                        <Grid item xs={12} md={4}>
                            <FooterDarkLink
                                component={Link}
                                to={"/terms-conditions"}
                            >
                                {t("terms_and_condition")}
                            </FooterDarkLink>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FooterDarkLink
                                component={Link}
                                to={"/privacy-policy"}
                            >
                                {t("privacy_policy")}
                            </FooterDarkLink>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FooterDarkLink component={Link} to={"/imprint"}>
                                {t("imprint")}
                            </FooterDarkLink>
                        </Grid>
                    </Grid>
                </Container>
            </FooterContainer>
        </footer>
    );
}

export default Footer;
