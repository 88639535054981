import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function addProfileDetail(profileData) {
    return Axios.post("/users/new_upload_profile_web", profileData);
}

export default function useAddProfileDetail() {
    return useMutation(addProfileDetail);
}
