//* mui component
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import ApartmentIcon from "@mui/icons-material/Apartment";
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import StairsIcon from "@mui/icons-material/Stairs";
import UndoIcon from "@mui/icons-material/Undo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

export default function PropertyDetailLoadingDesktopView() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Container maxWidth="lg" sx={{ p: 5 }}>
            <Grid container rowSpacing={1}>
                {/* Property Heading */}
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Button
                                sx={{
                                    p: 2,
                                    backgroundColor: "transparent",
                                    color: "primary.main",
                                    boxShadow: "none",
                                    border: "none",
                                }}
                                startIcon={<UndoIcon />}
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                {t("back")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Property Images */}
                <Grid item xs={12}>
                    <div className="gallery">
                        <Skeleton variant="rectangular" height={"inherit"} />
                        <Skeleton variant="rectangular" height={"inherit"} />
                        <Skeleton variant="rectangular" height={"inherit"} />
                        <Skeleton variant="rectangular" height={"inherit"} />
                        <Skeleton variant="rectangular" height={"inherit"} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            <Skeleton width={"50%"} height={50} />
                        </Typography>
                        <Typography variant="body1">
                            <Skeleton width={"40%"} height={50} />
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Grid item container>
                        <Grid item xs={12} md={8} p={2}>
                            {/* Features and Advertiser and contact Details */}
                            <Stack spacing={5}>
                                <Stack spacing={2}>
                                    <Typography variant="h5">
                                        {t("main_features")}
                                    </Typography>

                                    <Grid container rowSpacing={1}>
                                        <Grid item md={8}>
                                            <Grid item container rowSpacing={1}>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        spacing={1}
                                                    >
                                                        <ApartmentIcon
                                                            fontSize="medium"
                                                            sx={{
                                                                color: theme
                                                                    .palette
                                                                    .secondary
                                                                    .main,
                                                            }}
                                                        />
                                                        <Typography
                                                            component={"p"}
                                                        >
                                                            <Skeleton
                                                                width={90}
                                                                height={40}
                                                            />
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        spacing={1}
                                                    >
                                                        <BedIcon
                                                            fontSize="large"
                                                            sx={{
                                                                color: theme
                                                                    .palette
                                                                    .secondary
                                                                    .main,
                                                            }}
                                                        />
                                                        <Typography
                                                            component={"p"}
                                                        >
                                                            <Skeleton
                                                                width={90}
                                                                height={40}
                                                            />
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        spacing={1}
                                                    >
                                                        <StairsIcon
                                                            fontSize="large"
                                                            sx={{
                                                                color: theme
                                                                    .palette
                                                                    .secondary
                                                                    .main,
                                                            }}
                                                        />
                                                        <Typography
                                                            component={"p"}
                                                        >
                                                            <Skeleton
                                                                width={90}
                                                                height={40}
                                                            />
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        spacing={1}
                                                    >
                                                        <ControlCameraIcon
                                                            fontSize="large"
                                                            sx={{
                                                                color: theme
                                                                    .palette
                                                                    .secondary
                                                                    .main,
                                                            }}
                                                        />
                                                        <Typography
                                                            component={"p"}
                                                        >
                                                            <Skeleton
                                                                width={90}
                                                                height={40}
                                                            />
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Stack
                                                p={2}
                                                borderRadius={16}
                                                border={1}
                                                borderColor={
                                                    theme.palette.secondary.main
                                                }
                                                width={"fit-content"}
                                                sx={{
                                                    background: "#FFFFFF",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    <Skeleton
                                                        width={90}
                                                        height={40}
                                                    />
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                spacing={1}
                                                sx={{ pt: 1 }}
                                            >
                                                <AccessTimeIcon
                                                    fontSize="large"
                                                    sx={{
                                                        color: theme.palette
                                                            .secondary.main,
                                                    }}
                                                />
                                                <Typography component={"p"}>
                                                    <Skeleton
                                                        width={90}
                                                        height={40}
                                                    />
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Divider variant="middle" />
                                <Stack spacing={3}>
                                    <Typography variant="h5">
                                        {t("description")}
                                    </Typography>
                                    <Typography
                                        component={"p"}
                                        sx={{ color: "#717171" }}
                                    >
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </Typography>
                                </Stack>
                                <Divider
                                    sx={{ backgroundColor: "#f0bb9e61" }}
                                />
                            </Stack>
                        </Grid>
                        {/* Contact Seller details */}

                        <Grid
                            item
                            xs={12}
                            md={4}
                            p={2}
                            id="contact-seller-section"
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 5,
                                    borderRadius: "16px",
                                    position: "sticky",
                                    top: "100px",
                                    border: "1px solid",
                                    borderColor: theme.palette.secondary.main,
                                }}
                            >
                                <Stack spacing={4} alignItems={"center"}>
                                    <Stack spacing={3}>
                                        <Skeleton width={90} height={20} />
                                        <Skeleton width={90} height={20} />
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
