import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    TitleSpacer,
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import FormControl from "@mui/material/FormControl";

import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import cookies from "js-cookie";

const TenantOneSegmentTwo = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        getAllCountry,
        isCountryFilled,
        isStreetDisable,
        control,
        errors,
        sectionVariants,
        direction,
    } = additionalProps;

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <ControllerTextField
                        control={control}
                        name="zip"
                        label={t("postcode") + "*"}
                        disabled={isCountryFilled}
                        errorFlag={!!errors?.zip}
                        errorText={t(errors?.zip?.message)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <ControllerTextField
                        control={control}
                        name="city"
                        label={t("city") + "*"}
                        disabled={isCountryFilled}
                        errorFlag={!!errors?.city}
                        errorText={t(errors?.city?.message)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <FormControl variant="filled" fullWidth>
                        <ControllerTextField
                            control={control}
                            name="streetAddress"
                            label={t("street_address") + "*"}
                            disabled={isStreetDisable}
                            errorFlag={!!errors?.streetAddress}
                            errorText={t(errors?.streetAddress?.message)}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <ControllerTextField
                        control={control}
                        name="streetNo"
                        label={t("street_no") + "*"}
                        disabled={isStreetDisable}
                        errorFlag={!!errors?.streetNo}
                        errorText={t(errors?.streetNo?.message)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <ControllerAutoCompleteField
                        label={t("country") + "*"}
                        name="country"
                        options={getAllCountry}
                        disableClearable
                        errorFlag={!!errors?.country}
                        errorText={t(errors?.country?.message)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ControllerDateField
                        control={control}
                        name="residingThereSince"
                        label={t("residing_there_since")}
                        errorFlag={!!errors?.residingThereSince}
                        errorText={t(errors?.residingThereSince?.message)}
                        lang={currentLanguageCode}
                    />
                </Grid>
            </Grid>
        </DossierFormSection>
    );
};

export default TenantOneSegmentTwo;
