import React from "react";

//* map component
import Map from "../../../map/Map";

//* mui components imports
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const PropertyMobileLocation = ({ latitude, longitude, title }) => {
    return (
        <Stack rowGap={4} sx={{ height: "500px" }} mt={4} mb={10}>
            <Typography variant="h5">{title}</Typography>

            <Box
                sx={{
                    width: "100%",
                    height: 400,
                    borderRadius: "16px",
                    overflow: "hidden",
                    border: 1,
                    borderColor: "primary.main",
                }}
            >
                <Map
                    isSingleLocation={true}
                    latitude={latitude}
                    longitude={longitude}
                />
            </Box>
        </Stack>
    );
};
