import React, {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

//* translation
import { useTranslation } from "react-i18next";

import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";

//*  api calls
import useCities from "../../../hooks/search/useCities";
import { useCategories } from "../../../hooks/search/useSearchOptions";

//* mui components imports
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

//* mui icons imports
import CloseIcon from "@mui/icons-material/Close";
import MultiselectButton from "../../elements/button/MultiselectButton";
import {
    priceRange,
    roomRange,
    spaceRange,
} from "../../../static_options/filterOptions";
import { MenuItem, Skeleton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { checkForEmpty } from "../../../utils/checkDataFunction";

const Transition = forwardRef(function Transition(props, ref) {
    return <Grow direction="up" ref={ref} {...props} />;
});

const SelectTextField = ({
    label,
    name,
    value,
    onChange,
    children,
    ...props
}) => {
    return (
        <TextField
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{
                IconComponent: ArrowDropDownCircleOutlinedIcon,
                displayEmpty: true,
            }}
            variant="filled"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            {...props}
        >
            {children}
        </TextField>
    );
};

const CategoryLoading = () => {
    return [1, 2, 3, 4].map((category) => {
        return (
            <MultiselectButton key={`load-cat-${category}`}>
                <Skeleton width={100} />
            </MultiselectButton>
        );
    });
};

const getSelectedCityName = (placeType, zip, name) => {
    if (placeType === "") return "";
    if (placeType === "canton") return name;
    return `${zip} ${name}`;
};

const defaultValues = {
    city_id: "",
    city_name: "",
    zip: "",
    short_name: "",
    place_type: "",
    selectedCityName: "",
    type_id: 2,
    category_id: "",
    category_name: "",
    floor: "",
    space_from: "",
    space_to: "",
    // number_bedroom:"",
    price_from: "",
    price_to: "",
    room_from: "",
    room_to: "",
    aminity: "",
};

function FilterModal({ open, handleClose, refetchProperties }) {
    const { t } = useTranslation();
    const { data: categoryOption } = useCategories();
    const [searchTerm, setSearchTerm] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    const getSearchParams = useCallback(
        (name) => {
            let value = searchParams.get(name);
            if (checkForEmpty(value) === "") return "";
            return value;
        },
        [searchParams]
    );

    const [filterValue, setFilterValue] = useState(defaultValues);

    useEffect(() => {
        setFilterValue({
            city_id: getSearchParams("city_id"),
            city_name: getSearchParams("city_name"),
            zip: getSearchParams("zip"),
            short_name: getSearchParams("short_name"),
            place_type: getSearchParams("place_type"), //type of place like is it a canton or zip etc
            selectedCityName: getSelectedCityName(
                getSearchParams("place_type"),
                getSearchParams("zip"),
                getSearchParams("city_name")
            ),
            type_id: 2,
            category_id: getSearchParams("category_id"),
            category_name: getSearchParams("category_name"),
            floor: getSearchParams("floor"),
            space_from: getSearchParams("space_from"),
            space_to: getSearchParams("space_to"),
            // number_bedroom:getSearchParams("number_bedroom") ,
            price_from: getSearchParams("price_from"),
            price_to: getSearchParams("price_to"),
            room_from: getSearchParams("room_from"),
            room_to: getSearchParams("room_to"),
            aminity:
                searchParams.getAll("amenities").length === 0
                    ? ""
                    : searchParams.getAll("amenities"),
        });
    }, [getSearchParams, searchParams]);

    const { isLoading: isCityLoading, data: cityData } = useCities(searchTerm);
    const allCities = cityData?.data?.data;

    const getCategoryOptions = useMemo(
        () =>
            categoryOption?.map((options) => ({
                value: options.id,
                label: options.name,
            })),
        [categoryOption]
    );

    const { isLoading: isCategoryLoading, data: categoryData } =
        useCategories();

    const getCategoryName = (categoryId) => {
        let categoryObj = categoryData?.find(
            (category) => category.id === categoryId
        );
        if (!categoryObj) return "Not Available";
        return categoryObj?.name;
    };

    const onClearFilter = () => {
        setFilterValue(defaultValues);
    };

    const handleFilterOption = (event) => {
        const { name, value } = event.target;
        setFilterValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleFilterValue = (name, value) => {
        setFilterValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCategorySelect = (id, name) => {
        if (id === filterValue?.category_id) {
            setFilterValue((prevState) => ({
                ...prevState,
                category_id: "",
                category_name: "",
            }));
        } else {
            setFilterValue((prevState) => ({
                ...prevState,
                category_id: id,
                category_name: name,
            }));
        }
    };

    const getFullCityName = (name, zip) => {
        if (zip) {
            return `${zip} ${name} `;
        }
        return name;
    };

    const disableRangeFromItem = (selectedRangeToValue, rangeFromItem) => {
        if (selectedRangeToValue === "" || rangeFromItem === "") return false;
        let selectedRangeToValueNumber = Number(selectedRangeToValue);
        let rangeFromItemToNumber = Number(rangeFromItem);
        return selectedRangeToValueNumber < rangeFromItemToNumber;
    };

    const disableRangeToItem = (selectedRangeFromValue, rangeToItem) => {
        if (selectedRangeFromValue === "" || rangeToItem === "") return false;
        let selectedRangeFromValueNumber = Number(selectedRangeFromValue);
        let rangeToItemToNumber = Number(rangeToItem);
        return selectedRangeFromValueNumber > rangeToItemToNumber;
    };

    const onViewMore = () => {
        let viewMoreOptions = {
            city_id: filterValue.city_id,
            city_name: filterValue.city_name,
            zip: filterValue.zip,
            short_name: filterValue.short_name,
            place_type: filterValue.place_type,
            category_id: filterValue.category_id,
            category_name: filterValue.category_name,
            floor: filterValue.floor,
            space_from: filterValue.space_from,
            space_to: filterValue.space_to,
            room_from: filterValue.room_from,
            room_to: filterValue.room_to,
            price_from: filterValue.price_from,
            price_to: filterValue.price_to,
        };

        setSearchParams(viewMoreOptions);
        refetchProperties();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{
                style: {
                    maxWidth: "800px",
                    minWidth: 700,
                },
            }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle
                id="scroll-dialog-title"
                textAlign={"center"}
                display="flex"
                justifyContent={"space-between"}
                variant="h4"
            >
                {t("filters")}
                <IconButton onClick={handleClose} aria-label="delete">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Stack
                    direction={"column"}
                    spacing={2}
                    divider={<Divider variant="middle" flexItem />}
                >
                    <Box>
                        <Autocomplete
                            id="city-search"
                            getOptionLabel={(option) =>
                                typeof option === "string"
                                    ? option
                                    : getFullCityName(option.name, option.zip)
                            }
                            filterOptions={(x) => x}
                            isOptionEqualToValue={(option, value) => {
                                return option && option.id === value.id;
                            }}
                            options={allCities ?? []}
                            popupIcon={<ArrowDropDownCircleOutlinedIcon />}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            noOptionsText="No locations"
                            value={filterValue?.selectedCityName}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let shortName = newValue?.ShortName
                                        ? newValue.ShortName
                                        : "";
                                    let zip = newValue?.zip ? newValue.zip : "";
                                    let cityName =
                                        newValue?.type === "canton"
                                            ? newValue.name
                                            : `${newValue?.zip} ${newValue?.name}`;

                                    handleFilterValue("city_id", newValue.id);
                                    handleFilterValue("short_name", shortName);
                                    handleFilterValue(
                                        "city_name",
                                        newValue.name
                                    );
                                    handleFilterValue("zip", zip);
                                    handleFilterValue(
                                        "place_type",
                                        newValue?.type
                                    );
                                    handleFilterValue(
                                        "selectedCityName",
                                        cityName
                                    );
                                } else {
                                    handleFilterValue(
                                        "selectedCityName",
                                        newValue
                                    );
                                }
                            }}
                            loading={isCityLoading}
                            inputValue={searchTerm}
                            onInputChange={(event, newInputValue) => {
                                setSearchTerm(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("search_city")}
                                    fullWidth
                                    variant="filled"
                                />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography variant="body1">
                                                {getFullCityName(
                                                    option?.name,
                                                    option?.zip
                                                )}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                fontSize={14}
                                            >
                                                {option?.type}
                                            </Typography>
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography variant="h6" mb={2}>
                            {t("property_type")}
                        </Typography>
                        <Stack direction={"row"} gap={2} flexWrap="wrap">
                            {isCategoryLoading ? (
                                <CategoryLoading />
                            ) : (
                                getCategoryOptions?.map((category) => {
                                    return (
                                        <MultiselectButton
                                            key={category.value}
                                            selected={
                                                category?.value ===
                                                Number(filterValue?.category_id)
                                            }
                                            onClick={() =>
                                                handleCategorySelect(
                                                    category?.value,
                                                    category?.label?.toLowerCase()
                                                )
                                            }
                                        >
                                            {t(
                                                getCategoryName(
                                                    category?.value
                                                ).toLowerCase()
                                            )}
                                        </MultiselectButton>
                                    );
                                })
                            )}
                        </Stack>
                    </Box>
                    {/* room */}
                    <FormControl fullWidth>
                        <Typography variant="h6" mb={2}>
                            {t("rooms")}
                        </Typography>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            gap={2}
                        >
                            <SelectTextField
                                label={t("from")}
                                fullWidth={true}
                                name={"room_from"}
                                value={filterValue.room_from}
                                onChange={handleFilterOption}
                            >
                                {roomRange.map((room) => {
                                    return (
                                        <MenuItem
                                            key={`room-from-${room.id}`}
                                            value={room.value}
                                            disabled={disableRangeFromItem(
                                                filterValue.room_to,
                                                room.value
                                            )}
                                        >
                                            {room.label}
                                        </MenuItem>
                                    );
                                })}
                            </SelectTextField>
                            <SelectTextField
                                label={t("to")}
                                fullWidth={true}
                                name={"room_to"}
                                value={filterValue.room_to}
                                onChange={handleFilterOption}
                            >
                                {roomRange.map((room) => {
                                    return (
                                        <MenuItem
                                            key={`room-to-${room.id}`}
                                            value={room.value}
                                            disabled={disableRangeToItem(
                                                filterValue.room_from,
                                                room.value
                                            )}
                                        >
                                            {room.label}
                                        </MenuItem>
                                    );
                                })}
                            </SelectTextField>
                        </Stack>
                    </FormControl>
                    {/* living space */}
                    <FormControl fullWidth>
                        <Typography variant="h6" mb={2}>
                            {t("living_space")}
                        </Typography>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            gap={2}
                        >
                            <SelectTextField
                                label={t("from")}
                                fullWidth={true}
                                name={"space_from"}
                                value={filterValue.space_from}
                                onChange={handleFilterOption}
                            >
                                {spaceRange.map((space) => {
                                    return (
                                        <MenuItem
                                            key={`space-from-${space.id}`}
                                            value={space.value}
                                            disabled={disableRangeFromItem(
                                                filterValue.space_to,
                                                space.value
                                            )}
                                        >
                                            {space.label}&nbsp;
                                            {space.value === "" ? null : (
                                                <>
                                                    m<sup>2</sup>
                                                </>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </SelectTextField>
                            <SelectTextField
                                label={t("to")}
                                fullWidth={true}
                                name={"space_to"}
                                value={filterValue.space_to}
                                onChange={handleFilterOption}
                            >
                                {spaceRange.map((space) => {
                                    return (
                                        <MenuItem
                                            key={`space-to-${space.id}`}
                                            value={space.value}
                                            disabled={disableRangeToItem(
                                                filterValue.space_from,
                                                space.value
                                            )}
                                        >
                                            {space.label}&nbsp;
                                            {space.value === "" ? null : (
                                                <>
                                                    m<sup>2</sup>
                                                </>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </SelectTextField>
                        </Stack>
                    </FormControl>
                    {/* price  */}
                    <FormControl fullWidth>
                        <Typography variant="h6" mb={2}>
                            {t("budget")}
                        </Typography>

                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            gap={2}
                        >
                            <SelectTextField
                                label={t("from")}
                                fullWidth={true}
                                name="price_from"
                                value={filterValue.price_from}
                                onChange={handleFilterOption}
                            >
                                {priceRange.map((price) => {
                                    return (
                                        <MenuItem
                                            key={`price-from-${price.id}`}
                                            value={price.value}
                                            disabled={disableRangeFromItem(
                                                filterValue.price_to,
                                                price.value
                                            )}
                                        >
                                            {price.label}&nbsp;
                                            {price.value === "" ? null : (
                                                <>CHF</>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </SelectTextField>
                            <SelectTextField
                                label={t("to")}
                                fullWidth={true}
                                name="price_to"
                                value={filterValue.price_to}
                                onChange={handleFilterOption}
                            >
                                {priceRange.map((price) => {
                                    return (
                                        <MenuItem
                                            key={`price-to-${price.id}`}
                                            value={price.value}
                                            disabled={disableRangeToItem(
                                                filterValue.price_from,
                                                price.value
                                            )}
                                        >
                                            {price.label}&nbsp;
                                            {price.value === "" ? null : (
                                                <>CHF</>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </SelectTextField>
                        </Stack>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClearFilter} disableRipple>
                    {t("clear")}
                </Button>
                <Button variant="contained" onClick={onViewMore}>
                    {t("show_property")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FilterModal;
