import * as yup from "yup";

const step0Section1Schema = yup.object().shape({
    tenantTwoStreetAddress: yup
        .string()
        .nullable()
        .when("sameAddressAsFirstTenant", {
            is: "no",
            then: yup
                .string()
                .nullable()
                .required("please_enter_your_current_home_address"),
        }),
    tenantTwoStreetNo: yup.string().when("sameAddressAsFirstTenant", {
        is: "no",
        then: yup.string().nullable().required("please_enter_your_street_no"),
    }),

    tenantTwoZip: yup.string().when("sameAddressAsFirstTenant", {
        is: "no",
        then: yup.string().nullable().required("please_enter_your_zip_code"),
    }),

    tenantTwoCity: yup.mixed().when("sameAddressAsFirstTenant", {
        is: "no",
        then: yup.mixed().nullable().required("please_enter_your_city"),
    }),
    tenantTwoCountry: yup.mixed().when("sameAddressAsFirstTenant", {
        is: "no",
        then: yup.mixed().nullable().required("please_select_your_country"),
    }),
});

export default step0Section1Schema;
