import * as yup from "yup";

const twelveYearInMilliSeconds = 16 * 365 * 24 * 60 * 60 * 1000;

// const step0Section0Schema = yup.object().shape({
//     tenantTwoSalutation: yup.string().required("please_select_your_gender"),
//     tenantTwoFirstName: yup
//         .string()
//         .required("please_enter_your_first_name")
//         .min(2, "name_should_be_more_than_2_characters")
//         .max(200, "name_can_not_exceed_200_characters"),
//     tenantTwoLastName: yup
//         .string()
//         .required("please_enter_your_last_name")
//         .min(2, "name_should_be_more_than_2_characters")
//         .max(200, "name_can_not_exceed_200_characters"),
//     tenantTwoDateOfBirth: yup
//         .date("please_enter_a_valid_date")
//         .nullable()
//         .typeError("please_enter_a_valid_date")
//         .required("please_enter_your_year_of_birth")
//         .max(
//             new Date(Date.now() - twelveYearInMilliSeconds),
//             "your_age_should_be_more_than_19"
//         ),
//     // section two
//     // tenantTwoCountry: yup.mixed().when("sameAddressAsFirstTenant", {
//     //   is: "no",
//     //   then: yup.mixed().required("please_select_your_country"),
//     // }),
//     tenantTwoNationality: yup
//         .mixed()
//         .required("please_select_your_nationality"),
//     tenantTwoResidencyPermit: yup
//         .string()
//         .when(["sameAddressAsFirstTenant", "tenantTwoNationality"], {
//             is: (sameAddressAsFirstTenant, tenantTwoNationality) => {
//                 if (sameAddressAsFirstTenant === "no") {
//                     let country =
//                         tenantTwoNationality &&
//                         typeof tenantTwoNationality === "string"
//                             ? tenantTwoNationality
//                             : tenantTwoNationality?.value;
//                     return country?.toLowerCase() !== "switzerland";
//                 }
//             },
//             then: yup
//                 .string()
//                 .nullable()
//                 .required("please_select_residency_permit"),
//         }),
//     tenantTwoDateOfEntry: yup
//         .mixed()
//         .nullable()
//         .when(["sameAddressAsFirstTenant", "tenantTwoNationality"], {
//             is: (sameAddressAsFirstTenant, tenantTwoNationality) => {
//                 if (sameAddressAsFirstTenant === "no") {
//                     let country =
//                         tenantTwoNationality &&
//                         typeof tenantTwoNationality === "string"
//                             ? tenantTwoNationality
//                             : tenantTwoNationality?.value;
//                     return country?.toLowerCase() !== "switzerland";
//                 }
//             },
//             then: yup.mixed().nullable().required("please_enter_date_of_entry"),
//         }),
// });

const step0Section0Schema = yup.object().shape({
    tenantTwoSalutation: yup
        .string()
        .nullable()
        .required("please_select_your_gender"),
    tenantTwoOtherTitle: yup.string().when("tenantTwoTitle", {
        is: "other",
        then: yup.string().required("please_enter_your_title"),
    }),
    tenantTwoFirstName: yup
        .string()
        .required("please_enter_your_first_name")
        .min(2, "name_should_be_more_than_2_characters")
        .max(255, "name_can_not_exceed_200_characters"),
    tenantTwoLastName: yup
        .string()
        .required("please_enter_your_last_name")
        .min(2, "name_should_be_more_than_2_characters")
        .max(255, "name_can_not_exceed_200_characters"),
    tenantTwoDateOfBirth: yup
        .date()
        .nullable()
        .typeError("please_enter_a_valid_date")
        .required("please_enter_your_year_of_birth")
        .max(
            new Date(Date.now() - twelveYearInMilliSeconds),
            "your_age_should_be_more_than_19"
        ),
    tenantTwoNationality: yup
        .mixed()
        .required("please_select_your_nationality"),
    tenantTwoResidencyPermit: yup.string().when("tenantTwoNationality", {
        is: (tenantTwoNationality) => {
            let country =
                tenantTwoNationality && typeof tenantTwoNationality === "string"
                    ? tenantTwoNationality
                    : tenantTwoNationality?.value;
            return country?.toLowerCase() !== "switzerland";
        },
        then: yup
            .string()
            .nullable()
            .required("please_select_residency_permit"),
    }),
    tenantTwoDateOfEntry: yup
        .mixed()
        .nullable()
        .when("tenantTwoNationality", {
            is: (tenantTwoNationality) => {
                let countryName =
                    tenantTwoNationality &&
                    typeof tenantTwoNationality === "string"
                        ? tenantTwoNationality
                        : tenantTwoNationality?.value;
                return countryName?.toLowerCase() !== "switzerland";
            },
            then: yup.mixed().nullable().required("please_enter_date_of_entry"),
        }),
});

export default step0Section0Schema;
