// ----------------------------------------------------------------------

function Autocomplete(theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    ".MuiAutocomplete-input": {
                        marginLeft: "10px",
                    },
                },

                popper: {
                    "& .MuiPaper-root": {
                        borderRadius: "30px",
                        marginTop: "5px",
                        boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.10)",
                        ".MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused":
                            {
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.secondary.main,
                            },
                        // ".MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused.Mui-selected":
                        //     {
                        //         backgroundColor: theme.palette.secondary.main,
                        //         color: theme.palette.common.white,
                        //     },
                        ".MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected=true]":
                            {
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.common.white,
                            },
                    },
                },
            },
        },
    };
}

export default Autocomplete;
