import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useTranslation } from "react-i18next";
import DossierDocumentTable from "../../../table/dossier-document/DossierDocumentTable";
import { Stack } from "@mui/material";
import { useGetDossierDocuments } from "../../../../hooks/dossier/useDossierDocument";
import { useStateValue } from "../../../../store/StateProvider";
import Loading from "../../../loading/Loading";

const AccordionDocument = ({ title, defaultExpanded, children }) => {
    return (
        <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
                expandIcon={<KeyboardArrowDownOutlined />}
                aria-label="Expand"
                aria-controls="-content"
                id="-header"
            >
                <Typography variant="h5">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

function ViewDossierDocument({ stepLabels, isAnotherTenantMovingIn }) {
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const {
        isLoading: isLoadingDocuments,
        data: getAllDocumentData,
        status: getAllDocumentStatus,
        refetch: refetchDocument,
    } = useGetDossierDocuments(user_id);

    let dossierDocuments = getAllDocumentData?.data?.data;

    const documentTypeArray =
        getAllDocumentStatus === "success" ? Object.keys(dossierDocuments) : [];

    const tenantOneDocumentTypeArray = documentTypeArray?.filter(
        (type) => !type.includes("tenantTwo")
    );

    const tenantTwoDocumentTypeArray = documentTypeArray?.filter((type) =>
        type.includes("tenantTwo")
    );

    const tmpTenantOneFirstName =
        stepLabels[0] && stepLabels[0] !== "tenant_one"
            ? stepLabels[0]
            : t("tenant_one");
    const tmpTenantTwoFirstName =
        stepLabels[1] && stepLabels[1] !== "tenant_two"
            ? stepLabels[1]
            : t("tenant_two");

    return (
        <Stack rowGap={3}>
            <AccordionDocument
                title={tmpTenantOneFirstName}
                defaultExpanded={true}
            >
                {isLoadingDocuments ? (
                    <Loading />
                ) : (
                    <DossierDocumentTable
                        documentTypes={tenantOneDocumentTypeArray}
                        documents={dossierDocuments}
                        refetchDocument={refetchDocument}
                    />
                )}
            </AccordionDocument>
            {isAnotherTenantMovingIn && (
                <AccordionDocument
                    title={tmpTenantTwoFirstName}
                    defaultExpanded={true}
                >
                    {isLoadingDocuments ? (
                        <Loading />
                    ) : (
                        <DossierDocumentTable
                            documentTypes={tenantTwoDocumentTypeArray}
                            documents={dossierDocuments}
                            refetchDocument={refetchDocument}
                        />
                    )}
                </AccordionDocument>
            )}
        </Stack>
    );
}

export default ViewDossierDocument;
