import * as yup from "yup";

const createObjectFormSchema = yup.object().shape({
    //advertiser: yup.string().required("please_select_customer_segment"),
    //type: yup.string().required("please_select_type"),
    category: yup.mixed().required("please_select_category"),
    //country: yup.string().required("please_select_a_country"),
    city: yup.mixed().required("please_enter_a_city_name"),
    // zipcode: yup.string().required("Please enter a zipcode"),
});
export default createObjectFormSchema