import PropTypes from "prop-types";
import { forwardRef } from "react";
//* mui components import
import Grow from "@mui/material/Grow";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import { Controller } from "react-hook-form";
import { Icon, InputAdornment } from "@mui/material";
import { useState } from "react";

function PaperComponent(paperProps, ref) {
    return (
        <Grow in>
            <Paper {...paperProps} ref={ref} />
        </Grow>
    );
}

const PaperComponentForward = forwardRef(PaperComponent);

function ControllerAutoCompleteField({
    label,
    control,
    name,
    options,
    errorFlag,
    errorText,
    disableClearable,
    textFieldProps,
    autocompleteProps,
    InputProps,
    handleSelect,
}) {
    const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

    const handleAutocompleteChange = (event, newValue) => {
        if (newValue) {
            handleSelect(event, newValue);
            setAutocompleteInputValue("");
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setAutocompleteInputValue(newInputValue);
    };
    /*
    onChange={(event, value) => {
       field?.onChange(value);
    }}
    */
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    options={options}
                    disableClearable={disableClearable}
                    onChange={handleAutocompleteChange}
                    inputValue={autocompleteInputValue}
                    onInputChange={handleInputChange}
                    value={null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant="filled"
                            error={errorFlag}
                            helperText={errorText}
                            InputProps={{
                                ...params.InputProps,
                                ...InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon color="primary">search</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            {...textFieldProps}
                        />
                    )}
                    {...autocompleteProps}
                />
            )}
        />
    );
}

ControllerAutoCompleteField.propTypes = {
    autocompleteProps: PropTypes.object,
    control: PropTypes.any,
    disableClearable: PropTypes.bool,
    errorFlag: PropTypes.bool,
    errorText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.any,
    textFieldProps: PropTypes.object,
};

export default ControllerAutoCompleteField;
