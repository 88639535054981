//* mui components import
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//* mui icons imports
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useTheme} from "@mui/material";

function GoodMatchChanceModal({show, handleClose, matchChance, t}) {
    const theme = useTheme();

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            PaperProps={{
                style: {borderRadius: 10},
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                <Typography variant='h5'>
                    {t("good_match_potential")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container minWidth={"450px"} rowSpacing={3}>
                    <Grid item xs={6}>
                        <Grid item container>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {matchChance?.alert_property_type === "" ? (
                                    <CheckCircleIcon sx={{color: "#717171"}}/>
                                ) : (
                                    <CheckCircleIcon sx={{color: "secondary.main"}}/>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='h6'>
                                    {t("property_type")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item container>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {matchChance?.budget_prefrence === "" ? (
                                    <CheckCircleIcon sx={{color: "#717171"}}/>
                                ) : (
                                    <CheckCircleIcon sx={{color: "secondary.main"}}/>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='h6'>
                                    {t("budget_preference")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item container>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {matchChance?.alert_badrooms === "undefined" ? (
                                    <CheckCircleIcon sx={{color: "#717171"}}/>
                                ) : (
                                    <CheckCircleIcon sx={{color: "secondary.main"}}/>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='h6'>{t("rooms")}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item container>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {matchChance?.alert_living_space === "" ? (
                                    <CheckCircleIcon sx={{color: "#717171"}}/>
                                ) : (
                                    <CheckCircleIcon sx={{color: "secondary.main"}}/>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='h6'>
                                    {t("living_space")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item container>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {matchChance?.location === "" ? (
                                    <CheckCircleIcon sx={{color: "#717171"}}/>
                                ) : (
                                    <CheckCircleIcon sx={{color: "secondary.main"}}/>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='h6'>{t("location")}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item container>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {matchChance?.alert_availability === "null" ? (
                                    <CheckCircleIcon sx={{color: "#717171"}}/>
                                ) : (
                                    <CheckCircleIcon sx={{color: "secondary.main"}}/>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='h6'>
                                    {t("availability")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    onClick={handleClose}>
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default GoodMatchChanceModal;
