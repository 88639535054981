import { useQuery } from "react-query";
import { Axios } from "../../axios/axios";
import { actionTypes, useStateValue } from "../../store";
import useAuth from "../Auth/useAuth";

//* get
function getSocialLoginStatus() {
    return Axios.get("/login/success", {
        withCredentials: true,
    });
}

export function useSocialLoginStatus(user_id) {
    const dispatch = useStateValue()[1];
    const { auth, setAuth } = useAuth();

    return useQuery(
        ["get-social-login-status", user_id],
        getSocialLoginStatus,
        {
            onSuccess: (data) => {
                if (data.status === 200) {
                    //   console.log("response-after status", response);
                    if (data.data.success) {
                        const user_id = data?.data?.user[0]?.id;
                        const userName = data?.data?.user[0]?.firstName;
                        const lastName = data?.data?.user[0]?.lastName;
                        const accessToken = data?.data?.cookies?.session;
                        setAuth({
                            user_id,
                            userName,
                            lastName,
                            accessToken,
                        });
                        localStorage.setItem(
                            "nch_id",
                            data?.data?.cookies?.session
                        );
                        localStorage.setItem(
                            "nch_user_data",
                            JSON.stringify({
                                id: data?.data?.user[0]?.id,
                                user_email: data?.data?.user[0]?.email,
                            })
                        );
                        dispatch({
                            type: actionTypes.SET_USER,
                            token: data?.data?.cookies?.session,
                            user_id: data?.data?.user[0]?.id,
                            user_email: data?.data?.user[0]?.email,
                        });
                    }
                }
            },

            // select: (data) => {
            //     if (data.status === 200) {
            //         return data.data.success;
            //     }
            // },

            enabled: false,
        }
    );
}
