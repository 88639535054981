import React, { useEffect, useState } from "react";

//* cookie
import cookies from "js-cookie";

//*  using toast for success and error
import { toast, Zoom } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* libraries
import { useNavigate } from "react-router-dom";

//* hook form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//* store imports
import { useStateValue } from "../../../store/StateProvider";

//* api calls

import { useCategories } from "../../../hooks/search/useSearchOptions";
import useCities from "../../../hooks/search/useCities";

//* Schema

// //* custom components
// import { SelectInput } from "../../../mui_components/MuiPropertyFormComponents";

//* custom components
import { advertise, countryOption } from "../../../options/propertyOptions";

//* mui components imports
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ControllerSelectField } from "../../mui-components/MuiDossierFormComponents";
import { useCreateProperty } from "../../../hooks/properties/useCreateProperty";
import Alert from "@mui/material/Alert";
import { useAuthModal } from "../../../context/ModalContext";
import createObjectFormSchema from "../../../forms-schemas/properties-schemas/createObjectSchema";
import { useTheme } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import PuzzleIcon from "@mui/icons-material/Extension";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import { CreateBenefitsStepsHeading } from "../../mui-components/CustomBenefitsComponent";
import { BENEFITS } from "../../../static_options/newTenantOptions";

function CreateObjectForm() {
    const currentLanguageCode = cookies.get("i18next") || "en";
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();
    const { openAuthModal, handleUserRole } = useAuthModal();
    const [{ user_id, token, user_role }] = useStateValue();
    const [advertiserType, setAdvertiserType] = useState("");
    const [advertiserTypeError, setAdvertiserTypeError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { isLoading: categoryLoading, data: categoryData } =
        useCategories(currentLanguageCode);
    const [searchTerm, setSearchTerm] = useState("");
    const { isLoading, data } = useCities(searchTerm, "zip");
    const allCitiesData = data?.data?.data;
    const { isLoading: createPropertyLoading, mutate } = useCreateProperty();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(createObjectFormSchema),
        defaultValues: {
            advertiser: "",
            type: 2,
            category: null,
            country: "3",
            city: null,
            name: "",
        },
    });

    const handleAdvertiserTypeChange = (event) => {
        setAdvertiserType(event.target.value);
        setAdvertiserTypeError("");
        //console.log(userRole);
    };

    useEffect(() => {
        setValue("country", "3", { shouldValidate: false });
    }, [setValue]);

    const handleAuth = () => {
        if (advertiserType === "")
            return setAdvertiserTypeError(t("please_select_customer_segment"));
        if (advertiserType === "tenant") {
            handleUserRole("TN");
        } else {
            handleUserRole("PM");
        }
        openAuthModal();
    };

    const submitForm = (data) => {
        // if (user_id === null && token === null) {
        //     openAuthModal();
        //     return;
        // }

        const getAdvertiserOnRoleType = () => {
            if (user_role === "TN") return "tenant";
            if (user_role === "PM") return "owner";
            if (user_role === null && advertiserType === "") return "tenant";
            if (user_role === null || advertiserType !== "")
                return advertiserType;
            return "tenant";
        };

        let propertyDetail = {
            user_id,
            name: `${data.category.name} in ${data.city.zip} ${data.city.name}`,
            category_id: data.category.id,
            category_name: data.category.name,
            //tenant: data.advertiser,
            tenant: getAdvertiserOnRoleType(),
            property_type: data.type,
            country_id: data.country,
            city_id: data.city.id,
            zipcode: data.city.zip,
            latitude: data.city.latitude,
            longitude: data.city.longitude,
            language: currentLanguageCode,
            is_published: 0,
        };

        mutate(propertyDetail, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    let errorMessage = data?.data?.message;
                    setSuccessMessage("");
                    setErrorMessage(errorMessage);
                    toast.error(errorMessage, {
                        transition: Zoom,
                    });
                    return;
                }
                setErrorMessage("");
                setSuccessMessage("You successfully added your property");
                setTimeout(() => {
                    navigate(
                        `/add-property-details-fast-lane/${data?.data?.Property_id}`
                    );
                }, 1000);
            },
            onError: (err) => {
                console.error(err);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    const AuthSection = () => {
        const theme = useTheme();
        return (
            <Box
                sx={{
                    border: `2px solid ${theme.palette.secondary.main}`,
                    borderRadius: "12px",
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                {/*
                <Typography variant="h6" sx={{mb: 2}}>
                    {t("list_your_property_on_nextflat_register_now")}
                </Typography>
                */}
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={8}>
                        <TextField
                            // labelId="role-select-label"
                            // id="role-select"
                            select
                            fullWidth
                            value={advertiserType}
                            label={t("property_add_new_role_new_tenant_title")}
                            onChange={handleAdvertiserTypeChange}
                            error={advertiserTypeError !== ""}
                            helperText={advertiserTypeError}
                            // sx={{ width: "100%" }}
                        >
                            {/* Replace these options with your actual roles */}
                            <MenuItem value="tenant">
                                {t("tenant") +
                                    " (" +
                                    t(
                                        "property_new_tenant_list_of_flats_short"
                                    ) +
                                    ")"}
                            </MenuItem>
                            <MenuItem value="owner" disabled>
                                {t("owner")}
                            </MenuItem>
                            <MenuItem value="administration" disabled>
                                {t("business")}
                            </MenuItem>
                            {/*
                             */}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            onClick={handleAuth}
                            sx={{ mt: 2, mb: 2 }}
                        >
                            {t("log_in")} / {t("register")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    //console.log(userRole);
    const getFullCityName = (name, zip) => {
        if (zip) {
            return `${zip} ${name} `;
        }
        return name;
    };
    return (
        <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom={2}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            color: theme.palette.secondary.main,
                            mr: theme.spacing(1),
                        }}
                    >
                        {t("list_your_property_on_nextflat_property_part")}
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            color: theme.palette.secondary.main,
                            mr: theme.spacing(1),
                        }}
                    >
                        -
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            color: theme.palette.secondary.main,
                            mr: theme.spacing(1),
                        }}
                    >
                        {t("list_your_property_on_nextflat_tenant_part")}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom={2}
                >
                    <HomeIcon
                        sx={{
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                            backgroundColor: "#fff",
                            border: `1px solid ${theme.palette.primary.main}`,
                            marginRight: theme.spacing(1),
                            color: theme.palette.primary.main,
                        }}
                    />

                    <KeyIcon
                        sx={{
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                            backgroundColor: "#fff",
                            border: `1px solid ${theme.palette.primary.main}`,
                            marginRight: theme.spacing(1),
                            color: theme.palette.primary.main,
                        }}
                    />

                    <ArrowForwardIosIcon
                        sx={{
                            color: theme.palette.primary.main,
                            mx: 1,
                        }}
                    />
                    <Box
                        sx={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <PuzzleIcon
                            sx={{
                                position: "absolute",
                                top: -20,
                                color: theme.palette.secondary.main,
                                fontSize: 28,
                            }}
                        />
                        <PuzzleIcon
                            sx={{
                                color: theme.palette.secondary.main,
                                fontSize: 40,
                                mx: 1,
                            }}
                        />
                    </Box>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: theme.palette.primary.main,
                            mx: 1, // margin links und rechts
                        }}
                    />
                    <PersonIcon
                        sx={{
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                            backgroundColor: "#fff",
                            border: `1px solid ${theme.palette.primary.main}`,
                            marginRight: theme.spacing(1),
                            color: theme.palette.primary.main,
                        }}
                    />
                    <PeopleIcon
                        sx={{
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                            backgroundColor: "#fff",
                            border: `1px solid ${theme.palette.primary.main}`,
                            color: theme.palette.primary.main,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={8}>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12} md={12}>
                        <CreateBenefitsStepsHeading
                            heading={`${t("your_benefits")}`}
                            benefits={BENEFITS}
                            t={t}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} mt={6}>
                    <Grid item xs={12} md={12}>
                        {!token && <AuthSection />}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper
                            elevation={4}
                            sx={{
                                border: `2px solid ${theme.palette.secondary.main}`,
                                borderRadius: "12px",
                                filter: token ? "none" : "blur(4px)",
                                padding: "16px",
                                minHeight: "300px",
                                pointerEvents: token ? "auto" : "none",
                            }}
                        >
                            <Stack rowGap={1}>
                                <Typography variant="h5">
                                    {t(
                                        "list_your_property_on_nextflat_subtext"
                                    )}
                                </Typography>
                                {successMessage !== "" && (
                                    <Alert severity="success">
                                        {successMessage}
                                    </Alert>
                                )}
                                {errorMessage !== "" && (
                                    <Alert severity="error">
                                        {errorMessage}
                                    </Alert>
                                )}
                            </Stack>

                            <form onSubmit={handleSubmit(submitForm)}>
                                <Grid
                                    container
                                    // spacing={4}
                                    columnSpacing={4}
                                    rowGap={2}
                                    sx={{
                                        //   p: 2,
                                        mt: 5,
                                    }}
                                >
                                    {/* (
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Stack>
                                                <Typography
                                                    component={"label"}
                                                    sx={{
                                                        fontWeight: "500",
                                                        mb: 1,
                                                        pl: 0.5,
                                                    }}
                                                >
                                                    {t("i_am_advertising_as")}
                                                </Typography>
                                                <ControllerSelectField
                                                    name={"advertiser"}
                                                    control={control}
                                                    textFieldProps={{
                                                        hiddenLabel: true,
                                                        SelectProps: {
                                                            displayEmpty: true,
                                                            renderValue: (value) => {
                                                                if (value === "") {
                                                                    return (
                                                                        <span
                                                                            style={{
                                                                                color: "#757575",
                                                                            }}
                                                                        >
                                                                    {t(
                                                                        "choose"
                                                                    )}
                                                                </span>
                                                                    );
                                                                }
                                                                return t(
                                                                    advertise.find(
                                                                        (option) =>
                                                                            option.value ===
                                                                            value
                                                                    )?.label
                                                                );
                                                            },
                                                        },
                                                    }}
                                                    errorFlag={!!errors.advertiser}
                                                    errorText={t(
                                                        errors.advertiser?.message
                                                    )}
                                                    placeholder={"hey"}
                                                >
                                                    {advertise?.map((option) => (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {t(option.label)}
                                                        </MenuItem>
                                                    ))}
                                                </ControllerSelectField>
                                            </Stack>
                                        </Grid>
                                    ) */}

                                    <Grid item xs={12} md={12} lg={12}>
                                        <Stack>
                                            <Typography
                                                component={"label"}
                                                sx={{
                                                    fontWeight: "500",
                                                    mb: 1,
                                                    pl: 0.5,
                                                }}
                                            >
                                                {t("category")}
                                            </Typography>
                                            {
                                                //console.log(categoryData)
                                            }
                                            <Controller
                                                name="category"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <Autocomplete
                                                        onChange={(
                                                            event,
                                                            item
                                                        ) => {
                                                            onChange(item);
                                                        }}
                                                        getOptionLabel={(
                                                            option
                                                        ) => option?.name}
                                                        loading={
                                                            categoryLoading
                                                        }
                                                        options={
                                                            categoryData ?? []
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label={t(
                                                                    "select"
                                                                )}
                                                                variant="filled"
                                                                placeholder={t(
                                                                    "choose"
                                                                )}
                                                                error={
                                                                    !!errors.category
                                                                }
                                                                helperText={t(
                                                                    errors
                                                                        ?.category
                                                                        ?.message
                                                                )}
                                                                fullWidth
                                                            />
                                                        )}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => {
                                                            if (
                                                                option?.id === 2
                                                            ) {
                                                                return null;
                                                            }
                                                            return (
                                                                <li
                                                                    {...props}
                                                                    key={
                                                                        option?.id
                                                                    }
                                                                >
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Stack>
                                            <Typography
                                                component={"label"}
                                                sx={{
                                                    fontWeight: "500",
                                                    mb: 1,
                                                    pl: 0.5,
                                                }}
                                            >
                                                {t("postcode_&_city_long")}
                                            </Typography>

                                            <Controller
                                                name="city"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <Autocomplete
                                                        freeSolo
                                                        fullWidth
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            getFullCityName(
                                                                option.name,
                                                                option.zip
                                                            )
                                                        }
                                                        filterOptions={(x) => x}
                                                        options={
                                                            allCitiesData ?? []
                                                        }
                                                        clearOnBlur
                                                        autoComplete
                                                        includeInputInList
                                                        filterSelectedOptions
                                                        noOptionsText="No locations"
                                                        onChange={(e, item) => {
                                                            onChange(item);
                                                        }}
                                                        loading={isLoading}
                                                        inputValue={searchTerm}
                                                        onInputChange={(
                                                            event,
                                                            newInputValue
                                                        ) => {
                                                            setSearchTerm(
                                                                newInputValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="filled"
                                                                label={t(
                                                                    "select"
                                                                )}
                                                                placeholder={t(
                                                                    "postcode_&_city_long"
                                                                )}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    // style: {
                                                                    //     color: "#4D4D4D",
                                                                    //     padding:
                                                                    //         "10px 5px",
                                                                    // },
                                                                }}
                                                                fullWidth
                                                                error={
                                                                    !!errors.city
                                                                }
                                                                helperText={t(
                                                                    errors?.city
                                                                        ?.message
                                                                )}
                                                            />
                                                        )}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => {
                                                            return (
                                                                <li
                                                                    {...props}
                                                                    key={
                                                                        option.id
                                                                    }
                                                                >
                                                                    {getFullCityName(
                                                                        option.name,
                                                                        option.zip
                                                                    )}
                                                                </li>
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    {false && (
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Stack>
                                                <Typography
                                                    component={"label"}
                                                    sx={{
                                                        fontWeight: "500",
                                                        mb: 1,
                                                        pl: 0.5,
                                                    }}
                                                >
                                                    {t("country")}
                                                </Typography>
                                                <ControllerSelectField
                                                    name={"country"}
                                                    control={control}
                                                    errorFlag={!!errors.country}
                                                    errorText={t(
                                                        errors.country?.message
                                                    )}
                                                    textFieldProps={{
                                                        hiddenLabel: true,
                                                    }}
                                                >
                                                    {countryOption?.map(
                                                        (option) => (
                                                            <MenuItem
                                                                key={
                                                                    option.value
                                                                }
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {t(
                                                                    option.label
                                                                )}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </ControllerSelectField>
                                            </Stack>
                                        </Grid>
                                    )}
                                    {/*
                                     */}
                                </Grid>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    mt={5}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="medium"
                                        disabled={createPropertyLoading}
                                    >
                                        {createPropertyLoading
                                            ? t("creating")
                                            : t("next")}
                                    </Button>
                                </Stack>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreateObjectForm;
