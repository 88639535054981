import { Challenge, DossierLottie, Process } from "../utils/imgImports";

const HOW_DOSSIER_WORKS = [
    {
        id: "01",
        title: "how_it_dossier_title_one",
        subtitle: "how_it_dossier_subtitle_one",
        lottieIcon: Challenge,
    },
    {
        id: "02",
        title: "how_it_dossier_title_two",
        subtitle: "how_it_dossier_subtitle_two",
        lottieIcon: Process,
    },
    {
        id: "03",
        title: "how_it_dossier_title_three",
        subtitle: "how_it_dossier_subtitle_three",
        lottieIcon: DossierLottie,
    },
];

export default HOW_DOSSIER_WORKS;
