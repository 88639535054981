import React, { useRef, useState } from "react";
import ModalLayout from "../layouts/ModalLayout";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import {
    Grid,
    Button,
    Stack,
    IconButton,
    Slider,
    DialogTitle,
    DialogActions,
    Container,
    styled,
    Box,
    Typography,
} from "@mui/material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../store";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "../elements/button/LoadingButton";

const EditToolButton = styled(IconButton)(({ theme }) => ({
    width: 40,
    height: 40,
}));

const ZoomSlider = styled(Slider)(({ theme }) => ({
    height: 15,
    "& .MuiSlider-track": {
        border: "none",
    },
    "& .MuiSlider-rail": {
        backgroundColor: theme.palette.grey[300],
    },
    "& .MuiSlider-thumb": {
        height: 20,
        width: 20,
        backgroundColor: "#fff",
        filter: "drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.10))",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
    },
}));

function EditImage({ open, onClose, image, onSubmit, saving }) {
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const [scale, setScale] = useState(1);
    const cropperRef = useRef(null);

    const onRotate = (direction) => () => {
        let angle = 0;
        let angleConfig = {
            left: -30,
            right: 30,
        };
        angle = angleConfig[direction] ?? 0;
        cropperRef.current.cropper.rotate(angle);
    };

    const onScale = (e) => {
        const scaleValue = parseFloat(e.target.value);
        setScale(scaleValue);
        cropperRef.current.cropper.scale(scaleValue);
    };

    const onReset = () => {
        const cropper = cropperRef.current.cropper;
        cropper.reset();
        setScale(1);
    };

    const dataUrlToFileUsingFetch = async (url, fileName, mimeType) => {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();

        return new File([buffer], fileName, { type: mimeType });
    };

    const handleSave = async () => {
        let croppedImg = cropperRef.current.cropper
            .getCroppedCanvas()
            .toDataURL();
        const file = await dataUrlToFileUsingFetch(
            croppedImg,
            `nf-smart-dossier-profile-${user_id}`,
            "image/png"
        );

        onSubmit(file);
        setTimeout(() => {
            onClose();
        }, 800);
    };

    return (
        <ModalLayout maxWidth={"md"} open={open}>
            <Stack flexDirection={"row"} justifyContent={"space-between"} p={2}>
                <Typography variant="h5">{t("edit")}</Typography>
                <Box onClick={onClose} sx={{ cursor: "pointer" }}>
                    <CloseOutlined />
                </Box>
            </Stack>

            <Container maxWidth="md">
                <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Box maxHeight={400} width={"100%"}>
                            {image && (
                                <Cropper
                                    src={image}
                                    style={{ height: 400, width: "100%" }}
                                    zoom={1}
                                    background={false}
                                    initialAspectRatio={1}
                                    aspectRatio={1}
                                    cropBoxResizable={false}
                                    guides={false}
                                    dragMode="move"
                                    ref={cropperRef}
                                    viewMode={1}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item container justifyContent={"center"}>
                            <Grid item>
                                <EditToolButton
                                    aria-label="Rotate Right"
                                    onClick={onRotate("left")}
                                >
                                    <RotateLeftIcon
                                        fontSize="large"
                                        color="primary"
                                    />
                                </EditToolButton>
                            </Grid>
                            <Grid item>
                                <EditToolButton
                                    aria-label="Rotate Right"
                                    onClick={onRotate("right")}
                                >
                                    <RotateRightIcon
                                        fontSize="large"
                                        color="primary"
                                    />
                                </EditToolButton>
                            </Grid>
                            <Grid item>
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    alignItems="center"
                                    width={300}
                                >
                                    <ZoomOutIcon
                                        fontSize="large"
                                        color="primary"
                                    />
                                    <ZoomSlider
                                        aria-label="Zoom"
                                        min={0.2}
                                        max={2}
                                        step={0.2}
                                        defaultValue={1}
                                        color="secondary"
                                        value={scale}
                                        onChange={onScale}
                                    />
                                    <ZoomInIcon
                                        fontSize="large"
                                        color="primary"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <DialogActions
                sx={{
                    rowGap: 2,
                }}
            >
                <Button onClick={onReset}>{t("reset")}</Button>

                <LoadingButton
                    variant="contained"
                    onClick={handleSave}
                    loading={saving}
                    disabled={saving}
                >
                    {t("save")}
                </LoadingButton>
            </DialogActions>
        </ModalLayout>
    );
}

export default EditImage;
