import * as React from "react";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";

function TikTokIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <>
        <g clipPath="url(#clip0_5366_2215)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100 0H0V100H100V0ZM61.5191 24.4862C62.2144 28.2189 64.4426 31.423 67.5309 33.4248C69.6824 34.8194 72.2466 35.6323 75 35.6323V45.4324C69.8882 45.4324 65.1513 43.8066 61.2855 41.0483V60.9637C61.2855 70.9086 53.1477 79 43.1433 79C39.2775 79 35.692 77.7889 32.7461 75.7307C28.066 72.4659 25 67.0654 25 60.9637C25 51.0165 33.1388 42.9263 43.1422 42.9263C43.9721 42.9263 44.7853 42.9926 45.5863 43.1021V53.1057C44.8131 52.8657 43.9954 52.7252 43.1422 52.7252C38.5744 52.7252 34.8565 56.4214 34.8565 60.9637C34.8565 64.1257 36.662 66.874 39.2997 68.2532C40.45 68.8559 41.756 69.1999 43.1433 69.1999C47.6065 69.1999 51.2476 65.6707 51.4123 61.2722L51.429 22H61.2855C61.2855 22.8494 61.3689 23.68 61.5191 24.4862Z"
            // fill="#68437E"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_5366_2215">
            <rect width={100} height={100} rx={50}></rect>
          </clipPath>
        </defs>
      </>
    </SvgIcon>
  );
}

export default function SvgIconsSize() {
  return (
    <Stack alignItems="center" sx={{ display: "inline-block" }}>
      <TikTokIcon fontSize="large" />
    </Stack>
  );
}
