//* custom svg icons
import ArrangePartnership from "../component/custom-icons/ArrangePartnership";
import { MoveInIcon } from "../component/custom-icons/MoveInIcon";
import SendYourRequestIcon from "../component/custom-icons/SendYourRequestIcon";
import WeContactYouIcon from "../component/custom-icons/WeContactYouIcon";
import WeWillCheckIcon from "../component/custom-icons/WeWillCheckIcon";


export const PARTNERSHIPHEADING = [
   {
      id: 1,
      smallScreen: "h4",
      variant: "h1",
      headingOne: "are_you_an_administration",
      que: true,
   },
   {
      id: 2,
      smallScreen: "h5",
      variant: "h3",
      headingOne: "partnership_heading_one",
      headingTwo : "partnership_heading_two"
   },
];

export const BENEFITS = [
   {
      id: 1,
      title: "partnership_title_one",
   },
    {
       id: 2,
       title: "partnership_title_two",
    },
    {
       id: 3,
       title: "partnership_title_three",
    },
   {
      id: 4,
      title: "partnership_title_four",
   },
   {
      id: 5,
      title: "partnership_title_five",
   },
   {
      id: 6,
      title: "partnership_title_six",
   },
];


export const ICONDATA = [
   {
      id: 1,
      icon: <SendYourRequestIcon />,
      title: "send_your_request",
   },
   {
      id: 2,
      icon: <WeWillCheckIcon />,
      title: "we_will_check_it",
   },
   {
      id: 3,
      icon: <WeContactYouIcon />,
      title: "we_contact_you",
   },
   {
      id: 4,
      icon: <ArrangePartnership />,
      title: "arrange_partnership",
   },
   {
      id: 5,
      isLast: true,
      icon: <MoveInIcon />,
      title: "match_&_connect",
   },
];