import { useQuery } from "react-query";
import { Axios } from "../../axios/axios";

function getProperties({ queryKey }) {
    const [page, limit, lang] = queryKey;
    return Axios.get(
        `/property/properties?page=${page}&limit=${limit}&lang=${lang}`
    );
}

export function useProperties(page = 0, limit, lang = "en") {
    return useQuery(["all_properties", page, limit, lang], getProperties);
}

function getAllProperties({ queryKey }) {
    const params = new URLSearchParams(queryKey[1]);
    // return Axios.get(
    //     `/property/search_properties?page=${pageParam}&limit=${2}&lang=${"en"}&city_id=${}&type_id=${}&category_id=${}&floor=${}&period=${}&space_from=${}&space_to=${}&number_bedroom=${}&price=${}&aminity=${}&availability=${}&filter_attribute=${}&sort_by_filter=${}&user_id=${}`
    // );
    return Axios.get(`/property/search_properties?${params.toString()}`);
}

export default function useGetAllProperties(searchedData) {
    return useQuery(["get-all-properties", searchedData], getAllProperties, {
        keepPreviousData: true,
    });
}

// function getAllProperties({ queryKey }) {
//   const params = new URLSearchParams(queryKey[1]);

//   return Axios.get(`/property/search_properties?${params.toString()}`);
// }

// export default function useGetAllProperties(searchedData) {
//   return useInfiniteQuery(
//       ["get-all-properties", searchedData],
//       getAllProperties,
//       {
//           getNextPageParam: (_lastPage, pages) => {
//               if (pages.length < pages[0].data.totalPages) {
//                   return (pages.length - 1) * 1 + 1;
//               } else {
//                   return undefined;
//               }
//           },
//       }
//   );
// }
