import React from 'react';
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    ControllerPhoneTextField, TitleSpacer, FieldHintSpacer
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import { useContext } from 'react';
import FormSectionContextSegmentN, {
    useCommonEditFormContext
} from '../../tenant_common_context/EditFormSectionContextSegment';
import FormControl from "@mui/material/FormControl";

const TenantOneSegmentThree = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const {
        t
    } = commonProps;

    const {
        heading,
        Icon,
        index,
        control,
        errors,
        sectionVariants,
        direction
    } = additionalProps;

    return (
        <DossierFormSection
            {...commonProps}
            {...additionalProps}
        >
            <Grid item container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerPhoneTextField
                        control={control}
                        name="phoneNo"
                        label={t("phone_number")}
                        errorFlag={!!errors?.phoneNo}
                        errorText={t(errors?.phoneNo?.message)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerPhoneTextField
                        control={control}
                        name="privatePhoneNo"
                        label={t("phone_no_private")}
                        errorFlag={!!errors?.privatePhoneNo}
                        errorText={t(
                            errors?.privatePhoneNo?.message
                        )}
                    />
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'none' }}>
                    <ControllerTextField
                        control={control}
                        name="email"
                        label={t("email")}
                        errorFlag={!!errors?.email}
                        errorText={t(errors?.email?.message)}
                        disabled
                    />
                </Grid>

                {/* email hint splitter */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ display: 'none' }}
                >

                    <FieldHintSpacer
                        label={t("email_changeable_in_profile")}
                        style={{ display: 'none' }}
                    />
                </Grid>
            </Grid>
        </DossierFormSection>
    );
}

export default TenantOneSegmentThree