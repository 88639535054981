import React, { useState } from "react";

//* cookie
import cookies from "js-cookie";

//*  using toast for success and error
import { toast } from "react-toastify";

//* Translation
import { useTranslation } from "react-i18next";

//* hook form
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

//* api calls
import useRegistration from "../../../hooks/Auth/useRegistration";

//* Schemas
import { RegisterSchema } from "../../../forms-schemas/auth-schemas/RegisterSchema";

//* mui component import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

//* mui icons imports
import CheckIcon from "@mui/icons-material/Check";

//* router
import { Link } from "react-router-dom";

//* context
import { useAuthModal } from "../../../context/ModalContext";
import CustomGoogleIcon from "../../svg/CustomGoogleIcon";
import { useSocialLoginStatus } from "../../../hooks/social-login/useSocialLoginStatus";
import EmailIcon from "@mui/icons-material/Email";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

function RegisterForm() {
    const { t } = useTranslation();
    const theme = useTheme();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const { closeAuthModal, handleAuthForms, handleAuthData, userRole } =
        useAuthModal();
    const { isLoading, mutate } = useRegistration();
    const [success, setSuccess] = useState(false);

    //console.log(role.role);

    const {
        //   isLoading: socialLoginLoading,
        refetch: socialLoginRefetch,
    } = useSocialLoginStatus();

    const {
        control,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(RegisterSchema),
        defaultValues: {
            email: "",
        },
    });

    // const handleIsSubmitted = (bool) => {
    //     setIsSubmitted(bool);
    // };

    const submitForm = (data) => {
        const { email } = data;
        let userData = {
            email: email,
            first_name: null,
            last_name: null,
            username: null,
            role_type: userRole,
            device_type: "website",
            language: currentLanguageCode,
        };

        mutate(userData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    setSuccess(false);
                    let message = data?.data?.message;
                    let errorCheck = message?.toLowerCase();
                    if (errorCheck.includes("email")) {
                        setError("email", {
                            type: "server",
                            message: `${t("this_email_is_already_taken")}`,
                        });
                    }
                    if (errorCheck.includes("username")) {
                        setError("username", {
                            type: "server",
                            message: `${t(
                                "this_username_is_already_taken_try_a_different_one"
                            )}`,
                        });
                    }

                    return;
                }

                setSuccess(true);
                handleAuthData({ email });
                setTimeout(() => {
                    handleAuthForms("verify");
                    reset({
                        email: "",
                    });
                }, 800);
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    const google = () => {
        let timer = null;

        let googleUrl = `${process.env.REACT_APP_BASE_URL}/google`;

        let socialLoginWindow = window.open(
            googleUrl,
            "_blank",
            "width=500, height=600"
        );

        if (socialLoginWindow) {
            timer = setInterval(() => {
                if (socialLoginWindow.closed) {
                    socialLoginRefetch();
                    closeAuthModal();
                    if (timer) clearInterval(timer);
                }
            }, 500);
        }
    };

    return (
        <Box>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Stack alignItems={"center"}>
                        {/*
                        <Typography
                            textAlign={"center"}
                            variant="h4"
                            textTransform={"capitalize"}
                        >
                            {t("register")}
                        </Typography>
                        <Typography variant="body2">
                            {`${t("please_enter_your_details_to_sign_up")}.`}
                        </Typography>
                        */}
                        <Box display="flex" alignItems="center" gap={1} my={2}>
                            <EmailIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    ml: 0.5,
                                    marginTop: "-3px",
                                }}
                            />

                            <Typography variant="body2">E-Mail</Typography>
                            <ArrowForwardIosIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    mx: 0.5,
                                    marginTop: "-4px",
                                }}
                            />
                            <VpnKeyIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    ml: 0.5,
                                    marginTop: "-4px",
                                }}
                            />
                            <Typography variant="body2">Code</Typography>
                            <ArrowForwardIosIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    mx: 1,
                                    marginTop: "-3px",
                                }}
                            />
                            <PlayArrowIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    ml: 0.5,
                                    transform: "translateY(-3px)",
                                }}
                            />
                            <Typography variant="body2">Los!</Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Grid item container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => {
                                        return (
                                            <TextField
                                                type="email"
                                                label={t("email")}
                                                fullWidth
                                                variant="filled"
                                                {...field}
                                                error={!!errors?.email}
                                                helperText={t(
                                                    errors?.email?.message
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {/* <Stack direction={"row"} justifyContent={"center"}>  */}
                                <Box sx={{ position: "relative" }}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            ...(success && {
                                                bgcolor: green[500],
                                                "&:hover": {
                                                    bgcolor: green[700],
                                                },
                                            }),
                                        }}
                                        style={{
                                            textDecoration: "none",
                                            fontSize: "1.25rem",
                                        }}
                                        fullWidth
                                        disabled={isLoading}
                                        type="submit"
                                    >
                                        {success ? (
                                            <CheckIcon
                                                sx={{
                                                    color: theme.palette.common
                                                        .white,
                                                }}
                                            />
                                        ) : (
                                            t("register")
                                        )}
                                    </Button>
                                    {isLoading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                marginTop: "-12px",
                                                marginLeft: "-12px",
                                            }}
                                        />
                                    )}
                                </Box>

                                {/* </Stack>  */}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <div className="loginFormOrText" color={"secondary"}>
                        {t("or")}
                    </div>
                    {/* <div className="loginFormOrText">{t("or")}</div> */}
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"center"}
                        gap={2}
                    >
                        <Button
                            // variant="outlined"
                            variant="text"
                            color="secondary"
                            startIcon={
                                <CustomGoogleIcon viewBox={"0 0 32 32"} />
                            }
                            onClick={google}
                            style={{
                                color: theme.palette.secondary.main,
                                textDecoration: "none",
                                fontSize: "1.25rem",
                            }}
                        >
                            {t("sign_in")}
                        </Button>

                        {/* <Button
                        variant="text"
                        color="secondary"
                        startIcon={<CustomFacebookIcon viewBox={"0 0 16 16"} />}
                        // onClick={() => {}}
                    >
                        {t("sign_in")}
                    </Button> */}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"center"}
                        gap={1}
                    >
                        <Typography variant="subtitle">
                            {t("already_have_an_account")}
                        </Typography>
                        <Link
                            to="#"
                            style={{
                                // color: "secondary",
                                color: theme.palette.secondary.main,
                                textDecoration: "none",
                            }}
                            onClick={() => handleAuthForms("login")}
                        >
                            {t("sign_in")}
                        </Link>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default RegisterForm;
