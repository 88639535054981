import React from "react";

import { useEffect, useState } from "react";
//* translation
import { useTranslation } from "react-i18next";

//* react-hook-form
import { useFormContext } from "react-hook-form";

//* custom components
import {
    ControllerAutoCompleteField,
    ControllerTextField,
} from "../../../mui-components/MuiDossierFormComponents";

//* mui components import
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//* Import JSON data
import countryNames from "../../../../assets/JSON/countrybyname.json";
import cookies from "js-cookie";
import CountrySelector from "../../../mui-components/CountrySelector";

/*
let getAllCountry = countryNames.map((item) => ({
    label: item.country,
    code: item.code,
    value: item.country.toLowerCase(),
}));
*/

function BriefAddressDetail() {
    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation();

    const currentLanguageCode = cookies.get("i18next") || "en";
    const [getAllCountry, setAllCountry] = useState([]);

    useEffect(() => {
        async function loadCountries() {
            const countryData =
                currentLanguageCode === "de"
                    ? await import(
                          "../../../../assets/JSON/countrybyname_german.json"
                      )
                    : await import(
                          "../../../../assets/JSON/countrybyname.json"
                      );
            const formattedCountries = countryData.default.map((item) => ({
                label: item.country,
                code: item.code,
                value: item.country.toLowerCase(),
            }));
            setAllCountry(formattedCountries);
        }
        loadCountries();
    }, [currentLanguageCode]);

    const countryValue = watch("country");
    const cityValue = watch("city");
    const zipValue = watch("zip");

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h3" textAlign={"center"}>
                    {t("address")}
                </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
                <ControllerAutoCompleteField
                    label={t("country")}
                    name="country"
                    options={getAllCountry}
                    disableClearable
                    errorFlag={!!errors?.country}
                    errorText={t(errors?.country?.message)}
                />
                {/* todo: handle translation of country name */}
                {/* <CountrySelector
                    label={t("country")}
                    control={control}
                    name="country"
                    errorFlag={!!errors?.country}
                    errorText={t(errors?.country?.message)}
                /> */}
            </Grid>
            <Grid item xs={12} md={4}>
                <ControllerTextField
                    control={control}
                    name="zip"
                    label={t("postcode")}
                    disabled={countryValue === null || countryValue === ""}
                    errorFlag={!!errors?.zip}
                    errorText={t(errors?.zip?.message)}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <ControllerTextField
                    control={control}
                    name="city"
                    label={t("city")}
                    disabled={countryValue === null || countryValue === ""}
                    errorFlag={!!errors?.city}
                    errorText={t(errors?.city?.message)}
                />
            </Grid>
            {/* street */}

            <Grid item xs={12} md={8}>
                <ControllerTextField
                    control={control}
                    name="streetAddress"
                    label={t("street_address")}
                    disabled={
                        cityValue === "" ||
                        cityValue === null ||
                        zipValue === "" ||
                        zipValue === null
                    }
                    errorFlag={!!errors?.streetAddress}
                    errorText={t(errors?.streetAddress?.message)}
                />
            </Grid>

            {/* street no */}

            <Grid item xs={12} md={2}>
                <ControllerTextField
                    control={control}
                    name="streetNo"
                    label={t("house_no")}
                    disabled={
                        cityValue === "" ||
                        cityValue === null ||
                        zipValue === "" ||
                        zipValue === null
                    }
                    errorFlag={!!errors?.streetNo}
                    errorText={t(errors?.streetNo?.message)}
                />
            </Grid>

            {/* city */}
        </Grid>
    );
}

export default BriefAddressDetail;
