import { useMutation } from 'react-query'
import { Axios } from '../../axios/axios'

function onChoosePackage(packageDetails) {
  return Axios.post('/property/property_transaction', packageDetails)
}

export default function useChooseDossierPackage() {
  return useMutation(onChoosePackage)
}
