import React, { useState } from "react";

//* modal components imports
import { PropertyAmenitiesModal } from "../../../modal/properties/PropertyAmenitiesModal";

//* mui components imports
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

//* get all amenities
import { getAttributeIcons } from "../../../../utils/getAmenities";

const ShowMoreButton = styled(Button)(({ theme }) => ({
    "&.MuiButton-root": {
        border: "1px solid #717171",
    },
    "&.MuiButton-text": {
        color: " #717171",
    },
    "&.MuiButton-outlined": {
        color: "#717171",
    },
}));

export const PropertyMobileAmenities = ({ amenities, title }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let attributeArray = amenities?.split(",");

    const theme = useTheme();

    const iconColor = theme.palette.secondary.main;

    return (
        <>
            <Typography variant="h5" mb={2}>
                {title}
            </Typography>

            <Grid item container rowSpacing={2}>
                {attributeArray?.slice(0, 5)?.map((attribute, index) => (
                    <Grid item md={6} key={index} pr={3}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            {getAttributeIcons(
                                attribute?.replace(/\s/g, "")?.toLowerCase(),
                                iconColor
                            )}
                            <Typography
                                component={"p"}
                                sx={{ color: "#717171" }}
                            >
                                {attribute}
                            </Typography>
                        </Stack>
                    </Grid>
                ))}
            </Grid>
            {attributeArray?.length > 5 ? (
                <ShowMoreButton
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2, borderRadius: 2 }}
                    onClick={handleClickOpen}
                >
                    Show all {attributeArray?.length} amenities
                </ShowMoreButton>
            ) : null}
            <PropertyAmenitiesModal
                open={open}
                handleClose={handleClose}
                amenities={amenities}
            />
        </>
    );
};
