import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function handlePayrexxGateway(data) {
    return Axios.post("/payments/payrexx-gateway", data);
}

export default function usePayrexxGateway() {
    return useMutation(handlePayrexxGateway);
}
