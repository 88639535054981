import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerRadioField,
    ControllerTextField,
    ControllerDateField,
    ControllerSelectField,
    TitleSpacer,
    ControllerPhoneTextField,
    ControllerSliderTextField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import {
    employmentRelationshipArr,
    isEmployedOption,
    isYesNoOption,
    rentPaymentOption,
    SmokeOptionArr,
    tenantGender,
    unemploymentStatusOption,
    workload,
} from "../../../../../../options/dossierOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { isGreaterThanYear } from "../../../../../../utils/isGreaterThanYear";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import {
    EditFormProviderSegment,
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import AnnualSalaryRangeSelect from "../../../../../mui-components/AnnualSalaryRangeSelect";
import { DossierReferenceBox } from "../../../dossier_components/DossierReferenceBox";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";
import ControllerSliderComponent from "../../../dossier_components/ControllerSliderComponent";

const TenantOneSegmentFour = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        watchEmployed,
        watchUnemployedStatus,
        watchRentPaymentMethod,
        referenceStatus,
        handleReferenceStatus,
        setValue,
        control,
        errors,
        watch,
        sectionVariants,
        direction,
        isPayment,
    } = additionalProps;

    const watchEmploymentRelationship = watch("employmentRelationship");
    const watchWorkLoadPercentage = watch("workLoadPercentage");

    //console.log("isPayment", isPayment);
    
    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid item container spacing={4} sx={{ mt: 3 }}>
                <Grid container spacing={5} sx={{ px: 1, mx: -1 }}>
                    <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="employedOrNot"
                            label={t("are_you_employed") + "*"}
                            data={isEmployedOption}
                            errorFlag={!!errors.employedOrNot}
                            errorText={
                                errors.employedOrNot
                                    ? errors?.employedOrNot?.message
                                    : ""
                            }
                        />
                        {/*
                        <Controller
                            name="employedOrNot"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    variant="filled"
                                    select
                                    {...field}
                                    label={`${t("are_you_employed")}?*`}
                                    SelectProps={{
                                        IconComponent:
                                        ArrowDropDownCircleOutlinedIcon,
                                    }}
                                    fullWidth
                                    error={!!errors?.employedOrNot}
                                    helperText={t(
                                        errors?.employedOrNot?.message
                                    )}
                                >
                                    {isEmployedOption.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                        */}
                    </Grid>
                </Grid>

                {watchEmployed === "no" && (
                    <Fragment>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="unemploymentStatus"
                                label={t("what_is_your_status") + "*"}
                                data={unemploymentStatusOption}
                                errorFlag={!!errors?.unemploymentStatus}
                                errorText={t(
                                    errors?.unemploymentStatus?.message
                                )}
                            />

                            {/*


                            <ControllerSelectField
                                control={control}
                                name="unemploymentStatus"
                                label={`
                                                ${t("what_is_your_status")}?*
                                            `}
                                errorFlag={
                                    !!errors?.unemploymentStatus
                                }
                                errorText={t(
                                    errors?.unemploymentStatus
                                        ?.message
                                )}
                            >
                                {unemploymentStatusOption?.map(
                                    (option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {t(option.label)}
                                        </MenuItem>
                                    )
                                )}
                            </ControllerSelectField>
                                    */}
                        </Grid>
                        {watchUnemployedStatus === "other_unemployed" && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerTextField
                                    control={control}
                                    name="otherEmploymentStatus"
                                    label={t("status") + "*"}
                                    errorFlag={!!errors?.otherEmploymentStatus}
                                    errorText={t(
                                        errors?.otherEmploymentStatus?.message
                                    )}
                                />
                            </Grid>
                        )}
                        {watchUnemployedStatus === "self_employed" ? (
                            <Fragment>
                                {/* if self-employed */}

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="selfEmployedCompanyName"
                                        label={t("name_of_company") + "*"}
                                        errorFlag={
                                            !!errors?.selfEmployedCompanyName
                                        }
                                        errorText={t(
                                            errors?.selfEmployedCompanyName
                                                ?.message
                                        )}
                                    />
                                </Grid>
                                {/* address of company */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="selfEmployedCompanyAddress"
                                        label={t("address_of_company") + "*"}
                                        errorFlag={
                                            !!errors?.selfEmployedCompanyAddress
                                        }
                                        errorText={t(
                                            errors?.selfEmployedCompanyAddress
                                                ?.message
                                        )}
                                    />
                                </Grid>
                                {/* employed there since */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerDateField
                                        name={"selfEmployedThereSince"}
                                        control={control}
                                        label={t("employed_there_since")}
                                        lang={currentLanguageCode}
                                    />
                                </Grid>
                                {/* monthly income  */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ControllerTextField
                                        control={control}
                                        name="selfEmployedIncome"
                                        label={`${t("monthly_mid_income")}*`}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    CHF
                                                </InputAdornment>
                                            ),
                                        }}
                                        errorFlag={!!errors?.selfEmployedIncome}
                                        errorText={t(
                                            errors?.selfEmployedIncome?.message
                                        )}
                                    />
                                    <Typography variant="caption" ml={2}>
                                        {t(
                                            "please_add_tax_assessment_to_your_documents_later"
                                        )}
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <ControllerToggleButtonsComponent
                                            control={control}
                                            name="howYouPayRent"
                                            label={
                                                t("how_do_you_pay_your_rent") +
                                                "*"
                                            }
                                            data={rentPaymentOption}
                                            errorFlag={!!errors?.howYouPayRent}
                                            errorText={t(
                                                errors?.howYouPayRent?.message
                                            )}
                                        />

                                        {/*
                                        <ControllerSelectField
                                            control={control}
                                            name="howYouPayRent"
                                            label={`${t(
                                                "how_do_you_pay_your_rent"
                                            )}?*`}
                                            errorFlag={
                                                !!errors?.rentPaymentMethod
                                            }
                                            errorText={t(
                                                errors
                                                    ?.rentPaymentMethod
                                                    ?.message
                                            )}
                                        >
                                            {rentPaymentOption?.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={
                                                            option.value
                                                        }
                                                        value={
                                                            option.value
                                                        }
                                                    >
                                                        {t(
                                                            option.label
                                                        )}
                                                    </MenuItem>
                                                )
                                            )}
                                        </ControllerSelectField>
                                              */}
                                    </Grid>
                                    {watchRentPaymentMethod ===
                                        "additional_solidarity_liability" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            sx={{ mt: 4 }}
                                        >
                                            <ControllerTextField
                                                control={control}
                                                name="liablePerson"
                                                label={
                                                    t("who_is_this_person") +
                                                    "*"
                                                }
                                                errorFlag={
                                                    !!errors?.liablePerson
                                                }
                                                errorText={t(
                                                    errors?.liablePerson
                                                        ?.message
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Fragment>
                            </Fragment>
                        )}

                        {watchUnemployedStatus !== "self_employed" && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerSliderTextField
                                    control={control}
                                    name="coveredRentAmount"
                                    label={t("covered_rent_amount")}
                                    renderSlider
                                    SliderComponent={ControllerSliderComponent}
                                    sliderProps={{
                                        label: t("covered_rent_amount"),
                                        min: 400,
                                        max: 8000,
                                        step: 50,
                                    }}
                                />

                                {/*
                                    <ControllerTextField
                                        control={control}
                                        name="coveredRentAmount"
                                        label={`${t(
                                            "covered_rent_amount"
                                        )}`}
                                        errorFlag={
                                            !!errors?.coveredRentAmount
                                        }
                                        errorText={t(
                                            errors?.coveredRentAmount
                                                ?.message
                                        )}
                                    />
                                       */}
                            </Grid>
                        )}

                        {/* company section splitter */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TitleSpacer
                                label={t("request_reference_title_employer")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="referenceName"
                                label={t("reference_name")}
                                errorFlag={!!errors?.referenceName}
                                errorText={t(errors?.referenceName?.message)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="referenceEmail"
                                type="email"
                                label={t("reference_email")}
                                errorFlag={!!errors?.referenceEmail}
                                errorText={t(errors?.referenceEmail?.message)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerPhoneTextField
                                control={control}
                                name="referencePhone"
                                label={t("reference_phone")}
                                errorFlag={!!errors?.referencePhone}
                                errorText={t(errors?.referencePhone?.message)}
                            />
                        </Grid>
                    </Fragment>
                )}
                {/* if is employed */}
                {watchEmployed === "yes" && (
                    <Fragment>
                        <Grid container spacing={5} sx={{ px: 1, mx: -1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerToggleButtonsComponent
                                    control={control}
                                    name="employmentRelationship"
                                    label={t("employment_relationship") + "*"}
                                    data={employmentRelationshipArr}
                                    errorFlag={!!errors?.employmentRelationship}
                                    errorText={t(
                                        errors?.employmentRelationship?.message
                                    )}
                                />
                                {/*
                                <ControllerRadioField
                                    name={"employmentRelationship"}
                                    label={t("employment_relationship")}
                                    control={control}
                                >
                                    {employmentRelationshipArr.map(
                                        (employ_relationship) => (
                                            <FormControlLabel
                                                key={
                                                    employ_relationship.id
                                                }
                                                label={t(
                                                    employ_relationship.label
                                                )}
                                                value={
                                                    employ_relationship.value
                                                }
                                                control={<Radio />}
                                            />
                                        )
                                    )}
                                </ControllerRadioField>
                                */}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerToggleButtonsComponent
                                    control={control}
                                    name="workloadPercent"
                                    label={`${t("workload")} ${t("in")} % `}
                                    data={workload}
                                    minWidth="84px"
                                    errorFlag={!!errors.workloadPercent}
                                    errorText={t(
                                        errors?.workloadPercent?.message
                                    )}
                                />
                                {/*
                            <Controller
                                name="workloadPercent"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        variant="filled"
                                        select
                                        {...field}
                                        label={`${t(
                                            "workload"
                                        )} ${t("in")} % `}
                                        SelectProps={{
                                            IconComponent:
                                            ArrowDropDownCircleOutlinedIcon,
                                        }}
                                        fullWidth
                                        error={
                                            !!errors?.partTimePercent
                                        }
                                        helperText={t(
                                            errors?.partTimePercent
                                                ?.message
                                        )}
                                    >
                                        {workload.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label + "%"}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            */}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerDateField
                                name={"employedThereSince"}
                                control={control}
                                label={t("employed_there_since")}
                                lang={currentLanguageCode}
                                disableFuture
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="profession"
                                label={t("profession") + "*"}
                                errorFlag={!!errors?.profession}
                                errorText={t(errors?.profession?.message)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Controller
                                control={control}
                                // name="monthlyIncome"
                                name="annualSalaryRangeId"
                                render={({ field }) => {
                                    return (
                                        <AnnualSalaryRangeSelect
                                            error={
                                                !!errors?.annualSalaryRangeId
                                            }
                                            helperText={t(
                                                errors?.annualSalaryRangeId
                                                    ?.message
                                            )}
                                            textFieldProps={field}
                                            onSelect={(value) =>
                                                setValue(
                                                    "annualSalaryRange",
                                                    value
                                                )
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        {watchEmployed === "yes" && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerToggleButtonsComponent
                                    control={control}
                                    name="hasEmploymentTerminated"
                                    label={
                                        t(
                                            "employment_relationship_terminated"
                                        ) + "*"
                                    }
                                    data={isYesNoOption}
                                    errorFlag={
                                        !!errors?.hasEmploymentTerminated
                                    }
                                    errorText={t(
                                        errors?.hasEmploymentTerminated?.message
                                    )}
                                />
                                {/*
                                <ControllerRadioField
                                    control={control}
                                    name="hasEmploymentTerminated"
                                    label={`${t(
                                        "employment_relationship_terminated"
                                    )}`}
                                >
                                    <FormControlLabel
                                        label={t("no")}
                                        value="no"
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        label={t("yes")}
                                        value="yes"
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        label={t(
                                            "no_specification"
                                        )}
                                        value="no_specification"
                                        control={<Radio/>}
                                    />
                                </ControllerRadioField>
                                */}
                            </Grid>
                        )}

                        {/* company section splitter */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TitleSpacer
                                label={t(
                                    "current_address_for_employer_reference"
                                )}
                            />
                        </Grid>

                        {/* name of company */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="companyName"
                                label={t("name_of_company") + "*"}
                                errorFlag={!!errors?.companyName}
                                errorText={t(errors?.companyName?.message)}
                            />
                        </Grid>
                        {/* address of company */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="companyAddress"
                                label={t("address_of_company") + "*"}
                                errorFlag={!!errors?.companyAddress}
                                errorText={t(errors?.companyAddress?.message)}
                            />
                        </Grid>
                        {/* name of contact person */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="employeeName"
                                label={t("name_of_contact_person") + "*"}
                                errorFlag={!!errors?.employeeName}
                                errorText={t(errors?.employeeName?.message)}
                            />
                        </Grid>
                        {/* email of contact person */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerTextField
                                control={control}
                                name="employeeEmail"
                                label={t("email_of_contact_person") + "*"}
                                type="email"
                                errorFlag={!!errors?.employeeEmail}
                                errorText={t(errors?.employeeEmail?.message)}
                            />
                        </Grid>

                        {/* phone of contact person */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerPhoneTextField
                                control={control}
                                name="employeePhoneNo"
                                label={t("phone_of_contact_person") + "*"}
                                errorFlag={!!errors?.employeePhoneNo}
                                errorText={t(errors?.employeePhoneNo?.message)}
                            />
                        </Grid>
                        {/* Employment relationship terminated */}
                        {/*
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <ControllerRadioField
                                            control={control}
                                            name="hasApartmentCancelled"
                                            label={`${t(
                                                "current_apartment_is_already_cancelled"
                                            )}`}
                                        >
                                            <FormControlLabel
                                                label={t("no")}
                                                value="no"
                                                control={<Radio />}
                                            />
                                            <FormControlLabel
                                                label={t("yes")}
                                                value="yes"
                                                control={<Radio />}
                                            />
                                        </ControllerRadioField>
                                    </Grid>
                                    {hasApartmentCancelled === "yes" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <ControllerRadioField
                                                control={control}
                                                name="apartmentCancelledBy"
                                                label={`${t(
                                                    "who_cancelled_the_apartment"
                                                )}`}
                                            >
                                                <FormControlLabel
                                                    label={t("administration")}
                                                    value="administration"
                                                    control={<Radio />}
                                                />
                                                <FormControlLabel
                                                    label={t("tenant")}
                                                    value="tenant"
                                                    control={<Radio />}
                                                />
                                            </ControllerRadioField>
                                        </Grid>
                                    )}
                                    */}
                    </Fragment>
                )}

                {/* company reference section splitter */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TitleSpacer
                        label={t("request_reference_title_employer")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DossierReferenceBox
                        heading={t(
                            "you_have_chosen_the_verified_version_of_our_smart_dossier_employer_reference"
                        )}
                        bodyText={t(
                            "you_have_chosen_the_verified_version_of_our_smart_dossier_employer_reference_body_text"
                        )}
                        buttonTextOne={t("request")}
                        buttonTextTwo={t("employer_reference")}
                        buttonFor={"employer"}
                        status={referenceStatus?.employer}
                        tenantRole={"tenantOne"}
                        isDisabled={referenceStatus?.employer !== "" || isPayment === 0 || isPayment === undefined}
                        //isDisabled={referenceStatus?.employer !== ""}
                        handleReferenceStatus={handleReferenceStatus}
                    />
                </Grid>
            </Grid>
        </DossierFormSection>
    );
};

export default TenantOneSegmentFour;
