import React, { useState } from "react";

//* Translation
import { useTranslation } from "react-i18next";

//* libraries imports
import { Link } from "react-router-dom";

//* api calls
// import { useAddWishlist } from "../../hooks/Properties/useWishlist";

//* store imports
import { useStateValue } from "../../store/StateProvider";

//* mui components import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

//* mui icons imports
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PlaceIcon from "@mui/icons-material/Place";

//* custom reusable components
import {
    CarousalImages,
    PropertyCategoryAndPrice,
    PropertyNameTooltip,
} from "../mui-components/MuiPropertyCardComponents";

//* utility function
import {
    getPropertyCategory,
    getPropertyName,
    getPropertyPrice,
    getPropertyStreet,
} from "../../utils/getPropertyTypes";
import { useCategories } from "../../hooks/search/useSearchOptions";

//* imageUrl = Dynamic image path
const imageUrl = process.env.REACT_APP_PROPERTY_IMAGE_URL;

function PropertyCard(props) {
    const {
        id,
        name,
        category_name,
        category_id,
        price,
        street_no,
        images,
        is_fav,
        city_name,
    } = { ...props };

    const { t } = useTranslation();
    const [{ token, user_id }] = useStateValue();

    // const { isFavorite, handleWishlist } = useAddWishlist();

    const [isFavoriteProperty, setIsFavoriteProperty] = useState([]);

    // const { isLoading, data } = useCategories();
    // const getCategoryName = (categoryId) => {
    //     let categoryObj = data?.find((category) => category.id === categoryId);
    //     if (!categoryObj) return "Not Available";
    //     //console.log(categoryObj);
    //     return categoryObj?.name;
    // };

    const handleStaticFav = (id) => {
        if (isFavoriteProperty.includes(id)) {
            setIsFavoriteProperty(
                isFavoriteProperty.filter(
                    (selectedSponsors) => selectedSponsors !== id
                )
            );
        } else {
            setIsFavoriteProperty((prevState) => [...prevState, id]);
        }
    };

    let propertyNameTrimLength = 26;

    return (
        <Paper
            sx={{
                p: 1.25,
                borderRadius: 5,
                "&:hover": {
                    boxShadow: "6px 6px 20px 0px #b4b2b240;",
                    transition: "box-shadow 500ms ease-in-out",
                },
            }}
            className="property-card"
            elevation={0}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sx={{ position: "relative", height: 260, width: "100%" }}
                >
                    <CarousalImages
                        propertyImages={images}
                        altTags={street_no}
                        imageUrl={imageUrl + id + "/"}
                        height={"250px"}
                        width={"100%"}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: 20,
                            left: 10,
                            width: "95%",
                            zIndex: 1,
                            px: 1.875,
                        }}
                    >
                        <Link
                            to={`/property/${id}`}
                            style={{ textDecoration: "none" }}
                        >
                            <Stack
                                direction="row"
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <PropertyCategoryAndPrice
                                    color={"#68437E"}
                                    background={"rgba(238,248,254,0.7)"}
                                    styleProps={{
                                        backdropFilter: "blur(8px)",
                                        padding: "7px 7px",
                                        fontSize: "8px",
                                    }}
                                >
                                    {!category_name ||
                                    category_name === "undefined"
                                        ? t("apartment")
                                        : t(category_name.toLowerCase())}

                                    {
                                        //    t(getPropertyCategory(category_name))
                                    }
                                </PropertyCategoryAndPrice>
                                {/*
                                <Box
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        height: 30,
                                        width: 30,
                                        borderRadius: "50%",
                                        bgcolor: "rgba(238,248,254,0.7)",
                                        // bgcolor:"transparent",
                                        cursor: "pointer",
                                    }}
                                >
                                    {
                                        //* Static
                                        isFavoriteProperty.includes(id) ? (
                                            <FavoriteIcon
                                                onClick={() => {
                                                    // handleWishlist({ user_id, property_id: id, token });
                                                    handleStaticFav(id);
                                                }}
                                                sx={{
                                                    color: "#68437E",
                                                }}
                                            />
                                        ) : (
                                            <FavoriteBorderOutlinedIcon
                                                onClick={() => {
                                                    // handleWishlist({ user_id, property_id: id, token });
                                                    handleStaticFav(id);
                                                }}
                                                sx={{
                                                    color: "#68437E",
                                                }}
                                            />
                                        )
                                    }
                                </Box>
                                 */}
                            </Stack>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Link
                        to={`/property/${id}`}
                        style={{ textDecoration: "none" }}
                    >
                        <Grid item container spacing={1}>
                            <Grid item xs={12}>
                                {/*
                                <PropertyNameTooltip
                                    property={t(getPropertyName(name))}
                                >
                                </PropertyNameTooltip>
                                */}
                                <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500 }}
                                >
                                    {t(
                                        getPropertyStreet(
                                            street_no,
                                            propertyNameTrimLength
                                        )
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1}>
                                    <PlaceIcon sx={{ color: "#5BAFA9" }} />
                                    <Typography variant="body2">
                                        {city_name}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <PropertyCategoryAndPrice
                                    color={"#5BAFA9"}
                                    styleProps={{ fontSize: "15px" }}
                                >
                                    {getPropertyPrice(price)}
                                </PropertyCategoryAndPrice>
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PropertyCard;
