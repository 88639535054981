import React from "react";

//* context form
import MultiStepFormContext from "../../context/MultiStepFormContext";

function DossierForm() {
    return <MultiStepFormContext />;
}

export default DossierForm;
