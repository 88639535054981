import * as yup from "yup";

// const twelveYearInMilliSeconds = 16 * 365 * 24 * 60 * 60 * 1000;

export const briefFormSchema = [
   //* step one
   yup.object({}),

   //* step two
   yup.object({
      salutation: yup.string().required("please_select_your_gender"),
      firstName: yup
         .string()
         .required("please_enter_your_first_name")
         .min(2)
         .max(255),
      lastName: yup
         .string()
         .required("please_enter_your_last_name")
         .min(2)
         .max(255),
      // it's a optional field
      // dateOfBirth: yup
      //    .date("please_enter_a_valid_date")
      //    .nullable()
      //    .typeError("please_enter_a_valid_date")
      //    .required("please_enter_your_year_of_birth")
      //    .max(
      //       new Date(Date.now() - twelveYearInMilliSeconds),
      //       "your_age_should_be_more_than_18"
      //    ),
   }),

   //* step three
   yup.object({
      phoneNo: yup.string().max(19, "please_enter_a_valid_phone_number"),
      privatePhoneNo: yup.string().max(19, "please_enter_a_valid_phone_number"),
   }),

   //* step four
   yup.object({
      city: yup.mixed().nullable().required("please_enter_your_city"),
      zip: yup.string().required("please_enter_your_zip_code"),
      streetAddress: yup
         .string()
         .nullable()
         .required("please_enter_your_current_home_address"),
      streetNo: yup.string().required("please_enter_your_street_no"),
      country: yup.mixed().required("please_select_your_country"),
   }),
];
