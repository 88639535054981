import AccessibleIcon from '@mui/icons-material/Accessible'
import PetsIcon from '@mui/icons-material/Pets'
import BalconyIcon from '@mui/icons-material/Balcony'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CottageIcon from '@mui/icons-material/Cottage'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms'
import GarageIcon from '@mui/icons-material/Garage'
import ElevatorIcon from '@mui/icons-material/Elevator'
import LandscapeIcon from '@mui/icons-material/Landscape'
import ConstructionIcon from '@mui/icons-material/Construction'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import FireplaceIcon from '@mui/icons-material/Fireplace'
import InfoIcon from '@mui/icons-material/Info'

export const getAttributeIcons = (attribute, iconColor) => {
    let attributeIcon
    switch (attribute) {
        case 'haswheelchairaccess':
            attributeIcon = <AccessibleIcon fontSize="medium"
                                            sx={{color: iconColor}}/>
            break
        case 'petsallow':
            attributeIcon = <PetsIcon fontSize="medium"
                                      sx={{color: iconColor}}/>
            break
        case 'balcony/terrace':
            attributeIcon = <BalconyIcon fontSize="medium"
                                         sx={{color: iconColor}}/>
            break
        case 'parkingplace':
            attributeIcon = <LocalParkingIcon fontSize="medium"
                                              sx={{color: iconColor}}/>
            break
        case 'privatewashingmachine':
            attributeIcon = <LocalLaundryServiceIcon fontSize="medium"
                                                     sx={{color: iconColor}}/>
            break
        case 'quietneighbourhood':
            attributeIcon = <HolidayVillageIcon fontSize="medium"
                                                sx={{color: iconColor}}/>
            break
        case 'minergiecertified':
            attributeIcon = <AssignmentTurnedInIcon fontSize="medium"
                                                    sx={{color: iconColor}}/>
            break
        case 'oldbuilding':
            attributeIcon = <CottageIcon fontSize="medium"
                                         sx={{color: iconColor}}/>
            break
        case 'child-friendly':
            attributeIcon = <ChildFriendlyIcon fontSize="medium"
                                               sx={{color: iconColor}}/>
            break
        case 'smokingpermitted':
            attributeIcon = <SmokingRoomsIcon fontSize="medium"
                                              sx={{color: iconColor}}/>
            break
        case 'garage':
            attributeIcon = <GarageIcon fontSize="medium"
                                        sx={{color: iconColor}}/>
            break
        case 'elevator':
            attributeIcon = <ElevatorIcon fontSize="medium"
                                          sx={{color: iconColor}}/>
            break
        case 'view':
            attributeIcon = <LandscapeIcon fontSize="medium"
                                           sx={{color: iconColor}}/>
            break
        case 'minergieconstruction':
            attributeIcon = <ConstructionIcon fontSize="medium"
                                              sx={{color: iconColor}}/>
            break
        case 'newbuilding':
            attributeIcon = <DomainAddIcon fontSize="medium"
                                           sx={{color: iconColor}}/>
            break
        case 'fireplace':
            attributeIcon = <FireplaceIcon fontSize="medium"
                                           sx={{color: iconColor}}/>
            break

        default:
            attributeIcon = <InfoIcon fontSize="medium"
                                      sx={{color: iconColor}}/>
    }
    return attributeIcon
}
