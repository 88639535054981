import { useEffect, useState } from "react";
//*  using toast for success and error
import { toast } from "react-toastify";

//* Translation
import { useTranslation } from "react-i18next";

//* mui component import
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { motion, AnimatePresence } from "framer-motion";

import { MuiOtpInput } from "mui-one-time-password-input";
import useVerifyCode from "../../../hooks/Auth/useVerifyCode";
import useResendVerification from "../../../hooks/Auth/useResendVerification";
import { useAuthModal } from "../../../context/ModalContext";
import ThankyouForm from "./ThankyouForm";
import { enterAndExit } from "../../../utils/animation/Animation";
import useAuth from "../../../hooks/Auth/useAuth";
import { actionTypes, useStateValue } from "../../../store";
import { useNavigate } from "react-router-dom";
import useIsSmartDossierPaid from "../../../hooks/transaction/useIsSmartDossierPaid";

function VerifyOtpForm() {
    const { t } = useTranslation();
    const { authData, updateUserRole, userContext } = useAuthModal();

    const origin = userContext.origin;
    const action = userContext.action;
    const actionValue = userContext.actionValue;

    /*
    console.log(
        "Origin VerifyOtpForm: " + origin + " / " + action + " / " + actionValue
    );
    */
    const [otp, setOtp] = useState("");
    const { isLoading, mutate } = useVerifyCode();
    const { mutate: onResend, isLoading: resendIsLoading } =
        useResendVerification();
    const [isVerified, setIsVerified] = useState(false);

    const [success, setSuccess] = useState(false);
    const { setAuth } = useAuth();
    const [{ user_id }, dispatch] = useStateValue();
    const { closeAuthModal } = useAuthModal();
    const navigate = useNavigate();

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const [verifyStatus, setVerifyStatus] = useState({
        isError: false,
        isSuccess: false,
        message: "",
    });

    const redirectToProfile = setTimeout(() => {
        if (user_id && success) {
            //navigate("/account/fs/smart-dossier");
        }
    }, 800);

    const redirectToCreateProperty = setTimeout(() => {
        if (user_id && success) {
            //navigate("/create-object");
        }
    }, 800);

    const redirectToPropertyApplication = setTimeout(() => {
        if (user_id && success) {
            //navigate("/property/" + actionValue + "#contact-seller-section");
        }
    }, 800);

    const redirectToCreateSmartDossier = setTimeout(() => {
        if (user_id && success) {
            //navigate("/dossier-form/any-property");
        }
    }, 800);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(20);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [minutes, seconds]);

    const handleResend = () => {
        onResend(
            { email: authData?.email },
            {
                onSuccess: (data) => {
                    if (!data?.data?.statuscode) {
                        toast.error(t("something_went_wrong"));
                        return;
                    }
                    toast.success(
                        t(
                            "we_have_sent_have_sent_a_new_code_to_your_email_address"
                        )
                    );
                    setMinutes(1);
                    setSeconds(59);
                },
                onError: () => {
                    toast.error(`${t("we_are_facing_some_technical_issue")}`);
                },
            }
        );
    };

    const handleOtp = (value) => setOtp(value);

    // Payment check
    const {
        isLoading: isLoadingDossierPaid,
        data: smartDossierPayment,
        status: smartDossierPaymentStatus,
    } = useIsSmartDossierPaid(user_id);

    const handleComplete = (value) => {
        let data = {
            key: authData?.email,
            PIN: value,
        };
        mutate(data, {
            onSuccess: (data) => {
                //console.log(data);
                if (!data?.data?.statuscode) {
                    setVerifyStatus({
                        isError: true,
                        isSuccess: false,
                    });
                    return;
                }
                setVerifyStatus({
                    isError: false,
                    isSuccess: true,
                });
                setTimeout(() => {
                    setIsVerified(true);
                }, 800);
                setSuccess(true);
                const userId = data?.data?.data?.[0]?.id;
                const userEmail = data?.data?.data?.[0]?.email;
                const accessToken = data?.data?.accessToken;
                const userRole = data?.data?.data?.[0]?.role_type;
                const username = data?.data?.data?.[0]?.username;
                const user_role =
                    userRole !== null
                        ? userRole
                        : username === "owner"
                        ? "PM"
                        : "TN";

                // set by AAR to handle redirection and switch for properties

                setAuth({ userId, userEmail, accessToken, user_role });
                localStorage.setItem("nch_id", data?.data?.accessToken);
                localStorage.setItem(
                    "nch_user_data",
                    JSON.stringify({
                        id: data?.data?.data[0].id,
                        user_email: data?.data?.data[0].email,
                        user_role: user_role,
                    })
                );

                dispatch({
                    type: actionTypes.SET_USER,
                    token: data?.data?.accessToken,
                    user_id: data?.data?.data[0].id,
                    user_email: data?.data?.data[0].email,
                    user_role: user_role,
                });

                setTimeout(() => {
                    closeAuthModal();

                    /*
                    if (userRole === "PM") {
                        clearTimeout(redirectToCreateProperty);
                    } else {
                        clearTimeout(redirectToProfile);
                    }
                     */
                }, 800);

                let isPaid = Boolean(smartDossierPayment?.dossierPayment);
                if (isPaid) {
                    if (origin === "propertyApplication") {
                        navigate(
                            "/property/" +
                                actionValue +
                                "#contact-seller-section"
                        );
                    }
                } else if (origin === "createSmartDossier") {
                    clearTimeout(redirectToProfile);
                    //navigate("/brief-dossier-form");
                } else {
                    clearTimeout(redirectToProfile);
                    //navigate("/dossier-payment");
                }

                /*
                 */
            },
            onError: (error) => {
                console.error(error);
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
            },
        });
    };

    return (
        <Box>
            <AnimatePresence initial={false}>
                {/* {isVerified ? (
                    <motion.div
                        variants={enterAndExit}
                        animate={"visible"}
                        exit={"hidden"}
                        // initial={"hidden"}
                    >
                        <ThankyouForm />
                    </motion.div>
                ) : ( */}
                <motion.div
                    variants={enterAndExit}
                    animate={"visible"}
                    exit={"hidden"}
                >
                    <Grid container rowSpacing={3}>
                        <Grid item xs={12}>
                            <Typography textAlign={"center"} variant="h4">
                                {t("check_your_email_for_a_code")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle1"
                                textAlign={"center"}
                            >
                                {t(
                                    "please_enter_6_character_code_sent_to_your_mail"
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {verifyStatus?.isError && (
                                <Alert severity="error">
                                    {t(
                                        "verification_code_is_invalid_or_expired"
                                    )}
                                </Alert>
                            )}
                            {verifyStatus?.isSuccess && (
                                <Alert severity="success">
                                    {t("verification_code_verified")}
                                </Alert>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ position: "relative" }}>
                                <MuiOtpInput
                                    length={6}
                                    columnGap={{
                                        xs: "5px",
                                        md: "20px",
                                    }}
                                    value={otp}
                                    onChange={handleOtp}
                                    onComplete={handleComplete}
                                    TextFieldsProps={{
                                        // size: "small",
                                        placeholder: "-",
                                        disabled: isLoading,
                                        error: verifyStatus?.isError,
                                    }}
                                />
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                        {verifyStatus?.isSuccess || (
                            <Grid item xs={12}>
                                <Stack
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    {seconds > 0 || minutes > 0 ? (
                                        <Typography
                                            variant="subtitle1"
                                            textAlign={"center"}
                                        >
                                            {t("time_remaining")}
                                            <br />
                                            {`${minutes}m ${seconds}s`}
                                        </Typography>
                                    ) : (
                                        <Typography variant="subtitle1">{`${t(
                                            "didnt_recieve_the_code"
                                        )}?`}</Typography>
                                    )}
                                    <ButtonBase
                                        sx={{
                                            "&.Mui-disabled": {
                                                color: "#dedadb",
                                            },
                                            color: (theme) =>
                                                seconds > 0 || minutes > 0
                                                    ? "#DFE3E8"
                                                    : theme.palette.primary
                                                          .dark,
                                        }}
                                        disabled={
                                            seconds > 0 ||
                                            minutes > 0 ||
                                            resendIsLoading
                                        }
                                        onClick={handleResend}
                                    >
                                        <u>
                                            {resendIsLoading
                                                ? `${t("resend_code")}...`
                                                : t("resend_code")}
                                        </u>
                                    </ButtonBase>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </motion.div>
                {/* )} */}
            </AnimatePresence>
        </Box>
    );
}

export default VerifyOtpForm;
