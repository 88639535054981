import React, { useEffect } from "react";

//*  using toast for success and error
// import { Zoom, toast } from "react-toastify";

//*  Translation
// import { useTranslation } from "react-i18next";

//* libraries
import { useParams } from "react-router-dom";

//* store
import { useStateValue } from "../../store";

//* api calls
import usePropertyById from "../../hooks/properties/usePropertyById";

//* components import
import PropertyMobileHeading from "../../component/properties/property-details/property-detail-mobile/PropertyMobileHeading";
import { PropertyMobileTitle } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileTitle";
import { PropertyMobileFeatures } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileFeatures";
import { PropertyMobileAmenities } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileAmenities";
import { PropertyMobileDescription } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileDescription";
import { PropertyMobileAdvertiserBrief } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileAdvertiserBrief";
import { PropertyMobileLocation } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileLocation";
import { PropertyMobileContactSeller } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileContactSeller";
import { PropertyMobileImages } from "../../component/properties/property-details/property-detail-mobile/PropertyMobileImages";

//* mui components imports
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";
import Loading from "../../component/loading/Loading";
import { checkForNull } from "../../utils/checkDataFunction";

//* Dynamic path
const profileImageUrl = process.env.REACT_APP_PROFILE_IMAGE_URL;
const propertyImageUrl = process.env.REACT_APP_PROPERTY_IMAGE_URL;

export const PropertyDetailsMobileView = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [{ user_id, token }] = useStateValue();
    let property = {
        id: params.id,
        lang: "en",
        user_id: user_id,
    };
    const { data, isLoading } = usePropertyById(property);

    let propertyDetails = data?.data?.data[0];
    //   const video = propertyDetails?.youtube_link;
    //console.log(propertyDetails)}
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, [params.id]);

    if (isLoading)
        return (
            <Loading
                containerStyle={{
                    height: "100vh",
                }}
            />
        );

    return (
        <>
            <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                    <PropertyMobileHeading
                        propertyId={propertyDetails?.id}
                        propertyOwnerId={propertyDetails?.user_id}
                        userId={user_id}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <PropertyMobileImages
                            propertyDetailsImages={propertyDetails?.images}
                            propertyName={propertyDetails?.name}
                            propertyId={propertyDetails?.id}
                            imageUrl={propertyImageUrl + params.id + "/"}
                            isFav={propertyDetails?.is_fav}
                            propertyOwnerId={propertyDetails?.user_id}
                            userId={user_id}
                            token={token}
                        />
                    </Box>
                </Grid>
                <Container maxWidth="xl">
                    <Grid item xs={12}>
                        <PropertyMobileTitle heading={propertyDetails?.name} />
                    </Grid>

                    <Divider sx={{ my: 4 }} />

                    <Grid item xs={12}>
                        <PropertyMobileFeatures
                            categoryId={propertyDetails?.category_id}
                            rooms={propertyDetails?.number_bedroom}
                            numberOfFloors={propertyDetails?.floor}
                            livingSpace={propertyDetails?.living_space}
                            availabilityDate={
                                propertyDetails?.availability_date
                            }
                            availability={propertyDetails?.availability}
                        />
                    </Grid>

                    <Divider sx={{ my: 4 }} />

                    <Grid item xs={12}>
                        <PropertyMobileAmenities
                            amenities={propertyDetails?.attributes}
                            title={t("property_detail_amenities")}
                        />
                    </Grid>
                    <Divider sx={{ my: 4 }} />

                    <Grid item xs={12}>
                        <PropertyMobileDescription
                            description={propertyDetails?.description}
                            title={t("description")}
                        />
                    </Grid>
                    <Divider sx={{ my: 4 }} />

                    <Grid item xs={12}>
                        <PropertyMobileAdvertiserBrief
                            hostName={propertyDetails?.host_name}
                            advertiserPicture={propertyDetails?.profile_pic}
                            profileImageUrl={profileImageUrl}
                            title={t("property_detail_contact_provider")}
                        />
                    </Grid>
                    <Divider sx={{ my: 4 }} />
                    {checkForNull(propertyDetails?.latitude) ||
                    checkForNull(propertyDetails?.longitude) ? (
                        <Grid item xs={12}>
                            <PropertyMobileLocation
                                latitude={propertyDetails?.latitude}
                                longitude={propertyDetails?.longitude}
                                title={t("company_location")}
                            />
                        </Grid>
                    ) : null}
                </Container>
            </Grid>
            <PropertyMobileContactSeller
                name={propertyDetails?.name}
                price={propertyDetails?.price}
                propertyId={propertyDetails?.id}
                propertyOwnerId={propertyDetails?.user_id}
                contactButtonText={t("apply")}
                contactButtonAddonText={t("property_detail_is_monthly_rent")}
            />
        </>
    );
};
