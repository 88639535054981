// import { useState } from "react";

//* mui components imports
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* get all amenities
import {getAttributeIcons} from "../../../../utils/getAmenities";
import {useTheme} from "@mui/material/styles";

function PropertyAmenities({attributes, title}) {
    // const [isOpen, setIsOpen] = useState(true);
    // const handleCollapse = () => {
    //   setIsOpen(!isOpen);
    // };

    let attributeArray = attributes?.split(",");

    const theme = useTheme();

    const iconColor = theme.palette.secondary.main;

    return (
        <Stack spacing={1}>
            <Typography variant='h5'>
                {title}
            </Typography>
            {
                //console.log("Detail:" + attributes)
            }
            {attributeArray?.length ? (
                <Grid container rowSpacing={2}>
                    {attributeArray.map((attribute, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {getAttributeIcons(attribute.replace(/\s/g, '').toLowerCase(), iconColor)}
                                <Typography sx={{ color: "#717171" }}>{attribute}</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography component="p" sx={{ color: "#717171" }}>
                    Keine Angabe
                </Typography>
            )}
        </Stack>
    );
}

export default PropertyAmenities;
