import React from "react";

//* mui components imports
import Box from "@mui/material/Box";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

export const ScoreProgress = ({ size, score, isColor }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: "relative",
                cursor: "pointer",
                height: size,
                width: size,
                borderRadius: "50%",
                "&:hover": {
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                },
            }}
        >
            <CircularProgress
                variant="determinate"
                sx={{
                    color: "#F7F7f7",
                }}
                size={size}
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                    color: theme.palette.secondary.main,
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                    },
                }}
                size={size}
                thickness={4}
                value={parseInt(score)}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    m: 1,
                }}
            >
                <Typography variant="subtitle2" sx={{ fontSize: "0.85rem" }}>
                    {score === undefined || score === null ? (
                        <Skeleton width={40} />
                    ) : (
                        score + "%"
                    )}
                </Typography>
            </Box>
        </Box>
    );
};
