import { useQuery } from "react-query";
import { Axios } from "../../axios/axios";

function getAdminInfo({ queryKey }) {
    let lang = queryKey[1];
    return Axios.get(`/property/get_all_setting_data?lang=${lang}`);
}

export function useGetAdminInfoData(lang = "en") {
    return useQuery(["admin-info", lang], getAdminInfo);
}
