import React from "react";
import { Controller } from "react-hook-form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = ({ control, name, loading }) => {
   return (
      <Controller
         control={control}
         name={name}
         render={({ field }) => {
            const { onChange, value } = field;

            // Custom function to handle changes in the Quill editor content
            const handleQuillChange = (html) => {
               // Remove the "<p><br></p>" when the editor becomes empty
               if (html === "<p><br></p>") {
                  html = "";
               }
               onChange(html); // Update the form field value
            };
            return (
               <ReactQuill
                  {...field}
                  value={value}
                  onChange={handleQuillChange}
                  theme='snow'
                  readOnly={loading}
                  style={{
                     minHeight: "100%",
                  }}
               />
            );
         }}
      />
   );
};

export default RichTextEditor;
