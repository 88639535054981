import React from "react";

//* custom components
import { Img } from "../../../img/Img";

//* image imports
import { profilePlaceholder } from "../../../../assets/imgImport";

//* mui components imports
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function AdvertiserBrief({
    advertiserPhone,
    advertiserEmail,
    advertiserName,
    advertiserProfile,
    imageUrl,
    title,
}) {
    return (
        <Stack spacing={2}>
            <Typography variant="h5">{title}</Typography>
            <Stack direction={"row"} spacing={2}>
                <Img
                    src={
                        advertiserProfile
                            ? imageUrl + advertiserProfile
                            : profilePlaceholder
                    }
                    alt={advertiserName}
                    height={100}
                    width={100}
                    style={{
                        borderRadius: "10%",
                    }}
                />
                <Stack spacing={2}>
                    <Typography variant="h6">
                        {advertiserName || "N/A"}
                    </Typography>
                    <Typography variant="h6">
                        {advertiserEmail || "N/A"}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default AdvertiserBrief;
