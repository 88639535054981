// ----------------------------------------------------------------------

export function remToPx(value) {
    return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
    return `${value / 16}rem`;
}

export function responsiveFontSizes({ xs, sm, md, lg }) {
    return {
        "@media (min-width:300px)": {
            fontSize: pxToRem(xs),
        },
        "@media (min-width:600px)": {
            fontSize: pxToRem(sm),
        },
        "@media (min-width:900px)": {
            fontSize: pxToRem(md),
        },
        "@media (min-width:1200px)": {
            fontSize: pxToRem(lg),
        },
    };
}

// ----------------------------------------------------------------------

const typography = {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontWeightRegular: 300,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 600,
        fontSize: pxToRem(58),
        lineHeight: "64px",
        ...responsiveFontSizes({ xs: 44, sm: 44, md: 58, lg: 58 }),
    },
    h2: {
        fontWeight: 600,
        fontSize: pxToRem(46),
        lineHeight: "54px",
        ...responsiveFontSizes({ xs: 36, sm: 36, md: 46, lg: 46 }),
    },
    h3: {
        fontWeight: 600,
        fontSize: pxToRem(38),
        lineHeight: "46px",
        ...responsiveFontSizes({ xs: 30, sm: 30, md: 32, lg: 38 }),
    },
    h4: {
        fontWeight: 600,
        fontSize: pxToRem(28),
        lineHeight: "36px",
        ...responsiveFontSizes({ xs: 22, sm: 22, md: 28, lg: 28 }),
    },
    h5: {
        fontWeight: 600,
        fontSize: pxToRem(20),
        lineHeight: "28px",
        ...responsiveFontSizes({ xs: 18, sm: 18, md: 20, lg: 20 }),
    },
    h6: {
        fontWeight: 600,
        lineHeight: "28px",
        fontSize: pxToRem(18),
        ...responsiveFontSizes({ xs: 16, sm: 16, md: 18, lg: 18 }),
    },
    subtitle1: {
        fontWeight: 400,
        lineHeight: "30px",
        fontSize: pxToRem(20),
    },
    subtitle2: {
        fontWeight: 400,
        lineHeight: "28px",
        fontSize: pxToRem(18),
    },
    body1: {
        fontWeight: 300,
        lineHeight: "28px",
        fontSize: pxToRem(18),
        ...responsiveFontSizes({ xs: 16, sm: 16, md: 18, lg: 18 }),
    },
    body2: {
        fontWeight: 300,
        lineHeight: 22 / 14,
        fontSize: pxToRem(16),
    },

    caption: {
        lineHeight: "18px",
        fontSize: pxToRem(12),
    },
};

export default typography;
