
export const BENEFITS = [
   {
      id: 1,
      title: "new_tenant_title_one",
   },
    {
       id: 2,
       title: "new_tenant_title_two",
    },
    {
       id: 3,
       title: "new_tenant_title_three",
    },
   {
      id: 4,
      title: "new_tenant_title_four",
   },
   {
      id: 5,
      title: "new_tenant_title_five",
   },
   {
      id: 6,
      title: "new_tenant_title_six",
   },
];
