const FOOTER_LINKS = [
    {
        id: 1,
        title: "create_product",
        to: "/create-dossier",
    },
    {
        id: 2,
        title: "partnership",
        to: "/partnership",
    },
    {
        id: 3,
        title: "about",
        to: "/about",
    },
    {
        id: 4,
        title: "contact",
        to: "/contact",
    },
    // {
    //     id: 4,
    //     title: "FAQ",
    //     to: "/",
    // },
];

export default FOOTER_LINKS;
