import React, { useState } from "react";

//*  Translation
import { useTranslation } from "react-i18next";

//* libraries
import { useNavigate, useSearchParams } from "react-router-dom";

//* mui components imports
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//8 SVG
import PaymentSuccessSvg from "../../component/svg/PaymentSuccessSvg";
import { useStateValue } from "../../store";
import { useCheckDossier } from "../../hooks/profile/useCheckDossier";
import PaymentFailedSvg from "../../component/svg/PaymentFailedSvg";
import PageNotFound from "../Error-pages/PageNotFound";

function SmartDossierPaymentStatus() {
    const [searchParams] = useSearchParams();

    let paymentStatus = searchParams.get("status");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [{ user_id }] = useStateValue();

    const {
        isLoading: checkDossierIsLoading,
        data: checkDossierCreated,
        status: checkDossierStatus,
    } = useCheckDossier(user_id);

    const isDossierCreated = checkDossierCreated?.data?.briefFormFilled;

    const handleRedirect = () => {
        if (checkDossierStatus === "success" && isDossierCreated) {
            navigate("/account/fs/smart-dossier");
            return;
        }
        if (checkDossierStatus === "success" && !isDossierCreated) {
            navigate("/brief-dossier-form");
            return;
        }
    };

    if (paymentStatus === "failed") {
        return (
            <Container maxWidth="lg" sx={{ p: 5 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Stack spacing={3} justifyContent={"center"}>
                            <Typography variant="h1">
                                {t("payment_failed")}
                            </Typography>
                            <Typography variant="p">
                                {t("seems_to_be_problem_with_payment")}
                            </Typography>
                            <Stack direction="row" gap={2} flexWrap={"wrap"}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    disabled={checkDossierIsLoading}
                                    onClick={() =>
                                        navigate("/dossier-payment", {
                                            replace: true,
                                        })
                                    }
                                >
                                    {t("try_again")}
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    disabled={checkDossierIsLoading}
                                    onClick={() =>
                                        navigate("/", {
                                            replace: true,
                                        })
                                    }
                                >
                                    {t("go_to_homepage")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PaymentFailedSvg />
                    </Grid>
                </Grid>
            </Container>
        );
    }

    if (paymentStatus === "success")
        return (
            <Container maxWidth="lg" sx={{ p: 5 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Stack spacing={3} justifyContent={"center"}>
                            <Typography variant="h1">
                                {t("payment_successful")}
                            </Typography>

                            <Typography variant="p">
                                {t("smart_dossier_is_on_the_way")}
                            </Typography>
                            <Stack direction="row">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    disabled={checkDossierIsLoading}
                                    onClick={handleRedirect}
                                >
                                    {t("complete_your_dossier_now")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PaymentSuccessSvg />
                    </Grid>
                </Grid>
            </Container>
        );

    return <PageNotFound />;
}

export default SmartDossierPaymentStatus;
