import React, { useEffect, useState, useRef, useCallback } from "react";

//* google maps API
import {
    GoogleMap,
    InfoWindowF,
    MarkerF,
    useJsApiLoader,
} from "@react-google-maps/api";
import * as geolib from "geolib";

//* component imports
import MapCard from "../cards/MapCard";

//* mui components imports
import { Skeleton } from "@mui/material";
import { checkForNull } from "../../utils/checkDataFunction";
const containerStyle = {
    width: "100%",
    minHeight: "100%",
};

function Map({ properties, isSingleLocation, latitude, longitude, removeMap }) {
    const mapRef = useRef();
    const zoomLevel = isSingleLocation ? 15 : 10;
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 47.3769, lng: 8.5417 });
    const [flatCoordinates, setFlatCoordinates] = useState([]);
    const { isLoaded } = useJsApiLoader({
        id: "google-Box-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    const onMapClick = useCallback(() => {
        setSelectedCenter(null);
    }, []);

    useEffect(() => {
        if (Array.isArray(properties) && properties.length > 0) {
            const coordinates = properties.filter(
                (property) =>
                    checkForNull(property.latitude) !== null ||
                    checkForNull(property.longitude) !== null
            );
            setFlatCoordinates(coordinates);
        }
    }, [latitude, longitude, properties]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                setSelectedCenter(null);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const listener = (e) => {
            if (e.key === "Escape") setSelectedCenter(null);
        };
        window.addEventListener("keydown", listener);
        return () => window.removeEventListener("keydown", listener);
    }, []);

    useEffect(() => {
        const calculateCenter = () => {
            if (isSingleLocation) {
                return {
                    lat: Number(latitude),
                    lng: Number(longitude),
                };
            } else if (properties && properties.length > 0) {
                //console.log(properties);
                const filteredLatAndLongs = properties.filter(
                    ({ latitude, longitude }) => {
                        return (
                            checkForNull(latitude) !== null ||
                            checkForNull(longitude) !== null
                        );
                    }
                );
                const latAndLongs = filteredLatAndLongs.map(
                    ({ latitude, longitude }) => ({
                        latitude: Number(latitude),
                        longitude: Number(longitude),
                    })
                );
                console.log("🚀 ~ calculateCenter ~ latAndLongs:", latAndLongs);
                const geolibCenter = geolib.getCenter(latAndLongs);
                return {
                    lat: geolibCenter.latitude,
                    lng: geolibCenter.longitude,
                };
            }
            // Fallback, wenn keine gültigen Koordinaten verfügbar sind
            return { lat: 47.3769, lng: 8.5417 };
        };
        if (!mapRef.current) return;
        setMapCenter(calculateCenter());
    }, [properties, isSingleLocation, latitude, longitude]);

    if (!isLoaded)
        return (
            <Skeleton
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            />
        );
    // const svgMarker = {
    //     path: "M32,0C18.746,0,8,10.746,8,24c0,5.219,1.711,10.008,4.555,13.93c0.051,0.094,0.059,0.199,0.117,0.289l16,24 C29.414,63.332,30.664,64,32,64s2.586-0.668,3.328-1.781l16-24c0.059-0.09,0.066-0.195,0.117-0.289C54.289,34.008,56,29.219,56,24 C56,10.746,45.254,0,32,0z M32,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S36.418,32,32,32z",
    //     fillColor: "#68437E",
    //     fillOpacity: 0.8,
    //     strokeWeight: 0,
    //     rotation: 0,
    //     size: new window.google.maps.Size(16, 16),
    // };
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={zoomLevel}
            onLoad={(map) => (mapRef.current = map)}
            onClick={onMapClick}
            options={{
                streetViewControl: false, // This line disables the Street View control
            }}
        >
            {flatCoordinates?.map((property) => {
                return (
                    <MarkerF
                        key={property.id}
                        position={{
                            lat: Number(property.latitude),
                            lng: Number(property.longitude),
                        }}
                        // options={{
                        //     icon: svgMarker,
                        // }}
                        onClick={() => setSelectedCenter(property)}
                    />
                );
            })}

            {selectedCenter && (
                <InfoWindowF
                    position={{
                        lat: Number(selectedCenter.latitude),
                        lng: Number(selectedCenter.longitude),
                    }}
                    onCloseClick={() => setSelectedCenter(null)}
                >
                    <MapCard property={selectedCenter} />
                </InfoWindowF>
            )}
        </GoogleMap>
    );
}

export default Map;
