//* libraries
import moment from "moment";

export const isGreaterThanYear = (date, num) => {
  if (moment(date).isValid()) {
    let yearBefore = moment().get("year") - num;
    return moment(date).get("year") > yearBefore;
  } else {
    console.error("NOt valid:", date);
    return false;
  }
};


/*
export const isGreaterThanYear = (date, num) => {
  let yearBefore = moment().get("year") - num;
  return date.get("year") > yearBefore;
};
*/
