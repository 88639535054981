import {useNavigate} from "react-router-dom";
//* mui imports
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material";

//* img imports
import {AboutHeader} from "../../utils/imgImports";

import HeroLayout from "../../component/layouts/HeroLayout";
import HowDossierWorks from "../../component/section/HowDossierWorks";
import ApplyToSmartDossier from "../../component/section/ApplyToSmartDossier";
import NextflatTeam from "../../component/section/NextflatTeam";

const ContainerBox = styled(Container)(() => ({
    "&.MuiContainer-root": {
        padding: 0,
    },
}));

const AboutUs = () => {
    const navigate = useNavigate();
    const onToTheSmartDossier = () => navigate("/create-dossier");
    return (
        <Stack rowGap={1}>
            <HeroLayout
                pageName={"about_nextflat"}
                dropletImage={AboutHeader}
                dropletImagePosition={"left"}
                dropletImageSize={560}
                heroHeading={"the_key_to_your_dream_object"}
                heroSubHeading={"our_smart_dossier_revolutionizes"}
                heroButtonText={"to_the_smart_dossier"}
                heroButtonOnClick={onToTheSmartDossier}
                dropletImageAlt={"hero-image"}
                style={{marginTop: '-50px'}}
            />

            <Container maxWidth="lg">
                <HowDossierWorks/>
            </Container>

            <ContainerBox maxWidth="xl">
                <ApplyToSmartDossier coloredBg/>
            </ContainerBox>

            <Container maxWidth="lg">
                <NextflatTeam/>
            </Container>
        </Stack>
    );
};

export default AboutUs;
