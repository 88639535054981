import {DossierLottie, HomeRocket, HouseLottie} from "../utils/imgImports";

export const HOW_IT_WORKS = [
    {
        id: "how_it_work_title_one_title",
        title: "how_it_work_title_one",
        subtitle: "how_it_work_subtitle_one",
        lottieIcon: HouseLottie,
    },
    {
        id: "how_it_work_title_two_title",
        title: "how_it_work_title_two",
        subtitle: "how_it_work_subtitle_two",
        lottieIcon: DossierLottie,
    },
    {
        id: "how_it_work_title_three_title",
        title: "how_it_work_title_three",
        subtitle: "how_it_work_subtitle_three",
        lottieIcon: HomeRocket,
    },
];

export const HOW_IT_WORKS_PROPERTY = [
    {
        id: "how_it_work_title_one_title_property",
        title: "how_it_work_title_one_property",
        subtitle: "how_it_work_subtitle_one_property",
        lottieIcon: HouseLottie,
    },
    {
        id: "how_it_work_title_two_title_property",
        title: "how_it_work_title_two_property",
        subtitle: "how_it_work_subtitle_two_property",
        lottieIcon: DossierLottie,
    },
    {
        id: "how_it_work_title_three_title_property",
        title: "how_it_work_title_three_property",
        subtitle: "how_it_work_subtitle_three_property",
        lottieIcon: HomeRocket,
    },
];

//export { HOW_IT_WORKS, HOW_IT_WORKS_PROPERTY };
