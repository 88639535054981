import React, {createContext, useState, useEffect} from "react";

import {useContext} from "react";
import AuthModal from "../component/modal/auth-modals/AuthModal";

export const ModalContext = createContext();

export const AuthModalProvider = ({children}) => {
    const [authForm, setAuthForm] = useState("login");
    const [authData, setAuthData] = useState(null);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [userRole, setUserRole] = useState(
        localStorage.getItem("userRole") || null
    );
    const [userId, setUserId] = useState(null);
    const [tmpUserContext, setTmpUserContext] = useState(null);

    const [userContext, setUserContext] = useState(
        localStorage.getItem("userContext") || {}
    );
    useEffect(() => {
        if (tmpUserContext && tmpUserContext.actionOpenValue === "enterContactData") {
            setAuthForm("enterBasicContactData");
        } else {
            setAuthForm("login");
        }
    }, [tmpUserContext]);

    /*
    console.log("userContext: ", userContext);
    console.log("tmpUserContext: ", tmpUserContext);
    console.log(userId);
    console.log("authForm:", authForm);
     */
    const openAuthModal = () => setShowAuthModal(true);

    const handleTmpUserContext = (id) => {
        setTmpUserContext(id);
    };

    const handleUserId = (id) => {
        setUserId(id);
    };

    const handleUserRole = (role) => {
        setUserRole(role);
    };

    const closeAuthModal = () => {
        setShowAuthModal(false);
        setTimeout(() => {
            setAuthForm("login");
        }, 500);
    };

    const handleAuthForms = (form) => {
        // console.log(`Opening form: ${form}`);
        setAuthForm(form);
    };
    const handleAuthData = (data) => setAuthData(data);

    return (
        <ModalContext.Provider
            value={{
                showAuthModal,
                openAuthModal,
                closeAuthModal,
                authForm,
                authData,
                handleAuthForms,
                handleAuthData,
                userRole,
                userContext,
                handleTmpUserContext,
                handleUserRole,
                handleUserId,
            }}
        >
            {children}
            <AuthModal open={showAuthModal} onClose={closeAuthModal}/>
        </ModalContext.Provider>
    );
};

export const useAuthModal = () => useContext(ModalContext);
