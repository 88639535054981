import React, { Fragment } from "react";

//* mui components import
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material";

//* mui icons imports
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import PlaceIcon from "@mui/icons-material/Place";
import StairsIcon from "@mui/icons-material/Stairs";
import useResponsive from "../../hooks/responsive/useResponsive";

const PROPERTY_DETAILS = [
    {
        id: 1,
        icon: <StairsIcon color="secondary" />,
        type: "floor",
    },
    {
        id: 2,
        icon: <BedIcon color="secondary" />,
        type: "room",
    },
    {
        id: 3,
        icon: <ControlCameraIcon color="secondary" />,
        type: "living",

        isLast: true,
    },
];

export default function PropertyCardWithDescriptionSkeleton() {
    const { isBelowMd } = useResponsive();
    const theme = useTheme();

    return (
        <Paper
            sx={{
                p: 1.25,
                borderRadius: 5,
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
            }}
            elevation={0}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} sx={{ position: "relative" }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="270px"
                        sx={{ borderRadius: 4 }}
                    />
                    {/* Favorite Icon */}
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={30}
                        width={30}
                        borderRadius={"50%"}
                        // bgcolor={"#fe7529"}
                        sx={{
                            position: "absolute",
                            top: 30,
                            right: 20,
                            zIndex: 1,
                            cursor: "pointer",
                        }}
                    >
                        <Skeleton variant="circular">
                            <Avatar />
                        </Skeleton>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                justifyContent={"space-between"}
                            >
                                <Skeleton
                                    width="10%"
                                    height="38px"
                                    sx={{ bgcolor: "#eef8fe" }}
                                />
                                <Skeleton
                                    width="15%"
                                    height="38px"
                                    sx={{
                                        bgcolor: "#fe620033",
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <Skeleton width="65%" height="30px" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton width="100%" height="18px" />
                                    <Skeleton width="100%" height="18px" />
                                    <Skeleton width="30%" height="18px" />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 2,
                                    }}
                                >
                                    <PlaceIcon sx={{ color: "#fe7529" }} />
                                    <Skeleton width="38px" height="25px" />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: { xs: 3, md: 5 },
                                    }}
                                >
                                    {PROPERTY_DETAILS.map((item) => (
                                        <Fragment key={item.id}>
                                            <Box
                                                display="flex"
                                                flexDirection={"row"}
                                                gap={2}
                                            >
                                                {item.icon}
                                                <Skeleton
                                                    width={
                                                        isBelowMd
                                                            ? "10px"
                                                            : "35px"
                                                    }
                                                    height="25px"
                                                />
                                            </Box>
                                            {item.isLast || (
                                                <span
                                                    style={{
                                                        color: theme.palette
                                                            .primary.main,
                                                    }}
                                                >
                                                    &#8226;
                                                </span>
                                            )}
                                        </Fragment>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
