import * as yup from "yup";

export const RegisterSchema = yup.object().shape({
    email: yup
        .string()
        .email("email_is_invalid")
        .required("email_is_required")
        .min(6, "email_must_be_between_6_and_254_characters_long")
        .max(254, "email_must_be_between_6_and_254_characters_long"),

    // password: yup
    //     .string()
    //     .required("password_is_required")
    //     .min(8, "password_length_should_be_at_least_8_characters")
    //     .max(15, "password_length_should_be_at_least_15_characters"),
});
