import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function handleLogin(user) {
  return Axios.post("/users/new_login", user, {
    withCredentials: true,
  });
}

export default function useLogin() {
  return useMutation(handleLogin);
}
