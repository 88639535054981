import * as yup from "yup";

export const ContactSchema = yup.object().shape({
    fullName: yup.string().required("please_enter_your_name").min(2).max(255),
    email: yup
        .string()
        .email("email_is_invalid")
        .required("please_enter_your_email")
        .min(2)
        .max(255),
    subject: yup.string().required("please_enter_your_subject").min(2).max(255),
    // phone: yup
    //   .string()
    //   .required("Phone no. is required")
    //   .matches(/^\d+$/, "Please enter a valid number"),
    message: yup.string().required("please_enter_your_message").min(2).max(255),
});
