import React from "react";

//* libraries import
import { Link as RouterLink, useNavigate } from "react-router-dom";

//* mui components imports
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//* mui icon imports
import BedIcon from "@mui/icons-material/Bed";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import PlaceIcon from "@mui/icons-material/Place";
import StairsIcon from "@mui/icons-material/Stairs";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { CarousalImages } from "../mui-components/MuiPropertyCardComponents";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

//* MCI =  Map Card Image
const MCI = process.env.REACT_APP_PROPERTY_IMAGE_URL;

export default function MapCard({ property }) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                title={
                    <Typography variant="h6">{property?.street_no}</Typography>
                }
                subheader={
                    <Stack direction="row" sx={{ mt: 2 }}>
                        <PlaceIcon
                            sx={{ color: theme.palette.secondary.main }}
                        />
                        <Typography variant="h6">
                            {property?.city_name}
                        </Typography>
                    </Stack>
                }
            />

            <Grid item xs={12} md={12} lg={12} sx={{ position: "relative" }}>
                <CarousalImages
                    propertyImages={property?.images}
                    altTags={property?.street_no}
                    imageUrl={`${MCI}${property?.id}/`}
                    height={"250px"}
                    width={"100%"}
                />

                <Button
                    component={RouterLink}
                    to={`/property/${property?.id}`}
                    target="_blank"
                    sx={{
                        position: "absolute",
                        top: 12,
                        right: 12,
                        zIndex: 2,
                        fontSize: "0.75rem",
                        padding: "4px 8px",
                        borderRadius: 6,
                    }}
                    variant="contained"
                >
                    {t("details")}
                </Button>
            </Grid>

            <CardActions sx={{ justifyContent: "space-between" }}>
                <Tooltip
                    title={
                        (property?.floor || "NA") +
                        " " +
                        t("number_of_floor_single")
                    }
                >
                    <IconButton>
                        <StairsIcon
                            sx={{ color: theme.palette.secondary.main }}
                        />
                    </IconButton>
                    {
                        // + " " + t("number_of_floor_single")
                    }
                </Tooltip>
                <Typography>{property?.floor || "NA"}</Typography>

                <Tooltip
                    title={(property?.number_bedroom || "NA") + " bedrooms"}
                >
                    <IconButton>
                        <BedIcon sx={{ color: theme.palette.secondary.main }} />
                    </IconButton>
                </Tooltip>
                <Typography>{property?.number_bedroom || "NA"}</Typography>

                <Tooltip title={property?.living_space || "NA"}>
                    <IconButton>
                        <ControlCameraIcon
                            sx={{ color: theme.palette.secondary.main }}
                        />
                    </IconButton>
                </Tooltip>
                <Typography>
                    {property?.living_space || "NA"}m<sup>2</sup>
                </Typography>
                {/*
                <Button
                    variant='outlined'
                    sx={{
                        ml: 'auto',
                        borderRadius: '8px',
                        border: '1px solid',
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                    }}
                    color='secondary'
                    onClick={() => navigate(`/property/${property?.id}`)}
                >
                    {t("details")}
                </Button>
                */}
            </CardActions>
        </Card>
    );
}
