import React, {
    // forwardRef,
    useState,
} from "react";

//*  using toast for success and error
import { toast } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* store imports
import { useStateValue } from "../../../../store/StateProvider";
import { useNavigate } from "react-router-dom";

//* api calls
import { useAddWishlist } from "../../../../hooks/properties/useWishlist";

//* model component
import ShareModal from "../../../modal/properties/ShareModal";

//* mui components import
// import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

//* mui icons imports
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
// import CloseIcon from "@mui/icons-material/Close";
import Undo from "@mui/icons-material/Undo";
import Grid from "@mui/material/Grid";

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />;
// });

function PropertyHeading({
    propertyPrice,
    livingSpace,
    streetNo,
    advertiserName,
    advertiserPhone,
    advertiserProfile,
    propertyType,
    period,
    imageUrl,
    propertyName,
    propertyId,
    isPreview,
    isFav,
    propertyOwnerId,
    userId,
}) {
    const { t } = useTranslation();
    const handleNavigate = (path, context) => {
        navigate({
            pathname: path,
            search: `?context=${context}`,
        });
    };

    const navigate = useNavigate();
    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
        >
            <Grid item>
                <Button
                    sx={{
                        p: 2,
                        backgroundColor: "transparent",
                        color: "primary.main",
                        boxShadow: "none",
                        border: "none",
                    }}
                    startIcon={<Undo />}
                    variant="contained"
                    onClick={() => navigate(-1)}
                >
                    {t("back")}
                </Button>
            </Grid>

            <Grid item>
                {userId == propertyOwnerId && (
                    <Alert
                        severity="warning"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}
                    >
                        {t("manage_request_header_to_object_edit")}
                        <IconButton
                            color="primary"
                            onClick={() =>
                                handleNavigate(
                                    `/add-property-details/${propertyId}`,
                                    "fs-flats"
                                )
                            }
                            sx={{ marginLeft: 1 }}
                        >
                            <ExitToAppIcon />
                        </IconButton>
                    </Alert>
                )}
            </Grid>
        </Grid>
    );
}

export default PropertyHeading;
