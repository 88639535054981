import { useEffect } from "react";
import { useLocation } from "react-router-dom";

//* mui components imports
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

// store imports
import { useStateValue } from "./store/StateProvider";
import { actionTypes } from "./store/reducer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// page routes
import PageRoutes from "./routes/PageRoutes";

// component imports

// import Navigation from "./component/common/navigation/Navigation";
import ScrollTop from "./component/scrolls/ScrollTop";
import useAuth from "./hooks/Auth/useAuth";
import Footer from "./component/footer/Footer";
import Navigation from "./component/navigation/Navigation";

//hotjar
import { hotjar } from "react-hotjar";

import useBitrixScript from "./hooks/index/BitrixScrip";
import HIDE_NAVIGATION_LINKS from "./options/hideNavigationLinks";
import ViewMenuIconOnScroll from "./component/scrolls/ViewMenuIconOnScroll";

const Offset = styled("div")(({ theme }) => ({
    minHeight: 85,
    [theme.breakpoints.down("lg")]: {
        minHeight: 135,
    },
}));

// const hjid = 3692771;
// const hjsv = 6;

const excludedPathsFooter = [
    "/account",
    "/oauth-google-success",
    "/employer-reference-form",
    "/administration-reference-form",
    "/create-dossier",
    "/dossier-payment",
    "/dossier-form",
    "/switch-user-login",
];

const excludePathsBitrixScript = [
    "/dossier-form",
    "/create-dossier",
    "/oauth-google-success",
    "/employer-reference-form",
    "/administration-reference-form",
    "/create-dossier",
    "/dossier-payment",
    "/dossier-form",
    "/switch-user-login",
    "/dossier-form/any-property",
    "/account/fs/smart-dossier",
    "/property",
    "/create-object",
    "/account/nt",
    "/account/nt",
    "/account/fs",
    "/account/chats",
];

function App() {
    const { pathname } = useLocation();
    const [{ token, user_id }, dispatch] = useStateValue();
    const { auth, setAuth } = useAuth();

    const avoidShowingBitrixScript = !excludePathsBitrixScript.some((path) =>
        pathname.startsWith(path)
    );

    useBitrixScript(avoidShowingBitrixScript);

    useEffect(() => {
        if (token === null && user_id === null) {
            const user_token = localStorage.getItem("nch_id");
            const user = localStorage.getItem("nch_user_data");
            if (user_token !== null && user !== null) {
                const user_data = JSON.parse(user);
                setAuth({
                    user_id: user_data?.id,
                    user_email: user_data.user_email,
                    accessToken: user_token,
                    user_role: user_data?.user_role,
                });
                dispatch({
                    type: actionTypes.SET_USER,
                    token: user_token,
                    user_id: user_data.id,
                    user_email: user_data.user_email,
                    user_role: user_data?.user_role,
                });
            }
        }
    }, [dispatch, token, user_id, auth, setAuth]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, [pathname]);

    // useEffect(() => {
    //     hotjar.initialize(hjid, hjsv);
    // }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                }}
            >
                {pathname.includes("/account") ||
                pathname.includes("/oauth-google-success") ||
                pathname.includes("/switch-user-login") ? null : (
                    <Navigation />
                )}
                {pathname.includes("/account") ? null : <Offset />}
                <PageRoutes />
                {excludedPathsFooter.some((path) =>
                    pathname.includes(path)
                ) ? null : (
                    <Footer />
                )}

                {/*
                <ScrollTop/>
                */}
            </Box>
            <ToastContainer />
        </>
    );
}

export default App;
