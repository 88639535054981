import { useQuery } from "react-query";
import { Axios } from "../../axios/axios";

// const token = localStorage.getItem("nch_id");

function getSearchOption({ queryKey }) {
    let lang = queryKey[1];
    return Axios.get(`/property/all_values?lang=${lang}`);
}

export function useSearchOptions(lang = "en") {
    return useQuery(["search_options", lang], getSearchOption);
}

export function useCategories(lang = "en") {
    return useQuery(["search_options", lang], getSearchOption, {
        select: (data) => {
            return data?.data?.data[0].categories;
        },
    });
}

export function useAmenities(lang = "en") {
    return useQuery(["search_options", lang], getSearchOption, {
        select: (data) => {
            return data?.data?.data[0].aminities;
        },
    });
}

export function useAvailability(lang = "en") {
    return useQuery(["search_options", lang], getSearchOption, {
        select: (data) => {
            return data?.data?.data[0].aviability;
        },
    });
}
