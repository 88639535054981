import React, {forwardRef} from "react";

//* mui components imports
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import Slide from "@mui/material/Slide";

//* mui icons imports
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

//* get all amenities
import {getAttributeIcons} from "../../../utils/getAmenities";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const PropertyAmenitiesModal = ({open, handleClose, amenities}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const iconColor = theme.palette.secondary.main;

    let attributeArray = amenities?.split(",");

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <AppBar sx={{position: "relative", bgcolor: "#f5f5f5"}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="primary"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <ArrowBackIosIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogTitle id="responsive-dialog-title" variant="h5">
                {"What this place offers"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={2}>
                        {attributeArray?.map((attribute, index) => (
                            <React.Fragment key={attribute + "-" + index}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                    >
                                        {getAttributeIcons(
                                            attribute
                                                ?.replace(/\s/g, "")
                                                ?.toLowerCase(),
                                            iconColor
                                        )}
                                        <Typography
                                            component={"p"}
                                            sx={{color: "#717171"}}
                                        >
                                            {attribute}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Divider
                                    variant="middle"
                                    sx={{width: "80%", mt: 2}}
                                />
                            </React.Fragment>
                        ))}
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};
