import React, { forwardRef, useEffect } from "react";

//*  Translation

//* libraries
import { useParams } from "react-router-dom";

//* api calls
import { useGetUserPropertyDetail } from "../../hooks/properties/useCreateProperty";

//* property images
import PropertyImages from "../../component/properties/property-details/property-media/PropertyImages";
// import PropertyDialogBoxImages from "../../component/images/PropertyDialogBoxImages";

//* component import
import AdvertiserBrief from "../../component/properties/property-details/property-advertiser/AdvertiserBrief";
import PropertyAmenities from "../../component/properties/property-details/property-detail-desktop/PropertyAmenities";
import PropertyDescription from "../../component/properties/property-details/property-detail-desktop/PropertyDescription";
import PropertyFeatures from "../../component/properties/property-details/property-detail-desktop/PropertyFeatures";
import PropertyHeading from "../../component/properties/property-details/property-detail-desktop/PropertyHeading";
import PropertyLocation from "../../component/properties/property-details/property-detail-desktop/PropertyLocation";
// import PropertyImages from "../../component/properties/property_details/property_images/PropertyImages";
// import PropertyVideo from "../../component/properties/property_details/property_video/PropertyVideo";

//* mui component import
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import { useTheme } from "@mui/material";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../store";
import PropertyDetailLoadingDesktopView from "../../component/skeleton/PropertyDetailLoadingDesktopView";
import PropertyVideo from "../../component/properties/property-details/property-media/PropertyVideo";
import AdministrationDetail from "../../component/properties/property-details/property-advertiser/AdministrationDetail";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// profileImageUrl and propertyImageUrl = dynamic image path
const profileImageUrl = process.env.REACT_APP_PROFILE_IMAGE_URL;
const propertyImageUrl = process.env.REACT_APP_PROPERTY_IMAGE_URL;

const PreviewPropertyDetails = () => {
    // const { t } = useTranslation();

    const { t } = useTranslation();
    const theme = useTheme();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const params = useParams();
    const [{ user_id }] = useStateValue();
    const { propertyId } = useParams();
    const { isLoading, data } = useGetUserPropertyDetail(
        propertyId,
        currentLanguageCode
    );
    let propertyDetails = data?.data?.data[0];

    const video = propertyDetails?.youtube_link;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, [propertyId]);

    let AdministrationDetailObj = {
        admin_or_owner_name: propertyDetails?.admin_or_owner_name,
        contact_person_email: propertyDetails?.contact_person_email,
        contact_person_mobile: propertyDetails?.contact_person_mobile,
        contact_person_first_name: propertyDetails?.contact_person_first_name,
        contact_person_last_name: propertyDetails?.contact_person_last_name,
        contact_person_position: propertyDetails?.contact_person_position,
    };

    const isObjectAllValueEmpty = (obj) => {
        if (obj === null || obj === undefined) return true;
        if (Object.keys(obj).length === 0 && obj.constructor === Object)
            return true;
        if (
            Object.values(obj).every(
                (value) => value === "" || value === null || value === undefined
            )
        )
            return true;
        return false;
    };

    if (isLoading) {
        return <PropertyDetailLoadingDesktopView />;
    }

    //console.log(propertyDetails);
    return (
        <Container maxWidth="lg" sx={{ p: 5 }}>
            <Grid container rowSpacing={1}>
                {/* Property Heading */}
                <Grid item xs={12}>
                    <PropertyHeading
                        propertyId={propertyDetails?.id}
                        isFav={propertyDetails?.is_fav}
                        propertyOwnerId={propertyDetails?.user_id}
                        userId={user_id}
                    />
                </Grid>
                {/* Property Images */}
                <Grid item xs={12}>
                    <PropertyImages
                        propertyDetails={propertyDetails}
                        imageUrl={propertyImageUrl + propertyId + "/"}
                        height={"250px"}
                        sx={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: 12,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{
                            color: theme.palette.secondary.main,
                            p: 2,
                            pt: 3,
                            pb: 3,
                        }}
                    >
                        {propertyDetails?.name} ({propertyDetails?.id})
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            textDecoration: "none",
                            color: theme.palette.secondary.main,
                            cursor: "pointer",
                            mt: -2,
                            pb: 1,
                            pl: 2,
                        }}
                        // onClick={handleClickOpen}
                    >
                        {t("street")} : {propertyDetails?.street_no}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid item container>
                        <Grid item xs={12} md={8} p={2}>
                            {/* Features and Advertiser and contact Details */}
                            <Stack spacing={5}>
                                {/* Features details */}
                                {
                                    //console.log(propertyDetails)
                                }
                                <PropertyFeatures
                                    numberOfBedroom={
                                        propertyDetails?.number_bedroom
                                    }
                                    numberOfFloors={propertyDetails?.floor}
                                    price={propertyDetails?.price}
                                    livingSpace={propertyDetails?.living_space}
                                    categoryId={propertyDetails?.category_id}
                                    availabilityDate={
                                        propertyDetails?.availability_date
                                    }
                                    availability={propertyDetails?.availability}
                                />
                                {
                                    //console.log(propertyDetails)
                                }
                                <Divider variant="middle" />
                                <PropertyDescription
                                    propertyDescription={
                                        propertyDetails?.description
                                    }
                                    title={t("description")}
                                    propertyExternalLink={propertyDetails?.link}
                                />
                                <Divider
                                    sx={{ backgroundColor: "#f0bb9e61" }}
                                />
                                <PropertyAmenities
                                    attributes={propertyDetails?.attributes}
                                    title={t("property_detail_amenities")}
                                />
                                <Divider
                                    sx={{ backgroundColor: "#f0bb9e61" }}
                                />
                                {video && (
                                    <>
                                        <PropertyVideo video={video} />
                                        <Divider
                                            sx={{
                                                backgroundColor: "#f0bb9e61",
                                            }}
                                        />
                                    </>
                                )}
                                {/* Advertiser Details  */}
                                <AdvertiserBrief
                                    advertiserPhone={
                                        propertyDetails?.host_mobile
                                    }
                                    advertiserName={propertyDetails?.host_name}
                                    advertiserEmail={
                                        propertyDetails?.host_email
                                    }
                                    advertiserProfile={
                                        propertyDetails?.profile_pic
                                    }
                                    imageUrl={profileImageUrl}
                                    title={t(
                                        "property_detail_contact_provider"
                                    )}
                                />
                                {/* Advertiser Administration Details  */}

                                {!isObjectAllValueEmpty(
                                    AdministrationDetailObj
                                ) && (
                                    <>
                                        <Divider
                                            sx={{
                                                backgroundColor: "#f0bb9e61",
                                            }}
                                        />
                                        <AdministrationDetail
                                            adminOrOwnerName={
                                                propertyDetails?.admin_or_owner_name
                                            }
                                            contactPersonEmail={
                                                propertyDetails?.contact_person_email
                                            }
                                            contactPersonMobile={
                                                propertyDetails?.contact_person_mobile
                                            }
                                            contactPersonFirstName={
                                                propertyDetails?.contact_person_first_name
                                            }
                                            contactPersonLastName={
                                                propertyDetails?.contact_person_last_name
                                            }
                                            contactPersonPosition={
                                                propertyDetails?.contact_person_position
                                            }
                                            title={t(
                                                "property_detail_administration"
                                            )}
                                        />
                                    </>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <PropertyLocation
                propertyType={propertyDetails?.type_id}
                propertyPrice={propertyDetails?.price}
                period={propertyDetails?.period}
                name={propertyDetails?.name}
                images={propertyDetails?.images}
                latitude={propertyDetails?.latitude}
                longitude={propertyDetails?.longitude}
                streetNo={propertyDetails?.street_no}
                cityName={propertyDetails?.city_name}
                countryName={propertyDetails?.country_name}
                numberOfBathroom={propertyDetails?.number_bathroom}
                numberOfBedroom={propertyDetails?.number_bedroom}
                numberOfFloor={propertyDetails?.floor}
                livingSpace={propertyDetails?.living_space}
                title={t("company_location")}
            />
        </Container>
    );
};

export default PreviewPropertyDetails;
