import React, { Fragment, useCallback, useRef } from "react";

//* translation
import { useTranslation } from "react-i18next";

//* libraries
import ReactPlayer from "react-player";

//* react-hook-form
import { useFormContext } from "react-hook-form";

//* image imports
import { profilePlaceholder } from "../../../../assets/imgImport";

//* custom components
import { Img } from "../../../img/Img";

//* custom options
import {
    REASON_TO_MOVE_OPTIONS,
    // SmokeOptionArr,
    employmentRelationshipArr,
    permitOptionsArr,
} from "../../../../options/dossierOptions";

//* mui components import
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* custom functions
import {
    checkForDossierViewEmpty,
    checkForDossierViewEmptyDate,
    checkForDossierViewEmptyFind,
    // checkForDossierViewEmptyHometown,
    checkForDossierViewEmptyPhone,
    checkForDossierViewNull,
} from "../../../../utils/checkDossierViewFunction";

import DossierFormSection from "../dossier_components/DossierFormSection";
import { useTheme } from "@mui/material";
import { videoPlaceholder } from "../../../../utils/imgImports";
import { getSelectedOption } from "../../../../utils/checkDataFunction";

//* imageUrl and videoUrl  = Dynamic path
let imageUrl = process.env.REACT_APP_PROFILE_IMAGE_URL;
let videoUrl = process.env.REACT_APP_PROFILE_VIDEO_URL;

const Profile = ({ title, image }) => {
    return (
        <Fragment>
            <Typography variant={"h5"} textAlign={"center"}>
                {title}
            </Typography>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Img
                    src={image ? imageUrl + image : profilePlaceholder}
                    style={{
                        height: "160px",
                        width: "160px",
                        borderRadius: "8px",
                        objectFit: "cover",
                    }}
                    alt={"profile"}
                />
            </Box>
        </Fragment>
    );
};

const SmartMainStack = ({ mainHeading, children }) => {
    return (
        <Paper
            elevation={0}
            sx={{
                bgcolor: "#f5f5f5",
                padding: 2.5,
                borderRadius: 3.75,
            }}
        >
            <Stack spacing={1}>
                <Typography variant="h4">{mainHeading}</Typography>
                <Box width={"100%"}>
                    <Grid container spacing={2}>
                        {children}
                    </Grid>
                </Box>
            </Stack>
        </Paper>
    );
};

const SmartItem = ({ label, value, typographyStyle }) => {
    return (
        <Grid item xs={12} md={6}>
            <Grid
                item
                container
                bgcolor={"#ffffff"}
                p={2}
                // py={2.5}
                rowGap={0.625}
                borderRadius={2.5}
            >
                <Grid item xs={12}>
                    <Typography
                        variant={"subtitle1"}
                        textTransform={""}
                        color={"secondary"}
                    >
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={typographyStyle}>{value}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

function SmartDossierSummaryView({ stepLabels }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const { getValues } = useFormContext();
    const allValues = getValues();
    const videoPreviewRef = useRef();

    const tmpTenantOneFirstName =
        stepLabels[0] && stepLabels[0] !== "tenant_one"
            ? stepLabels[0]
            : t("tenant_one");
    const tmpTenantTwoFirstName =
        stepLabels[1] && stepLabels[1] !== "tenant_two"
            ? stepLabels[1]
            : t("tenant_two");

    const {
        //* Tenant1
        //first-section
        salutation,
        title,
        otherTitle,
        firstName,
        lastName,
        dateOfBirth,
        civilStatus,
        nationality,
        //second-section
        country,
        residencyPermit,
        dateOfEntry,
        // hometown,
        city,
        zip,
        streetAddress,
        streetNo,
        residingThereSince,
        //section-third
        phoneNo,
        privatePhoneNo,
        email,
        //section-fourth
        employedOrNot,
        //if-no
        unemploymentStatus,
        otherEmploymentStatus,
        selfEmployedCompanyName,
        selfEmployedCompanyAddress,
        selfEmployedThereSince,
        selfEmployedIncome,
        howYouPayRent,
        coveredRentAmount,
        liablePerson,

        referenceName,
        referenceEmail,
        referencePhone,
        //if-yes
        employedThereSince,
        employmentRelationship,
        workloadPercent,
        profession,
        monthlyIncome,
        annualSalaryRange,
        companyNameAndAddress,
        companyName,
        companyAddress,
        employeeName,
        employeeEmail,
        employeePhoneNo,
        //section-fifth
        hasNoCurrentApartment,
        currentAdministrationName,
        currentRent,
        currentAdministrationEmail,
        currentAdministrationPhoneNo,
        nameTags,
        residencyChangeReason,
        residencyChangeReasonComment,
        isImmediateAndAfterAgreement,
        dateOfMoving,
        movingOutOf,
        otherMovingOut,
        parentName,
        parentEmail,
        parentPhoneNo,
        //section-sixth
        noOfAdults,
        hasChildren,
        //if yes
        noOfChildren,
        childrensAverageAge,
        noOfChildrenMovingIn,
        isSmoker,
        // if yes
        smokePreference,
        hasMusicalInstrument,
        // if yes
        isPlayingAtHome,
        hasVehicle,
        // if yes
        typeOfVehicle,
        isParkingNeeded,
        numberOfParkingSpace,
        hasPet,
        //if yes
        pet,
        // petMaxHeight,
        isAnotherTenantMovingIn,
        anotherTenantRelation,
        //if yes
        //   anotherTenantRelation,
        //* Tenant 2
        //1st-section
        //   tenantTwoId,
        tenantTwoSalutation,
        tenantTwoTitle,
        tenantTwoOtherTitle,
        tenantTwoFirstName,
        tenantTwoLastName,
        tenantTwoDateOfBirth,
        tenantTwoCivilStatus,
        tenantTwoNationality,
        //2nd-section
        //   sameAddressAsFirstTenant,
        // if No
        tenantTwoCountry,
        tenantTwoResidencyPermit,
        tenantTwoDateOfEntry,
        tenantTwoCity,
        tenantTwoZip,
        tenantTwoStreetAddress,
        tenantTwoStreetNo,
        tenantTwoResidingThereSince,
        //3rd section
        //   sameContactAsFirstTenant,
        // if no
        tenantTwoPhoneNo,
        tenantTwoPrivatePhoneNo,
        tenantTwoEmail,
        //4th section
        tenantTwoEmployedOrNot,
        // if no
        tenantTwoUnemploymentStatus,
        tenantTwoOtherEmploymentStatus,
        tenantTwoSelfEmployedCompanyName,
        tenantTwoSelfEmployedCompanyAddress,
        tenantTwoSelfEmployedThereSince,
        tenantTwoSelfEmployedIncome,
        tenantTwoLiablePerson,
        tenantTwoCoveredRentAmount,

        tenantTwoHowYouPay,
        tenantTwoReferenceName,
        tenantTwoReferenceEmail,
        tenantTwoReferencePhone,
        // if yes
        tenantTwoEmployedThereSince,
        tenantTwoEmploymentRelationship,
        tenantTwoWorkloadPercent,
        tenantTwoProfession,
        tenantTwoMonthlyIncome,
        tenantTwoCompanyNameAndAddress,
        tenantTwoEmployeeName,
        tenantTwoEmployeeEmail,
        tenantTwoEmployeePhoneNo,
        //5th section
        // if no
        sameAdministrationAsFirstTenant,
        tenantTwoCurrentAdministrationName,
        tenantTwoCurrentRent,
        tenantTwoCurrentAdministrationEmail,
        tenantTwoCurrentAdministrationPhoneNo,
        tenantTwoNameTags,
        tenantTwoResidencyChangeReason,
        tenantTwoResidencyChangeReasonComment,
        tenantTwoHasNoCurrentApartment,
        tenantTwoIsImmediateAndAfterAgreement,
        tenantTwoDateOfMoving,
        tenantTwoMovingOutOf,
        tenantTwoOtherMovingOut,
        tenantTwoParentName,
        tenantTwoParentEmail,
        tenantTwoParentPhoneNo,
        //6th section
        isTenantTwoSmoker,
        // if yes
        tenantTwoSmokePreference,
        tenantTwoHasMusicalInstrument,
        // if yes
        isTenantTwoPlayingAtHome,
        hasTenantTwoVehicle,
        tenantTwoTypeOfVehicle,
        isTenantTwoNeedParking,
        tenantTwoNumberOfParkingSpace,
        hasTenantTwoPet,
        tenantTwoPet,

        // propertyAddress,
        // propertyCityAndZip,
        // propertyRooms,
        // propertyFloor,
        // propertyRent,
        // wishedCity,
        // wishedRooms,
        // maxRent,
        video,
        description,
        profilePic,
        tenantTwoProfilePic,
        // bannerImage,
        // passport_id,
        // salary_statement,
        // debt_collect,
        // criminal_record,
    } = allValues;

    let residencyChangeReasonObj = getSelectedOption(
        residencyChangeReason,
        REASON_TO_MOVE_OPTIONS
    );

    let residencyChange =
        typeof residencyChangeReasonObj === "string"
            ? t(residencyChangeReasonObj)
            : `${t(residencyChangeReasonObj?.label)}`;

    let residencyPermitValue = useCallback(
        (permit) => {
            if (checkForDossierViewEmpty(permit) === "-") return "-";
            let permitObj = permitOptionsArr?.find(
                (permitOption) => permitOption.value === permit
            );
            return permitObj
                ? `${t(permitObj.label)} (${t(permitObj.shortDescription)})`
                : "-";
        },
        [t]
    );

    return (
        <Grid container rowSpacing={2} my={2}>
            <Grid item xs={12}>
                <Typography variant="h3" textAlign={"center"}>
                    {t("summary")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <DossierFormSection heading={t("profile")}>
                    <Grid item container>
                        <Grid item xs={12} md={6}>
                            <Grid item container>
                                <Grid item xs={12} md={6}>
                                    <Profile
                                        title={tmpTenantOneFirstName}
                                        image={profilePic}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {isAnotherTenantMovingIn === "yes" && (
                                        <Profile
                                            title={tmpTenantTwoFirstName}
                                            image={tenantTwoProfilePic}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                <Typography variant={"h5"} textAlign={"center"}>
                                    {t("personal_video_message")}
                                </Typography>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        borderLeft: "2px solid #f5f5f5",
                                    }}
                                >
                                    {video === null ? (
                                        <img
                                            alt="no-smart-video"
                                            src={videoPlaceholder}
                                            width="160"
                                            height="160"
                                        />
                                    ) : (
                                        <ReactPlayer
                                            ref={videoPreviewRef}
                                            className="summary-video-preview"
                                            url={videoUrl + video}
                                            light={imageUrl + profilePic}
                                            width="160"
                                            height="160"
                                            onEnded={() =>
                                                videoPreviewRef.current.showPreview()
                                            }
                                            controls
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </DossierFormSection>
            </Grid>
            <Grid item xs={12}>
                <DossierFormSection heading={t("motivation")}>
                    <div
                        style={{ color: theme.palette.primary.dark }}
                        dangerouslySetInnerHTML={{
                            __html: checkForDossierViewEmpty(description),
                        }}
                    />
                </DossierFormSection>
            </Grid>
            <Grid item xs={12}>
                <DossierFormSection heading={tmpTenantOneFirstName}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <SmartMainStack
                                mainHeading={t("who_are_you") + "?"}
                            >
                                <SmartItem
                                    label={t("salutation")}
                                    value={t(
                                        checkForDossierViewEmpty(salutation)
                                    )}
                                />
                                <SmartItem
                                    label={t("title")}
                                    value={
                                        title === "other"
                                            ? checkForDossierViewEmpty(
                                                  otherTitle
                                              )
                                            : t(checkForDossierViewEmpty(title))
                                    }
                                />
                                <SmartItem
                                    label={t("first_name")}
                                    value={checkForDossierViewEmpty(firstName)}
                                />
                                <SmartItem
                                    label={t("last_name")}
                                    value={checkForDossierViewEmpty(lastName)}
                                />
                                <SmartItem
                                    label={t("birthday")}
                                    value={checkForDossierViewEmptyDate(
                                        dateOfBirth
                                    )}
                                />
                                <SmartItem
                                    label={t("civil_status")}
                                    value={t(
                                        checkForDossierViewEmpty(civilStatus)
                                    )}
                                />

                                <SmartItem
                                    label={t("nationality")}
                                    value={checkForDossierViewEmpty(
                                        nationality
                                    )}
                                />

                                <SmartItem
                                    label={t("country")}
                                    value={checkForDossierViewEmpty(country)}
                                />
                                {nationality?.toLowerCase() !==
                                    "switzerland" && (
                                    <>
                                        <SmartItem
                                            label={t("residency_permit")}
                                            value={residencyPermitValue(
                                                residencyPermit
                                            )}
                                        />
                                        <SmartItem
                                            label={t("date_of_entry")}
                                            value={checkForDossierViewEmptyDate(
                                                dateOfEntry,
                                                "date"
                                            )}
                                        />
                                    </>
                                )}
                            </SmartMainStack>
                        </Grid>
                        <Grid item xs={12}>
                            <SmartMainStack mainHeading={t("address")}>
                                <SmartItem
                                    label={t("city")}
                                    value={checkForDossierViewEmpty(city)}
                                />
                                <SmartItem
                                    label={t("zip")}
                                    value={checkForDossierViewEmpty(zip)}
                                />

                                <SmartItem
                                    label={t("street_no")}
                                    value={checkForDossierViewEmpty(streetNo)}
                                />
                                <SmartItem
                                    label={t("street_address")}
                                    value={checkForDossierViewEmpty(
                                        streetAddress
                                    )}
                                />

                                <SmartItem
                                    label={t("residing_there_since")}
                                    value={checkForDossierViewEmptyDate(
                                        residingThereSince,
                                        "date"
                                    )}
                                />
                            </SmartMainStack>
                        </Grid>
                        <Grid item xs={12}>
                            <SmartMainStack mainHeading={t("contact")}>
                                <SmartItem
                                    label={t("phone_number")}
                                    value={checkForDossierViewEmptyPhone(
                                        phoneNo
                                    )}
                                />
                                <SmartItem
                                    label={t("phone_no_private")}
                                    value={checkForDossierViewEmptyPhone(
                                        privatePhoneNo
                                    )}
                                />
                                <SmartItem
                                    label={t("email")}
                                    value={checkForDossierViewEmpty(email)}
                                />
                            </SmartMainStack>
                        </Grid>
                        <Grid item xs={12}>
                            <SmartMainStack mainHeading={t("employment")}>
                                <SmartItem
                                    label={t("are_you_employed") + "?"}
                                    typographyStyle={{
                                        textTransform: "",
                                    }}
                                    value={t(
                                        checkForDossierViewEmpty(employedOrNot)
                                    )}
                                />
                                {employedOrNot === "no" && (
                                    <>
                                        <SmartItem
                                            label={t("what_is_your_status")}
                                            value={t(
                                                checkForDossierViewEmpty(
                                                    unemploymentStatus
                                                )
                                            )}
                                        />
                                        {unemploymentStatus ===
                                            "other_unemployed" && (
                                            <SmartItem
                                                label={t("other_unemployed")}
                                                value={t(
                                                    checkForDossierViewEmpty(
                                                        otherEmploymentStatus
                                                    )
                                                )}
                                            />
                                        )}
                                        {unemploymentStatus ===
                                        "self_employed" ? (
                                            <>
                                                <SmartItem
                                                    label={t("name_of_company")}
                                                    value={t(
                                                        checkForDossierViewEmpty(
                                                            selfEmployedCompanyName
                                                        )
                                                    )}
                                                />

                                                <SmartItem
                                                    label={t(
                                                        "address_of_company"
                                                    )}
                                                    value={t(
                                                        checkForDossierViewEmpty(
                                                            selfEmployedCompanyAddress
                                                        )
                                                    )}
                                                />
                                                <SmartItem
                                                    label={t(
                                                        "employed_there_since"
                                                    )}
                                                    value={t(
                                                        checkForDossierViewEmptyDate(
                                                            selfEmployedThereSince,
                                                            "date"
                                                        )
                                                    )}
                                                />
                                                <SmartItem
                                                    label={t(
                                                        "monthly_mid_income"
                                                    )}
                                                    value={t(
                                                        checkForDossierViewEmpty(
                                                            selfEmployedIncome
                                                        )
                                                    )}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <SmartItem
                                                    label={t(
                                                        "how_you_pay_your_rent"
                                                    )}
                                                    value={t(
                                                        checkForDossierViewEmpty(
                                                            howYouPayRent
                                                        )
                                                    )}
                                                />
                                                {howYouPayRent ===
                                                    "additional_solidarity_liability" && (
                                                    <SmartItem
                                                        label={t(
                                                            "who_is_this_person"
                                                        )}
                                                        value={t(
                                                            checkForDossierViewEmpty(
                                                                liablePerson
                                                            )
                                                        )}
                                                    />
                                                )}
                                                <SmartItem
                                                    label={t(
                                                        "covered_rent_amount"
                                                    )}
                                                    value={t(
                                                        checkForDossierViewEmpty(
                                                            coveredRentAmount
                                                        )
                                                    )}
                                                />
                                            </>
                                        )}

                                        <SmartItem
                                            label={t("reference_name")}
                                            value={checkForDossierViewEmpty(
                                                referenceName
                                            )}
                                        />
                                        <SmartItem
                                            label={t("reference_email")}
                                            value={checkForDossierViewEmpty(
                                                referenceEmail
                                            )}
                                        />
                                        <SmartItem
                                            label={t("reference_phone")}
                                            value={checkForDossierViewEmptyPhone(
                                                referencePhone
                                            )}
                                        />
                                    </>
                                )}
                                {employedOrNot === "yes" && (
                                    <>
                                        <SmartItem
                                            label={t("employed_there_since")}
                                            value={checkForDossierViewEmptyDate(
                                                employedThereSince,
                                                "date"
                                            )}
                                        />
                                        <SmartItem
                                            label={t("contract_type")}
                                            value={t(
                                                checkForDossierViewEmptyFind(
                                                    employmentRelationshipArr,
                                                    employmentRelationship
                                                )
                                            )}
                                        />

                                        <SmartItem
                                            label={t("workload")}
                                            value={checkForDossierViewEmpty(
                                                workloadPercent
                                            )}
                                        />

                                        <SmartItem
                                            label={t("profession")}
                                            value={checkForDossierViewEmpty(
                                                profession
                                            )}
                                        />
                                        <SmartItem
                                            label={t(
                                                "gross_annual_salary_incl_13"
                                            )}
                                            value={t(
                                                checkForDossierViewEmpty(
                                                    annualSalaryRange
                                                )
                                            )}
                                        />
                                        {/*
                                        <SmartItem
                                            label={t(
                                                "name_and_address_of_company"
                                            )}
                                            value={checkForDossierViewEmpty(
                                                companyNameAndAddress
                                            )}
                                        />
                                        */}
                                        <SmartItem
                                            label={t(
                                                "name_and_address_of_company"
                                            )}
                                            value={checkForDossierViewEmpty(
                                                `${companyName}, ${companyAddress}`
                                            )}
                                        />
                                        <SmartItem
                                            label={t("name_of_employee")}
                                            value={checkForDossierViewEmpty(
                                                employeeName
                                            )}
                                        />
                                        <SmartItem
                                            label={t("email_of_employee")}
                                            value={checkForDossierViewEmpty(
                                                employeeEmail
                                            )}
                                        />

                                        <SmartItem
                                            label={t("phone_no._of_employer")}
                                            value={checkForDossierViewEmptyPhone(
                                                employeePhoneNo
                                            )}
                                        />
                                    </>
                                )}
                            </SmartMainStack>
                        </Grid>
                        <Grid item xs={12}>
                            <SmartMainStack
                                mainHeading={t("current_administration")}
                            >
                                {hasNoCurrentApartment ? (
                                    <>
                                        <SmartItem
                                            label={t("i_am_moving_out_of")}
                                            value={t(
                                                checkForDossierViewEmpty(
                                                    movingOutOf
                                                )
                                            )}
                                        />
                                        {movingOutOf === "other" && (
                                            <SmartItem
                                                label={t("please_describe")}
                                                value={checkForDossierViewEmpty(
                                                    otherMovingOut
                                                )}
                                            />
                                        )}
                                        {movingOutOf === "parental_home" && (
                                            <>
                                                <SmartItem
                                                    label={t("name_of_parent")}
                                                    value={checkForDossierViewEmpty(
                                                        parentName
                                                    )}
                                                />
                                                <SmartItem
                                                    label={t("email_of_parent")}
                                                    value={checkForDossierViewEmpty(
                                                        parentEmail
                                                    )}
                                                />
                                                <SmartItem
                                                    label={t("phone_of_parent")}
                                                    value={checkForDossierViewEmpty(
                                                        parentPhoneNo
                                                    )}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <SmartItem
                                            label={t(
                                                "name_of_current_administration"
                                            )}
                                            value={checkForDossierViewEmpty(
                                                currentAdministrationName
                                            )}
                                        />
                                        <SmartItem
                                            label={t("current_rent")}
                                            value={
                                                checkForDossierViewEmpty(
                                                    currentRent
                                                ) === "-"
                                                    ? "-"
                                                    : `CHF ${currentRent}.-`
                                            }
                                        />
                                        <SmartItem
                                            label={t(
                                                "email_of_current_administration"
                                            )}
                                            value={checkForDossierViewEmpty(
                                                currentAdministrationEmail
                                            )}
                                        />

                                        <SmartItem
                                            label={t(
                                                "phone_of_current_administration"
                                            )}
                                            value={checkForDossierViewEmptyPhone(
                                                currentAdministrationPhoneNo
                                            )}
                                        />
                                    </>
                                )}

                                {/* <SmartItem
                           label={t("preferred_text_on_your_name_tags")}
                           value={checkForDossierViewEmpty(nameTags)}
                        /> */}

                                <SmartItem
                                    label={t("reason_of_change_of_residence")}
                                    value={residencyChange}
                                />
                                <SmartItem
                                    label={t(
                                        "reason_of_change_of_residence_comment"
                                    )}
                                    value={checkForDossierViewEmpty(
                                        residencyChangeReasonComment
                                    )}
                                />

                                <SmartItem
                                    label={
                                        t("earliest_possible_movin_date") + "?"
                                    }
                                    value={
                                        isImmediateAndAfterAgreement
                                            ? t("immediately_&_after_agreement")
                                            : checkForDossierViewEmptyDate(
                                                  dateOfMoving
                                              )
                                    }
                                />
                            </SmartMainStack>
                        </Grid>
                        <Grid item xs={12}>
                            <SmartMainStack mainHeading={t("additional")}>
                                <SmartItem
                                    label={t("no_of_adults")}
                                    value={checkForDossierViewEmpty(noOfAdults)}
                                />

                                <SmartItem
                                    label={t("do_you_have_children") + "?"}
                                    value={t(
                                        checkForDossierViewEmpty(hasChildren)
                                    )}
                                />

                                {hasChildren === "yes" && (
                                    <>
                                        <SmartItem
                                            label={t("no_of_children")}
                                            value={`${
                                                childrensAverageAge &&
                                                childrensAverageAge.length > 0
                                                    ? "" +
                                                      childrensAverageAge
                                                          .map(
                                                              (item) =>
                                                                  `${
                                                                      item.age
                                                                  } ${t("yrs")}`
                                                          )
                                                          .join(", ") +
                                                      ""
                                                    : ""
                                            }`}
                                        />
                                        <SmartItem
                                            label={t(
                                                "How_many_children_are_moving_in_with_you"
                                            )}
                                            value={checkForDossierViewEmpty(
                                                noOfChildrenMovingIn
                                            )}
                                        />
                                    </>
                                )}
                                {/*
                                <SmartItem
                                    label={t("do_you_have_children") + "?"}
                                    value={t(
                                        checkForDossierViewEmpty(hasChildren)
                                    )}
                                />

                                <SmartItem
                                    label={t("no_of_children")}
                                    value={checkForDossierViewEmpty(
                                        noOfChildren
                                    )}
                                />
                                {checkForDossierViewNull(childrensAverageAge) &&
                                    childrensAverageAge?.map((item, index) => (
                                        <SmartItem
                                            label={`${index + 1}. ${t(
                                                "child"
                                            )}`}
                                            value={`${item.age} ${t("yrs")}`}
                                        />
                                    ))}
                                */}
                                <SmartItem
                                    label={t("do_you_smoke") + "?"}
                                    value={t(
                                        checkForDossierViewEmpty(isSmoker)
                                    )}
                                />
                                {isSmoker === "yes" && (
                                    <SmartItem
                                        label={t("smoke_preference")}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                smokePreference
                                            )
                                        )}
                                    />
                                )}

                                <SmartItem
                                    // label={t("musical_instruments")}
                                    label={
                                        t("do_you_play_music_instruments") + "?"
                                    }
                                    value={t(
                                        checkForDossierViewEmpty(
                                            hasMusicalInstrument
                                        )
                                    )}
                                />
                                {hasMusicalInstrument === "yes" && (
                                    <SmartItem
                                        label={t("do_you_play_at_home") + "?"}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                isPlayingAtHome
                                            )
                                        )}
                                    />
                                )}

                                <SmartItem
                                    label={t("do_you_have_vehicles") + "?"}
                                    value={t(
                                        checkForDossierViewEmpty(hasVehicle)
                                    )}
                                />
                                {hasVehicle === "yes" && (
                                    <>
                                        <SmartItem
                                            label={t("type_of_vehicle")}
                                            value={t(
                                                checkForDossierViewEmpty(
                                                    typeOfVehicle
                                                )
                                            )}
                                        />
                                        <SmartItem
                                            label={
                                                t("need_parking_space") + "?"
                                            }
                                            value={`${t(
                                                checkForDossierViewEmpty(
                                                    isParkingNeeded
                                                )
                                            )}${
                                                isParkingNeeded &&
                                                parseInt(
                                                    numberOfParkingSpace,
                                                    10
                                                ) > 0
                                                    ? ", " +
                                                      t(
                                                          "need_parking_space_quantity"
                                                      ) +
                                                      ": " +
                                                      numberOfParkingSpace
                                                    : ""
                                            }`}
                                        />
                                    </>
                                )}

                                {/*
                                <SmartItem
                                    label={t("need_parking_space") + "?"}
                                    value={t(
                                        checkForDossierViewEmpty(
                                            isParkingNeeded
                                        )
                                    )}
                                />
                                */}

                                <SmartItem
                                    label={t("do_you_have_pets") + "?"}
                                    value={t(checkForDossierViewEmpty(hasPet))}
                                />

                                {hasPet === "yes" && (
                                    <SmartItem
                                        label={t("pets")}
                                        value={checkForDossierViewEmpty(pet)}
                                    />
                                )}
                                <SmartItem
                                    label={
                                        t("is_another_tenant_moving_in") + "?"
                                    }
                                    value={t(checkForDossierViewEmpty(
                                        isAnotherTenantMovingIn
                                    ))}
                                />
                                {isAnotherTenantMovingIn === "yes" && (
                                    <SmartItem
                                        label={
                                            t(
                                                "what_is_your_relationship_to_each_other"
                                            ) + "*"
                                        }
                                        value={t(
                                            checkForDossierViewEmpty(
                                                anotherTenantRelation
                                            )
                                        )}
                                    />
                                )}
                            </SmartMainStack>
                        </Grid>
                    </Grid>
                </DossierFormSection>
            </Grid>
            <Grid item xs={12}>
                {isAnotherTenantMovingIn === "yes" && (
                    <DossierFormSection heading={tmpTenantTwoFirstName}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <SmartMainStack
                                    mainHeading={t("who_are_you") + "?"}
                                >
                                    <SmartItem
                                        label={t("salutation")}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                tenantTwoSalutation
                                            )
                                        )}
                                    />
                                    <SmartItem
                                        label={t("title")}
                                        value={
                                            tenantTwoTitle === "other"
                                                ? checkForDossierViewEmpty(
                                                      tenantTwoOtherTitle
                                                  )
                                                : t(
                                                      checkForDossierViewEmpty(
                                                          tenantTwoTitle
                                                      )
                                                  )
                                        }
                                    />

                                    <SmartItem
                                        label={t("first_name")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoFirstName
                                        )}
                                    />
                                    <SmartItem
                                        label={t("last_name")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoLastName
                                        )}
                                    />
                                    <SmartItem
                                        label={t("birthday")}
                                        value={checkForDossierViewEmptyDate(
                                            tenantTwoDateOfBirth
                                        )}
                                    />
                                    <SmartItem
                                        label={t("civil_status")}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                tenantTwoCivilStatus
                                            )
                                        )}
                                    />

                                    <SmartItem
                                        label={t("nationality")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoNationality
                                        )}
                                    />
                                </SmartMainStack>
                            </Grid>
                            <Grid item xs={12}>
                                <SmartMainStack mainHeading={t("address")}>
                                    <SmartItem
                                        label={t("country")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoCountry
                                        )}
                                    />
                                    {tenantTwoNationality?.toLowerCase() !==
                                        "switzerland" && (
                                        <>
                                            <SmartItem
                                                label={t("residency_permit")}
                                                value={residencyPermitValue(
                                                    tenantTwoResidencyPermit
                                                )}
                                            />
                                            <SmartItem
                                                label={t("date_of_entry")}
                                                value={checkForDossierViewEmptyDate(
                                                    tenantTwoDateOfEntry,
                                                    "date"
                                                )}
                                            />
                                        </>
                                    )}
                                    <SmartItem
                                        label={t("city")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoCity
                                        )}
                                    />
                                    <SmartItem
                                        label={t("zip")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoZip
                                        )}
                                    />

                                    <SmartItem
                                        label={t("street_no")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoStreetNo
                                        )}
                                    />
                                    <SmartItem
                                        label={t("street_address")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoStreetAddress
                                        )}
                                    />

                                    <SmartItem
                                        label={t("residing_there_since")}
                                        value={checkForDossierViewEmptyDate(
                                            tenantTwoResidingThereSince,
                                            "date"
                                        )}
                                    />
                                </SmartMainStack>
                            </Grid>
                            <Grid item xs={12}>
                                <SmartMainStack mainHeading={t("contact")}>
                                    <SmartItem
                                        label={t("phone_number")}
                                        value={checkForDossierViewEmptyPhone(
                                            tenantTwoPhoneNo
                                        )}
                                    />
                                    <SmartItem
                                        label={t("phone_no_private")}
                                        value={checkForDossierViewEmptyPhone(
                                            tenantTwoPrivatePhoneNo
                                        )}
                                    />
                                    <SmartItem
                                        label={t("email")}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoEmail
                                        )}
                                    />
                                </SmartMainStack>
                            </Grid>
                            <Grid item xs={12}>
                                <SmartMainStack mainHeading={t("employment")}>
                                    <SmartItem
                                        label={t("are_you_employed") + "?"}
                                        typographyStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                tenantTwoEmployedOrNot
                                            )
                                        )}
                                    />

                                    {tenantTwoEmployedOrNot === "no" && (
                                        <>
                                            <SmartItem
                                                label={t("what_is_your_status")}
                                                value={t(
                                                    checkForDossierViewEmpty(
                                                        tenantTwoUnemploymentStatus
                                                    )
                                                )}
                                            />
                                            {tenantTwoUnemploymentStatus ===
                                                "other_unemployed" && (
                                                <SmartItem
                                                    label={t(
                                                        "other_unemployed"
                                                    )}
                                                    value={t(
                                                        checkForDossierViewEmpty(
                                                            tenantTwoOtherEmploymentStatus
                                                        )
                                                    )}
                                                />
                                            )}
                                            {tenantTwoUnemploymentStatus ===
                                            "self_employed" ? (
                                                <>
                                                    <SmartItem
                                                        label={t(
                                                            "name_of_company"
                                                        )}
                                                        value={t(
                                                            checkForDossierViewEmpty(
                                                                tenantTwoSelfEmployedCompanyName
                                                            )
                                                        )}
                                                    />

                                                    <SmartItem
                                                        label={t(
                                                            "address_of_company"
                                                        )}
                                                        value={t(
                                                            checkForDossierViewEmpty(
                                                                tenantTwoSelfEmployedCompanyAddress
                                                            )
                                                        )}
                                                    />
                                                    <SmartItem
                                                        label={t(
                                                            "employed_there_since"
                                                        )}
                                                        value={t(
                                                            checkForDossierViewEmptyDate(
                                                                tenantTwoSelfEmployedThereSince,
                                                                "date"
                                                            )
                                                        )}
                                                    />
                                                    <SmartItem
                                                        label={t(
                                                            "monthly_mid_income"
                                                        )}
                                                        value={t(
                                                            checkForDossierViewEmpty(
                                                                tenantTwoSelfEmployedIncome
                                                            )
                                                        )}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <SmartItem
                                                        label={
                                                            t(
                                                                "how_you_pay_your_rent"
                                                            ) + "?"
                                                        }
                                                        value={t(
                                                            checkForDossierViewEmpty(
                                                                tenantTwoHowYouPay
                                                            )
                                                        )}
                                                    />
                                                    {tenantTwoHowYouPay ===
                                                        "additional_solidarity_liability" && (
                                                        <SmartItem
                                                            label={t(
                                                                "who_is_this_person"
                                                            )}
                                                            value={t(
                                                                checkForDossierViewEmpty(
                                                                    tenantTwoLiablePerson
                                                                )
                                                            )}
                                                        />
                                                    )}
                                                    <SmartItem
                                                        label={t(
                                                            "covered_rent_amount"
                                                        )}
                                                        value={t(
                                                            checkForDossierViewEmpty(
                                                                tenantTwoCoveredRentAmount
                                                            )
                                                        )}
                                                    />
                                                </>
                                            )}

                                            <SmartItem
                                                label={t("reference_name")}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoReferenceName
                                                )}
                                            />
                                            <SmartItem
                                                label={t("reference_email")}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoReferenceEmail
                                                )}
                                            />
                                            <SmartItem
                                                label={t("reference_phone")}
                                                value={checkForDossierViewEmptyPhone(
                                                    tenantTwoReferencePhone
                                                )}
                                            />
                                        </>
                                    )}
                                    {tenantTwoEmployedOrNot === "yes" && (
                                        <>
                                            <SmartItem
                                                label={t(
                                                    "employed_there_since"
                                                )}
                                                value={checkForDossierViewEmptyDate(
                                                    tenantTwoEmployedThereSince,
                                                    "date"
                                                )}
                                            />
                                            <SmartItem
                                                label={t(
                                                    "employment_relationship"
                                                )}
                                                value={t(
                                                    checkForDossierViewEmptyFind(
                                                        employmentRelationshipArr,
                                                        tenantTwoEmploymentRelationship
                                                    )
                                                )}
                                            />

                                            <SmartItem
                                                label={t("workload")}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoWorkloadPercent
                                                )}
                                            />

                                            <SmartItem
                                                label={t("profession")}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoProfession
                                                )}
                                            />
                                            <SmartItem
                                                label={t(
                                                    "gross_annual_salary_incl_13"
                                                )}
                                                value={t(
                                                    checkForDossierViewEmpty(
                                                        tenantTwoMonthlyIncome
                                                    )
                                                )}
                                            />

                                            <SmartItem
                                                label={t(
                                                    "name_and_address_of_company"
                                                )}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoCompanyNameAndAddress
                                                )}
                                            />
                                            <SmartItem
                                                label={t(
                                                    "name_of_employer_contact_person"
                                                )}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoEmployeeName
                                                )}
                                            />
                                            <SmartItem
                                                label={t("employers_email")}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoEmployeeEmail
                                                )}
                                            />
                                            <SmartItem
                                                label={t(
                                                    "employers_phone_number"
                                                )}
                                                value={checkForDossierViewEmptyPhone(
                                                    tenantTwoEmployeePhoneNo
                                                )}
                                            />
                                        </>
                                    )}
                                </SmartMainStack>
                            </Grid>
                            <Grid item xs={12}>
                                <SmartMainStack
                                    mainHeading={t("current_administration")}
                                >
                                    {tenantTwoHasNoCurrentApartment ? (
                                        <>
                                            <SmartItem
                                                label={t("i_am_moving_out_of")}
                                                value={t(
                                                    checkForDossierViewEmpty(
                                                        tenantTwoMovingOutOf
                                                    )
                                                )}
                                            />
                                            {tenantTwoMovingOutOf ===
                                                "other" && (
                                                <SmartItem
                                                    label={t("please_describe")}
                                                    value={checkForDossierViewEmpty(
                                                        tenantTwoOtherMovingOut
                                                    )}
                                                />
                                            )}
                                            {tenantTwoMovingOutOf ===
                                                "parental_home" && (
                                                <>
                                                    <SmartItem
                                                        label={t(
                                                            "name_of_parent"
                                                        )}
                                                        value={checkForDossierViewEmpty(
                                                            tenantTwoParentName
                                                        )}
                                                    />
                                                    <SmartItem
                                                        label={t(
                                                            "email_of_parent"
                                                        )}
                                                        value={checkForDossierViewEmpty(
                                                            tenantTwoParentEmail
                                                        )}
                                                    />
                                                    <SmartItem
                                                        label={t(
                                                            "phone_of_parent"
                                                        )}
                                                        value={checkForDossierViewEmpty(
                                                            tenantTwoParentPhoneNo
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <SmartItem
                                                label={t(
                                                    "name_of_current_administration"
                                                )}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoCurrentAdministrationName
                                                )}
                                            />
                                            <SmartItem
                                                label={t("current_rent")}
                                                value={
                                                    checkForDossierViewEmpty(
                                                        tenantTwoCurrentRent
                                                    ) === "-"
                                                        ? "-"
                                                        : `CHF ${tenantTwoCurrentRent}.-`
                                                }
                                            />
                                            <SmartItem
                                                label={t(
                                                    "email_of_current_administration"
                                                )}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoCurrentAdministrationEmail
                                                )}
                                            />

                                            <SmartItem
                                                label={t(
                                                    "phone_of_current_administration"
                                                )}
                                                value={checkForDossierViewEmptyPhone(
                                                    tenantTwoCurrentAdministrationPhoneNo
                                                )}
                                            />
                                            {/* <SmartItem
                                                label={t("text_for_name_tags")}
                                                value={checkForDossierViewEmpty(
                                                    tenantTwoNameTags
                                                )}
                                                /> */}
                                        </>
                                    )}

                                    <SmartItem
                                        label={t(
                                            "reason_of_change_of_residence"
                                        )}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                tenantTwoResidencyChangeReason
                                            )
                                        )}
                                    />
                                    <SmartItem
                                        label={t(
                                            "reason_of_change_of_residence_comment"
                                        )}
                                        value={checkForDossierViewEmpty(
                                            tenantTwoResidencyChangeReasonComment
                                        )}
                                    />
                                    <SmartItem
                                        label={
                                            t("earliest_possible_movin_date") +
                                            "?"
                                        }
                                        value={
                                            tenantTwoIsImmediateAndAfterAgreement
                                                ? t(
                                                      "immediately_&_after_agreement"
                                                  )
                                                : checkForDossierViewEmptyDate(
                                                      tenantTwoDateOfMoving
                                                  )
                                        }
                                    />
                                </SmartMainStack>
                            </Grid>
                            <Grid item xs={12}>
                                <SmartMainStack mainHeading={t("additional")}>
                                    <SmartItem
                                        label={t("do_you_smoke") + "?"}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                isTenantTwoSmoker
                                            )
                                        )}
                                    />

                                    {isTenantTwoSmoker === "yes" && (
                                        <SmartItem
                                            label={t("smoke_preference")}
                                            value={t(
                                                checkForDossierViewEmpty(
                                                    tenantTwoSmokePreference
                                                )
                                            )}
                                        />
                                    )}
                                    <SmartItem
                                        // label={t("musical_instruments")}
                                        label={
                                            t(
                                                "do_you_have_musical_instrument"
                                            ) + "?"
                                        }
                                        value={t(
                                            checkForDossierViewEmpty(
                                                tenantTwoHasMusicalInstrument
                                            )
                                        )}
                                    />
                                    {tenantTwoHasMusicalInstrument === "yes" && (
                                        <SmartItem
                                            label={
                                                t("do_you_play_at_home") + "?"
                                            }
                                            value={t(
                                                checkForDossierViewEmpty(
                                                    isTenantTwoPlayingAtHome
                                                )
                                            )}
                                        />
                                    )}

                                    <SmartItem
                                        label={t("do_you_have_vehicles") + "?"}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                hasTenantTwoVehicle
                                            )
                                        )}
                                    />
                                    {hasTenantTwoVehicle === "yes" && (
                                        <>
                                            <SmartItem
                                                label={t("type_of_vehicle")}
                                                value={t(
                                                    checkForDossierViewEmpty(
                                                        tenantTwoTypeOfVehicle
                                                    )
                                                )}
                                            />
                                            <SmartItem
                                                label={
                                                    t("need_parking_space") +
                                                    "?"
                                                }
                                                value={`${t(
                                                    checkForDossierViewEmpty(
                                                        isTenantTwoNeedParking
                                                    )
                                                )}${
                                                    isTenantTwoNeedParking &&
                                                    parseInt(
                                                        tenantTwoNumberOfParkingSpace,
                                                        10
                                                    ) > 0
                                                        ? ", " +
                                                          t(
                                                              "need_parking_space_quantity"
                                                          ) +
                                                          ": " +
                                                          tenantTwoNumberOfParkingSpace
                                                        : ""
                                                }`}
                                            />
                                        </>
                                    )}

                                    <SmartItem
                                        label={t("do_you_have_pets") + "?"}
                                        value={t(
                                            checkForDossierViewEmpty(
                                                hasTenantTwoPet
                                            )
                                        )}
                                    />
                                    {hasTenantTwoPet === "yes" && (
                                        <SmartItem
                                            label={t("pets")}
                                            value={checkForDossierViewEmpty(
                                                tenantTwoPet
                                            )}
                                        />
                                    )}
                                </SmartMainStack>
                            </Grid>
                        </Grid>
                    </DossierFormSection>
                )}
            </Grid>
        </Grid>
    );
}

export default SmartDossierSummaryView;
