import * as yup from 'yup';

const twelveYearInMilliSeconds = 16 * 365 * 24 * 60 * 60 * 1000;

const step0Section2Schema = yup.object().shape({
    /*
    phoneNo: yup
        .string()
        .max(19, "please_enter_a_valid_phone_number"),

    privatePhoneNo: yup
        .string()
        .max(19, "please_enter_a_valid_phone_number"),

    email: yup
        .string()
        .email("email_is_invalid")
        .required("please_enter_your_email"),
     */
});

export default step0Section2Schema;
