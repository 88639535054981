import { useState, useCallback } from 'react';

const useUserContext = () => {
    const readUserContext = () => {
        try {
            const storedUserContextString = localStorage.getItem('userContext');
            if (storedUserContextString) {
                return JSON.parse(storedUserContextString);
            }
        } catch (error) {
            console.error('Error parsing the user context from the LocalStorage:', error);
        }
        return {
            origin: 'createSmartDossier',
            action: 'createOrLogin',
            actionValue: '',
            actionOpenValue: '',
            actionCloseValue: '',
        };
    };

    const [userContext, setUserContext] = useState(readUserContext);

    const updateUserContext = useCallback((newUserContext) => {
        try {
            localStorage.setItem('userContext', JSON.stringify(newUserContext));
            setUserContext(newUserContext);
        } catch (error) {
            console.error('Error updating the user context in the LocalStorage:', error);
        }
    }, []);

    return { userContext, updateUserContext };
};

export default useUserContext;