export const isObjectValuesEmpty = (obj) => {
    if (obj === null || obj === undefined) return true;
    if (Object.keys(obj).length === 0 && obj.constructor === Object)
        return true;
    if (
        Object.values(obj).every(
            (value) => value === "" || value === null || value === undefined
        )
    )
        return true;
    return false;
};
