import React from "react";

//* translation
import { useTranslation } from "react-i18next";
//* cookie
import cookies from "js-cookie";
//* react-hook-form
import { useFormContext } from "react-hook-form";

//* custom components
import {
    ControllerDateField, ControllerPhoneTextField,
    ControllerRadioField,
    ControllerTextField,
} from "../../../mui-components/MuiDossierFormComponents";

// Custom options
import { tenantGender, tenantTitle } from "../../../../options/dossierOptions";

//* mui components import

// import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

// mui icons imports
import { isGreaterThanYear } from "../../../../utils/isGreaterThanYear";

function BriefContactDetail() {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    const title = watch("title");

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6" textAlign={"center"}>
                    {t("contact_data")}
                </Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} md={6}>
                    <ControllerPhoneTextField
                        control={control}
                        name="phoneNo"
                        label={t("phone_number")}
                        errorFlag={!!errors?.phoneNo}
                        errorText={t(errors?.phoneNo?.message)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControllerPhoneTextField
                        control={control}
                        name="privatePhoneNo"
                        label={t("phone_no_private")}
                        errorFlag={!!errors?.privatePhoneNo}
                        errorText={t(
                            errors?.privatePhoneNo?.message
                        )}
                    />

                </Grid>
            </Grid>

        </Grid>
    );
}

export default BriefContactDetail;

// minDate={moment().subtract(500, "years")}
