import React, { Fragment, useState } from "react";

//* translation
import { useTranslation } from "react-i18next";

//* mui components import
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

//* mui icons imports
import { toast } from "react-toastify";
import moment from "moment";
import { DeleteOutline } from "@mui/icons-material";
import { useDeleteDossierDocument } from "../../../hooks/dossier/useDossierDocument";
import { getDocumentTypeLabel } from "../../../utils/getDocumentTypeLabel";

const tableHeading = [
    { id: 1, label: "Sr no." },
    { id: 2, label: "type" },
    { id: 3, label: "action" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "& > *": { borderBottom: "unset" },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledDocumentTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 14,
    },
}));

function Row({ documentType, documentFiles, srNo, handleDelete }) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    let documents = documentFiles[documentType] || [];
    let documentLabel = getDocumentTypeLabel(documentType);

    return (
        <React.Fragment>
            <StyledTableRow>
                <StyledTableCell>{srNo}</StyledTableCell>
                <StyledTableCell>{t(documentLabel)}</StyledTableCell>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box m={2}>
                            <Table size="small" aria-label="document list">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledDocumentTableCell>
                                            {t("date_of_upload")}
                                        </StyledDocumentTableCell>
                                        <StyledDocumentTableCell>
                                            {t("file_name")}
                                        </StyledDocumentTableCell>
                                        <StyledDocumentTableCell>
                                            {t("comment")}
                                        </StyledDocumentTableCell>
                                        <StyledDocumentTableCell>
                                            {t("action")}
                                        </StyledDocumentTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {documents?.map((document) => {
                                        const {
                                            updated_at,
                                            originalName,
                                            comment,
                                            user_id,
                                            id,
                                        } = document;
                                        return (
                                            <StyledTableRow
                                                key={`${user_id}-${originalName}`}
                                            >
                                                <StyledDocumentTableCell>
                                                    {moment(updated_at).format(
                                                        "DD.MM.YY"
                                                    )}
                                                </StyledDocumentTableCell>
                                                <StyledDocumentTableCell>
                                                    {originalName}
                                                </StyledDocumentTableCell>
                                                <StyledDocumentTableCell>
                                                    {comment}
                                                </StyledDocumentTableCell>
                                                <StyledDocumentTableCell>
                                                    <Box>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleDelete(id)
                                                            }
                                                        >
                                                            <DeleteOutline color="error" />
                                                        </IconButton>
                                                    </Box>
                                                </StyledDocumentTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

export default function DossierDocumentTable({
    documentTypes,
    documents,
    refetchDocument,
}) {
    const { t } = useTranslation();
    const [deleteConfirmation, setDeleteConfirmation] = useState({
        show: false,
        data: null,
    });
    const { isLoading, mutate } = useDeleteDossierDocument();

    const handleDeleteConfirmationOpen = (data) => {
        setDeleteConfirmation({
            show: true,
            data: data,
        });
    };
    const handleDeleteConfirmationClose = () => {
        setDeleteConfirmation({
            show: false,
            data: null,
        });
    };

    const handleDeleteDocument = (documentData) => {
        // single id delete for now
        let ids = {
            id: [documentData],
        };
        mutate(ids, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    return;
                }

                toast.success("Document deleted successfully");
                refetchDocument();
                setTimeout(handleDeleteConfirmationClose, 800);
            },
            onError: (errors) => {
                toast.error(`${t("we_are_facing_some_technical_issue")}`);
                console.error(errors);
            },
        });
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="document type list" size="small">
                    <TableHead>
                        <StyledTableRow>
                            {tableHeading?.map((th) => (
                                <StyledTableCell key={th.id}>
                                    {t(th.label)}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(documentTypes) &&
                        documentTypes.length === 0 ? (
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} align="center">
                                    {t("no_data_found")}
                                </StyledTableCell>
                            </StyledTableRow>
                        ) : (
                            documentTypes?.map((documentType, index) => {
                                return (
                                    <Row
                                        key={documentType}
                                        srNo={index + 1}
                                        documentType={documentType}
                                        documentFiles={documents}
                                        handleDelete={
                                            handleDeleteConfirmationOpen
                                        }
                                    />
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={deleteConfirmation.show}
                onClose={handleDeleteConfirmationClose}
            >
                <DialogTitle>Delete Document</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`${t(
                        "are_you_sure_you_want_to_delete_document"
                    )}?`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteConfirmationClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() =>
                            handleDeleteDocument(deleteConfirmation?.data)
                        }
                        autoFocus
                        disabled={isLoading}
                        variant="contained"
                        color="error"
                    >
                        {t("delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
