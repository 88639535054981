import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material";

const Button = styled(MuiButton)(() => {
   return {
      "& .MuiButton-endIcon": {
         transition: "transform 300ms ease-in",
      },
      "& .MuiButton-startIcon": {
         transition: "transform 300ms ease-in",
      },
      "&:hover": {
         "& .MuiButton-startIcon": {
            transform: `translateX(-5px)`,
         },
         "& .MuiButton-endIcon": {
            transform: `translateX(5px)`,
         },
      },
   };
});

function ArrowButton({ children, arrowStart = false, ...props }) {
   return (
      <Button
         {...props}
         {...(arrowStart
            ? { startIcon: <Icon>arrow_back</Icon> }
            : { endIcon: <Icon>arrow_forward</Icon> })}
      >
         {children}
      </Button>
   );
}

ArrowButton.propTypes = {
   children: PropTypes.node,
   arrowStart: PropTypes.bool,
};

export default ArrowButton;
