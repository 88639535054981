// import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Container() {
   return {
      MuiContainer: {
         styleOverrides: {
            root: {
               // "&.MuiContainer-root": {
               //    padding: 0,
               // },
            },
         },
      },
   };
}
