import React, {useEffect} from "react";

//* mui components imports
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import { PropertyDetailsDesktopView } from "./PropertyDetailsDesktopView";
import { PropertyDetailsMobileView } from "./PropertyDetailsMobileView";
import useResponsive from "../../hooks/responsive/useResponsive";

const PropertyDetails = () => {
    const { isBelowSm } = useResponsive();

    return (
        <>
            {!isBelowSm ? (
                <PropertyDetailsDesktopView />
            ) : (
                <PropertyDetailsMobileView />
            )}
        </>
    );
};

export default PropertyDetails;
