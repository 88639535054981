import React, {Fragment} from 'react';
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    ControllerPhoneTextField, TitleSpacer, FieldHintSpacer, ControllerRadioField
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import { useContext } from 'react';
import FormSectionContextSegmentN, {
    useCommonEditFormContext
} from '../../tenant_common_context/EditFormSectionContextSegment';
import FormControl from "@mui/material/FormControl";
import {isEmployedOption, isYesNoOption} from "../../../../../../options/dossierOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";

const TenantTwoSegmentThree = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const {
        t
    } = commonProps;

    const {
        heading,
        Icon,
        index,
        currentLanguageCode,
        watchSameContactAsFirstTenant,
        control,
        errors,
        sectionVariants,
        direction
    } = additionalProps;

    return (
        <DossierFormSection
            {...commonProps}
            {...additionalProps}
        >
            <Grid item container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="sameContactAsFirstTenant"
                        label={t("same_contact_detail_as_first_tenant") + "*"}
                        data={isYesNoOption}
                        errorFlag={!!errors.sameContactAsFirstTenant}
                        errorText={errors.sameContactAsFirstTenant ? errors.sameContactAsFirstTenant.message : ''}
                    />
                    {/*
                    <ControllerRadioField
                        name="sameContactAsFirstTenant"
                        label={
                            t(
                                "same_contact_detail_as_first_tenant"
                            ) + "?"
                        }
                        control={control}
                        errorFlag={
                            !!errors?.sameContactAsFirstTenant
                        }
                        errorText={t(
                            errors?.sameContactAsFirstTenant
                                ?.message
                        )}
                    >
                        {isYesNoOption.map((option) => (
                            <FormControlLabel
                                key={option.id}
                                label={t(option.label)}
                                value={option.value}
                                control={<Radio/>}
                            />
                        ))}
                    </ControllerRadioField>
                    */}
                </Grid>
                {watchSameContactAsFirstTenant === "no" && (
                    <Fragment>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <ControllerPhoneTextField
                                control={control}
                                name="tenantTwoPhoneNo"
                                label={t("phone_number")}
                                //   errorFlag={!!errors?.tenantTwoPhoneNo}
                                //   errorText={t(errors?.tenantTwoPhoneNo?.message)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <ControllerPhoneTextField
                                control={control}
                                name="tenantTwoPrivatePhoneNo"
                                label={t("phone_no_private")}
                                //   errorFlag={!!errors?.tenantTwoPrivatePhoneNo}
                                //   errorText={t(errors?.tenantTwoPrivatePhoneNo?.message)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <ControllerTextField
                                control={control}
                                name="tenantTwoEmail"
                                label={t("email")}
                                style={{ display: 'none' }}
                                //   errorFlag={!!errors?.tenantTwoEmail}
                                //   errorText={t(errors?.tenantTwoEmail?.message)}
                            />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </DossierFormSection>
    );
}

export default TenantTwoSegmentThree