import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

function handleResendVerification(userData) {
    return Axios.post("/users/resendVerification", userData);
}

export default function useResendVerification() {
    return useMutation(handleResendVerification);
}
