import * as yup from 'yup';

const twelveYearInMilliSeconds = 16 * 365 * 24 * 60 * 60 * 1000;

const step0Section4Schema = yup.object().shape({
    // tenantTwoCurrentAdministrationName: yup
    //   .string()
    //   .when("sameAdministrationAsFirstTenant", {
    //     is: "no",
    //     then: yup.string().required("please_enter_current_administration_name"),
    //   }),
    // tenantTwoCurrentRent: yup.string().when("sameAdministrationAsFirstTenant", {
    //   is: "no",
    //   then: yup.string().required("please_enter_current_rent"),
    // }),
    // tenantTwoCurrentAdministrationEmail: yup
    //   .string()
    //   .when("sameAdministrationAsFirstTenant", {
    //     is: "no",
    //     then: yup
    //       .string()
    //       .required("please_enter_current_administration_email"),
    //   }),
    // tenantTwoCurrentAdministrationPhoneNo: yup
    //   .string()
    //   .when("sameAdministrationAsFirstTenant", {
    //     is: "no",
    //     then: yup
    //       .string()
    //       .required("please_enter_current_administration_phone_no"),
    //   }),
    // tenantTwoNameTags: yup.string().when("sameAdministrationAsFirstTenant", {
    //   is: "no",
    //   then: yup.string().required("please_enter_name_tags"),
    // }),
    // tenantTwoResidencyChangeReason: yup
    //   .string()
    //   .when("sameAdministrationAsFirstTenant", {
    //     is: "no",
    //     then: yup.string().required("please_enter_residency_change_reason"),
    //   }),
});

export default step0Section4Schema;
