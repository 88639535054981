import * as yup from "yup";

const step0Section1Schema = yup.object().shape({
    zip: yup.string().required("please_enter_your_zip_code"),

    city: yup.mixed().nullable().required("please_enter_your_city"),

    streetAddress: yup
        .string()
        .nullable()
        .required("please_enter_your_current_home_address"),

    streetNo: yup.string().required("please_enter_your_street_no"),

    country: yup.mixed().required("please_select_your_country"),
});

export default step0Section1Schema;
