import React from "react";

//* mui components imports
import Box from "@mui/material/Box";

import { BriefDossierContextForm } from "../../context/BriefDossierContextForm";

function BriefDossierForm() {
    return (
        <Box>
            <BriefDossierContextForm />
        </Box>
    );
}

export default BriefDossierForm;
