export const BENEFITS_OPTIONS = [
    {
        id: 1,
        icon: "flag",
        title: "benefit_title_one",
        subtitle: "benefit_subtitle_one",
    },
    {
        id: 2,
        icon: "finance_chip",
        title: "benefit_title_two",
        subtitle: "benefit_subtitle_two",
    },
    {
        id: 3,
        icon: "repeat",
        title: "benefit_title_two_half",
        subtitle: "benefit_subtitle_two_half",
    },
    {
        id: 4,
        icon: "visibility",
        title: "benefit_title_three",
        subtitle: "benefit_subtitle_three",
    },
    {
        id: 5,
        icon: "repeat",
        title: "benefit_title_four",
        subtitle: "benefit_subtitle_four",
    },

];
