import React from "react";

//* libraries
import { useNavigate } from "react-router-dom";

//* SVG
import PageNotFoundSvg from "../../component/svg/PageNotFoundSvg";

//* mui components imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function PageNotFound() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Container maxWidth="lg">
            <Box
                display={"flex"}
                minHeight="100vh"
                justifyContent="center"
                alignItems="center"
                flexDirection={"column"}
                rowGap={2}
            >
                <Typography variant="h2">{t("page_not_found")}</Typography>
                <Box>
                    <PageNotFoundSvg height={"100%"} width={"100%"} />
                </Box>
                <Typography variant="h4">
                    {t("page_not_found_return_to_homepage")}
                </Typography>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() =>
                        navigate("/", {
                            replace: true,
                        })
                    }
                >
                    Back to Homepage
                </Button>
            </Box>
        </Container>
    );
}

export default PageNotFound;
