import { useQuery } from "react-query";
import { Axios } from "../../axios/axios";

function getCities({ queryKey }) {
    const { searchTerm, type } = queryKey[1];
    return Axios.get(`/property/search_cities?name=${searchTerm}&type=${type}`);
}
export default function useCities(searchTerm, type = "") {
    return useQuery(["all_properties", { searchTerm, type }], getCities, {
        enabled: searchTerm?.trim()?.length > 2,
    });
}
