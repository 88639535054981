function Chip(theme) {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.dark,
                },
                filledPrimary: {
                    color: theme.palette.common.white,
                },

                outlinedSecondary: {
                    color: theme.palette.primary.dark,

                    transitionProperty: "background-color, color",
                    transitionDuration: "300ms",
                    transitionTimingFunction: "linear",
                    border: 0,
                    outline: "none",
                    "&&:hover": {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.secondary.main,
                    },
                },
                filledSecondary: {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.secondary.main,
                    transitionProperty: "background-color, color",
                    transitionDuration: "300ms",
                    transitionTimingFunction: "linear",
                    "&:hover": {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.secondary.main,
                    },
                },
            },
        },
    };
}

export default Chip;
