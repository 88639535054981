import React, { useEffect, useState } from "react";

//*  using toast for success and error
import { toast, Zoom, Flip } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* libraries
import { useNavigate, useParams } from "react-router-dom";

//* axios imports
import { Axios } from "../../../../axios/axios";

//* store imports
import { useStateValue } from "../../../../store/StateProvider";

//* react-query
import { useMutation } from "react-query";

//* api calls
import { useCheckDossier } from "../../../../hooks/profile/useCheckDossier";
import useUserProfile from "../../../../hooks/profile/useUserProfile";

import ApartmentIcon from "@mui/icons-material/Apartment";

//* mui components imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Divider from "@mui/material/Divider";
// import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAuthModal } from "../../../../context/ModalContext";
import MatchOverview from "../../../modal/MatchOverview";
import { useTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import useUserContext from "../../../../hooks/user-context/useUserContext";
import { useUserPropertyRequests } from "../../../../hooks/properties/useUserPropertyRequests";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import cookies from "js-cookie";
import Loading from "../../../loading/Loading";
import useResponsive from "../../../../hooks/responsive/useResponsive";
import { briefFormSchema as schema } from "../../../../forms-schemas/dossier-schemas/BriefFormSchema";
import useAddProfileDetail from "../../../../hooks/profile/useAddProfileDetails";
import useGetAllProfileDetailsById from "../../../../hooks/profile/useGetAllProfileDetailsById";

import { AnimatePresence, motion } from "framer-motion";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
    checkForEmpty,
    checkForNull,
} from "../../../../utils/checkDataFunction";

const onContact = (contactData) => {
    return Axios.post("/property/contact_seller", contactData);
};

function InformSmartDossierModal({
    show,
    handleClose,
    onAlreadyHave,
    onCreateDossier,
    onMaybeLater,
    isDossierCreated,
    modalTitle,
    modalBody,
}) {
    const { t } = useTranslation();
    const theme = useTheme();

    const NumberedItem = ({ number, children }) => (
        <Box display="flex" alignItems="flex-start" gap={2}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    minWidth: 28,
                    height: 28,
                    borderRadius: "50%",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    fontSize: "0.875rem",
                    paddingTop: "1px",
                    mt: "8px",
                    mb: "8px",
                }}
            >
                {number}
            </Box>
            <Typography sx={{ mt: "4px" }}>{children}</Typography>
        </Box>
    );

    return (
        <Dialog
            open={show}
            PaperProps={{
                style: {
                    borderRadius: 10,
                    border: "2px solid",
                    borderColor: theme.palette.primary.main,
                    padding: "10px",
                },
            }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    top: 8,
                    right: 8,
                }}
            >
                <CloseIcon
                    onClick={handleClose}
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                        color: theme.palette.grey[500],
                    }}
                />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 1,
                    color: theme.palette.primary.main,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ color: theme.palette.primary.main }}
                >
                    {modalTitle}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ color: theme.palette.primary.main }}
                >
                    {modalBody}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{ margin: "2px 0", padding: "10px" }}
                >
                    {isDossierCreated ? (
                        modalBody
                    ) : (
                        <Box sx={{ marginTop: "1px" }}>
                            <NumberedItem number={1}>
                                {t("onboarding_via_application_modal_one")}
                            </NumberedItem>
                            <NumberedItem number={2}>
                                {t("onboarding_via_application_modal_two")}
                            </NumberedItem>
                            <NumberedItem number={3}>
                                {t("onboarding_via_application_modal_three")}
                            </NumberedItem>
                            <NumberedItem number={4}>
                                {t("onboarding_via_application_modal_four")}
                            </NumberedItem>
                            <NumberedItem number={4}>
                                {t("onboarding_via_application_modal_five")}
                            </NumberedItem>
                        </Box>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: "flex-end",
                    padding: "8px 24px",
                }}
            >
                <Button
                    variant="contained"
                    style={{ height: "36px" }}
                    onClick={onCreateDossier}
                    sx={{}}
                    startIcon={<EditIcon />}
                >
                    {t("onboarding_via_application_modal_button")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DossierConfirmationModal({
    show,
    handleClose,
    onAlreadyHave,
    onCreateDossier,
    onMaybeLater,
    isDossierCreated,
    modalTitle,
    modalBody,
}) {
    const { t } = useTranslation();
    const theme = useTheme();

    const NumberedItem = ({ number, children }) => (
        <Box display="flex" alignItems="flex-start" gap={2}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    minWidth: 28,
                    height: 28,
                    borderRadius: "50%",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    fontSize: "0.875rem",
                    paddingTop: "1px",
                    mt: "8px",
                    mb: "8px",
                }}
            >
                {number}
            </Box>
            <Typography sx={{ mt: "4px" }}>{children}</Typography>
        </Box>
    );

    return (
        <Dialog
            open={show}
            PaperProps={{
                style: {
                    borderRadius: 10,
                    border: "2px solid",
                    borderColor: theme.palette.primary.main,
                    padding: "10px",
                },
            }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    top: 8,
                    right: 8,
                }}
            >
                <CloseIcon
                    onClick={handleClose}
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                        color: theme.palette.grey[500],
                    }}
                />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: theme.palette.primary.main,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ color: theme.palette.primary.main }}
                >
                    {modalTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{ margin: "2px 0", padding: "10px" }}
                >
                    {isDossierCreated ? (
                        modalBody
                    ) : (
                        <Box sx={{ marginTop: "1px" }}>
                            <NumberedItem number={1}>
                                {t("property_detail_apply_with_dossier_one")}
                            </NumberedItem>
                            <NumberedItem number={2}>
                                {t("property_detail_apply_with_dossier_two")}
                            </NumberedItem>
                            <NumberedItem number={3}>
                                {t("property_detail_apply_with_dossier_three")}
                            </NumberedItem>
                            <NumberedItem number={4}>
                                {t("property_detail_apply_with_dossier_four")}
                            </NumberedItem>
                        </Box>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {isDossierCreated ? (
                    <Stack flexDirection={"row"} flexWrap={"wrap"} gap={2}>
                        <Button
                            variant="outlined"
                            style={{ height: "30px" }}
                            onClick={onAlreadyHave}
                            sx={{
                                height: "30px",
                                border: "primary.main",
                                color: "primary.main",
                                "&:hover": {
                                    backgroundColor: "white",
                                    borderColor: "primary.main",
                                },
                            }}
                            startIcon={<CancelIcon />}
                        >
                            {t("no")}, {t("continue_to_requirements")}
                        </Button>
                        <Button
                            variant="contained"
                            style={{ height: "30px" }}
                            onClick={onCreateDossier}
                            endIcon={<SendIcon />}
                            sx={{}}
                        >
                            {t("yes")}
                        </Button>
                    </Stack>
                ) : (
                    <Stack flexDirection={"row"} flexWrap={"wrap"} gap={2}>
                        <Button
                            variant="contained"
                            style={{ height: "30px" }}
                            onClick={onCreateDossier}
                            sx={{}}
                            startIcon={<EditIcon />}
                        >
                            {t("property_detail_apply_with_dossier_fillout")}
                        </Button>

                        <Button
                            variant="outlined"
                            sx={{
                                height: "30px",
                                border: "primary.main",
                                color: "primary.main",
                                "&:hover": {
                                    backgroundColor: "white",
                                    borderColor: "primary.main",
                                },
                            }}
                            endIcon={<SendIcon />}
                            onClick={onAlreadyHave}
                        >
                            {t(
                                "property_detail_apply_with_dossier_direct_release"
                            )}
                        </Button>
                    </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
}

function ContactSeller({
    name,
    propertyId,
    propertyOwnerId,
    cityName,
    zipCode,
}) {
    const { t } = useTranslation();
    const [{ user_id, token }] = useStateValue();
    const {
        openAuthModal,
        handleUserRole,
        handleUserId,
        handleTmpUserContext,
    } = useAuthModal();
    const navigate = useNavigate();

    const [contactData, setContactData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "Hello, \nI am interested in this property. \nKind regards",
    });

    // userContext handling
    const { userContext, updateUserContext, readUserContext } =
        useUserContext();

    const tmpOrigin = userContext?.origin ?? "";
    const tmpAction = userContext?.action ?? "";
    const tmpActionValue = userContext?.actionValue ?? "";
    const tmpActionOpenValue = userContext?.actionOpenValue ?? "";
    const tmpActionCloseValue = userContext?.actionCloseValue ?? "";

    const [isDataReady, setIsDataReady] = useState(false);

    const checkAndShowDossierConfirmation = () => {
        if (
            tmpActionOpenValue === "onboarding" &&
            tmpActionCloseValue === "done"
        ) {
            onShowDossierConfirmation();
        }
    };
    useEffect(() => {
        checkAndShowDossierConfirmation();
    }, [tmpActionOpenValue, tmpActionCloseValue]);

    /*
    useEffect(() => {
        const newContext = {
            origin: "propertyApplication",
            action: "propertyId",
            actionValue: propertyId,
            actionOpenValue: "",
            actionCloseValue: "",
        };
        updateUserContext(newContext);
    }, [propertyId, updateUserContext]);
    */
    /*
    console.log(
        "Origin ContactSeller: " +
        userContext.origin +
        " / " +
        userContext.action +
        " / " +
        userContext.actionValue +
        " / " +
        userContext.actionOpenValue +
        " / " +
        userContext.actionCloseValue
    );
    */
    const theme = useTheme();

    const { mutate } = useMutation(onContact);
    // const [error, setError] = useState("");

    // const { isSmallPropertyDetailPage } = useSmallScreen();

    const [showBasicContactData, setShowBasicContactData] = useState(false);

    const [showDossierConfirmation, setShowDossierConfirmation] =
        useState(false);

    const [showInformSmartDossier, setShowInformSmartDossier] = useState(false);

    const [showMatchScoreOverview, setShowMatchScoreOverview] = useState(false);

    const {
        // isLoading,
        data,
    } = useUserProfile(user_id);

    const { data: checkDossierCreated } = useCheckDossier(user_id);

    const dossierCreated = checkDossierCreated?.data?.data;
    // const { isLoading: checkDossierScoreIsLoading, mutate: onCheckScore } = useCheckDossierScore();

    // control missing attribute of user
    const [missingAttributes, setMissingAttributes] = useState([]);

    //console.log(missingAttributes);

    useEffect(() => {
        if (user_id && token) {
            //console.log("useEffect-data?.data?.data[0]): ", data?.data?.data[0]);

            //console.log("data:", data);
            if (data?.data?.statuscode) {
                //
                setContactData((prevState) => ({
                    ...prevState,
                    salutation: data?.data?.data[0]?.gender || "",
                    firstName: data?.data?.data[0]?.first_name || "New",
                    lastName: data?.data?.data[0]?.last_name || "User",
                    email: data?.data?.data[0]?.email || "",
                    phone: data?.data?.data[0]?.phone || "",
                }));
                setIsDataReady(true);

                // console.log("GoGoGo: ", data)
                // console.log("GoGoGo: ", userContext)
            }
        }
    }, [user_id, token, data?.data?.statuscode, data?.data?.data]);

    const onShowInformSmartDossier = () => {
        setShowInformSmartDossier(true);
    };

    const onCloseInformSmartDossier = () => {
        setShowInformSmartDossier(false);
    };

    const onShowHandleBasicContactData = () => {
        setShowBasicContactData(true);
    };

    const onCloseBasicContactData = () => {
        setShowDossierConfirmation(false);
    };

    const onShowDossierConfirmation = () => {
        setShowDossierConfirmation(true);
    };
    const onCloseDossierConfirmation = () => {
        setShowDossierConfirmation(false);
    };

    const onShowMatchScoreOverview = () => {
        setShowMatchScoreOverview(true);
    };
    const onCloseMatchScoreOverview = () => {
        setShowMatchScoreOverview(false);
    };

    // const handleContactData = (e) => {
    //     const { name, value } = e.target;
    //     setContactData((prevState) => ({ ...prevState, [name]: value }));
    // };

    const onCreateDossier = () => {
        const newContext = {
            origin: tmpOrigin,
            action: tmpAction,
            actionValue: tmpActionValue,
            actionOpenValue: "onboarding",
            actionCloseValue: "todo",
        };
        updateUserContext(newContext);
        window.location.href = "/account/fs/smart-dossier";
    };

    const onDoPropertyAppointment = () => {
        const newContext = {
            origin: tmpOrigin,
            action: tmpAction,
            actionValue: tmpActionValue,
            actionOpenValue: "onboarding",
            actionCloseValue: "todo",
        };
        updateUserContext(newContext);
        window.location.href = "/brief-dossier-form";
    };

    const onAlreadyHave = () => {
        onCloseDossierConfirmation();
        onShowMatchScoreOverview();
    };

    const onApplyProperty = () => {
        const { firstName, lastName, email, phone, message } = contactData;

        let applyData = {
            user_id: user_id,
            property_id: propertyId,
            property_owner_id: propertyOwnerId,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_no: phone,
            city: "",
            zip_code: "",
            property_name: name,
            message: message,
            applier_status: "all_request",
            total_view: 0,
        };

        //console.log("contactData:", contactData);
        //console.log("applyData:", applyData);

        mutate(applyData, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    let errorMessage = data?.data?.message.toLowerCase();
                    if (
                        errorMessage.includes(
                            "you have already applied this property"
                        )
                    ) {
                        toast.success(
                            t("already_applied_to_property_toast_message"),
                            {
                                transition: Zoom,
                            }
                        );
                    } else {
                        toast.error(t("something_went_wrong"), {
                            transition: Zoom,
                        });
                    }
                    /*
                    toast.error(data?.data?.message, {
                        transition: Zoom,
                    });
                    */
                    console.error(data?.data?.message);
                    return;
                }
                toast.success(
                    t("property_detail_apply_with_dossier_success_Message"),
                    {
                        type: "success",
                        transition: Flip,
                    }
                );
                onCloseMatchScoreOverview();
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("something_went_wrong")}`);
            },
        });
    };

    useEffect(() => {
        if (
            isDataReady &&
            userContext.actionCloseValue === "enterContactDataFinished"
        ) {
            //console.log("userContext IN:", userContext);
            setIsDataReady(false);
            const newContext = {
                ...userContext,
                actionCloseValue: "",
            };
            updateUserContext(newContext);
            //console.error("onApplyProperty before:", newContext);

            //console.error("onApplyProperty after:", newContext);
            onApplyProperty();
            onShowInformSmartDossier();
        }
    }, [readUserContext, updateUserContext, onApplyProperty]);
    /*
     */

    const onContactSeller = async (e) => {
        e.preventDefault();

        //setShowBasicContactData(true);
        if (user_id === null || token === null) {
            const newContext = {
                origin: "propertyApplication",
                action: "propertyId",
                actionValue: propertyId,
                actionOpenValue: "enterContactData",
                actionCloseValue: "",
            };
            updateUserContext(newContext);

            try {
                await updateUserContext(newContext);
                await handleUserRole("TN");
                await handleTmpUserContext(newContext);
                openAuthModal();
            } catch (error) {
                console.error(
                    "Error updating user context or setting user role:",
                    error
                );
            }
        } else {
            //onShowInformSmartDossier();
            onShowDossierConfirmation();
        }
    };

    const onJumpToApplications = (e) => {
        e.preventDefault();
        if (user_id === null || token === null) {
            openAuthModal();
            return;
        }
        navigate("/account/fs/applied-flats-v1");
    };

    // ADDED BY AAR
    const {
        isLoading,
        data: propertyRequestsData,
        status,
    } = useUserPropertyRequests(propertyId, propertyOwnerId);

    let applicationStatus = 0;

    let propertyResponse = propertyRequestsData?.data;
    //console.log("propertyResponse: ", JSON.stringify(propertyResponse, null, 2));

    const allRequests = propertyResponse?.all_request;
    if (allRequests && Array.isArray(allRequests)) {
        const userInListAll = allRequests.find((request) => {
            //console.log(`Vergleiche: ${request.user_id} (Typ: ${typeof request.user_id}) mit ${user_id} (Typ: ${typeof user_id})`);
            return Number(request.user_id) === user_id;
        });
        /*
                console.log(user_id);
                console.log(userInListAll);
                console.log(propertyId);
         */
        if (userInListAll) {
            switch (userInListAll.lead_status) {
                case "all_request":
                    //console.log("Status: 1");
                    applicationStatus = 1;
                    break;
                default:
                    //console.log("Unbekannter Status.");
                    break;
            }
        } else {
            //console.log("Nutzer in all_requests nicht gefunden.");
        }
    } else {
        //console.log("Keine Anfragen gefunden.");
    }

    const selectedResult = propertyResponse?.selected_result;
    if (selectedResult && Array.isArray(selectedResult)) {
        const userInListSelected = selectedResult.find((request) => {
            //console.log(`Vergleiche: ${request.user_id} (Typ: ${typeof request.user_id}) mit ${user_id} (Typ: ${typeof user_id})`);
            return Number(request.user_id) === user_id;
        });

        if (userInListSelected) {
            switch (userInListSelected.lead_status) {
                case "selected":
                    //console.log("Status: 2");
                    applicationStatus = 2;
                    try {
                        const invitationDates = JSON.parse(
                            userInListSelected.invitation_date
                        );
                        if (
                            Array.isArray(invitationDates) &&
                            invitationDates.length > 0
                        ) {
                            // console.log("Mindestens ein Termin vorhanden.");
                        } else {
                            // console.log("Keine Termine vorhanden.");
                        }
                    } catch (error) {
                        //console.error("Fehler beim Parsen der invitation_date:", error);
                    }
                    break;
                default:
                    //console.log("Unbekannter Status.");
                    break;
            }
        } else {
            //console.log("Nutzer in selected_result nicht gefunden.");
        }
    } else {
        //console.log("Keine Anfragen gefunden.");
    }

    const visitedResult = propertyResponse?.visited_result;
    if (visitedResult && Array.isArray(visitedResult)) {
        const userInListVisited = visitedResult.find((request) => {
            //console.log(`Vergleiche: ${request.user_id} (Typ: ${typeof request.user_id}) mit ${user_id} (Typ: ${typeof user_id})`);
            return Number(request.user_id) === user_id;
        });

        if (userInListVisited) {
            switch (userInListVisited.lead_status) {
                case "visited":
                    //console.log("Status: 3");
                    applicationStatus = 3;
                    try {
                        const invitationDates = JSON.parse(
                            userInListVisited.invitation_date
                        );
                        if (
                            Array.isArray(invitationDates) &&
                            invitationDates.length > 0
                        ) {
                            //console.log("Mindestens ein Besuch vorhanden.");
                        } else {
                            //console.log("Kein Besuch vorhanden.");
                        }
                    } catch (error) {
                        //console.error("Fehler beim Parsen der invitation_date:", error);
                    }
                    break;
                default:
                    //console.log("Unbekannter Status.");
                    break;
            }
        } else {
            //console.log("Nutzer in visited_result nicht gefunden.");
        }
    } else {
        //console.log("Keine Anfragen gefunden.");
    }

    return (
        <>
            <Paper
                elevation={0}
                sx={{
                    p: 5,
                    borderRadius: "16px",
                    position: "sticky",
                    top: "100px",
                    border: "1px solid",
                    borderColor: theme.palette.secondary.main,
                }}
            >
                <Stack spacing={4} alignItems={"center"}>
                    <Stack spacing={3}>
                        <Typography
                            variant="h5"
                            textAlign={"initial"}
                            sx={{
                                color: theme.palette.secondary.main,
                            }}
                        >
                            <ApartmentIcon
                                sx={{
                                    color: theme.palette.secondary.main,
                                }}
                            />{" "}
                            {t("your_dream_apartment") + "?"}
                        </Typography>
                        {/*
                        <Typography variant="h6" textAlign={"initial"}>
                            {t("apply_now")}
                        </Typography>
                        */}
                    </Stack>
                    {
                        //console.log(applicationStatus)
                    }
                    {applicationStatus >= 2 ? (
                        <>
                            <Typography
                                variant="subtitle2"
                                sx={{ mb: 1, textAlign: "center" }}
                            >
                                {t("apply_with_smart_dossier_already_text")}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={onJumpToApplications}
                                fullWidth
                                sx={{
                                    borderRadius: "16px",
                                    position: "sticky",
                                    top: "100px",
                                    border: "1px solid",
                                    backgroundColor: theme.palette.primary.main,
                                    borderColor: theme.palette.secondary.main,
                                }}
                                startIcon={<CalendarTodayIcon />}
                                endIcon={<SendIcon />}
                            >
                                {t("applied_flats")}
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={onContactSeller}
                            fullWidth
                            sx={{
                                borderRadius: "16px",
                                position: "sticky",
                                top: "50px",
                                border: "1px solid",
                                backgroundColor: theme.palette.primary.main,
                                borderColor: theme.palette.secondary.main,
                                mt: -20,
                            }}
                            startIcon={<CalendarTodayIcon />}
                            endIcon={<SendIcon />}
                        >
                            {t("apply_with_smart_dossier")}
                        </Button>
                    )}
                </Stack>
            </Paper>
            {/*
             */}
            <InformSmartDossierModal
                show={showInformSmartDossier}
                handleClose={onCloseInformSmartDossier}
                onAlreadyHave={onAlreadyHave}
                onCreateDossier={onCreateDossier}
                isDossierCreated={dossierCreated}
                modalTitle={t("onboarding_via_application_modal_title")}
                modalBody={t("onboarding_via_application_modal_body")}
            />
            <DossierConfirmationModal
                show={showDossierConfirmation}
                handleClose={onCloseDossierConfirmation}
                onAlreadyHave={onAlreadyHave}
                onCreateDossier={onCreateDossier}
                isDossierCreated={dossierCreated}
                modalTitle={t(
                    "property_detail_apply_with_dossier_title_request_appointment"
                )}
                modalBody={t("property_detail_apply_with_dossier_body")}
            />
            {
                //console.log("data:", data?.data)
            }
            <MatchOverview
                show={showMatchScoreOverview}
                handleClose={onCloseMatchScoreOverview}
                onApplyProperty={onApplyProperty}
                isDossierCreated={dossierCreated}
                modalTitle={t(
                    "property_detail_apply_with_dossier_match_attributes_overview"
                )}
                modalBody={t(
                    "property_detail_apply_with_dossier_match_attributes_body"
                )}
                onCreateDossier={onCreateDossier}
                onDoPropertyAppointment={onDoPropertyAppointment}
                propertyId={propertyId}
                userAttributes={data?.data?.data[0]}
                applicationStatus={applicationStatus}
            />
        </>
    );
}

export default ContactSeller;
