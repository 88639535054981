import { useQuery } from 'react-query'
import { Axios } from '../../axios/axios'

const getPropertyResponses = ({ queryKey }) => {
  return Axios.get(
    `/property/get_respones?property_id=${queryKey[1]}&property_owner_id=${queryKey[2]}`
  )
}

export function  useUserPropertyRequests(property_id, property_owner_id) {
  return useQuery(
    ['get-requests', property_id, property_owner_id],
    getPropertyResponses
  )
}
